import { RoomTypeBaseModel } from '../room-type/room-type-base.model';
import { PricePlanRangeModel } from './price-plan-range.model';
import { RatePlanWithDerivedPricingModel } from '../rate-plan/rate-plan-with-derived-pricing.model';
import { CurrencyModel } from '../account/currency.model';
import { RatePlanBaseModel } from '../rate-plan/rate-plan-base.model';
import { EnumModel } from '../shared/enum-model';

export class PricePlanPageModel {
    public roomTypes: PPRoomTypeModel[] = [];
    public ratePlans: RatePlanWithDerivedPricingModel[] = [];
    public currency: CurrencyModel;
    public hasActiveOtaConnection: boolean;

    constructor() {
        this.currency = new CurrencyModel();
    }
}

export class PPRoomTypeModel {
    public roomType: RoomTypeBaseModel;
    public slave: boolean;
    public nights: PPNightsModel[];
    public closeOuts: PPCloseOutModel[];
    public rulesAreDefined: boolean;
    public additionalInfo: string;
    public otaRestriction: boolean;
    public dynamicPricingDefined: boolean;

    // added new properties
    public disabled: boolean;

    constructor(roomTypeId?: number, roomTypeName?: string) {
        this.roomType = new RoomTypeBaseModel();
        this.roomType.id = roomTypeId;
        this.roomType.shortName = roomTypeName;
    }
}

export class PPNightsModel {
    public minLoS: number;
    public prices: PricePlanRangeModel[];

    // added new properties
    public allPrices: PricePlanRangeModel[] = [];
}

export class PPCloseOutModel {
    public date: Date;
    public ratePlanId: number;
    public roomTypeId: number;
    public status: string;
}

export class PPCloseOutPopupSourceModel {
    public ratePlans: Array<CloseOutRatePlanModel>;
    public roomTypes: Array<CloseOutRoomTypesModel>;
    public statuses: Array<EnumModel>;
}

export class CloseOutRatePlanModel {
    public id: number;
    public name: string;
}

export class CloseOutRoomTypesModel extends CloseOutRatePlanModel {
    public parentId: number;

    constructor(parentId: number, id: number, name: string) {
        super();
        this.parentId = parentId;
        this.id = id;
        this.name = name;
    }
}

export class UpdateCloseOutModel {
    public propertyId: number;
    public dateFrom: Date;
    public dateTo: Date;
    public ratePlanId: number;
    public roomTypeIdList: Array<number> = [];
    public status: string;
}
