import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { AuthTokenService } from '../shared/services/auth-token.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.less'],
})
export class LayoutComponent implements OnInit {
    loader = false;
    theme: number;

    constructor(public router: Router) {}

    ngOnInit() {
        this.router.events.subscribe((event) => {
            this.navigationInterceptor(event);
        });
    }

    navigationInterceptor(event: any) {
        if (event instanceof NavigationStart) {
            this.loader = true;
        }
        if (event instanceof NavigationEnd) {
            this.loader = false;
        }
        if (event instanceof NavigationCancel) {
            this.loader = false;
        }
        if (event instanceof NavigationError) {
            this.loader = false;
        }
    }
}
