import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpRequestService } from './http-request.service';
import { ReportsService } from 'src/app/routes/reports/reports.service';
import { BehaviorSubject } from 'rxjs';
import DateHelper from '../helpers/date-helper';
import * as _ from 'lodash';

@Injectable()
export class TableToCsvService {

    exportToCsv(tableId: string, fileName: string, separator: string = ',') {
        let rows = document.querySelectorAll('table#' + tableId + ' tr');
        let csv = [];
        for (let i = 0; i < rows.length; i++) {
            let row = [], cols = rows[i].querySelectorAll('td, th');
            for (let j = 0; j < cols.length; j++) {
                // Clean textContent to remove multiple spaces and jumpline (break csv)
                let data = cols[j].textContent.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
                // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                data = data.replace(/"/g, '""');
                // Push escaped string
                row.push('"' + data + '"');

                if (cols[j].getAttribute ('colspan') != null) {
                    let colspan = parseInt(cols[j].getAttribute ('colspan'));
                    for (let k=0; k< colspan-1; k++) {
                        row.push('""');
                    }
                }
            }
            csv.push(row.join(separator));
        }

        let BOM = "\uFEFF";
        let csv_string = csv.join('\n');
        // Download it
        let link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(BOM + csv_string));
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

}