import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IncidentalSalesService } from '../incidental-sales.service';
import { CurrencyContext , CountryContext, TokenContext} from '../../../../models/auth-jwt/token-context';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { SaleItemPDModel } from '../../../../models/cost-center/sale-item-pd.model';
import { SaleItemModel } from '../../../../models/cost-center/sale-item.model';
import { StatusModel } from '../../../../models/shared/status.model';
import { EnumModel } from '../../../../models/shared/enum-model';
import { ExtrasPermissionsModel } from '../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../enums/permissions.enum';
import { SaleItemCategoryModel } from 'src/app/models/cost-center/sale-item-category.model';

@Component({
    selector: 'app-incidental-sales-item',
    templateUrl: './incidental-sales-item.component.html',
    styleUrls: ['./incidental-sales-item.component.less'],
})
export class IncidentalSalesItemComponent implements OnInit {
    @Input() addItem: boolean;
    @Output() addItemChange = new EventEmitter<boolean>();
    @Input() editItem: boolean;
    @Output() editItemChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();
    @Input() saleItemId: number;
    @Input() saleItemCategory: string;

    loader = false;

    isEditRatePlans = false;
    isFunction = false;

    permissionsModel: ExtrasPermissionsModel;

    valForm: UntypedFormGroup;
    saleItemModelPDModel = new SaleItemPDModel();
    statusModelList: StatusModel[] = [];
    chargeTypes: EnumModel[] = [];
    theme: number;

    currencyContext = new CurrencyContext();
    countryContext: CountryContext;
    token: TokenContext = new TokenContext();

    constructor(
        private fb: UntypedFormBuilder,
        private incidentalSalesService: IncidentalSalesService,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.token = AuthTokenService.decodeJwtToken();
        this.permissionsModel = permissionsService.createPermissionsModel(PermissionsEnum.Extras);
        this.valForm = this.fb.group({
            name: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            category: [null, Validators.required],
            costCentre: [null, Validators.required],
            price: [null, Validators.compose([Validators.required])],
            chargeType: [null, Validators.required],
            status: [null, Validators.required],
            notes: [null],
            vatRate: [null, Validators.required],
            webSale: [null],
            itemType: [null, Validators.compose([Validators.required])],
            housekeeping: [null],
        });

        this.currencyContext = this.token.Currency;
        this.countryContext = this.token.Country;
        this.statusModelList.push(new StatusModel(true, 'Active'));
        this.statusModelList.push(new StatusModel(false, 'Inactive'));
    }

    ngOnInit() {
        if (this.addItem) {
            this.incidentalSalesService
                .getSaleItemPageDataForInsert(AuthTokenService.decodeJwtToken().Property.Id)
                .subscribe(
                    (response) => {
                        this.saleItemModelPDModel = response;
                        this.saleItemModelPDModel.saleItem = new SaleItemModel();
                        this.saleItemModelPDModel.saleItem.price = 0;
                        this.chargeTypes = this.saleItemModelPDModel.chargeType;
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        } else if (this.editItem) {
            this.checkFunctionCategory(this.saleItemCategory);
            this.incidentalSalesService
                .getSaleItemDetails(this.saleItemId, AuthTokenService.decodeJwtToken().Property.Id)
                .subscribe(
                    (response) => {
                        this.saleItemModelPDModel = response;
                        const selectedItemType = this.saleItemModelPDModel.itemType.find(x => x.id===this.saleItemModelPDModel.saleItem.itemType);
                        const selectedCategory = this.saleItemModelPDModel.saleItemCategory.find(x => x.id===this.saleItemModelPDModel.saleItem.categoryId);
                        this.setChargeTypes(selectedItemType, selectedCategory);
                        
                        this.valForm.controls['category'].setValue(this.saleItemModelPDModel.saleItem.categoryId);
                        this.valForm.controls['itemType'].setValue(this.saleItemModelPDModel.saleItem.itemType);
                        let disableControlSettings = {name: false, price: false, chargeType: false, status: false, webSale: false, itemType: false, vat: false, housekeeping: false}

                        if (this.saleItemModelPDModel.saleItem.itemType === 'EPOSItem') {
                            if (this.token.Property.ZReadEnabled == false || this.saleItemModelPDModel.saleItem.defaultEPOSItem === true)
                            {
                                Object.keys(disableControlSettings).forEach(x => disableControlSettings[x] = true); //disable everything
                            }
                            else 
                            {
                                disableControlSettings.price = true;
                                disableControlSettings.chargeType = true;
                                disableControlSettings.webSale = true;
                                disableControlSettings.housekeeping = true;
                            }
                        }
                        if (this.saleItemModelPDModel.saleItem.itemType === 'CancellationItem') {
                            Object.keys(disableControlSettings).forEach(x => disableControlSettings[x] = true); //disable everything
                            disableControlSettings.vat = false;
                        }
                        if (this.saleItemModelPDModel.saleItem.itemType === 'Gratuity') {
                            disableControlSettings.price = true;
                            disableControlSettings.chargeType = true;
                            disableControlSettings.webSale = true;
                            disableControlSettings.housekeeping = true;
                        }
                        if (this.saleItemModelPDModel.saleItem.itemType === 'EarlyCheckIn' || this.saleItemModelPDModel.saleItem.itemType === 'LateCheckOut') {
                            disableControlSettings.chargeType = true;
                        }
                        this.disableControls(disableControlSettings);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    disableControls(disableControlSettings: any) {
        this.valForm.controls['name'].enable();
        this.valForm.controls['price'].enable();
        this.valForm.controls['chargeType'].enable();
        this.valForm.controls['status'].enable();
        this.valForm.controls['vatRate'].enable();
        this.valForm.controls['webSale'].enable();
        this.valForm.controls['itemType'].enable();
        this.valForm.controls['housekeeping'].enable();
        
        if (disableControlSettings.name) {
            this.valForm.controls['name'].disable();
        }
        
        if (disableControlSettings.price) {
            this.valForm.controls['price'].disable();
        }
        
        if (disableControlSettings.chargeType) {
            this.valForm.controls['chargeType'].disable();
        }
        
        if (disableControlSettings.status) {
            this.valForm.controls['status'].disable();
        }
        
        if (disableControlSettings.vat) {
            this.valForm.controls['vatRate'].disable();
        }
        
        if (disableControlSettings.webSale) {
            this.valForm.controls['webSale'].disable();
        }
        
        if (disableControlSettings.itemType) {
            this.valForm.controls['itemType'].disable();
        }
        
        if (disableControlSettings.housekeeping) {
            this.valForm.controls['housekeeping'].disable();
        }

        if (!this.permissionsModel.formButtonEnabled) {
            this.valForm.controls['name'].disable();
            this.valForm.controls['price'].disable();
            this.valForm.controls['chargeType'].disable();
            this.valForm.controls['status'].disable();
            this.valForm.controls['vatRate'].disable();
            this.valForm.controls['webSale'].disable();
            this.valForm.controls['itemType'].disable();
            this.valForm.controls['housekeeping'].disable();
        }
    }

    
    checkItemType(e) {
        if (e.itemData !== null && this.saleItemModelPDModel.saleItem != null && this.saleItemModelPDModel.saleItem.categoryId != null) {
            const selectedCategory = this.saleItemModelPDModel.saleItemCategory.find(x => x.id===this.saleItemModelPDModel.saleItem.categoryId);
            this.setChargeTypes(e.itemData, selectedCategory);
        }
    }

    categoryChanged(e) {
        if (e.itemData !== null && this.saleItemModelPDModel.saleItem != null && this.saleItemModelPDModel.saleItem.itemType != null) {
            const selectedItemType = this.saleItemModelPDModel.itemType.find(x => x.id===this.saleItemModelPDModel.saleItem.itemType);
            this.setChargeTypes(selectedItemType, e.itemData);
        }
        
    }

    checkFunctionCategory(selectedCategoryName: String) {
        if (selectedCategoryName.toLowerCase() === 'functions - catering' || selectedCategoryName.toLowerCase() === 'functions - items') {
            this.isFunction=true;
            //we limit the charge type to each for functions
            this.chargeTypes = this.saleItemModelPDModel.chargeType.filter(
                (x) => x.value === 'Each'
            );
        }  
        else {
            this.isFunction = false;
        }  
    }

    setChargeTypes(selectedItemType: EnumModel, selectedCategory: SaleItemCategoryModel) {
        this.checkFunctionCategory(selectedCategory.name);
        let disableControlSettings = {name: false, price: false, chargeType: false, status: false, webSale: false, itemType: false, vat: false, housekeeping: true}
        if (!this.isFunction) {
            switch (selectedItemType.value) {
                case 'Breakfast':
                    this.chargeTypes = this.saleItemModelPDModel.chargeType.filter(
                        (x) => x.value !== 'PerPersonPerDayEvening' && x.value !== 'PerDayEvening'
                    );  
                    break;  
                case 'Dinner':
                    this.chargeTypes = this.saleItemModelPDModel.chargeType.filter(
                        (x) => x.value !== 'PerPersonPerDayMorning' && x.value !== 'PerDayMorning'
                    );  
                    break;  
                case 'EPOSItem':
                    this.chargeTypes = this.saleItemModelPDModel.chargeType.filter(
                        (x) => x.value === 'Each'
                    );
                    break;
                case 'AllowanceItem':
                    this.chargeTypes = this.saleItemModelPDModel.chargeType.filter(
                        (x) => x.value === 'PerPersonPerStay' || x.value === 'Each'
                    );
                    break;
                case 'Gratuity':
                case 'LateCheckOut':
                case 'EarlyCheckIn':
                    this.chargeTypes = this.saleItemModelPDModel.chargeType.filter(
                        (x) => x.value === 'Each'
                    );
                    break;
                default:
                    this.chargeTypes = this.saleItemModelPDModel.chargeType;
                    break;  
            }

        }
        if (selectedItemType.value == 'Gratuity' || selectedItemType.value == 'EPOSItem') {
            this.saleItemModelPDModel.saleItem.price = 0;
            this.saleItemModelPDModel.saleItem.chargeType = 'Each';
            this.saleItemModelPDModel.saleItem.webSale = false;
            if ( this.saleItemModelPDModel.saleItem.defaultEPOSItem === true)
            {
                Object.keys(disableControlSettings).forEach(x => disableControlSettings[x] = true); //disable everything
                this.disableControls(disableControlSettings);
            }
            else 
            {
                disableControlSettings.price = true;
                disableControlSettings.chargeType = true;
                disableControlSettings.webSale = true;
                this.disableControls(disableControlSettings);
            }

            
        }
    }

    submitSaleItem(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            if (this.addItem) {
                this.loader = true;
                this.insertSaleItem();
            } else if (this.editItem) {
                this.updateSaleItem();
            }
        }
    }

    insertSaleItem() {
        if (!this.saleItemModelPDModel.saleItem.notes) {
            this.saleItemModelPDModel.saleItem.notes = '';
        }
        this.incidentalSalesService.insertSaleItem(this.saleItemModelPDModel.saleItem).subscribe(
            (response) => {
                this.update.emit();
                this.close();
            },
            (error) => {
                console.log(error);
                this.loader = false;
            }
        );
    }

    updateSaleItem() {
        this.loader = true;
        this.incidentalSalesService.updateSaleItem(this.saleItemModelPDModel.saleItem).subscribe(
            (response) => {
                this.loader = false;
                this.update.emit();
                this.close();
            },
            (error) => {
                this.loader = false;
                console.log(error);
            }
        );
    }

    close() {
        this.addItemChange.emit(false);
        this.editItemChange.emit(false);
    }

    editRatePlans() {
        this.isEditRatePlans = true;
    }

    closeEditRatePlans() {
        this.isEditRatePlans = false;
    }

    saveSaleItemRatePlans() {
        this.loader = true;
        this.incidentalSalesService.updateSaleItemRatePlans(this.saleItemModelPDModel.ratePlans).subscribe(
            (response) => {
                this.isEditRatePlans = false;
                this.loader = false;
                this.update.emit();
            },
            (error) => {
                this.isEditRatePlans = false;
                this.loader = false;
            }
        );
    }
}
