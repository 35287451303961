import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CostCentreModel } from '../../../../../../models/rate-plan/cost-centre.model';
import {
    RatePlanCostCentreFeeModel,
    RatePlanCostCentreFeeManageModel,
    CostCentreRatePlanFeeToDeleteModel,
} from '../../../../../../models/rate-plan/rate-plan-cost-centre-fee.model';
import { AuthTokenService } from '../../../../../../shared/services/auth-token.service';
import { RatePlanService } from '../../rate-plan.service';
import { EnumModel } from '../../../../../../models/shared/enum-model';
import * as _ from 'lodash';
import { InventoryRoomTypesPermissionsModel } from '../../../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../../../enums/permissions.enum';

@Component({
    selector: 'app-cost-fees-popup',
    templateUrl: './cost-fees-popup.component.html',
    styleUrls: ['./cost-fees-popup.component.less'],
})
export class CostFeesPopupComponent implements OnInit {
    @Input() costFees: boolean;
    @Output() costFeesChange = new EventEmitter<boolean>();
    @Input() ratePlanId: number;
    @Input() ratePlanName: number;
    @Input() itemChargeType: EnumModel[];

    permissionsModel: InventoryRoomTypesPermissionsModel;

    errorMessage = '';
    theme: number;

    costCentreModel: CostCentreModel[];
    ratePlanCostCentreFeeModel: RatePlanCostCentreFeeModel[] = [];
    ratePlanCostCentreFeeManageModel = new RatePlanCostCentreFeeManageModel();

    constructor(private ratePlanService: RatePlanService, private permissionsService: PermissionsService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryRoomTypes);
        this.ratePlanCostCentreFeeManageModel.costCentreRatePlanFeeToDelete = [];
    }

    ngOnInit() {
        if (AuthTokenService.decodeJwtToken().Property.Id) {
            this.ratePlanService.getRatePlanCostCentre(AuthTokenService.decodeJwtToken().Property.Id).subscribe(
                (response) => {
                    this.costCentreModel = response;
                    this.ratePlanService.getRatePlanCostCentreFee(this.ratePlanId).subscribe(
                        (response2) => {
                            this.ratePlanCostCentreFeeModel = response2;
                        },
                        (error) => console.log(error)
                    );
                },
                (error) => console.log(error)
            );
        }
    }

    insertNewCentreFee() {
        if (this.ratePlanCostCentreFeeModel.length === 0) {
            this.ratePlanCostCentreFeeModel = [];
        }
        const ratePlanCostCentreFeeModel = new RatePlanCostCentreFeeModel();
        ratePlanCostCentreFeeModel.ratePlanId = this.ratePlanId;
        ratePlanCostCentreFeeModel.feeAmount = 0;
        this.ratePlanCostCentreFeeModel.push(ratePlanCostCentreFeeModel);
    }

    deleteCostFees(data) {
        const index = this.ratePlanCostCentreFeeModel.indexOf(data);
        const costFeeToDelete = new CostCentreRatePlanFeeToDeleteModel(
            this.ratePlanCostCentreFeeModel[index].costCentreId,
            this.ratePlanCostCentreFeeModel[index].ratePlanId
        );
        this.ratePlanCostCentreFeeManageModel.costCentreRatePlanFeeToDelete.push(costFeeToDelete);
        this.ratePlanCostCentreFeeModel.splice(index, 1);
    }

    saveCentreFees() {
        if (!this.validateForm()) {
            this.ratePlanCostCentreFeeManageModel.costCentreRatePlanFee = this.ratePlanCostCentreFeeModel;
            this.ratePlanCostCentreFeeManageModel.ratePlanId = this.ratePlanCostCentreFeeManageModel
                .costCentreRatePlanFee.length
                ? this.ratePlanCostCentreFeeManageModel.costCentreRatePlanFee[0].ratePlanId
                : null;

            this.ratePlanService.setRatePlanCostCentreFee(this.ratePlanCostCentreFeeManageModel).subscribe(
                (response) => {
                    this.close();
                },
                (error) => console.log(error)
            );
        }
    }

    validateForm(): boolean {
        for (const details of this.ratePlanCostCentreFeeModel) {
            if (details.chargeType === null || details.costCentreId === null || details.feeAmount === null) {
                this.errorMessage = 'Error! Please fill all fields.';
                return true;
            }

            if (details.feeAmount < 0) {
                this.errorMessage = 'Error! Fee amount cannot be negative.';
                return true;
            }
        }

        if (
            _.uniqBy(this.ratePlanCostCentreFeeModel, 'costCentreId').length !== this.ratePlanCostCentreFeeModel.length
        ) {
            this.errorMessage = 'Duplicate Cost Centres.';
            return true;
        }

        // if (this.ratePlanCostCentreFeeModel.length === 0) {
        //   this.errorMessage = 'You have to create at least one cost centre fee.';
        //   return true;
        // }

        this.errorMessage = '';
        return false;
    }

    close() {
        this.costFeesChange.emit(false);
    }
}
