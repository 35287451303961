import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { CleaningFrequencyModel } from '../../../../models/cleaning-frequency/cleaning-frequency.model';
import { KeyValuePair } from '../../../../models/shared/key-value-pair.model';
import { HousekeepingService } from '../housekeeping.service';

@Component({
    selector: 'app-change-order-housekeeping',
    templateUrl: './change-order-housekeeping.component.html',
    styleUrls: ['./change-order-housekeeping.component.less'],
})
export class ChangeOrderHousekeepingComponent implements OnInit {
    @Input() housekeepingOrder: boolean;
    @Output() housekeepingOrderChange = new EventEmitter<boolean>();
    @Input() cleaningFrequencies: CleaningFrequencyModel[];
    @Output() update = new EventEmitter();

    copyOfCleaningFrequency: CleaningFrequencyModel[] = [];
    listOfReorderedHousekeepingTypes: KeyValuePair[] = [];

    changeOrderLoader = false;
    theme: number;
    constructor(private housekeepingService: HousekeepingService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.onReorder = this.onReorder.bind(this);
    }

    ngOnInit() {
        this.copyOfCleaningFrequency = [...this.cleaningFrequencies];
    }

    onReorder(e) {
        const visibleRows = e.component.getVisibleRows();
    
        const toIndex = this.copyOfCleaningFrequency.findIndex((item) => item.id === visibleRows[e.toIndex].data.id);
        const fromIndex = this.copyOfCleaningFrequency.findIndex((item) => item.id === e.itemData.id);

        this.copyOfCleaningFrequency.splice(fromIndex, 1);
        this.copyOfCleaningFrequency.splice(toIndex, 0, e.itemData);
    
        for (let i=1; i<= this.copyOfCleaningFrequency.length; i++) {
          this.copyOfCleaningFrequency[i-1].order = i;
        }
      }

    calculateStatusValue(rowData) {
        if (rowData.active) {
            return "Active";
        }
        return "Inactive";
    }

    submitOrderHousekeeping() {
        this.changeOrderLoader = true;
        let i = 0;

        for (const cleaningFrequency of this.copyOfCleaningFrequency) {
            i++;
            this.listOfReorderedHousekeepingTypes.push(new KeyValuePair(cleaningFrequency.id, i));
        }
        this.housekeepingService.reorderCleaningFrequencies(this.listOfReorderedHousekeepingTypes).subscribe(
            (response) => {
                this.changeOrderLoader = false;
                this.update.emit();
                this.close();
            },
            (error) => {
                this.changeOrderLoader = false;
            }
        );
    }
    close() {
        this.housekeepingOrderChange.emit(false);
    }
}
