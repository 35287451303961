import { Component, OnInit, Input, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.less'],
})
export class ConfirmationComponent implements OnInit {
    @Input() confirmation: boolean;
    @Input() confirmationChange: EventEmitter<boolean> = new EventEmitter();
    @Input() propertyEmail: string;

    tempPropertyEmail: string;
    constructor() {}

    ngOnInit() {
        this.tempPropertyEmail = this.propertyEmail;
    }
}
