<app-header></app-header>

<div class="debit-mandate-wrapper flex">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <h2>Create direct debit mandate</h2>

        <form [formGroup]="valForm" (ngSubmit)="openConfirmModal($event)">
            <div class="form-item">
                <div class="custom-label">Account holder name</div>
                <input
                    class="custom-input"
                    type="text"
                    name="accountHolderName"
                    formControlName="name"
                    [(ngModel)]="activationFlowModel.goCardlessData.accountHolderName"
                />
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['name'].hasError('required') &&
                        (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                    "
                    >Field is required.</span
                >
            </div>
            <div class="form-item">
                <div class="custom-label">Account number</div>
                <input
                    class="custom-input"
                    type="text"
                    name="number"
                    formControlName="number"
                    [(ngModel)]="activationFlowModel.goCardlessData.accountNumber"
                />
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['number'].hasError('required') &&
                        (valForm.controls['number'].dirty || valForm.controls['number'].touched)
                    "
                    >Field is required.</span
                >
            </div>
            <div class="form-item">
                <div class="custom-label">Branch code</div>
                <input
                    class="custom-input"
                    type="text"
                    name="code"
                    formControlName="code"
                    [(ngModel)]="activationFlowModel.goCardlessData.branchCode"
                />
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['code'].hasError('required') &&
                        (valForm.controls['code'].dirty || valForm.controls['code'].touched)
                    "
                    >Field is required.</span
                >
            </div>
            <div class="form-item">
                <input
                    type="checkbox"
                    name="agreement"
                    formControlName="agreement"
                    [(ngModel)]="agreement"
                    id="agreement"
                    class="css-checkbox"
                />
                <label for="agreement" class="css-label radGroup1"></label>
                <div class="custom-label inline">
                    I confirm that I am the account holder and am authorised to set up Direct Debit payments on this
                    account.
                </div>
                <span
                    class="text-danger"
                    *ngIf="
                        (valForm.controls['agreement'].hasError('required') &&
                            (valForm.controls['agreement'].dirty || valForm.controls['agreement'].touched)) ||
                        (submitted && !agreement)
                    "
                    >Field is required.</span
                >
            </div>

            <button *ngIf="!loader" type="submit" class="custom-button blue debit">Set up a Direct Debit</button>
            <div *ngIf="loader" class="loader"></div>
        </form>

        <div class="terms-wrapper">
            <div>
                By confirming you are agreeing to our
                <span class="link" (click)="openTerms()">terms & conditions</span>.
            </div>
            <div>
                Your payments are protected by the
                <span class="link" (click)="openGuaranteeInfo()">Direct Debit Guarantee</span>.
            </div>
        </div>
    </div>
</div>
<app-message-modal
    *ngIf="popup"
    [(popup)]="popup"
    [title]="title"
    [message]="message"
    (confirm)="confirm()"
></app-message-modal>
<app-confirm-direct-debit-modal
    *ngIf="debitConfirmModal"
    [goCardLessModel]="activationFlowModel.goCardlessData"
    (confirm)="submitDebitMandate($event)"
    [(debitConfirmModal)]="debitConfirmModal"
>
</app-confirm-direct-debit-modal>
<app-guarantee-info *ngIf="guaranteeInfo" [(guaranteeInfo)]="guaranteeInfo"></app-guarantee-info>
