import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpRequestService } from './http-request.service';
import { FileInsertModel } from 'src/app/models/shared/file-insert-model';
import { PropertyFileModel } from 'src/app/models/shared/property-file-model';
import { FunctionFileModel } from 'src/app/models/function-room/function-flow.model';

@Injectable()
export class FileService {
    private uploadFileUrl = environment.apiUrl + '/File/Upload';
    private downloadFileUrl = environment.apiUrl + '/File/Download';
    private getByCategoryUrl = environment.apiUrl + '/File/GetPropertyFilesByCategory';
    private deleteFileUrl = environment.apiUrl + '/File/DeleteFile';
    private getFunctionRoomFilesUrl = environment.apiUrl + '/Function/GetFunctionRoomFiles';
    private deleteFunctionRoomUrl = environment.apiUrl + '/Function/DeleteFunctionRoomFile';
    private uploadFunctionRoomUrl = environment.apiUrl + '/Function/UploadFunctionRoomFile';

    request = '';
    constructor(private http: HttpRequestService) {}

    upload(model: FileInsertModel, file: File[]) {
        return this.http.postMultipart(this.uploadFileUrl, model, file, true);
    }

    getFilesByCategory(category: number) {
        return this.http.get(this.getByCategoryUrl + '?fileCategory=' + category);
    }

    download(model: PropertyFileModel) {
        return this.http.getBlob(this.downloadFileUrl, model);
    }

    delete(model: PropertyFileModel) {
        return this.http.post(this.deleteFileUrl, model);
    }

    getFunctionRoomFiles(functionRoomId: number) {
        return this.http.get(this.getFunctionRoomFilesUrl + '?functionRoomId=' + functionRoomId);
    }

    uploadFunctionRoomFile(model: FunctionFileModel, file: File[]) {
        return this.http.postMultipart(this.uploadFunctionRoomUrl, model, file, true);
    }

    deleteFunctionRoomFile(model: FunctionFileModel) {
        return this.http.post(this.deleteFunctionRoomUrl, model);
    }

}