<div class="guests-wrapper flex">
    <div class="custom-box">
        <h2>Manage guests</h2>

        <button type="button" class="custom-button orange right">Export</button>
        <div class="form-item search">
            <i class="fa fa-search"></i>
            <input class="custom-input" placeholder="Search" />
        </div>

        <table>
            <tr>
                <th>
                    <input type="checkbox" name="checkbox" id="checkbox1" class="css-checkbox" />
                    <label for="checkbox1" class="css-label radGroup1"></label>
                </th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Last book. ref.</th>
                <th></th>
                <th></th>
            </tr>
            <tr>
                <td>
                    <input type="checkbox" name="checkbox" id="checkbox2" class="css-checkbox" />
                    <label for="checkbox2" class="css-label radGroup1"></label>
                </td>
                <td>Lorem ipsum</td>
                <td>lor&#64;domain.com</td>
                <td>12345678</td>
                <td><span>BR10098547</span></td>
                <td><span (click)="guestPopup = true">Details</span></td>
                <td><span>Delete</span></td>
            </tr>
            <tr>
                <td>
                    <input type="checkbox" name="checkbox" id="checkbox3" class="css-checkbox" />
                    <label for="checkbox3" class="css-label radGroup1"></label>
                </td>
                <td>Lorem ipsum</td>
                <td>lor&#64;domain.com</td>
                <td>12345678</td>
                <td><span>BR10098547</span></td>
                <td><span (click)="guestPopup = true">Details</span></td>
                <td><span>Delete</span></td>
            </tr>
            <tr>
                <td>
                    <input type="checkbox" name="checkbox" id="checkbox4" class="css-checkbox" />
                    <label for="checkbox4" class="css-label radGroup1"></label>
                </td>
                <td>Lorem ipsum</td>
                <td>lor&#64;domain.com</td>
                <td>12345678</td>
                <td><span>BR10098547</span></td>
                <td><span (click)="guestPopup = true">Details</span></td>
                <td><span>Delete</span></td>
            </tr>
            <tr>
                <td>
                    <input type="checkbox" name="checkbox" id="checkbox5" class="css-checkbox" />
                    <label for="checkbox5" class="css-label radGroup1"></label>
                </td>
                <td>Lorem ipsum</td>
                <td>lor&#64;domain.com</td>
                <td>12345678</td>
                <td><span>BR10098547</span></td>
                <td><span (click)="guestPopup = true">Details</span></td>
                <td><span>Delete</span></td>
            </tr>
        </table>

        <button type="button" (click)="guestPopup = true" class="custom-button blue left">Merge Guests</button>
        <button type="button" class="custom-button blue left">Anonymize Guests</button>
    </div>

    <div *ngIf="guestPopup" class="overlay flex">
        <div class="custom-box tabs popup">
            <input type="radio" id="tab1" class="tab-input" name="tabs" checked />
            <label for="tab1" class="tab-label t1">Guest details</label>
            <input type="radio" id="tab2" class="tab-input" name="tabs" />
            <label for="tab2" class="tab-label t2">Previous bookings</label>

            <div id="details" class="form-box flex">
                <div class="left-side flex">
                    <div class="form-item short">
                        <div class="custom-label">Title</div>
                        <select class="custom-select">
                            <option value="">Mr.</option>
                            <option value="">Ms.</option>
                            <option value="">Mrs.</option>
                            <option value="">Miss</option>
                        </select>
                    </div>
                    <div class="form-item custom">
                        <div class="custom-label">First name</div>
                        <input class="custom-input" />
                    </div>
                    <div class="form-item custom">
                        <div class="custom-label">Last name</div>
                        <input class="custom-input" />
                    </div>
                    <div class="form-item half">
                        <div class="custom-label">Telephone</div>
                        <input class="custom-input" />
                    </div>
                    <div class="form-item half">
                        <div class="custom-label">Email</div>
                        <input type="email" class="custom-input" />
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Note</div>
                        <input class="custom-input" />
                    </div>
                    <div class="form-item">
                        <input type="checkbox" name="checkbox" id="checkbox6" class="css-checkbox" />
                        <label for="checkbox6" class="css-label radGroup1"></label>

                        <div class="custom-label inline">Subscribed to newsletter</div>
                    </div>
                </div>

                <div class="right-side flex">
                    <div class="form-item">
                        <div class="custom-label">Address 1</div>
                        <input class="custom-input" />
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Address 2</div>
                        <input class="custom-input" />
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Address 3</div>
                        <input class="custom-input" />
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Postcode</div>
                        <input class="custom-input" />
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Country</div>
                        <select class="custom-select">
                            <option value="">England</option>
                            <option value="">Germany</option>
                            <option value="">France</option>
                            <option value="">Spain</option>
                        </select>
                    </div>
                </div>

                <div class="button-wrapper">
                    <button type="button" (click)="guestPopup = false" class="custom-button blue left">Save</button>
                    <button type="button" (click)="guestPopup = false" class="custom-button red left">Cancel</button>
                </div>
            </div>
            <div id="bookings" class="form-box flex">
                <button type="button" (click)="guestPopup = false" class="custom-button blue left">Save</button>
                <button type="button" (click)="guestPopup = false" class="custom-button red left">Cancel</button>
            </div>
        </div>
    </div>
</div>
