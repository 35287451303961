<router-outlet (activate)="resetIdle()"></router-outlet>
<app-toaster></app-toaster>
<app-auto-logout-popup
    *ngIf="timeOutPopup"
    [(timeOutPopup)]="timeOutPopup"
    [(message)]="message"
></app-auto-logout-popup>
<app-refresh-page-popup *ngIf="refreshPagePopup" [(refreshPagePopup)]="refreshPagePopup"></app-refresh-page-popup>
<app-maintenance-mode-modal *ngIf="maintenanceMode" [(maintenanceMode)]="maintenanceMode"></app-maintenance-mode-modal>

<section class="rotate-screen" *ngIf="mobileView">
    <div><i class="fas fa-undo"></i></div>
    <div>Please rotate or expand your screen</div>
</section>
