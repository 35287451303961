import { RoomModel } from './rooms-mapping.model';
import { RoomTypeModel } from '../room-type/room-types.model';

export class RoomDetailsModel {
    public room: RoomModel;
    public pageData: RoomDetailsPDModel;
}

export class RoomDetailsPDModel {
    public status: RoomStatus[];
    public lastRoomDefault: RoomTypeModel;
}

export class RoomStatus {
    public value: string;
    public id: string;
    public name: string;
}
