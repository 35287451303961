<div class="assign-rooms-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Room details</h2>

            <form [formGroup]="valForm" (ngSubmit)="submitMapRoomDetails($event)">
                <div class="form-item">
                    <div class="custom-label">Occupancy</div>
                    <input
                        class="custom-input"
                        type="number"
                        name="occupancy"
                        min="0"
                        formControlName="occupancy"
                        [(ngModel)]="roomTypeRoomDetailsModel.roomTypeRoom.maximumOccupancy"
                    />
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['occupancy'].hasError('required') &&
                            (valForm.controls['occupancy'].dirty || valForm.controls['occupancy'].touched)
                        "
                        >Field is required.</span
                    >
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['occupancy'].hasError('min') &&
                            (valForm.controls['occupancy'].dirty || valForm.controls['occupancy'].touched)
                        "
                        >Minimum number is 0.</span
                    >
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['occupancy'].hasError('max') &&
                            (valForm.controls['occupancy'].dirty || valForm.controls['occupancy'].touched)
                        "
                        >Maximum number reached.</span
                    >
                </div>
                <div class="form-item">
                    <div class="custom-label">Children</div>
                    <input
                        class="custom-input"
                        type="number"
                        name="children"
                        min="0"
                        formControlName="children"
                        [(ngModel)]="roomTypeRoomDetailsModel.roomTypeRoom.maximumChildren"
                    />
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['children'].hasError('required') &&
                            (valForm.controls['children'].dirty || valForm.controls['children'].touched)
                        "
                        >Field is required.</span
                    >
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['children'].hasError('min') &&
                            (valForm.controls['children'].dirty || valForm.controls['children'].touched)
                        "
                        >Minimum number is 0.</span
                    >
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['children'].hasError('max') &&
                            (valForm.controls['children'].dirty || valForm.controls['children'].touched)
                        "
                        >Maximum number reached.</span
                    >
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['children'].value > valForm.controls['occupancy'].value &&
                            !valForm.controls['children'].hasError('required') &&
                            !valForm.controls['children'].hasError('min') &&
                            !valForm.controls['children'].hasError('max')
                        "
                        >This num cannot be greater than Occupancy num.</span
                    >
                </div>
                <div class="form-item">
                    <div class="custom-label">Status</div>
                    <dx-select-box
                        class="custom-select"
                        [dataSource]="roomTypeRoomDetailsModel.pageData.status"
                        displayExpr="name"
                        valueExpr="value"
                        [(value)]="roomTypeRoomDetailsModel.roomTypeRoom.status"
                        name="status"
                        formControlName="status"
                    >
                    </dx-select-box>
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['status'].hasError('required') &&
                            (valForm.controls['status'].dirty || valForm.controls['status'].touched)
                        "
                        >Field is required.</span
                    >
                </div>

                <button *ngIf="!loader" type="submit" class="custom-button blue left">Save</button>
                <div *ngIf="loader" class="loader left"></div>
                <button type="button" (click)="close()" class="custom-button red left">Cancel</button>
            </form>
        </div>
    </div>
</div>
