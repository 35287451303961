import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RatePlanRoomTypeModel } from '../../../../../models/rate-plan/rate-plan-room-type.model';
import { PricingPlanService } from '../pricing-plan.service';
import { CurrencyContext } from '../../../../../models/auth-jwt/token-context';
import { AuthTokenService } from '../../../../../shared/services/auth-token.service';
import { PPRoomTypeModel } from '../../../../../models/price-plan/price-plan-page.model';
import { RatePlanWithDerivedPricingModel } from '../../../../../models/rate-plan/rate-plan-with-derived-pricing.model';
import { PricePlanPermissionsModel } from '../../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../../enums/permissions.enum';
import { RoomTypeBaseModel } from 'src/app/models/room-type/room-type-base.model';
@Component({
    selector: 'app-occupancy-rules',
    templateUrl: './occupancy-rules.component.html',
    styleUrls: ['./occupancy-rules.component.less'],
})
export class OccupancyRulesComponent implements OnInit {
    @Input() occupancyRules: boolean;
    @Output() occupancyRulesChange = new EventEmitter<boolean>();
    @Input() ratePlan: RatePlanWithDerivedPricingModel;
    @Input() roomTypeId: number;
    @Input() roomTypesSource: PPRoomTypeModel[];
    @Output() update = new EventEmitter();
    permissionsModel: PricePlanPermissionsModel;
    loader = false;
    lessThan = true;
    theme: number;
    valForm: FormGroup;
    ratePlanRoomTypeModel = new RatePlanRoomTypeModel();
    currencyContext: CurrencyContext = new CurrencyContext();
    roomTypes: PPRoomTypeModel[] = [];
    wasUpdated: boolean;

    selectedRoomType: PPRoomTypeModel;
    warningChildren: boolean;
    warningAdults: boolean;
    warningOccupancy: boolean;
    constructor(
        private fb: FormBuilder,
        private pricePlanService: PricingPlanService,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = permissionsService.createPermissionsModel(PermissionsEnum.PricePlans);
        this.valForm = this.fb.group({
            included: [null, Validators.compose([Validators.required, Validators.min(0)])],
            additional: [null, Validators.compose([Validators.required, Validators.min(0)])],
            'additional-children': [null, Validators.compose([Validators.required, Validators.min(0)])],
            'children-rate': [null, Validators.compose([Validators.min(0)])],
            'more-less': [null, Validators.min(0)],
            'lead-time': [null, Validators.min(0)],
            change: [null],
        });
    }
    ngOnInit() {
        this.currencyContext = AuthTokenService.decodeJwtToken().Currency;
        this.roomTypes = JSON.parse(JSON.stringify(this.roomTypesSource));

        // this.roomTypes.push(new PPRoomTypeModel(-1, 'All'));
        this.ratePlanRoomTypeModel.ratePlanId = this.ratePlan.id;

        if (this.roomTypeId !== -1) {
            this.selectedRoomType = this.roomTypes.find(x => x.roomType.id == this.roomTypeId);
        }
        this.refreshView();
    }
    refreshView() {
        this.ratePlanRoomTypeModel.roomTypeId = this.roomTypeId;
        
        this.pricePlanService.getDetailsDynamicPricing(this.ratePlan.id, this.roomTypeId).subscribe(
            (response) => {
                this.ratePlanRoomTypeModel = response;
                if (this.ratePlanRoomTypeModel.dpLessThan === null) {
                    this.ratePlanRoomTypeModel.dpLessThan = 0;
                }
                if (this.ratePlanRoomTypeModel.dpDaysFrom === null) {
                    this.ratePlanRoomTypeModel.dpDaysFrom = 0;
                }
                if (this.ratePlanRoomTypeModel.dpIncreaseFor === null) {
                    this.ratePlanRoomTypeModel.dpIncreaseFor = 0;
                }
                this.ratePlanRoomTypeModel.dpLessThanHelper = this.ratePlanRoomTypeModel.dpLessThan;
                if (this.ratePlanRoomTypeModel.dpLessThanHelper < 0) {
                    this.lessThan = true;
                    this.ratePlanRoomTypeModel.dpLessThanHelper = Math.abs(this.ratePlanRoomTypeModel.dpLessThanHelper);
                } else {
                    this.lessThan = false;
                }
                if (this.ratePlanRoomTypeModel.ratePlanId === null) {
                    this.ratePlanRoomTypeModel.ratePlanId = this.ratePlan.id;
                }
                if (this.ratePlanRoomTypeModel.roomTypeId === null) {
                    this.ratePlanRoomTypeModel.roomTypeId = this.roomTypeId;
                }
                this.validateOccupancy();
            },
            (error) => {
                console.log(error);
            }
        );
    }
    filterByRoomType(e) {
        if (e.itemData !== null) {
            if (this.roomTypeId !== -1) {
                this.selectedRoomType = this.roomTypes.find(x => x.roomType.id == this.roomTypeId);
                this.refreshView();
            } else {
                this.ratePlanRoomTypeModel.ratePlanId = this.ratePlan.id;
                this.ratePlanRoomTypeModel.roomTypeId = this.roomTypeId;
                this.ratePlanRoomTypeModel.baseGuests = 0;
                this.ratePlanRoomTypeModel.additionalGuests = 0;
                this.ratePlanRoomTypeModel.additionalChildren = 0;
                this.ratePlanRoomTypeModel.childrenRate = 0;
                this.ratePlanRoomTypeModel.dpLessThan = 0;
                this.ratePlanRoomTypeModel.dpLessThanHelper = 0;
                this.ratePlanRoomTypeModel.dpDaysFrom = 0;
                this.ratePlanRoomTypeModel.dpIncreaseFor = 0;
            }
        }
    }
    submitForms(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        if (this.valForm.valid) {
            this.loader = true;
            if (this.lessThan) {
                this.ratePlanRoomTypeModel.dpLessThan = -1 * this.ratePlanRoomTypeModel.dpLessThanHelper;
            } else {
                this.ratePlanRoomTypeModel.dpLessThan = this.ratePlanRoomTypeModel.dpLessThanHelper;
            }
            this.pricePlanService.insertDynamicPricing(this.ratePlanRoomTypeModel).subscribe(
                (response) => {
                    this.wasUpdated = true;
                    this.loader = false;
                },
                (error) => {
                    this.loader = false;
                }
            );
        }
    }
    validateOccupancy() {
        
        this.warningChildren = false;
        this.warningAdults= false;
        this.warningOccupancy = false;
        return; //no validation currently
        let rpAdults = 0;
        let rpChildren = 0;
        if (this.ratePlanRoomTypeModel.baseGuests != null) {
            rpAdults = rpAdults + this.ratePlanRoomTypeModel.baseGuests;
        }
        if (this.ratePlanRoomTypeModel.additionalGuests != null) {
            rpAdults = rpAdults + this.ratePlanRoomTypeModel.additionalGuests;
        }
        if (this.ratePlanRoomTypeModel.additionalChildren != null) {
            rpChildren = rpChildren + this.ratePlanRoomTypeModel.additionalChildren;
        }

        if (this.selectedRoomType != null)
        {
            if (this.selectedRoomType.roomType.childrenTakeAvailability) {
                if (this.selectedRoomType.roomType.maximumChildren < rpChildren) {
                    this.warningChildren = true;
                    return;
                }
                if (this.selectedRoomType.roomType.maximumOccupancy < (rpAdults + rpChildren)) {
                    this.warningOccupancy = true;
                    return;
                }
            }
            if (this.selectedRoomType.roomType.maximumOccupancy < rpAdults) {
                this.warningAdults = true;
                return;
            }
        }

    }
    close() {
        if (this.wasUpdated) {
            this.update.emit();
        }
        console.log('value of occupancyRules=>' + this.occupancyRules);
        this.occupancyRulesChange.emit(false);
    }
}

