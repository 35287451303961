import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RatePlanService } from '../../rate-plan.service';
import {
    CheckedSaleItemModel,
    CheckedSaleItemPDModel,
    UpdateRatePlanSaleItemPDModel,
} from '../../../../../../models/rate-plan/checked-sale-item-pd.model';
import { CurrencyContext } from '../../../../../../models/auth-jwt/token-context';
import { AuthTokenService } from '../../../../../../shared/services/auth-token.service';
import { InventoryRoomTypesPermissionsModel } from '../../../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../../../enums/permissions.enum';

@Component({
    selector: 'app-sale-items-popup',
    templateUrl: './sale-items-popup.component.html',
    styleUrls: ['./sale-items-popup.component.less'],
})
export class SaleItemsPopupComponent implements OnInit {
    @Input() saleItemsPopup: boolean;
    @Output() saleItemsPopupChange = new EventEmitter<boolean>();
    @Input() ratePlanId: number;
    @Input() ratePlanName: number;

    loader = false;
    theme: number;

    checkedSaleItemPDModel = new CheckedSaleItemPDModel();
    updateRatePlanSaleItemPDModel = new UpdateRatePlanSaleItemPDModel();
    currencyContext: CurrencyContext = new CurrencyContext();
    permissionsModel: InventoryRoomTypesPermissionsModel;

    constructor(private ratePlanService: RatePlanService, private permissionsService: PermissionsService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryRoomTypes);
    }

    ngOnInit() {
        this.currencyContext = AuthTokenService.decodeJwtToken().Currency;

        this.ratePlanService.getCheckedSaleItemList(this.ratePlanId).subscribe(
            (response) => {
                this.checkedSaleItemPDModel = response;
                this.checkedSaleItemPDModel.list.forEach((item) => {
                    item.saleItems.forEach((itemList) => {
                        itemList.oldPrice = itemList.price;
                    });
                });
            },
            (error) => console.log(error)
        );
    }

    saveSaleItems() {
        const saleItemsList: CheckedSaleItemModel[] = [];
        for (const item of this.checkedSaleItemPDModel.list) {
            for (const itemSale of item.saleItems) {
                if (itemSale.checked) {
                    saleItemsList.push(itemSale);
                }
            }
        }

        this.updateRatePlanSaleItemPDModel.ratePlanId = this.ratePlanId;
        this.updateRatePlanSaleItemPDModel.list = saleItemsList;

        this.loader = true;
        this.ratePlanService.updateRatePlanSaleItems(this.updateRatePlanSaleItemPDModel).subscribe(
            (response) => {
                this.loader = false;
                this.close();
            },
            (error) => {
                console.log(error);
                this.loader = false;
            }
        );
    }

    close() {
        this.saleItemsPopupChange.emit(false);
    }
}
