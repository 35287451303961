import DataSource from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular';

export class DxGridHelper {
    static ConfigureGrid(
        dataGrid: DxDataGridComponent,
        gridRequest: GridRequest,
        gridConfiguration: GridConfiguration,
        component: any = null
    ) {
        gridConfiguration.dataGrid = dataGrid;
        dataGrid.remoteOperations = gridConfiguration.remoteOperations;
        // dataGrid.dataSource = gridConfiguration.rowsDataSource;
        dataGrid.columns = gridConfiguration.columns;
        dataGrid.allowColumnResizing = gridConfiguration.columnResizingMode;
        dataGrid.paging = gridConfiguration.paging;
        dataGrid.pager = gridConfiguration.pager;
        dataGrid.selection = gridConfiguration.selection;
        dataGrid.filterRow = gridConfiguration.filterRow;
        dataGrid.sorting = gridConfiguration.sorting;
        dataGrid.searchPanel = gridConfiguration.searchPanel;

        dataGrid.dataSource = new DataSource({
            load: function (loadOptions) {
                gridConfiguration.mapGridRequest(gridRequest, loadOptions);

                return gridConfiguration.service
                    .getData(gridRequest)
                    .toPromise()
                    .then((response: GridResponse) => {
                        if (component && component.onDataLoaded) {
                            component.onDataLoaded(response);
                        }

                        return {
                            data: response.rows,
                            totalCount: response.totalCount,
                        };
                    })
                    .catch((error) => {
                        throw new Error(error);
                    });
            },
        });
    }
}

export class GridRequest {
    options: any;
}

export class GridResponse {
    rows: GridElementModel[];
    totalCount: number;
}

export class GridConfiguration {
    customSearch: [
        {
            fieldName: string;
            value: string;
        }
    ];

    service: any;

    //   remoteOperations = `{
    //     filtering: true,
    //     sorting: true,
    //     paging: true,
    //     grouping: true,
    //     summary: true
    //   }`;

    remoteOperations = true;

    // allowColumnResizing = false;
    columnResizingMode = false;

    paging = {
        enabled: true,
        pageIndex: 0,
        pageSize: 10,
    };

    pager = {
        showPageSizeSelector: true,
        showNavigationButtons: true,
        allowedPageSizes: [5, 10],
        pageSize: 10,
        showInfo: false,
    };

    selection = {
        mode: 'single',
        // showCheckBoxesMode : 'always',
        allowSelectAll: false,
        selectAllMode: 'page',
    };

    sorting = {
        mode: 'single', // or 'multiple'
    };

    filterRow: any; // { visible: true };

    searchPanel: any; // { visible: true },

    columns: any;
    dataGrid: DxDataGridComponent;
    rowsDataSource: [any];

    mapGridRequest(gridRequest: GridRequest, loadOptions: any) {
        if (!gridRequest.options) {
            gridRequest.options = {};
        }

        gridRequest.options.filter = loadOptions.filter;
        gridRequest.options.group = loadOptions.group;
        gridRequest.options.requireTotalCount = loadOptions.requireTotalCount;
        gridRequest.options.searchExpr = loadOptions.searchExpr;
        gridRequest.options.searchOperation = loadOptions.searchOperation;
        gridRequest.options.skip = loadOptions.skip;
        gridRequest.options.sort = loadOptions.sort;
        gridRequest.options.take = loadOptions.take;
    }
    reloadGrid() {
        this.dataGrid.instance.getDataSource().reload();
    }

    getSelected() {
        return this.dataGrid.instance.getSelectedRowsData();
    }
}
export class GridElementModel {
    isSelected: boolean;
}
