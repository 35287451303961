export class RoomTypeBaseModel {
    public id: number;
    public propertyId: number;
    public name: string;
    public shortName: string;
    public maximumOccupancy: number;
    public maximumChildren: number;
    public childrenTakeAvailability: boolean;
    public class: string;
    public beddingArangement: string;
    public description: string;
    public otaTypeId: number;
    public status: string;
    public rackRate: number;

    // added new properties
    public masterRoomTypeId: number;
    public masterRoomTypeName: string;
    constructor(id?: number, name?: string) {
        this.id = id;
        this.name = name;
    }
}
