import { RoomTypeBaseModel } from '../room-type/room-type-base.model';
import { RatePlanDerivedPricingModel } from './rate-plan-derived-pricing.model';
import { EnumModel } from '../shared/enum-model';
import { RatePlanModel } from './rate-plan.model';

export class DerivedPricingPopupModel {
    public options: EnumModelDerive[];
    public ratePlans: RatePlanModel[];
    public roomTypes: RoomTypeBaseModel[];
    public ratePlan: RatePlanModel;
    public offsetTypes: EnumModel[];
    public derivedPricesDetails: RatePlanDerivedPricingModel[];
}

export class EnumModelDerive extends EnumModel {
    public selected = false;
}
