import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SignupService } from '../../signup.service';
import { VirtualRoomDetailsModel } from '../../../../models/room/virtual-room-details.model';
import { VirtualRoomModel } from '../../../../models/room/virtual-room.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { InventoryAssignRoomsPermissionsModel } from '../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../enums/permissions.enum';
import { DxSelectBoxComponent } from 'devextreme-angular';

@Component({
    selector: 'app-virtual-room',
    templateUrl: './virtual-room.component.html',
    styleUrls: ['./virtual-room.component.less'],
})
export class VirtualRoomComponent implements OnInit {
    @ViewChild('selectBox') selectBox: DxSelectBoxComponent;
    @Input() virtualRoom: boolean;
    @Output() virtualRoomChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();
    @Input() roomId: number;

    addVirtualRoom = false;
    editVirtualRoom = false;
    isChecked = true;
    loader = false;

    activeTab = 0;
    theme: number;

    valForm: UntypedFormGroup;
    virtualRoomDetailsModel = new VirtualRoomDetailsModel();
    virtualRoomModel: VirtualRoomModel;
    selectedRooms: number[] = [];

    permissionsModel: InventoryAssignRoomsPermissionsModel;

    constructor(
        private signUpService: SignupService,
        private fb: UntypedFormBuilder,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryAssignRooms);

        this.valForm = this.fb.group({
            fullName: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            shortName: [null, Validators.compose([Validators.required, Validators.maxLength(10)])],
            customTag: [null, Validators.compose([Validators.maxLength(50)])],
            status: [null, Validators.required],
            lastRoomType: [null],
            rooms: [null, Validators.required],
        });
    }

    ngOnInit() {
        if (this.roomId === null) {
            this.editVirtualRoom = false;
            this.addVirtualRoom = true;
        } else {
            this.editVirtualRoom = true;
            this.addVirtualRoom = false;
        }

        this.signUpService.getVirtualRooms(this.roomId).subscribe(
            (response) => {
                this.virtualRoomDetailsModel = response;
                if (this.virtualRoomDetailsModel.selectedRoom !== null) {
                    this.virtualRoomModel = this.virtualRoomDetailsModel.selectedRoom;
                    this.selectedRooms = this.virtualRoomModel.virtualRooms.map((vr) => vr.id);
                    if (this.virtualRoomDetailsModel.selectedRoom.lastRoomTypeId === 0) {
                        this.virtualRoomDetailsModel.selectedRoom.lastRoomTypeId = -1;
                    }
                    this.valForm.controls['status'].setValue(this.virtualRoomModel.status);
                    this.valForm.controls['rooms'].setValue(this.selectedRooms);
                    this.valForm.controls['lastRoomType'].setValue(this.virtualRoomModel.lastRoomTypeId);
                } else {
                    this.virtualRoomModel = new VirtualRoomModel();
                }
            },
            (error) => console.log(error)
        );
    }

    openEditVirtualRoom(id) {
        this.activeTab = 0;
        this.editVirtualRoom = true;
        this.addVirtualRoom = false;

        const idRoom = id;

        this.signUpService.getVirtualRooms(idRoom).subscribe(
            (response) => {
                this.virtualRoomDetailsModel = response;
                if (this.virtualRoomDetailsModel.selectedRoom !== null) {
                    this.selectedRooms = [];
                    this.virtualRoomModel = this.virtualRoomDetailsModel.selectedRoom;
                    this.selectedRooms = this.virtualRoomModel.virtualRooms.map((vr) => vr.id);
                    if (this.virtualRoomDetailsModel.selectedRoom.lastRoomTypeId === 0) {
                        this.virtualRoomDetailsModel.selectedRoom.lastRoomTypeId = -1;
                    }
                    this.valForm.controls['status'].setValue(this.virtualRoomModel.status);
                    this.valForm.controls['rooms'].setValue(this.selectedRooms);
                    this.valForm.controls['lastRoomType'].setValue(this.virtualRoomModel.lastRoomTypeId);
                } else {
                    this.virtualRoomModel = new VirtualRoomModel();
                }
            },
            (error) => console.log(error)
        );
    }

    openAddVirtualRoom() {
        this.activeTab = 0;
        this.isChecked = true;
        this.editVirtualRoom = false;
        this.addVirtualRoom = true;

        this.virtualRoomModel = new VirtualRoomModel();
        this.selectedRooms = [];
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].reset();
        }
    }

    submitVirtualRoom(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            this.loader = true;
            if (this.addVirtualRoom) {
                this.addRoom();
            } else if (this.editVirtualRoom) {
                this.editRoom();
            }
        }
    }

    addRoom() {
        this.virtualRoomModel.propertyId = AuthTokenService.decodeJwtToken().Property.Id;
        this.virtualRoomModel.virtualRooms = this.selectedRooms.map((sr) =>
            this.virtualRoomDetailsModel.pageData.allRooms.find((vr) => vr.id === sr)
        );
        this.signUpService.createVirtualRoom(this.virtualRoomModel).subscribe(
            (response) => {
                this.loader = false;
                this.update.emit();
                this.close();
            },
            (error) => {
                this.loader = false;
                console.log(error);
            }
        );
    }

    editRoom() {
        this.virtualRoomModel.virtualRooms = this.selectedRooms.map((sr) =>
            this.virtualRoomDetailsModel.pageData.allRooms.find((vr) => vr.id === sr)
        );
        this.signUpService.updateVirtualRoom(this.virtualRoomModel).subscribe(
            (response) => {
                this.loader = false;
                this.update.emit();
                this.close();
            },
            (error) => {
                this.loader = false;
                console.log(error);
            }
        );
    }

    changeTab(selectedTab) {
        this.activeTab = selectedTab;

        if (selectedTab === 0) {
            this.isChecked = true;
        }
    }

    close() {
        this.virtualRoomChange.emit(false);
    }
}
