import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserLoginModel } from '../../models/user/user-login.model';
import { TokenContext } from '../../models/auth-jwt/token-context';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Idle } from '@ng-idle/core';
import { UserStatus } from '../../enums/user.enum';
import { CommunicationService } from './communication.service';
import { ToastService } from './toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';

@Injectable()
export class AuthTokenService {
    public token: string;
    private maintenanceMode: number;
    private userLoginURL = environment.apiUrl + '/User/Login';
    private loginAdminUrl = environment.apiUrl + '/User/LoginAdmin';
    private getNotificationsOnLoginUrl = environment.apiUrl + '/User/GetNotificationsOnLogin';

    static getToken() {
        if (sessionStorage.getItem('HotelBooking-Token') === null) {
            return localStorage.getItem('HotelBooking-Token');
        } else {
            return sessionStorage.getItem('HotelBooking-Token');
        }
    }

    static getParsedToken(): string {
        if (sessionStorage.getItem('HotelBooking-Token') !== null) {
            return JSON.parse(sessionStorage.getItem('HotelBooking-Token')).token;
        } else if (localStorage.getItem('HotelBooking-Token') !== null) {
            return JSON.parse(localStorage.getItem('HotelBooking-Token')).token;
        }
    }

    public static decodeJwtToken(): TokenContext {
        let tokenContext: TokenContext = new TokenContext();
        const jwtHelper: JwtHelperService = new JwtHelperService();

        const token: string = AuthTokenService.getParsedToken();
        if (token) {
            tokenContext = jwtHelper.decodeToken(token);
            return tokenContext;
        } else {
            return null;
        }
    }

    constructor(
        private idle: Idle,
        private http: HttpClient,
        private toasterService: ToastService,
        private communicationService: CommunicationService
    ) {
        this.maintenanceMode = 0;
    }

    private handleError(error: any) {
        let errMsg: string;
        if (error.error.message) {
            errMsg = error.error.message;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }

        this.toasterService.showToast('Error', errMsg, null, ToastEventType.Error, true, 5000);

        this.checkMaintenanceMode(error);

        return observableThrowError({ status: error.status, message: errMsg });
    }

    public checkMaintenanceMode(response: any) {
        if (response && response.headers && response.headers.has('Maintenance-Mode')) {
            const currentMaintenanceMode: number = +response.headers.get('Maintenance-Mode');

            if (this.maintenanceMode === 0 && currentMaintenanceMode === 1) {
                this.maintenanceMode = currentMaintenanceMode;
                this.communicationService.maintenanceMode.next(true);
                return;
            }

            if (this.maintenanceMode === 1 && currentMaintenanceMode === 0) {
                this.maintenanceMode = currentMaintenanceMode;
                this.communicationService.maintenanceMode.next(false);
                return;
            }

            return;
        }

        if (this.maintenanceMode === 1) {
            this.maintenanceMode = 0;
            this.communicationService.maintenanceMode.next(false);
            return;
        }
    }

    login(user: UserLoginModel, rememberMe: boolean) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.http.post(this.userLoginURL, user, { headers }).pipe(
            map((response: any) => {
                this.checkMaintenanceMode(response);
                this.insertTokenLogin(response, rememberMe);

                try {
                    return response.user.isAccountActive;
                } catch (e) {
                    return false;
                }
            }),
            catchError((response) => this.handleError(response))
        );
    }

    loginAdminAsEndUser(userId: number, token: string) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.http.get(this.loginAdminUrl + '?userId=' + userId + '&token=' + token, { headers }).pipe(
            map((response: Response) => {
                this.checkMaintenanceMode(response);
                this.insertTokenLogin(response, false);
            }),
            catchError((response) => this.handleError(response))
        );
    }

    insertTokenLogin(response: any, rememberMe: boolean) {
        const token = response.token;
        const user = response.user;
        if (!user.isAccountActive) {
            return;
        }
        if (token) {
            this.token = token;

            if (rememberMe) {
                localStorage.setItem('HotelBooking-Token', JSON.stringify({ token: token }));
                localStorage.setItem('User', JSON.stringify({ user: user }));
            } else {
                sessionStorage.setItem('HotelBooking-Token', JSON.stringify({ token: token }));
                sessionStorage.setItem('User', JSON.stringify({ user: user }));
            }

            return response;
        } else {
            return false;
        }
    }

    insertTokenSetNewPassword(response: any, rememberMe: boolean) {
        const token = response.token;
        const user = response.user;
        if (token) {
            this.token = token;

            if (rememberMe) {
                localStorage.setItem('HotelBooking-Token', JSON.stringify({ token: token }));
                localStorage.setItem('User', JSON.stringify({ user: user }));
            } else {
                sessionStorage.setItem('HotelBooking-Token', JSON.stringify({ token: token }));
                sessionStorage.setItem('User', JSON.stringify({ user: user }));
            }

            return response;
        } else {
            return false;
        }
    }

    insertTokenAfterSettingRooms(response: any) {
        const token = response.token;
        const user = response.user;
        if (token) {
            this.token = token;

            if (sessionStorage.getItem('User') === null) {
                localStorage.setItem('HotelBooking-Token', JSON.stringify({ token: token }));
                localStorage.setItem('User', JSON.stringify({ user: user }));
            } else {
                sessionStorage.setItem('HotelBooking-Token', JSON.stringify({ token: token }));
                sessionStorage.setItem('User', JSON.stringify({ user: user }));
            }

            return response;
        } else {
            return false;
        }
    }

    refreshToken(token: string) {
        if (token) {
            this.token = null;
            this.token = token;

            if (sessionStorage.getItem('HotelBooking-Token') === null) {
                localStorage.removeItem('HotelBooking-Token');
                localStorage.setItem('HotelBooking-Token', JSON.stringify({ token: token }));
            } else {
                sessionStorage.removeItem('HotelBooking-Token');
                sessionStorage.setItem('HotelBooking-Token', JSON.stringify({ token: token }));
            }
        }
    }

    isAuthenticated() {
        const promise = new Promise((resolve, reject) => {
            resolve(AuthTokenService.getToken());
        });
        return promise;
    }

    getDecodedTokenPromise() {
        const promise = new Promise((resolve, reject) => {
            resolve(AuthTokenService.decodeJwtToken());
        });
        return promise;
    }

    isSuperAdmin() {
        const promise = new Promise((resolve, reject) => {
            resolve(AuthTokenService.decodeJwtToken().IsSuperUser);
        });
        return promise;
    }

    getUserStatus() {
        let status: UserStatus;
        if (AuthTokenService.decodeJwtToken().IsSuperUser) {
            status = AuthTokenService.decodeJwtToken().AdminUser.Status;
        } else {
            status = AuthTokenService.decodeJwtToken().User.Status;
        }
        const promise = new Promise((resolve, reject) => {
            resolve(status);
        });
        return promise;
    }

    getRoleName() {
        let role: string;
        if (AuthTokenService.decodeJwtToken().IsSuperUser) {
            role = AuthTokenService.decodeJwtToken().AdminUser.Role;
        } else {
            role = AuthTokenService.decodeJwtToken().User.Role.Name;
        }
        const promise = new Promise((resolve, reject) => {
            resolve(role);
        });
        return promise;
    }

    logout() {
        this.token = null;

        localStorage.removeItem('HotelBooking-Token');
        sessionStorage.removeItem('HotelBooking-Token');

        localStorage.removeItem('User');
        sessionStorage.removeItem('User');

        document.cookie = 'dateFrom=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';

        this.idle.stop();
    }

    getNotificationsOnLogin() {
        return this.http.get(this.getNotificationsOnLoginUrl).pipe(
            map((response) => {
                this.checkMaintenanceMode(response);
                return response;
            }),
            catchError((error) => this.handleError(error))
        );
    }
}
