export class RatePlanCostCentreFeeManageModel {
    public costCentreRatePlanFee: Array<RatePlanCostCentreFeeModel> = [];
    public costCentreRatePlanFeeToDelete: Array<CostCentreRatePlanFeeToDeleteModel> = [];
    public ratePlanId: number;
}

export class RatePlanCostCentreFeeModel {
    public ratePlanId: number;
    public costCentreId: number;
    public chargeType: string;
    public feeAmount: number;
    public active: boolean;

    // added new properties

    constructor() {
        this.costCentreId = null;
        this.chargeType = null;
        this.feeAmount = null;
    }
}

export class CostCentreRatePlanFeeToDeleteModel {
    public costCentreId: number;
    public ratePlanId: number;

    constructor(costCentreId, ratePlanId) {
        this.costCentreId = costCentreId;
        this.ratePlanId = ratePlanId;
    }
}
