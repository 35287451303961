import { ReportingEPOSCategoryGroupModel, ReportingEPOSConfigurationModel } from "../epos/epos-category-group.model";
import { PropertyLogModel } from "../property/property-log.model";
import { PropertySettingModel } from "../property/property-setting.model";
import { PropertyVatBaseModel } from "../property/property-vat-base.model";
import { EnumModel } from "../shared/enum-model";
import { XeroAccountModel } from "./xero-account.model";
import { XeroMappingModel } from "./xero-mapping.model";
import { XeroTaxRateModel } from "./xero-tax-rate.model";
import { XeroTrackingCategoryModel } from "./xero-tracking-category.model";

export class XeroConfigurationModel
{
    public xeroAuthorisationStatus: string;
    public xeroMappingValidation: XeroMappingValidationResult;
    public xeroSettingsStatus: string;
    public xeroAccounts: XeroAccountModel[];
    public xeroTrackingCategories: XeroTrackingCategoryModel[];
    public xeroMappings: XeroMappingModel[];
    public xeroTaxRates: XeroTaxRateModel[];
    public xeroSettings: PropertySettingModel[];
    public xeroLogs: PropertyLogModel[];
    public xeroAccountsWithIgnore: XeroAccountModel[];
}

export class XeroMappingValidationResult
{
    public status: string;
    public statusText: string;
    public issues: XeroMappingIssue[];
    public affectedMappings: XeroMappingModel[];
}

export class XeroMappingIssue {
    issueDescription: string;
    issueType: number;
    issueParagraph: number;
}