<div class="pricing-plans-wrapper flex">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <h2>Availability</h2>

        <div class="inventory-options flex">
            <div class="left">
                <div class="inputs flex">
                    <div class="form-item search left">
                        <i class="fa fa-search"></i>
                        <input class="custom-input" placeholder="Search" [(ngModel)]="searchFilter" />
                    </div>
                    <div class="form-item select-wrapper">
                        <dx-select-box
                            class="custom-select"
                            [dataSource]="activeRateSchedules"
                            valueExpr="id"
                            [(value)]="availabilityRequestModel.rateScheduleId"
                            displayExpr="name"
                            (onItemClick)="filterByRateSchedule($event)"
                        ></dx-select-box>
                    </div>
                </div>

                <div class="legend flex">
                    <div class="l-closed flex">
                        <div class="circle"></div>
                        <span>Closed</span>
                    </div>
                    <div class="l-unavailable flex">
                        <div class="circle"></div>
                        <span>Unavailable</span>
                    </div>
                    <div class="l-enquiry flex">
                        <div class="circle"></div>
                        <span>Enquiry Only</span>
                    </div>
                    <div class="l-arrival flex">
                        <div class="circle"></div>
                        <span>Closed to Arrival</span>
                    </div>
                    <div class="l-departure flex">
                        <div class="circle"></div>
                        <span>Closed to Departure</span>
                    </div>
                    <div class="l-function-unavailable flex">
                        <div class="circle"></div>
                        <span>Function room unavailable</span>
                    </div>
                </div>
            </div>

            <div class="month-wrapper right flex">
                <button type="button" (click)="setDateToToday()" class="custom-button btnRightDateToday">Today</button>
                <div *ngIf="!prevLoader" class="prev" title="Previous two weeks" (click)="getPreviousTwoWeeks()">
                    <i class="fa fa-angle-left"></i>
                </div>
                <div *ngIf="prevLoader" class="loader"></div>
                <div class="month">{{ currentDateRange }}</div>
                <div *ngIf="!nextLoader" class="next" title="Next two weeks" (click)="getNextTwoWeeks(true)">
                    <i class="fa fa-angle-right"></i>
                </div>
                <div *ngIf="nextLoader" class="loader"></div>
                <div>
                    <dx-date-box
                        class="header-date-box"
                        type="date"
                        [displayFormat]="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        (onValueChanged)="datePickerFunction($event)"
                    >
                    </dx-date-box>
                </div>
            </div>
        </div>

        <div class="days-heading flex">
            <div class="left-part">
                <div class="header"></div>
            </div>

            <div class="right-part">
                <div class="header flex">
                    <div class="h-item" *ngFor="let date of datesArray" title="{{ date | date: 'dd MMM yyyy' }}">
                        {{ date | date: 'EEE dd' }}
                    </div>
                </div>
            </div>
        </div>

        <div class="room-wrapper">
        <div class="plans-wrapper">
            <div
                class="plan-item"
                *ngFor="
                    let roomType of availabilityGridPdModel.availabilityList.roomTypes | searchPipe: searchFilter;
                    let indexRoomType = index
                "
            >
                <div class="left-part left">
                    <div class="room-row flex">
                        <div (click)="editRoom(roomType.roomTypeId)" class="name">
                            <i [ngClass]="[theme == 2 ? 'dark-theme' : '']" class="fa-solid fa-link fa-icon fa-icon-xsmall blue link-icon"></i>
                            {{ roomType.roomTypeShortName }}
                        </div>
                    </div>
                </div>
                <div class="right-part right">
                    <div class="month-row flex">
                        <div
                            class="price-item flex"
                            *ngFor="
                                let availability of roomType.roomTypeAvailabilty
                                    | slice: 0:roomType.roomTypeAvailabilty.length - 1;
                                let indexAvailability = index
                            "
                            id="link{{ indexRoomType }}{{ indexAvailability }}"
                            (mouseenter)="showPopup($event, availability)"
                            (mouseleave)="hidePopup(availability)"
                            [ngClass]="[statusOfRoom(availability), weekendClass(availability)]"
                            (click)="openInventoryDetails(roomType.roomTypeId, availability.date)"
                        >
                            <div
                                class="img-actions"
                                *ngIf="availability.show"
                                [style.top.px]="top"
                                [style.left.px]="left"
                            >
                                <div class="flex header">
                                    <div class="type bold">
                                        {{ rateScheduleBaseModel.name }} - {{ roomType.roomTypeName }}
                                    </div>
                                    <div class="date">{{ availability.date | date: 'dd/MM/yyyy' }}</div>
                                </div>
                                <div class="caret"></div>
                                <div class="rate">
                                    Status = <span class="bold">{{ availability.status }}</span>
                                </div>
                            </div>

                            <div class="available" title="{{ availability.status | camelCaseSeparate }}">
                                {{ availability.available }}
                            </div>
                            <div class="booked" title="booked">{{ availability.booked }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="plan-item" *ngIf="availabilityGridPdModel.availabilityList.roomTypes.length > 0">
                <div class="left-part left">
                    <div class="room-row flex">
                        <div class="total">Actual Rooms Free</div>
                    </div>
                </div>
                <div class="right-part right">
                    <div class="month-row total flex">
                        <div
                            class="price-item"
                            *ngFor="
                                let total of availabilityGridPdModel.availabilityList.totalAvailabilty
                                    | slice: 0:availabilityGridPdModel.availabilityList.totalAvailabilty.length - 1
                            "
                        >
                            {{ total.available }}
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <div class="plans-wrapper" *ngIf="availabilityGridPdModel.availabilityList.roomTypes.length == 0">
            <p>No data</p>
        </div>

        <div class="functions-wrapper">
            <div
                class="function-item"
                *ngFor="
                    let roomType of availabilityGridFunctionPdModel.availabilityList.roomTypes | searchPipe: searchFilter;
                    let indexRoomType = index
                "
            >
                <div class="left-part left">
                    <div class="room-row flex">
                        <div (click)="editFunctionRoom(roomType.roomTypeId)" class="name" title="{{roomType.roomTypeName}}">
                            <i [ngClass]="[theme == 2 ? 'dark-theme' : '']" class="fa-solid fa-link fa-icon fa-icon-xsmall blue link-icon"></i>
                            {{ roomType.roomTypeShortName }}
                        </div>
                    </div>
                </div>
                <div class="right-part right">
                    <div class="month-row flex">
                        <div
                            class="price-item flex function-availability"
                            *ngFor="
                                let availability of roomType.roomTypeAvailabilty
                                    | slice: 0:roomType.roomTypeAvailabilty.length - 1;
                                let indexAvailability = index
                            "
                            id="function-link{{ indexRoomType }}{{ indexAvailability }}"
                            [ngClass]="[statusOfRoom(availability), weekendClass(availability)]"
                            (click)="openFunctionInventoryDetails(roomType.roomTypeId, availability.date)"
                        >

                            <div [ngClass]="[availability.available > 0 ? 'function-available' : 'function-unavailable']">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    </div>
</div>

<div class="loader" *ngIf="loader" [ngClass]="[theme == 2 ? 'dark-theme' : '']"></div>

<app-inventory-details
    *ngIf="inventoryDetails"
    [(inventoryDetails)]="inventoryDetails"
    [dateFrom]="dateForAvailabilityDetails"
    [dateTo]="dateForAvailabilityDetails"
    [roomTypeId]="roomTypeId"
    [rateScheduleId]="rateScheduleId"
    (update)="init()"
>
</app-inventory-details>

<app-inventory-function-details
    *ngIf="inventoryFunctionDetails"
    [(inventoryDetails)]="inventoryFunctionDetails"
    [dateFrom]="dateForAvailabilityDetails"
    [dateTo]="dateForAvailabilityDetails"
    [roomTypeId]="roomTypeId"
    [rateScheduleId]="rateScheduleId"
    (update)="functionAvailabilityUpdated()"
>
</app-inventory-function-details>

<!--<app-inventory-partner *ngIf="inventoryPartner" [(inventoryPartner)]="inventoryPartner" (update)="init()"></app-inventory-partner>-->

<app-room-type-edit
    *ngIf="editRoomType"
    [(editRoomType)]="editRoomType"
    [hasOrder]="false"
    [idOfRoomType]="roomTypeId"
    (update)="init()"
>
</app-room-type-edit>

<app-function-rooms-edit
    *ngIf="editFunctionRoomType"
    [hasOrder]="false"
    [editFunctionRoom]="true"
    [idOfFunctionRoom]="roomTypeId"
    (update)="functionRoomUpdated()"
></app-function-rooms-edit>
