<div class="incidental-wrapper flex">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <h2>Extras</h2>

        <dx-data-grid
            class="sale-items"
            [dataSource]="categoriesWithSaleItemsModel"
            rowTemplate="rowTemplateName"
            [columns]="[
                { dataField: 'name', caption: 'Category Item', allowSorting: true },
                { dataField: 'saleItems.chargeType.name', caption: 'Charge Type', allowSorting: false },
                { dataField: 'saleItems.notes', caption: 'Notes', allowSorting: false },
                { cellTemplate: 'edit', allowSorting: false, width: 60 },
                { cellTemplate: 'delete', allowSorting: false, width: 60 }
            ]"
        >
            <table>
                <ng-container *dxTemplate="let row of 'rowTemplateName'">
                    <tr class="table-header">
                        <td>{{ row.data.name }}</td>
                        <td></td>
                        <td></td>
                        <td>
                            <i *ngIf="permissionsModel.update" title="Edit" class="ml-10 fas fa-pen-to-square fa-icon gray fa-icon-small" (click)="openEditSaleCategory(row.data)"></i>
                        </td>
                        <td>
                            <i *ngIf="permissionsModel.delete" title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small" (click)="deleteModalCategory(row.data)"></i>
                        </td>
                    </tr>
                    <tr *ngFor="let item of row.data.saleItems; let i = index">
                        <td>{{ item.name }}</td>
                        <td>{{ item.chargeType.name }}</td>
                        <td title="{{ item.notes }}" class="notes">{{ item.notes }}</td>
                        <td>
                            <i title="{{ permissionsModel.update ? 'Edit' : 'View' }}" class="ml-10 fas fa-pen-to-square fa-icon gray fa-icon-small" (click)="openEditSaleItem(item.id)"></i>
                        </td>
                        <td>
                            <i *ngIf="permissionsModel.delete" title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small" (click)="deleteModal(item)"></i>
                        </td>
                    </tr>
                </ng-container>
            </table>

            <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="true" [showInfo]="true"> </dxo-pager>
        </dx-data-grid>

        <button
            type="button"
            (click)="openAddSaleItem()"
            class="custom-button blue left"
            *ngIf="permissionsModel.create"
        >
            Add new item
        </button>
        <button
            type="button"
            (click)="openAddSaleCategory()"
            class="custom-button blue left"
            *ngIf="permissionsModel.create"
        >
            Add new category
        </button>
    </div>
</div>

<app-delete-modal
    *ngIf="deletePopup"
    [(deleteModal)]="deletePopup"
    [message]="'Are you sure you want to delete this item?'"
    (delete)="openDeleteSaleItem()"
></app-delete-modal>
<app-delete-modal
    *ngIf="deletePopupCategory"
    [(deleteModal)]="deletePopupCategory"
    [message]="'Are you sure you want to delete this category?'"
    (delete)="openDeleteSaleItemCategory()"
></app-delete-modal>

<app-incidental-sale-category *ngIf="addCategory" [(addCategory)]="addCategory" (update)="init()">
</app-incidental-sale-category>
<app-incidental-sale-category
    *ngIf="editCategory"
    [(editCategory)]="editCategory"
    [saleItemCategoryModel]="saleItemCategoryModel"
    (update)="init()"
>
</app-incidental-sale-category>
<app-incidental-sales-item *ngIf="addItem" [(addItem)]="addItem" (update)="init()"> </app-incidental-sales-item>
<app-incidental-sales-item *ngIf="editItem" [(editItem)]="editItem" [saleItemId]="saleItemId" [saleItemCategory]="editSaleItemCategory" (update)="init()">
</app-incidental-sales-item>
