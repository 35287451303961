export class AvailabilityGridModel {
    public roomTypes: RoomTypeAvailabilityGridModel[] = [];
    public totalAvailabilty: RoomTypeDateAvailabilityGridModel[] = [];
}

export class RoomTypeAvailabilityGridModel {
    public roomTypeId: number;
    public roomTypeName: string;
    public roomTypeShortName: string;
    public roomTypeAvailabilty: RoomTypeDateAvailabilityGridModel[] = [];
}

export class RoomTypeDateAvailabilityGridModel {
    public date: Date;
    public available: number;
    public booked: number;
    public status: string;

    // added new properties
    public show = false;
}
