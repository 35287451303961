<div class="rate-plans-wrapper flex">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <h2>{{this.countryContext.VatName}} rates</h2>

        <dx-data-grid
            
            [dataSource]="vatRates"
            [allowColumnResizing]="true"
            rowTemplate="dataRowTemplateName"
            [columns]="[
                { dataField: 'name', caption: 'Name', allowSorting: true, alignment: 'left', width: '35%' },
                { dataField: 'value', caption: 'Rate', allowSorting: true, alignment: 'left', width: '20%' },
                { dataField: 'active', caption: 'Active', allowSorting: true, alignment: 'center', width: '15%' },
                { dataField: 'noVat', caption: 'No VAT', allowSorting: true, alignment: 'center', width: '15%'  },
                { cellTemplate: 'delete', allowSorting: false, width: '15%' }
            ]"
        >

        <ng-container *dxTemplate="let row of 'dataRowTemplateName'">
            <tr class="main-row">
                <td>
                    <div class="form-item fee-input select-input">
                        <input
                            [(ngModel)]="row.data.name"
                            name="vat-rate-name"
                            [ngModelOptions]="{ standalone: true }"
                            type="text"
                            class="custom-input"
                            [readOnly]="!permissionsModel.update"
                        />
                    </div>
                </td>
                <td>
                    <div class="form-item fee-input select-input">
                        <input
                            [(ngModel)]="row.data.value"
                            name="vat-rate-value"
                            [ngModelOptions]="{ standalone: true }"
                            min="0"
                            max="99"
                            type="number"
                            class="custom-input"
                            [readOnly]="!permissionsModel.update"
                        />
                    </div>
                </td>
                <td>
                    <div class="form-item fee-input">
                        <!--<dx-check-box [(value)]="row.data.active"></dx-check-box>-->
                        <input
                            type="checkbox"
                            name="vat-rate-active"
                            [(ngModel)]="row.data.active"
                            id="active{{ row.rowIndex }}"
                            class="css-checkbox"
                            [disabled]="!permissionsModel.update"
                        />
                        <label for="active{{ row.rowIndex }}" class="css-label radGroup1"></label>
                    </div>
                </td>
                <td>
                    <div class="form-item fee-input">
                        <input
                            type="checkbox"
                            name="vat-rate-no-vat"
                            [(ngModel)]="row.data.noVat"
                            id="noVat{{ row.rowIndex }}"
                            class="css-checkbox"
                            [disabled]="!permissionsModel.update"
                        />
                        <label for="noVat{{ row.rowIndex }}" class="css-label radGroup1"></label>
                    </div>
                </td>
                <td>
                    <i *ngIf="permissionsModel.update" title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small" (click)="deleteVatRate(row.data)"></i>
                </td>
            </tr>
          </ng-container>

            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager
                [showNavigationButtons]="true"
                [showPageSizeSelector]="true"
                [allowedPageSizes]="[5, 10]"
                [showInfo]="true"
            >
            </dxo-pager>
        </dx-data-grid>
        <div class="form-item">
            <span class="text-danger"> {{ errorMessage }} </span>
        </div>

        <div title="Add new" class="add-new-box" (click)="addNewVatRate()" *ngIf="permissionsModel.create">
            <div class="text">Add new</div>
            <div class="img">
                <i class="fa-solid fa-plus fa-icon gray"></i>
            </div>
        </div>

        <div class="actions actions-button flex">
            <button
                type="button"
                *ngIf="!loader && permissionsModel.update"
                (click)="saveVatRates()"
                class="custom-button blue"
            >
                Save
            </button>
            <div *ngIf="loader" class="loader"></div>
        </div>
    </div>
</div>
