import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-widget-payment-redirect',
    templateUrl: './widget-payment-redirect.component.html',
    styleUrls: ['./widget-payment-redirect.component.less'],
})
export class WidgetPaymentRedirectComponent implements OnInit {
    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        const paymentRef = this.route.snapshot.queryParams.transactionGuid;

        setTimeout((x) => {
            window.parent.postMessage({ paymentRef }, '*');
        }, 0);
    }
}
