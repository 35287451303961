<div class="function-room-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Add new Function Room</h2>

            <form [formGroup]="valForm" (ngSubmit)="submitFunctionRoom($event)">
                <ng-container *ngIf="addFunctionRoom">
                    <div class="function-room flex">
                        <div class="left-part flex">
                            <div class="form-item">
                                <div class="custom-label">Name</div>
                                <input
                                    class="custom-input"
                                    formControlName="name"
                                    name="functionRoomName"
                                    [(ngModel)]="functionRoomModel.name"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['name'].hasError('required') &&
                                        (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                                    "
                                    >Name is required.</span>
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['name'].hasError('maxlength') &&
                                        (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                                    "
                                    >Max 100 characters.</span>

                            </div>

                            <div class="form-item">
                                <div class="custom-label">Short name</div>
                                <input
                                    class="custom-input"
                                    formControlName="shortName"
                                    name="shortName"
                                    [(ngModel)]="functionRoomModel.shortName"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['shortName'].hasError('required') &&
                                        (valForm.controls['shortName'].dirty || valForm.controls['shortName'].touched)
                                    "
                                    >Short name is required.</span>
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['shortName'].hasError('maxlength') &&
                                        (valForm.controls['shortName'].dirty || valForm.controls['shortName'].touched)
                                    "
                                    >Max 10 characters.</span>
                            </div>

                            <div class="form-item w-30p">
                                <div class="custom-label">Max Attendance</div>
                                <input
                                    type="number"
                                    class="custom-input"
                                    min="1"
                                    name="maxAttendance"
                                    formControlName="maxAttendance"
                                    [(ngModel)]="functionRoomModel.maxAttendance"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['maxAttendance'].hasError('required') &&
                                        (valForm.controls['maxAttendance'].dirty ||
                                            valForm.controls['maxAttendance'].touched)
                                    "
                                    >Max attendance is required.</span>
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['maxAttendance'].hasError('min') &&
                                        (valForm.controls['maxAttendance'].dirty ||
                                            valForm.controls['maxAttendance'].touched)
                                    "
                                    >Minimum number is 1.</span>

                            </div>

                            <div class="form-item w-30p">
                                <div class="custom-label">Display order</div>
                                <input
                                    type="number"
                                    class="custom-input"
                                    min="1"
                                    name="displayOrder"
                                    formControlName="displayOrder"
                                    [(ngModel)]="functionRoomModel.displayOrder"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['displayOrder'].hasError('required') &&
                                        (valForm.controls['displayOrder'].dirty ||
                                            valForm.controls['displayOrder'].touched)
                                    "
                                    >Display order is required.</span>
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['displayOrder'].hasError('min') &&
                                        (valForm.controls['displayOrder'].dirty ||
                                            valForm.controls['displayOrder'].touched)
                                    "
                                    >Minimum number is 1.</span>
                                <span class="text-danger" *ngIf="!validateDisplayOrder()">Order is occupied.</span>
                            </div>

                            <div class="form-item w-30p">
                                <div class="custom-label">Base Price</div>
                                <input
                                    type="number"
                                    class="custom-input"
                                    min="0"
                                    name="basePrice"
                                    formControlName="basePrice"
                                    [(ngModel)]="functionRoomModel.basePrice"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['basePrice'].hasError('min') &&
                                        (valForm.controls['basePrice'].dirty ||
                                            valForm.controls['basePrice'].touched)
                                    "
                                    >Minimum number is 0.</span>

                            </div>

                            <div class="form-item w-30p">
                                <div class="custom-label">Status</div>
                                <dx-select-box
                                    class="custom-select"
                                    [(value)]="functionRoomModel.status"
                                    [dataSource]="functionRoomModel.pageData.status"
                                    displayExpr="name"
                                    valueExpr="value"
                                    formControlName="status"
                                    name="status"
                                >
                                </dx-select-box>
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['status'].hasError('required') &&
                                        (valForm.controls['status'].dirty || valForm.controls['status'].touched)
                                    "
                                    >Status is required.</span>
                            </div>

                            <div class="form-item w-30p">
                                <div class="custom-label">VAT Rate</div>
                                <dx-select-box
                                    class="custom-select"
                                    [(value)]="functionRoomModel.vatId"
                                    [dataSource]="functionRoomModel.pageData.propertyVATs"
                                    displayExpr="name"
                                    valueExpr="id"
                                    formControlName="vat"
                                    name="vat"
                                >
                                </dx-select-box>
                                <span
                                    class="text-danger"
                                    *ngIf="vatInvalid"
                                    >VAT Rate is required.</span>
                            </div>

                            <div class="form-item w-30p">
                                <div class="custom-label">Cost centre</div>
                                <dx-select-box
                                    class="custom-select"
                                    [(value)]="functionRoomModel.costCenterId"
                                    [dataSource]="functionRoomModel.pageData.costCentres"
                                    displayExpr="name"
                                    valueExpr="id"
                                    formControlName="costCenter"
                                    name="costCenter"
                                >
                                </dx-select-box>
                                <span
                                    class="text-danger"
                                    *ngIf="costCenterInvalid"
                                    >Cost Center Rate is required.</span>
                            </div>

                        </div>

                        <div class="right-part flex">
                            <div class="form-item">
                                <div class="custom-label">Description</div>
                                <app-tiny-editor
                                    class="room-desc-text-editor"
                                    [statusBar]="false"
                                    [elementId]="'desc'"
                                    elementHeight="200"
                                    [text]="functionRoomModel.description"
                                    (onEditorContentChange)="setValueFromEditor($event)"
                                ></app-tiny-editor>
                                <span
                                    class="text-danger"
                                    *ngIf="!functionRoomModel.description && submittedForm"
                                    >Description is required.</span>
                            </div>
                        </div>

                    </div>

                    <div class="flex bottom-part">

                        <div class="form-item w-30p">
                            <div class="custom-label">Bookable Days</div>
                            <dx-tag-box
                                class="custom-select"
                                [dataSource]="functionRoomModel.pageData.bookableDays"
                                displayExpr="name"
                                [value]="functionRoomModel.bookableDays"
                                formControlName="bookableDays"
                                [showSelectionControls]="true"
                                name="bookableDays"
                                (onValueChanged)="setBookableDays($event)"
                            >
                            </dx-tag-box>
                            <span class="text-danger" *ngIf="!validateBookableDays() && submittedForm">Please select at least one day.</span>
                        </div>

                        <div class="form-item w-30p" *ngIf="1===0">
                            <div class="custom-label">Catering Options</div>
                            <dx-tag-box
                                class="custom-select"
                                [dataSource]="functionRoomModel.pageData.cateringOptions"
                                displayExpr="name"
                                [value]="functionRoomModel.cateringOptions"
                                formControlName="cateringOptions"
                                [showSelectionControls]="true"
                                name="cateringOptions"
                                (onValueChanged)="setCateringOptions($event)"
                            >
                            </dx-tag-box>
                        </div>

                        <div class="form-item w-30p">
                            <div class="custom-label">Sessions</div>
                            <dx-tag-box
                                class="custom-select"
                                [dataSource]="functionRoomModel.pageData.sessions"
                                displayExpr="name"
                                [value]="functionRoomModel.sessions"
                                formControlName="sessions"
                                [showSelectionControls]="true"
                                name="sessions"
                                (onValueChanged)="setSessions($event)"
                            >
                            </dx-tag-box>
                            <span class="text-danger" *ngIf="!validateSessions() && submittedForm">Please select at least one session.</span>
                        </div>

                        <div class="form-item w-30p">
                            <div class="custom-label">Room Configuration</div>
                            <dx-tag-box
                                class="custom-select"
                                [dataSource]="functionRoomModel.pageData.configurations"
                                displayExpr="name"
                                [value]="functionRoomModel.configurations"
                                formControlName="configurations"
                                [showSelectionControls]="true"
                                name="configurations"
                                (onValueChanged)="setConfigurations($event)"
                            >
                            </dx-tag-box>
                            <div style="float:right" class="view-configuration custom-link" (click)="viewConfiguration()">View</div>
                            <span class="text-danger" *ngIf="!validateConfigurations() && submittedForm">Please select at least one configuration.</span>
                        </div>

                    </div>

                    <div *ngIf="showConfigurationTooltip" [style.top.px]="top" [style.left.px]="left" class="configuration-tooltip">
                        <div (click)="closeConfiguration()" class="close-function-popover">X</div>
                        <img src="../../../../assets/images/room layouts.png" alt="No image available" width="100%">
        
                    </div>

                    <div *ngIf="loader" class="loader left"></div>

                    <div class="dialog-controls-wrap">
                        <button *ngIf="!loader" type="submit" class="custom-button blue left">Save</button>
                        <button type="button" (click)="close()" class="custom-button red left">Cancel</button>
                    </div>

                </ng-container>
            </form>

        </div>
    </div>
</div>