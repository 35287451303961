export class EnumModel {
    public id: string;
    public name: string;
    public value: string;

    constructor(id?: string, value?: string, name?: string) {
        this.id = id;
        this.value = value;
        this.name = name;
    }
}
