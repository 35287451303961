import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';

@Component({
    selector: 'app-guarantee-info',
    templateUrl: './guarantee-info.component.html',
    styleUrls: ['./guarantee-info.component.less'],
})
export class GuaranteeInfoComponent implements OnInit {
    @Input() guaranteeInfo: boolean;
    @Output() guaranteeInfoChange = new EventEmitter<boolean>();
    theme: number;

    constructor() {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    }

    ngOnInit() {}

    close() {
        this.guaranteeInfoChange.emit(false);
    }
}
