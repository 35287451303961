import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthTokenService } from '../../services/auth-token.service';

@Component({
    selector: 'app-refresh-page-popup',
    templateUrl: './refresh-page-popup.component.html',
    styleUrls: ['./refresh-page-popup.component.less'],
})
export class RefreshPagePopupComponent implements OnInit {
    @Input() refreshPagePopup: boolean;
    @Output() refreshPagePopupChange = new EventEmitter<boolean>();

    theme: number;

    constructor() {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    }

    ngOnInit() {}

    close() {
        this.refreshPagePopupChange.emit(false);
        location.reload();
    }
}
