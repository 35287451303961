import { FunctionPaymentModel } from './function-flow.model';
import { EnumModel } from '../shared/enum-model';
import { InvoiceModel } from '../booking/invoice.model';
import { FinanceAccountType } from '../../enums/finance-account-type.enum';

export class FunctionPaymentPDModel {
    public payment: FunctionPaymentModel;
    public paidByList: FinanceAccountModel[] = [];
    public paymentTypes: EnumModel[] = [];
    public paymentHybridTypes: FunctionPaymentHybridSourceModel[] = [];
    public paymentPurposes: EnumModel[] = [];
    public invoices: InvoiceModel[] = [];
    public balance: number;

    constructor() {
        this.payment = new FunctionPaymentModel();
    }
}

export class FinanceAccountModel {
    public accountId: number;
    public accountName: string;
    public accountType: string;
}

export class FunctionPaymentHybridSourceModel {
    public id: number;
    public source: string;
    public type: string;
    public description: string;
    public amount: number;
    public paidBy: number;
    public invoiceId?: number;
}