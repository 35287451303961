import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BookingGuestRegistrationMenageModel } from '../../models/booking/booking-details.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';
import { AuthTokenService } from '../../shared/services/auth-token.service';
import { HttpRequestService } from 'src/app/shared/services/http-request.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class GuestRegistrationServiceNew {
    private getBookingForGuestRegistrationUrl = environment.apiUrl + '/Booking/GetBookingForGuestRegistration';
    private acceptTermsUrl = environment.apiUrl + '/Booking/AcceptTerms';
    private getTermsUrl = environment.apiUrl + '/Booking/GetTermsAndConditions';
    private setBookingGuestsListUrl = environment.apiUrl + '/Booking/SetBookingGuestsList';
    private exportGuestRegistrationFormToPdfUrl = environment.apiUrl + '/Booking/ExportGuestRegistrationFormToPdf';
    private sendSMSregistrationLink = environment.apiUrl + '/Booking/SendRegistrationSMS';
    private sendSMSregistrationByIdLink = environment.apiUrl + '/Booking/SendRegistrationSMSById';

    constructor(
        private http: HttpClient,
        private httpService: HttpRequestService,
        private toasterService: ToastService,
        private authTokenService: AuthTokenService
    ) {}

    getBookingGuests(bookingId: number, token: string) {
        return this.http
            .get(this.getBookingForGuestRegistrationUrl + '?bookingId=' + bookingId, {
                ...this.getOptions(token),
                observe: 'response',
            })
            .pipe(
                map((response: any) => {
                    this.authTokenService.checkMaintenanceMode(response);
                    return response.body;
                }),
                catchError((error) => this.handleError(error))
            );
    }

    updateGuestsForBooking(model: BookingGuestRegistrationMenageModel, token: string, showToast?: boolean) {
        return this.http
            .post(this.setBookingGuestsListUrl, model, { ...this.getOptions(token), observe: 'response' })
            .pipe(
                map((response: any) => {
                    this.authTokenService.checkMaintenanceMode(response);

                    if (response == null) {
                        return null;
                    } else {
                        if (showToast) {
                            this.toasterService.showToast('Success', 'Guests submission successful', null, ToastEventType.Success, true, 2000);
                        }

                        return response.body;
                    }
                }),
                catchError((response) => this.handleError(response))
            );
    }

    acceptTermsСondtions(bookingId, state, token) {
        return this.http
            .get(`${this.acceptTermsUrl}?bookingId=${bookingId}&state=${state}`, {
                ...this.getOptions(token),
                observe: 'response',
            })
            .pipe(catchError((error) => this.handleError(error)));
    }

    getTermsAndConditions(propertyId, token) {
        return this.http
            .get(`${this.getTermsUrl}?propertyId=${propertyId}`, { ...this.getOptions(token), observe: 'response' })
            .pipe(catchError((error) => this.handleError(error)));
    }

    exportGuestRegistrationFormToPdf(token: string) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: token });

        return this.http
            .post(this.exportGuestRegistrationFormToPdfUrl, null, {
                headers,
                responseType: 'blob',
                observe: 'response',
            })
            .pipe(
                map((response) => {
                    this.authTokenService.checkMaintenanceMode(response);
                    this.toasterService.showToast('Success', 'Guests submission successful', null, ToastEventType.Success, true, 2000);
                    return response.body;
                })
            );
    }

    getOptions(token: string) {
        return { headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: token }) };
    }

    sendSMSById(bookingId, propertyId) {
        return this.http
            .get(`${this.sendSMSregistrationByIdLink}?bookingId=${bookingId}&propertyId=${propertyId}`, {
                ...this.getOptions(AuthTokenService.getParsedToken()),
                observe: 'response',
            })
            .pipe(
                map((response) => {
                    this.authTokenService.checkMaintenanceMode(response);
                    this.toasterService.showToast('Success', 'SMS successfully sent', null, ToastEventType.Success, true, 2000);
                    return 1;
                }),
                catchError((error) => this.handleError(error))
            );
    }

    private handleError(error: any) {
        let errMsg: string;
        if (error.error.message) {
            errMsg = error.error.message;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.log(errMsg);
        this.toasterService.showToast('Error', errMsg, null, ToastEventType.Error, true, 5000);

        this.authTokenService.checkMaintenanceMode(error);

        return observableThrowError({ status: error.status, message: errMsg });
    }
}
