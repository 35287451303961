<div class="promo-codes-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2 *ngIf="generatePopup">Generate promo codes</h2>
            <h2 *ngIf="editGeneratePopup">
                {{ permissionsModel.formButtonEnabled ? 'Update promo codes' : 'Promo codes' }}
            </h2>

            <form [formGroup]="valForm" (ngSubmit)="submitGenerateCode($event)">
                <div class="form-box flex">
                    <div class="form-item long">
                        <div class="custom-label">Name</div>
                        <input
                            class="custom-input"
                            formControlName="name"
                            type="text"
                            name="promoCodeModelName"
                            [(ngModel)]="promoCodeModel.name"
                            [readOnly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['name'].hasError('required') &&
                                (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                            "
                            >Name is required</span
                        >
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['name'].hasError('maxlength') &&
                                (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                            "
                            >Max 50 characters</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Code</div>
                        <input
                            class="custom-input"
                            formControlName="code"
                            type="text"
                            name="code"
                            [(ngModel)]="promoCodeModel.code"
                            [readOnly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['code'].hasError('required') &&
                                (valForm.controls['code'].dirty || valForm.controls['code'].touched)
                            "
                            >Code is required</span
                        >
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['code'].hasError('maxlength') &&
                                (valForm.controls['code'].dirty || valForm.controls['code'].touched)
                            "
                            >Max 50 characters</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Rate schedule</div>
                        <dx-select-box
                            class="custom-select"
                            valueExpr="id"
                            [(value)]="promoCodeModel.rateScheduleId"
                            displayExpr="name"
                            [dataSource]="rateSchedules"
                            formControlName="rateSchedule"
                            name="rateSchedule"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-select-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['rateSchedule'].hasError('required') &&
                                (valForm.controls['rateSchedule'].dirty || valForm.controls['rateSchedule'].touched)
                            "
                            >Field is required.</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Discount type</div>
                        <dx-select-box
                            class="custom-select"
                            valueExpr="value"
                            [(value)]="promoCodeModel.discountType"
                            displayExpr="name"
                            [dataSource]="types"
                            formControlName="type"
                            (onItemClick)="setDiscountType($event)"
                            name="type"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-select-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['type'].hasError('required') &&
                                (valForm.controls['type'].dirty || valForm.controls['type'].touched)
                            "
                            >Field is required.</span
                        >
                    </div>
                    <div class="form-item" *ngIf="reward">
                        <div class="custom-label">Value</div>
                        <input
                            class="custom-input"
                            formControlName="reward"
                            type="number"
                            name="reward"
                            [(ngModel)]="promoCodeModel.reward"
                            [readOnly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['reward'].hasError('required') &&
                                (valForm.controls['reward'].dirty || valForm.controls['reward'].touched)
                            "
                            >Field is required.</span
                        >
                    </div>
                    <div class="form-item" *ngIf="!reward">
                        <div class="custom-label">Extras</div>

                        <div class="flex sale-item-flex">
                            <input
                                class="custom-input"
                                readonly
                                type="text"
                                formControlName="saleItem"
                                name="sale-item"
                                [disabled]="!permissionsModel.formButtonEnabled"
                            />
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['saleItem'].hasError('required') &&
                                    (valForm.controls['saleItem'].dirty || valForm.controls['saleItem'].touched)
                                "
                                >Field is required.</span
                            >
                            <button type="button" class="custom-button blue left sale-item" (click)="openSaleItems()">
                                Choose
                            </button>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Valid from</div>
                        <dx-date-box
                            [(value)]="dateFrom"
                            type="date"
                            [displayFormat]="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            formControlName="dateFrom"
                            name="dateFrom"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-date-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['dateFrom'].hasError('required') &&
                                (valForm.controls['dateFrom'].dirty || valForm.controls['dateFrom'].touched)
                            "
                            >Field is required.</span
                        >
                        <span class="text-danger">{{ dateErrorMessage }}</span>
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Valid to</div>
                        <dx-date-box
                            [(value)]="dateTo"
                            type="date"
                            [displayFormat]="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            formControlName="dateTo"
                            name="dateTo"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-date-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['dateTo'].hasError('required') &&
                                (valForm.controls['dateTo'].dirty || valForm.controls['dateTo'].touched)
                            "
                            >Field is required.</span
                        >
                    </div>
                </div>

                <button *ngIf="!loader && generatePopup" type="submit" class="custom-button blue left">Add</button>
                <button
                    *ngIf="!loader && editGeneratePopup && permissionsModel.formButtonEnabled"
                    type="submit"
                    class="custom-button blue left"
                >
                    Update
                </button>
                <div *ngIf="loader" class="loader left"></div>
                <button (click)="close()" class="custom-button red left" type="button">
                    {{ permissionsModel.formButtonEnabled ? 'Cancel' : 'Close' }}
                </button>
            </form>
        </div>
    </div>
</div>
<app-promo-code-sale-items
    *ngIf="promoCodeSaleItem"
    [(promoCodeSaleItem)]="promoCodeSaleItem"
    [categoriesWithSaleItemsModel]="categoriesWithSaleItemsModel"
    (updateSaleItem)="setSaleItem($event)"
>
</app-promo-code-sale-items>
