import { NgModule } from '@angular/core';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { CommonModule } from '@angular/common';
import { TinyEditorComponent } from './components/tiny-editor/tiny-editor.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { XmlViewComponent } from './xml-view/xml-view.component';
import { DatexPipe } from './pipes/datex.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CamelCaseSeparate } from 'src/app/shared/pipes/camelcase-seperate.pipe';
import { BooleanDescriptionPipe } from './pipe-transforms/boolean-description.pipe';
import { ShowHideContainerComponent } from './directives/show-hide-container';
import { MessageTemplatesRecepientComponent } from './message-templates-recepient/message-templates-recepient.component';
import { MessageTemplateRecepientPreviewComponent } from './message-templates-recepient/message-templates-recepient-preview/message-templates-recepient-preview.component';
import { DxDataGridModule, DxTooltipModule, DxDropDownBoxModule, DxSelectBoxModule, DxDateBoxModule, DxCalendarModule, DxTabsModule, DxTabPanelModule } from 'devextreme-angular';
import { BookingPrintReceiptComponent } from '../routes/booking/settings-booking-detail/booking-print-receipt/booking-print-receipt.component';
import { MessageTemplatePreviewComponent } from './message-template-preview/message-template-preview.component';
import { FooterComponent } from './footer/footer.component';
import { BookingDetailsInvoiceComponent } from './booking-details-invoice/booking-details-invoice.component';
import { BookingDetailsInvoiceItemComponent } from './booking-details-invoice/booking-details-invoice-item/booking-details-invoice-item.component';
import { BookingDetailsInvoicePaymentComponent } from './booking-details-invoice/booking-details-invoice-payment/booking-details-invoice-payment.component';
import { FunctionDetailsInvoiceComponent } from '../routes/function/function-details-invoice/function-details-invoice.component';
import { FunctionDetailsInvoiceItemComponent } from '../routes/function/function-details-invoice/function-details-invoice-item/function-details-invoice-item.component';
import { FunctionDetailsInvoicePaymentComponent } from '../routes/function/function-details-invoice/function-details-invoice-payment/function-details-invoice-payment.component';
import { ColorpickerComponent } from './colorpicker/colorpicker.component';
import { PaymentFormModalComponent } from '../routes/booking/settings-booking-detail/booking-details-payment/payment-form-modal/payment-form-modal.component';
import { SettingsLinkFunctionComponent } from '../routes/function/settings-link-function/settings-link-function.component';
import { SettingsFunctionDetailsPaymentComponent } from '../routes/function/settings-function-details-payment/settings-function-details-payment.component';
import { PartnerDetailsComponent } from '../routes/settings/settings-partners/partner-details/partner-details.component';
import { CardsListModalFunctionsComponent } from 'src/app/routes/function/settings-function-details-payment/cards-list-modal-functions/cards-list-modal-functions.component';
import { SafeResourcePipe } from 'src/app/shared/pipes/safe-resource.pipe'
import { SafeHtmlPipe } from 'src/app/shared/pipes/safe-html.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DxDataGridModule,
        DxDropDownBoxModule,
        DxTooltipModule,
        DxSelectBoxModule,
        DxDateBoxModule,
        DxCalendarModule,
        DxTabsModule,
        DxTabPanelModule
    ],
    declarations: [
        DeleteModalComponent,
        TinyEditorComponent,
        ConfirmModalComponent,
        XmlViewComponent,
        DatexPipe,
        CamelCaseSeparate,
        BooleanDescriptionPipe,
        ShowHideContainerComponent,
        MessageTemplatesRecepientComponent,
        MessageTemplateRecepientPreviewComponent,
        MessageTemplatePreviewComponent,
        BookingPrintReceiptComponent,
        FooterComponent,
        BookingDetailsInvoiceComponent,
        BookingDetailsInvoiceItemComponent,
        BookingDetailsInvoicePaymentComponent,
        ColorpickerComponent,
        PaymentFormModalComponent,
        SettingsLinkFunctionComponent,
        SettingsFunctionDetailsPaymentComponent,
        PartnerDetailsComponent,
        FunctionDetailsInvoiceComponent,
        FunctionDetailsInvoiceItemComponent,
        FunctionDetailsInvoicePaymentComponent,
        CardsListModalFunctionsComponent,
        SafeResourcePipe,
        SafeHtmlPipe,
    ],
    exports: [
        DeleteModalComponent,
        TinyEditorComponent,
        ConfirmModalComponent,
        XmlViewComponent,
        DatexPipe,
        CamelCaseSeparate,
        BooleanDescriptionPipe,
        ShowHideContainerComponent,
        MessageTemplatesRecepientComponent,
        MessageTemplateRecepientPreviewComponent,
        MessageTemplatePreviewComponent,
        BookingPrintReceiptComponent,
        FooterComponent,
        BookingDetailsInvoiceComponent,
        BookingDetailsInvoiceItemComponent,
        BookingDetailsInvoicePaymentComponent,
        ColorpickerComponent,
        PaymentFormModalComponent,
        SettingsLinkFunctionComponent,
        SettingsFunctionDetailsPaymentComponent,
        PartnerDetailsComponent,
        FunctionDetailsInvoiceComponent,
        FunctionDetailsInvoiceItemComponent,
        FunctionDetailsInvoicePaymentComponent,
        CardsListModalFunctionsComponent,
        SafeResourcePipe,
        SafeHtmlPipe,
    ],
})
export class SharedModule {}
