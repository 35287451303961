import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertySettingModel } from '../../../../models/property/property-setting.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SettingsPropertiesService } from '../../settings-properties/settings-properties.service';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { environment } from '../../../../../environments/environment';
import { EnumModel } from '../../../../models/shared/enum-model';
import { CustomValidationService } from 'src/app/shared/services/custom-validation.service';
import { BookingEmailSMSSettingsPermissionsModel } from '../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../enums/permissions.enum';
import { weekdays } from 'moment';

@Component({
    selector: 'app-settings-property-settings-details',
    templateUrl: './settings-property-settings-details.component.html',
    styleUrls: ['./settings-property-settings-details.component.less'],
})
export class SettingsPropertySettingsDetailsComponent implements OnInit {
    @Input() editPropertySetting: boolean;
    @Output() editPropertySettingChange = new EventEmitter<boolean>();
    @Input() data: PropertySettingModel;
    @Output() update = new EventEmitter();

    permissionsModel: BookingEmailSMSSettingsPermissionsModel;
    loader = false;
    valForm: UntypedFormGroup;

    initLoad = false;
    showInput = false;
    showComboBox = false;
    showSwitch = false; // for sale items for widget
    showPasswordInput = false;
    showTagBox = false; // for weekdays selection (multiple with comma separated)
    valueBool: boolean;
    isSuperUser: boolean;
    disableSubmit = false;
    showCheckbox: boolean;
    isSms: boolean;
    isEmailSender: boolean;
    userIsSure = false;
    showTimeInput = false;
    title = 'Email / SMS Setting Details';
    timeValue = new Date();
    timeInterval = "15";

    sourceForComboBox: EnumModel[] = [];
    propertySettingModel = new PropertySettingModel();
    theme: number;

    days: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    selectedWeekdays: string[] = []; // for display
    selectedDays: string = ''; // for databae
    constructor(
        private fb: UntypedFormBuilder,
        private settingsPropertyService: SettingsPropertiesService,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.BookingEmailSMSSettings);
        this.isSuperUser = AuthTokenService.decodeJwtToken().IsSuperUser;
    }

    ngOnInit() {
        // SMS keep disabled if has value for reg user
        this.isSms = this.data.key.includes('SMS');
        this.isEmailSender = this.data.key.includes('Mail.FromAddress');

        if (this.isSms) {
            this.valForm = this.fb.group({
                value: [
                    null,
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(4),
                        Validators.maxLength(11)
                    ]),
                ],
            });
        } else if (this.data.keySetting.type === 4) {
            //currency type
            this.valForm = this.fb.group({
                value: [
                    null,
                    Validators.compose([Validators.required, Validators.pattern(CustomValidationService.currencyExpression)]),
                ],
            });
        } else {
            this.valForm = this.fb.group({
                value: [
                    null,
                    Validators.compose(
                        this.isEmailSender ? [Validators.required, Validators.email] : [Validators.required]
                    ),
                ],
            });
        }

        if (this.isSms && this.data.value && !this.isSuperUser) {
            this.valForm.controls['value'].disable();
            this.disableSubmit = true;
        }

        if (this.editPropertySetting) {
            this.propertySettingModel = JSON.parse(JSON.stringify(this.data));
            if (this.propertySettingModel.keySetting.key.startsWith('Other.')) {
                this.title = 'Other settings';
            }
            if (this.propertySettingModel.keySetting.key.startsWith('Reporting.')) {
                this.title = 'Reporting settings';
            }
            if (this.propertySettingModel.keySetting.key.startsWith('Xero.')) {
                this.title = 'Xero settings';
            }

            // check to show combo box
            if (this.propertySettingModel.keySetting.source === null) {
                if (this.propertySettingModel.keySetting.type === 0) {
                    this.showSwitch = false;
                    this.checkPasswordInput();
                } else if (this.propertySettingModel.keySetting.type === 1) {
                    this.showSwitch = false;
                    this.checkPasswordInput();
                } else if (this.propertySettingModel.keySetting.type === 2) {
                    this.showInput = false;
                    this.showSwitch = true;
                    if (this.propertySettingModel.value === '1') {
                        this.valueBool = true;
                    } else {
                        this.valueBool = false;
                    }
                    this.valForm.controls['value'].setValue(this.valueBool);
                } else if (this.propertySettingModel.keySetting.type === 3) {
                    this.showTimeInput = true;
                    this.title = 'Other settings';
                    if (this.propertySettingModel.value) {
                        const hours = this.propertySettingModel.value.split(':')[0];
                        const minutes = this.propertySettingModel.value.split(':')[1];

                        this.timeValue.setHours(Number(hours));
                        this.timeValue.setMinutes(Number(minutes));
                      if (this.propertySettingModel.key === 'Other.LastBookingCutOffTime') { this.timeInterval = "30";}
                    }
                } else if (this.propertySettingModel.keySetting.type === 4) {
                    this.showSwitch = false;
                    this.showInput = true;  
                                    
                } else if (this.propertySettingModel.keySetting.type === 6) {
                    //encrypted string
                    this.showInput = false;
                    this.showPasswordInput = true;
                    this.initLoad = true;
                    this.decryptValue();
                } else if (this.propertySettingModel.keySetting.type === 9) {
                    // weekdays tag box -- warning-- do not use it for any other purpose.
                    this.showSwitch = false;
                    this.showInput = false;
                    this.showPasswordInput = false;
                    this.showComboBox = false;
                    this.showTagBox = true;
                    this.setWeekDays(this.propertySettingModel.value);
                    this.valForm.controls['value'].setValue(this.propertySettingModel.value);
                }
            } else {
                this.showSwitch = false;
                this.showInput = false;
                this.showPasswordInput = false;
                this.showComboBox = true;
                const sourceUrl: string = environment.apiUrl + '/' + this.propertySettingModel.keySetting.source;
                this.settingsPropertyService.getSourceForPropertySettings(sourceUrl).subscribe(
                    (response) => {
                        this.sourceForComboBox = response;
                        this.valForm.controls['value'].setValue(this.propertySettingModel.value);
                    },
                    (error) => {
                        console.log(error);
                        this.close();
                    }
                );
            }
        }
    }

    checkPasswordInput() {
        if (this.propertySettingModel.key.toLowerCase().indexOf('password') > -1) {
            this.showInput = false;
            this.showPasswordInput = true;
        } else {
            this.showPasswordInput = false;
            this.showInput = true;
        }
    }

  submitPropertySetting(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            if (this.valForm.controls.hasOwnProperty(c)) {
                this.valForm.controls[c].markAsTouched();
            }
      }

      if (this.valForm.valid) {
        if (!this.isSuperUser) {
                if (this.isSms) {
                    this.showCheckbox = true;
                    if (this.userIsSure) {
                        this.loader = true;
                        this.showCheckbox = false;
                        this.updatePropertySetting();
                    }
                } else {
                    this.loader = true;
                    this.updatePropertySetting();
                }
        } else {
                this.loader = true;
                this.updatePropertySetting();
            }
        }
    }

  updatePropertySetting() {
        if (this.showSwitch) {
            // handle value for sale items for widget
            switch (this.valueBool) {
                case true:
                    this.propertySettingModel.value = '1';
                    break;
                case false:
                    this.propertySettingModel.value = '0';
                    break;
                default:
                    this.propertySettingModel.value = '0';
                    break;
            }
        }
        if (
            this.propertySettingModel.key === 'Other.CheckInFrom' ||
            this.propertySettingModel.key === 'Other.CheckOutUntil' ||
            this.propertySettingModel.key === 'Other.LastCheckIn' ||
            this.propertySettingModel.key === 'Other.LastBookingCutOffTime'
        ) {
            this.propertySettingModel.value = this.timeValue.toTimeString();
      }
      if (this.propertySettingModel.key === 'Other.LastBookingCutOffDays') {
        this.propertySettingModel.value = this.getWeekDays();
      }

        this.settingsPropertyService.updatePropertySetting(this.propertySettingModel, true).subscribe(
            (response) => {
                this.update.emit();
                this.loader = false;
                this.close();
            },
            (error) => {
                console.log(error);
                this.loader = false;
            }
        );
    }

    close() {
        this.editPropertySettingChange.emit(false);
    }

  setWeekDays(val: string) {
    for (let day of val.split(',')) {
          if (day !== '0')
          this.selectedWeekdays.push(this.days[Number(day) -1]);
        }

  }

  getWeekDays() {
    var selectedVal = this.selectedWeekdays.length > 0 ? '': '0';
    for (let day of this.selectedWeekdays) {
      selectedVal += selectedVal.length > 0 ? ',' + (this.days.indexOf(day) + 1) : (this.days.indexOf(day) + 1);
    }   
    return selectedVal;
  }

  decryptValue() {
    this.settingsPropertyService.getDecryptedPropertySetting(this.propertySettingModel.propertyId, this.propertySettingModel.key).subscribe(
        (response) => {
            let decryptedSetting: PropertySettingModel = response;
            this.propertySettingModel.value = decryptedSetting.value;
            this.initLoad = false;
        },
        (error) => {
            console.log(error);
        }
    );
  }
}
