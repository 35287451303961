export enum UserRoleEnum {
    Owner = 1,
    Manager,
    Supervisor,
    Receptionist,
    Housekeeping,
    WebDesigner,
    BookingOnly,
    DemoUser,
}
