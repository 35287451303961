<div class="confirm-modal flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2 *ngIf="displayHtmlMessage != true">{{ message }}</h2>

            <div class="mb-20" *ngIf="displayHtmlMessage" [innerHTML]="messageHtml"></div>

            <div class="button-wrapper flex" [ngClass]="[reverseOptions ? 'reverse' : '']">
                <button type="button" class="custom-button blue" [ngClass]="confirmClass" *ngIf="displayConfirmButton" (click)="confirmFunction()">
                    {{ confirmLabel ? confirmLabel : 'Yes' }}
                </button>
                <button type="button" class="custom-button orange" [ngClass]="alternativeClass" *ngIf="displayAlternativeOptionButton" (click)="alternativeOptionFunction()">
                    {{ alternativeOptionLabel }}
                </button>
                <button type="button" class="custom-button red" [ngClass]="declineClass" *ngIf="showDecline()" (click)="close()">
                    {{ declineLabel ? declineLabel : 'No' }}
                </button>
            </div>
        </div>
    </div>
</div>
