<div class="properties-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>{{ title }}</h2>
            <div id="details" class="form-box flex" [ngClass]="[theme == 2 ? 'dark-theme' : '']">

                <form [formGroup]="valForm" (ngSubmit)="submitPropertySetting($event)" class="form-box form-grid flex">
                    <div class="form-item">
                        <p>{{ propertySettingModel.keySetting.desc }}</p>
                    </div>
                    <div class="form-item">
                      <ng-container *ngIf="showInput">
                        <input [(ngModel)]="propertySettingModel.value"
                               formControlName="value"
                               name="value"
                               class="custom-input" />
                        <span class="text-danger"
                              *ngIf="
                                    valForm.controls['value'].hasError('required') &&
                                    (valForm.controls['value'].dirty || valForm.controls['value'].touched)
                                ">
                          Value is required<br />
                        </span>
                        <span class="text-danger"
                              *ngIf="
                                    valForm.controls['value'].hasError('maxlength') &&
                                    (valForm.controls['value'].dirty || valForm.controls['value'].touched) &&
                                    isSms
                                ">
                          Maximum 11 characters allowed<br />
                        </span>
                        <span class="text-danger"
                              *ngIf="
                                    valForm.controls['value'].hasError('minlength') &&
                                    (valForm.controls['value'].dirty || valForm.controls['value'].touched) &&
                                    isSms
                                ">
                          Minimum 4 characters required<br />
                        </span>
                        <span class="text-danger"
                              style="top: 65px"
                              *ngIf="
                                    valForm.controls['value'].hasError('invalid_characters') &&
                                    (valForm.controls['value'].dirty || valForm.controls['value'].touched) &&
                                    isSms
                                ">Using number 3 is not allowed</span>
                        <span class="text-danger"
                              *ngIf="
                                    valForm.controls['value'].hasError('email') &&
                                    (valForm.controls['value'].dirty || valForm.controls['value'].touched) &&
                                    isEmailSender
                                ">Must be a valid email address</span>
                      </ng-container>

                      <ng-container *ngIf="showPasswordInput">
                        <app-show-hide-container>
                          <input [(ngModel)]="propertySettingModel.value"
                                 #showhideinput
                                 formControlName="value"
                                 name="value-pass"
                                 type="password"
                                 class="custom-input" />
                          <span class="text-danger"
                                *ngIf="
                                        valForm.controls['value'].hasError('required') &&
                                        (valForm.controls['value'].dirty || valForm.controls['value'].touched)
                                    ">Value is required</span>
                          <span class="text-danger"
                                *ngIf="
                                        valForm.controls['value'].hasError('maxlength') &&
                                        (valForm.controls['value'].dirty || valForm.controls['value'].touched)
                                    ">Max 250 characters</span>
                        </app-show-hide-container>
                      </ng-container>

                      <ng-container *ngIf="showTimeInput">
                        <dx-date-box [(ngModel)]="timeValue"
                                     ([interval])="timeInterval"
                                     [displayFormat]="'HH:mm'"
                                     formControlName="value"
                                     name="value-time"
                                     type="time">
                        </dx-date-box>
                      </ng-container>

                      <ng-container *ngIf="showSwitch">
                        <dx-switch [(value)]="valueBool" formControlName="value" name="value-switch"></dx-switch>
                      </ng-container>

                      <ng-container *ngIf="showComboBox">
                        <dx-select-box class="custom-select"
                                       [dataSource]="sourceForComboBox"
                                       displayExpr="name"
                                       valueExpr="value"
                                       [(value)]="propertySettingModel.value"
                                       name="value-combo"
                                       formControlName="value">
                        </dx-select-box>
                      </ng-container>

                      <div class="confirm-checkbox" *ngIf="showCheckbox">
                        <dx-check-box [(value)]="userIsSure"></dx-check-box>
                        <p>
                          Please double check and confirm the SMS origin. Once this is set it cannot be changed.
                        </p>
                      </div>

                      <ng-container *ngIf="showTagBox">
                        <dx-tag-box class="custom-select"
                                    [items]="days"
                                    [showSelectionControls]="true"
                                    applyValueMode="useButtons"
                                    [(value)]="selectedWeekdays" >
                        </dx-tag-box>
                        
                      </ng-container>
                    </div>

                    <div class="button-wrapper">
                        <button
                            *ngIf="!loader && !initLoad && !disableSubmit && permissionsModel.update"
                            type="submit"
                            class="custom-button blue left"
                        >
                            Save
                        </button>
                        <div *ngIf="loader" class="loader left"></div>
                        <button type="button" (click)="close()" class="custom-button red left">
                            {{ permissionsModel.update ? 'Cancel' : 'Close' }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="loader" *ngIf="initLoad"></div>