import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { environment } from '../../../../environments/environment';
import { CleaningFrequencyModel } from '../../../models/cleaning-frequency/cleaning-frequency.model';
import { CleaningGroupModel } from '../../../models/cleaning-frequency/cleaning-group.model';
import { KeyValuePair } from '../../../models/shared/key-value-pair.model';

@Injectable()
export class HousekeepingService {
    private createCleaningFrequencyUrl = environment.apiUrl + '/CleaningFrequency/CreateCleaningFrequency';
    private getCleaningFrequenciesUrl = environment.apiUrl + '/CleaningFrequency/GetCleaningFrequencies';
    private updateCleaningFrequencyUrl = environment.apiUrl + '/CleaningFrequency/UpdateCleaningFrequency';
    private deleteCleaningFrequencyUrl = environment.apiUrl + '/CleaningFrequency/DeleteCleaningFrequency';
    private getCleaningFrequencyTypesUrl = environment.apiUrl + '/CleaningFrequency/GetCleaningFrequencyTypes';

    private getCleaningGroupsWithRoomsUrl = environment.apiUrl + '/CleaningFrequency/GetCleaningGroupsWithRooms';
    private createCleaningGroupUrl = environment.apiUrl + '/CleaningFrequency/CreateCleaningGroup';
    private updateCleaningGroupUrl = environment.apiUrl + '/CleaningFrequency/UpdateCleaningGroup';
    private deleteCleaningGroupUrl = environment.apiUrl + '/CleaningFrequency/DeleteCleaningGroup';
    private getPageDataCleaningGroupsUrl = environment.apiUrl + '/CleaningFrequency/GetPageDataCleaningGroups';
    private getPageDataCleaningGroupsForInsertUrl =
        environment.apiUrl + '/CleaningFrequency/GetPageDataCleaningGroupsForInsert';
    private reorderCleaningFrequenciesUrl = environment.apiUrl + '/CleaningFrequency/ReorderCleaningFrequencies';
    constructor(private http: HttpRequestService) {}

    createCleaningFrequency(model: CleaningFrequencyModel) {
        return this.http.post(this.createCleaningFrequencyUrl, model, true);
    }

    getCleaningFrequencies() {
        return this.http.get(this.getCleaningFrequenciesUrl);
    }

    getCleaningFrequencyTypes() {
        return this.http.get(this.getCleaningFrequencyTypesUrl);
    }

    updateCleaningFrequency(model: CleaningFrequencyModel) {
        return this.http.post(this.updateCleaningFrequencyUrl, model, true);
    }

    deleteCleaningFrequency(id: number) {
        return this.http.delete(this.deleteCleaningFrequencyUrl + '?id=' + id, true);
    }

    createCleaningGroup(model: CleaningGroupModel) {
        return this.http.post(this.createCleaningGroupUrl, model, true);
    }

    getCleaningGroupsWithRooms() {
        return this.http.get(this.getCleaningGroupsWithRoomsUrl);
    }

    updateCleaningGroup(model: CleaningGroupModel) {
        return this.http.post(this.updateCleaningGroupUrl, model, true);
    }

    deleteCleaningGroup(id: number) {
        return this.http.delete(this.deleteCleaningGroupUrl + '?id=' + id, true);
    }

    getPageDataCleaningGroups(id: number) {
        return this.http.get(this.getPageDataCleaningGroupsUrl + '?id=' + id);
    }

    getPageDataCleaningGroupsForInsert() {
        return this.http.get(this.getPageDataCleaningGroupsForInsertUrl);
    }
    reorderCleaningFrequencies(model: KeyValuePair[]) {
        return this.http.post(this.reorderCleaningFrequenciesUrl, model, true);
    }
}
