<div class="pass-code-wrapper flex" [ngClass]="[hasNotification ? 'notification-active-passcode' : '']">
    <div class="custom-box pass-code">
        <div class="logo-img">
            <img src="../../../../assets/images/logo.svg" alt="caterbook logo" />
        </div>
        <p>Please provide a pass code to continue</p>
        <div class="form-item">
            <input
                #passCode
                name="passCode"
                (keyup)="checkPassCode()"
                class="custom-input"
                [(ngModel)]="passCodeText"
                type="password"
                maxlength="4"
            />
        </div>
    </div>
</div>
