export class XeroAccountModel 
{
    public propertyId: number;
    public accountId: string;
    public code: string;
    public name: string;
    public codeAndName: string;
    public type: string;
    public bankAccountNumber: string;
    public status: string;
    public description: string;
    public bankAccountType: string;
    public currencyCode: string;
    public taxType: string;
    public enablePaymentsToAccount: Boolean;
    public showInExpenseClaims: Boolean;
    public class: string;
    public systemAccount: string;
    public reportingCode: string;
    public reportingCodeName: string;
    public hasAttachments: Boolean;
    public addToWatchlist: Boolean;

    constructor(accountId: string, codeAndName: string) {
        this.accountId = accountId;
        this.codeAndName = codeAndName;
    }
}