export class MessageTemplateModel {
    public id: number;
    public propertyId: number;
    public type: string;
    public name: string;
    public sender: string;
    public recipient: string;
    public ccProperty: boolean;
    public subject: string;
    public message: string;
    public madeByOTA?: boolean;
    public madeByWebsite?: boolean;
    public madeByStaff?: boolean;
    public rateplanId?: number;
    public otaChannelId?: number;
    public rateScheduleId?: number;
    public partnerId?: number;
    public saleItemId?: number;
    public scheduleActive: boolean;
    public scheduleType?: string;
    public scheduleTrigger?: string;
    public minutesDelay?: number;
    public invoices: Array<MessageInvoiceModel>;
    public reservationType: string;
}

export class MessageInvoiceModel {
    public id: number;
    public inviceNo: string;
}
