import { throwError as observableThrowError, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthTokenService } from './auth-token.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ToastService } from './toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';

@Injectable()
export class HttpRequestService {
    public showRefreshPopup: Subject<boolean> = new Subject();

    constructor(
        private http: HttpClient,
        private toasterService: ToastService,
        private router: Router,
        private authTokenService: AuthTokenService
    ) {}

    private static getHeaders(token) {
        let auth;
        if (token) {
            auth = { Authorization: token };
        }
        return new HttpHeaders({ 'Content-Type': 'application/json', ...auth });
    }

    get(url: string, showSuccessToast?: boolean, toastMessage?: string, title?: string) {
        return this.http.get(url, { ...this.getOptions(), observe: 'response' }).pipe(
            map((response: any) => {
                this.authTokenService.checkMaintenanceMode(response);
                return this.handleResponse(response, showSuccessToast, toastMessage);
            }),
            catchError((response) => this.handleError(response))
        );
    }

    post(
        url: string,
        request: any,
        showSuccessToast?: boolean,
        toastMessage?: string,
        title?: string,
        showErrorToast: boolean = true
    ) {
        return this.http.post<Response>(url, request, { ...this.getOptions(), observe: 'response' }).pipe(
            map((response: any) => {
                this.authTokenService.checkMaintenanceMode(response);
                return this.handleResponse(response, showSuccessToast, toastMessage);
            }),
            catchError((response) => this.handleError(response, showErrorToast))
        );
    }

    delete(url: string, showSuccessToast?: boolean, toastMessage?: string, body?: any) {
        return this.http.delete(url, { ...this.getOptions(body), observe: 'response' }).pipe(
            map((response: any) => {
                this.authTokenService.checkMaintenanceMode(response);
                return this.handleResponse(response, showSuccessToast, toastMessage);
            }),
            catchError((response) => this.handleError(response))
        );
    }

    put(url: string, request: any, showSuccessToast?: boolean, toastMessage?: string) {
        return this.http.put(url, request, { ...this.getOptions(), observe: 'response' }).pipe(
            map((response: any) => {
                this.authTokenService.checkMaintenanceMode(response);
                return this.handleResponse(response, showSuccessToast, toastMessage);
            }),
            catchError((response) => this.handleError(response))
        );
    }

    postMultipart(url: string, data: any, files: File[], showSuccessToast?: boolean, toastMessage?: string) {
        const token = AuthTokenService.getParsedToken();
        const headers = new HttpHeaders({ Authorization: token });
        const formData: FormData = new FormData();
        formData.append('data', JSON.stringify(data));
        for (const file of files) {
            formData.append(file.name, file);
        }
        return this.http.post(url, formData, { headers, observe: 'response' }).pipe(
            map((response: any) => {
                this.authTokenService.checkMaintenanceMode(response);
                return this.handleResponse(response, showSuccessToast, toastMessage);
            }),
            catchError((response) => this.handleError(response))
        );
    }

    postDownload(url, data) {
        return this.http.post(url, data, { ...this.getOptions(), responseType: 'blob', observe: 'response' }).pipe(
            map((res: any) => {
                this.authTokenService.checkMaintenanceMode(res);
                return res;
            }),
            catchError((response) => this.handleError(response))
        );
    }

    getOptions(body?: any): any {
        const token = AuthTokenService.getParsedToken();
        const headers = HttpRequestService.getHeaders(token);
        return { headers, body };
    }

    getBlob(url: string, model: any) {
        const token = AuthTokenService.getParsedToken();
        const headers = HttpRequestService.getHeaders(token);
        return this.http.post(url, model, { headers, responseType: 'blob' });
    }

    getExcelFile(url: string, model: any) {
        const token = AuthTokenService.getParsedToken();
        const headers = HttpRequestService.getHeaders(token);
        return this.http.post(url, model, { headers, responseType: 'blob' });
    }

    private handleResponse(response, showSuccessToast, toastMessage) {
        if (response == null) {
            return null;
        } else {
            if (showSuccessToast) {
                this.toasterService.showToast('Success', toastMessage ? toastMessage : 'Operation successful.', null, ToastEventType.Success, true, 2000);
            }

            return response.body;
        }
    }

    private handleError(error: any, showErrorToast: boolean = true) {
        let errMsg: string;
        if (error.error) {
            errMsg = error.error.message;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.log(errMsg);

        this.authTokenService.checkMaintenanceMode(error);

        if (errMsg && errMsg.toLowerCase().indexOf('refreshpage') > -1) {
            this.get(environment.apiUrl + '/Property/RefreshUserToken').subscribe(
                (response) => {
                    this.authTokenService.refreshToken(response);
                    this.showRefreshPopup.next(true);
                },
                (error2) => {
                    this.toasterService.showToast('Error', errMsg, null, ToastEventType.Error, true, 5000);
                    // error while refreshing token
                    // log off user
                }
            );
        } else {
            if (showErrorToast) {
                this.toasterService.showToast('Error', errMsg, null, ToastEventType.Error, true, 5000);
            }

            if (errMsg && (errMsg.toLowerCase().indexOf('login token has expired') > -1 || errMsg.toLowerCase().indexOf('your account has been disabled') > -1)) {
                this.authTokenService.logout();
                this.router.navigate(['login']);
            }

            return observableThrowError({ status: error.status, message: errMsg });
        }
    }
}
