<div class="pricing-plans-wrapper flex">
    <ng-container *ngIf="!inactiveRatePlans">
        <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>
                Price Plans
                <div class="currency" *ngIf="currencyText">
                    ( <span class="currency-symbol">{{ currencyContext.Symbol }}</span>
                    <span class="currency-text"> - All values are in {{ currencyContext.Name }} )</span>
                </div>
            </h2>

            <div class="options flex">
                <div class="form-item rate">
                    <div class="custom-label">Rate schedule</div>
                    <dx-select-box
                        class="custom-select"
                        [(value)]="rateScheduleId"
                        placeholder="Choose a rate schedule"
                        [dataSource]="rateSchedulesWithRatePlans"
                        (onItemClick)="filterByRateSchedule($event)"
                        displayExpr="name"
                        valueExpr="id"
                    >
                    </dx-select-box>
                    <div *ngIf="ratePlan.masterPlan !== null && ratePlanSelected" class="custom-label derived">
                        Derived from
                        {{ ratePlan.masterPlan.name }}
                    </div>
                </div>
                <div class="form-item rate">
                    <div class="custom-label">Rate plan</div>
                    <dx-select-box
                        class="custom-select"
                        [(value)]="ratePlanId"
                        placeholder="Choose a rate plan"
                        [dataSource]="pricePlanPageModel.ratePlans"
                        (onItemClick)="filterByRatePlan($event)"
                        displayExpr="name"
                        valueExpr="id"
                    >
                    </dx-select-box>
                </div>

                <button
                    type="button"
                    *ngIf="ratePlanSelected"
                    (click)="openDeriveOptions()"
                    class="custom-button orange"
                >
                    Derived pricing
                </button>

                <div class="month-wrapper flex">
                    <button type="button" (click)="setDateToToday()" class="custom-button btnRightDateToday">
                        Today
                    </button>
                    <div *ngIf="!prevLoader" class="prev" title="Previous two weeks" (click)="getPreviousTwoWeeks()">
                        <i class="fa fa-angle-left"></i>
                    </div>
                    <div *ngIf="prevLoader" class="loader"></div>
                    <div class="month">{{ currentDateRange }}</div>
                    <div *ngIf="!nextLoader" class="next" title="Next two weeks" (click)="getNextTwoWeeks(true)">
                        <i class="fa fa-angle-right"></i>
                    </div>
                    <div *ngIf="nextLoader" class="loader"></div>
                    <div>
                        <dx-date-box
                            class="header-date-box"
                            type="date"
                            [value]="datesArray[0]"
                            [displayFormat]="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            (onValueChanged)="datePickerFunction($event)"
                        >
                        </dx-date-box>
                    </div>
                </div>
            </div>

            <div class="legend flex" *ngIf="hasChannelManager">
                <div class="l-unavailable flex">
                    <div class="circle"></div>
                    <span>Unavailable</span>
                </div>
                <div class="l-arrival flex">
                    <div class="circle"></div>
                    <span>Closed to Arrival</span>
                </div>
                <div class="l-departure flex">
                    <div class="circle"></div>
                    <span>Closed to Departure</span>
                </div>
            </div>

            <div class="days-heading flex">
                <div class="left-part">
                    <div class="header flex">
                        <div class="h-item">Nights</div>
                    </div>
                </div>
                <div class="right-part">
                    <div class="header flex">
                        <div class="h-item" *ngFor="let date of datesArray" title="{{ date | date: 'dd MMM yyyy' }}">
                            {{ date | date: 'EEE dd' }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="plans-wrapper" *ngIf="ratePlanSelected && pricePlanPageModel.roomTypes.length > 0">
                <div
                    class="plan-item"
                    [ngClass]="{ derived: roomType.slave || (ratePlan.masterPlan !== null && ratePlanSelected) }"
                    *ngFor="let roomType of pricePlanPageModel.roomTypes; let i = index; let finished = last"
                >
                    <div class="left-part left">
                        <ng-container *ngIf="roomType.nights.length > 0">
                            <div
                                class="room-row flex"
                                *ngFor="let night of roomType.nights | slice: 0:1"
                                [ngStyle]="{
                                    margin: !roomType.nights.length || roomType.nights.length === 1 ? '0' : ''
                                }"
                            >
                                <div class="nights"><span>{{ night.minLoS }}</span></div>

                                    <div class="flex">
                                        <div class="icon-container">
                                            <div
                                            title="Edit occupancy rules"
                                            class="fas fa-pen-to-square fa-icon fa-icon-small"
                                            [ngClass]="[!roomType.rulesAreDefined ? 'red' : 'gray']"
                                            (click)="openOccupancyRules(roomType.roomType.id)"
                                            >
                                            </div>
                                        </div>
                                        <div class="icon-container">
                                            <div
                                                title="Edit dynamic pricing"  
                                                class="fas fa-hourglass-half fa-icon fa-icon-small hourglass"                                      
                                                (click)="openDynamicPricing(roomType.roomType.id)"
                                                [ngClass]="[roomType.dynamicPricingDefined ? 'blue' : 'gray']"
                                            ></div>
                                        </div>
                                        <div class="icon-container">
                                            <div
                                                *ngIf="ratePlan.masterPlan == null && !roomType.slave && permissionsModel.create"
                                                (click)="openAddPricePlan(roomType.slave, roomType.roomType, roomType.nights,null,null)"
                                                class="fas fa-square-plus fa-icon gray fa-icon-medium"
                                                title="Add new minimum length of stay" 
                                            >
                                            </div>
                                        </div>
  
                                </div>
                                <div class="room-name">
                                    <div (click)="editRoom(roomType.roomType.id)">
                                        <i [ngClass]="[theme == 2 ? 'dark-theme' : '']" class="fa-solid fa-link fa-icon fa-icon-xsmall blue link-icon"></i>
                                        {{ roomType.roomType.shortName }}
                                    </div>
                                    <div *ngIf="roomType.slave" class="derived-label">
                                        Derived from {{ roomType.roomType.masterRoomTypeName }}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="roomType.nights.length == 0">
                            <div
                                class="room-row flex"
                                [ngStyle]="{
                                    margin: !roomType.nights.length || roomType.nights.length === 1 ? '0' : ''
                                }"
                            >
                                <div class="nights"></div>
                                <div class="flex">
                                    <div class="icon-container">
                                        <div
                                        title="Edit occupancy rules"
                                        class="fas fa-pen-to-square fa-icon fa-icon-small"
                                        [ngClass]="[!roomType.rulesAreDefined ? 'red' : 'gray']"
                                        (click)="openOccupancyRules(roomType.roomType.id)"
                                        >
                                        </div>
                                    </div>
                                    <div class="icon-container">
                                        <div
                                            title="Edit dynamic pricing"  
                                            class="fas fa-hourglass-half fa-icon fa-icon-small hourglass"                                      
                                            (click)="openDynamicPricing(roomType.roomType.id)"
                                            [ngClass]="[roomType.dynamicPricingDefined ? 'blue' : 'gray']"
                                        ></div>
                                    </div>
                                    <div class="icon-container">
                                        <div
                                            *ngIf="ratePlan.masterPlan == null && !roomType.slave && permissionsModel.create"
                                            (click)="openAddPricePlan(roomType.slave,roomType.roomType,roomType.nights,null,null)"
                                            class="fas fa-square-plus fa-icon gray fa-icon-medium"
                                            title="Add new minimum length of stay"                                       
                                            
                                        >                                        
                                        </div>
                                    </div>
                                </div>

                                <div class="room-name">
                                    <div (click)="editRoom(roomType.roomType.id)">
                                        <i class="fa-solid fa-link fa-icon fa-icon-xsmall blue link-icon"></i>
                                        {{ roomType.roomType.shortName }}
                                    </div>
                                    <div *ngIf="roomType.slave" class="derived-label">
                                        Derived from {{ roomType.roomType.masterRoomTypeName }}
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div
                            class="room-row flex"
                            *ngFor="let night of roomType.nights | slice: 1; let lastNight = last"
                            [ngStyle]="{
                                margin: lastNight || !roomType.nights.length || roomType.nights.length === 1 ? '0' : ''
                            }"
                        >
                            <div class="nights">{{ night.minLoS }}</div>
                        </div>
                        <div *ngIf="roomType.otaRestriction" class="restriction-label right">
                            <strong>OTA Restriction</strong>
                        </div>
                    </div>
                    <div class="right-part right">
                        <ng-container *ngIf="roomType.nights.length > 0">
                            <div
                                class="month-row flex"
                                *ngFor="let night of roomType.nights; let nightIndex = index; let last = last"
                            >
                                <div class="price-item" *ngFor="let price of night.allPrices; let priceIndex = index">
                                    <ng-container *ngIf="price.baseRate > 0">
                                        <div
                                            class="base-price"
                                            id="link{{ i }}{{ nightIndex }}{{ priceIndex }}"
                                            (mouseenter)="showPopup($event, price)"
                                            (mouseleave)="hidePopup(price)"
                                            (click)="
                                                openEditPricePlan(
                                                    roomType.slave,
                                                    roomType.roomType,
                                                    roomType.nights,
                                                    price,
                                                    price.priceDateString
                                                )
                                            "
                                        >
                                            {{ price.finalRate | number: '1.2-5' }}

                                            <dx-popover
                                                target="#link{{ i }}{{ nightIndex }}{{ priceIndex }}"
                                                position="top"
                                                [width]="300"
                                                [(visible)]="price.show"
                                            >
                                                <dxo-animation>
                                                    <dxo-show type="pop" [from]="{ scale: 0 }" [to]="{ scale: 1 }">
                                                    </dxo-show>
                                                    <dxo-hide type="fade" [from]="1" [to]="0"> </dxo-hide>
                                                </dxo-animation>
                                                <div *dxTemplate="let data = model; of: 'content'">
                                                    <div class="img-actions">
                                                        <div class="flex header">
                                                            <div class="type bold">
                                                                {{ ratePlan.shortName }} -
                                                                {{ roomType.roomType.shortName }}
                                                            </div>
                                                            <div class="date">{{ price.priceDateString }}</div>
                                                        </div>
                                                        <div class="pricing-type">
                                                            <ng-container *ngIf="ratePlan.masterPlan != null">
                                                                Pricing type: {{ ratePlan.masterPlan.pricingType }}
                                                            </ng-container>
                                                            <ng-container *ngIf="ratePlan.masterPlan == null">
                                                                Pricing type: {{ ratePlan.pricingType }}
                                                            </ng-container>
                                                        </div>
                                                        <div class="pricing-type">
                                                            Availability: {{ availabilityRooms }}
                                                        </div>
                                                        <div class="pax" *ngIf="price.rulesAreDefined">
                                                            <p *ngFor="let e of price.priceModel">
                                                                {{ e.numberOfGuests }}
                                                                <ng-container *ngIf="e.numberOfGuests == 1"
                                                                    >person</ng-container
                                                                >
                                                                <ng-container *ngIf="e.numberOfGuests > 1"
                                                                    >persons</ng-container
                                                                >
                                                                =
                                                                <span class="bold"
                                                                    >{{ currencyContext.Symbol }}{{ e.price }}</span
                                                                >
                                                            </p>
                                                        </div>
                                                        <div *ngIf="!price.rulesAreDefined">
                                                            {{ price.additionalInfo }}
                                                        </div>
                                                        <div class="rate">
                                                            Base price =
                                                            <span class="bold"
                                                                >{{ currencyContext.Symbol }}{{ price.finalRate }}</span
                                                            >
                                                        </div>
                                                        <div
                                                            class="rate"
                                                            *ngIf="
                                                                price.additionalRate != 0 &&
                                                                price.additionalRate != null
                                                            "
                                                        >
                                                            Extra adult price =
                                                            <span class="bold"
                                                                >{{ currencyContext.Symbol
                                                                }}{{ price.additionalRate }}</span
                                                            >
                                                        </div>
                                                        <div
                                                            class="rate"
                                                            *ngIf="
                                                                price.childrenRate != 0 && price.childrenRate != null
                                                            "
                                                        >
                                                            Children price =
                                                            <span class="bold"
                                                                >{{ currencyContext.Symbol
                                                                }}{{ price.childrenRate }}</span
                                                            >
                                                        </div>
                                                        <div *ngIf="roomType.slave || ratePlan.masterPlan != null">
                                                            <span class="redLetters"
                                                                >This pricing is derived and cannot be edited here</span
                                                            >
                                                        </div>
                                                        <div *ngIf="price.dynamicPricingRuleApplied" class="flex header">
                                                            <div class="type bold">                                                            
                                                            </div>
                                                            <div class="date">Dynamic pricing rule applied</div> 
                                                        </div>   
                                                    </div>
                                                </div>
                                            </dx-popover>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="price.baseRate == 0">
                                        <div
                                            class="base-price"
                                            (click)="
                                                openEditPricePlan(
                                                    roomType.slave,
                                                    roomType.roomType,
                                                    roomType.nights,
                                                    price,
                                                    price.priceDateString
                                                )
                                            "
                                        ></div>
                                    </ng-container>
                                    <div
                                        class="legend-price {{ roomType.closeOuts[priceIndex].status.toLowerCase() }}"
                                        *ngIf="last && roomType.otaRestriction"
                                        (click)="openCloseOutModal(roomType.closeOuts[priceIndex])"
                                    ></div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="roomType.nights.length == 0">
                            <div class="month-row flex">
                                <ng-container
                                    *ngFor="let item of datesArray; let datesIndex = index; let lastDate = last"
                                >
                                    <div class="price-item empty-weekend">
                                        <div
                                            class="base-price"
                                            (click)="
                                                openAddPricePlan(
                                                    roomType.slave,
                                                    roomType.roomType,
                                                    roomType.nights,
                                                    null,
                                                    item
                                                )
                                            "
                                        ></div>
                                        <div
                                            class="legend-price {{
                                                roomType.closeOuts[datesIndex].status.toLowerCase()
                                            }}"
                                            (click)="openCloseOutModal(roomType.closeOuts[datesIndex])"
                                            *ngIf="roomType.otaRestriction"
                                        ></div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="plans-wrapper" *ngIf="!ratePlanSelected">
                <p>Please choose Rate plan to view prices.</p>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="inactiveRatePlans" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <div class="custom-popup-message">
            <h4>There are no active Rate Plans</h4>
        </div>
    </ng-container>
</div>

<div class="loader" *ngIf="loader" [ngClass]="[theme == 2 ? 'dark-theme' : '']"></div>

<app-pricing-plans-details
    *ngIf="insertRegular"
    [(insertRegular)]="insertRegular"
    [ratePlansSource]="pricePlanPageModel.ratePlans"
    [roomTypeBaseModel]="roomTypeBaseModel"
    [rateScheduleId]="rateScheduleId"
    [ratePlan]="ratePlan"
    [dateFromInput]="dateFromInput"
    [dateToInput]="dateToInput"
    (update)="init()"
    [roomBased]="roomBased"
>
</app-pricing-plans-details>
<app-pricing-plans-details
    *ngIf="updateRegular"
    [(updateRegular)]="updateRegular"
    [rateScheduleId]="rateScheduleId"
    [nights]="nights"
    [selectedPrice]="selectedPrice"
    [ratePlansSource]="pricePlanPageModel.ratePlans"
    [roomTypeBaseModel]="roomTypeBaseModel"
    [ratePlan]="ratePlan"
    [dateFromInput]="dateFromInput"
    [dateToInput]="dateToInput"
    (update)="init()"
    [roomBased]="roomBased"
>
</app-pricing-plans-details>
<app-pricing-plans-details
    *ngIf="insertWeekday"
    [(insertWeekday)]="insertWeekday"
    [ratePlansSource]="pricePlanPageModel.ratePlans"
    [rateScheduleId]="rateScheduleId"
    [roomTypeBaseModel]="roomTypeBaseModel"
    [ratePlan]="ratePlan"
    [dateFromInput]="dateFromInput"
    [dateToInput]="dateToInput"
    (update)="init()"
    [roomBased]="roomBased"
>
</app-pricing-plans-details>
<app-pricing-plans-details
    *ngIf="updateWeekday"
    [(updateWeekday)]="updateWeekday"
    [nights]="nights"
    [selectedPrice]="selectedPrice"
    [rateScheduleId]="rateScheduleId"
    [roomTypeBaseModel]="roomTypeBaseModel"
    [ratePlansSource]="pricePlanPageModel.ratePlans"
    [dateFromInput]="dateFromInput"
    [dateToInput]="dateToInput"
    [ratePlan]="ratePlan"
    (update)="init()"
    [roomBased]="roomBased"
>
</app-pricing-plans-details>
<app-dynamic-pricing
    *ngIf="dynamicPricing"
    [(dynamicPricing)]="dynamicPricing"
    [ratePlan]="ratePlan"
    [roomTypesSource]="pricePlanPageModel.roomTypes"
    [roomTypeId]="roomTypeId"
    (update)="init()"
></app-dynamic-pricing>
<app-occupancy-rules
    *ngIf="occupancyRules"
    [(occupancyRules)]="occupancyRules"
    [ratePlan]="ratePlan"
    [roomTypesSource]="pricePlanPageModel.roomTypes"
    [roomTypeId]="roomTypeId"
    (update)="init()"
></app-occupancy-rules>
<app-derive-options
    *ngIf="deriveOptions"
    [(deriveOptions)]="deriveOptions"
    (update)="init()"
    [ratePlanId]="ratePlan.id"
>
</app-derive-options>

<app-close-out-details
    *ngIf="showCloseOut"
    [(showCloseOut)]="showCloseOut"
    [closeOutSource]="closeOutPopUpSource"
    [dateFrom]="closeOutDateFrom"
    [roomTypeId]="closeOutRoomTypeId"
    [ratePlanId]="closeOutRatePlanId"
    [status]="closeOutStatus"
    (update)="init()"
>
</app-close-out-details>

<app-room-type-edit
    *ngIf="editRoomType"
    [(editRoomType)]="editRoomType"
    [hasOrder]="false"
    [idOfRoomType]="roomTypeId"
    (update)="init()"
></app-room-type-edit>
