export class RatePlanBaseModel {
    public id: number;
    public propertyId: number;
    public name: string;
    public shortName: string;
    public promoCodes: boolean;
    public includeChildren: boolean;
    public bookingType: string;
    public pricingType: string;
}
