import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { environment } from '../../../../environments/environment';
import { XeroMappingModel } from 'src/app/models/xero/xero-mapping.model';


@Injectable()
export class XeroService {
    private getXeroConfigurationUrl = environment.apiUrl + '/Xero/GetXeroConfiguration';
    private cancelXeroAuthorisationUrl = environment.apiUrl + '/Xero/CancelXeroAuthorisation';
    private saveXeroConfigurationUrl = environment.apiUrl + '/Xero/SaveXeroConfiguration';
    private createXeroConfigurationUrl = environment.apiUrl + '/Xero/CreateXeroMappings';
    private postTenantSetSyncUrl = environment.apiUrl + '/Xero/PostTenantSetSync';

    constructor(private http: HttpRequestService) {}

    getXeroConfiguration() {
        return this.http.get(this.getXeroConfigurationUrl);
    }

    cancelXeroAuthorisation() {
        return this.http.post(this.cancelXeroAuthorisationUrl, null, false);
    }

    saveXeroConfiguration(model: XeroMappingModel[])
    {
        return this.http.post(this.saveXeroConfigurationUrl, model, true);
    }

    createXeroConfiguration() {
        return this.http.post(this.createXeroConfigurationUrl, null, false);
    }
    
    postTenantSetSync() {
        console.log('postTenantSetSync')
        return this.http.post(this.postTenantSetSyncUrl, null, false);
    }
}
