import { GridRequest, GridConfiguration } from '../../shared/helpers/dx-grid-helper';

// export class BookingSearchFilterModel {
//     arrivalFrom: Date;
//     arrivalTo: Date;
//     departureFrom: Date;
//     departureTo: Date;
//     bookedFrom: Date;
//     bookedTo: Date;
//     statuses: number[];
//     roomIds: number[];
//     roomTypeIds: number[];
// }

export class BookingSearchFilterModel {
    arrival: ArrivalFilterModel;
    departure: DepartureFilterModel;
    bookedOn: BookedOnFilterModel;
    status: StatusFilterModel;
    room: RoomFilterModel;
    roomType: RoomTypeFilterModel;
    promoCode: PromoCodeFilterModel;
    partner: PartnerFilterModel;
    source: SourceFilterModel;
    accomodationSearch: Boolean;
    functionSearch: Boolean;
    functionRoom: RoomFilterModel;
    functionStatus: StatusFilterModel;
    functionBookedOn: BookedOnFilterModel;
    functionArrival: ArrivalFilterModel;
    functionPartner: PartnerFilterModel;
    ota: OtaFilterModel;

    constructor() {
        this.arrival = new ArrivalFilterModel();
        this.arrival.arrivalFrom = new Date();
        this.arrival.arrivalTo = new Date();
        this.departure = new DepartureFilterModel();
        this.departure.departureFrom = new Date();
        this.departure.departureTo = new Date();
        this.bookedOn = new BookedOnFilterModel();
        this.bookedOn.bookedFrom = new Date();
        this.bookedOn.bookedTo = new Date();
        this.status = new StatusFilterModel();
        this.room = new RoomFilterModel();
        this.roomType = new RoomTypeFilterModel();
        this.promoCode = new PromoCodeFilterModel();
        this.partner = new PartnerFilterModel();
        this.source = new SourceFilterModel();
        this.accomodationSearch = true;
        this.functionSearch = false;
        this.functionRoom = new RoomFilterModel();
        this.functionStatus = new StatusFilterModel();
        this.functionBookedOn = new BookedOnFilterModel();
        this.functionBookedOn.bookedFrom = new Date();
        this.functionBookedOn.bookedTo = new Date();
        this.functionArrival = new ArrivalFilterModel();
        this.functionArrival.arrivalFrom = new Date();
        this.functionArrival.arrivalTo = new Date();
        this.functionPartner = new PartnerFilterModel();
        this.ota = new OtaFilterModel();
  }
  
}

export class BookingSearchRequestModel extends GridRequest {
    filters: BookingSearchFilterModel;
    manageInvoicesPage = false;
}

export class BookingSearchConfigurationModel extends GridConfiguration {
    constructor() {
        super();
        this.columns = [
            {
                dataField: 'bookingNo',
                caption: 'Ref #',
                width: '70',
            },
            {
                dataField: 'functionName',
                caption: 'Function',
                width: '110',
                visible: false
            },
            {
                dataField: 'guest',
                caption: 'Guest',
                allowSorting: false,
                width: 110,
            },
            {
                dataField: 'arrivalDate',
                caption: 'Arrival',
                allowSorting: false,
                width: 110,
            },
            {
                dataField: 'departureDate',
                caption: 'Departure',
                allowSorting: false,
                width: 110,
            },
            {
                dataField: 'nights',
                caption: 'Nights',
                allowSorting: false,
                // alignment: 'center',
                cellTemplate: 'nights',
                width: 60,
            },

            {
                dataField: 'bookedOnDate',
                cellTemplate: 'bookedOnDate',
                caption: 'Booked On',
                allowSorting: true,
                width: 110,
            },

            {
                dataField: 'notes',
                caption: 'Note',
                allowSorting: false,
                width: 140,
            },
            {
                cellTemplate: 'indicator',
                caption: '',
                allowSorting: false,
                width: 30,
            },
            {
                dataField: 'total',
                caption: 'Total',
                allowSorting: false,
                cellTemplate: 'total',
                width: 85,
            },
            {
                dataField: 'balance',
                caption: 'Balance',
                allowSorting: false,
                cellTemplate: 'balance',
                width: 90,
            },
            {
                dataField: 'status',
                caption: 'Status',
                allowSorting: true,
                cellTemplate: 'status',
                width: 75,
            },
            {
                cellTemplate: 'statusIcon',
                caption: '',
                allowSorting: false,
                width: 25,
            },
            {
                dataField: 'madeBy',
                caption: 'Made by',
                allowSorting: false,
                width: 110,
            },
            {
                dataField: 'dateCancelled',
                cellTemplate: 'dateCancelled',
                caption: 'Date Cancelled',
                allowSorting: true,
                width: 115,
                visible: false,
            },
        ];

        this.customSearch = [
            {
                fieldName: 'searchString',
                value: '',
            },
        ];

        this.pager = {
            showPageSizeSelector: false,
            showNavigationButtons: true,
            allowedPageSizes: [5, 10],
            pageSize: 20,
            showInfo: false,
        };

        this.paging = {
            enabled: true,
            pageIndex: 0,
            pageSize: 20,
        };
    }

    mapGridRequest(gridRequest: GridRequest, loadOptions: any) {
        super.mapGridRequest(gridRequest, loadOptions);
        gridRequest.options.customSearch = this.customSearch;
    }
}

class FilterModel {
    filter: boolean;

    constructor() {
        this.filter = false;
    }
}

class ArrivalFilterModel extends FilterModel {
    arrivalFrom: Date;
    arrivalTo: Date;    
}

class DepartureFilterModel extends FilterModel {
    departureFrom: Date;
    departureTo: Date;
}

class BookedOnFilterModel extends FilterModel {
    bookedFrom: Date;
    bookedTo: Date;
}

class StatusFilterModel extends FilterModel {
    statuses: number[];
}

class RoomFilterModel extends FilterModel {
    roomIds: number[];
}

class RoomTypeFilterModel extends FilterModel {
    RoomTypeIds: number[];
}

class PromoCodeFilterModel extends FilterModel {
    promoCode: string;
}

class PartnerFilterModel extends FilterModel {
    partnerIds: number[];
}

class SourceFilterModel extends FilterModel {
    sources: string[];
}

class OtaFilterModel extends FilterModel {
    otas: string[];
}
