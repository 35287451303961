<div class="toaster position-fixed">
    <div *ngFor="let toast of currentToasts;index as i">

        <div #toastElement
        class="toast fade mt-2"
        [ngClass]="toast.type">
    
            <div class="toast-icon-col">
                <i [ngClass]="toast.iconCssClass" class="mr-5"></i>
            </div>
            <div class="col-2">
                <div class="toast-header"> 
                    <div class="toast-title"><strong>{{toast.title}}</strong></div>
                    <div class="toast-close"><i class="fa-solid fa-circle-xmark" *ngIf="toast.showCloseButton" (click)="removeAlert(toast)"></i></div>
                </div>
                <div class="toast-body mt-1">
                    <div class="toast-primary">{{toast.primaryMessage}}</div>
                    <div class="toast-secondary mt-2">{{toast.secondaryMessage}}</div>
                </div>
            
            </div>
       
        </div>
        

    </div>

</div>

<div class="alerts custom-box" *ngIf="alertToasts !=null && alertToasts.length > 0">
    <div class="alert-container">
        <div *ngFor="let toast of alertToasts;index as i">

            <div #alertElement
            class="toast fade mt-2"
            [ngClass]="toast.type">
        
                <div class="toast-icon-col">
                    <i [ngClass]="toast.iconCssClass" class="mr-5"></i>
                </div>
                <div class="col-2">
                    <div class="toast-header"> 
                        <div class="toast-title"><strong>{{toast.title}}</strong></div>
                    </div>
                    <div class="toast-body mt-1">
                        <div class="toast-primary">{{toast.primaryMessage}}</div>
                        <div class="toast-secondary mt-2">{{toast.secondaryMessage}}</div>
                    </div>
                
                </div>
        
            </div>
            
            <div class="close-alert-container mt-20 text-center">
                <button type="button" (click)="removeAlert(toast)" class="custom-button smaller red">Close</button>
            </div>
            
        </div>
        
    </div>
</div>