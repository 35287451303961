<!--<app-header></app-header>-->

<div class="signup-wrapper flex" [ngStyle]="{ 'background-image': 'url(' + imageUrl + ')' }">
    <div class="custom-box" *ngIf="!passCode">
        <div class="logo-img">
            <img src="../../../assets/images/logo.svg" alt="" />
        </div>
        <h2>Sign up</h2>

        <form [formGroup]="valForm" (ngSubmit)="registerSubmitForm($event)">
            <div class="form-item">
                <div class="custom-label">First Name</div>
                <input
                    [ngClass]="[
                        !checkForSpaces(registrationModel.firstName) &&
                        !valForm.controls['firstName'].hasError('required')
                            ? 'invalid-field'
                            : ''
                    ]"
                    name="firstName"
                    class="custom-input"
                    formControlName="firstName"
                    [(ngModel)]="registrationModel.firstName"
                />
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['firstName'].hasError('required') &&
                        (valForm.controls['firstName'].dirty || valForm.controls['firstName'].touched)
                    "
                    >First name is required</span
                >
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['firstName'].hasError('maxlength') &&
                        (valForm.controls['firstName'].dirty || valForm.controls['firstName'].touched)
                    "
                    >Max 50 characters</span
                >
                <span
                    class="text-danger"
                    *ngIf="
                        !checkForSpaces(registrationModel.firstName) &&
                        !valForm.controls['firstName'].hasError('required')
                    "
                    >Cannot contain only blank characters</span
                >
            </div>
            <div class="form-item">
                <div class="custom-label">Last Name</div>
                <input
                    [ngClass]="[
                        !checkForSpaces(registrationModel.lastName) &&
                        !valForm.controls['lastName'].hasError('required')
                            ? 'invalid-field'
                            : ''
                    ]"
                    name="lastName"
                    formControlName="lastName"
                    [(ngModel)]="registrationModel.lastName"
                    class="custom-input"
                />
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['lastName'].hasError('required') &&
                        (valForm.controls['lastName'].dirty || valForm.controls['lastName'].touched)
                    "
                    >Last name is required</span
                >
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['lastName'].hasError('maxlength') &&
                        (valForm.controls['lastName'].dirty || valForm.controls['lastName'].touched)
                    "
                    >Max 50 characters</span
                >
                <span
                    class="text-danger"
                    *ngIf="
                        !checkForSpaces(registrationModel.lastName) &&
                        !valForm.controls['lastName'].hasError('required')
                    "
                    >Cannot contain only blank characters</span
                >
            </div>
            <div class="form-item">
                <div class="custom-label">Property name</div>
                <input
                    [ngClass]="[
                        !checkForSpaces(registrationModel.propertyName) &&
                        !valForm.controls['property'].hasError('required')
                            ? 'invalid-field'
                            : ''
                    ]"
                    name="property"
                    formControlName="property"
                    [(ngModel)]="registrationModel.propertyName"
                    class="custom-input"
                />
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['property'].hasError('required') &&
                        (valForm.controls['property'].dirty || valForm.controls['property'].touched)
                    "
                    >Property is required</span
                >
                <span
                    class="text-danger"
                    *ngIf="
                        !checkForSpaces(registrationModel.propertyName) &&
                        !valForm.controls['property'].hasError('required')
                    "
                    >Cannot contain only blank characters</span
                >
            </div>
            <div class="form-item">
                <div class="custom-label">Username</div>
                <input
                    [ngClass]="[
                        !checkForSpaces(registrationModel.userName) &&
                        !valForm.controls['username'].hasError('required')
                            ? 'invalid-field'
                            : ''
                    ]"
                    name="username"
                    (keyup)="clearValidationExist()"
                    formControlName="username"
                    [(ngModel)]="registrationModel.userName"
                    type="username"
                    class="custom-input"
                />
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['username'].hasError('required') &&
                        (valForm.controls['username'].dirty || valForm.controls['username'].touched)
                    "
                    >Username is required</span
                >
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['username'].hasError('maxlength') &&
                        (valForm.controls['username'].dirty || valForm.controls['username'].touched)
                    "
                    >Max 50 characters</span
                >
                <span
                    class="text-danger"
                    *ngIf="
                        !checkForSpaces(registrationModel.userName) &&
                        !valForm.controls['username'].hasError('required')
                    "
                    >Cannot contain only blank characters</span
                >
            </div>
            <div class="form-item">
                <div class="custom-label">Property email</div>
                <input
                    name="email"
                    (change)="checkEmail()"
                    (keyup)="clearValidationExist()"
                    formControlName="email"
                    [(ngModel)]="registrationModel.email"
                    type="email"
                    class="custom-input"
                />
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['email'].hasError('required') &&
                        (valForm.controls['email'].dirty || valForm.controls['email'].touched)
                    "
                    >Email is required</span
                >
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['email'].hasError('maxlength') &&
                        (valForm.controls['email'].dirty || valForm.controls['email'].touched)
                    "
                    >Max 50 characters</span
                >
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['email'].hasError('email') &&
                        (valForm.controls['email'].dirty || valForm.controls['email'].touched)
                    "
                    >Must be a valid email address</span
                >
                <span class="text-danger" *ngIf="showEmailError">Email exists.</span>
            </div>
            <div class="form-item">
                <div class="custom-label">Property phone number</div>
                <input
                    name="email"
                    (keyup)="clearValidationExist()"
                    formControlName="telephone"
                    [(ngModel)]="registrationModel.phone"
                    class="custom-input"
                />
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['telephone'].hasError('maxlength') &&
                        (valForm.controls['telephone'].dirty || valForm.controls['telephone'].touched)
                    "
                    >Max 50 characters</span
                >
            </div>
            <div class="form-item" *ngIf="countries && countries.length > 0">
                <div class="custom-label">Country</div>
                <select class="custom-select" (change)="changeCountry($event)" name="country" formControlName="country">
                    <option value="" disabled>Select country...</option>
                    <option *ngFor="let country of countries" [ngValue]="country.id">{{ country.name }}</option>
                </select>
                <!-- <dx-select-box
          class="custom-select"
          [dataSource]="countries"
          formControlName="country"
          name="country"
          displayExpr="name"
          valueExpr="id"
          [(value)]="registrationModel.countryId"
          placeholder="Select country...">
        </dx-select-box> -->
                <span
                    class="text-danger country-required-label"
                    *ngIf="
                        valForm.controls['country'].hasError('required') &&
                        (valForm.controls['country'].dirty || valForm.controls['country'].touched)
                    "
                    >Country is required</span
                >
            </div>
            <div class="form-item terms">
                <input
                    type="checkbox"
                    name="acceptedTerms"
                    formControlName="acceptedTerms"
                    [(ngModel)]="registrationModel.isAcceptedTerms"
                    id="checkbox1"
                    class="css-checkbox"
                />
                <label for="checkbox1" class="css-label radGroup1"></label>
                <div class="custom-label inline">Accept Terms and Conditions</div>
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['acceptedTerms'].hasError('required') &&
                        (valForm.controls['acceptedTerms'].dirty || valForm.controls['acceptedTerms'].touched)
                    "
                    >You must agree terms and conditions</span
                >
                <button
                    *ngIf="!loader"
                    type="button"
                    (click)="termsAndConditionsPopup()"
                    class="custom-button orange terms-and-conditions"
                >
                    Terms and Conditions
                </button>
            </div>
            <div class="actions flex">
                <div class="info">
                    Already registered?<br />
                    <div routerLink="/login" class="custom-link">Log in here</div>
                </div>
                <button *ngIf="!loader" type="submit" class="custom-button blue">Next</button>
                <div *ngIf="loader" class="loader"></div>
            </div>
        </form>
    </div>
    <div *ngIf="showTermsAndConditionsPopup" class="terms-and-conditions-wrapper flex">
        <div class="overlay flex">
            <div class="custom-box popup messageLogLink" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
                <div [innerHTML]="termsAndConditions"></div>
                <div class="buttons-wrapper">
                    <button type="button" class="custom-button red left" (click)="closeTermsAndConditionsPopup()">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showActivatedSuccesMessage" class="activation-wrapper flex">
        <div class="overlay flex">
            <div class="custom-box popup messageLogLink" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
                <h2>Activation</h2>
                <p>
                    Activation e-mail sent to {{ propertyEmail }}
                    Please click the link inside the email to verify your address and log in to your account.
                </p>
            </div>
        </div>
    </div>
</div>
<!-- <app-confirmation  [(confirmation)]="confirmation" [propertyEmail]="propertyEmail"  *ngIf="confirmation" ></app-confirmation> -->
<!--<app-pass-code *ngIf="passCode" [(passCode)]="passCode" (onClose)="validPass()"></app-pass-code>-->
