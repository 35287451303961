import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RatePlanModel } from '../../../../../models/rate-plan/rate-plan.model';
import { RatePlanService } from '../rate-plan.service';
import { AuthTokenService } from '../../../../../shared/services/auth-token.service';
import { RatePlanPropertyModel } from '../../../../../models/rate-plan/rate-plan-property.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { CancellationPolicyModel } from '../../../../../models/policy/cancellation-policy.model';
import { DepositPolicyModel } from '../../../../../models/policy/deposit-policy.model';
import { PaymentPolicyModel } from '../../../../../models/policy/payment-policy.model';
import { CreateRatePlanPDModel } from '../../../../../models/rate-plan/create-rate-plan-pd.model';
import { TrimObjService } from '../../../../../shared/services/obj-trim.service';
import { InventoryRoomTypesPermissionsModel } from '../../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../../enums/permissions.enum';
import { CountryContext } from 'src/app/models/auth-jwt/token-context';
import { TokenContext } from 'src/app/models/auth-jwt/token-context';

@Component({
    selector: 'app-rate-plans-details',
    templateUrl: './rate-plans-details.component.html',
    styleUrls: ['./rate-plans-details.component.less'],
})
export class RatePlansDetailsComponent implements OnInit {
    @Input() detailsPopup: boolean;
    @Output() detailsPopupChange = new EventEmitter<boolean>();
    @Input() ratePlanId: number;
    @Input() hasOrder: boolean;
    @Input() addRatePlan: boolean;
    @Output() addRatePlanChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();
    @Input() displayOrderList: number[];

    token: TokenContext;
    permissionsModel: InventoryRoomTypesPermissionsModel;

    loader = false;
    saleItemsPopup = false;
    costFees = false;
    rateSchedules = false;
    deriveOptions = false;
    eposAllowance = false;
    alertDialog = false;
    theme: number;

    valForm: UntypedFormGroup;

    ratePlanModel = new RatePlanModel();
    ratePlanPropertyModelList: RatePlanPropertyModel[];
    createRatePlanPDModel = new CreateRatePlanPDModel();
    countryContext: CountryContext;

    constructor(
        private ratePlanService: RatePlanService,
        private fb: UntypedFormBuilder,
        private trimService: TrimObjService,
        private permissionsService: PermissionsService
    ) {
        this.token = AuthTokenService.decodeJwtToken();
        this.theme = this.token.User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryRoomTypes);
        this.countryContext = this.token.Country;

        this.valForm = this.fb.group({
            id: [null],
            name: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            shortName: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            boardType: [null, Validators.required],
            pricingType: [null, Validators.required],
            activity: [null, Validators.required],
            vat: [null, Validators.required],
            cancelOption: [null],
            description: [null, Validators.required],
            depositType: [null],
            paymentType: [null],
            minlosType: [null, Validators.required],
            orderAppearance: [null, Validators.compose([Validators.required, Validators.min(1)])],
            includeChildren: [null],
            promoCodes: [null],
            excludeFromWeb: [null],
            arrivalDay: [null],
        });

        if (!this.permissionsModel.formButtonEnabled) {
            this.valForm.controls['id'].disable();
            this.valForm.controls['name'].disable();
            this.valForm.controls['shortName'].disable();
            this.valForm.controls['boardType'].disable();
            this.valForm.controls['pricingType'].disable();
            this.valForm.controls['activity'].disable();
            this.valForm.controls['vat'].disable();
            this.valForm.controls['cancelOption'].disable();
            this.valForm.controls['description'].disable();
            this.valForm.controls['depositType'].disable();
            this.valForm.controls['paymentType'].disable();
            this.valForm.controls['minlosType'].disable();
            this.valForm.controls['orderAppearance'].disable();
            this.valForm.controls['includeChildren'].disable();
            this.valForm.controls['promoCodes'].disable();
            this.valForm.controls['excludeFromWeb'].disable();
            this.valForm.controls['arrivalDay'].disable();
        }

        if (AuthTokenService.decodeJwtToken().Property.Id) {
            this.ratePlanModel.propertyId = AuthTokenService.decodeJwtToken().Property.Id;
            this.ratePlanService.getRatePlanForProperty(AuthTokenService.decodeJwtToken().Property.Id).subscribe(
                (response) => {
                    this.ratePlanPropertyModelList = response;
                },
                (error) => console.log(error)
            );
        }
    }

    ngOnInit() {
        this.ratePlanService.newRatePlan().subscribe(
            (response) => {
                this.createRatePlanPDModel = response;
                this.addNoneValues();
                if (this.addRatePlan) {
                    this.ratePlanModel = this.createRatePlanPDModel.ratePlan;
                    this.ratePlanModel.propertyId = AuthTokenService.decodeJwtToken().Property.Id;
                }
            },
            (error) => console.log(error)
        );

        if (this.detailsPopup) {
            this.ratePlanService.getRatePlanById(this.ratePlanId).subscribe(
                (response) => {
                    this.ratePlanModel = response;
                    this.ratePlanModel.bookingTypeOld = this.ratePlanModel.bookingType;
                },
                (error) => console.log(error)
            );
        }
        
        if (!this.hasOrder) {
            this.valForm.controls['orderAppearance'].disable();
        }
    }

    validateDisplayOrder() {
        if (this.hasOrder) {
            for (const number of this.displayOrderList) {
                if (number === this.ratePlanModel.orderNumber) {
                    return false;
                }
            }
        }

        return true;
    }

    addNoneValues() {
        const cancelOption: CancellationPolicyModel = new CancellationPolicyModel();
        cancelOption.id = 0;
        cancelOption.name = 'None';
        this.createRatePlanPDModel.cancellationPolicies.unshift(cancelOption);

        const depositOption: DepositPolicyModel = new DepositPolicyModel();
        depositOption.id = 0;
        depositOption.name = 'None';
        this.createRatePlanPDModel.depositPolicies.unshift(depositOption);

        const paymentOption: PaymentPolicyModel = new PaymentPolicyModel();
        paymentOption.id = 0;
        paymentOption.name = 'None';
        this.createRatePlanPDModel.paymentPolicies.unshift(paymentOption);
    }

    setRatePlan(e) {
        if (e.itemData !== null) {
            const ratePlanId: number = e.itemData.id;
            this.ratePlanService.getRatePlanById(ratePlanId).subscribe(
                (response) => {
                    // update list of order numbers
                    const oldOrderNumber: number = this.ratePlanModel.orderNumber;
                    const newOrderNumber: number = response.orderNumber;
                    const index = this.displayOrderList.indexOf(newOrderNumber);
                    this.displayOrderList.splice(index, 1);
                    this.displayOrderList.push(oldOrderNumber);

                    this.ratePlanModel = response;
                    this.ratePlanId = this.ratePlanModel.id;
                    this.ratePlanModel.bookingTypeOld = this.ratePlanModel.bookingType;
                },
                (error) => console.log(error)
            );
        }
    }

    submitRatePlanDetails(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid && this.validateDisplayOrder()) {
            this.loader = true;
            if (this.detailsPopup) {
                this.validateChangeOfMinLoSType();
            } else if (this.addRatePlan) {
                this.insertRatePlan();
            }
        }
    }

    validateChangeOfMinLoSType() {
        if (this.ratePlanModel.bookingTypeOld !== this.ratePlanModel.bookingType) {
            this.ratePlanService.ratePlanHasPrices(this.ratePlanModel.id).subscribe(
                (response) => {
                    if (response) {
                        this.loader = false;
                        this.alertDialog = true;
                    } else {
                        this.loader = false;
                        this.updateRatePlan();
                    }
                },
                (error) => {
                    console.log(error);
                    this.loader = false;
                }
            );
        } else {
            this.updateRatePlan();
        }
    }

    insertRatePlan() {
        this.ratePlanModel = this.trimService.trimObj(this.ratePlanModel);
        this.ratePlanService.createRatePlan(this.ratePlanModel).subscribe(
            (response) => {
                this.loader = false;
                this.update.emit();
                this.close();
            },
            (error) => {
                this.loader = false;
                console.log(error);
            }
        );
    }

    updateRatePlan() {
        this.ratePlanModel = this.trimService.trimObj(this.ratePlanModel);
        this.ratePlanService.updateRatePlan(this.ratePlanModel).subscribe(
            (response) => {
                this.loader = false;
                this.update.emit();
                this.close();
            },
            (error) => {
                this.loader = false;
                console.log(error);
            }
        );
    }

    openSaleItems() {
        this.saleItemsPopup = true;
    }

    openCostFees() {
        this.costFees = true;
    }

    openRateSchedules() {
        this.rateSchedules = true;
    }

    openDeriveOptions() {
        this.deriveOptions = true;
    }

    openEPOSAllowance() {
        this.eposAllowance = true;
    }

    close() {
        this.detailsPopupChange.emit(false);
        this.addRatePlanChange.emit(false);
    }

    selectionChanged(e) {}
}
