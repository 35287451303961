import { Injectable } from '@angular/core';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { DxDataGridComponent } from 'devextreme-angular';

@Injectable()
export class ExportExcelService {

    constructor() {
    }

    exportToExcel(grid: DxDataGridComponent, selectedCellsOnly: boolean, worksheetName: string, workbookName: string, customiseCell: any) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(worksheetName);

        exportDataGrid({
            component: grid.instance,
            worksheet,
            selectedRowsOnly: selectedCellsOnly,
            customizeCell: customiseCell,
            autoFilterEnabled: true,
          }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), workbookName);
            });
          });
    }

}