    <div class="error-wrapper flex" [ngStyle]="{ 'background-image': 'url(' + imageUrl + ')' }">

        <div class="custom-box">
            <div class="logo-img">
                <img src="../../../assets/images/logo.svg" alt="caterbook logo" />
            </div>
            <h2>Error</h2>
            
            <p>Sorry, it seems that either an error occurred, or that the requested url could not be found</p>

        </div>


    </div>
    