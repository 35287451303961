import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoomTypeRateScheduleModel } from '../../../../../models/rate-schedule/room-type-rate-schedule.model';
import { RateSchedulesService } from '../../../../settings/settings-pricing/rate-schedules/rate-schedules.service';
import * as _ from 'lodash';
import { RateScheduleBaseModel } from '../../../../../models/rate-schedule/rate-schedule-base.model';
import { RoomTypesWithRateSchedulesModel } from '../../../../../models/room-type/room-types-with-rate-schedules.model';
import { AuthTokenService } from '../../../../../shared/services/auth-token.service';
import { PermissionsService } from '../../../../../shared/services/permissions.service';
import { InventoryRoomTypesPermissionsModel } from '../../../../../models/permissions/permissions.model';
import { PermissionsEnum } from '../../../../../enums/permissions.enum';

@Component({
    selector: 'app-room-type-rate-schedule',
    templateUrl: './room-type-rate-schedule.component.html',
    styleUrls: ['./room-type-rate-schedule.component.less'],
})
export class RoomTypeRateScheduleComponent implements OnInit {
    @Input() rateSchedule: boolean;
    @Output() rateScheduleChange = new EventEmitter<boolean>();
    @Input() roomTypeId: number;
    @Input() roomTypeShortName: string;
    @Output() update = new EventEmitter();

    permissionsModel: InventoryRoomTypesPermissionsModel;

    loader = false;
    errorMessage: string;
    scheduleDetails = false;
    popupLoader = true;

    rateScheduleId: number;

    roomTypeRateScheduleModel = new RoomTypeRateScheduleModel();
    roomTypesWithRateSchedules = new RoomTypesWithRateSchedulesModel();
    theme: number;

    constructor(private rateScheduleService: RateSchedulesService, private permissionsService: PermissionsService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryRoomTypes);
    }

    ngOnInit() {
        this.rateScheduleService.getAssignRateSchedulesPopup(this.roomTypeId).subscribe(
            (response) => {
                this.roomTypesWithRateSchedules = response;
                this.roomTypeRateScheduleModel.roomTypeId = this.roomTypeId;
                this.roomTypeRateScheduleModel.rateSchedules = this.roomTypesWithRateSchedules.roomTypeRateSchedules;
                this.popupLoader = false;
            },
            (error) => {
                this.popupLoader = false;
            }
        );
    }

    setRateScheduleToRoomType(e, data) {
        const rateScheduleModel: RateScheduleBaseModel = {
            id: e.value,
            name: this.roomTypesWithRateSchedules.allRateSchedules.find((r) => r.id === e.value).name,
        };
        const index = this.roomTypeRateScheduleModel.rateSchedules.indexOf(data);
        this.roomTypeRateScheduleModel.rateSchedules[index] = rateScheduleModel;
    }

    openRateScheduleDetails(id) {
        this.scheduleDetails = true;
        this.rateScheduleId = id;
    }

    deleteItem(data) {
        const index = this.roomTypeRateScheduleModel.rateSchedules.indexOf(data);
        this.roomTypeRateScheduleModel.rateSchedules.splice(index, 1);
    }

    addNewItem() {
        const rateScheduleModel: RateScheduleBaseModel = new RateScheduleBaseModel();
        rateScheduleModel.id = null;
        this.roomTypeRateScheduleModel.rateSchedules = [
            ...this.roomTypeRateScheduleModel.rateSchedules,
            rateScheduleModel,
        ];
    }

    validateForm(): boolean {
        for (const rateSchedule of this.roomTypeRateScheduleModel.rateSchedules) {
            if (rateSchedule.id === null) {
                this.errorMessage = 'Error! Please fill all fields.';
                return true;
            }
        }

        if (
            _.uniqBy(this.roomTypeRateScheduleModel.rateSchedules, 'id').length !==
            this.roomTypeRateScheduleModel.rateSchedules.length
        ) {
            this.errorMessage = 'Error! You cannot enter same rate schedules.';
            return true;
        }

        this.errorMessage = '';
        return false;
    }

    submitRateSchedulesToRoomTypes() {
        if (!this.validateForm()) {
            this.loader = true;
            this.rateScheduleService.assignRateSchedules(this.roomTypeRateScheduleModel).subscribe(
                (response) => {
                    this.loader = false;
                    this.update.emit();
                    this.close();
                },
                (error) => {
                    this.loader = false;
                    console.log(error);
                }
            );
        }
    }

    close() {
        this.rateScheduleChange.emit(false);
    }
}
