import { environment } from '../../../../environments/environment';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { PropertyVatBaseModel } from '../../../models/property/property-vat-base.model';
import { Injectable } from '@angular/core';

@Injectable()
export class SettingsVatRatesService {
    private getVatsUrl = environment.apiUrl + '/Property/GetVats';
    private getVatUrl = environment.apiUrl + '/Property/GetVat';
    private insertVatUrl = environment.apiUrl + '/Property/InsertVat';
    private updateVatUrl = environment.apiUrl + '/Property/UpdateVat';
    private deleteVatUrl = environment.apiUrl + '/Property/DeleteVat';
    private SetVatRatesUrl = environment.apiUrl + '/Property/SetVatRates';

    constructor(private httpService: HttpRequestService) {}

    getVats() {
        return this.httpService.get(this.getVatsUrl);
    }

    getVatById(id: number) {
        return this.httpService.get(this.getVatsUrl + '?id=' + id);
    }

    insertVat(model: PropertyVatBaseModel) {
        return this.httpService.post(this.insertVatUrl, model);
    }

    updateVat(model: PropertyVatBaseModel) {
        return this.httpService.post(this.updateVatUrl, model);
    }

    deleteVat(id: number) {
        return this.httpService.get(this.deleteVatUrl + '?id=' + id);
    }

    setVatRates(vatRates: PropertyVatBaseModel[]) {
        return this.httpService.post(this.SetVatRatesUrl, vatRates, true);
    }
}
