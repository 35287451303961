import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../shared/services/http-request.service';
import { environment } from '../../../environments/environment';
import { CleaningStatusModel } from '../../models/cleaning-frequency/cleaning-status.model';
import {
    SalesReportsSearchModel,
    ReportsSearchModel,
    RecencyReportsSearchModel,
    FrequencyReportsSearchModel,
    MonetaryReportsSearchModel,
    InvoiceReportsSearchModel,
    PaymentReconciliationReportsSearchModel,
    ReportsSearchStringModel,
    ResidentGuestSearchModel,
    PartnerInvoiceReportsSearchModel,
    PartnerInvoiceReportsSearchParameterStoredModel,
    EndOfDayReportSearchModel,
} from '../../models/reports/reports-search.model';
import { ResidentGuestsReportModel } from '../../models/reports/reports-resident-guests.model';
import { InvoiceProcessModel } from '../../models/booking/invoice-process.model';
import { AuthTokenService } from '../../shared/services/auth-token.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ReportingEposConfigurationModel, ReportingEposShiftConfigurationListsModel} from '../../models/reports/reports-epos-configuration.model';
import DateHelper from 'src/app/shared/helpers/date-helper';
import { ZReadConfigurationModel } from 'src/app/models/reports/zread-configuration.model';

@Injectable()
export class ReportsService {
    private saveCleaningNoteUrl = environment.apiUrl + '/Report/SaveCleaningNote';
    private changeHousekeepingStatusUrl = environment.apiUrl + '/Report/ChangeHousekeepingStatus';
    private getHousekeepingReportUrl = environment.apiUrl + '/Report/GetHousekeepingReport';
    private getCleaningFrequencyReportUrl = environment.apiUrl + '/Report/GetCleaningFrequencyReport';
    private getAllCleaningGroupsUrl = environment.apiUrl + '/Report/GetAllCleaningGroups';
    private updateCleaningReportUrl = environment.apiUrl + '/Report/UpdateCleaningStatus';
    private insertCleaningStatusUrl = environment.apiUrl + '/Report/InsertCleaningStatus';
    private getRoomCleaningStatusesUrl = environment.apiUrl + '/Report/GetRoomCleaningStatuses';
    private getAllLogsForPropertyUrl = environment.apiUrl + '/Property/GetAllLogsForProperty';
    private GetDataForPropertyLogSearchUrl = environment.apiUrl + '/Property/GetDataForPropertyLogSearch';
    private getPropertySummaryReportUrl = environment.apiUrl + '/Report/GetPropertySummaryReport';
    private getRoomTypeAndRatePlanReportUrl = environment.apiUrl + '/Report/GetRoomTypeAndRatePlanReport';
    private getOccupancyRoomReportUrl = environment.apiUrl + '/Report/GetOccupancyRoomReport';
    private getOccupancySummaryPdfUrl = environment.apiUrl + '/Report/GetOccupancySummaryPdf';
    private getOccupancyByRoomPdfUrl = environment.apiUrl + '/Report/GetOccupancyRoomPdf';
    private getOccupancyRTRPRPdfUrl = environment.apiUrl + '/Report/GetOccupancyRoomTypeAndRatePlanPdf';

    // Sales reports url
    private getSalesSummaryReportUrl = environment.apiUrl + '/Report/GetSalesSummaryReport';
    private getSalesItemisedReportUrl = environment.apiUrl + '/Report/GetSalesItemisedReport';
    private getSalesItemisedReportCsvUrl = environment.apiUrl + '/Report/GetSalesItemisedReportCsv';

    // Held deposits reports url
    private getHeldDepositsReportModelUrl = environment.apiUrl + '/Report/GetHeldDepositsReportModel';

    // Residental guest reports url
    private getResidentGuestsReportModelUrl = environment.apiUrl + '/Report/GetResidentGuestsReportModel';
    private getResidentGuestsReportPrintUrl = environment.apiUrl + '/Report/ResidentGuestsPrint';

    // RFM reports url
    private getRecencyReportModelUrl = environment.apiUrl + '/Report/GetRecencyReportModel';
    private getFrequencyReportModelUrl = environment.apiUrl + '/Report/GetFrequencyReportModel';
    private getMonetaryValueReportModelUrl = environment.apiUrl + '/Report/GetMonetaryValueReportModel';
    private getRecencyCsvUrl = environment.apiUrl + '/Report/GetRecencyCsv';
    private getFrequencyCsvUrl = environment.apiUrl + '/Report/GetFrequencyCsv';
    private getMonetaryValueCsvUrl = environment.apiUrl + '/Report/GetMonetaryValueCsv';

    // Invoice reports url
    private getInvoiceStatusesUrl = environment.apiUrl + '/Report/GetInvoiceStatuses';
    private getInvoiceReportUrl = environment.apiUrl + '/Report/GetInvoiceReport';

    // Partner Invoice url
    private getForPartnerInvoiceReportUrl = environment.apiUrl + '/Report/GetForPartnerInvoiceReport';
    private getPartnerInvoiceReportUrl = environment.apiUrl + '/Report/GetPartnerInvoiceReport';
    private ExportPartnerInvoicesToPdfUrl = environment.apiUrl + '/Booking/ExportInvoicesToPdf';
    private EmailPartnerInvoicesUrl = environment.apiUrl + '/Booking/EmailPartnerInvoices';

    // Direct Revenue reports url
    private getDirectRevenueRatioReportUrl = environment.apiUrl + '/Report/GetDirectRevenueRatioReport';

    // End of day reports url
    private getEndOfDayReportUrl = environment.apiUrl + '/Report/GetEndOfDayReport';
    private resetEndOfDayReportUrl = environment.apiUrl + '/Report/ResetEndOfDayReport'; 
    private getEndOfDayReportContentsUrl = environment.apiUrl + '/Report/GetEndOfDayReportContents';
    
    // Payment reconsiliation reports url
    private getPaymentReconciliationReportUrl = environment.apiUrl + '/Report/GetPaymentReconciliationReport';
    private getPaymentReconciliationDetailsReportUrl =
        environment.apiUrl + '/Report/GetPaymentReconciliationDetailsReport';
    private getRoomTypeCsvUrl = environment.apiUrl + '/Report/GetOccupancyRoomTypeCSV';
    private getRatePlanCsvUrl = environment.apiUrl + '/Report/GetOccupancyRatePlanCSV';
    private getRoomCsvUrl = environment.apiUrl + '/Report/GetOccupancyRoomCSV';

    //Report set-up
    private getReportingDateConfigurationUrl = environment.apiUrl + '/ReportSetup/GetReportingDateConfiguration';
    private getReportingEposConfigurationUrl = environment.apiUrl + '/ReportSetup/GetReportingEposConfiguration';
    private saveReportingEposConfigurationUrl = environment.apiUrl + '/ReportSetup/SaveReportingEposConfigurations';
    private getReportingDatesFinalisedUrl = environment.apiUrl + '/ReportSetup/GetReportingDatesFinalised';  
    private getZReadConfigurationUrl = environment.apiUrl + '/ReportSetup/GetZReadInputModel';  
    private saveZreadInputUrl = environment.apiUrl + '/ReportSetup/SaveZReadInput';

    //Pace report
    private getPaceReportUrl = environment.apiUrl + '/Report/GetPaceReport';

    //html -> pdf 
    private convertHtmlToPdfUrl = environment.apiUrl + '/Report/ConvertHtmlToPdf';

    constructor(private http: HttpRequestService, private httpOriginal: HttpClient) {}

    saveCleaningNote(roomId: number, text: string) {
        return this.http.get(this.saveCleaningNoteUrl + '?roomId=' + roomId + '&text=' + text);
    }
    changeHousekeepingStatus(roomId: number, date: string, status: string) {
        return this.http.get(
            this.changeHousekeepingStatusUrl + '?roomId=' + roomId + '&date=' + date + '&status=' + status
        );
    }

    getHousekeepingReport(groupId: number, date: string) {
        return this.http.get(this.getHousekeepingReportUrl + '?groupId=' + groupId + '&date=' + date);
    }

    getCleaningFrequencyReport(groupId: number, date: string) {
        return this.http.get(this.getCleaningFrequencyReportUrl + '?groupId=' + groupId + '&date=' + date);
    }

    getAllCleaningGroups() {
        return this.http.get(this.getAllCleaningGroupsUrl);
    }

    getRoomCleaningStatuses() {
        return this.http.get(this.getRoomCleaningStatusesUrl);
    }

    updateCleaningReport(model: CleaningStatusModel) {
        return this.http.post(this.updateCleaningReportUrl, model, true);
    }

    insertCleaningStatus(model: CleaningStatusModel) {
        return this.http.post(this.insertCleaningStatusUrl, model, true);
    }

    getData(data) {
        return this.http.post(this.getAllLogsForPropertyUrl, data);
    }

    getDataForPropertyLogSearch() {
        return this.http.get(this.GetDataForPropertyLogSearchUrl);
    }

    getPropertySummaryReport(data) {
        return this.http.post(this.getPropertySummaryReportUrl, data);
    }

    getRoomTypeAndRatePlanReport(data) {
        return this.http.post(this.getRoomTypeAndRatePlanReportUrl, data);
    }

    getOccupancyRoomReport(data) {
        return this.http.post(this.getOccupancyRoomReportUrl, data);
    }

    getOccupancySummaryPdf(data) {
        return this.http.postDownload(this.getOccupancySummaryPdfUrl, data);
    }

    getOccupancyByRoomPdf(data) {
        return this.http.postDownload(this.getOccupancyByRoomPdfUrl, data);
    }

    getOccupancyRTRPRPdf(data) {
        return this.http.postDownload(this.getOccupancyRTRPRPdfUrl, data);
    }

    // Sales reports
    getSalesSummaryReport(data: SalesReportsSearchModel) {
        return this.http.post(this.getSalesSummaryReportUrl, data);
    }

    getSalesItemisedReport(data: SalesReportsSearchModel) {
        return this.http.post(this.getSalesItemisedReportUrl, data);
    }

    getSalesItemisedReportCsv(data: SalesReportsSearchModel) {
        return this.http.getBlob(this.getSalesItemisedReportCsvUrl, data);
    }

    // Held deposits reports
    getHeldDepositsReportModel(data: ReportsSearchStringModel) {
        return this.http.post(this.getHeldDepositsReportModelUrl, data);
    }

    // Resident guests reports
    getResidentGuestsReportModel(data: ResidentGuestSearchModel) {
        return this.http.post(this.getResidentGuestsReportModelUrl, data);
    }

    getResidentGuestsReportPrint(data: ResidentGuestsReportModel) {
        return this.http.getBlob(this.getResidentGuestsReportPrintUrl, data);
    }

    // RFM reports
    getRecencyReportModel(data: RecencyReportsSearchModel) {
        return this.http.post(this.getRecencyReportModelUrl, data);
    }

    getFrequencyReportModel(data: FrequencyReportsSearchModel) {
        return this.http.post(this.getFrequencyReportModelUrl, data);
    }

    getMonetaryValueReportModel(data: MonetaryReportsSearchModel) {
        return this.http.post(this.getMonetaryValueReportModelUrl, data);
    }

    getRecencyCsv(recencySearchModel: RecencyReportsSearchModel) {
        return this.http.getBlob(this.getRecencyCsvUrl, recencySearchModel);
    }

    getFrequencyCsv(frequencySearchModel: FrequencyReportsSearchModel) {
        return this.http.getBlob(this.getFrequencyCsvUrl, frequencySearchModel);
    }

    getMonetaryValueCsv(monetarySearchModel: MonetaryReportsSearchModel) {
        return this.http.getBlob(this.getMonetaryValueCsvUrl, monetarySearchModel);
    }

    // Invoice reports
    getInvoiceStatuses() {
        return this.http.get(this.getInvoiceStatusesUrl);
    }

    getInvoiceReport(data: InvoiceReportsSearchModel) {
        return this.http.post(this.getInvoiceReportUrl, data);
    }

    // Partner Invoice reports

    getForPartnerInvoiceReport() {
        return this.http.get(this.getForPartnerInvoiceReportUrl);
    }

    exportPartnerInvoicesToPdf(invoices: InvoiceProcessModel[]) {
        const token = AuthTokenService.getParsedToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: token });

        return this.httpOriginal.post(this.ExportPartnerInvoicesToPdfUrl + '?invoices=' + invoices, invoices, {
            headers,
            responseType: 'blob',
        });
    }

    public emailPartnerInvoices(messageTemplateId: number, invoices: InvoiceProcessModel[]) {
        return this.http.post(
            this.EmailPartnerInvoicesUrl + '?messageTemplateId=' + messageTemplateId,
            invoices,
            true,
            'Action has been added to queue',
            ' '
        );
    }

    getPartnerInvoiceReport(data: PartnerInvoiceReportsSearchModel) {
        return this.http.post(this.getPartnerInvoiceReportUrl, data);
    }

    getPartnerInvoiceSearchModel(): PartnerInvoiceReportsSearchParameterStoredModel {
        const expiresString = localStorage.getItem('partner-invoice-search-expires-from');
        const model = new PartnerInvoiceReportsSearchParameterStoredModel();
        const now: Date = new Date();
        const expireDate: Date = new Date(expiresString);
        if (expiresString) {
            try {
                if (expireDate < now) {
                    return null;
                }

                const outastanding = localStorage.getItem('partner-invoice-search-outastanding');
                if (outastanding) {
                    model.outstandingBalanceOnly = outastanding === '1' ? true : false;
                } else {
                    model.outstandingBalanceOnly = false;
                }

                const partnerId = localStorage.getItem('partner-invoice-search-partnerId');
                if (partnerId) {
                    model.partnerId = +partnerId;
                } else {
                    model.partnerId = -1;
                }

                const dateFrom = localStorage.getItem('partner-invoice-search-dateFrom');
                if (dateFrom) {
                    model.dateFrom = new Date(dateFrom);
                } else {
                    model.dateFrom = null;
                }
            } catch (ex) {
                return null;
            }
        }
        return model;
    }

    setPartnerInvoiceParameters(model: PartnerInvoiceReportsSearchParameterStoredModel) {
        localStorage.setItem('partner-invoice-search-expires-from', model.expires ? model.expires.toString() : null);
        localStorage.setItem(
            'partner-invoice-search-outastanding',
            model.outstandingBalanceOnly ? (model.outstandingBalanceOnly ? '1' : '0') : null
        );
        localStorage.setItem('partner-invoice-search-partnerId', model.partnerId ? model.partnerId.toString() : null);
        localStorage.setItem('partner-invoice-search-dateFrom', model.dateFrom ? model.dateFrom.toString() : null);
    }

    // Direct revenue reports
    getDirectRevenueRatioReport(data: ReportsSearchStringModel) {
        return this.http.post(this.getDirectRevenueRatioReportUrl, data);
    }

    getPaymentReconciliationReport(data: PaymentReconciliationReportsSearchModel) {
        return this.http.post(this.getPaymentReconciliationReportUrl, data);
    }

    getPaymentReconciliationDetailsReport(data: PaymentReconciliationReportsSearchModel) {
        return this.http.post(this.getPaymentReconciliationDetailsReportUrl, data);
    }

    getRoomTypeCsv(roomTypeRequest: ReportsSearchModel) {
        return this.http.getBlob(this.getRoomTypeCsvUrl, roomTypeRequest);
    }

    getRatePlanCsv(ratePlanRequest: ReportsSearchModel) {
        return this.http.getBlob(this.getRatePlanCsvUrl, ratePlanRequest);
    }

    getRoomCsv(roomRequest: ReportsSearchModel) {
        return this.http.getBlob(this.getRoomCsvUrl, roomRequest);
    }

    getEndOfDayReport(data: EndOfDayReportSearchModel) {
        let inputData = JSON.parse(JSON.stringify(data));
        inputData.reportingDate = DateHelper.formatDate(inputData.reportingDate, "YYYY-MM-DD");
        return this.http.post(this.getEndOfDayReportUrl, inputData);
        
    }

    getEndOfDayReportContents(data: EndOfDayReportSearchModel) {
        let inputData = JSON.parse(JSON.stringify(data));
        inputData.reportingDate = DateHelper.formatDate(inputData.reportingDate, "YYYY-MM-DD");
        if (data.finalised) {
            return this.http.postDownload(this.getEndOfDayReportContentsUrl, inputData);
        }
    }

    resetEndOfDayReport(data: EndOfDayReportSearchModel) {
        let inputData = JSON.parse(JSON.stringify(data));
        inputData.reportingDate = DateHelper.formatDate(inputData.reportingDate, "YYYY-MM-DD");
        return this.http.post(this.resetEndOfDayReportUrl, inputData);
    }

    getReportingDateConfiguration(reportDate: Date) {
        let inputDate = DateHelper.formatDate(reportDate, "YYYY-MM-DD");
        return this.http.get(this.getReportingDateConfigurationUrl + '?reportDate=' + inputDate);
    }

    getReportingDatesFinalised() {
        return this.http.get(this.getReportingDatesFinalisedUrl);
    }

    getReportingEposConfiguration(propertyId: number) {
        return this.http.get(this.getReportingEposConfigurationUrl + '?propertyId=' + propertyId);
    }

    saveReportingEposConfiguration(data: ReportingEposShiftConfigurationListsModel) {
        return this.http.post(this.saveReportingEposConfigurationUrl, data, false);
    }

    getZReadConfiguration(reportDate: Date) {
        let inputDate = DateHelper.formatDate(reportDate, "YYYY-MM-DD");
        return this.http.get(this.getZReadConfigurationUrl + '?reportDate=' + inputDate);
    }

    saveZreadInput(data: ZReadConfigurationModel) {
        return this.http.post(this.saveZreadInputUrl, data, false);
    }

    getPaceReport() {
        return this.http.post(this.getPaceReportUrl, null);
        
    }

    convertHtmlToPdf(html: string, reportName: string, fileName: string)
    {
        let json = JSON.stringify({html: html, reportName: reportName, fileName: fileName});
        return this.http.postDownload(this.convertHtmlToPdfUrl, json);
    }

}
