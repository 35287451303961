import { CountryModel } from '../account/country.model';
import { PartnerGridModel } from '../partners/partner-grid.model';
import { KeyValuePair } from '../shared/key-value-pair.model';
import {PropertyFunctionRoomModel} from './function-room.model';
import { SaleItemModel, SaleItemWithCategoryNameModel } from '../cost-center/sale-item.model';
import {CardModel} from '../booking/booking-details.model';
import { EnumModel } from '../shared/enum-model';
import { PropertyVatBaseModel } from '../property/property-vat-base.model';
import { PropertyFileModel } from '../shared/property-file-model';
import { BookingFunction } from './function.model';

export class FunctionFlowModel {

    public id: number;
    public functionName: string;
    public functionNo: number;

    public partnerId?: number;
    public title: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public telephone: string;
    public mobilePhone: string;
    public address1: string;
    public address2: string;
    public address3: string;
    public address4: string;
    public postcode: string;
    public countryId: number;
    public userId: number;
    public superUserId: number;
    public coordinator: string;

    public status: string;
    public canBeInvoiced: boolean;
    public propertyId: number;
    
    public notes: string;

    public functionRooms: FunctionRoomModel[] = [];
    public functionExtras_Items: FunctionExtraModel[] = [];
    public functionExtras_Catering: FunctionExtraModel[] = [];
    public functionPayment: FunctionPaymentModel; //current payment
    public functionPayments: FunctionPaymentModel[] = [];
    public functionInvoices: FunctionInvoiceModel[] = [];
    public linkedBookings: BookingFunction[] = [];

    constructor() {
        this.functionPayment = new FunctionPaymentModel();
    }
}

export class FunctionRoomModel {
    public id: number;
    public eventName: string;
    public functionId: number;
    public propertyFunctionRoomId: number;
    public date: Date;
    public morningSession: boolean;
    public afternoonSession: boolean;
    public eveningSession: boolean;
    public startTime: Date;
    public endTime: Date;
    public configurationId: number;
    public attendees: number;
    public basePrice: number;
    public itinerary: string;
    public price: number;
    public vatId: number;
    public status: string;
    public eposAccountLimit: number;

    public functionFiles: FunctionFileModel[] = [];

    constructor(id?: number, eventName?: string, attendees?: number) {
        this.id = id;
        this.eventName = eventName;
        this.attendees = attendees;
    }

}


export class FunctionExtraModel {
    public id: number;
    public functionId: number;
    public saleItemId: number;
    public quantity: number;
    public price: number;
    public total: number;
    public invoiceId?: number;
    public functionRoomId?: number;
    public notes: string;
    public vatId?: number;
    public extraType: number;
    public description: string;

    public saleItem: SaleItemModel;

    public chargeType: string;
    public itemType: string;
    public disabledSaleItem: boolean;
    public disabledItem: boolean;
    public saleItemItemsSource: SaleItemWithCategoryNameModel[] = [];
    public saleItemCateringSource: SaleItemWithCategoryNameModel[] = [];

}

export class FunctionPaymentModel {
    public id: number;
    public propertyId: number;
    public userId: number;
    public superUserId: number;
    public paymentDate: Date = new Date();
    public paymentNo: string;
    public amount: number;
    public paymentType: string;
    public paymentPurpose: string;
    public functionId?: number;
    public invoiceId?: number;
    public guestId?: number;
    public partnerId?: number;
    public paymentRef?: string;
    public htmlPaymentForm: string;
    public dateCreated: Date;
    public note: string;
    public merchantData: string;
    public functionPaymentId?: number;
    public paidBy: number;
    public status: string;
    public includeDeposit: boolean;

    public purpose: string;
    public purposeName: string;
    public type: string;
    public typeName: string;

    public cards: CardModel[];

    public card: string;
    public cardExpiry: string;
    public cardReportedInvalid: boolean;
    public canRebill: boolean;
    public canRefund: boolean;
    public canVerifyToken: boolean;
    public canChargeToken: boolean;

    constructor() {
        this.cards = [];
    }
}

export class FunctionInvoiceModel {
    public id: number;
    public partnerId?: number;
    public invoiceNo: number;
    public functionId: number;
    //public bookingNo: string;
    public dateCreated: Date;
    public status: EnumModel;
    public customerName: string;
    public amount: number;
    public paid: number;
    public balance: number;
}

export class FunctionFileModel {
    public id: number;
    public functionRoomId: number;
    public fileId: number;
    public propertyFile: PropertyFileModel;
}

export class FunctionSearchModel {

    public currentFunctionRoom: FunctionRoomModel;

    public propertyFunctionRooms: PropertyFunctionRoomModel[] = [];

    public partners: PartnerGridModel[] = [];
    
    public countries: CountryModel[] = [];

    public propertyVATs: PropertyVatBaseModel[] = [];
    
    configurations: EnumModel[] = [];

    statuses: EnumModel[] = [];

    titles: EnumModel[] = [];

    public bookingPaymentTypes: EnumModel[] = [];

    public paidByList: EnumModel[] = [];

    constructor() {
        this.currentFunctionRoom = new FunctionRoomModel()
    }
}

export class FunctionEmailModel {
    public functionId: number;
    public subject: string;
    public recipient: string;
    public emailContent: string;
    public emailType: number;
    public attachSummary: boolean;
}

export class FunctionPrintModel {
    public functionNo: string;
    public amount: number;
    public isRefund: boolean;
}

export class FunctionBookingBookingDetailModel {
    public id: number;
    public propertyId: number;
    public bookingNo: number;
    public status: string;

    public leadGuestName: string;
    public leadGuestEmail: string;

    public bookingRooms: FunctionBookingBookingRoomDetailModel[] = [];
}

export class FunctionBookingBookingRoomDetailModel {
    public id: number;
    public bookingId: number;
    public roomId: number;
    public adults: number;
    public children: number;
    public status: string;
    public bookingFrom: Date;
    public bookingTo: Date;
    public price: number;  
    public nights?: number;

    public room: FunctionBookingRoomDetailModel;
}

export class FunctionBookingRoomDetailModel {
    public id: number
    public propertyId: number;
    public fullName: string;
    public shortName: string;

    public roomType: FunctionBookingRoomTypeDetailModel;
}

export class FunctionBookingRoomTypeDetailModel {
    public id: number
    public propertyId: number;
    public name: string;
    public shortName: string;
    public customTag: string;
    public maximumOccupancy: number;
    public maximumChildren: number;
}

