import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { UpdateUserDataModel } from '../../../models/user/update-user.model';

@Injectable()
export class SettingsUsersService {
    private getUsersUrl = environment.apiUrl + '/User/GetUsers';
    private getForUpdateUrl = environment.apiUrl + '/User/GetForUpdate';
    private getDataForInsertUrl = environment.apiUrl + '/User/GetDataForInsert';
    private addUserUrl = environment.apiUrl + '/User/AddUser';
    private updateUserUrl = environment.apiUrl + '/User/UpdateUser';
    private deleteUserUrl = environment.apiUrl + '/User/Delete';

    constructor(private http: HttpRequestService) {}

    getUsers(accountId: number) {
        return this.http.get(this.getUsersUrl + '?accountId=' + accountId);
    }

    getForUpdate(userId: number) {
        return this.http.get(this.getForUpdateUrl + '?userId=' + userId);
    }

    getDataForInsert() {
        return this.http.get(this.getDataForInsertUrl);
    }

    addUserService(model: UpdateUserDataModel) {
        return this.http.post(this.addUserUrl, model, true);
    }

    updateUserService(model: UpdateUserDataModel) {
        return this.http.put(this.updateUserUrl, model, true);
    }

    deleteUser(userId: number) {
        return this.http.delete(this.deleteUserUrl + '?userId=' + userId, true);
    }
}
