import { AfterViewInit, Component, OnInit, ViewChildren, Output } from '@angular/core';
import { RegistrationModel } from '../../models/user/registration.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidationService } from 'src/app/shared/services/custom-validation.service';
import { CountryModel } from '../../models/account/country.model';
import { SignupService } from './signup.service';
import { Router } from '@angular/router';
import { CheckEmailUsernameService } from '../../shared/services/check-email-username.service';
import { DataTransferService } from '../../shared/services/data-transfer.service';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.less'],
})
export class SignupComponent implements OnInit {
    @ViewChildren('passCode') code;
    loader = false;
    // Change to true when passCode is activated
    passCode = false;
    showEmailError = false;
    confirmation = false;
    imageArray = [
        '../../assets/images/wallpaper2.jpg',
        '../../assets/images/wallpaper3.jpg',
        '../../assets/images/wallpaper1.jpg',
        '../../assets/images/wallpaper4.jpg',
        '../../assets/images/wallpaper5.jpg',
        '../../assets/images/wallpaper6.jpg',
    ];
    imageUrl: string;
    registrationModel = new RegistrationModel();
    valForm: UntypedFormGroup;
    countries: CountryModel[];
    termsAndConditions: string;

    propertyEmail: string;

    showTermsAndConditionsPopup = false;
    showActivatedSuccesMessage = false;

    constructor(
        private fb: UntypedFormBuilder,
        private signupService: SignupService,
        private router: Router,
        private checkUsernameEmail: CheckEmailUsernameService
    ) {
        this.valForm = this.fb.group({
            firstName: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            lastName: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            property: [null, Validators.required],
            email: [null, Validators.compose([Validators.required, Validators.maxLength(50), Validators.email])],
            telephone: [null, Validators.maxLength(50)],
            country: [null, Validators.required],
            acceptedTerms: [null, Validators.required],
            username: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
        });
        const randomImage = Math.floor(Math.random() * this.imageArray.length);
        this.imageUrl = this.imageArray[randomImage];
        this.signupService.getAllCountriesWithRegisteredAccountAndTC().subscribe(
            (response) => {
                this.countries = response.countries;
                this.termsAndConditions = response.termsAndConditions;
            },
            (error) => {
                console.log(error);
            }
        );

        if (DataTransferService.GetData('PassCodeSuccess') !== null) {
            const decodedString = btoa(DataTransferService.GetData('PassCodeSuccess'));
            decodedString === 'true' ? (this.passCode = false) : (this.passCode = true);
        }
    }

    ngOnInit() {}

    checkEmail() {
        if (this.valForm.controls['email'].valid) {
            this.checkUsernameEmail.checkEmailExists(this.registrationModel.email).subscribe((response: any) => {
                this.showEmailError = response.success;
            });
        }
    }

    validPass() {
        DataTransferService.PushData('PassCodeSuccess', atob('true'));
    }

    clearValidationExist() {
        if (!this.valForm.controls['email'].valid && this.showEmailError) {
            this.showEmailError = false;
        }
    }

    validateWhiteSpace() {
        if (
            this.valForm.controls['firstName'].value.trim() === '' ||
            this.valForm.controls['lastName'].value.trim() === '' ||
            this.valForm.controls['property'].value.trim() === '' ||
            this.valForm.controls['username'].value.trim() === ''
        ) {
            return false;
        }
        return true;
    }

    checkForSpaces(value) {
        if (typeof value !== 'undefined' && value !== null) {
            if (value.trim() === '') {
                return false;
            }
        }
        return true;
    }

    termsAndConditionsPopup() {
        this.showTermsAndConditionsPopup = true;
    }

    closeTermsAndConditionsPopup() {
        this.showTermsAndConditionsPopup = false;
    }

    registerSubmitForm(e) {
        e.stopPropagation();

        if (this.registrationModel.isAcceptedTerms === false) {
            this.valForm.controls['acceptedTerms'].setErrors({ required: true });
            return;
        }

        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid && !this.showEmailError && this.validateWhiteSpace()) {
            this.loader = true;

            this.signupService.sendAuthenticationMail(this.registrationModel).subscribe(
                (response) => {
                    this.loader = false;
                    this.propertyEmail = this.registrationModel.email;
                    this.showActivatedSuccesMessage = true;
                },
                (error) => {
                    console.log(error);
                    this.loader = false;
                }
            );
        }
    }

    changeCountry(e) {
        this.registrationModel.countryId = e.target.value
            .split(':')
            .reverse()
            .map((d) => parseInt(d.trim()))[0];
    }
}
