<div class="billing-wrapper flex">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <h2>Payments</h2>

        <div class="flex filter-wrapper">
            <div class="form-item">
                <input
                    type="text"
                    class="custom-input"
                    name="search-input"
                    placeholder="Search..."
                    [(ngModel)]="searchValue"
                    (keyup.enter)="searchPayments()"
                />
            </div>
            <div class="form-item">
                <button class="custom-button blue" (click)="searchPayments()">Search</button>
            </div>
            <div class="form-item">
                <button class="custom-button blue" [ngClass]="[showFilter ? 'active' : '']" (click)="toogleFilter()">
                    Filter
                </button>
            </div>
            <div class="form-item export" *ngIf="permissionsModel.exportEnabled">
                <a #download>
                    <button class="custom-button orange" [disabled]="loader" (click)="exportPayments($event, download)">
                        Export
                    </button>
                </a>
            </div>
        </div>

        <div class="filter-container" [ngClass]="{ expanded: showFilter }">
            <div class="separator"></div>

            <div class="filter-item">
                <div class="form-item">
                    <label>Date from:</label>
                    <dx-date-box
                        id="date-from"
                        name="from-filter"
                        type="date"
                        class="filter-control"
                        (onValueChanged)="reloadGrid()"
                        [displayFormat]="'dd/MM/yyyy'"
                        [showClearButton]="false"
                        [(value)]="dateFrom"
                        [max]="dateTo"
                    >
                    </dx-date-box>
                </div>
                <div class="form-item">
                    <label>Date to:</label>
                    <dx-date-box
                        id="date-to"
                        name="from-filter"
                        type="date"
                        class="filter-control"
                        (onValueChanged)="reloadGrid()"
                        [displayFormat]="'dd/MM/yyyy'"
                        [showClearButton]="false"
                        [(value)]="dateTo"
                        [min]="dateFrom"
                    >
                    </dx-date-box>
                </div>
                <div class="form-item" *ngIf="paymentStatusSource">
                    <label>Status: </label>
                    <dx-select-box
                        #status
                        id="status"
                        class="custom-select filter-control"
                        displayExpr="name"
                        valueExpr="value"
                        name="ota-log-status"
                        (onValueChanged)="reloadGrid()"
                        [showClearButton]="true"
                        [value]="bookingPaymentsRequest.paymentStatus"
                        [items]="paymentStatusSource"
                    >
                    </dx-select-box>
                </div>
                <div class="form-item" *ngIf="paymentTypeSource">
                    <label>Type: </label>
                    <dx-tag-box
                        #type
                        id="type"
                        class="custom-select filter-control"
                        displayExpr="name"
                        valueExpr="value"
                        [value]="bookingPaymentsRequest.paymentType"
                        [items]="paymentTypeSource"
                        (onValueChanged)="reloadGrid()"
                    ></dx-tag-box>
                </div>
            </div>
            <div class="button-wrapper">
                <button type="button" class="custom-button smaller red" name="clear-filters" (click)="clearFilters()">
                    Clear Filter
                </button>
            </div>

            <div class="separator"></div>
        </div>
        <dx-data-grid class="rate-plans-grid">
            <div
                class="ref-num"
                *dxTemplate="let r of 'bookingNo'"
                (click)="goToBooking(r.data)"
                [ngClass]='paymentClass(r.data)'

            >
            <i [ngClass]="[theme == 2 ? 'dark-theme' : '']" class="fa-solid fa-link fa-icon fa-icon-xsmall blue link-icon"></i> #{{ r.data?.bookingNo }}
            </div>
            <div
                class="ref-num"
                *dxTemplate="let ar of 'arrivalDate'"
                [ngClass]='paymentClass(ar.data)'
            >
                {{ ar.data?.arrivalDate | date: 'dd/MM/yyyy' }}
            </div>
            <div
                class="ref-num"
                *dxTemplate="let n of 'name'"
                [ngClass]='paymentClass(n.data)'
            >
                {{ n.data?.name }}
            </div>
            <div
                class="ref-num"
                *dxTemplate="let p of 'paymentId'"
                [ngClass]='paymentClass(p.data)'
            >
                {{ p.data?.paymentId }}
            </div>
            <div
                class="ref-num"
                *dxTemplate="let pt of 'paymentType'"
                [ngClass]='paymentClass(pt.data)'
            >
                {{ pt.data?.paymentType }}
            </div>
            <div
                class="ref-num"
                *dxTemplate="let d of 'date'"
                [ngClass]='paymentClass(d.data)'
            >
                {{ d.data?.date | date: 'dd/MM/yyyy' }}
            </div>
            <div
                class="ref-num"
                *dxTemplate="let t of 'time'"
                [ngClass]='paymentClass(t.data)'
            >
                {{ t.data?.date | date: 'HH:mm' }}
            </div>
            <div
                class="ref-num"
                *dxTemplate="let a of 'amount'"
                [ngClass]='paymentClass(a.data)'
            >
                {{ currencySymbol }}{{ a.data?.amount | number: '.2' }}
            </div>
            <dxo-scrolling mode="standard"></dxo-scrolling>
        </dx-data-grid>
        <div class="bottom-holder">
            <div class="count-holder right">
                <p>
                    Total: <span>{{ currencySymbol }}{{ total | number: '.2' }}</span>
                </p>
            </div>
        </div>
    </div>
</div>
