export enum ExportPdfPostCompleteAction {
    Download = 1,
    Print = 2
}

export enum ExportPdfHeader {
    ReportTitle = 1,
    Logo = 2,
    PropertyName = 3,
    None = 4
}