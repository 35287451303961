<div class="pricing-plans-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Function room availability details</h2>

            <form [formGroup]="valForm" (ngSubmit)="submitAvailability($event)">
                <div class="form-box flex">
                    <div class="form-item">
                        <div class="custom-label">Date from</div>
                        <dx-date-box
                            formControlName="dateFrom"
                            (onValueChanged)="changeFromDate($event)"
                            name="dateFrom"
                            [(value)]="dateFrom"
                            type="date"
                            [displayFormat]="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric'
                            }"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-date-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['dateFrom'].hasError('required') &&
                                (valForm.controls['dateFrom'].dirty || valForm.controls['dateFrom'].touched)
                            "
                            >Date from is required.</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Date to</div>
                        <dx-date-box
                            formControlName="dateTo"
                            (onValueChanged)="changeToDate($event)"
                            name="dateTo"
                            [(value)]="dateTo"
                            type="date"
                            [displayFormat]="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric'
                            }"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-date-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['dateTo'].hasError('required') &&
                                (valForm.controls['dateTo'].dirty || valForm.controls['dateTo'].touched)
                            "
                            >Date to is required.</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Function room</div>
                        <dx-select-box
                            class="custom-select"
                            [dataSource]="availabilityPopupModel.roomTypes"
                            [value]="roomTypeId"
                            (onItemClick)="setRoomTypeValue($event)"
                            formControlName="roomType"
                            name="roomType"
                            valueExpr="id"
                            displayExpr="name"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-select-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['roomType'].hasError('required') &&
                                (valForm.controls['roomType'].dirty || valForm.controls['roomType'].touched)
                            "
                            >Room type is required.</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Status</div>
                        <dx-select-box
                            class="custom-select"
                            [dataSource]="availabilityPopupModel.statuses"
                            [(value)]="updateAvailabilityModel.status"
                            valueExpr="value"
                            displayExpr="name"
                            name="status"
                            formControlName="status"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-select-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['status'].hasError('required') &&
                                (valForm.controls['status'].dirty || valForm.controls['status'].touched)
                            "
                            >Status is required.</span
                        >
                    </div>
                </div>

                <div *ngIf="loader" class="loader left"></div>
                <button
                    *ngIf="!loader && permissionsModel.updateButtonEnabled"
                    type="submit"
                    class="custom-button blue left"
                >
                    Update
                </button>
                <button (click)="close()" class="custom-button red left" type="button">Close</button>
            </form>
        </div>
    </div>
</div>
