<div class="rate-schedules-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box rate popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Message templates</h2>

            <dx-data-grid
                class="message-templates-guest-grid"
                #grid
                [dataSource]="messageTemplatesList"
                (onRowClick)="onRowClick($event)"
                [columns]="columnsConfig"
            >
                <!-- Valentyn - removed message column due to Chris request. Also this is quite good idea as message generation is expensive when list is big-->
                <!-- <div *dxTemplate="let item of 'message'">
            <div id="template-{{ item.data.id }}" class="message-template-guest-body" [innerHTML]="item.data.message"></div>
          </div> -->
                <dxo-scrolling mode="standard"></dxo-scrolling>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true"> </dxo-pager>
            </dx-data-grid>

            <button (click)="close()" type="button" class="custom-button red left message-template-button">
                Close
            </button>
        </div>
    </div>
</div>

<dx-tooltip target="#template-{{ targetId }}" position="top" [width]="200" [(visible)]="showTooltip">
    <dxo-animation>
        <dxo-show type="pop" [from]="{ scale: 0 }" [to]="{ scale: 1 }"> </dxo-show>
        <dxo-hide type="fade" [from]="1" [to]="0"> </dxo-hide>
    </dxo-animation>

    <div *dxTemplate="let data of 'content'">
        <div [innerHTML]="message"></div>
    </div>
</dx-tooltip>

<app-message-template-recepient-preview
    *ngIf="messagePreview"
    [(messagePreview)]="messagePreview"
    [messageTemplateId]="messageTemplate?.id"
    [email]="email"
    [mobilePhone]="mobilePhone"
    [bookingId]="bookingId"
    [bookingRoomId]="bookingRoomId"
    [bookingGuestId]="bookingGuestId"
    (update)="close()"
></app-message-template-recepient-preview>
