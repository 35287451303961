import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PricingPlanService } from '../pricing-plan.service';
import { PricePlanModel } from '../../../../../models/price-plan/price-plan.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PricePlanRangeRegularModel } from '../../../../../models/price-plan/price-plan-range-regular.model';
import { PricePlanRangeWeekdayModel } from '../../../../../models/price-plan/price-plan-range-weekday.model';
import { DayOfWeekHelper } from '../../../../../models/shared/day-of-week-helper.model';
import { PPNightsModel, PPRoomTypeModel } from '../../../../../models/price-plan/price-plan-page.model';
import * as _ from 'lodash';
import { DeleteExactPricePlanModel } from '../../../../../models/price-plan/delete-exact-price-plan.model';
import { RoomTypeBaseModel } from '../../../../../models/room-type/room-type-base.model';
import { RatePlanWithDerivedPricingModel } from '../../../../../models/rate-plan/rate-plan-with-derived-pricing.model';
import { PricePlanRangeModel } from '../../../../../models/price-plan/price-plan-range.model';
import { CurrencyContext } from '../../../../../models/auth-jwt/token-context';
import { AuthTokenService } from '../../../../../shared/services/auth-token.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';
import { PricePlanPermissionsModel } from '../../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../../enums/permissions.enum';

@Component({
    selector: 'app-pricing-plans-details',
    templateUrl: './pricing-plans-details.component.html',
    styleUrls: ['./pricing-plans-details.component.less'],
})
export class PricingPlansDetailsComponent implements OnInit, OnDestroy {
    @Input() insertRegular: boolean;
    @Output() insertRegularChange = new EventEmitter<boolean>();

    @Input() updateRegular: boolean;
    @Output() updateRegularChange = new EventEmitter<boolean>();

    @Input() insertWeekday: boolean;
    @Output() insertWeekdayChange = new EventEmitter<boolean>();

    @Input() updateWeekday: boolean;
    @Output() updateWeekdayChange = new EventEmitter<boolean>();

    @Output() update = new EventEmitter();

    @Input() rateScheduleId: number;
    @Input() ratePlansSource: RatePlanWithDerivedPricingModel[];
    @Input() ratePlan: RatePlanWithDerivedPricingModel;
    @Input() roomTypeBaseModel: RoomTypeBaseModel;
    @Input() nights: PPNightsModel[];
    @Input() selectedPrice: PricePlanRangeModel;
    // @Input() minLosArray: number[];

    @Input() dateFromInput: Date;
    @Input() dateToInput: Date;

    @Input() roomBased: boolean;

    permissionsModel: PricePlanPermissionsModel;

    initLoader = false;
    loader = false;
    loaderDelete = false;
    deletePopup = false;
    message: string;
    errorMessageText = '';
    daysToggled = false;

    dateTo: Date = new Date();
    dateFrom: Date = new Date();
    minLosArray: number[] = [];

    // selected days helpers
    checkedFriSat: boolean;
    checkedSunThr: boolean;
    checkedAll = false;

    theme: number;

    bookingDay: number;

    valForm: UntypedFormGroup;

    // copies of input objects
    copyOfRoomTypeBaseModel: RoomTypeBaseModel = new RoomTypeBaseModel();
    copyOfRatePlanModel: RatePlanWithDerivedPricingModel = new RatePlanWithDerivedPricingModel();

    // models
    pricePlanModel = new PricePlanModel();
    pricePlanRangeRegularModel: PricePlanRangeRegularModel;
    pricePlanRangeWeekdayModel: PricePlanRangeWeekdayModel;
    deleteExactPricePlan = new DeleteExactPricePlanModel();
    currencyContext: CurrencyContext = new CurrencyContext();
    dayOfWeekHelper: DayOfWeekHelper[] = [];
    dayOfWeekHelperFilter: DayOfWeekHelper[] = [];

    // source for rate plans and room types
    ratePlans: RatePlanWithDerivedPricingModel[] = [];
    roomSource: PPRoomTypeModel[] = [];

    subscriptionRoomTypes: any;

    wasUpdated: boolean;

    constructor(
        private pricePlanService: PricingPlanService,
        private fb: UntypedFormBuilder,
        private toasterService: ToastService,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = permissionsService.createPermissionsModel(PermissionsEnum.PricePlans);

        this.valForm = this.fb.group({
            'price-date': [null],
            'base-rate': [null, Validators.compose([Validators.required, Validators.min(1)])],
            'add-rate': [null, Validators.min(0)],
            minLos: [null, Validators.compose([Validators.required, Validators.min(1)])],
        });
        this.currencyContext = AuthTokenService.decodeJwtToken().Currency;

        // initialize days of week
        for (let i = 1; i <= 7; i++) {
            const newDayOfWeek = new DayOfWeekHelper();
            newDayOfWeek.id = i;
            newDayOfWeek.day = newDayOfWeek.days[i - 1];
            newDayOfWeek.selected = false;
            this.dayOfWeekHelper.push(newDayOfWeek);
        }
    }

    ngOnInit() {
        this.dateFrom = new Date(this.dateFromInput);
        this.dateTo = new Date(this.dateToInput);
        this.copyOfRoomTypeBaseModel = JSON.parse(JSON.stringify(this.roomTypeBaseModel));
        this.copyOfRatePlanModel = JSON.parse(JSON.stringify(this.ratePlan));
        this.pricePlanRangeRegularModel = new PricePlanRangeRegularModel();
        this.pricePlanRangeRegularModel.ratePlanId = this.copyOfRatePlanModel.id;
        // set values for regular type
        if (this.insertRegular || this.updateRegular) {
        } else if (this.insertWeekday || this.updateWeekday) {
            // set values for weekday type
            // this.valForm.controls['bookingDay'].setValidators(Validators.required);
            this.pricePlanRangeWeekdayModel = new PricePlanRangeWeekdayModel();
            this.pricePlanRangeWeekdayModel.ratePlanId = this.copyOfRatePlanModel.id;
        }

        this.ratePlans = JSON.parse(JSON.stringify(this.ratePlansSource));
        this.ratePlans.forEach((x) => {
            x.disabled = x.masterPlan !== null;
            x.name = x.disabled ? x.name + ' (derived)' : x.name;
        });

        if (this.updateRegular || this.updateWeekday) {
            // this.nights = [];
            this.pricePlanModel.minLoS = this.selectedPrice.minLoS;
            this.pricePlanModel.baseRate = this.selectedPrice.baseRate;
            this.pricePlanModel.additionalRate = this.selectedPrice.additionalRate;
            this.valForm.controls['minLos'].setValue(this.pricePlanModel.minLoS);
        }

        // set rates to zero
        if (this.insertWeekday || this.insertRegular) {
            this.pricePlanModel.baseRate = 0;
            this.pricePlanModel.additionalRate = 0;
        }

        this.getRoomTypesSource(true);

        this.filterDayOfWeek();
    }

    changeDateFrom() {
        if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
            const dateFrom: Date = new Date(this.dateFrom);
            this.dateTo = new Date(dateFrom.getTime());
        }
    }

    changeDateTo() {
        if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
            const dateTo: Date = new Date(this.dateTo);
            this.dateFrom = new Date(dateTo.getTime());
        }
    }

    getRoomTypeById(id): PPRoomTypeModel {
        for (const roomType of this.roomSource) {
            if (id === roomType.roomType.id) {
                return roomType;
            }
        }

        return null;
    }

    getFirstRoomTypeNotDerived(): number {
        if (this.roomSource.some((room) => room.roomType.id === this.copyOfRoomTypeBaseModel.id && !room.disabled)) {
            const model = this.roomSource.filter((room) => room.roomType.id === this.copyOfRoomTypeBaseModel.id);
            return model[0].roomType.id;
        } else {
            for (const roomType of this.roomSource) {
                if (!roomType.disabled) {
                    return roomType.roomType.id;
                }
            }
        }
    }

    getRoomTypesSource(fromInit: boolean) {
        this.initLoader = true;

        if (typeof this.subscriptionRoomTypes !== 'undefined') {
            this.subscriptionRoomTypes.unsubscribe();
        }

        this.subscriptionRoomTypes = this.pricePlanService
            .getRoomTypesPricePlanDetails(
                this.rateScheduleId,
                this.copyOfRatePlanModel.id,
                this.dateFrom.toDateString(),
                this.dateTo.toDateString()
            )
            .subscribe(
                (response) => {
                    this.roomSource = response;
                    if (this.roomSource.length > 0) {
                        // disable room types which are derived
                        this.roomSource.forEach((x) => {
                            x.disabled = x.slave;
                            x.roomType.shortName = x.disabled
                                ? x.roomType.shortName + ' (derived)'
                                : x.roomType.shortName;
                        });

                        this.copyOfRoomTypeBaseModel.id = fromInit
                            ? this.copyOfRoomTypeBaseModel.id
                            : this.getFirstRoomTypeNotDerived();
                        if (this.updateRegular || this.updateWeekday) {
                            this.nights = fromInit
                                ? this.nights
                                : this.getRoomTypeById(this.copyOfRoomTypeBaseModel.id).nights;

                            if (this.nights.length === 0) {
                                this.errorMessageText = 'There are not defined nights for this room type and date.';
                                this.pricePlanModel.minLoS = null;
                            } else {
                                this.errorMessageText = '';
                                this.pricePlanModel.minLoS = fromInit
                                    ? this.selectedPrice.minLoS
                                    : this.nights[0].minLoS;
                                this.valForm.controls['minLos'].setValue(this.pricePlanModel.minLoS);

                                this.getBaseAndAdditionalPriceFromPricePlan(
                                    this.copyOfRatePlanModel.id,
                                    this.copyOfRoomTypeBaseModel.id,
                                    this.pricePlanModel.minLoS,
                                    new Date(this.dateFrom).toDateString()
                                );
                            }
                        }

                        if (this.insertRegular || this.insertWeekday) {
                            this.minLosArray = [];
                            const selectedRoomType: PPRoomTypeModel = this.getRoomTypeById(
                                this.copyOfRoomTypeBaseModel.id
                            );
                            selectedRoomType.nights.forEach((x) => this.minLosArray.push(x.minLoS));
                        }
                    } else {
                        this.toasterService.showToast('Error', 'There are not any room type available to this rate plan', null, ToastEventType.Error, true, 5000);
                        this.copyOfRoomTypeBaseModel.id = null;
                    }

                    this.initLoader = false;
                },
                (error) => {
                    this.initLoader = false;
                    console.log(error);
                }
            );
    }

    getBaseAndAdditionalPriceFromPricePlan(ratePlanId: number, roomTypeId: number, minLoS: number, priceDate: string) {
        this.pricePlanService
            .getBaseAndAdditionalPriceFromPricePlan(ratePlanId, roomTypeId, minLoS, priceDate)
            .subscribe(
                (response) => {
                    this.pricePlanModel.baseRate = response.baseRate;
                    this.pricePlanModel.additionalRate = response.additionalRate;
                },
                (error) => {
                    console.log(error);
                    this.pricePlanModel.baseRate = 0;
                    this.pricePlanModel.additionalRate = 0;
                }
            );
    }

    filterDayOfWeek() {
        const arrayOfDays: number[] = [];
        const dateFrom: Date = new Date(this.dateFrom);
        this.dayOfWeekHelperFilter = [];

        while (dateFrom.getTime() <= this.dateTo.getTime()) {
            if (dateFrom.getDay() === 0) {
                arrayOfDays.push(7);
            } else {
                arrayOfDays.push(dateFrom.getDay());
            }
            dateFrom.setDate(dateFrom.getDate() + 1);
        }

        const arrayOfDaysUnique = _.uniq(arrayOfDays);
        for (const day of this.dayOfWeekHelper) {
            for (const dayUnique of arrayOfDaysUnique) {
                if (day.id === dayUnique) {
                    this.dayOfWeekHelperFilter.push(day);
                }
            }
        }
    }

    selectAll(event) {
        this.checkedFriSat = false;
        this.checkedSunThr = false;
        this.daysToggled = true;

        if (event.target.checked) {
            this.checkedAll = true;
            this.pricePlanRangeRegularModel.weekdays = [];
            for (let i = 0; i < this.dayOfWeekHelper.length; i++) {
                this.dayOfWeekHelper[i].selected = true;
                this.pricePlanRangeRegularModel.weekdays.push(i + 1);
            }
        } else {
            this.checkedAll = false;
            for (let i = 0; i < this.dayOfWeekHelper.length; i++) {
                this.dayOfWeekHelper[i].selected = false;
            }
            this.pricePlanRangeRegularModel.weekdays = [];
        }
    }

    setDayOfWeek(index: number) {
        if (this.checkedFriSat || this.checkedSunThr) {
            this.pricePlanRangeRegularModel.weekdays = [];
        }
        this.checkedFriSat = false;
        this.checkedSunThr = false;
        this.checkedAll = false;
        this.daysToggled = true;

        let counter = 0;
        for (let i = 0; i < this.pricePlanRangeRegularModel.weekdays.length; i++) {
            if (this.pricePlanRangeRegularModel.weekdays[i] === index) {
                this.pricePlanRangeRegularModel.weekdays.splice(i, 1);
                counter++;
            }
        }
        if (counter === 0) {
            this.pricePlanRangeRegularModel.weekdays.push(index);
        }
    }

    quickFriSat() {
        this.turnOffFirstGroup();
        this.checkedAll = false;
        this.checkedSunThr = false;
        this.daysToggled = true;
        this.pricePlanRangeRegularModel.weekdays = [];
        if (this.checkedFriSat) {
            this.pricePlanRangeRegularModel.weekdays = [5, 6];
        }
    }

    quickSunThr() {
        this.turnOffFirstGroup();
        this.checkedAll = false;
        this.checkedFriSat = false;
        this.daysToggled = true;
        this.pricePlanRangeRegularModel.weekdays = [];
        if (this.checkedSunThr) {
            this.pricePlanRangeRegularModel.weekdays = [1, 2, 3, 4, 7];
            console.log('passed if', this.pricePlanRangeRegularModel.weekdays);
        }
    }

    turnOffFirstGroup() {
        for (const day of this.dayOfWeekHelper) {
            day.selected = false;
        }
    }

    validateMinLos() {
        if (this.insertRegular || this.insertWeekday) {
            for (const number of this.minLosArray) {
                if (number === this.pricePlanModel.minLoS) {
                    return true;
                }
            }

            return false;
        }
    }

    filterByRoomType(e) {
        if (e.itemData !== null) {
            // update validation of min los by room type
            if (this.insertRegular || this.insertWeekday) {
                this.minLosArray = [];
                const selectedRoomType: PPRoomTypeModel = this.getRoomTypeById(this.copyOfRoomTypeBaseModel.id);
                selectedRoomType.nights.forEach((x) => this.minLosArray.push(x.minLoS));
            }

            // update form by room type data
            if (this.updateRegular || this.updateWeekday) {
                this.nights = this.getRoomTypeById(this.copyOfRoomTypeBaseModel.id).nights;
                if (this.nights.length === 0) {
                    this.pricePlanModel.minLoS = null;
                    this.errorMessageText = 'There are not defined nights for this room type and date.';
                    this.pricePlanModel.baseRate = 0;
                    this.pricePlanModel.additionalRate = 0;
                } else {
                    const sameMinLos = this.nights.find(
                        (element) => element.minLoS === this.valForm.controls['minLos'].value
                    );
                    this.pricePlanModel.minLoS = sameMinLos ? sameMinLos.minLoS : this.nights[0].minLoS;
                    this.valForm.controls['minLos'].setValue(this.pricePlanModel.minLoS);
                    this.errorMessageText = '';
                    this.getBaseAndAdditionalPriceFromPricePlan(
                        this.copyOfRatePlanModel.id,
                        this.copyOfRoomTypeBaseModel.id,
                        this.pricePlanModel.minLoS,
                        new Date(this.dateFrom).toDateString()
                    );
                }
            }
        }
    }

    filterByRatePlan(e) {
        if (e.itemData !== null) {
            // check arrival type of rate plan
            if (e.itemData.bookingType === 'AnyDay') {
                // check insert/update form
                if (this.insertWeekday || this.insertRegular) {
                    this.insertWeekday = false;
                    this.insertRegular = true;
                }

                if (this.updateRegular || this.updateWeekday) {
                    this.updateWeekday = false;
                    this.updateRegular = true;
                }

                if (typeof this.pricePlanRangeRegularModel === 'undefined') {
                    this.pricePlanRangeRegularModel = new PricePlanRangeRegularModel();
                }
                this.pricePlanRangeRegularModel.ratePlanId = this.copyOfRatePlanModel.id;
                // this.valForm.controls['bookingDay'].clearValidators();
            } else {
                if (this.insertWeekday || this.insertRegular) {
                    this.insertRegular = false;
                    this.insertWeekday = true;
                }

                if (this.updateRegular || this.updateWeekday) {
                    this.updateRegular = false;
                    this.updateWeekday = true;
                }

                if (typeof this.pricePlanRangeWeekdayModel === 'undefined') {
                    this.pricePlanRangeWeekdayModel = new PricePlanRangeWeekdayModel();
                }
                this.pricePlanRangeWeekdayModel.ratePlanId = this.copyOfRatePlanModel.id;
                this.valForm.controls['bookingDay'].setValidators(Validators.required);
            }

            this.valForm.controls['base-rate'].reset();
            this.valForm.controls['add-rate'].reset();
            // this.valForm.controls['bookingDay'].reset();
            this.pricePlanModel.baseRate = 0;
            this.pricePlanModel.additionalRate = 0;

            this.getRoomTypesSource(false);
        }
    }

    submitForms(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid && !this.validateMinLos() && this.copyOfRoomTypeBaseModel.id !== null) {
            if (this.insertRegular || this.insertWeekday) {
                this.loader = true;
                this.addRegularPricePlan();
            } else if ((this.updateRegular || this.updateWeekday) && this.nights.length > 0) {
                this.loader = true;
                this.updateRegularPricePlan();
            }
            // } else if (this.insertWeekday) {
            //   this.loader = true;
            //   this.addWeekdayPricePlan();
            // } else if (this.updateWeekday && this.nights.length > 0) {
            //   this.loader = true;
            //   this.updateWeekdayPricePlan();
            // }
        }
    }

    addRegularPricePlan() {
        if (this.pricePlanRangeRegularModel.weekdays.length === 0) {
            this.pricePlanRangeRegularModel.weekdays = [1, 2, 3, 4, 5, 6, 7];
        }
        this.pricePlanRangeRegularModel.dateFrom = this.dateFrom.toDateString();
        this.pricePlanRangeRegularModel.dateTo = this.dateTo.toDateString();
        this.pricePlanRangeRegularModel.pricePlan = this.pricePlanModel;
        this.pricePlanRangeRegularModel.pricePlan.roomTypeId = this.copyOfRoomTypeBaseModel.id;
        this.pricePlanRangeRegularModel.roomTypeId = this.copyOfRoomTypeBaseModel.id;
        this.pricePlanRangeRegularModel.minLos = this.pricePlanModel.minLoS;
        this.pricePlanService.insertPriceRangeRegular(this.pricePlanRangeRegularModel).subscribe(
            (response) => {
                this.loader = false;
                this.wasUpdated = true;
                if (!this.daysToggled) {
                    this.pricePlanRangeRegularModel.weekdays = [];
                }
            },
            (error) => {
                console.log(error);
                this.loader = false;
                if (!this.daysToggled) {
                    this.pricePlanRangeRegularModel.weekdays = [];
                }
            }
        );
    }

    updateRegularPricePlan() {
        if (this.pricePlanRangeRegularModel.weekdays.length === 0) {
            this.pricePlanRangeRegularModel.weekdays = [1, 2, 3, 4, 5, 6, 7];
        }
        this.pricePlanRangeRegularModel.dateFrom = this.dateFrom.toDateString();
        this.pricePlanRangeRegularModel.dateTo = this.dateTo.toDateString();
        this.pricePlanRangeRegularModel.pricePlan = this.pricePlanModel;
        this.pricePlanRangeRegularModel.pricePlan.roomTypeId = this.copyOfRoomTypeBaseModel.id;
        this.pricePlanRangeRegularModel.roomTypeId = this.copyOfRoomTypeBaseModel.id;
        this.pricePlanRangeRegularModel.minLos = this.pricePlanModel.minLoS;
        this.pricePlanService.updatePriceRangeRegular(this.pricePlanRangeRegularModel).subscribe(
            (response) => {
                this.loader = false;
                this.wasUpdated = true;
                if (!this.daysToggled) {
                    this.pricePlanRangeRegularModel.weekdays = [];
                }
            },
            (error) => {
                console.log(error);
                this.loader = false;
                if (!this.daysToggled) {
                    this.pricePlanRangeRegularModel.weekdays = [];
                }
            }
        );
    }

    addWeekdayPricePlan() {
        this.pricePlanRangeWeekdayModel.dateFrom = this.dateFrom.toDateString();
        this.pricePlanRangeWeekdayModel.dateTo = this.dateTo.toDateString();
        this.pricePlanRangeWeekdayModel.pricePlan = this.pricePlanModel;
        this.pricePlanRangeWeekdayModel.pricePlan.roomTypeId = this.copyOfRoomTypeBaseModel.id;
        this.pricePlanRangeWeekdayModel.roomTypeId = this.copyOfRoomTypeBaseModel.id;
        this.pricePlanRangeWeekdayModel.minLos = this.pricePlanModel.minLoS;
        this.pricePlanRangeWeekdayModel.bookingDay = this.bookingDay;
        this.pricePlanService.insertPriceRangeWeekDay(this.pricePlanRangeWeekdayModel).subscribe(
            (response) => {
                this.loader = false;
                this.wasUpdated = true;
            },
            (error) => {
                console.log(error);
                this.loader = false;
            }
        );
    }

    updateWeekdayPricePlan() {
        this.pricePlanRangeWeekdayModel.dateFrom = this.dateFrom.toDateString();
        this.pricePlanRangeWeekdayModel.dateTo = this.dateTo.toDateString();
        this.pricePlanRangeWeekdayModel.pricePlan = this.pricePlanModel;
        this.pricePlanRangeWeekdayModel.pricePlan.roomTypeId = this.copyOfRoomTypeBaseModel.id;
        this.pricePlanRangeWeekdayModel.roomTypeId = this.copyOfRoomTypeBaseModel.id;
        this.pricePlanRangeWeekdayModel.minLos = this.pricePlanModel.minLoS;
        this.pricePlanRangeWeekdayModel.bookingDay = this.bookingDay;
        this.pricePlanService.updatePriceRangeWeekday(this.pricePlanRangeWeekdayModel).subscribe(
            (response) => {
                this.loader = false;
                this.wasUpdated = true;
            },
            (error) => {
                console.log(error);
                this.loader = false;
            }
        );
    }

    clearValidationFromQualNights(e) {
        if (e.value !== null) {
            this.errorMessageText = '';
        }
    }

    openDeleteModal() {
        if (this.pricePlanModel.minLoS === null || typeof this.pricePlanModel.minLoS === 'undefined') {
            for (const c in this.valForm.controls) {
                this.valForm.controls[c].reset();
            }
            this.errorMessageText = 'Minimum stay is required.';
            return;
        }

        if (this.roomSource.length === 0) {
            return;
        }

        this.deletePopup = true;
        if (this.pricePlanModel.minLoS === 1) {
            this.message =
                'Are you sure you want to delete Price plans from ' +
                this.dateFrom.toLocaleDateString() +
                ' to ' +
                this.dateTo.toLocaleDateString() +
                ' for ' +
                this.pricePlanModel.minLoS +
                ' night?';
        } else {
            this.message =
                'Are you sure you want to delete Price plans from ' +
                this.dateFrom.toLocaleDateString() +
                ' to ' +
                this.dateTo.toLocaleDateString() +
                ' for ' +
                this.pricePlanModel.minLoS +
                ' nights?';
        }
    }

    deletePricePlan() {
        this.loaderDelete = true;
        this.deleteExactPricePlan.dateFrom = this.dateFrom.toDateString();
        this.deleteExactPricePlan.dateTo = this.dateTo.toDateString();
        this.deleteExactPricePlan.ratePlanId = this.copyOfRatePlanModel.id;
        this.deleteExactPricePlan.minLoS = this.pricePlanModel.minLoS;
        this.deleteExactPricePlan.roomTypeId = this.copyOfRoomTypeBaseModel.id;
        if (this.pricePlanRangeRegularModel.weekdays.length === 0) {
            this.pricePlanRangeRegularModel.weekdays = [1, 2, 3, 4, 5, 6, 7];
        }
        this.deleteExactPricePlan.weekdays = this.pricePlanRangeRegularModel.weekdays;

        if (this.updateRegular) {
            this.pricePlanService.deleteRegularPricePlan(this.deleteExactPricePlan).subscribe(
                (response) => {
                    this.loaderDelete = false;
                    this.wasUpdated = true;
                },
                (error) => {
                    this.loaderDelete = false;
                    console.log(error);
                }
            );
        } else if (this.updateWeekday) {
            this.pricePlanService.deleteWeekdayPricePlan(this.deleteExactPricePlan).subscribe(
                (response) => {
                    this.loaderDelete = false;
                    this.wasUpdated = true;
                },
                (error) => {
                    this.loaderDelete = false;
                    console.log(error);
                }
            );
        }
    }

    close() {
        if (this.wasUpdated) {
            this.update.emit();
        }
        this.insertRegularChange.emit(false);
        this.updateRegularChange.emit(false);
        this.insertWeekdayChange.emit(false);
        this.updateWeekdayChange.emit(false);
    }

    ngOnDestroy() {
        if (typeof this.subscriptionRoomTypes !== 'undefined') {
            this.subscriptionRoomTypes.unsubscribe();
        }
    }
}
