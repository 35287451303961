<div class="rate-schedules-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box rate popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2 *ngIf="functionPayment">Add Function Payment</h2>
            <h2 *ngIf="editFunctionPayment">Edit Function Payment</h2>
            <div class="form-item" *ngIf="disabledPaymentControl">
                <span class="text-danger header">{{ paymentWarningMessage }}</span>
            </div>
            <p [ngClass]="disabledPaymentControl ? 'warning-message-offset' : ''">
                Function balance: {{ token.Currency.Symbol }}{{ functionPaymentPDModel?.balance | number: '1.2-2' }}
            </p>
            <form
                (ngSubmit)="toShowMiscRefundWithWarning ? showMiscRefundWarning() : submitFunctionPayment($event)"
                class="rate-schedules flex"
                [formGroup]="valForm"
            >
                <div class="form-item">
                    <div class="custom-label">Paid by</div>
                    <dx-select-box
                        class="custom-select"
                        [dataSource]="functionPaymentPDModel.paidByList"
                        displayExpr="accountName"
                        [value]="paidBy"
                        formControlName="paidBy"
                        (onItemClick)="setPaidByValueDevex($event)"
                        name="paidBy"
                        valueExpr="accountId"
                    >
                    </dx-select-box>
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['paidBy'].hasError('required') &&
                            (valForm.controls['paidBy'].dirty || valForm.controls['paidBy'].touched)
                        "
                        >Paid by is required.</span
                    >
                </div>
                <div class="form-item">
                    <div class="custom-label">Purpose</div>
                    <dx-select-box
                        class="custom-select"
                        [dataSource]="filteredPaymentPurposes"
                        [(value)]="functionPaymentPDModel.payment.paymentPurpose"
                        displayExpr="name"
                        name="purpose"
                        formControlName="purpose"
                        valueExpr="value"
                        (onItemClick)="checkPurposeType($event)"
                    >
                    </dx-select-box>
                    <span
                        class="text-danger"
                        *ngIf="valForm.controls['purpose'].hasError('required') && valForm.controls['purpose'].touched"
                        >Purpose is required.</span
                    >
                </div>
                <div class="form-item">
                    <div class="custom-label">Type</div>
                    <dx-select-box
                        class="custom-select"
                        #paymentType
                        *ngIf="showTypeListSelect"
                        [dataSource]="filteredPaymentHybridTypes"
                        displayExpr="description"
                        [valueExpr]="editFunctionPayment ? 'type' : null"
                        [showClearButton]="functionPayment"
                        name="type"
                        formControlName="type"
                        (onItemClick)="checkPaymentType($event)"
                    >
                    </dx-select-box>
                    <span *ngIf="!showTypeListSelect">{{filteredPaymentHybridTypes[0].description}}</span>
                    <span
                        class="text-danger"
                        *ngIf="valForm.controls['type'].hasError('required') && valForm.controls['type'].touched"
                        >Type is required.</span
                    >
                </div>

                <div class="form-item">
                    <div class="custom-label">Amount</div>
                    <input
                        class="custom-input"
                        type="number"
                        [(ngModel)]="functionPaymentPDModel.payment.amount"
                        min="0"
                        name="amount"
                        formControlName="amount"
                    />
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['amount'].hasError('required') &&
                            (valForm.controls['amount'].dirty || valForm.controls['amount'].touched)
                        "
                        >Amount is required.</span
                    >
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['amount'].hasError('min') &&
                            (valForm.controls['amount'].dirty || valForm.controls['amount'].touched)
                        "
                        >Minimum amount is 0.</span
                    >
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['amount'].hasError('max') &&
                            (valForm.controls['amount'].dirty || valForm.controls['amount'].touched)
                        "
                        >Maximum amount is {{ functionPaymentPDModel.balance }}.</span
                    >
                    <span class="text-danger" *ngIf="functionPaymentPDModel.payment.amount > refundAmount"
                        >Maximum refund amount is {{ refundAmount }}.</span
                    >
                </div>
                <div class="form-item">
                    <div class="custom-label">Invoice</div>
                    <dx-select-box
                        class="custom-select"
                        [dataSource]="functionPaymentPDModel.invoices"
                        [(value)]="functionPaymentPDModel.payment.invoiceId"
                        [displayExpr]="displayInvoiceLabel"
                        name="invoice"
                        (onItemClick)="getInvoiceBalance($event)"
                        formControlName="invoice"
                        valueExpr="id"
                    >
                    </dx-select-box>
                </div>
                <div class="form-item flex balance">
                    <p *ngIf="balance">Balance: {{ token.Currency.Symbol }}{{ invoiceBalance | number: '1.2-2' }}</p>
                </div>
                <div class="form-item">
                    <div class="custom-label">Note</div>
                    <textarea
                        class="custom-input dark full-w no-resize"
                        formControlName="note"
                        type="text"
                        [(ngModel)]="functionPaymentPDModel.payment.note"
                        placeholder="Notes"
                    ></textarea>
                </div>
                <div class="form-item">
                    <div class="custom-label">Payment date and time</div>
                    <dx-date-box
                        type="datetime"
                        name="paymentDate"
                        [(value)]="functionPaymentPDModel.payment.paymentDate"
                        formControlName="paymentDate"
                    ></dx-date-box>
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['paymentDate'].hasError('required') &&
                            (valForm.controls['paymentDate'].dirty || valForm.controls['paymentDate'].touched)
                        "
                        >Payment date is required.</span
                    >
                </div>
                <button
                    *ngIf="!loader"
                    type="submit"
                    [disabled]="disabledPaymentControl || showMiscRefundWithWarning"
                    class="custom-button blue left"
                >
                    Save
                </button>
                <div *ngIf="loader" class="loader left"></div>
                <button (click)="closePopup()" type="button" class="custom-button red left">Cancel</button>
            </form>

        </div>
    </div>
</div>

<app-confirm-modal
    *ngIf="showMiscRefundWithWarning"
    [(confirmModal)]="showMiscRefundWithWarning"
    (declined)="showMiscRefundWithWarning = false"
    [message]="
        'There are payments assigned to one or more invoices for this function. You should either unlink those payments or ensure you add this refund to the relevant invoice.'
    "
    (confirm)="submitFunctionPayment($event)"
    [confirmLabel]="'Continue'"
    [declineLabel]="'Cancel'"
>
</app-confirm-modal>

<app-payment-form-modal
    *ngIf="showPaymentFormModal"
    [(paymentForm)]="showPaymentFormModal"
    [caterpayForm]="caterpayForm"
    [pciBookingForm]="pciBookingForm"
>
</app-payment-form-modal>

<app-cards-list-modal-functions
    *ngIf="showCardsListModal"
    [(showCardModal)]="showCardsListModal"
    [cardListModel]="cardListModel"
    [functionPayment]="functionPaymentPDModel.payment"
    [newPaymentActionType]="newPaymentActionType"
    [newPaymentSourceData]="newPaymentSourceData"
    (closeAllModalsEmitter)="closePopup()"
    (reloadFunctionDetails)="reloadFunctionDetailsFromPayment()"
>
</app-cards-list-modal-functions>
