import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { AccountAdminUpdateModel } from '../../../models/admin/account-admin-update.model';
import { AccountAdminInsertModel } from '../../../models/account/account-admin-insert.model';
import { AdminUserModel } from '../../../models/admin/admin-user.model';

@Injectable()
export class AdminAccountsService {
    private getAllAccountsUrl = environment.apiUrl + '/Account/GetAllAccounts';
    private updateAccountAdminUrl = environment.apiUrl + '/Account/UpdateAccountAdmin';
    private getAccountDetailsForAdminUrl = environment.apiUrl + '/Account/GetAccountDetailsForAdmin';
    private getDataForInsertAccountUrl = environment.apiUrl + '/Account/GetDataForInsertAccount';
    private insertNewAccountAdminUrl = environment.apiUrl + '/Account/InsertNewAccountAdmin';
    private createPropertyLoginTokenUrl = environment.apiUrl + '/AdminUser/CreatePropertyLoginToken';
    private createAccountLoginTokenUrl = environment.apiUrl + '/AdminUser/CreateAccountLoginToken';

    constructor(private http: HttpRequestService) {}

    getAllAccounts() {
        return this.http.get(this.getAllAccountsUrl);
    }

    updateAccountAdmin(model: AccountAdminUpdateModel) {
        return this.http.post(this.updateAccountAdminUrl, model, true);
    }

    getAccountDetailsForAdmin(accountId: number) {
        return this.http.get(this.getAccountDetailsForAdminUrl + '?accountId=' + accountId);
    }

    getDataForInsertAccount() {
        return this.http.get(this.getDataForInsertAccountUrl);
    }

    insertNewAccountAdmin(model: AccountAdminInsertModel) {
        return this.http.post(this.insertNewAccountAdminUrl, model, true);
    }

    createPropertyLoginToken(id: number) {
        return this.http.get(this.createPropertyLoginTokenUrl + '?propertyId=' + id, false);
    }

    createAccountLoginToken(model: any, id: number) {
        return this.http.post(this.createAccountLoginTokenUrl + '?accountId=' + id, model, true);
    }
}
