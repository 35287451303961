import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { environment } from '../../../../environments/environment';
import { BookingSearchFilterModel } from '../../../models/booking/booking-search-filter-model';
import { BookingFilterSearchRequestModel } from '../../../models/booking/booking-filter-search-request.model';
import { BookingSearchRequestModel } from '../../../models/booking/booking-search-filter-model';

@Injectable({
    providedIn: 'root',
})
export class SettingsBookingSearchService {
    private getDataForBookingSearchUrl = environment.apiUrl + '/Booking/GetDataForBookingSearch';
    private getFilteredBookingUrl = environment.apiUrl + '/Booking/GetFilteredBookings';
    private exportBookingToCsvUrl = environment.apiUrl + '/Booking/GetFilteredBookingsExport';
    private exportAllBookingToCsvUrl = environment.apiUrl + '/Booking/GetAllFilteredBookingsExport';

    constructor(private http: HttpRequestService) {}

    getDataForBookingSearch() {
        return this.http.get(this.getDataForBookingSearchUrl);
    }

    getFilteredBookings(model: BookingFilterSearchRequestModel) {
        return this.http.post(this.getFilteredBookingUrl, model);
    }

    exportBookingsToCsv(model: BookingSearchRequestModel) {
        return this.http.getExcelFile(this.exportBookingToCsvUrl, model);
    }

    exportAllBookingsToCsv(model: BookingSearchRequestModel) {
        return this.http.getExcelFile(this.exportAllBookingToCsvUrl, model);
    }

    getData(data) {
        return this.http.post(this.getFilteredBookingUrl, data);
    }
}
