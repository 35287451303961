export class PromoCodeModel {
    public id: number;
    public propertyId: number;
    public rateScheduleId: number;
    public name: string;
    public code: string;
    public validFrom: string;
    public validTo: string;
    public discountType: string;
    public reward?: number;
    public discountItem?: number;
    public status: string;

    // added new properties
    public rateScheduleName: string;
}
