import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FunctionPaymentModel} from 'src/app/models/function-room/function-flow.model'
import { CurrencyContext } from 'src/app/models/auth-jwt/token-context';
import {NewFunctionService} from 'src/app/routes/function/settings-new-function/new-function.service';
import {AuthTokenService} from 'src/app/shared/services/auth-token.service'

@Component({
  selector: 'app-function-details-invoice-payment',
  templateUrl: './function-details-invoice-payment.component.html',
  styleUrls: ['./function-details-invoice-payment.component.less']
})
export class FunctionDetailsInvoicePaymentComponent implements OnInit {
  @Input() addPayment: boolean;
  @Output() addPaymentChange = new EventEmitter<boolean>();
  @Output() paymentList = new EventEmitter<FunctionPaymentModel[]>();
  @Input() selectedPayments: FunctionPaymentModel[];

  @Input() editPayment: boolean;
  @Output() editPaymentChange = new EventEmitter<boolean>();
  @Output() update = new EventEmitter();

  @Input() functionId: number;
  @Input() invoiceId: number;

  loader = false;

  functionPaymentList: FunctionPaymentModel[] = [];
  selectedRows: FunctionPaymentModel[] = [];
  currencyContext: CurrencyContext = new CurrencyContext();

  theme: number;

  constructor(private functionService: NewFunctionService) { 
    this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    this.currencyContext = AuthTokenService.decodeJwtToken().Currency;
  }

  ngOnInit() {
    this.functionService.getPaymentItemsForInvoice(this.functionId).subscribe(
        (response) => {
            this.functionPaymentList = response;
            if (this.addPayment) {
                this.removeExistItems();
            }
        },
        (error) => {
            console.log(error);
        }
    );
  }

  removeExistItems() {
    const idOfFunctionPayments: number[] = [];
    this.selectedPayments.forEach((x) => idOfFunctionPayments.push(x.id));

    for (let i = 0; i < this.functionPaymentList.length; i++) {
        for (let j = 0; j < idOfFunctionPayments.length; j++) {
            if (this.functionPaymentList[i].id === idOfFunctionPayments[j]) {
                this.functionPaymentList.splice(i, 1);
            }
        }
    }
  }

  submitBookingPayments() {
    if (this.selectedRows.length > 0) {
        if (this.addPayment) {
            this.paymentList.emit(this.selectedRows);
            this.close();
        } else if (this.editPayment) {
            this.selectedRows.forEach((x) => (x.invoiceId = this.invoiceId));
            this.functionService.insertInvoicePayments(this.selectedRows).subscribe(
                (response) => {
                    this.update.emit();
                    this.close();
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }
  }

  close() {
    this.addPaymentChange.emit(false);
    this.editPaymentChange.emit(false);
  }

}
