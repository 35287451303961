<div class="room-type-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Change order</h2>
            <p>Drag and drop room type to change order using the drag handle</p>

            <dx-data-grid
                *ngIf="copyOfRoomTypes.roomTypes"
                [dataSource]="copyOfRoomTypes.roomTypes"
                class="room-type-grid"
            >
            <dxo-row-dragging
            [allowReordering]="true"
            [onReorder]="onReorder"
            [showDragIcons]="true">
            </dxo-row-dragging>
            <dxo-sorting mode="none"></dxo-sorting>
            <dxo-scrolling mode="virtual"></dxo-scrolling>
                <!--<dxi-column dataField="order" caption='' [allowSorting]='false' [width]='50'></dxi-column>-->
                <dxi-column dataField="displayOrder" caption="Order" [width]="60"></dxi-column>
                <dxi-column dataField="name" caption="Name"></dxi-column>
                <dxi-column
                    dataField="shortName"
                    caption="Short name"
                    [width]="110"
                ></dxi-column>
                <dxi-column
                    dataField="configuredRooms"
                    caption="Configured Rooms"
                    [width]="200"
                    [calculateCellValue]="calculateConfiguredRooms"
                ></dxi-column>
                <dxi-column dataField="total" caption="Total" [width]="50" [calculateCellValue]="calculateTotal"></dxi-column>

                
            </dx-data-grid>
            <div *ngIf="changeOrderLoader" class="loader left"></div>
            <button
                *ngIf="!changeOrderLoader"
                type="button"
                (click)="submitOrderRoomType()"
                class="custom-button blue left"
            >
                Save
            </button>
            <button type="button" (click)="close()" class="custom-button red left">Cancel</button>
        </div>
    </div>
</div>
