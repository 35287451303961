import { Component, Output, Input, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { InvoiceBillingModel } from '../../../../models/booking/invoice-billing.model';
import { CurrencyContext } from '../../../../models/auth-jwt/token-context';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { SettingsBillingService } from '../settings-billing-service';
import * as FileSaver from 'file-saver';
import { InvoiceProcessModel } from '../../../../models/booking/invoice-process.model';
import { MessageTemplateModel } from '../../../../models/templates/message-template.model';
import { FinanceManageInvoicesPermissionsModel } from '../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../enums/permissions.enum';
import { DxDataGridComponent } from 'devextreme-angular';
import { first, tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {InvoiceType} from '../../../../enums/invoice-type.enum';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';

@Component({
    selector: 'app-settings-billing-invoice-list',
    templateUrl: './settings-billing-invoice-list.component.html',
    styleUrls: ['./settings-billing-invoice-list.component.less'],
})
export class SettingsBillingInvoiceListComponent {
    @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
    @Output() closePopupEvent = new EventEmitter();
    @Input() invoices: InvoiceBillingModel[];
    permissionsModel: FinanceManageInvoicesPermissionsModel;
    currencyContext: CurrencyContext = new CurrencyContext();

    theme: number;
    loaderPrint = false;
    loaderEmail = false;
    loaderPdf = false;
    openInvoiceMessageTemplates = false;
    confirmInvoiceSendingPopupMessage = 'Are you sure you want to batch send these invoices by email?';
    sendInvoiceConfirmPopup = false;

    constructor(
        private billingService: SettingsBillingService,
        private toasterService: ToastService,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.FinanceManageInvoices);
        this.currencyContext = AuthTokenService.decodeJwtToken().Currency;
    }

    close() {
        this.closePopupEvent.emit();
    }

    printSelectedInvoices() {
        if (this.grid.instance.getSelectedRowKeys().length > 0) {
            const invoices = this.getSelectedInvoicesProcessModel();

            this.loaderPrint = true;
            this.billingService.exportInvoicesToPdf(invoices, InvoiceType.Booking ).subscribe(
                (response) => {
                    const blob = new Blob([response], { type: 'application/pdf' });
                    const blobUrl = URL.createObjectURL(blob);
                    const iframe = document.createElement('iframe');
                    const nav = (window.navigator as any);
                    iframe.style.display = 'none';
                    document.body.appendChild(iframe);

                    iframe.onload = function () {
                        const date = new Date();
                        const dateString =
                            date.getDate() +
                            '/' +
                            (date.getMonth() + 1) +
                            '/' +
                            date.getFullYear() +
                            ' ' +
                            date.getHours() +
                            ':' +
                            date.getMinutes() +
                            ':' +
                            date.getSeconds();

                        if (nav.msSaveOrOpenBlob) {
                            nav.msSaveOrOpenBlob(blob, 'Invoice-' + dateString + '.pdf');
                        } else if (window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
                            const a = document.createElement('a');
                            document.body.appendChild(a);
                            a.href = blobUrl;
                            a.download = 'Invoice-' + dateString + '.pdf';
                            a.target = '_blank';
                            a.click();
                        } else {
                            iframe.contentWindow.print();
                        }
                    };

                    iframe.src = blobUrl;
                    this.loaderPrint = false;
                },
                (error) => {
                    this.loaderPrint = false;
                }
            );
        } else {
            this.toasterService.showToast('Error', 'User has no email!', null, ToastEventType.Error, true, 5000);
        }
    }

    openTemplatesPreview() {
        this.openInvoiceMessageTemplates = true;
    }

    openConfirmSendingInvoice(invoice) {
        this.sendInvoiceConfirmPopup = true;
    }

    confirmInvoiceSend(template: MessageTemplateModel) {
        const selectedInvoices = this.grid.instance.getSelectedRowKeys();
        const filteredInvoices = selectedInvoices.filter((i) => i.hasEmail).map((el) => el.id);

        if (filteredInvoices.length > 0) {
            const invoiceProcessModels = this.getSelectedInvoicesProcessModel();
            const invoices = invoiceProcessModels.filter((i) => filteredInvoices.includes(i.invoiceId));

            this.loaderEmail = true;

            return this.billingService
                .emailInvoicesWithDefaultTemplates(invoices)
                .pipe(
                    first(),
                    catchError((e) => {
                        console.error(e);
                        this.sendInvoiceConfirmPopup = false;
                        this.loaderEmail = false;
                        return of(null);
                    }),
                    tap((res) => {
                        this.sendInvoiceConfirmPopup = false;
                        this.loaderEmail = false;
                    })
                )
                .subscribe();
        } else {
            this.toasterService.showToast('Error', 'User has no email!', null, ToastEventType.Error, true, 5000);
        }
    }

    exportInvoicesToPDF() {
        if (this.grid.instance.getSelectedRowKeys().length > 0) {
            const invoices = this.getSelectedInvoicesProcessModel();

            this.loaderPdf = true;
            this.billingService.exportInvoicesToPdf(invoices, InvoiceType.Booking).subscribe(
                (response) => {
                    const blob = new Blob([response], { type: 'application/pdf' });

                    const date = new Date();
                    const dateString =
                        date.getDate() +
                        '/' +
                        date.getMonth() +
                        '/' +
                        date.getFullYear() +
                        ' ' +
                        date.getHours() +
                        ':' +
                        date.getMinutes() +
                        ':' +
                        date.getSeconds();
                    FileSaver.saveAs(blob, 'Invoices' + ' - ' + dateString + '.pdf');
                    this.loaderPdf = false;
                },
                (error) => {
                    this.loaderPdf = false;
                }
            );
        } else {
            this.toasterService.showToast('Error', 'User has no email!', null, ToastEventType.Error, true, 5000);
        }
    }

    getSelectedInvoicesProcessModel(): InvoiceProcessModel[] {
        return this.grid.instance.getSelectedRowKeys().map((x) => {
            const model = new InvoiceProcessModel();
            model.bookingId = x.bookingId;
            model.invoiceId = x.id;
            return model;
        });
    }
}
