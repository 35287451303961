<div class="rate-plans-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup cost-fees" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Cost centre fees - {{ ratePlanName }}</h2>

            <dx-data-grid
                [dataSource]="ratePlanCostCentreFeeModel"
                [allowColumnResizing]="true"
                rowTemplate="rowTemplateName"
                [columns]="[
                    { dataField: 'costCentreId', caption: 'Cost centre', alignment: 'left', allowSorting: true },
                    { dataField: 'feeAmount', caption: 'Fee', alignment: 'left', allowSorting: true },
                    { dataField: 'chargeType', caption: 'Unit of Measure', alignment: 'left', allowSorting: true },
                    { cellTemplate: 'delete', allowSorting: false, width: 60 }
                ]"
            >
                <table>
                    <ng-container *dxTemplate="let row of 'rowTemplateName'">
                        <tr>
                            <td>
                                <dx-select-box
                                    class="custom-select"
                                    [dataSource]="costCentreModel"
                                    [(value)]="row.data.costCentreId"
                                    valueExpr="id"
                                    displayExpr="name"
                                ></dx-select-box>
                            </td>
                            <td>
                                <div class="form-item fee-input">
                                    <input
                                        [(ngModel)]="row.data.feeAmount"
                                        [ngModelOptions]="{ standalone: true }"
                                        min="0"
                                        type="number"
                                        class="custom-input"
                                    />
                                </div>
                            </td>
                            <td>
                                <dx-select-box
                                    class="custom-select"
                                    [dataSource]="itemChargeType"
                                    [(value)]="row.data.chargeType"
                                    valueExpr="id"
                                    displayExpr="name"
                                ></dx-select-box>
                            </td>
                            <td>
                                <i *ngIf="permissionsModel.delete" title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small" (click)="deleteCostFees(row.data)"></i>
                            </td>
                        </tr>
                    </ng-container>
                </table>

                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="true" [showInfo]="true"> </dxo-pager>
            </dx-data-grid>

            <div (click)="insertNewCentreFee()" class="add-new-box" *ngIf="permissionsModel.formButtonEnabled">
                <span class="text">Add new</span>
                <div class="img">
                    <i class="fa-solid fa-plus fa-icon gray"></i>
                </div>
            </div>

            <!--<div class="add-new" (click)="insertNewCentreFee()">+ Insert new</div>-->
            <div class="form-item">
                <span class="text-danger"> {{ errorMessage }} </span>
            </div>

            <button
                type="button"
                (click)="saveCentreFees()"
                class="custom-button blue"
                *ngIf="permissionsModel.formButtonEnabled"
            >
                Save
            </button>
            <button type="button" (click)="close()" class="custom-button red">
                {{ permissionsModel.formButtonEnabled ? 'Cancel' : 'Close' }}
            </button>
        </div>
    </div>
</div>
