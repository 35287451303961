import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'camelCaseSeparate',
    pure: false,
})
export class CamelCaseSeparate implements PipeTransform {
    transform(camelCaseString: string): string {
        if (camelCaseString) {
            return camelCaseString.split(/(?=[A-Z])/).join(' ');
        } else {
            return;
        }
    }
}
