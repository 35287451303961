import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoomsMappingModel } from '../../../../models/room/rooms-mapping.model';
import { KeyValuePair } from '../../../../models/shared/key-value-pair.model';
import { SignupService } from '../../signup.service';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';

@Component({
    selector: 'app-assign-room-order',
    templateUrl: './assign-room-order.component.html',
    styleUrls: ['./assign-room-order.component.less'],
})
export class RoomOrderComponent implements OnInit {
    @Input() roomOrder: boolean;
    @Output() roomOrderChange = new EventEmitter<boolean>();
    @Input() roomsGridModel: RoomsMappingModel;
    @Output() update = new EventEmitter();

    copyOfRoomsModel: RoomsMappingModel;
    listOfReorderedRooms: KeyValuePair[] = [];
    changeOrderLoader = false;
    theme: number;

    constructor(private permissionsService: PermissionsService, private signupService: SignupService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.onReorder = this.onReorder.bind(this);
    }

    ngOnInit() {
        this.copyOfRoomsModel = JSON.parse(JSON.stringify(this.roomsGridModel));
    }

    onReorder(e) {
        const visibleRows = e.component.getVisibleRows();
    
        const toIndex = this.copyOfRoomsModel.rooms.findIndex((item) => item.id === visibleRows[e.toIndex].data.id);
        const fromIndex = this.copyOfRoomsModel.rooms.findIndex((item) => item.id === e.itemData.id);

        this.copyOfRoomsModel.rooms.splice(fromIndex, 1);
        this.copyOfRoomsModel.rooms.splice(toIndex, 0, e.itemData);
    
        for (let i=1; i<= this.copyOfRoomsModel.rooms.length; i++) {
          this.copyOfRoomsModel.rooms[i-1].orderNumber = i;
        }
      }

    submitOrderRoom() {
        this.changeOrderLoader = true;
        let i = 0;

        for (const room of this.copyOfRoomsModel.rooms) {
            i++;
            this.listOfReorderedRooms.push(new KeyValuePair(room.id, i));
        }

        this.signupService.reorderRooms(this.listOfReorderedRooms).subscribe(
            (response) => {
                this.changeOrderLoader = false;
                this.update.emit();
                this.close();
            },
            (error) => {
                this.changeOrderLoader = false;
                console.log(error);
            }
        );
    }

    close() {
        this.roomOrderChange.emit(false);
    }
}
