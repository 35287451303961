<div class="rate-plans-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Change order</h2>
            <p>Drag and drop room to change order using the drag handle</p>
            <dx-data-grid
                [dataSource]="copyOfRoomsModel.rooms"
                class="rate-plans-grid room-order"
            >
            <dxo-row-dragging
            [allowReordering]="true"
            [onReorder]="onReorder"
            [showDragIcons]="true">
            </dxo-row-dragging>
            <dxo-sorting mode="none"></dxo-sorting>
            <dxo-scrolling mode="virtual"></dxo-scrolling>
            <dxo-paging [pageSize]="100"></dxo-paging>
                <dxi-column dataField="orderNumber" caption="Order" [allowSorting]="false" [width]="60"></dxi-column>
                <dxi-column dataField="fullName" caption="Name" [allowSorting]="false"></dxi-column>
                <dxi-column dataField="shortName" caption="Short name" [allowSorting]="false"></dxi-column>
                <dxi-column dataField="status" caption="Status" [allowSorting]="false"></dxi-column>

                
            </dx-data-grid>
            <div *ngIf="changeOrderLoader" class="loader left"></div>
            <button
                *ngIf="!changeOrderLoader"
                type="button"
                (click)="submitOrderRoom()"
                class="custom-button blue left"
            >
                Save
            </button>
            <button type="button" (click)="close()" class="custom-button red left">Cancel</button>
        </div>
    </div>
</div>
