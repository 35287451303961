import { RatePlanBaseModel } from './rate-plan-base.model';
import { RatePlanDerivedPricingModel } from './rate-plan-derived-pricing.model';

export class RatePlanWithDerivedPricingModel extends RatePlanBaseModel {
    public masterPlan: RatePlanBaseModel;
    public ratePlanDerivedPricings: RatePlanDerivedPricingModel[] = [];

    // added new properties
    public disabled: boolean;

    constructor() {
        super();
        this.masterPlan = new RatePlanBaseModel();
    }
}
