export class AccountBillInfoModel {
    public items: AccountBillingDetailsModel[] = [];
    public total: number;
    public excludedItems: AccountBillingDetailsModel[] = [];
    public itemsView: AccountBillingDetailsModel[] = [];
    public excludedItemsView: AccountBillingDetailsModel[] = [];
    public caterpayPlan: AdditionalBillingItemlModel;
    public smsPlan: AdditionalBillingItemlModel;
}

export class AccountBillingDetailsModel {
    public accountId: number;
    public billingPlanId: number;
    public ruleId: number;
    public itemName: string;
    public itemDescription: string;
    public itemAmount: number;
    public itemValue: number;

    // added new properties
    public checked: boolean;
}

export class AdditionalBillingItemlModel {
    public id: number;
    public propertyId: number;
    public itemName: string;
    public itemDescription: string;
    public itemAmount: number;
    public itemValue: number;
    public additionalItemAmount: number;
    public checked: boolean;
}
