export class RegistrationModel {
    public firstName: string;
    public lastName: string;
    public propertyName: string;
    public shortName: string;
    public email: string;
    public userName: string;
    public isAcceptedTerms: boolean;
    public countryId: number;
    public phone: string;
}
