import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SaleItemCategoryModel } from '../../../../models/cost-center/sale-item-category.model';
import { IncidentalSalesService } from '../incidental-sales.service';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { StatusModel } from '../../../../models/shared/status.model';

@Component({
    selector: 'app-incidental-sale-category',
    templateUrl: './incidental-sale-category.component.html',
    styleUrls: ['./incidental-sale-category.component.less'],
})
export class IncidentalSaleCategoryComponent implements OnInit {
    @Input() addCategory: boolean;
    @Output() addCategoryChange = new EventEmitter<boolean>();
    @Input() editCategory: boolean;
    @Output() editCategoryChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();
    @Input() saleItemCategoryModel: SaleItemCategoryModel;

    loader = false;

    valForm: UntypedFormGroup;
    saleItemCategory = new SaleItemCategoryModel();
    statusModelList: StatusModel[] = [];
    theme: number;

    constructor(private fb: UntypedFormBuilder, private incidentalSalesService: IncidentalSalesService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;

        this.valForm = this.fb.group({
            name: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            status: [null, Validators.required],
        });
        this.statusModelList.push(new StatusModel(true, 'Active'));
        this.statusModelList.push(new StatusModel(false, 'Inactive'));
    }

    ngOnInit() {
        if (this.editCategory) {
            this.saleItemCategory = JSON.parse(JSON.stringify(this.saleItemCategoryModel));
            this.valForm.controls['status'].setValue(this.saleItemCategory.active);
        } else if (this.addCategory) {
            this.saleItemCategory.propertyId = AuthTokenService.decodeJwtToken().Property.Id;
        }
    }

    submitSaleCategory(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            this.loader = true;
            if (this.addCategory) {
                this.insertCategory();
            } else if (this.editCategory) {
                this.updateCategory();
            }
        }
    }

    insertCategory() {
        this.incidentalSalesService.insertSaleItemCategory(this.saleItemCategory).subscribe(
            (response) => {
                this.loader = false;
                this.update.emit();
                this.close();
            },
            (error) => {
                this.loader = false;
                console.log(error);
            }
        );
    }

    updateCategory() {
        this.incidentalSalesService.updateSaleItemCategory(this.saleItemCategory).subscribe(
            (response) => {
                this.update.emit();
                this.close();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    close() {
        this.addCategoryChange.emit(false);
        this.editCategoryChange.emit(false);
    }
}
