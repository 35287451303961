import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { BookingSourceModel } from '../../../models/booking/new-booking-pd.model';

@Injectable()
export class SettingsBookingSourcesService {
    private getBookingSourcesUrl = environment.apiUrl + '/Booking/GetAllBookingSources';
    private insertBookingSourceUrl = environment.apiUrl + '/Booking/InsertBookingSource';
    private updateBookingSourceUrl = environment.apiUrl + '/Booking/UpdateBookingSource';
    private deleteBookingSourceUrl = environment.apiUrl + '/Booking/DeleteBookingSource';

    constructor(private http: HttpRequestService) {}

    getBookingSources() {
        return this.http.get(this.getBookingSourcesUrl);
    }

    insertBookingSource(model: BookingSourceModel) {
        return this.http.post(this.insertBookingSourceUrl, model, true);
    }

    updateBookingSource(model: BookingSourceModel) {
        return this.http.post(this.updateBookingSourceUrl, model, true);
    }

    deleteBookingSource(bookingSourceId: number) {
        return this.http.delete(this.deleteBookingSourceUrl + '?bookingSourceId=' + bookingSourceId, true);
    }
}
