<div class="rate-plans-wrapper flex">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <h2>Rate Plans</h2>
        <div *ngIf="permissionsModel.formButtonEnabled">
            <button type="button" (click)="openRatePlanOrder()" class="change-order">
                <i class="fa fa-sort" aria-hidden="true"></i>Change order
            </button>
        </div>
        <dx-data-grid
            class="rate-plans-grid"
            [dataSource]="ratePlanGridModel"
            [allowColumnResizing]="true"
            (onCellClick)="cellClick($event)"
            [columns]="[
                { dataField: 'orderNumber', caption: 'Order', cellTemplate: 'order', allowSorting: true, width: '60' },
                { dataField: 'name', cellTemplate: 'name', caption: 'Name', allowSorting: true },
                { dataField: 'shortName', caption: 'Short Name', allowSorting: true },
                { dataField: 'id', caption: 'ID', cellTemplate: 'id', allowSorting: true, width: '50' },
                { dataField: 'status', caption: 'Status', allowSorting: true },
                { cellTemplate: 'pricing', width: '50' },
                { cellTemplate: 'delete', width: '50' }
            ]"
        >
            <div class="name" *dxTemplate="let n of 'name'">
                <i [ngClass]="[theme == 2 ? 'dark-theme' : '']" class="fa-solid fa-link fa-icon fa-icon-xsmall blue link-icon"></i>
                {{ n.data.name }}
            </div>
            <div class="center-td" *dxTemplate="let n of 'id'">
                {{ n.data.id }}
            </div>
            <div class="center-td" *dxTemplate="let n of 'order'">
                {{ n.data.orderNumber }}
            </div>
            <div *dxTemplate="let a of 'pricing'">
                <i *ngIf="a.data.status == 'Active' && a.data.rateSchedules.length > 0" title="Pricing" class="fa-solid fa-sterling-sign fa-icon gray fa-icon-small" (click)="openPricing(a.data)"></i>
            </div>
            <div *dxTemplate="let a of 'delete'">
                <i *ngIf="permissionsModel.delete" title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small" (click)="deleteModal(a.data.id)"></i>
            </div>
            <dxo-scrolling mode="standard"></dxo-scrolling>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true"> </dxo-pager>
        </dx-data-grid>

        <button
            type="button"
            (click)="openAddRatePlan()"
            class="custom-button blue"
            *ngIf="permissionsModel.formButtonEnabled"
        >
            Create new rate plan
        </button>
    </div>
</div>

<app-delete-modal
    *ngIf="deletePopup"
    [(deleteModal)]="deletePopup"
    [message]="'Are you sure you want to delete this rate plan?'"
    (delete)="deleteRatePlan()"
></app-delete-modal>
<app-rate-plans-details
    *ngIf="detailsPopup"
    [hasOrder]="true"
    [displayOrderList]="displayOrderList"
    [(detailsPopup)]="detailsPopup"
    [ratePlanId]="ratePlanId"
    (update)="init()"
></app-rate-plans-details>
<app-rate-plans-details
    *ngIf="addRatePlan"
    [hasOrder]="true"
    [displayOrderList]="displayOrderList"
    [(addRatePlan)]="addRatePlan"
    (update)="init()"
></app-rate-plans-details>
<app-rate-plan-order
    *ngIf="ratePlanOrder"
    [(ratePlanOrder)]="ratePlanOrder"
    (update)="init()"
    [ratePlanGridModel]="ratePlanGridModel"
></app-rate-plan-order>
