import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommunicationService } from '../../../../../shared/services/communication.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-payment-form-modal',
    templateUrl: './payment-form-modal.component.html',
    styleUrls: ['./payment-form-modal.component.less'],
})
export class PaymentFormModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() paymentForm: boolean;
    @Output() paymentFormChange = new EventEmitter<boolean>();
    @Input() caterpayForm: string;
    @Input() pciBookingForm: string;
    form: SafeHtml;
    subscription: Subscription;

    constructor(public sanitizer: DomSanitizer, private communicationService: CommunicationService) {
        this.subscription = this.communicationService.closePaymentFormModal.subscribe((response) => {
            if (response) {
                this.close();
            }
        });
    }

    ngOnInit() {
        window.scrollTo(0, 0);
        if (this.caterpayForm) {
            this.form = this.sanitizer.bypassSecurityTrustHtml(this.caterpayForm);
        } else if (this.pciBookingForm) {
            this.form = this.sanitizer.bypassSecurityTrustHtml(this.pciBookingForm);
        }
    }

    ngAfterViewInit() {
        document.forms['cpform'].submit();
    }

    close() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('noscroll');
        this.paymentFormChange.emit(false);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
