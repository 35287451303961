<div class="auto-logout-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup cost-fees" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>You have been inactive for 30 minutes.</h2>
            <p>{{ message }}</p>
            <div class="actions actions-button flex">
                <button type="button" (click)="close()" class="custom-button blue">Return to the app</button>
            </div>
        </div>
    </div>
</div>
