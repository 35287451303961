import { SaleItemModel } from './sale-item.model';
import { SaleItemCategoryModel } from './sale-item-category.model';
import { CostCentreModel } from '../rate-plan/cost-centre.model';
import { EnumModel } from '../shared/enum-model';
import { PropertyVatBaseModel } from '../property/property-vat-base.model';

export class SaleItemPDModel {
    public saleItem: SaleItemModel;
    public saleItemCategory: SaleItemCategoryModel[] = [];
    public costCentres: CostCentreModel[] = [];
    public chargeType: EnumModel[] = [];
    public vatRates: PropertyVatBaseModel[];
    public itemType: EnumModel[] = [];
    public isEditable: boolean;
    public message: string;
    public ratePlans: SaleItemRatePlansModel[] = [];

    constructor() {
        this.saleItem = new SaleItemModel();
    }
}

export class SaleItemRatePlansModel {
    public checked: boolean;
    public id: number;
    public includeFree: boolean;
    public name: string;
    public price: number;
}
