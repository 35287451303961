<app-header></app-header>

<div class="room-creation-wrapper flex">
    <div class="custom-box">
        <h2>Add Rooms</h2>

        <p>
            We have created Room<ng-container *ngIf="numberOfRooms > 1">s</ng-container> 1<ng-container
                *ngIf="numberOfRooms > 1"
            >
                - {{ numberOfRooms }}</ng-container
            >
            as double room<ng-container *ngIf="numberOfRooms > 1">s</ng-container>. These can easily be renamed,
            re-ordered and assigned to different room types if you fine tune your account next, or changed later if you
            want to make some bookings next.
        </p>

        <button type="button" routerLink="/booking/calendar" class="custom-button blue">
            I want to put some bookings in<br />
            now and edit my settings later
        </button>
        <button type="button" routerLink="/inventory/room-types" class="custom-button blue">
            I want to fine tune my account<br />
            and put some bookings in later
        </button>
    </div>
</div>
