import { PricingPlanService } from './../pricing-plan.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthTokenService } from '../../../../../shared/services/auth-token.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UpdateAvailabilityModel } from '../../../../../models/booking/update-availability.model';
import { AvailabilityPopupModel } from '../../../../../models/booking/availability-popup.model';
import { RoomTypeBaseModel } from '../../../../../models/room-type/room-type-base.model';
import { InventoryService } from '../../../../inventory/inventory.service';
import {
    PPCloseOutPopupSourceModel,
    CloseOutRoomTypesModel,
    UpdateCloseOutModel,
} from '../../../../../models/price-plan/price-plan-page.model';

@Component({
    selector: 'app-close-out-details',
    templateUrl: './close-out-details.component.html',
    styleUrls: ['./close-out-details.component.less'],
})
export class CloseOutDetailsComponent implements OnInit {
    @Input() closeOutSource: PPCloseOutPopupSourceModel;
    @Input() showCloseOut: boolean;
    @Input() roomTypeId: number;
    @Input() ratePlanId: number;
    @Input() status: number;
    @Output() showCloseOutChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();
    @Input() dateFrom: Date;

    dateTo: Date;
    loader = false;
    valForm: UntypedFormGroup;
    theme: number;
    wasChanged: boolean;

    filteredRooms: Array<CloseOutRoomTypesModel>;
    updateCloseOutModel: UpdateCloseOutModel;

    constructor(private pricingPlansService: PricingPlanService, private fb: UntypedFormBuilder) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;

        this.valForm = fb.group({
            dateFrom: [null, Validators.required],
            dateTo: [null, Validators.required],
            roomTypeId: [null, Validators.required],
            ratePlanId: [null, Validators.required],
            status: [null, Validators.required],
        });
    }

    changeFromDate(event) {
        if (event.value !== null) {
            if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
                const dateFrom: Date = new Date(this.dateFrom);
                this.dateTo = new Date(dateFrom.getTime());
            }
        }
    }

    changeToDate(event) {
        if (event.value !== null) {
            if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
                const dateTo: Date = new Date(this.dateTo);
                this.dateFrom = new Date(dateTo.getTime());
            }
        }
    }

    ngOnInit() {
        this.filteredRooms = [];
        this.filterRoomTypes();
        this.dateTo = this.dateFrom;
        this.valForm.controls['dateFrom'].setValue(this.dateFrom);
        this.valForm.controls['dateTo'].setValue(this.dateTo);
        this.valForm.controls['status'].setValue(this.status);
        this.valForm.controls['ratePlanId'].setValue(this.ratePlanId);
        this.valForm.controls['roomTypeId'].setValue(this.roomTypeId);
    }

    filterRoomTypes(event?) {
        if (event) {
            this.ratePlanId = event.value;
            this.filteredRooms = [];
        }
        const roomTypes: Array<CloseOutRoomTypesModel> = this.closeOutSource.roomTypes;

        for (let index = 0; index < roomTypes.length; index++) {
            const element: CloseOutRoomTypesModel = roomTypes[index];

            if (element.parentId === this.ratePlanId) {
                this.filteredRooms.push(element);
            }
        }

        if (this.filteredRooms.length > 0) {
            // const allElement: CloseOutRoomTypesModel = new CloseOutRoomTypesModel();
            // allElement.id = -1;
            // allElement.name = 'All';
            // allElement.parentId = this.filteredRooms[0].parentId;
            this.filteredRooms.push(new CloseOutRoomTypesModel(this.filteredRooms[0].parentId, -1, 'All'));
        }
    }

    submitCloseOut(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            if (this.valForm.controls.hasOwnProperty(c)) {
                this.valForm.controls[c].markAsTouched();
            }
        }

        if (this.valForm.valid) {
            this.updateCloseOutModel = this.valForm.value;
            this.updateCloseOutModel.propertyId = AuthTokenService.decodeJwtToken().Property.Id;
            this.updateCloseOutModel.roomTypeIdList = [];

            if (this.valForm.value.roomTypeId === -1) {
                for (let i = 0; i < this.filteredRooms.length; i++) {
                    if (this.filteredRooms[i].id !== -1) {
                        this.updateCloseOutModel.roomTypeIdList.push(this.filteredRooms[i].id);
                    }
                }
            } else {
                this.updateCloseOutModel.roomTypeIdList.push(this.valForm.value.roomTypeId);
            }

            // this.updateCloseOutModel.roomTypeId = roomTypeId;

            this.loader = true;
            this.pricingPlansService.updateCloseOut(this.updateCloseOutModel).subscribe(
                (res) => {
                    this.loader = false;
                    this.wasChanged = true;
                },
                (error) => {
                    console.log(error);
                    this.loader = false;
                }
            );
        }
    }

    close() {
        if (this.wasChanged) {
            this.update.emit();
        }
        this.showCloseOutChange.emit(false);
    }
}
