import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import {
    BookingExtraDetailModel,
    BookingGuestDetailModel,
    BookingPaymentDetailModel,
    BookingPrintModel,
    BookingRoomDetailModel,
} from '../../../models/booking/booking-all-details.model';
import { BookingPaymentModel } from '../../../models/booking/booking-details.model';
import { EligibleRoomTypesRequestModel } from '../../../models/booking/eligible-room-types-request.model';
import { InvoiceDetailsModel, InvoiceItemModel } from '../../../models/booking/invoice-details-pd.model';
import { BookingAddRoomGridModel } from '../../../models/booking/booking-flow.model';
import { FinishCmsBookingPaymentModel } from '../../../models/booking/finish-cms-booking-payment.model';
import { PciBookingDetailModel } from '../../../models/booking/pci-booking-detail.model';
import { BookingNoteModel } from '../../../models/booking/booking-note.mode';
import { CustomMessageTemplateForGuestModel } from '../../../models/templates/custom-message-template-for-guest.model';
import { BookingGroupColorModel } from 'src/app/models/booking/booking-group-color.mode';
import { MoveEposModel } from 'src/app/models/epos/move-epos.model';
import { BcomReportMisconductModel } from 'src/app/models/booking/bcom-report-misconduct.model';
import { PaymentActionType } from 'src/app/enums/payment-action-type.enum';

@Injectable({
    providedIn: 'root',
})
export class BookingDetailsService {
    private getBookingDetailsUrl = environment.apiUrl + '/Booking/GetBookingDetails';
    private deleteBookingExtraUrl = environment.apiUrl + '/Booking/DeleteBookingExtra';
    private insertBookingExtraUrl = environment.apiUrl + '/Booking/InsertBookingExtra';
    private updateBookingExtraUrl = environment.apiUrl + '/Booking/UpdateBookingExtra';
    private searchExistingGuestsUrl = environment.apiUrl + '/Booking/SearchExistingGuests';
    private searchBookingsUrl = environment.apiUrl + '/Booking/SearchBookings';
    private insertNewBookingGuestUrl = environment.apiUrl + '/Booking/InsertNewBookingGuest';
    private getInvoiceBalanceUrl = environment.apiUrl + '/Booking/GetInvoiceBalance';
    private newPaymentUrl = environment.apiUrl + '/Booking/NewPayment';
    private insertPaymentUrl = environment.apiUrl + '/Booking/InsertPayment';
    private updateBookingRoomUrl = environment.apiUrl + '/Booking/UpdateBookingRoom';
    private insertBookingRoomUrl = environment.apiUrl + '/Booking/InsertBookingRoom';
    private getEligibleRoomTypesUrl = environment.apiUrl + '/Booking/GetEligibleRoomTypesRatePlans';
    private getFreeRoomsUrl = environment.apiUrl + '/Booking/GetFreeRooms';
    private getAllFreeRoomsUrl = environment.apiUrl + '/Booking/GetAllFreeRooms';
    private newInvoiceUrl = environment.apiUrl + '/Booking/NewInvoice';
    private editInvoiceUrl = environment.apiUrl + '/Booking/EditInvoice';
    private getRoomItemsForInvoiceUrl = environment.apiUrl + '/Booking/GetRoomItemsForInvoice';
    private getExtraItemsForInvoiceUrl = environment.apiUrl + '/Booking/GetExtraItemsForInvoice';
    private getPaymentItemsForInvoiceUrl = environment.apiUrl + '/Booking/GetPaymentItemsForInvoice';
    private createInvoiceUrl = environment.apiUrl + '/Booking/CreateInvoice';
    private createInvoiceFromBookingUrl = environment.apiUrl + '/Booking/CreateInvoiceFromBooking';
    private deleteInvoiceItemUrl = environment.apiUrl + '/Booking/DeleteInvoiceItem';
    private deleteInvoiceItemExtraUrl = environment.apiUrl + '/Booking/DeleteInvoiceItemExtra';
    private deleteInvoicePaymentUrl = environment.apiUrl + '/Booking/DeleteInvoicePayment';
    private insertInvoicePaymentsUrl = environment.apiUrl + '/Booking/InsertInvoicePayments';
    private insertInvoiceItemsUrl = environment.apiUrl + '/Booking/InsertInvoiceItems';
    private closeInvoiceUrl = environment.apiUrl + '/Booking/CloseInvoice';
    private bookingRoomCheckInUrl = environment.apiUrl + '/Booking/BookingRoomCheckIn';
    private bookingRoomCheckOutUrl = environment.apiUrl + '/Booking/BookingRoomCheckOut';
    private cancelBookingUrl = environment.apiUrl + '/Booking/CancelBooking';
    private unCancelBookingUrl = environment.apiUrl + '/Booking/UnCancelBooking';
    private noShowBookingUrl = environment.apiUrl + '/Booking/NoShowBooking';
    private deleteBookingRoomUrl = environment.apiUrl + '/Booking/DeleteBookingRoom';
    private deleteBookingPartnerUrl = environment.apiUrl + '/Booking/DeleteBookingPartner';
    private updateBookingPartnerUrl = environment.apiUrl + '/Booking/UpdateBookingPartner';
    private deleteBookingGuestUrl = environment.apiUrl + '/Booking/DeleteBookingGuest';
    private updateInvoiceUrl = environment.apiUrl + '/Booking/UpdateInvoice';
    private getCardListUrl = environment.apiUrl + '/Payment/GetCardList';
    private getCaterpayHtmlFormForNewPaymentUrl = environment.apiUrl + '/Payment/GetCaterpayHtmlFormForNewPayment';
    private createCreditNoteUrl = environment.apiUrl + '/Booking/CreateCreditNote';
    private deleteInvoiceUrl = environment.apiUrl + '/Booking/DeleteInvoice';
    private updateExistBookingGuestUrl = environment.apiUrl + '/Booking/UpdateExistBookingGuest';
    private getGuestRegistrationTokenUrl = environment.apiUrl + '/Booking/GetGuestRegistrationToken';
    private checkIfBookingRoomIsInvoicedUrl = environment.apiUrl + '/Booking/CheckIfBookingRoomIsInvoiced';
    private editPaymentUrl = environment.apiUrl + '/Booking/EditPayment';
    private updatePaymentUrl = environment.apiUrl + '/Booking/UpdatePayment';
    private processPaymentDirectUrl = environment.apiUrl + '/Payment/ProcessPaymentDirect';
    private reportCardInvalidUrl = environment.apiUrl + '/Payment/ReportCardInvalid';
    private bookingExtraIsOnInvoiceUrl = environment.apiUrl + '/Booking/BookingExtraIsOnInvoice';
    private getRoomTypesByRatePlanBookingDetailsUrl =
        environment.apiUrl + '/Booking/GetRoomTypesByRatePlanBookingDetails';
    private confirmBookingUrl = environment.apiUrl + '/Booking/ConfirmBooking';
    private pciNewPaymentUrl = environment.apiUrl + '/Payment/PciNewPayment';
    private saveBookingNoteUrl = environment.apiUrl + '/Booking/SaveNote';
    private saveBookingGroupColorUrl = environment.apiUrl + '/Booking/SaveGroupColor';
    private getMessageTemplatesForGuestUrl = environment.apiUrl + '/Templates/GetMessageTemplatesForGuest';
    private getMessageTemplatesForPartnerUrl = environment.apiUrl + '/Templates/GetMessageTemplatesForPartner';
    private getMessageTemplatesForInvoiceUrl = environment.apiUrl + '/Templates/GetMessageTemplatesForInvoice';
    private getGuestMessageTemplateDetailsUrl = environment.apiUrl + '/Templates/GetGuestMessageTemplateDetails';
    private manuallySendMessageToRecepientUrl = environment.apiUrl + '/Templates/ManuallySendMessageToRecepient';
    private checkForBookingRoomCheckInUrl = environment.apiUrl + '/Booking/CheckForBookingRoomCheckIn';
    private getPropertyContactUrl = environment.apiUrl + '/Property/GetPropertyContact';
    private getBookingSummaryDataUrl = environment.apiUrl + '/Booking/GetDataForBookingSummary';
    private unlinkAllowanceItemUrl = environment.apiUrl + '/Booking/UnlinkExtraFromAllowance';
    private getBookingRoomsForDateUrl = environment.apiUrl + '/Booking/GetBookingRoomsForDate';
    private moveExtraItemsUrl = environment.apiUrl + '/Booking/MoveExtras';
    private getBcomMisconductCategoriesUrl = environment.apiUrl + '/Booking/GetBcomMisconductCategories';
    private reportBcomMisconductUrl = environment.apiUrl + '/Booking/ReportBcomMisconduct';
    private reportBcomRoomStayChangeUrl = environment.apiUrl + '/Booking/ReportBcomRoomStayChange';
    private cancellationPolicyUrl = environment.apiUrl + '/Booking/GetCancellationPolicy';
    private bulkEditSearchUrl = environment.apiUrl + '/Booking/BulkEditSearch';
    private bulkEditUrl = environment.apiUrl + '/Booking/BulkEdit';
    private canBulkEditUrl = environment.apiUrl + '/Booking/CanBulkEdit';
    private returnEnquiryUrl = environment.apiUrl + '/Booking/ReturnEnquiry';
    private bookingRoomUndoCheckInUrl = environment.apiUrl + '/Booking/BookingRoomUndoCheckIn';
    private resendPaymentLinkUrl = environment.apiUrl + '/Booking/ResendPaymentLink';
    private receiptData: BookingPrintModel;

    constructor(private http: HttpRequestService) {}

    getBookingDetails(bookingId: number) {
        return this.http.get(this.getBookingDetailsUrl + '?bookingId=' + bookingId);
    }

    getRoomItemsForInvoice(bookingId: number) {
        return this.http.get(this.getRoomItemsForInvoiceUrl + '?bookingId=' + bookingId);
    }

    getCardList(model: BookingPaymentModel) {
        return this.http.post(this.getCardListUrl, model);
    }

    getCaterpayHtmlFormForNewPayment(model: BookingPaymentModel) {
        return this.http.post(this.getCaterpayHtmlFormForNewPaymentUrl, model);
    }

    checkIfBookingRoomIsInvoiced(bookingRoomId: number) {
        return this.http.get(this.checkIfBookingRoomIsInvoicedUrl + '?bookingRoomId=' + bookingRoomId);
    }

    bookingRoomCheckIn(bookingRoomId: number, isCalendar = false, checkGuestBefore = true) {
        return this.http.get(
            this.bookingRoomCheckInUrl +
                '?bookingRoomId=' +
                bookingRoomId +
                '&isCalendar=' +
                isCalendar +
                '&checkGuestBefore=' +
                checkGuestBefore,
            !checkGuestBefore ? true : false
        );
    }

    bookingRoomUndoCheckIn(bookingRoomId: number) {
        return this.http.post(this.bookingRoomUndoCheckInUrl + '?bookingRoomId=' + bookingRoomId, null, false);
    }

    bookingRoomCheckOut(bookingRoomId: number) {
        return this.http.get(this.bookingRoomCheckOutUrl + '?bookingRoomId=' + bookingRoomId);
    }

    getPaymentItemsForInvoice(bookingId: number) {
        return this.http.get(this.getPaymentItemsForInvoiceUrl + '?bookingId=' + bookingId);
    }

    getExtraItemsForInvoice(bookingId: number) {
        return this.http.get(this.getExtraItemsForInvoiceUrl + '?bookingId=' + bookingId);
    }

    getMessageTemplatesForGuest() {
        return this.http.get(this.getMessageTemplatesForGuestUrl);
    }

    getMessageTemplatesForPartner() {
        return this.http.get(this.getMessageTemplatesForPartnerUrl);
    }

    getGuestMessageTemplateDetails(bookingId: number, bookingGuestId: number, templateId: number) {
        return this.http.get(
            `${this.getGuestMessageTemplateDetailsUrl}?bookingId=${bookingId}&bookingGuestId=${bookingGuestId}&templateId=${templateId}`
        );
    }

    getMessageTemplatesForInvoice(partnerInvoice: boolean = false, bookingGuestId?: number, bookingId?: number) {
        return this.http.get(
            this.getMessageTemplatesForInvoiceUrl +
                '?partnerStatementsDefaultTemplate=' +
                partnerInvoice +
                (bookingGuestId ? '&bookingGuestId=' + bookingGuestId : '') +
                (bookingId ? '&bookingId=' + bookingId : '')
        );
    }

    manuallySendMessageToRecepient(model: CustomMessageTemplateForGuestModel) {
        return this.http.post(
            this.manuallySendMessageToRecepientUrl,
            model,
            true,
            'Action has been added to queue',
            ' '
        );
    }

    getEligibleRoomTypesRatePlans(model: EligibleRoomTypesRequestModel) {
        return this.http.post(this.getEligibleRoomTypesUrl, model);
    }

    getFreeRooms(bookingRoomId: number, ratePlanId: number, roomTypeId: number, from: string, to: string) {
        return this.http.get(
            this.getFreeRoomsUrl +
                '?bookingRoomId=' +
                bookingRoomId +
                '&ratePlanId=' +
                ratePlanId +
                '&roomTypeId=' +
                roomTypeId +
                '&from=' +
                from +
                '&to=' +
                to
        );
    }

    getAllFreeRooms(bookingRoomId: number, from: string, to: string) {
        return this.http.get(
            this.getAllFreeRoomsUrl +
                '?bookingRoomId=' +
                bookingRoomId +
                '&from=' +
                from +
                '&to=' +
                to
        );
    }

    newPayment(bookingId: number, paymentActionType: PaymentActionType, sourcePayment: BookingPaymentDetailModel) {
        let sourcePaymentId = sourcePayment == null ? 0 : sourcePayment.id;
        return this.http.get(this.newPaymentUrl + '?bookingId=' + bookingId + '&paymentActionType=' + paymentActionType + '&sourcePaymentId=' + sourcePaymentId);
    }

    editPayment(bookingId: number, bookingPaymentId: number) {
        return this.http.get(this.editPaymentUrl + '?bookingId=' + bookingId + '&bookingPaymentId=' + bookingPaymentId);
    }

    getInvoiceBalance(invoiceId: number) {
        return this.http.get(this.getInvoiceBalanceUrl + '?invoiceId=' + invoiceId);
    }

    searchExistingGuests(bookingId: number, searchValue: string) {
        return this.http.get(this.searchExistingGuestsUrl + '?bookingId=' + bookingId + '&searchValue=' + searchValue);
    }

    searchBookings(searchValue: string) {
        return this.http.get(this.searchBookingsUrl + '?searchValue=' + searchValue);
    }

    deleteBookingExtra(bookingExtraId: number) {
        return this.http.delete(this.deleteBookingExtraUrl + '?bookingExtraId=' + bookingExtraId, true);
    }

    insertBookingExtra(model: BookingExtraDetailModel) {
        return this.http.post(this.insertBookingExtraUrl, model, true);
    }

    updateBookingExtra(model: BookingExtraDetailModel) {
        return this.http.post(this.updateBookingExtraUrl, model, true);
    }

    insertNewBookingGuest(model: BookingGuestDetailModel) {
        return this.http.post(this.insertNewBookingGuestUrl, model, false);
    }

    insertPayment(model: BookingPaymentModel) {
        return this.http.post(this.insertPaymentUrl, model, true);
    }

    updateBookingRoom(model: BookingRoomDetailModel, showSuccess: boolean) {
        return this.http.post(this.updateBookingRoomUrl, model, showSuccess);
    }

    insertBookingRoom(model: BookingAddRoomGridModel[]) {
        return this.http.post(this.insertBookingRoomUrl, model, true);
    }

    newInvoice(bookingId: number) {
        return this.http.get(this.newInvoiceUrl + '?bookingId=' + bookingId);
    }

    editInvoice(invoiceId: number) {
        return this.http.get(this.editInvoiceUrl + '?invoiceId=' + invoiceId);
    }

    createInvoice(model: InvoiceDetailsModel) {
        return this.http.put(this.createInvoiceUrl, model, true);
    }

    createInvoiceFromBooking(bookingId: number, partnerId: number) {
        return this.http.get(
            this.createInvoiceFromBookingUrl + '?bookingId=' + bookingId + '&partnerId=' + partnerId,
            true
        );
    }

    deleteInvoiceItem(model: InvoiceItemModel) {
        return this.http.post(this.deleteInvoiceItemUrl, model, true);
    }

    deleteInvoiceItemExtra(invoiceId: number, bookingExtraId: number) {
        return this.http.post(this.deleteInvoiceItemExtraUrl + '?invoiceId=' + invoiceId + '&bookingExtraId=' + bookingExtraId, null , true);
    }

    deleteInvoicePayment(model: BookingPaymentModel) {
        return this.http.post(this.deleteInvoicePaymentUrl, model, true);
    }

    insertInvoicePayments(model: BookingPaymentDetailModel[]) {
        return this.http.put(this.insertInvoicePaymentsUrl, model, true);
    }

    insertInvoiceItems(model: InvoiceItemModel[]) {
        return this.http.put(this.insertInvoiceItemsUrl, model, true);
    }

    closeInvoice(invoiceId: number) {
        return this.http.get(this.closeInvoiceUrl + '?invoiceId=' + invoiceId, true);
    }

    cancelBooking(bookingId: number, applyPenalty: number) {
        return this.http.get(this.cancelBookingUrl + '?bookingId=' + bookingId + '&applyPenalty=' + applyPenalty, true);
    }

    returnEnquiry(bookingId: number) {
        return this.http.post(this.returnEnquiryUrl + '?bookingId=' + bookingId, null, false);
    }

    getCancellationPolicy(bookingId: number) {
        return this.http.get(this.cancellationPolicyUrl + '?bookingId=' + bookingId, false);
    }

    unCancelBooking(bookingId: number) {
        return this.http.get(this.unCancelBookingUrl + '?bookingId=' + bookingId, true);
    }

    noShowBooking(bookingId: number, applyPenalty: number) {
        let model = {bookingId: bookingId, applyPenalty: applyPenalty};
        return this.http.post(this.noShowBookingUrl, model, false);
    }

    deleteBookingRoom(bookingRoomId: number) {
        return this.http.delete(this.deleteBookingRoomUrl + '?bookingRoomId=' + bookingRoomId, true);
    }

    deleteBookingPartner(bookingId: number) {
        return this.http.delete(this.deleteBookingPartnerUrl + '?bookingId=' + bookingId, true);
    }
    updateBookingPartner(bookingId: number, partnerId: number) {
        return this.http.get(
            this.updateBookingPartnerUrl + '?bookingId=' + bookingId + '&partnerId=' + partnerId,
            true
        );
    }

    deleteBookingGuest(bookingGuestId: number) {
        return this.http.delete(this.deleteBookingGuestUrl + '?bookingGuestId=' + bookingGuestId, true);
    }

    updateInvoice(model: InvoiceDetailsModel) {
        return this.http.put(this.updateInvoiceUrl, model, true);
    }

    deleteInvoice(invoiceId: number) {
        return this.http.get(this.deleteInvoiceUrl + '?invoiceId=' + invoiceId, true);
    }

    createCreditNote(invoiceId: number) {
        return this.http.get(this.createCreditNoteUrl + '?invoiceId=' + invoiceId, true);
    }

    updateExistBookingGuest(model: BookingGuestDetailModel) {
        return this.http.post(this.updateExistBookingGuestUrl, model, false);
    }

    getGuestRegistrationToken(bookingId: number) {
        return this.http.get(this.getGuestRegistrationTokenUrl + '?bookingId=' + bookingId);
    }

    updatePayment(model: BookingPaymentModel) {
        return this.http.post(this.updatePaymentUrl, model, true);
    }

    processPaymentDirect(model: FinishCmsBookingPaymentModel) {
        return this.http.post(this.processPaymentDirectUrl, model, false);
    }

    reportCardInvalid(model: object) {
        return this.http.post(this.reportCardInvalidUrl, model, false);
    }

    bookingExtraIsOnInvoice(bookingExtraId: number) {
        return this.http.get(this.bookingExtraIsOnInvoiceUrl + '?bookingExtraId=' + bookingExtraId);
    }

    getRoomTypesByRatePlanBookingDetails(
        bookingRoomId: number,
        ratePlanId: number,
        roomId: number,
        from: string,
        to: string
    ) {
        return this.http.get(
            this.getRoomTypesByRatePlanBookingDetailsUrl +
                '?bookingRoomId=' +
                bookingRoomId +
                '&ratePlanId=' +
                ratePlanId +
                '&roomId=' +
                roomId +
                '&from=' +
                from +
                '&to=' +
                to
        );
    }

    confirmBooking(bookingId: number) {
        return this.http.get(this.confirmBookingUrl + '?bookingId=' + bookingId);
    }

    pciNewPayment(model: PciBookingDetailModel) {
        return this.http.post(this.pciNewPaymentUrl, model, true);
    }

    saveBookingNote(bookingNoteModel: BookingNoteModel) {
        return this.http.post(this.saveBookingNoteUrl, bookingNoteModel, true);
    }

    saveBookingColor(bookingColorModel: BookingGroupColorModel) {
        return this.http.post(this.saveBookingGroupColorUrl, bookingColorModel, true);
    }

    checkForBookingRoomCheckIn(bookingRoomId: number, roomId = null, dateFrom = null) {
        return this.http.get(
            this.checkForBookingRoomCheckInUrl +
                '?bookingRoomId=' +
                bookingRoomId +
                '&roomId=' +
                roomId +
                '&dateFrom=' +
                dateFrom
        );
    }

    setReceiptData(data: BookingPrintModel) {
        this.receiptData = data;
    }

    getReceiptData() {
        return this.receiptData;
    }

    clearReceiptData(): void {
        this.receiptData = null;
    }

    getPropertyContact() {
        return this.http.get(this.getPropertyContactUrl);
    }

    getDataForBookingSummary(bookingId: number) {
        return this.http.get(this.getBookingSummaryDataUrl + '?bookingId=' + bookingId);
    }

    unlinkAllowanceItem(model: BookingExtraDetailModel) {
        return this.http.post(this.unlinkAllowanceItemUrl, model, true);
    }

    getBookingRoomsForDate(date: String) {
        return this.http.get(this.getBookingRoomsForDateUrl + '?date=' + date);
    }

    moveExtraItems(model: MoveEposModel) {
        return this.http.post(this.moveExtraItemsUrl, model, true);
    }

    getBcomMisconductCategories() {
        return this.http.get(this.getBcomMisconductCategoriesUrl);
    }

    reportBcomMisconduct(model: BcomReportMisconductModel) {
        return this.http.post(this.reportBcomMisconductUrl, model, false);
    }

    reportBcomRoomStayChange(model:BookingRoomDetailModel) {
        return this.http.post(this.reportBcomRoomStayChangeUrl, model, false);
    }

    bulkEditSearch(bookingId: number, dateFrom: string, dateTo: string) {
        return this.http.get(this.bulkEditSearchUrl + '?bookingId=' + bookingId +'&from=' + dateFrom + '&to=' + dateTo);
    }

    bulkEdit(model:BookingRoomDetailModel[]) {
        return this.http.post(this.bulkEditUrl, model, false);
    }

    canBulkEdit(bookingId: number) {
        return this.http.get(this.canBulkEditUrl + '?bookingId=' + bookingId );
    }

    resendPaymentLink(bookingPaymentId: number) {
        return this.http.post(this.resendPaymentLinkUrl + '?bookingPaymentId=' + bookingPaymentId, null);
    }
}
