import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { SettingsBillingService } from './settings-billing-service';
import { DxGridHelper, GridConfiguration } from '../../../shared/helpers/dx-grid-helper';
import {
    BookingSearchFilterModel,
    BookingSearchRequestModel,
} from '../../../models/booking/booking-search-filter-model';
import { BookingSearchPDModel } from '../../../models/booking/booking-search-pd.model';
import { CurrencyContext } from '../../../models/auth-jwt/token-context';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingBillingConfigurationModel } from '../../../models/booking/booing-billing-filter-model';
import { InvoiceBillingModel } from '../../../models/booking/invoice-billing.model';
import { FinanceManageInvoicesPermissionsModel } from '../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../enums/permissions.enum';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-settings-billing',
    templateUrl: './settings-billing.component.html',
    styleUrls: ['./settings-billing.component.less'],
})
export class SettingsBillingComponent implements OnInit, AfterViewInit {
    @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
    permissionsModel: FinanceManageInvoicesPermissionsModel;
    theme: number;

    bookingSearchFilterModel = new BookingSearchFilterModel();
    bookingSearchPDModel: BookingSearchPDModel;
    bookingSearchGridRequest = new BookingSearchRequestModel();
    gridConfiguration: GridConfiguration;
    searchValue: string;
    activeInvoice: number;
    invoiceMessage: string;
    size: any;
    tooltipTarget: string;

    currencyContext: CurrencyContext = new CurrencyContext();

    errorMessageDatesDeparture = '';
    noAdress = false;
    invoiceListPopup = false;
    messageResponse: boolean;
    departureDate = new Date();

    invoices: InvoiceBillingModel[];

    closePopup() {
        this.invoiceListPopup = false;
    }

    constructor(
        private settingsBillingService: SettingsBillingService,
        private route: ActivatedRoute,
        private router: Router,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.FinanceManageInvoices);
        this.currencyContext = AuthTokenService.decodeJwtToken().Currency;
    }

    ngOnInit() {
        this.bookingSearchPDModel = this.route.snapshot.data.pdData;
        this.gridConfiguration = new BookingBillingConfigurationModel();
        this.gridConfiguration.service = this.settingsBillingService;
        this.bookingSearchGridRequest.filters = this.bookingSearchFilterModel;
        this.bookingSearchGridRequest.manageInvoicesPage = true;
        this.setDepartureDateFilter();
    }

    ngAfterViewInit(): void {
        this.initGrid();
    }

    initGrid(): void {
        DxGridHelper.ConfigureGrid(this.grid, this.bookingSearchGridRequest, this.gridConfiguration);
    }

    searchBookings() {
        this.setDepartureDateFilter();
        if (this.validateDateRangeDeparture()) {
            this.gridConfiguration.customSearch[0].value = this.searchValue;
            this.bookingSearchGridRequest.filters = this.bookingSearchFilterModel;
            this.gridConfiguration.reloadGrid();
        }
    }

    validateDateRangeDeparture(): boolean {
        if (
            new Date(this.bookingSearchFilterModel.departure.departureFrom).getTime() >
            new Date(this.bookingSearchFilterModel.departure.departureTo).getTime()
        ) {
            this.errorMessageDatesDeparture = 'Date from must be less than date to.';
            return false;
        }

        this.errorMessageDatesDeparture = '';
        return true;
    }

    rowClickEvent(e) {
        console.log(e);
    }

    goToBookingRef(e, booking) {
        e.stopPropagation();
        this.router.navigate(['/booking', 'details', booking.id, 'finance']);
    }

    renderStatus(s: string) {
        const status = this.bookingSearchPDModel.statuses.find((x) => x.value === s);
        if (status) {
            const name = status.name;
            return name;
        }
        return '';
    }

    batchCreateInvoices() {
        const bookingIds = this.grid.instance.getSelectedRowKeys().map((x) => x.id);
        this.grid.instance.clearSelection();

        this.settingsBillingService.batchCreateInvoices(bookingIds).subscribe(
            (response) => {
                this.gridConfiguration.reloadGrid();
            },
            (error) => {
                this.gridConfiguration.reloadGrid();
            }
        );
    }

    dxhoverstart(e, address: boolean) {
        setTimeout(() => {
            this.messageResponse = true;
            this.activeInvoice = e.id;
            if (address) {
                this.tooltipTarget = '#response-' + this.activeInvoice;
                this.invoiceMessage = 'User has no address';
            } else {
                this.tooltipTarget = '#briefcase-' + this.activeInvoice;
                this.invoiceMessage = 'Partner booking';
            }
        }, 100);
    }

    dxhoverend(e) {
        setTimeout(() => {
            this.tooltipTarget = null;
            this.messageResponse = false;
        }, 100);
    }

    openInvoiceListPopup() {
        const bookingIds = this.grid.instance.getSelectedRowKeys().map((x) => x.id);
        this.grid.instance.clearSelection();
        this.settingsBillingService.getInvoicesForBookings(bookingIds).subscribe(
            (response) => {
                this.invoices = response;
                if (this.invoices && this.invoices.length > 0) {
                    this.invoices = this.invoices.map((x) => ({
                        ...x,
                        selected: false,
                    }));
                }
                this.invoiceListPopup = true;
            },
            (error) => {}
        );
    }

    changeInvoicesHanlder(invoices) {
        this.invoices = invoices;
    }

    setDepartureDateFilter() {
        const date = this.departureDate;
        this.bookingSearchFilterModel.departure.filter = true;
        this.bookingSearchFilterModel.departure.departureFrom = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0,
            -date.getTimezoneOffset()
        );
        this.bookingSearchFilterModel.departure.departureTo = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0,
            -date.getTimezoneOffset()
        );
    }
}
