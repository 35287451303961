import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BookingSearchPDModel } from '../../../models/booking/booking-search-pd.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SettingsBookingSearchService } from '../../booking/settings-booking-search/settings-booking-search.service';

@Injectable({
    providedIn: 'root',
})
export class SettingsBillingPdResolver  {
    constructor(private settingsBookingSearchService: SettingsBookingSearchService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): BookingSearchPDModel | Observable<BookingSearchPDModel> | Promise<BookingSearchPDModel> {
        return this.settingsBookingSearchService.getDataForBookingSearch();
    }
}
