import { GuestsMergeModel, GuestsRegistrationFormModel } from './../../../models/guest-merge/guest-merge.model';
import { environment } from '../../../../environments/environment';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class GuestMergeService {
    private getFilteredGuestsUrl = environment.apiUrl + '/Booking/GetFilteredGuests';
    private mergeGuestsUrl = environment.apiUrl + '/Booking/MergeGuests';
    private getForGuestUpdateUrl = environment.apiUrl + '/Booking/GetForGuestUpdate';
    private updateMergeGuestUrl = environment.apiUrl + '/Booking/UpdateMergeGuest';
    private getGuestBookingsUrl = environment.apiUrl + '/Booking/GetGuestBookings';
    private printBulkGRFUrl = environment.apiUrl + '/Booking/PrintBulkGRF';

    constructor(private http: HttpRequestService) {}

    getData(data) {
        return this.http.post(this.getFilteredGuestsUrl, data);
    }

    mergeGuests(model: GuestsMergeModel) {
        return this.http.post(this.mergeGuestsUrl, model, true);
    }

    getForGuestUpdate(countryId: number) {
        return this.http.get(`${this.getForGuestUpdateUrl}?countryId=${countryId}`);
    }

    updateMergeGuest(updateMergeGuestModel) {
        return this.http.post(this.updateMergeGuestUrl, updateMergeGuestModel, true);
    }

    getGuestBookings(guestId: number, guestName: string) {
        return this.http.get(`${this.getGuestBookingsUrl}?guestId=${guestId}&guestName=${guestName}`);
    }

    printBulkGRF(GuestsRegistrationFormModel) {
        return this.http.getBlob(this.printBulkGRFUrl, GuestsRegistrationFormModel);
    }
}
