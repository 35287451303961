import * as moment from 'moment';

export default class DateHelper {
    static formatDate(dt: Date, format?: string): string {
        if (dt==null) {
            return '';
        }

        if (format == undefined) {
            format="MMM DD, YYYY";
        }

        return moment(dt).format(format);
    }

    static formatTime(dt, format?: string): string {
        if (dt==null) {
          return '';
        }
        if (format == undefined) {
            format="HH:mm";
        }

        return moment(dt).format(format)
    }

    static formatDateTime(dt, format?: string): string {
        if (dt==null) {
          return '';
        }
        if (format == undefined) {
            format="YYYY-MM-DD HH:mm";
        }

        return moment(dt).format(format)
    }

}