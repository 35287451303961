<div
    class="print-page"
    style="margin: 50px auto; width: 210mm; padding: 0 10px; font-family: 'Verdana', sans-serif"
    #content
>
    <div
        class="headline"
        [ngClass]="[!imageSafeUrl ? 'with-border' : '']"
        style="
            margin: 0 auto;
            height: 100px;
            width: 99%;
            display: flex;
            align-items: center;
            justify-content: center;
            //border: 1px solid green;
            //padding: 25px 0;
            font-size: 18px;
            flex-direction: column;
        "
    >
        <div
            *ngIf="!imageSafeUrl"
            style="
                border: 1px solid green;
                width: 100%;
                height: 100%;
                top: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            "
        >
            {{ propertyInfo?.name }}
        </div>

        <ng-container *ngIf="imageSafeUrl">
            <img class="uploaded-img" [src]="imageSafeUrl" alt="hotel-image" style="max-height: 100%; max-width: 99%" />
        </ng-container>
    </div>
    <div
        class="date"
        style="font-family: 'Verdana', sans-serif; margin-top: 10px; margin-right: 10px; font-size: 12pt; float: right"
    >
        {{ currentDate | date: 'dd/MM/yyyy' }}
    </div>
    <div class="message" style="font-family: 'Verdana', sans-serif; margin-top: 40px; font-size: 12pt">
        <strong style="font-size: 18px; font-weight: 700"
            >{{ receiptInfo.isRefund ? 'Refund' : 'Payment' }} Receipt</strong
        >
        <br /><br /><br />
        {{ receiptInfo.isRefund ? 'Refunded' : 'Received with thanks' }}, the sum of {{ currencyContext.Symbol
        }}{{ receiptInfo.isRefund ? receiptInfo?.amount * -1 : (receiptInfo?.amount | number: '1.2-5') }} in respect of
        booking number {{ receiptInfo?.bookingNo }}.<br /><br />
    </div>
    <div class="token-info">
        <div style="font-family: 'Verdana', sans-serif; font-size: 10pt">
            {{ propertyInfo?.name }}<br />
            {{ propertyInfo?.address
            }}<ng-container *ngIf="propertyInfo?.address2">, {{ propertyInfo?.address2 }}</ng-container
            ><ng-container *ngIf="propertyInfo?.address3">, {{ propertyInfo?.address3 }}</ng-container
            ><ng-container *ngIf="propertyInfo?.postalCode">, {{ propertyInfo?.postalCode }}</ng-container
            >.
            <ng-container *ngIf="propertyInfo?.phone"><br />Tel: {{ propertyInfo.phone }}</ng-container>
            <ng-container *ngIf="propertyInfo?.vatNo"
                ><br />VAT Registration Number: {{ propertyInfo.vatNo }}</ng-container
            >
        </div>
    </div>
</div>
