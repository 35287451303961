import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CommunicationService {
    public refreshToken: Subject<any> = new Subject();
    public getPropertyLog: Subject<any> = new Subject();
    public refreshProperties: Subject<any> = new Subject();
    public changeThemeOnSidebar: Subject<any> = new Subject();
    public passVariableToTinyMCE: Subject<any> = new Subject();
    public setTinyMCEContent: Subject<any> = new Subject();
    public checkIfGuestIsEntered: Subject<any> = new Subject();
    public accountActivated: Subject<any> = new Subject();
    public isMultiProperty: Subject<any> = new Subject();
    public askForProperties: Subject<any> = new Subject();
    public passAssignedProperties: Subject<any> = new Subject();
    public closePaymentFormModal: Subject<any> = new Subject();
    public maintenanceMode: Subject<boolean> = new Subject<boolean>();
    public tinyMCEPrint: Subject<any> = new Subject();

    constructor() {}
}
