export class GuestModel {
    public id: number;
    public documentId: string;
    public title: string;
    public firstName: string;
    public lastName: string;
    public initials: string;
    public gender?: number;
    public email: string;
    public phone: string;
    public phone2: string;
    public address1: string;
    public address2: string;
    public address3: string;
    public houseNumber: string;
    public postalCode: string;
    public countryId?: number;
    public partnerId?: number;
    public newsletter: boolean;
    public status: number;
    public dateCreated: Date;
    public completed: boolean;
    public isUnderSixteen = false;
    public under16: boolean;

    constructor() {}
}
