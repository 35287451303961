export class FilteredOtaSyncInModel {
    public storageLogId: string;
    public otaBookingRef: string;
    public bookingRef: string;
    public bookingId: number;
    public otaRef: string;
    public otaRefMask: string;
    public dateCreated: string;
    public syncStartDate: string;
    public syncEndDate: string;
    public status: string;
    public message: string;
}

export class XMLPreviewModel {
    public otaConnectionName: string;
    public xmlString: string;
    public storageLog: FilteredOtaSyncInModel;

    constructor() {
        this.storageLog = new FilteredOtaSyncInModel();
    }
}
