import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InternationalDiallingNumbersModel } from '../../models/shared/international-dialling-numbers.model';

@Injectable()
export class InternationalDiallingCodesService {
    static setLabel(source: InternationalDiallingNumbersModel[]): InternationalDiallingNumbersModel[] {
        source.forEach((x) => (x.label = x.name + ' (' + x.dialCode + ')'));
        return source;
    }

    static sortByPriority(source: InternationalDiallingNumbersModel[]): InternationalDiallingNumbersModel[] {
        // get other countries and sort by name
        const otherCountries: InternationalDiallingNumbersModel[] = source
            .filter((x) => x.priority === -1)
            .sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }

                if (a.name > b.name) {
                    return 1;
                }

                return 0;
            });

        // get countries from eu sorted by name
        const europeanCountries: InternationalDiallingNumbersModel[] = source
            .filter((x) => x.priority === 2)
            .sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }

                if (a.name > b.name) {
                    return 1;
                }

                return 0;
            });

        // find uk (priority one)
        const priorityOne: InternationalDiallingNumbersModel = source.find((x) => x.priority === 1);
        // find frequently used countries and sort them by priority
        const freqUsedCountries: InternationalDiallingNumbersModel[] = source
            .filter((x) => x.priority !== 1 && x.priority !== 2 && x.priority !== -1)
            .sort(function (a, b) {
                if (a.priority < b.priority) {
                    return -1;
                }

                if (a.priority > b.priority) {
                    return 1;
                }

                return 0;
            });

        // add uk to eu contries at the top of the list
        europeanCountries.unshift(priorityOne);
        // add frequently used countries after eu countries
        freqUsedCountries.forEach((x) => europeanCountries.push(x));
        // add other countries
        otherCountries.forEach((x) => europeanCountries.push(x));

        return europeanCountries;
    }

    constructor(private http: HttpClient) {}

    getDiallingCodes(): any {
        return this.http.get('../../assets/international-dialling-numbers/international-dialling-numbers.json');
    }
}
