<app-header></app-header>

<div class="activation-wrapper flex">
    <div class="custom-box">
        <h2>Registration</h2>

        <ng-container *ngIf="!showError">
            <p>
                Thanks for confirming your email. Please enter a password with a minimum of 12 characters, using a mix of
                upper and lower case letters plus a number - and we’ll get on with creating your account.
            </p>
            <br />
            <form [formGroup]="valForm" (ngSubmit)="activateAccountSubmitForm($event)">
                <div class="form-item">
                    <div class="custom-label">Password</div>
                    <input
                        name="newPassword"
                        formControlName="newPassword"
                        [(ngModel)]="userPasswordModel.newPassword"
                        type="password"
                        class="custom-input"
                    />
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['newPassword'].hasError('required') &&
                            (valForm.controls['newPassword'].dirty || valForm.controls['newPassword'].touched)
                        "
                        >Password is required.</span
                    >
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['newPassword'].hasError('maxlength') &&
                            (valForm.controls['newPassword'].dirty || valForm.controls['newPassword'].touched)
                        "
                        >Max 50 characters.</span
                    >
                    <span
                        class="text-danger password-pattern"
                        *ngIf="
                            valForm.controls['newPassword'].hasError('pattern') && valForm.controls['newPassword'].dirty
                        "
                        >Password must contain at least 12 characters, including uppercase, lowercase letters and
                        number.</span
                    >
                </div>
                <div class="form-item">
                    <div class="custom-label">Confirm Password</div>
                    <input
                        name="confirmPassword"
                        type="password"
                        formControlName="confirmPassword"
                        [(ngModel)]="userPasswordModel.confirmPassword"
                        class="custom-input"
                    />
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['confirmPassword'].hasError('required') &&
                            (valForm.controls['confirmPassword'].dirty || valForm.controls['confirmPassword'].touched)
                        "
                        >Confirm password is required.</span
                    >
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['confirmPassword'].hasError('maxlength') &&
                            (valForm.controls['confirmPassword'].dirty || valForm.controls['confirmPassword'].touched)
                        "
                        >Max 50 characters.</span
                    >
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['confirmPassword'].value != valForm.controls['newPassword'].value &&
                            !valForm.controls['confirmPassword'].hasError('required')
                        "
                        >Passwords do not match.</span
                    >
                </div>
                <div class="form-item">
                    <input
                        type="checkbox"
                        name="remember"
                        formControlName="remember"
                        [(ngModel)]="remember"
                        id="checkbox2"
                        class="css-checkbox"
                    />
                    <label for="checkbox2" class="css-label radGroup1"></label>
                    <div class="custom-label inline">Remember me</div>
                </div>

                <button *ngIf="!loader" type="submit" class="custom-button blue right">Next</button>
                <div *ngIf="loader" class="loader right"></div>
            </form>
        </ng-container>
        <ng-container *ngIf="showError">
            <p>Error!</p>
        </ng-container>
    </div>
</div>
