import { UserRoleEnum } from '../../enums/user-role.enum';
export class PermissionsModel {
    create: boolean;
    read: boolean;
    update: boolean;
    delete: boolean;

    constructor() {
        this.create = true;
        this.read = true;
        this.update = true;
        this.delete = true;
    }
}

export class CalendarPermissionsModel extends PermissionsModel {
    editBooking: boolean;
    goToBooking: boolean;
    constructor() {
        super();
        this.editBooking = true;
        this.goToBooking = true;
    }
}

export class BookingSearchPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;
    goToBooking: boolean;
    constructor() {
        super();
        this.formButtonEnabled = true;
        this.goToBooking = true;
    }
}

export class NewBookingPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;
    constructor() {
        super();
        this.formButtonEnabled = true;
    }
}

export class NewFunctionPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;
    constructor() {
        super();
        this.formButtonEnabled = true;
    }
}

// PricePlanPermissionsModel
export class GuestRecordsPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;
    exportButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                this.exportButtonEnabled = true;
                break;
            case UserRoleEnum.Supervisor:
            case UserRoleEnum.Receptionist:
                this.formButtonEnabled = true;
                break;
        }
    }
}

export class PricePlanPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Supervisor:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Receptionist:
                this.formButtonEnabled = false;
                this.delete = false;
                this.update = false;
                this.create = false;
                break;
            default:
                this.delete = false;
        }
    }
}

export class RatePlanPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Supervisor:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                break;
            default:
                this.delete = false;
                break;
        }
    }
}

export class RateSchedulesPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
                this.formButtonEnabled = true;
                this.create = true;
                break;
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                this.create = true;
                break;
            default:
                this.formButtonEnabled = true;
                this.create = false;
                this.update = false;
                this.delete = false;
                break;
        }
    }
}

export class ExtrasPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Supervisor:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Receptionist:
                this.formButtonEnabled = true;
                this.update = false;
                this.delete = false;
                this.create = false;
                break;
            default:
                this.delete = false;
                this.create = false;
        }
    }
}

export class PromoCodesPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;
    exportToExcel: boolean;
    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
                this.formButtonEnabled = true;
                this.exportToExcel = true;
                break;
            case UserRoleEnum.Supervisor:
                this.formButtonEnabled = true;
                this.exportToExcel = true;
                break;
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                this.exportToExcel = true;
                break;
            case UserRoleEnum.Receptionist:
                this.formButtonEnabled = false;
                this.update = false;
                this.create = false;
                break;
            default:
                this.create = false;
        }
    }
}

export class InventoryAvailabilityPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;
    updateButtonEnabled = false;
    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
            case UserRoleEnum.Manager:
            case UserRoleEnum.Supervisor:
                this.formButtonEnabled = true;
                this.updateButtonEnabled = true;
                break;
            // case UserRoleEnum.BookingOnly:
            //   this.formButtonEnabled = true;
        }
    }
}

export class InventoryRoomTypesPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                break;
            default:
                this.formButtonEnabled = false;
                this.delete = false;
                this.create = false;
                this.update = false;
                break;
        }
    }
}

export class InventoryAssignRoomsPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Supervisor:
                this.formButtonEnabled = true;
                this.update = false;
                this.create = false;
                break;
            default:
                this.formButtonEnabled = false;
                this.update = false;
                this.create = false;
                break;
        }
    }
}

export class InventoryFunctionRoomsPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                break;
            default:
                this.formButtonEnabled = false;
                this.delete = false;
                this.create = false;
                this.update = false;
                break;
        }
    }
}

export class InventoryHousekeepingPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Supervisor:
            case UserRoleEnum.Housekeeping:
                this.formButtonEnabled = true;
                this.create = false;
                this.delete = false;
                break;
            default:
                this.create = false;
                this.delete = false;
                break;
        }
    }
}

export class FinanceManageInvoicesPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;
    exportPrintEmailEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
            case UserRoleEnum.Manager:
            case UserRoleEnum.Supervisor:
            case UserRoleEnum.Receptionist:
                this.formButtonEnabled = true;
                this.exportPrintEmailEnabled = true;
                break;
            case UserRoleEnum.BookingOnly:
                this.formButtonEnabled = false;
                this.exportPrintEmailEnabled = true;
                this.create = false;
                break;
            default:
                this.create = false;
                break;
        }
    }
}

export class FinancePaymentsPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;
    exportEnabled: boolean;
    bookingNavigateEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
            case UserRoleEnum.Manager:
            case UserRoleEnum.BookingOnly:
                this.formButtonEnabled = false;
                this.exportEnabled = true;
                this.bookingNavigateEnabled = true;
                break;
            case UserRoleEnum.Supervisor:
                this.formButtonEnabled = false;
                this.exportEnabled = true;
                this.bookingNavigateEnabled = true;
                break;
            case UserRoleEnum.Receptionist:
                this.formButtonEnabled = false;
                this.bookingNavigateEnabled = true;
                break;
        }
    }
}

export class FinanceFinanceVatRatesPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        this.create = false;

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
                this.formButtonEnabled = true;
                this.create = true;
                break;
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Supervisor:
            case UserRoleEnum.Receptionist:
                this.formButtonEnabled = true;
                this.update = false;
                break;
            default:
                this.update = false;
                this.delete = false;
                break;
        }
    }
}

export class MessagingPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Supervisor:
                this.formButtonEnabled = true;
                this.create = false;
                break;
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                this.create = true;
                break;
            case UserRoleEnum.Receptionist:
                this.formButtonEnabled = true;
                this.create = false;
                this.delete = false;
        }
    }
}

export class HousekeepingPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Supervisor:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Receptionist:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Housekeeping:
                this.formButtonEnabled = true;
                break;
        }
    }
}

export class FinanceHeldDepositsPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;
    exportEnabled: boolean;
    bookingNavigateEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
            case UserRoleEnum.Manager:
            case UserRoleEnum.Supervisor:
            case UserRoleEnum.Receptionist:
            case UserRoleEnum.BookingOnly:
                this.formButtonEnabled = false;
                this.bookingNavigateEnabled = true;
        }
    }
}

export class PropertyListPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
          case UserRoleEnum.Owner:
          case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                break;            
            case UserRoleEnum.Supervisor:
            case UserRoleEnum.BookingOnly:
                this.formButtonEnabled = true;
                this.update = false;
                break;
            default:
                this.formButtonEnabled = false;
                this.update = false;
                break;
        }
    }
}

export class PropertyCostCentresPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                break;
            default:
                this.formButtonEnabled = false;
                this.create = false;
                this.update = false;
                this.delete = false;
                this.read = false;
                break;
        }
    }
}

export class PropertyBookingSourcesPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;
    exportButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
                this.formButtonEnabled = true;
                this.exportButtonEnabled = true;
                break;
            case UserRoleEnum.Supervisor:
            case UserRoleEnum.Receptionist:
                this.formButtonEnabled = true;
                this.delete = false;
                break;
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                this.exportButtonEnabled = true;
                this.delete = true;
                break;
            default:
                this.formButtonEnabled = false;
                this.create = false;
                this.update = false;
                this.delete = false;
                this.read = false;
                break;
        }
    }
}

export class PropertyTermsAndConditionsPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                break;
            default:
                this.formButtonEnabled = false;
                break;
        }
    }
}

export class BookingTemplatePermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
            case UserRoleEnum.Manager:
            case UserRoleEnum.WebDesigner:
                this.formButtonEnabled = true;
                this.update = true;
                break;
            case UserRoleEnum.Supervisor:
            case UserRoleEnum.Receptionist:
                this.formButtonEnabled = false;
                this.update = false;
                break;
        }
    }
}

export class BookingEmailSMSSettingsPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
            case UserRoleEnum.Manager:
            case UserRoleEnum.WebDesigner:
                this.formButtonEnabled = true;
                this.update = true;
                break;
            case UserRoleEnum.Supervisor:
            case UserRoleEnum.Receptionist:
                this.formButtonEnabled = false;
                this.update = false;
                break;
        }
    }
}

export class GuestRegistrationTemplatePermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
            case UserRoleEnum.Manager:
            case UserRoleEnum.Supervisor:
            case UserRoleEnum.Receptionist:
                this.formButtonEnabled = true;
                break;
        }
    }
}

export class ReportsPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;
    exportToCsv: boolean;
    exportPdf: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
                this.formButtonEnabled = true;
                this.exportToCsv = true;
                this.exportPdf = true;
                break;
            case UserRoleEnum.Supervisor:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                this.exportToCsv = true;
                this.exportPdf = true;
                break;
            case UserRoleEnum.Housekeeping:
                this.formButtonEnabled = true;
                break;
        }
    }
}

export class PoliciesPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Supervisor:
                this.formButtonEnabled = false;
                this.create = false;
                break;
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                this.create = true;
                break;
            default:
                this.create = false;
                break;
        }
    }
}

export class UsersPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;
    exportToExcel: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
                this.formButtonEnabled = true;
                this.exportToExcel = true;
                break;
            case UserRoleEnum.Supervisor:
                this.delete = false;
                this.exportToExcel = false;
                this.create = false;
                break;
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                this.exportToExcel = true;
                this.delete = false;
                break;
            case UserRoleEnum.Receptionist:
                this.create = false;
                this.delete = false;
                break;
            case UserRoleEnum.Housekeeping:
                this.create = false;
                this.delete = false;
                break;
        }
    }
}

export class PartnersPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Supervisor:
                this.formButtonEnabled = true;
                this.create = false;
                this.delete = false;
                break;
            case UserRoleEnum.Receptionist:
                this.formButtonEnabled = true;
                this.create = false;
                this.delete = false;
                break;
            default:
                this.formButtonEnabled = false;
                this.create = false;
                this.update = false;
                break;
        }
    }
}

export class OtaConnectionsPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Supervisor:
                this.formButtonEnabled = true;
                this.create = false;
                break;
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                this.create = false;
                break;
            case UserRoleEnum.Receptionist:
                this.formButtonEnabled = true;
                this.create = false;
                this.delete = false;
                break;
        }
    }
}

export class CloudhookPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
                this.formButtonEnabled = true;
                break;
            case UserRoleEnum.Supervisor:
                this.formButtonEnabled = false;
                break;
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                break;
        }
    }
}

export class BookingDetailPermissionsModel extends PermissionsModel {
    formButtonEnabled: boolean;

    constructor(userRoleEnum: UserRoleEnum) {
        super();

        switch (userRoleEnum) {
            case UserRoleEnum.Owner:
                this.formButtonEnabled = true;
                this.delete = true;
                break;
            case UserRoleEnum.Supervisor:
                this.formButtonEnabled = true;
                this.delete = true;
                break;
            case UserRoleEnum.Manager:
                this.formButtonEnabled = true;
                this.delete = true;
                break;
            case UserRoleEnum.Receptionist:
                this.formButtonEnabled = true;
                this.delete = true;
                break;
            default:
                this.delete = false;
        }
    }
}
