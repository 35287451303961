<div class="assign-rooms-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>{{ permissionsModel.formButtonEnabled ? 'Update Rooms' : 'Rooms' }}</h2>
            <form [formGroup]="valForm" (ngSubmit)="submitUpdateRoomCheck()">
                <div class="edit-room flex" *ngIf="roomDetailsModel.room && roomDetailsModel.pageData">
                    <div class="left-part flex">
                        <div class="form-item">
                            <div class="custom-label">Room name</div>
                            <input
                                class="custom-input"
                                name="roomName"
                                formControlName="roomName"
                                [(ngModel)]="roomDetailsModel.room.fullName"
                            />
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['roomName'].hasError('required') &&
                                    (valForm.controls['roomName'].dirty || valForm.controls['roomName'].touched)
                                "
                                >Name is required.</span
                            >
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['roomName'].hasError('maxlength') &&
                                    (valForm.controls['roomName'].dirty || valForm.controls['roomName'].touched)
                                "
                                >Max 50 characters.</span
                            >
                        </div>
                        <div class="form-item">
                            <div class="custom-label">Short name</div>
                            <input
                                class="custom-input"
                                name="shortName"
                                formControlName="shortName"
                                [(ngModel)]="roomDetailsModel.room.shortName"
                            />
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['shortName'].hasError('required') &&
                                    (valForm.controls['shortName'].dirty || valForm.controls['shortName'].touched)
                                "
                                >Short name is required.</span
                            >
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['shortName'].hasError('maxlength') &&
                                    (valForm.controls['shortName'].dirty || valForm.controls['shortName'].touched)
                                "
                                >Max 10 characters.</span
                            >
                        </div>
                        <div class="form-item">
                            <div class="custom-label">Custom tag</div>
                            <input
                                class="custom-input"
                                name="customTag"
                                formControlName="customTag"
                                [(ngModel)]="roomDetailsModel.room.customTag"
                            />
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['customTag'].hasError('maxlength') &&
                                    (valForm.controls['customTag'].dirty || valForm.controls['customTag'].touched)
                                "
                                >Max 50 characters.</span
                            >
                        </div>
                        <div class="form-item">
                            <div class="custom-label">Status</div>
                            <dx-select-box
                                class="custom-select"
                                [dataSource]="roomDetailsModel.pageData.status"
                                displayExpr="name"
                                [(value)]="roomDetailsModel.room.status"
                                valueExpr="value"
                                name="status"
                                formControlName="status"
                            >
                            </dx-select-box>
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['status'].hasError('required') &&
                                    (valForm.controls['status'].dirty || valForm.controls['status'].touched)
                                "
                                >Status is required.</span
                            >
                        </div>
                        <div class="form-item">
                            <div class="custom-label">Last room default type</div>
                            <dx-select-box
                                class="custom-select"
                                [dataSource]="roomDetailsModel.pageData.lastRoomDefault"
                                displayExpr="name"
                                [(value)]="roomDetailsModel.room.lastRoomTypeId"
                                valueExpr="id"
                                name="type"
                                formControlName="type"
                            >
                            </dx-select-box>
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['type'].hasError('required') &&
                                    (valForm.controls['type'].dirty || valForm.controls['type'].touched)
                                "
                                >Type is required.</span
                            >
                        </div>
                    </div>

                    <div class="right-part flex">
                        <div class="form-item">
                            <div class="custom-label">Note</div>
                            <textarea
                                name="notes"
                                class="custom-textarea"
                                formControlName="notes"
                                [(ngModel)]="roomDetailsModel.room.note"
                            ></textarea>
                        </div>
                    </div>
                </div>

                <button *ngIf="!loader && permissionsModel.update" type="submit" class="custom-button blue left">
                    Save
                </button>
                <div *ngIf="loader" class="loader left"></div>
                <button type="button" (click)="close()" class="custom-button red left">
                    {{ permissionsModel.update ? 'Cancel' : 'Close' }}
                </button>
            </form>
        </div>
    </div>
</div>

<app-confirm-modal
    *ngIf="confirmRoomStatusChangeToArchive"
    [(confirmModal)]="confirmRoomStatusChangeToArchive"
    (declined)="confirmRoomStatusChangeToArchive = false"
    [message]="confirmRoomStatusChangeToArchiveMessage"
    (confirm)="submitUpdateRoom()"
>
</app-confirm-modal>
