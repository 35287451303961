export const environment = {
    production: true,
    apiUrl: 'https://api.caterbook.net/api',
    azureBlobUrl: 'https://caterbookstorageprod.blob.core.windows.net/',
    commonPicturesContainer: 'common-pictures/',
    webWidgetUrl: 'https://book.caterbook.net',
    webWidgetScript: 'main.min.js',
    titleAppName: 'Caterbook',
    uiUrl: 'https://m.caterbook.net/'
};
