import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoCardlessModel } from '../../../../models/user/activation.model';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';

@Component({
    selector: 'app-confirm-direct-debit-modal',
    templateUrl: './confirm-direct-debit-modal.component.html',
    styleUrls: ['./confirm-direct-debit-modal.component.less'],
})
export class ConfirmDirectDebitModalComponent implements OnInit {
    @Input() debitConfirmModal: boolean;
    @Output() debitConfirmModalChange = new EventEmitter<boolean>();
    @Output() confirm = new EventEmitter<boolean>();
    @Input() goCardLessModel: GoCardlessModel;
    theme: number;

    constructor() {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    }

    ngOnInit() {}

    confirmFunction() {
        this.confirm.emit(true);
        this.close();
    }

    close() {
        this.debitConfirmModalChange.emit(false);
    }
}
