<div class="rate-schedules-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box rate popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2 *ngIf="addInvoice">Add Invoice</h2>
            <h2 *ngIf="editInvoice">
                Invoice No.: <span class="blue-letters">{{ invoiceDetailsPDModel?.invoice?.invoiceNo }}</span>
            </h2>
            <h3 *ngIf="editInvoice">
                Status: <span class="blue-letters">{{ invoiceDetailsPDModel?.invoice?.status }}</span>
            </h3>

            <div class="form-item" *ngIf="addInvoice">
                <div class="custom-label">Invoice to</div>
                <dx-select-box
                    class="custom-select"
                    #selectBox
                    [value]="invoiceAccount"
                    [dataSource]="invoiceDetailsPDModel.invoiceToList"
                    displayExpr="invoiceTo.accountName"
                    valueExpr="invoiceTo.accountId"
                    (onItemClick)="setHeaderDetailsDevex($event)"
                    name="item"
                >
                </dx-select-box>
            </div>
            <form [formGroup]="valForm" (ngSubmit)="submitInvoice($event)">
                <div class="booking-details">
                    <!-- <div class="status-box" *ngIf="editInvoice">
            <ul>
              <li>Invoice No.: <span class="blue-letters">{{ invoiceDetailsPDModel?.invoice?.invoiceNo }}</span></li>
              <li>Status: <span class="blue-letters">{{ invoiceDetailsPDModel?.invoice?.status }}<br></span></li>
            </ul>
          </div> -->
                    <div class="field-box">
                        <ul>
                            <div class="form-item">
                                <div class="custom-label">Customer name:</div>
                                <input
                                    type="text"
                                    class="custom-input"
                                    name="customerName"
                                    formControlName="customerName"
                                    [(ngModel)]="invoiceDetailsPDModel.invoice.customerName"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['customerName'].hasError('required') &&
                                        (valForm.controls['customerName'].dirty ||
                                            valForm.controls['customerName'].touched)
                                    "
                                    >Customer name is required.</span
                                >
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['customerName'].hasError('maxlength') &&
                                        (valForm.controls['customerName'].dirty ||
                                            valForm.controls['customerName'].touched)
                                    "
                                    >Max 50 characters.</span
                                >
                            </div>
                            <div class="form-item">
                                <div class="custom-label">Customer additional data 1:</div>
                                <input
                                    type="text"
                                    class="custom-input"
                                    name="customerData1"
                                    formControlName="customerData1"
                                    [(ngModel)]="invoiceDetailsPDModel.invoice.customerAdditionalData1"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['customerData1'].hasError('maxlength') &&
                                        (valForm.controls['customerData1'].dirty ||
                                            valForm.controls['customerData1'].touched)
                                    "
                                    >Max 100 characters.</span
                                >
                            </div>
                            <div class="form-item">
                                <div class="custom-label">Customer additional data 2:</div>
                                <input
                                    type="text"
                                    class="custom-input"
                                    name="customerData2"
                                    formControlName="customerData2"
                                    [(ngModel)]="invoiceDetailsPDModel.invoice.customerAdditionalData2"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['customerData2'].hasError('maxlength') &&
                                        (valForm.controls['customerData2'].dirty ||
                                            valForm.controls['customerData2'].touched)
                                    "
                                    >Max 100 characters.</span
                                >
                            </div>
                            <div class="form-item">
                                <div class="custom-label">PO Reference:</div>
                                <input
                                    type="text"
                                    class="custom-input"
                                    name="poRef"
                                    formControlName="poRef"
                                    [(ngModel)]="invoiceDetailsPDModel.invoice.poRef"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['poRef'].hasError('maxlength') &&
                                        (valForm.controls['poRef'].dirty ||
                                            valForm.controls['poRef'].touched)
                                    "
                                    >Max 50 characters.</span
                                >
                            </div>
                        </ul>
                    </div>
                    <div class="field-box">
                        <ul>
                            <div class="form-item">
                                <div class="custom-label">Customer address:</div>
                                <input
                                    type="text"
                                    class="custom-input"
                                    name="customerAddress"
                                    formControlName="customerAddress"
                                    [(ngModel)]="invoiceDetailsPDModel.invoice.customerAddress"
                                />
                                <!-- <span class="text-danger" *ngIf="valForm.controls['customerAddress'].hasError('required') && (valForm.controls['customerAddress'].dirty || valForm.controls['customerAddress'].touched)">Customer address is required.</span> -->
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['customerAddress'].hasError('maxlength') &&
                                        (valForm.controls['customerAddress'].dirty ||
                                            valForm.controls['customerAddress'].touched)
                                    "
                                    >Max 50 characters.</span
                                >
                            </div>
                            <div class="form-item">
                                <input
                                    type="text"
                                    class="custom-input"
                                    name="customerAddress2"
                                    formControlName="customerAddress2"
                                    [(ngModel)]="invoiceDetailsPDModel.invoice.customerAddress2"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['customerAddress2'].hasError('maxlength') &&
                                        (valForm.controls['customerAddress2'].dirty ||
                                            valForm.controls['customerAddress2'].touched)
                                    "
                                    >Max 50 characters.</span
                                >
                            </div>
                            <div class="form-item">
                                <input
                                    type="text"
                                    class="custom-input"
                                    name="customerAddress3"
                                    formControlName="customerAddress3"
                                    [(ngModel)]="invoiceDetailsPDModel.invoice.customerAddress3"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['customerAddress3'].hasError('maxlength') &&
                                        (valForm.controls['customerAddress3'].dirty ||
                                            valForm.controls['customerAddress3'].touched)
                                    "
                                    >Max 50 characters.</span
                                >
                            </div>
                            <div class="form-item">
                                <input
                                    type="text"
                                    class="custom-input"
                                    name="customerAddress4"
                                    formControlName="customerAddress4"
                                    [(ngModel)]="invoiceDetailsPDModel.invoice.customerPostalCode"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['customerAddress4'].hasError('maxlength') &&
                                        (valForm.controls['customerAddress4'].dirty ||
                                            valForm.controls['customerAddress4'].touched)
                                    "
                                    >Max 50 characters.</span
                                >
                            </div>
                            <div class="form-item">
                                <div class="custom-label">Customer country:</div>
                                <input
                                    type="text"
                                    class="custom-input"
                                    name="customerCountry"
                                    formControlName="customerCountry"
                                    [(ngModel)]="invoiceDetailsPDModel.invoice.customerCountry"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['customerCountry'].hasError('maxlength') &&
                                        (valForm.controls['customerCountry'].dirty ||
                                            valForm.controls['customerCountry'].touched)
                                    "
                                    >Max 50 characters.</span
                                >
                            </div>
                        </ul>
                    </div>
                </div>

                <hr />

                <p class="subtitle">Rooms</p>
                <dx-data-grid
                    class="room-type-grid invoice-grid"
                    [dataSource]="invoiceDetailsPDModel.invoice.rooms.items"
                    (onCellPrepared) ="onCellPrepared($event)"[columns]="[
                        { dataField: 'description', width: '420', caption: 'Description', allowSorting: true },
                        { dataField: 'quantity', caption: 'Quantity', cellTemplate: 'quantity', allowSorting: true },
                        { dataField: 'priceIncVat', caption: 'Price', cellTemplate: 'price', allowSorting: true },
                        {
                            dataField: 'vatAmount',
                            caption: 'Vat Rate',
                            cellTemplate: 'vatAmount',
                            allowSorting: true
                        },
                        { dataField: 'total', caption: 'Total', cellTemplate: 'total', allowSorting: true },
                        { cellTemplate: 'delete', width: '50' }
                    ]"
                >
                    <div class="center-td-text" *dxTemplate="let item of 'price'">
                        {{ currencyContext.Symbol }}{{ item.data.priceIncVat | number: '1.2-2' }}
                    </div>
                    <div class="center-td-text" *dxTemplate="let item of 'total'">
                        {{ currencyContext.Symbol }}{{ item.data.total | number: '1.2-2' }}
                    </div>
                    <div class="center-td-text" *dxTemplate="let item of 'quantity'">
                        {{ item.data.quantity }}
                    </div>
                    <div class="center-td-text" *dxTemplate="let item of 'vatAmount'">
                        {{ item.data.vatAmount }}
                    </div>
                    <div *dxTemplate="let item of 'delete'">
                        <i *ngIf="!disableEditMode" title="Unlink" class="fa-solid fa-link-slash fa-icon gray fa-icon-xsmall" (click)="deleteRoomItem(item.data)"></i>
                    </div>
                    <dxo-scrolling mode="standard"></dxo-scrolling>
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true">
                    </dxo-pager>
                </dx-data-grid>

                <div class="flex total-btn-wrapper">
                    <div *ngIf="!disableEditMode" (click)="addNewRooms()" title="Link items" class="add-new-box">
                        <div class="text">Link items</div>
                        <div class="img">
                            <i class="fa-solid fa-plus fa-icon gray"></i>
                        </div>
                    </div>
                    <p class="total-price">
                        Total:
                        <span class="blue-letters"
                            >{{ currencyContext.Symbol }}{{ totalRoomsPrice() | number: '1.2-2' }}</span
                        >
                    </p>
                </div>

                <p class="subtitle">Extras</p>
                <dx-data-grid
                    class="room-type-grid invoice-grid"
                    #extrasGrid
                    [dataSource]="invoiceDetailsPDModel.invoice.extras.items"
                    (onCellPrepared) ="onCellPrepared($event)"[columns]="[
                        { dataField: 'description', width: '420', caption: 'Description', allowSorting: true },
                        { dataField: 'quantity', caption: 'Quantity', cellTemplate: 'quantity', allowSorting: true },
                        { dataField: 'priceIncVat', caption: 'Price', cellTemplate: 'price', allowSorting: true },
                        {
                            dataField: 'vatAmount',
                            caption: 'Vat Rate',
                            cellTemplate: 'vatAmount',
                            allowSorting: true
                        },
                        { dataField: 'total', caption: 'Total', cellTemplate: 'total', allowSorting: true },
                        { cellTemplate: 'delete', width: '50' }
                    ]"
                >
                    <div class="center-td-text" *dxTemplate="let item of 'price'">
                        {{ currencyContext.Symbol }}{{ item.data.priceIncVat | number: '1.2-2' }}
                    </div>
                    <div class="center-td-text" *dxTemplate="let item of 'total'">
                        {{ currencyContext.Symbol }}{{ item.data.total | number: '1.2-2' }}
                    </div>
                    <div class="center-td-text" *dxTemplate="let item of 'quantity'">
                        {{ item.data.quantity }}
                    </div>
                    <div class="center-td-text" *dxTemplate="let item of 'vatAmount'">
                        {{ item.data.vatAmount }}
                    </div>
                    <div *dxTemplate="let item of 'delete'">
                        <i *ngIf="!disableEditMode" title="Unlink" class="fa-solid fa-link-slash fa-icon gray fa-icon-xsmall" (click)="deleteExtrasItem(item.data)"></i>
                    </div>
                    <dxo-scrolling mode="standard"></dxo-scrolling>
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true">
                    </dxo-pager>
                </dx-data-grid>
                <div class="flex total-btn-wrapper">
                    <div *ngIf="!disableEditMode" (click)="addNewExtras()" title="Link items" class="add-new-box">
                        <div class="text">Link items</div>
                        <div class="img">
                            <i class="fa-solid fa-plus fa-icon gray"></i>
                        </div>
                    </div>
                    <p class="total-price">
                        Total:
                        <span class="blue-letters"
                            >{{ currencyContext.Symbol }}{{ totalExtrasPrice() | number: '1.2-2' }}</span
                        >
                    </p>
                </div>

                <hr />

                <div class="flex payments-total">
                    <div class="grid">
                        <p class="subtitle">Payments</p>
                        <dx-data-grid
                            class="room-type-grid"
                            [dataSource]="displayPayments"
                            [columns]="[
                                { dataField: 'paymentNo', caption: 'Payment No', allowSorting: true, width: 90 },
                                {
                                    dataField: 'paymentDate',
                                    cellTemplate: 'paymentDate',
                                    caption: 'Date',
                                    allowSorting: true,
                                    width: 80
                                },
                                { dataField: 'paymentType', caption: 'Type', allowSorting: true, width: 80 },
                                { dataField: 'paymentPurpose', caption: 'Purpose', allowSorting: true, width: 80 },
                                {
                                    dataField: 'amount',
                                    caption: 'Amount',
                                    cellTemplate: 'amount',
                                    allowSorting: true,
                                    width: 80
                                },
                                { cellTemplate: 'delete', width: '50' }
                            ]"
                        >
                            <div *dxTemplate="let item of 'paymentDate'">
                                {{ item.data.paymentDate | date: 'dd MMM yyyy' }}
                            </div>
                            <div *dxTemplate="let item of 'amount'">
                                {{ currencyContext.Symbol }}{{ item.data.amount | number: '1.2-2' }}
                            </div>
                            <div *dxTemplate="let item of 'delete'">
                                <i *ngIf="!disableEditMode" title="Unlink" class="fa-solid fa-link-slash fa-icon gray fa-icon-xsmall" (click)="deletePaymentItem(item.data)"></i>
                            </div>
                            <dxo-scrolling mode="standard"></dxo-scrolling>
                            <dxo-paging [pageSize]="10"></dxo-paging>
                            <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true">
                            </dxo-pager>
                        </dx-data-grid>

                        <div class="flex total-btn-wrapper">
                            <div
                                *ngIf="!disableEditMode"
                                (click)="addNewPayment()"
                                title="Link items"
                                class="add-new-box"
                            >
                                <div class="text">Link items</div>
                                <div class="img">
                                    <i class="fa-solid fa-plus fa-icon gray"></i>
                                </div>
                            </div>

                            <p class="total-price">
                                Total Payments:
                                <span class="blue-letters"
                                    >{{ currencyContext.Symbol }}{{ totalAmountOfPayments() | number: '1.2-2' }}</span
                                >
                            </p>
                        </div>
                    </div>

                    <div class="totals-wrapper">
                        <p>
                            Total:
                            <span class="blue-letters"
                                >{{ currencyContext.Symbol
                                }}{{ totalRoomsPrice() + totalExtrasPrice() | number: '1.2-2' }}</span
                            >
                        </p>
                        <p>
                            Payments:
                            <span class="blue-letters"
                                >{{ currencyContext.Symbol }}{{ totalAmountOfPayments() | number: '1.2-2' }}</span
                            >
                        </p>
                        <p>
                            Balance:
                            <span class="blue-letters"
                                >{{ currencyContext.Symbol }}{{ balanceInvoice() | number: '1.2-2' }}</span
                            >
                        </p>
                    </div>
                </div>

                <div class="mb-50">
                    <button *ngIf="!loader && !disableEditMode" type="submit" class="custom-button blue left">
                        Save
                    </button>
                    <div *ngIf="loader" class="loader left"></div>
                    <button (click)="close()" type="button" class="custom-button red left" *ngIf="!disableEditMode">
                        Cancel
                    </button>
                    <button (click)="close()" type="button" class="custom-button red left" *ngIf="disableEditMode">
                        Close
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<app-booking-details-invoice-item
    *ngIf="addRoomItems"
    [(addRoomItem)]="addRoomItems"
    [bookingId]="bookingId"
    [selectedRooms]="invoiceDetailsPDModel.invoice.rooms.items"
    (roomItemsList)="setRoomItemsInList($event)"
></app-booking-details-invoice-item>

<app-booking-details-invoice-item
    *ngIf="editRoomItems"
    [(editRoomItem)]="editRoomItems"
    [invoiceId]="invoiceId"
    [bookingId]="bookingId"
    (update)="init(); refreshParentData()"
></app-booking-details-invoice-item>

<app-booking-details-invoice-item
    *ngIf="addExtraItems"
    [(addExtraItem)]="addExtraItems"
    [selectedExtras]="invoiceDetailsPDModel.invoice.extras.items"
    [bookingId]="bookingId"
    (extrasItemsList)="setExtraItemsInList($event)"
></app-booking-details-invoice-item>

<app-booking-details-invoice-item
    *ngIf="editExtraItems"
    [(editExtraItem)]="editExtraItems"
    [invoiceId]="invoiceId"
    [bookingId]="bookingId"
    (update)="init(); refreshParentData()"
></app-booking-details-invoice-item>

<app-booking-details-invoice-payment
    *ngIf="addPaymentItems"
    [(addPayment)]="addPaymentItems"
    [selectedPayments]="invoiceDetailsPDModel.invoice.payments"
    [bookingId]="bookingId"
    (paymentList)="setPaymentsInList($event)"
></app-booking-details-invoice-payment>

<app-booking-details-invoice-payment
    *ngIf="editPaymentItems"
    [(editPayment)]="editPaymentItems"
    [invoiceId]="invoiceId"
    [bookingId]="bookingId"
    (update)="init(); refreshParentData()"
>
</app-booking-details-invoice-payment>

<app-delete-modal
    *ngIf="deleteRoomItems"
    [(deleteModal)]="deleteRoomItems"
    [message]="'Are you sure you want to unlink this room item?'"
    (delete)="confirmDeleteRoomItems()"
>
</app-delete-modal>

<app-delete-modal
    *ngIf="deleteExtraItems"
    [(deleteModal)]="deleteExtraItems"
    [message]="'Are you sure you want to unlink this extra item?'"
    (delete)="confirmDeleteExtraItems()"
>
</app-delete-modal>

<app-delete-modal
    *ngIf="deletePaymentItems"
    [(deleteModal)]="deletePaymentItems"
    [message]="'Are you sure you want to unlink this payment?'"
    (delete)="confirmDeletePaymentItems()"
>
</app-delete-modal>
