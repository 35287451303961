import { RateScheduleBaseModel } from '../rate-schedule/rate-schedule-base.model';
import { EnumModel } from '../shared/enum-model';
import { PromoCodeModel } from './promo-code.model';

export class PromoCodePDModel {
    public promoCodes: PromoCodeModel[];
    public rateSchedules: RateScheduleBaseModel[] = [];
    public statuses: EnumModel[] = [];
    public types: EnumModel[] = [];
}
