import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SettingsPartnersService } from '../settings-partners.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PartnerPdModel } from '../../../../models/partners/partner-pd.model';
import { PartnerModel } from '../../../../models/partners/partner.model';
import { StatusModel } from '../../../../models/shared/status.model';
import { EnumModel } from '../../../../models/shared/enum-model';
import { CountryModel } from '../../../../models/account/country.model';
import { SignupService } from '../../../signup/signup.service';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { CustomValidationService } from 'src/app/shared/services/custom-validation.service';
import { InternationalDiallingNumbersModel } from '../../../../models/shared/international-dialling-numbers.model';
import { InternationalDiallingCodesService } from '../../../../shared/services/international-dialling-codes.service';
import { PartnersPermissionsModel } from '../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../enums/permissions.enum';

@Component({
    selector: 'app-partner-details',
    templateUrl: './partner-details.component.html',
    styleUrls: ['./partner-details.component.less'],
})
export class PartnerDetailsComponent implements OnInit {
    @Input() addPartnerPopup: boolean;
    @Input() editPartnerPopup: boolean;
    @Input() partnerId: number;
    @Output() addPartnerPopupChange = new EventEmitter<boolean>();
    @Output() editPartnerPopupChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();

    loader = false;
    // dialCode: string;
    // phoneNumber: string;
    // phoneErrorMessage = '';

    valForm: UntypedFormGroup;
    partnerPDModel = new PartnerPdModel();
    statusModelList: StatusModel[] = [];
    countries: CountryModel[] = [];
    // diallingCodesList: InternationalDiallingNumbersModel[] = [];

    theme: number;

    permissionsModel: PartnersPermissionsModel;

    constructor(
        private settingsPartnersService: SettingsPartnersService,
        private fb: UntypedFormBuilder,
        private signupService: SignupService,
        private diallingCodeService: InternationalDiallingCodesService,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.Partners);

        /*this.diallingCodeService.getDiallingCodes()
      .subscribe(
        (response) => {
          this.diallingCodesList = response;
          this.diallingCodesList = InternationalDiallingCodesService.setLabel(this.diallingCodesList);
          this.diallingCodesList = InternationalDiallingCodesService.sortByPriority(this.diallingCodesList);
        }
      );*/

        this.valForm = this.fb.group({
            name: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            status: [null, Validators.required],
            rateSchedule: [null, Validators.required],
            priceAdjustment: [null, Validators.required],
            discAmount: [null, Validators.required],
            shortName: [null, Validators.required],
            email: [null, Validators.compose([Validators.maxLength(50), Validators.email])],
            phoneNumber: [null, Validators.compose([Validators.maxLength(50)])],
            phone2: [null, Validators.compose([Validators.maxLength(50)])],
            address1: [null],
            address2: [null],
            address3: [null],
            postalCode: [null],
            country: [null],
        });

        this.statusModelList.push(new StatusModel(true, 'Active'));
        this.statusModelList.push(new StatusModel(false, 'Inactive'));
    }

    ngOnInit() {
        if (this.editPartnerPopup) {
            this.settingsPartnersService.getPartnerDetailsPd(this.partnerId).subscribe(
                (response) => {
                    this.partnerPDModel = response;

                    /*if (this.partnerPDModel.partnerModel.phone) {
              this.dialCode = this.partnerPDModel.partnerModel.phone.substring(0, this.partnerPDModel.partnerModel.phone.indexOf(' '));
              this.phoneNumber = this.partnerPDModel.partnerModel.phone.substring(this.partnerPDModel.partnerModel.phone.indexOf(' ') + 1);
            } else {
              this.dialCode = '+44';
            }*/

                    if (this.partnerPDModel.partnerModel.discountType === 'None') {
                        this.valForm.controls['discAmount'].clearValidators();
                        this.valForm.controls['discAmount'].disable();
                        this.valForm.updateValueAndValidity();
                    }
                },
                (error) => {
                    this.close();
                }
            );
        } else if (this.addPartnerPopup) {
            // this.dialCode = '+44';

            this.settingsPartnersService.getPartnerDetailsPdForInsert().subscribe(
                (response) => {
                    this.partnerPDModel.partnerModel = new PartnerModel();
                    this.partnerPDModel.discountTypes = response.discountTypes;
                    this.partnerPDModel.rateSchedules = response.rateSchedules;
                },
                (error) => {
                    this.close();
                }
            );
        }

        this.signupService.getActiveCountries().subscribe(
            (response) => {
                this.countries = response;
            },
            (error) => {}
        );
    }

    submitProperty(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            this.insertUpdatePartner();

            /*if (this.phoneNumber) {
        if (!isNaN(+this.phoneNumber.replace(/\s+/g, ''))) {
          if (this.dialCode && this.phoneNumber) {
            this.partnerPDModel.partnerModel.phone = this.dialCode + ' ' + this.phoneNumber;
          } else if (!this.dialCode && this.phoneNumber) {
            this.phoneErrorMessage = 'Please select dial code';
            return;
          } else {
            this.partnerPDModel.partnerModel.phone = null;
          }

          this.insertUpdatePartner();

        } else {
          this.phoneErrorMessage = 'Number is not valid';
        }
      } else {
        this.partnerPDModel.partnerModel.phone = null;
        this.insertUpdatePartner();
      }*/
        }
    }

    insertUpdatePartner() {
        this.loader = true;
        if (this.addPartnerPopup) {
            this.insertPartner();
        } else if (this.editPartnerPopup) {
            this.updatePartner();
        }
    }

    insertPartner() {
        this.settingsPartnersService.insertPartner(this.partnerPDModel.partnerModel).subscribe(
            (response) => {
                this.loader = false;
                this.update.emit();
                this.close();
            },
            (error) => {
                this.loader = false;
            }
        );
    }

    updatePartner() {
        this.settingsPartnersService.updatePartner(this.partnerPDModel.partnerModel).subscribe(
            (response) => {
                this.loader = false;
                this.update.emit();
                this.close();
            },
            (error) => {
                this.loader = false;
            }
        );
    }

    checkDiscountType(e) {
        if (e.itemData !== null) {
            const type: EnumModel = e.itemData;
            if (type.value === 'None') {
                this.partnerPDModel.partnerModel.discountAmount = null;
                this.valForm.controls['discAmount'].clearValidators();
                this.valForm.controls['discAmount'].disable();
                this.valForm.updateValueAndValidity();
            } else {
                this.valForm.controls['discAmount'].setValidators(Validators.required);
                this.valForm.controls['discAmount'].enable();
                this.valForm.updateValueAndValidity();
            }
        }
    }

    close() {
        this.addPartnerPopupChange.emit(false);
        this.editPartnerPopupChange.emit(false);
    }
}
