<div class="incidental-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup flex" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Invoices</h2>

            <dx-data-grid
                class="rate-plans-grid"
                [dataSource]="invoices"
                [allowColumnResizing]="true"
                [columns]="[
                    {
                        dataField: 'invoiceNo',
                        caption: 'Invoice No.',
                        cellTemplate: 'invoice',
                        allowSorting: true,
                        width: '120'
                    },
                    {
                        dataField: 'amount',
                        caption: 'Value',
                        allowSorting: true,
                        width: '100',
                        cellTemplate: 'amounts',
                        alignment: 'left'
                    },
                    {
                        dataField: 'balance',
                        caption: 'Balance',
                        allowSorting: true,
                        cellTemplate: 'balances',
                        alignment: 'left'
                    },
                    {
                        dataField: 'status',
                        caption: 'Status',
                        allowSorting: true,
                        width: '100',
                        cellTemplate: 'statuses'
                    },
                    {
                        dataField: 'hasEmail',
                        caption: 'Has Email',
                        allowSorting: true,
                        width: '100',
                        cellTemplate: 'hasEmail'
                    },
                    { dataField: 'customerName', caption: 'Name', allowSorting: true, width: '100' }
                ]"
            >
                <div class="invoice" *dxTemplate="let n of 'invoice'">
                    {{ n.data.invoiceNo }}
                </div>
                <div class="invoice" *dxTemplate="let r of 'hasEmail'">
                    <ng-container *ngIf="r.data.hasEmail"><i class="fa fa-check"></i></ng-container>
                    <ng-container *ngIf="!r.data.hasEmail"><i class="fa fa-times"></i></ng-container>
                </div>
                <div class="invoice" *dxTemplate="let s of 'statuses'">
                    {{ s.data.status.name }}
                </div>
                <div class="invoice" *dxTemplate="let r of 'amounts'">
                    {{ currencyContext.Symbol }} {{ r.data.amount }}
                </div>
                <div class="invoice" *dxTemplate="let r of 'balances'">
                    {{ currencyContext.Symbol }} {{ r.data.balance }}
                </div>
                <dxo-selection [allowSelectAll]="true" mode="multiple" showCheckBoxesMode="always"> </dxo-selection>
                <dxo-scrolling mode="standard"></dxo-scrolling>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true"> </dxo-pager>
            </dx-data-grid>
            <div class="bottom-holder">
                <div class="flex">
                    <button
                        type="button"
                        *ngIf="!loaderPrint && permissionsModel.exportPrintEmailEnabled"
                        class="custom-button orange"
                        (click)="printSelectedInvoices()"
                    >
                        Print selected invoices
                    </button>
                    <div *ngIf="loaderPrint" class="loader left"></div>
                    <button
                        type="button"
                        *ngIf="!loaderEmail && permissionsModel.exportPrintEmailEnabled"
                        class="custom-button orange"
                        (click)="openConfirmSendingInvoice()"
                    >
                        Email selected invoices
                    </button>
                    <div *ngIf="loaderEmail" class="loader left"></div>
                    <button
                        type="button"
                        *ngIf="!loaderPdf && permissionsModel.exportPrintEmailEnabled"
                        class="custom-button orange"
                        (click)="exportInvoicesToPDF()"
                    >
                        Export to PDF
                    </button>
                    <div *ngIf="loaderPdf" class="loader left"></div>
                </div>
                <button type="button" (click)="close()" class="custom-button red">Cancel</button>
            </div>
        </div>
    </div>
</div>
<app-confirm-modal
    *ngIf="sendInvoiceConfirmPopup"
    [(confirmModal)]="sendInvoiceConfirmPopup"
    [message]="confirmInvoiceSendingPopupMessage"
    (confirm)="confirmInvoiceSend()"
>
</app-confirm-modal>
