import { WidgetColorSchemeModel } from './../../../models/booking/widget-color-scheme.model';
import { MessageLogFilterModel } from './../../../models/message/message-log-filter.model';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { RegistrationFormModel } from '../../../models/templates/registration-form.model';
import { InvoiceTemplatePDModel } from '../../../models/templates/invoice-template-pd.mode';

@Injectable()
export class SettingsTemplatesService {
    // Registration form Templates Urls
    private getRegistrationFormTemplateUrl = environment.apiUrl + '/Templates/GetRegistrationFormTemplate';
    private updateRegistrationFormTemplateUrl = environment.apiUrl + '/Templates/UpdateRegistrationFormTemplate';

    // Booking Templates Urls
    private getPropertyBookingTemplatePageDataUrl =
        environment.apiUrl + '/Templates/GetPropertyBookingTemplatePageData';
    private getAccountBookingTemplatePageDataUrl = environment.apiUrl + '/Templates/GetAccountBookingTemplatePageData';

    // Invoice Templates Urls
    private updateInvoiceTempalteUrl = environment.apiUrl + '/Templates/UpdateInvoiceTemplate';
    private getInvoiceTemplateForPropertyUrl = environment.apiUrl + '/Templates/GetInvoiceTemplateForProperty';
    private getInvoiceReportsAccessTokenUrl = environment.apiUrl + '/Booking/GetInvoiceReportsAccessToken';
    public getInvoiceReportsIFrameUrl = environment.apiUrl + '/Booking/GetInvoiceReportsForm';

    // Message logs
    private getMessageLogsUrl = environment.apiUrl + '/Property/GetMessageLogs';
    private getForMessageLogsUrl = environment.apiUrl + '/Property/GetForMessageLogs';

    // PropertySettings Url
    private getPropertySettingByKeyUrl = environment.apiUrl + '/Property/GetPropertySettingByKey';

    // widget colors
    private savePropertyWebWidgetColorSchemeUrl = environment.apiUrl + '/Templates/SavePropertyWebWidgetColorScheme';
    private saveAccountWebWidgetColorSchemeUrl = environment.apiUrl + '/Templates/SaveAccountWebWidgetColorScheme';

    private getDefaultInvoiceTemplateForGuestUrl = environment.apiUrl + '/Templates/GetDefaultInvoiceTemplateForGuest';
    private getDefaultInvoiceTemplateForPartnerUrl =
        environment.apiUrl + '/Templates/GetDefaultInvoiceTemplateForPartner';
    private getDefaultInvoiceTemplateForStatementUrl =
        environment.apiUrl + '/Templates/GetDefaultInvoiceTemplateForStatement';

    constructor(private http: HttpRequestService) {}

    getRegistrationForm() {
        return this.http.get(this.getRegistrationFormTemplateUrl);
    }

    updateRegistrationForm(model: RegistrationFormModel) {
        return this.http.post(
            this.updateRegistrationFormTemplateUrl,
            model,
            true,
            'You successfully updated registration form template.'
        );
    }

    updateInvoiceTemplate(model: InvoiceTemplatePDModel) {
        return this.http.post(this.updateInvoiceTempalteUrl, model, true, 'You successfully updated invoice template.');
    }

    getPropertySettingByKey(key: string) {
        return this.http.get(this.getPropertySettingByKeyUrl + '?key=' + key);
    }

    getAccountBookingTemplatePageData() {
        return this.http.get(this.getAccountBookingTemplatePageDataUrl);
    }

    getPropertyBookingTemplatePageData() {
        return this.http.get(this.getPropertyBookingTemplatePageDataUrl);
    }

    getInvoiceTemplateForProperty() {
        return this.http.get(this.getInvoiceTemplateForPropertyUrl);
    }

    getInvoiceReportsAccessToken() {
        return this.http.get(this.getInvoiceReportsAccessTokenUrl);
    }

    getMessageLogs(messageLogFilterModel: MessageLogFilterModel) {
        return this.http.post(this.getMessageLogsUrl, messageLogFilterModel);
    }

    getForMessageLogs() {
        // grabs the enums for the select boxes
        return this.http.get(this.getForMessageLogsUrl);
    }

    saveWebWidgetColorScheme(model: WidgetColorSchemeModel) {
        return this.http.post(
            this.savePropertyWebWidgetColorSchemeUrl,
            model,
            true,
            'Colors and font updated successfully.'
        );
    }

    saveAccountWebWidgetColorScheme(model: WidgetColorSchemeModel) {
        return this.http.post(
            this.saveAccountWebWidgetColorSchemeUrl,
            model,
            true,
            'Colors and font updated successfully.'
        );
    }

    getDefaultInvoiceTemplateForGuest() {
        return this.http.get(this.getDefaultInvoiceTemplateForGuestUrl);
    }

    getDefaultInvoiceTemplateForPartner() {
        return this.http.get(this.getDefaultInvoiceTemplateForPartnerUrl);
    }

    getDefaultInvoiceTemplateForStatement() {
        return this.http.get(this.getDefaultInvoiceTemplateForStatementUrl);
    }
}
