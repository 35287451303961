import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthTokenService } from '../../services/auth-token.service';

@Component({
    selector: 'app-auto-logout-popup',
    templateUrl: './auto-logout-popup.component.html',
    styleUrls: ['./auto-logout-popup.component.less'],
})
export class AutoLogoutPopupComponent implements OnInit {
    @Input() timeOutPopup: boolean;
    @Output() timeOutPopupChange = new EventEmitter<boolean>();
    @Input() message: string;

    theme: number;

    constructor() {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    }

    ngOnInit() {}

    close() {
        this.timeOutPopupChange.emit(false);
    }
}
