import { RoomModel } from '../room/rooms-mapping.model';

export class CleaningGroupModel {
    public id: number;
    public propertyId: number;
    public name: string;
    public acive: boolean;
    public dateCreated: Date;
    public rooms: RoomModel[] = [];

    constructor(id?: number, name?: string) {
        this.id = id;
        this.name = name;
    }
}
