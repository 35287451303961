<div class="virtual-room-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <div class="flex tabs">
                <div class="tab" (click)="changeTab(0)" [ngClass]="[activeTab === 0 ? 'active' : '']">Add/Edit</div>
                <div class="tab" (click)="changeTab(1)" [ngClass]="[activeTab === 1 ? 'active' : '']">
                    All virtual rooms
                </div>
            </div>
            <h2 *ngIf="activeTab == 0">Virtual Room</h2>
            <h2 *ngIf="activeTab == 1">All Virtual Rooms</h2>
            <div class="form-box flex" *ngIf="activeTab == 1">
                <dx-data-grid
                    *ngIf="virtualRoomDetailsModel.virtualRooms"
                    [dataSource]="virtualRoomDetailsModel.virtualRooms"
                    [columns]="[
                        { dataField: 'fullName', caption: 'Name', allowSorting: true },
                        { cellTemplate: 'configuredRooms', caption: 'Configured Rooms', allowSorting: false },
                        { dataField: 'status', caption: 'Status', allowSorting: true },
                        { cellTemplate: 'action' }
                    ]"
                >
                    <div *dxTemplate="let t of 'configuredRooms'">
                        <ng-container *ngFor="let item of t.data.virtualRooms"> {{ item.shortName }}</ng-container>
                    </div>
                    <div *dxTemplate="let a of 'action'">
                        <i title="{{ permissionsModel.formButtonEnabled ? 'Edit' : 'View' }}" class="fas fa-pen-to-square fa-icon gray fa-icon-small" (click)="openEditVirtualRoom(a.data.id)"></i>
                    </div>
                    <dxo-scrolling mode="standard"></dxo-scrolling>
                    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true">
                    </dxo-pager>
                </dx-data-grid>
                <div
                    (click)="openAddVirtualRoom()"
                    title="Add new type"
                    class="add-new-box"
                    *ngIf="permissionsModel.create"
                >
                    <span class="text">Add New</span>
                    <div class="img">
                        <i class="fa-solid fa-plus fa-icon gray"></i>
                    </div>
                </div>
                <div class="button-wraper">
                    <button type="button" (click)="close()" class="custom-button red right">
                        {{ permissionsModel.formButtonEnabled ? 'Cancel' : 'Close' }}
                    </button>
                </div>
            </div>
            <div class="form-box flex" *ngIf="activeTab == 0">
                <form [formGroup]="valForm" (ngSubmit)="submitVirtualRoom($event)">
                    <ng-container *ngIf="virtualRoomModel">
                        <div class="items">
                            <div class="form-item">
                                <div class="custom-label">Name</div>
                                <input
                                    class="custom-input"
                                    name="virtualRoomModelName"
                                    formControlName="fullName"
                                    [(ngModel)]="virtualRoomModel.fullName"
                                    type="text"
                                    [readOnly]="!permissionsModel.formButtonEnabled"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['fullName'].hasError('required') &&
                                        (valForm.controls['fullName'].dirty || valForm.controls['fullName'].touched)
                                    "
                                    >Name is required.</span
                                >
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['fullName'].hasError('maxlength') &&
                                        (valForm.controls['fullName'].dirty || valForm.controls['fullName'].touched)
                                    "
                                    >Max 50 characters.</span
                                >
                            </div>
                            <div class="form-item">
                                <div class="custom-label">Short Name</div>
                                <input
                                    class="custom-input"
                                    name="shortName"
                                    formControlName="shortName"
                                    [(ngModel)]="virtualRoomModel.shortName"
                                    type="text"
                                    [readOnly]="!permissionsModel.formButtonEnabled"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['shortName'].hasError('required') &&
                                        (valForm.controls['shortName'].dirty || valForm.controls['shortName'].touched)
                                    "
                                    >Short name is required.</span
                                >
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['shortName'].hasError('maxlength') &&
                                        (valForm.controls['shortName'].dirty || valForm.controls['shortName'].touched)
                                    "
                                    >Max 10 characters.</span
                                >
                            </div>
                        </div>
                        <div class="items">
                            <div class="form-item">
                                <div class="custom-label">Custom Tag</div>
                                <input
                                    class="custom-input"
                                    name="customTag"
                                    formControlName="customTag"
                                    [(ngModel)]="virtualRoomModel.customTag"
                                    type="text"
                                    [readOnly]="!permissionsModel.formButtonEnabled"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['customTag'].hasError('required') &&
                                        (valForm.controls['customTag'].dirty || valForm.controls['customTag'].touched)
                                    "
                                    >Custom tag is required.</span
                                >
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['customTag'].hasError('maxlength') &&
                                        (valForm.controls['customTag'].dirty || valForm.controls['customTag'].touched)
                                    "
                                    >Max 50 characters.</span
                                >
                            </div>
                            <div class="form-item">
                                <div class="custom-label">Status</div>
                                <dx-select-box
                                    class="custom-select"
                                    [(value)]="virtualRoomModel.status"
                                    [dataSource]="virtualRoomDetailsModel.pageData.status"
                                    displayExpr="name"
                                    valueExpr="value"
                                    name="status"
                                    formControlName="status"
                                    [disabled]="!permissionsModel.formButtonEnabled"
                                >
                                </dx-select-box>
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['status'].hasError('required') &&
                                        (valForm.controls['status'].dirty || valForm.controls['status'].touched)
                                    "
                                    >Status is required.</span
                                >
                            </div>
                        </div>
                        <div class="items">
                            <!--<div class="form-item">
                <div class="custom-label">Accommodation Type</div>
                <dx-select-box class="custom-select"
                               [(value)]="virtualRoomModel.accommodationTypeId"
                               [dataSource]="virtualRoomDetailsModel.pageData.accommodationTypes"
                               displayExpr="name"
                               valueExpr="id"
                               name="accommodationType"
                               formControlName="accommodationType">
                </dx-select-box>
                <span class="text-danger"
                      *ngIf="valForm.controls['accommodationType'].hasError('required') && (valForm.controls['accommodationType'].dirty || valForm.controls['accommodationType'].touched)">Accommodation type is required.</span>
              </div>-->
                            <div class="form-item">
                                <div class="custom-label">Last Room Default Type</div>
                                <dx-select-box
                                    class="custom-select"
                                    [(value)]="virtualRoomModel.lastRoomTypeId"
                                    [dataSource]="virtualRoomDetailsModel.pageData.lastRoomDefault"
                                    displayExpr="name"
                                    valueExpr="id"
                                    name="lastRoomType"
                                    formControlName="lastRoomType"
                                    [disabled]="!permissionsModel.formButtonEnabled"
                                >
                                </dx-select-box>
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['lastRoomType'].hasError('required') &&
                                        (valForm.controls['lastRoomType'].dirty ||
                                            valForm.controls['lastRoomType'].touched)
                                    "
                                    >Last room default type is required.</span
                                >
                            </div>
                            <div class="form-item">
                                <div class="custom-label">Rooms</div>
                                <dx-tag-box
                                    class="custom-select"
                                    [(value)]="selectedRooms"
                                    valueExpr="id"
                                    displayExpr="fullName"
                                    placeholder="Select rooms..."
                                    [dataSource]="virtualRoomDetailsModel.pageData.allRooms"
                                    name="rooms"
                                    formControlName="rooms"
                                    [showSelectionControls]="true"
                                    [disabled]="!permissionsModel.formButtonEnabled"
                                >
                                </dx-tag-box>
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['rooms'].hasError('required') &&
                                        (valForm.controls['rooms'].dirty || valForm.controls['rooms'].touched)
                                    "
                                    >Rooms are required.</span
                                >
                            </div>
                        </div>
                        <div class="items"></div>
                    </ng-container>
                    <div class="button-wrapper">
                        <button
                            *ngIf="!loader && permissionsModel.update"
                            type="submit"
                            class="custom-button left blue"
                        >
                            Save
                        </button>
                        <div *ngIf="loader" class="loader left"></div>
                        <button (click)="close()" class="custom-button red left" type="button">
                            {{ permissionsModel.update ? 'Cancel' : 'Close' }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
