<div class="link-function-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box rate popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Link to Function</h2>

            <form (ngSubmit)="submitFunctionLink($event)" class="function-container" [formGroup]="valForm">
                <div class="form-item">
                    <div class="custom-label">Function</div>
                    <dx-select-box
                        class="custom-select"
                        [dataSource]="functions"
                        displayExpr="functionDesc"
                        [(value)]="functionId"
                        formControlName="function"
                        name="function"
                        valueExpr="id"
                    >
                    </dx-select-box>
                </div>
            

                <div class="flex total-btn-wrapper">
                    <button *ngIf="!loader" type="submit" class="custom-button blue left">Save</button>
                    <div *ngIf="loader" class="loader left"></div>
                    <button (click)="close()" type="button" class="custom-button red left">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>

