<div class="delete-modal flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>{{ message }}</h2>

            <div class="button-wrapper flex">
                <button type="button" class="custom-button red" (click)="confirmDelete()">Delete</button>
                <button type="button" class="custom-button orange" (click)="close()">Cancel</button>
            </div>
        </div>
    </div>
</div>
