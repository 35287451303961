<div class="rate-plans-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup sale-items" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Available extras - {{ ratePlanName }}</h2>

            <dx-data-grid
                [dataSource]="checkedSaleItemPDModel.list"
                rowTemplate="rowTemplateName"
                [columns]="[
                    { caption: '', allowSorting: false, width: 30 },
                    { dataField: 'name', caption: 'Category Item', allowSorting: true },
                    { dataField: 'saleItems.free', caption: 'Include (Free)', allowSorting: false },
                    { dataField: 'saleItems.chargeType', caption: 'Charge Type', allowSorting: false },
                    { dataField: 'saleItems.notes', caption: 'Notes', allowSorting: false }
                ]"
            >
                <table>
                    <ng-container *dxTemplate="let row of 'rowTemplateName'">
                        <tr class="category">
                            <td></td>
                            <td>{{ row.data.name }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr class="sale-grid-row" *ngFor="let item of row.data.saleItems; let i = index">
                            <td>
                                <input
                                    type="checkbox"
                                    name="checked"
                                    [(ngModel)]="item.checked"
                                    id="checkbox{{ item.id }}"
                                    class="css-checkbox"
                                    *ngIf="permissionsModel.formButtonEnabled"
                                />
                                <label for="checkbox{{ item.id }}" class="css-label radGroup1"></label>
                            </td>
                            <td class="row-name" title="{{ item.name }}">{{ item.name }}</td>
                            <td>
                                <input
                                    type="checkbox"
                                    name="free"
                                    [disabled]="!item.checked"
                                    [(ngModel)]="item.free"
                                    id="checkbox{{ item.id }}-2"
                                    class="css-checkbox"
                                />
                                <label
                                    for="checkbox{{ item.id }}-2"
                                    [ngClass]="{ disabled: !item.checked }"
                                    class="css-label radGroup1"
                                ></label>
                            </td>
                            <td>{{ item.chargeType }}</td>
                            <td>{{ item.notes }}</td>
                        </tr>
                    </ng-container>
                </table>

                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true"> </dxo-pager>
            </dx-data-grid>

            <button
                type="button"
                *ngIf="!loader && permissionsModel.formButtonEnabled"
                (click)="saveSaleItems()"
                class="custom-button blue"
            >
                Save
            </button>
            <div *ngIf="loader" class="loader left"></div>
            <button type="button" (click)="close()" class="custom-button red">
                {{ permissionsModel.formButtonEnabled ? 'Cancel' : 'Close' }}
            </button>
        </div>
    </div>
</div>
