import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { StatusModel } from '../../../../models/shared/status.model';
import { CleaningFrequencyModel } from '../../../../models/cleaning-frequency/cleaning-frequency.model';
import { HousekeepingService } from '../housekeeping.service';
import { EnumModel } from '../../../../models/shared/enum-model';
import { DayOfWeekHelper } from '../../../../models/shared/day-of-week-helper.model';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { InventoryHousekeepingPermissionsModel } from '../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../enums/permissions.enum';

@Component({
    selector: 'app-settings-housekeeping-details',
    templateUrl: './settings-housekeeping-details.component.html',
    styleUrls: ['./settings-housekeeping-details.component.less'],
})
export class SettingsHousekeepingDetailsComponent implements OnInit {
    @Input() addHousekeeping: boolean;
    @Input() editHousekeeping: boolean;
    @Output() addHousekeepingChange = new EventEmitter<boolean>();
    @Output() editHousekeepingChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();
    @Input() cleaningFrequency: CleaningFrequencyModel;

    permissionsModel: InventoryHousekeepingPermissionsModel;

    loader = false;
    showTagBox = false;
    valForm: UntypedFormGroup;
    statusModelList: StatusModel[] = [];
    frequencyTypes: EnumModel[] = [];
    cleaningFrequencyModel = new CleaningFrequencyModel();
    dayOfWeekHelper: DayOfWeekHelper[] = [];

    theme: number;

    constructor(
        private fb: UntypedFormBuilder,
        private housekeepingService: HousekeepingService,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryHousekeeping);

        this.valForm = this.fb.group({
            name: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            frequency: [null, Validators.required],
            scheduled: [null],
            status: [null, Validators.required],
            linen: [null],
            towel: [null],
            exlSunday: [null],
            summary: [null],
        });

        if (!this.permissionsModel.formButtonEnabled) {
            this.valForm.controls['name'].disable();
            this.valForm.controls['frequency'].disable();
            this.valForm.controls['scheduled'].disable();
            this.valForm.controls['status'].disable();
            this.valForm.controls['linen'].disable();
            this.valForm.controls['towel'].disable();
            this.valForm.controls['exlSunday'].disable();
            this.valForm.controls['summary'].disable();
        }

        this.statusModelList.push(new StatusModel(true, 'Active'));
        this.statusModelList.push(new StatusModel(false, 'Inactive'));
        for (let i = 1; i <= 7; i++) {
            const newDayOfWeek = new DayOfWeekHelper();
            newDayOfWeek.id = i;
            newDayOfWeek.day = newDayOfWeek.days[i - 1];
            this.dayOfWeekHelper.push(newDayOfWeek);
        }
    }

    ngOnInit() {
        this.housekeepingService.getCleaningFrequencyTypes().subscribe(
            (response) => {
                this.frequencyTypes = response;
                if (this.editHousekeeping) {
                    this.cleaningFrequencyModel = JSON.parse(JSON.stringify(this.cleaningFrequency));
                    this.valForm.controls['status'].setValue(this.cleaningFrequencyModel.active);
                    this.valForm.controls['frequency'].setValue(this.cleaningFrequencyModel.frequencyType);

                    if (this.cleaningFrequencyModel.frequencyType === 'Scheduled') {
                        this.showTagBox = true;
                        this.valForm.addControl('scheduled', new UntypedFormControl(null, Validators.required));
                        this.valForm.updateValueAndValidity();

                        if (this.cleaningFrequencyModel.weekdays !== null) {
                            this.cleaningFrequencyModel.weekdaysParsed = [];
                            for (const item of this.cleaningFrequencyModel.weekdays) {
                                this.cleaningFrequencyModel.weekdaysParsed.push(+item);
                            }

                            this.valForm.controls['scheduled'].setValue(this.cleaningFrequencyModel.weekdaysParsed);
                        }
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    submitCleaningType(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            this.loader = true;
            if (this.addHousekeeping) {
                this.insert();
            } else if (this.editHousekeeping) {
                this.edit();
            }
        }
    }

    checkFreqType(event) {
        if (event.itemData !== null) {
            if (event.itemData.value === 'Scheduled') {
                this.valForm.addControl('scheduled', new UntypedFormControl(null, Validators.required));
                this.valForm.updateValueAndValidity();
                this.showTagBox = true;
            } else {
                this.showTagBox = false;
                this.valForm.removeControl('scheduled');
                this.valForm.updateValueAndValidity();
                this.cleaningFrequencyModel.weekdays = null;
                this.cleaningFrequencyModel.weekdaysParsed = null;
            }
        }
    }

    setScheduledFreq(event) {
        const array: number[] = event.value;
        let stringArray = '';
        for (const item of array) {
            stringArray += '' + item;
        }
        this.cleaningFrequencyModel.weekdays = stringArray;
    }

    insert() {
        this.housekeepingService.createCleaningFrequency(this.cleaningFrequencyModel).subscribe(
            (response) => {
                this.loader = false;
                this.update.emit();
                this.close();
            },
            (error) => {
                console.log(error);
                this.loader = false;
            }
        );
    }

    edit() {
        this.housekeepingService.updateCleaningFrequency(this.cleaningFrequencyModel).subscribe(
            (response) => {
                this.loader = false;
                this.update.emit();
                this.close();
            },
            (error) => {
                this.loader = false;
                console.log(error);
            }
        );
    }

    close() {
        this.addHousekeepingChange.emit(false);
        this.editHousekeepingChange.emit(false);
    }
}
