import { Component, OnInit } from '@angular/core';
import { AuthTokenService } from '../shared/services/auth-token.service';
import { Router } from '@angular/router';
import { PropertyModel } from '../models/property/property.model';
import { SettingsPropertiesService } from '../routes/settings/settings-properties/settings-properties.service';
import { AuthLoginService } from '../shared/services/auth-login.service';
import { CommunicationService } from '../shared/services/communication.service';
import { DataTransferService } from '../shared/services/data-transfer.service';
import { AccountStatus } from '../enums/account.enum';
import { TokenContext } from '../models/auth-jwt/token-context';
import { AdminAccountsService } from '../routes/admin/admin-accounts/admin-accounts.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit {
    showActivationButton = true;
    showLogout = true;
    showPropertyCombo = true;

    propertyId: number;
    propertyList: PropertyModel[] = [];
    loggedInUser: string;

    token: TokenContext = new TokenContext();

    constructor(
        private authTokenService: AuthTokenService,
        private settingsPropertiesService: SettingsPropertiesService,
        private authLoginService: AuthLoginService,
        private communicationService: CommunicationService,
        public router: Router,
        private adminAccountsService: AdminAccountsService
    ) {
        this.token = AuthTokenService.decodeJwtToken();

        if (
            this.router.url.indexOf('login') > 0 ||
            this.router.url.indexOf('signup') > 0 ||
            this.router.url.indexOf('confirmation') > 0 ||
            this.router.url.indexOf('register-password/:hash') > 0 ||
            this.router.url.indexOf('register-password') > 0 ||
            this.router.url.indexOf('forgot-password') > 0 ||
            this.router.url.indexOf('ConfirmPassword') > 0 ||
            this.router.url.indexOf('ConfirmAdminPassword') > 0
        ) {
            this.showLogout = false;
            this.showActivationButton = false;
            this.showPropertyCombo = false;
        }

        if (this.router.url.indexOf('room-number') > 0) {
            this.showActivationButton = false;
        }

        if (
            this.token &&
            (this.router.url.indexOf('account-details') > 0 ||
                this.router.url.indexOf('debit-mandate') > 0 ||
                this.router.url.indexOf('payment-details') > 0)
        ) {
            this.showActivationButton = false;
            this.showPropertyCombo = false;
        }

        if (
            this.token &&
            this.token.Account &&
            (this.token.Account.Status === AccountStatus.Live || this.token.Account.Status === AccountStatus.Development || this.token.IsSuperUser)
        ) {
            this.showActivationButton = false;
        }

        this.communicationService.accountActivated.subscribe((response: boolean) => {
            if (response) {
                const token = AuthTokenService.decodeJwtToken();
                if (token.Account.Status) {
                    this.showActivationButton = false;
                }
            }
        });

        this.communicationService.refreshProperties.subscribe((response: boolean) => {
            if (response) {
                this.getProperties();
            }
        });

        this.communicationService.askForProperties.subscribe((res: boolean) => {
            if (res) {
                this.getProperties();
            }
        });
    }

    ngOnInit() {
        if (this.showPropertyCombo) {
            this.getProperties();
        }
        if (this.token != null) {
            if (this.token.IsSuperUser) {
                this.loggedInUser = 'super user : ' + this.token.User.Email;
            } else {
                this.loggedInUser = 'user : ' + this.token.User.Username;
            }
        }
    }

    getProperties() {
        this.settingsPropertiesService.getProperties().subscribe(
            (response) => {
                this.propertyList = response;

                if (this.token && this.token.Property) {
                    this.propertyId = this.token.Property.Id;
                } else {
                    if (this.propertyList.length > 0) {
                        this.propertyId = this.propertyList[0].id;
                    }
                }
                if (this.propertyList.length > 1) {
                    this.communicationService.isMultiProperty.next(true);
                }
                this.communicationService.passAssignedProperties.next(this.propertyList);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    setDashboardDataForProperty(propId) {
        if (propId !== null) {
            const propertyModel: PropertyModel = this.propertyList.find((p) => p.id === propId);

            if (this.token && this.token.IsSuperUser) {
                this.adminAccountsService.createPropertyLoginToken(propertyModel.id).subscribe(
                    (response) => {
                        this.authTokenService.refreshToken(response);

                        const refreshTokenWithoutImg = {
                            token: AuthTokenService.decodeJwtToken(),
                            refreshThumbnail: false,
                        };

                        this.communicationService.refreshToken.next(refreshTokenWithoutImg);
                        window.location.reload();
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            } else if (this.token && !this.token.IsSuperUser) {
                this.authLoginService.createPropertyLoginToken(propertyModel.id).subscribe(
                    (response) => {
                        this.authTokenService.refreshToken(response);

                        const refreshTokenWithoutImg = {
                            token: AuthTokenService.decodeJwtToken(),
                            refreshThumbnail: false,
                        };
                        this.communicationService.refreshToken.next(refreshTokenWithoutImg);
                        window.location.reload();
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
            this.checkRoute();
        }
    }

    activateAccount() {
        DataTransferService.PushData('ShowTrialMessage', false);
        this.router.navigate(['account-details']);
    }

    logout() {
        this.authTokenService.logout();
        this.router.navigate(['login']);
    }

    checkRoute() {
        if (this.token && this.router.url.indexOf('booking/details/') > 0) {
            this.router.navigate(['dashboard']);
        }
    }
}
