<app-header></app-header>

<div class="payment-details-wrapper flex">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <h2>Cardstream payment details</h2>

        <form [formGroup]="valForm" (ngSubmit)="submitCardStream($event)">
            <div class="form-box flex">
                <div class="form-item">
                    <!--<div class="custom-label">Payment description</div>
          <input class="custom-input">-->
                </div>
                <div class="form-item">
                    <!--<div class="custom-label">Card type</div>
          <select class="custom-select">
            <option value=""></option>
            <option value=""></option>
            <option value=""></option>
            <option value=""></option>
          </select>-->
                </div>
                <div class="form-item long">
                    <div class="custom-label">Card number</div>
                    <input
                        class="custom-input"
                        name="cardNumber"
                        formControlName="cardNumber"
                        type="number"
                        [(ngModel)]="activationFlowModel.cardStreamData.cardNumber"
                    />
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['cardNumber'].hasError('required') &&
                            (valForm.controls['cardNumber'].dirty || valForm.controls['cardNumber'].touched)
                        "
                        >Field is required.</span
                    >
                </div>
                <div class="form-item short">
                    <div class="custom-label">CVV</div>
                    <input
                        class="custom-input"
                        name="cvv"
                        formControlName="cvv"
                        min="0"
                        type="number"
                        [(ngModel)]="activationFlowModel.cardStreamData.cardCVV"
                    />
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['cvv'].hasError('required') &&
                            (valForm.controls['cvv'].dirty || valForm.controls['cvv'].touched)
                        "
                        >Field is required.</span
                    >
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['cvv'].hasError('maxlength') &&
                            (valForm.controls['cvv'].dirty || valForm.controls['cvv'].touched)
                        "
                        >Only 3 numbers are allowed.</span
                    >
                </div>
                <div class="form-item half">
                    <div class="custom-label">Expiry month</div>
                    <dx-select-box
                        class="custom-select"
                        [dataSource]="monthsEnumModel"
                        displayExpr="name"
                        [(value)]="activationFlowModel.cardStreamData.cardExpiryMonth"
                        valueExpr="value"
                        name="month"
                        formControlName="month"
                    >
                    </dx-select-box>
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['month'].hasError('required') &&
                            (valForm.controls['month'].dirty || valForm.controls['month'].touched)
                        "
                        >Field is required.</span
                    >
                </div>
                <div class="form-item half">
                    <div class="custom-label">Expiry year</div>
                    <dx-select-box
                        class="custom-select"
                        [dataSource]="yearArray"
                        [(value)]="activationFlowModel.cardStreamData.cardExpiryYear"
                        name="year"
                        formControlName="year"
                    >
                    </dx-select-box>
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['year'].hasError('required') &&
                            (valForm.controls['year'].dirty || valForm.controls['year'].touched)
                        "
                        >Field is required.</span
                    >
                </div>
            </div>

            <button *ngIf="!loader" type="submit" class="custom-button blue">Save</button>
            <div *ngIf="loader" class="loader"></div>
        </form>
    </div>
</div>

<app-success-modal
    *ngIf="popup"
    [(popup)]="popup"
    [title]="title"
    [message]="message"
    (confirm)="confirm()"
></app-success-modal>
