import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-settings-assign-rooms',
    templateUrl: './settings-assign-rooms.component.html',
    styleUrls: ['./settings-assign-rooms.component.less'],
})
export class SettingsAssignRoomsComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
