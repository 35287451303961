export default class CookieHelper {

  static  setCookie(name: string, value: any, seconds: number, isStringified: boolean) {
    var date = new Date();
    date.setSeconds(date.getSeconds() + (seconds || 300)); // default 300 
    var expires = "; expires=" + date.toUTCString();
    var obj = isStringified ? JSON.stringify(value) : value;
    document.cookie = name + "=" + (obj || "") + expires + "; path=/";
  } 

  static getCookie(name: string, isParsed: boolean) {
    var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
    if (result && result.length > 0 && isParsed)
      return JSON.parse(result[1]);
    else if (result && result.length > 0) {
      var ret = result[1];
      return ret;
    }
    return null;
  }

  static deleteCookie(name: string) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
 
}
