<div class="rate-plans-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2 *ngIf="addRatePlan">New Rate Plan</h2>
            <h2 *ngIf="detailsPopup">{{ permissionsModel.formButtonEnabled ? 'Edit Rate Plan' : 'Rate Plan' }}</h2>

            <div class="form-item small" *ngIf="detailsPopup">
                <div class="custom-label">Rate plan</div>
                <dx-select-box
                    class="custom-select"
                    [value]="ratePlanModel.id"
                    [dataSource]="ratePlanPropertyModelList"
                    displayExpr="shortName"
                    valueExpr="id"
                    (onItemClick)="setRatePlan($event)"
                >
                </dx-select-box>
            </div>

            <form [formGroup]="valForm" (ngSubmit)="submitRatePlanDetails($event)">
                <div class="rate-plan flex">
                    <div class="left-part flex">
                        <div class="form-item" hidden>
                            <div class="custom-label">ID</div>
                            <input
                                class="custom-input"
                                readonly
                                name="id"
                                formControlName="id"
                                [ngModel]="ratePlanModel.id"
                            />
                        </div>
                        <div class="form-item">
                            <div class="custom-label">{{this.countryContext.VatName}}</div>
                            <dx-select-box
                                class="custom-select"
                                [(value)]="ratePlanModel.vatId"
                                [dataSource]="createRatePlanPDModel.propertyVATs"
                                displayExpr="name"
                                valueExpr="id"
                                name="vat"
                                formControlName="vat"
                            >
                            </dx-select-box>
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['vat'].hasError('required') &&
                                    (valForm.controls['vat'].dirty || valForm.controls['vat'].touched)
                                "
                                >VAT is required.</span
                            >
                        </div>
                        <div class="form-item">
                            <div class="custom-label">Name</div>
                            <input
                                class="custom-input"
                                type="text"
                                name="ratePlanModelName"
                                formControlName="name"
                                [(ngModel)]="ratePlanModel.name"
                            />
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['name'].hasError('required') &&
                                    (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                                "
                                >Name is required.</span
                            >
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['name'].hasError('maxlength') &&
                                    (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                                "
                                >Max 50 characters.</span
                            >
                        </div>
                        <div class="form-item">
                            <div class="custom-label">Cancellation option</div>
                            <dx-select-box
                                class="custom-select"
                                [(value)]="ratePlanModel.cancellationPolicyId"
                                [dataSource]="createRatePlanPDModel.cancellationPolicies"
                                displayExpr="name"
                                valueExpr="id"
                                name="cancelOption"
                                formControlName="cancelOption"
                            >
                            </dx-select-box>
                        </div>
                        <div class="form-item">
                            <div class="custom-label">Short name</div>
                            <input
                                class="custom-input"
                                type="text"
                                name="shortName"
                                formControlName="shortName"
                                [(ngModel)]="ratePlanModel.shortName"
                            />
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['shortName'].hasError('required') &&
                                    (valForm.controls['shortName'].dirty || valForm.controls['shortName'].touched)
                                "
                                >Short name is required.</span
                            >
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['shortName'].hasError('maxlength') &&
                                    (valForm.controls['shortName'].dirty || valForm.controls['shortName'].touched)
                                "
                                >Max 50 characters.</span
                            >
                        </div>
                        <div class="form-item">
                            <div class="custom-label">Deposit option</div>
                            <dx-select-box
                                class="custom-select"
                                [(value)]="ratePlanModel.depositPolicyId"
                                [dataSource]="createRatePlanPDModel.depositPolicies"
                                displayExpr="name"
                                valueExpr="id"
                                name="depositType"
                                formControlName="depositType"
                            >
                            </dx-select-box>
                        </div>
                        <div class="form-item">
                            <div class="custom-label">Payment option</div>
                            <dx-select-box
                                class="custom-select"
                                [(value)]="ratePlanModel.paymentPolicyId"
                                [dataSource]="createRatePlanPDModel.paymentPolicies"
                                displayExpr="name"
                                valueExpr="id"
                                formControlName="paymentType"
                            >
                            </dx-select-box>
                        </div>
                    </div>

                    <div class="right-part flex">
                        <div class="form-item">
                            <div class="custom-label">Description</div>
                            <textarea
                                name="description"
                                class="custom-textarea"
                                formControlName="description"
                                [(ngModel)]="ratePlanModel.description"
                            ></textarea>
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['description'].hasError('required') &&
                                    (valForm.controls['description'].dirty || valForm.controls['description'].touched)
                                "
                                >Description is required.</span
                            >
                        </div>

                        <div class="form-item checkbox-wrapper flex">
                            <div class="form-item check">
                                <input
                                    type="checkbox"
                                    name="excludeFromWeb"
                                    formControlName="excludeFromWeb"
                                    [(ngModel)]="ratePlanModel.excludeFromWeb"
                                    id="excludeFromWeb"
                                    class="css-checkbox"
                                />
                                <label for="excludeFromWeb" class="css-label radGroup1"></label>
                                <div class="custom-label inline">Not bookable online</div>
                            </div>

                            <div class="form-item check">
                                <input
                                    type="checkbox"
                                    name="promoCodes"
                                    formControlName="promoCodes"
                                    [(ngModel)]="ratePlanModel.promoCodes"
                                    id="promoCodes"
                                    class="css-checkbox"
                                />
                                <label for="promoCodes" class="css-label radGroup1"></label>
                                <div class="custom-label inline">Allow promo codes</div>
                            </div>
                        </div>
                    </div>

                    <div class="middle-part flex">
                        <div class="form-item">
                            <div class="custom-label">Board type</div>
                            <dx-select-box
                                class="custom-select"
                                [(value)]="ratePlanModel.boardType"
                                [dataSource]="createRatePlanPDModel.boardTypes"
                                displayExpr="name"
                                valueExpr="value"
                                name="boardType"
                                formControlName="boardType"
                            >
                            </dx-select-box>
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['boardType'].hasError('required') &&
                                    (valForm.controls['boardType'].dirty || valForm.controls['boardType'].touched)
                                "
                                >Field is required.</span
                            >
                        </div>
                        <!-- <div class="form-item">
              <div class="custom-label">Payment option</div>
              <dx-select-box class="custom-select"
                             [(value)]="ratePlanModel.paymentPolicyId"
                             [dataSource]="createRatePlanPDModel.paymentPolicies"
                             displayExpr="name"
                             valueExpr="id"
                             formControlName="paymentType">
              </dx-select-box>
            </div> -->

                        <div class="form-item">
                            <div class="custom-label">Pricing type</div>
                            <dx-select-box
                                class="custom-select"
                                [(value)]="ratePlanModel.pricingType"
                                [dataSource]="createRatePlanPDModel.pricingTypes"
                                displayExpr="name"
                                valueExpr="value"
                                name="pricingType"
                                formControlName="pricingType"
                            >
                            </dx-select-box>
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['pricingType'].hasError('required') &&
                                    (valForm.controls['pricingType'].dirty || valForm.controls['pricingType'].touched)
                                "
                                >Field is required.</span
                            >
                        </div>

                        <div class="form-item">
                            <div class="custom-label">Allow arrival</div>
                            <dx-select-box
                                class="custom-select"
                                [dataSource]="createRatePlanPDModel.bookingTypes"
                                [(value)]="ratePlanModel.bookingType"
                                displayExpr="name"
                                valueExpr="value"
                                name="minlosType"
                                formControlName="minlosType"
                            >
                            </dx-select-box>
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['minlosType'].hasError('required') &&
                                    (valForm.controls['minlosType'].dirty || valForm.controls['minlosType'].touched)
                                "
                                >Field is required.</span
                            >
                        </div>
                        <div class="form-item">
                            <div *ngIf="ratePlanModel.bookingType == 'SpecificDay'">
                                <div class="custom-label">Arrival Day</div>
                                <dx-select-box
                                    class="custom-select"
                                    displayExpr="name"
                                    [dataSource]="createRatePlanPDModel.weekDays"
                                    [(ngModel)]="ratePlanModel.arrivalDay"
                                    (onChange)="selectionChanged($event)"
                                    valueExpr="value"
                                    name="arrivalDay"
                                    formControlName="arrivalDay"
                                >
                                </dx-select-box>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="custom-label">Status</div>
                            <dx-select-box
                                class="custom-select"
                                [(value)]="ratePlanModel.status"
                                [dataSource]="createRatePlanPDModel.ratePlanStatuses"
                                displayExpr="name"
                                valueExpr="value"
                                name="activity"
                                formControlName="activity"
                            >
                            </dx-select-box>
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['activity'].hasError('required') &&
                                    (valForm.controls['activity'].dirty || valForm.controls['activity'].touched)
                                "
                                >Field is required.</span
                            >
                        </div>
                        <div class="form-item">
                            <div class="custom-label">Order of appearance</div>
                            <input
                                class="custom-input"
                                type="number"
                                min="1"
                                name="orderAppearance"
                                formControlName="orderAppearance"
                                [(ngModel)]="ratePlanModel.orderNumber"
                            />
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['orderAppearance'].hasError('required') &&
                                    (valForm.controls['orderAppearance'].dirty ||
                                        valForm.controls['orderAppearance'].touched)
                                "
                                >Field is required.</span
                            >
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['orderAppearance'].hasError('min') &&
                                    (valForm.controls['orderAppearance'].dirty ||
                                        valForm.controls['orderAppearance'].touched)
                                "
                                >Minimum number is 1.</span
                            >
                            <span class="text-danger" *ngIf="!validateDisplayOrder()">Order is occupied.</span>
                        </div>

                        <div class="form-item check">
                            <input
                                type="checkbox"
                                name="includeChildren"
                                formControlName="includeChildren"
                                [(ngModel)]="ratePlanModel.includeChildren"
                                id="children"
                                class="css-checkbox"
                            />
                            <label for="children" class="css-label radGroup1"></label>
                            <div class="custom-label inline">Allow children</div>
                        </div>

                        <div class="form-item"></div>
                    </div>
                </div>

                <div class="actions flex">
                    <button
                        type="button"
                        *ngIf="detailsPopup"
                        (click)="openSaleItems()"
                        class="custom-button orange left"
                    >
                        Extras
                    </button>
                    <button
                        type="button"
                        *ngIf="detailsPopup"
                        (click)="openCostFees()"
                        class="custom-button orange left"
                    >
                        Cost centre fees
                    </button>
                    <button
                        type="button"
                        *ngIf="detailsPopup"
                        (click)="openRateSchedules()"
                        class="custom-button orange left"
                    >
                        Rate schedules
                    </button>
                    <button
                        type="button"
                        *ngIf="detailsPopup"
                        (click)="openDeriveOptions()"
                        class="custom-button orange right"
                    >
                        Derived pricing
                    </button>
                    <button
                        type="button"
                        *ngIf="detailsPopup && token.Property.CloudHookEPOS && ratePlanModel.boardType=='BreakfastAndDinner'"
                        (click)="openEPOSAllowance()"
                        class="custom-button orange right"
                    >
                        Configure allowance
                    </button>
                </div>

                <button
                    *ngIf="!loader && permissionsModel.formButtonEnabled"
                    type="submit"
                    class="custom-button blue left"
                >
                    Save
                </button>
                <div *ngIf="loader" class="loader left"></div>
                <button (click)="close()" class="custom-button red left" type="button">
                    {{ permissionsModel.formButtonEnabled ? 'Cancel' : 'Close' }}
                </button>
            </form>
        </div>
    </div>
</div>
<app-sale-items-popup
    *ngIf="saleItemsPopup"
    [(saleItemsPopup)]="saleItemsPopup"
    [ratePlanName]="ratePlanModel.name"
    [ratePlanId]="ratePlanId"
>
</app-sale-items-popup>
<app-cost-fees-popup
    *ngIf="costFees"
    [(costFees)]="costFees"
    [ratePlanName]="ratePlanModel.name"
    [ratePlanId]="ratePlanId"
    [itemChargeType]="createRatePlanPDModel.itemChargeTypes"
>
</app-cost-fees-popup>
<app-rate-plan-schedules
    *ngIf="rateSchedules"
    [ratePlanName]="ratePlanModel.name"
    [(rateSchedules)]="rateSchedules"
    [ratePlanId]="ratePlanId"
>
</app-rate-plan-schedules>

<app-epos-allowance-popup
    *ngIf="eposAllowance"
    [(eposAllowance)]="eposAllowance"
    [ratePlanName]="ratePlanModel.name"
    [ratePlanId]="ratePlanId"
    [ratePlanEposAllowanceType]="ratePlanModel.ePOSAllowanceType"
>
</app-epos-allowance-popup>

<app-derive-options *ngIf="deriveOptions" [(deriveOptions)]="deriveOptions" [ratePlanId]="ratePlanModel.id">
</app-derive-options>
<app-alert-popup
    *ngIf="alertDialog"
    (emitUpdate)="updateRatePlan()"
    [message]="
        'You have changed MinLoS type. This rate plan contains price plans. If you continue these price plans will be deleted.'
    "
    [(alertPopup)]="alertDialog"
>
</app-alert-popup>
