import { Component, EventEmitter, OnInit, Input, Output  } from '@angular/core';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { TokenContext } from '../../../models/auth-jwt/token-context';
import { Router } from '@angular/router';
import { FunctionModel, BookingFunction } from '../../../models/function-room/function.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NewFunctionService } from '../settings-new-function/new-function.service';

@Component({
  selector: 'app-settings-link-function',
  templateUrl: './settings-link-function.component.html',
  styleUrls: ['./settings-link-function.component.less']
})
export class SettingsLinkFunctionComponent implements OnInit {
  @Output() linkFunctionChange = new EventEmitter<boolean>();
  @Input() bookingId: number;

  theme: number;
  token: TokenContext;

  loader = false;
  functions: FunctionModel[] = [];
  functionId: number;

  valForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private functionService: NewFunctionService,
    ) { 
    this.token = AuthTokenService.decodeJwtToken();
    this.theme = this.token.User.CmsTheme;
    
    this.valForm = fb.group({
      function: [null, Validators.required],
    });

  }

  ngOnInit(): void {
    this.functionService.getFunctions().subscribe(
      (response) => {
          this.functions = response;
          this.functions.splice(0,0,new FunctionModel(0, 'None', 'None'));
      },
      (error) => {}
    );
  }

  close() {
    this.linkFunctionChange.emit(false);
  }

  submitFunctionLink(e) {
    e.stopPropagation();
    for (const c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid) {
      this.loader = true;

      let bookingFunction = new BookingFunction();
      bookingFunction.bookingId = this.bookingId;
      bookingFunction.functionId = this.functionId;

      this.functionService
      .linkToBooking(bookingFunction)
      .subscribe(
          (response) => {
            this.close();
          },
          (error) => {
              this.loader = false;
          }
      );
        
    }
  }

}
