import { Component, OnDestroy, OnInit } from '@angular/core';
import { AvailabilityRequestModel } from '../../models/booking/availability-request.model';
import { InventoryService } from './inventory.service';
import { AvailabilityGridPdModel } from '../../models/booking/availability-grid-pd.model';
import { AuthTokenService } from '../../shared/services/auth-token.service';
import { RateScheduleBaseModel } from '../../models/rate-schedule/rate-schedule-base.model';
import { RoomTypeDateAvailabilityGridModel } from '../../models/booking/availability-grid.model';
import { CookieService } from 'ngx-cookie-service';
import { PermissionsService } from '../../shared/services/permissions.service';
import { InventoryAvailabilityPermissionsModel } from '../../models/permissions/permissions.model';
import { PermissionsEnum } from '../../enums/permissions.enum';
import { TokenContext } from '../../models/auth-jwt/token-context';

@Component({
    selector: 'app-inventory',
    templateUrl: './inventory.component.html',
    styleUrls: ['./inventory.component.less'],
})
export class InventoryComponent implements OnInit, OnDestroy {
    permissionsModel: InventoryAvailabilityPermissionsModel;

    token: TokenContext;

    inventoryPartner = false;
    inventoryDetails = false;
    inventoryFunctionDetails = false;
    editRoomType = false;
    editFunctionRoomType = false;
    idOfRoomType: number;
    timeoutId: any;
    prevLoader = false;
    nextLoader = false;
    rateScheduleSelected = false;
    showTooltip = true;
    size: any;
    windowSize: any;
    loader = false;

    top: number;
    left: number;

    searchFilter: string;
    currentDateRange: string;
    roomTypeId: number;
    datePickerValue: string;

    // dates
    startDate: Date = new Date();
    endDate: Date = new Date();
    datesArray: string[] = [];
    dateForAvailabilityDetails: Date = new Date();

    rateScheduleBaseModel = new RateScheduleBaseModel();
    activeRateSchedules: RateScheduleBaseModel[] = [];
    availabilityRequestModel = new AvailabilityRequestModel();
    availabilityGridPdModel = new AvailabilityGridPdModel();
    availabilityGridFunctionPdModel = new AvailabilityGridPdModel();

    subscriptionSchedules: any;
    subscriptionAvailability: any;
    subscriptionFunctionAvailability: any;
    theme: number;

    rateScheduleId: number;

    constructor(
        private inventoryService: InventoryService,
        private cookieService: CookieService,
        private permissionsService: PermissionsService
    ) {
        this.token = AuthTokenService.decodeJwtToken();
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryAvailability);

        // cookie configuration
        const match = document.cookie.match(new RegExp('(^| )dateFrom=([^;]+)'));
        if (match && match.length > 1) {
            const dateFrom: string = match[2];
            const dateFromDecoded: string = decodeURI(dateFrom);
            this.endDate = new Date(dateFromDecoded);
        }

        const expiresDate: Date = new Date();
        expiresDate.setMinutes(expiresDate.getMinutes() + 10);
        this.setCookie(this.endDate.toDateString(), expiresDate);

        // cookieService.set('dateFrom', this.endDate.toDateString(), expiresDate, '');

        // const cookieExist: boolean = cookieService.check('dateFrom');
        // if (cookieExist) {
        //   this.endDate = new Date(cookieService.get('dateFrom'));
        // }

        this.getNextTwoWeeks(false);
    }

    setCookie(cookieValue, expiryDate) {
        const expires = 'expires=' + expiryDate.toUTCString();
        document.cookie = 'dateFrom=' + cookieValue + ';' + expires + ';path=/';
    }

    ngOnInit() {
        this.init();

        this.size = <HTMLElement>document.querySelectorAll('html')[0];
        if (this.size.getBoundingClientRect().width < 1025) {
            this.showTooltip = false;
        }
    }

    setDateToToday() {
        this.endDate = new Date();
        this.getNextTwoWeeks(true);
    }

    init() {
        const monthFrom = new Date(this.datesArray[0]).toLocaleString('en-us', { month: 'long' });
        const monthTo = new Date(this.datesArray[this.datesArray.length - 1]).toLocaleString('en-us', {
            month: 'long',
        });

        const yearFrom = new Date(this.datesArray[0]).getFullYear();
        const yearTo = new Date(this.datesArray[this.datesArray.length - 1]).getFullYear();

        if (monthFrom === monthTo && yearFrom === yearTo) {
            this.currentDateRange = monthFrom + ' / ' + yearFrom;
        } else if (monthFrom !== monthTo && yearFrom === yearTo) {
            this.currentDateRange = monthFrom + ' - ' + monthTo + ' / ' + yearFrom;
        } else {
            this.currentDateRange = monthFrom + ' / ' + yearFrom + ' - ' + monthTo + ' / ' + yearTo;
        }

        this.datePickerValue = this.datesArray[0];

        if (typeof this.subscriptionSchedules !== 'undefined') {
            this.subscriptionSchedules.unsubscribe();
        }

        this.subscriptionSchedules = this.inventoryService.getActiveSchedules().subscribe(
            (activeRateSchedules) => {
                this.activeRateSchedules = activeRateSchedules;
                this.availabilityRequestModel.propertyId = AuthTokenService.decodeJwtToken().Property.Id;
                this.availabilityRequestModel.from = this.datesArray[0];
                const dateTo = new Date(this.datesArray[this.datesArray.length - 1]);
                this.availabilityRequestModel.to = new Date(dateTo.getTime() + 24 * 60 * 60 * 1000).toDateString();

                if (!this.rateScheduleSelected && this.activeRateSchedules.length > 0) {
                    this.availabilityRequestModel.rateScheduleId = this.activeRateSchedules[0].id;
                    this.rateScheduleBaseModel = this.activeRateSchedules[0];
                }

                if (typeof this.subscriptionAvailability !== 'undefined') {
                    this.subscriptionAvailability.unsubscribe();
                }
                if (typeof this.subscriptionFunctionAvailability !== 'undefined') {
                    this.subscriptionFunctionAvailability.unsubscribe();
                }
                this.loader = true;
                this.subscriptionAvailability = this.inventoryService
                    .getAvailability(this.availabilityRequestModel)
                    .subscribe(
                        (availabilityRequestModel) => {
                            this.availabilityGridPdModel = availabilityRequestModel;
                            if (this.token.Property.Functions)
                            {
                                this.subscriptionFunctionAvailability = this.inventoryService
                                .getFunctionAvailability(this.availabilityRequestModel)
                                .subscribe(
                                    (availabilityRequestModel) => {
                                        this.loader = false;
                                        this.nextLoader = false;
                                        this.prevLoader = false;
                                        this.availabilityGridFunctionPdModel = availabilityRequestModel;
                                    },
                                    (error) => {
                                        this.loader = false;
                                        this.nextLoader = false;
                                        this.prevLoader = false;
                                        console.log(error);
                                    }
                                );
                            }
                            else {
                                this.loader = false;
                                this.nextLoader = false;
                                this.prevLoader = false;
                            }
                        },
                        (error) => {
                            this.loader = false;
                            this.nextLoader = false;
                            this.prevLoader = false;
                            console.log(error);
                        }
                    );
                
                this.rateScheduleId = this.availabilityRequestModel.rateScheduleId;
            },
            (error) => {
                this.nextLoader = false;
                this.prevLoader = false;
                console.log(error);
            }
        );
    }

    editRoom(id) {
        this.editRoomType = true;
        this.roomTypeId = id;
    }

    editFunctionRoom(id) {
        this.editFunctionRoomType = true;
        this.roomTypeId = id;
    }

    functionRoomUpdated() {
        this.editFunctionRoomType = false;
        this.roomTypeId = null;
        this.init();
    }

    getRateSchedule(id: number, list: RateScheduleBaseModel[]): RateScheduleBaseModel {
        for (const rateSchedule of list) {
            if (id === rateSchedule.id) {
                return rateSchedule;
            }
        }
    }

    showPopup(event, availability) {
        this.windowSize = <HTMLElement>document.querySelectorAll('html')[0];

        this.top = event.currentTarget.offsetTop - 6;

        if (this.size.getBoundingClientRect().width > 1500) {
            this.left = event.target.offsetLeft + 167;
        }

        if (this.size.getBoundingClientRect().width <= 1500 && this.size.getBoundingClientRect().width > 1366) {
            this.left = event.target.offsetLeft + 162;
        }

        if (this.size.getBoundingClientRect().width <= 1366 && this.size.getBoundingClientRect().width > 1200) {
            this.left = event.target.offsetLeft + 84;
        }

        if (this.size.getBoundingClientRect().width <= 1200) {
            this.left = event.target.offsetLeft - 79;
        }
        this.timeoutId = setTimeout(() => {
            availability.show = true;
        }, 1000);
    }

    hidePopup(availability) {
        clearTimeout(this.timeoutId);
        availability.show = false;
    }

    statusOfRoom(model: RoomTypeDateAvailabilityGridModel) {
        switch (model.status) {
            case 'Closed':
                return 'closed-status';
            case 'Unavailable':
                return 'unavailable-status';
            case 'Available':
                return 'available-status';
            case 'EnquiryOnly':
                return 'enquiry-only-status';
            case 'ClosedOnArrival':
                return 'closed-on-arrival-status';
            case 'ClosedOnDeparture':
                return 'closed-on-departure-status';
        }
    }

    weekendClass(model: RoomTypeDateAvailabilityGridModel) {
        const date: string = new Date(model.date).toDateString();
        const className: string =
            date.substr(0, date.indexOf(' ')) === 'Sat' || date.substr(0, date.indexOf(' ')) === 'Sun' ? 'weekend' : '';
        return className;
    }

    filterByRateSchedule(e) {
        if (e.itemData !== null) {
            this.rateScheduleSelected = true;
            this.rateScheduleBaseModel = this.getRateSchedule(e.itemData.id, this.activeRateSchedules);
            this.init();
        }
    }

    datePickerFunction(e) {
        if (e.value !== null) {
            this.datesArray = [];
            const chosenDate: Date = e.value;

            const currentDate: Date = new Date(chosenDate);
            const endDate: Date = new Date(chosenDate);
            endDate.setDate(endDate.getDate() + 14);

            while (currentDate < endDate) {
                const newDate: Date = new Date(currentDate);
                this.datesArray.push(newDate.toDateString());
                currentDate.setDate(currentDate.getDate() + 1);
            }

            const startDay = new Date(this.datesArray[0]);
            const endDay = new Date(this.datesArray[this.datesArray.length - 1]);
            this.startDate = new Date(startDay);
            this.endDate = new Date(endDay);
            this.startDate.setDate(startDay.getDate() - 1);
            this.endDate.setDate(endDay.getDate() + 1);

            const expiresDate: Date = new Date();
            expiresDate.setMinutes(expiresDate.getMinutes() + 10);
            this.setCookie(new Date(this.datesArray[0]).toDateString(), expiresDate);
            // this.cookieService.set('dateFrom', new Date(this.datesArray[0]).toDateString(), expiresDate, '');

            this.init();
        }
    }

    getNextTwoWeeks(fromConstructor?: boolean) {
        this.datesArray = [];
        this.nextLoader = true;

        const currentDate: Date = new Date(this.endDate);
        const endDate: Date = new Date(this.endDate);
        endDate.setDate(endDate.getDate() + 14);

        while (currentDate < endDate) {
            const newDate: Date = new Date(currentDate);
            this.datesArray.push(newDate.toDateString());
            currentDate.setDate(currentDate.getDate() + 1);
        }

        const startDay = new Date(this.datesArray[0]);
        const endDay = new Date(this.datesArray[this.datesArray.length - 1]);
        this.startDate = new Date(startDay);
        this.endDate = new Date(endDay);
        this.startDate.setDate(startDay.getDate() - 1);
        this.endDate.setDate(endDay.getDate() + 1);

        if (fromConstructor) {
            const expiresDate: Date = new Date();
            expiresDate.setMinutes(expiresDate.getMinutes() + 10);
            this.setCookie(new Date(this.datesArray[0]).toDateString(), expiresDate);
            // this.cookieService.set('dateFrom', new Date(this.datesArray[0]).toDateString(), expiresDate, '');

            this.init();
        }
    }

    getPreviousTwoWeeks() {
        this.datesArray = [];
        this.prevLoader = true;

        const endDate: Date = new Date(this.startDate);
        const startDate: Date = new Date(this.startDate);
        startDate.setDate(startDate.getDate() - 13);

        while (startDate <= endDate) {
            const newDate: Date = new Date(startDate);
            this.datesArray.push(newDate.toDateString());
            startDate.setDate(startDate.getDate() + 1);
        }

        const startDay = new Date(this.datesArray[0]);
        const endDay = new Date(this.datesArray[this.datesArray.length - 1]);
        this.startDate = new Date(startDay);
        this.endDate = new Date(endDay);
        this.startDate.setDate(startDay.getDate() - 1);
        this.endDate.setDate(endDay.getDate() + 1);

        const expiresDate: Date = new Date();
        expiresDate.setMinutes(expiresDate.getMinutes() + 10);
        this.setCookie(new Date(this.datesArray[0]).toDateString(), expiresDate);
        // this.cookieService.set('dateFrom', new Date(this.datesArray[0]).toDateString(), expiresDate, '');

        this.init();
    }

    openPartnerAvailability() {
        this.inventoryPartner = true;
    }

    openInventoryDetails(roomTypeId, date) {
        this.dateForAvailabilityDetails = date;
        this.roomTypeId = roomTypeId;
        this.inventoryDetails = true;
    }

    openFunctionInventoryDetails(roomTypeId, date) {
        this.dateForAvailabilityDetails = date;
        this.roomTypeId = roomTypeId;
        this.inventoryFunctionDetails = true;
    }

    functionAvailabilityUpdated() {
        this.roomTypeId = null;
        this.inventoryFunctionDetails = false;
        this.init();
    }

    ngOnDestroy() {
        if (typeof this.subscriptionAvailability !== 'undefined') {
            this.subscriptionAvailability.unsubscribe();
        }

        if (typeof this.subscriptionFunctionAvailability !== 'undefined') {
            this.subscriptionFunctionAvailability.unsubscribe();
        }

        if (typeof this.subscriptionSchedules !== 'undefined') {
            this.subscriptionSchedules.unsubscribe();
        }
    }
}
