import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ToastEvent } from "src/app/models/shared/toast-event";
import { ToastEventType } from "src/app/enums/toast-event-type";
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})

export class ToastService {
    toastEvents: Observable<ToastEvent>;

    private defaultId = 'default-alert';
    successTitle: string;
    warningTitle: string;
    errorTitle: string;
    infoTitle: string;

    private _toastEvents = new Subject<ToastEvent>();

    constructor() {
        this.toastEvents = this._toastEvents.asObservable();
    }

    showToast(title: string, primaryMessage: string, secondaryMessage: string, type: ToastEventType, showCloseButton: boolean, timeOut: number) {
        let iconCssClass = '';

        this._toastEvents.next({
            primaryMessage,
            secondaryMessage,
            title,
            type,
            showCloseButton,
            timeOut,
            iconCssClass
        });
    }
}