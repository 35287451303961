<div class="rate-schedules-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box rate popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2 *ngIf="addPayment">Link Payments</h2>
            <h2 *ngIf="editPayment">Link Payments</h2>

            <dx-data-grid
                [dataSource]="bookingPaymentList"
                [(selectedRowKeys)]="selectedRows"
                [columns]="[
                    { dataField: 'paymentNo', caption: 'Payment No', allowSorting: true },
                    { dataField: 'bookingNo', caption: 'Booking Ref', allowSorting: true },
                    { dataField: 'purpose.name', caption: 'Purpose', allowSorting: true },
                    { dataField: 'paidBy', caption: 'Paid by', allowSorting: true },
                    { dataField: 'typeName', caption: 'Type', allowSorting: true },
                    {
                        dataField: 'date',
                        caption: 'Date',
                        dataType: 'date',
                        format: 'shortDateShortTime',
                        allowSorting: true
                    },
                    { dataField: 'amount', caption: 'Amount', cellTemplate: 'amount', width: '70', allowSorting: true }
                ]"
            >
                <div *dxTemplate="let item of 'amount'">
                    {{ currencyContext.Symbol }}{{ item.data.amount | number: '1.2-2' }}
                </div>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
                <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="true" [showInfo]="true"> </dxo-pager>
            </dx-data-grid>

            <button
                *ngIf="!loader"
                type="button"
                (click)="submitBookingPayments()"
                [disabled]="selectedRows.length == 0"
                class="custom-button blue left"
            >
                Save
            </button>
            <div *ngIf="loader" class="loader left"></div>
            <button (click)="close()" type="button" class="custom-button red left">Cancel</button>
        </div>
    </div>
</div>
