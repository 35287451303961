import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { PropertyFunctionRoomModel } from '../../../../models/function-room/function-room.model';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../enums/permissions.enum';
import { FunctionRoomsService } from '../function-rooms.service';
import { InventoryFunctionRoomsPermissionsModel } from '../../../../models/permissions/permissions.model';
import ArrayStore from 'devextreme/data/array_store';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';

@Component({
  selector: 'app-function-rooms-edit',
  templateUrl: './function-rooms-edit.component.html',
  styleUrls: ['./function-rooms-edit.component.less']
})
export class FunctionRoomsEditComponent implements OnInit {
  @Input() editFunctionRoom: boolean;
  @Output() editFunctionRoomChange = new EventEmitter<boolean>();
  @Output() update = new EventEmitter();
  @Input() displayOrderList: number[];
  @Input() idOfFunctionRoom: number;
  @Input() hasOrder: boolean;

  permissionsModel: InventoryFunctionRoomsPermissionsModel;

  loader = false;
  showEditor = false;
  theme: number;
  popupLoader = true;
  submittedForm = false;

  valForm: UntypedFormGroup;
  functionRoomModel = new PropertyFunctionRoomModel();
  files: File[] = [];

  sessions: any;
  configurations: any;
  bookableDays: any;
  cateringOptions: any;

  showConfigurationTooltip = false;

  constructor(
    private fb: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private toasterService: ToastService,
    private functionRoomsService: FunctionRoomsService,
    private permissionsService: PermissionsService,
  ) { 

    this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryRoomTypes);
    this.sessions = [{id:1, value:"Morning", key:"Morning", name: "Morning"}];

    this.valForm = this.fb.group({
      name: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      shortName: [null, Validators.compose([Validators.required, Validators.maxLength(10)])],
      displayOrder: [null, Validators.compose([Validators.required, Validators.min(1)])],
      maxAttendance: [null, Validators.compose([Validators.required, Validators.min(1)])],
      basePrice: [null, Validators.compose([Validators.required, Validators.min(0)])],
      status: [null, Validators.required],
      vat: [null, Validators.required],
      costCenter: [null, Validators.required],
      bookableDays: [null],
      cateringOptions: [null],
      configurations: [null],
      sessions: [null],  
    });

    if (!this.permissionsModel.formButtonEnabled) {
      this.valForm.controls['name'].disable();
      this.valForm.controls['shortName'].disable();
      this.valForm.controls['displayOrder'].disable();
      this.valForm.controls['maxAttendance'].disable();
      this.valForm.controls['vat'].disable();
      this.valForm.controls['costCenter'].disable();
      this.valForm.controls['basePrice'].disable();
      this.valForm.controls['status'].disable();
      this.valForm.controls['bookableDays'].disable();
      this.valForm.controls['cateringOptions'].disable();
      this.valForm.controls['configurations'].disable();
      this.valForm.controls['sessions'].disable();
  }

  }

  submitFunctionRoom(e) {
    e.stopPropagation();
      this.submittedForm = true;
      for (const c in this.valForm.controls) {
          this.valForm.controls[c].markAsTouched();
      }

      if (
          this.valForm.valid &&
          this.validateDisplayOrder() &&
          this.validateBookableDays() &&
          this.validateConfigurations() && 
          this.validateSessions() &&
          this.functionRoomModel.description
      ) {
          this.loader = true;
          this.updateFunctionRoom();
      }
  }

  updateFunctionRoom() {
    this.functionRoomsService.updateFunctionRoom(this.functionRoomModel, this.files).subscribe(
        (response) => {
            this.loader = false;
            this.update.emit();
        },
        (error) => {
            this.loader = false;
            console.log(error);
        }
    );
}

  ngOnInit(): void {
    this.init();

    if (!this.hasOrder) {
      this.valForm.controls['displayOrder'].disable();
    }
  }

  init() {
    const propertyId = AuthTokenService.decodeJwtToken().Property.Id;
    this.functionRoomsService.getPropertyFunctionRoomDetails(propertyId, this.idOfFunctionRoom).subscribe(
        (response) => {
            this.functionRoomModel = response;
            this.sessions = new ArrayStore({
              data: this.functionRoomModel.pageData.sessions,
              key: 'id',
            });
            //move sunday to end
            this.functionRoomModel.pageData.bookableDays.push(this.functionRoomModel.pageData.bookableDays.shift());
            this.bookableDays = new ArrayStore({
              data: this.functionRoomModel.pageData.bookableDays,
              key: 'id',
            });
            this.configurations = new ArrayStore({
              data: this.functionRoomModel.pageData.configurations,
              key: 'id',
            });
            this.cateringOptions = new ArrayStore({
              data: this.functionRoomModel.pageData.cateringOptions,
              key: 'id',
            });
            //move sunday to end
            this.functionRoomModel.bookableDays.push(this.functionRoomModel.bookableDays.shift());

            this.popupLoader = false;
            this.showEditor = true;
            
        },
        (error) => {
            this.popupLoader = false;
        }
    );
  }

  validateBookableDays() {
    if (this.functionRoomModel.bookableDays == null || this.functionRoomModel.bookableDays.length == 0) {
      return false;
    }
    return true;
  }

  validateConfigurations() {
    if (this.functionRoomModel.configurations == null || this.functionRoomModel.configurations.length == 0) {
      return false;
    }
    return true;
  }

  validateSessions() {
    if (this.functionRoomModel.sessions == null || this.functionRoomModel.sessions.length == 0) {
      return false;
    }
    return true;
  }

  validateDisplayOrder() {
    if (this.displayOrderList != null) {
      for (const number of this.displayOrderList) {
        if (number === this.functionRoomModel.displayOrder) {
            return false;
        }
      }
    }
    return true;
  }

  setBookableDays(e) {
    this.functionRoomModel.bookableDays = e.value;
  }

  setCateringOptions(e) {
    this.functionRoomModel.cateringOptions = e.value;
  }

  setConfigurations(e) {
    this.functionRoomModel.configurations = e.value;
  }

  setSessions(e) {
    this.functionRoomModel.sessions = e.value;
  }

  close() {
    this.editFunctionRoomChange.emit(false);
  }

  setValueFromEditor(value) {
    this.functionRoomModel.description = value;
  }

  viewConfiguration() {
    this.showConfigurationTooltip = true;
  }

  closeConfiguration() {
    this.showConfigurationTooltip = false;
  }

}
