import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoomTypePictureModel } from '../../../../models/room-type/room-types.model';
import { RoomTypeDetailsModel } from '../../../../models/room-type/room-type-details.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SignupService } from '../../signup.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';

@Component({
    selector: 'app-room-type-add',
    templateUrl: './room-type-add.component.html',
    styleUrls: ['./room-type-add.component.less'],
})
export class RoomTypeAddComponent implements OnInit {
    @Input() addRoomType: boolean;
    @Output() addRoomTypeChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();
    @Input() displayOrderList: number[];

    loader = false;
    submittedForm = false;
    popupLoader = true;

    valForm: UntypedFormGroup;
    roomTypeDetailsModel = new RoomTypeDetailsModel();
    roomTypePictures: RoomTypePictureModel[] = [];

    files: File[] = [];
    theme: number;
    selectedBeddingArrangments: string[];

    constructor(
        private fb: UntypedFormBuilder,
        private signUpService: SignupService,
        private sanitizer: DomSanitizer,
        private toasterService: ToastService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;

        this.valForm = this.fb.group({
            status: [null, Validators.required],
            name: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            shortName: [null, Validators.compose([Validators.required, Validators.maxLength(10)])],
            occupancy: [null, Validators.compose([Validators.required, Validators.min(1)])],
            customTag: [null, Validators.maxLength(50)],
            childrenAvailability: [null],
            displayOrder: [null, Validators.compose([Validators.required, Validators.min(1)])],
            maxChildren: [null, Validators.compose([Validators.required, Validators.min(0)])],
            cleaningFrequencies: [null],
            beddingArrangement: [null, Validators.required],
            amenities: [null],
            class: [null, Validators.required],
            rackRate: [null, Validators.required],
            desc: [null],
        });

        this.signUpService.getDetailsRoomType(null).subscribe(
            (response) => {
                this.popupLoader = false;
                this.roomTypeDetailsModel.pageData = response.pageData;
                this.roomTypeDetailsModel.roomType.status = 'Active';
                this.roomTypeDetailsModel.roomType.childrenTakeAvailability =
                    response.roomType.childrenTakeAvailability;
                this.selectedBeddingArrangments = response.roomType.beddingArangement;
                // set number fields to zero
                this.roomTypeDetailsModel.roomType.maximumOccupancy = 1;
                this.roomTypeDetailsModel.roomType.maximumChildren = 0;
                this.roomTypeDetailsModel.roomType.displayOrder = this.roomTypeDetailsModel.pageData.displayOrder;
            },
            (error) => {
                this.popupLoader = false;
            }
        );

        for (let i = 0; i < 3; i++) {
            const picture = new RoomTypePictureModel();
            picture.order = i;
            this.roomTypePictures.push(picture);
        }
    }

    ngOnInit() {}

    validateDisplayOrder() {
        for (const number of this.displayOrderList) {
            if (number === this.roomTypeDetailsModel.roomType.displayOrder) {
                return false;
            }
        }

        return true;
    }

    setCleaningFrequencies(e) {
        this.roomTypeDetailsModel.roomType.cleaningFrequencies = e.value;
    }

    setAmenities(e) {
        this.roomTypeDetailsModel.roomType.amenities = e.value;
    }

    uploadImage(event, index) {
        const component = this;
        const files: any = <HTMLElement>event.target.files;
        const urlStringReader = new FileReader();
        if (files.length && files.length > 0 && this.isValidUploadData(files)) {
            const file = files[0];

            urlStringReader.addEventListener('load', function (event: any) {
                const src = event.target.result;
                component.roomTypePictures[index].thumbnail = src.split(',').pop();
                component.roomTypePictures[index].file = file;
            });

            urlStringReader.readAsDataURL(file);
        } else {
            this.toasterService.showToast('Error', 'File size exceeds the set limit of 4MB. Please try with a smaller file.', null, ToastEventType.Error, true, 5000);
        }
    }

    isValidUploadData(files: FileList) {
        let valid = true;
        Object.keys(files).forEach((i) => {
            if (files[i].size >= 4000000) {
                valid = false;
            }
        });
        return valid;
    }

    removeImage(picture) {
        const index = this.roomTypePictures.indexOf(picture);
        if (this.roomTypePictures.length > index) {
            this.roomTypePictures[index].thumbnail = '';
            this.roomTypePictures[index].file = null;
            this.roomTypePictures[index].order = null;
        }
    }

    sanitize(picture) {
        const index = this.roomTypePictures.indexOf(picture);
        if (index > -1) {
            if (this.roomTypePictures[index].thumbnail == null || this.roomTypePictures[index].thumbnail.length === 0) {
                return this.sanitizer.bypassSecurityTrustUrl('../../../../../assets/images/room-type-placeholder.png');
            }
            return this.sanitizer.bypassSecurityTrustUrl(
                'data:image/jpeg;base64,' + this.roomTypePictures[index].thumbnail
            );
        } else {
            return this.sanitizer.bypassSecurityTrustUrl('../../../../../assets/images/room-type-placeholder.png');
        }
    }

    submitRoomType(e) {
        e.stopPropagation();
        this.submittedForm = true;
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (
            this.valForm.valid &&
            this.valForm.controls['maxChildren'].value < this.valForm.controls['occupancy'].value &&
            this.validateDisplayOrder() &&
            this.roomTypeDetailsModel.roomType.description
        ) {
            this.loader = true;

            this.files = [];
            for (const picture of this.roomTypePictures) {
                if (picture.file !== null) {
                    this.files.push(picture.file);
                }
            }
            console.log(this.files);
            this.newRoomType();
        }
    }

    newRoomType() {
        this.roomTypeDetailsModel.roomType.propertyId = AuthTokenService.decodeJwtToken().Property.Id;
        this.roomTypeDetailsModel.roomType.beddingArangement = this.selectedBeddingArrangments;
        this.signUpService.createRoomType(this.roomTypeDetailsModel.roomType, this.files).subscribe(
            (response) => {
                this.loader = false;
                this.update.emit();
                this.close();
            },
            (error) => {
                this.loader = false;
                console.log(error);
            }
        );
    }

    close() {
        this.addRoomTypeChange.emit(false);
    }

    setValueFromEditor(value) {
        this.roomTypeDetailsModel.roomType.description = value;
        /*const div = document.createElement('div');
    div.innerHTML = this.messageTemplatePDModel.messageTemplate.message;
    this.messageWithoutHTMLTags = div.textContent || div.innerText || '';*/
    }
}
