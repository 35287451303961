import { Component, OnInit } from '@angular/core';
import { HousekeepingService } from './housekeeping.service';
import { CleaningFrequencyModel } from '../../../models/cleaning-frequency/cleaning-frequency.model';
import { CleaningGroupModel } from '../../../models/cleaning-frequency/cleaning-group.model';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { InventoryHousekeepingPermissionsModel } from '../../../models/permissions/permissions.model';
import { PermissionsEnum } from '../../../enums/permissions.enum';
import { PermissionsService } from '../../../shared/services/permissions.service';

@Component({
    selector: 'app-settings-housekeeping',
    templateUrl: './settings-housekeeping.component.html',
    styleUrls: ['./settings-housekeeping.component.less'],
})
export class SettingsHousekeepingComponent implements OnInit {
    permissionsModel: InventoryHousekeepingPermissionsModel;
    housekeepingOrder = false;
    addHousekeeping = false;
    editHousekeeping = false;
    addHousekeepingGroup = false;
    editHousekeepingGroup = false;
    deleteModalType = false;
    deleteModalGroup = false;

    activeTypeId: number;
    activeGroupId: number;
    theme: number;

    cleaningFrequencies: CleaningFrequencyModel[] = [];
    activeCleaningFrequency = new CleaningFrequencyModel();

    cleaningGroups: CleaningGroupModel[] = [];

    constructor(private housekeepingService: HousekeepingService, private permissionsService: PermissionsService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryHousekeeping);
    }

    ngOnInit() {
        this.init();
    }

    init() {
        this.housekeepingService.getCleaningFrequencies().subscribe(
            (response) => {
                this.cleaningFrequencies = response;
            },
            (error) => {
                console.log(error);
            }
        );

        this.housekeepingService.getCleaningGroupsWithRooms().subscribe(
            (response) => {
                this.cleaningGroups = response;
            },
            (error) => {
                console.log(error);
            }
        );
    }

    openHousekeepingOrder() {
        this.housekeepingOrder = true;
    }

    updateData() {
        this.housekeepingService.getCleaningFrequencies().subscribe(
            (response) => {
                this.cleaningFrequencies = response;
            },
            (error) => {}
        );
    }

    cellClick(e) {
        if (e.columnIndex === 0 && e.rowType !== 'header') {
            this.editCleaningType(e.data);
        }
    }

    cellClickGroup(e) {
        if (e.columnIndex === 0) {
            this.editCleaningGroup(e.data.id);
        }
    }

    addNewCleaningType() {
        this.addHousekeeping = true;
    }

    editCleaningType(data) {
        this.editHousekeeping = true;
        this.activeCleaningFrequency = data;
    }

    addNewCleaningGroup() {
        this.addHousekeepingGroup = true;
    }

    editCleaningGroup(id) {
        this.editHousekeepingGroup = true;
        this.activeGroupId = id;
    }

    openModalDeleteType(id) {
        this.activeTypeId = id;
        this.deleteModalType = true;
    }

    deleteType() {
        this.housekeepingService.deleteCleaningFrequency(this.activeTypeId).subscribe(
            (response) => {
                this.init();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    openModalDeleteGroup(id) {
        this.activeGroupId = id;
        this.deleteModalGroup = true;
    }

    deleteGroup() {
        this.housekeepingService.deleteCleaningGroup(this.activeGroupId).subscribe(
            (response) => {
                this.init();
            },
            (error) => {
                console.log(error);
            }
        );
    }
}
