<div class="function-rooms-wrapper flex">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <div class="title-wrapper">
            <h2>Function room editor</h2>
        </div>
        <div *ngIf="permissionsModel.update">
            <button type="button" (click)="openFunctionRoomOrder()" class="change-order">
                <i class="fa fa-sort" aria-hidden="true"></i>Change order
            </button>
        </div>

        <dx-data-grid
            *ngIf="functionRoomsModel?.functionRooms"
            [dataSource]="functionRoomsModel.functionRooms"
            class="function-room-grid"
            (onCellClick)="cellClick($event)"
            [columns]="[
                { dataField: 'displayOrder', cellTemplate: 'displayOrder', caption: 'Order', allowSorting: true, width: '100' },
                { dataField: 'name', cellTemplate: 'name', caption: 'Name', allowSorting: true },
                { dataField: 'shortName', caption: 'Short name', allowSorting: true, width: '120' },
                { dataField: 'id', cellTemplate: 'id', caption: 'Id', allowSorting: true, width: '100' },
                { dataField: 'description', caption: 'Description', cellTemplate: 'description', allowSorting: true },
                { dataField: 'maxAttendance', caption: 'Capacity', allowSorting: true, width: '120' },
                { cellTemplate: 'delete', width: '60' }
            ]"
        >
        <div class="left-td" *dxTemplate="let n of 'displayOrder'">
            {{ n.data.displayOrder }}
        </div>

        <div class="name" *dxTemplate="let n of 'name'">
            <i [ngClass]="[theme == 2 ? 'dark-theme' : '']" class="fa-solid fa-link fa-icon fa-icon-xsmall blue link-icon"></i>{{ n.data.name }}
        </div>

        <div class="left-td" *dxTemplate="let n of 'id'">
            {{ n.data.id }}
        </div>

        <div class="desc" *dxTemplate="let d of 'description'">
            {{ this.cleanHtml(d.data.description) }}
        </div>

        <div *dxTemplate="let a of 'delete'">
            <i *ngIf="permissionsModel.delete" title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small" (click)="deleteModal(a.data.id)"></i>
        </div>

            <dxo-scrolling mode="standard"></dxo-scrolling>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
            <dxo-paging [pageSize]="10"></dxo-paging>
        </dx-data-grid>

        <div *ngIf="permissionsModel.create" (click)="openFunctionRoomsPopup()" class="add-new-box">
            <span class="text">Add New</span>
            <div class="img">
                <i class="fa-solid fa-plus fa-icon gray"></i>
            </div>
        </div>

        
    </div>
</div>


<app-delete-modal
    *ngIf="deletePopup"
    [(deleteModal)]="deletePopup"
    [message]="'Are you sure you want to delete this function room?'"
    (delete)="deleteFunctionRoom()"
></app-delete-modal>

<app-function-rooms-add
    *ngIf="addFunctionRoomPopup"
    [(addFunctionRoom)]="addFunctionRoomPopup"
    [displayOrderList]="displayOrderList"
    (update)="updateData()"
></app-function-rooms-add>

<app-function-rooms-edit
    *ngIf="editFunctionRoomPopup"
    [hasOrder]="true"
    [idOfFunctionRoom]="idOfFunctionRoom"
    [displayOrderList]="displayOrderList"
    [(editFunctionRoom)]="editFunctionRoomPopup"
    (update)="updateData()"
></app-function-rooms-edit>

<app-function-room-order
    *ngIf="functionRoomOrder"
    [(functionRoomOrder)]="functionRoomOrder"
    [functionRoomsModel]="functionRoomsModel"
    (update)="updateData()"
></app-function-room-order>