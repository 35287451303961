export class RatePlanModel {
    public id: number;
    public propertyId: number;
    public vatId: number;
    public name: string;
    public shortName: string;
    public boardType: string;
    public pricingType: string;
    public bookingType: string;
    public promoCodes: boolean;
    public orderNumber: number;
    public includeChildren: boolean;
    public cancellationPolicyId: number;
    public depositPolicyId: number;
    public paymentPolicyId: number;
    public description: string;
    public status: string;
    public dateCreated: Date;
    public masterPlanId: number;
    public offset: number;
    public offsetPercentage: number;
    public offsetType: string;
    public excludeFromWeb: boolean;
    public arrivalDay: string;
    public googleOta: boolean;
    public ePOSAllowanceType: number;
    public ePOSAllowanceAmount: number;
    public ePOSAllowanceChargeType:number;

    // added new properties
    public bookingTypeOld: string;
}
