export enum PermissionsEnum {
    Calendar = 0,
    BookingSearch,
    NewBooking,
    NewFunction,
    GuestRecords,
    ResidentGuests,
    BrokenPayments,
    PricePlans,
    RatePlans,
    RateSchedules,
    Extras,
    PromoCodes,
    InventoryAvailability,
    InventoryRoomTypes,
    InventoryAssignRooms,
    InventoryFunctionRooms,
    InventoryVrooms,
    InventoryHousekeeping,
    FinanceManageInvoices,
    FinancePayments,
    FinanceVatRates,
    Messaging,
    Housekeeping,
    BookingTemplate,
    BookingEmailSMSSettings,
    Reports,
    PropertyList,
    PropertyCostCentres,
    PropertyBookingSources,
    PropertyTermsAndConditions,
    GuestRegistrationTemplate,
    Policies,
    Users,
    Partners,
    OtaConnections,
    Cloudhook,
    BookingDetail,
}
