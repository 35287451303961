export class CleaningFrequencyModel {
    public id: number;
    public order: number;
    public propertyId: number;
    public name: string;
    public frequencyType: string;
    public weekdays: string;
    public excludeSunday: boolean;
    public linen: boolean;
    public towels: boolean;
    public active: boolean;
    public dateCreated: Date;
    public summary: string;
    // added new properties
    public weekdaysParsed: number[] = [];
}
