import { UserStatus } from '../../enums/user.enum';
import { AccountStatus } from '../../enums/account.enum';

export class TokenContext {
    public User: UserContext;
    public AdminUser: AdminUserContext;
    public Account: AccountContext;
    public Property: PropertyContext;
    public Country: CountryContext;
    public Currency: CurrencyContext;
    public IsSuperUser: boolean;

    constructor() {
        this.User = new UserContext();
        this.AdminUser = new AdminUserContext();
        this.Account = new AccountContext();
        this.Property = new PropertyContext();
        this.Country = new CountryContext();
        this.Currency = new CurrencyContext();
    }
}

export class UserContext {
    public Id: number;
    public FirstName: string;
    public LastName: string;
    public Username: string;
    public Email: string;
    public Role: RoleStructToken;
    public Status: UserStatus;
    public CmsTheme: number;

    constructor() {
        this.Role = new RoleStructToken();
    }
}

export class AdminUserContext {
    public Id: number;
    public FirstName: string;
    public LastName: string;
    public Email: string;
    public Role: string;
    public Status: UserStatus;
}

export class AccountContext {
    public Id: number;
    public Name: string;
    public Email: string;
    public Status: AccountStatus;
}

export class PropertyContext {
    public Id: number;
    public Name: string;
    public CountryId: number;
    public ThumbnailId: string;
    public CloudHookEPOS: boolean;
    public ChannelManager: boolean;
    public Functions: boolean;
    public HistoricFunctions: boolean;
    public CloudHookEPOSAdvanced: boolean;
    public Xero: boolean;
    public ZReadEnabled: boolean;
    public OTASwitch: boolean;
}

export class CountryContext {
    public Name: string;
    public Code: string;
    public VatName: string;
}

export class CurrencyContext {
    public Name: string;
    public Code: string;
    public Symbol: string;
}

export class RoleStructToken {
    public Value: number;
    public Name: string;
    public Type: string;
}
