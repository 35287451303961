import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../shared/services/http-request.service';
import { environment } from '../../../environments/environment';
import { CancellationPolicyModel } from '../../models/policy/cancellation-policy.model';
import { DepositPolicyModel } from '../../models/policy/deposit-policy.model';
import { PaymentPolicyModel } from '../../models/policy/payment-policy.model';

@Injectable()
export class PoliciesService {
    private getDetailsCancellationPolicyUrl = environment.apiUrl + '/Policy/GetDetailsCancellationPolicy';
    private getDetailsCancellationPolicyForInsertUrl =
        environment.apiUrl + '/Policy/GetDetailsCancellationPolicyForInsert';
    private getAllCancellationPoliciesUrl = environment.apiUrl + '/Policy/GetCancellationPolicies';
    private deleteCancellationPolicyUrl = environment.apiUrl + '/Policy/DeleteCancellationPolicy';
    private insertCancellationPolicyUrl = environment.apiUrl + '/Policy/InsertCancellationPolicy';
    private updateCancellationPolicyUrl = environment.apiUrl + '/Policy/UpdateCancellationPolicy';

    private getDetailsDepositPolicyUrl = environment.apiUrl + '/Policy/GetDetailsDepositPolicy';
    private getDetailsDepositPolicyForInsertUrl = environment.apiUrl + '/Policy/GetDetailsDepositPolicyForInsert';
    private getAllDepositPoliciesUrl = environment.apiUrl + '/Policy/GetDepositPolicies';
    private deleteDepositPolicyUrl = environment.apiUrl + '/Policy/DeleteDepositPolicy';
    private insertDepositPolicyUrl = environment.apiUrl + '/Policy/InsertDepositPolicy';
    private updateDepositPolicyUrl = environment.apiUrl + '/Policy/UpdateDepositPolicy';

    private getDetailsPaymentPolicyUrl = environment.apiUrl + '/Policy/GetDetailsPaymentPolicy';
    private getDetailsPaymentPolicyForInsertUrl = environment.apiUrl + '/Policy/GetDetailsPaymentPolicyForInsert';
    private getAllPaymentPoliciesUrl = environment.apiUrl + '/Policy/GetPaymentPolicies';
    private deletePaymentPolicyUrl = environment.apiUrl + '/Policy/DeletePaymentPolicy';
    private insertPaymentPolicyUrl = environment.apiUrl + '/Policy/InsertPaymentPolicy';
    private updatePaymentPolicyUrl = environment.apiUrl + '/Policy/UpdatePaymentPolicy';

    constructor(private http: HttpRequestService) {}

    /* CANCELLATION POLICY */

    getDetailsCancellationPolicy(cancellationPolicyId: number) {
        return this.http.get(this.getDetailsCancellationPolicyUrl + '?cancellationPolicyId=' + cancellationPolicyId);
    }

    getDetailsCancellationPolicyForInsert() {
        return this.http.get(this.getDetailsCancellationPolicyForInsertUrl);
    }

    deleteCancellationPolicy(cancellationPolicyId: number) {
        return this.http.delete(
            this.deleteCancellationPolicyUrl + '?cancellationPolicyId=' + cancellationPolicyId,
            true
        );
    }

    getAllCancellationPolicies() {
        return this.http.get(this.getAllCancellationPoliciesUrl);
    }

    insertCancellationPolicy(model: CancellationPolicyModel) {
        return this.http.post(this.insertCancellationPolicyUrl, model, true);
    }

    updateCancellationPolicy(model: CancellationPolicyModel) {
        return this.http.put(this.updateCancellationPolicyUrl, model, true);
    }

    /* PAYMENT POLICY */

    getDetailsPaymentPolicy(paymentPolicyId: number) {
        return this.http.get(this.getDetailsPaymentPolicyUrl + '?paymentPolicyId=' + paymentPolicyId);
    }

    getDetailsPaymentPolicyForInsert() {
        return this.http.get(this.getDetailsPaymentPolicyForInsertUrl);
    }

    deletePaymentPolicy(paymentPolicyId: number) {
        return this.http.delete(this.deletePaymentPolicyUrl + '?paymentPolicyId=' + paymentPolicyId, true);
    }

    getAllPaymentPolicies() {
        return this.http.get(this.getAllPaymentPoliciesUrl);
    }

    insertPaymentPolicy(model: PaymentPolicyModel) {
        return this.http.post(this.insertPaymentPolicyUrl, model, true);
    }

    updatePaymentPolicy(model: PaymentPolicyModel) {
        return this.http.put(this.updatePaymentPolicyUrl, model, true);
    }

    /* DEPOSIT POLICY */

    getDetailsDepositPolicy(depositPolicyId: number) {
        return this.http.get(this.getDetailsDepositPolicyUrl + '?depositPolicyId=' + depositPolicyId);
    }

    getDetailsDepositPolicyForInsert() {
        return this.http.get(this.getDetailsDepositPolicyForInsertUrl);
    }

    deleteDepositPolicy(depositPolicyId: number) {
        return this.http.delete(this.deleteDepositPolicyUrl + '?depositPolicyId=' + depositPolicyId, true);
    }

    getAllDepositPolicies() {
        return this.http.get(this.getAllDepositPoliciesUrl);
    }

    insertDepositPolicy(model: DepositPolicyModel) {
        return this.http.post(this.insertDepositPolicyUrl, model, true);
    }

    updateDepositPolicy(model: DepositPolicyModel) {
        return this.http.put(this.updateDepositPolicyUrl, model, true);
    }
}
