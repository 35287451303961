import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-settings-room-types',
    templateUrl: './settings-room-types.component.html',
    styleUrls: ['./settings-room-types.component.less'],
})
export class SettingsRoomTypesComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
