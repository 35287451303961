import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { PropertyFunctionRoomModel } from '../../../../models/function-room/function-room.model';
import { FunctionRoomsService } from '../function-rooms.service';
import { FinanceHeldDepositsPermissionsModel } from 'src/app/models/permissions/permissions.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';

@Component({
  selector: 'app-function-rooms-add',
  templateUrl: './function-rooms-add.component.html',
  styleUrls: ['./function-rooms-add.component.less']
})
export class FunctionRoomsAddComponent implements OnInit {
  @Input() addFunctionRoom: boolean;
  @Output() addFunctionRoomChange = new EventEmitter<boolean>();
  @Output() update = new EventEmitter();
  @Input() displayOrderList: number[];

  loader = false;
  theme: number;
  popupLoader = true;
  submittedForm = false;

  valForm: UntypedFormGroup;
  functionRoomModel = new PropertyFunctionRoomModel();
  files: File[] = [];

  showConfigurationTooltip = false;

  vatInvalid = false;
  costCenterInvalid = false;

  constructor(
    private fb: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private toasterService: ToastService,
    private functionRoomsService: FunctionRoomsService,
  ) { 
    this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    
    this.valForm = this.fb.group({
      name: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      shortName: [null, Validators.compose([Validators.required, Validators.maxLength(10)])],
      description: [null],
      displayOrder: [null, Validators.compose([Validators.required, Validators.min(1)])],
      maxAttendance: [null, Validators.compose([Validators.required, Validators.min(1)])],
      basePrice: [null, Validators.compose([Validators.required, Validators.min(0)])],
      status: [null, Validators.required],
      vat: [null, Validators.required],
      costCenter: [null, Validators.required],
      bookableDays: [null],
      cateringOptions: [null],
      configurations: [null],
      sessions: [null],
    });

    const propertyId = AuthTokenService.decodeJwtToken().Property.Id;

    this.functionRoomsService.getPropertyFunctionRoomDetails(propertyId, null).subscribe(
      (response) => {
          this.popupLoader = false;
          this.functionRoomModel = response;
          //this.roomTypeDetailsModel.roomType.displayOrder = this.roomTypeDetailsModel.pageData.displayOrder;
          //move sunday to end
          this.functionRoomModel.pageData.bookableDays.push(this.functionRoomModel.pageData.bookableDays.shift());
      },
      (error) => {
          this.popupLoader = false;
      }
    );

  }

  ngOnInit(): void {
  }

  submitFunctionRoom(e) {
    e.stopPropagation();
    this.submittedForm = true;
    for (const c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
    }

    if (
        this.valForm.valid &&
        this.validateDisplayOrder() &&
        this.validateVat() &&
        this.validateCostCentre() &&
        this.validateBookableDays() &&
        this.validateConfigurations() && 
        this.validateSessions() &&
        this.functionRoomModel.description
    ) {
        this.loader = true;
        this.newFunctionRoom();
    }
  }

  newFunctionRoom() {
    this.functionRoomModel.propertyId = AuthTokenService.decodeJwtToken().Property.Id;

    //note this.files is an empty array, this leaves the option of enabling function room images at a later date
    this.functionRoomsService.createFunctionRoom(this.functionRoomModel, this.files).subscribe(
        (response) => {
            this.loader = false;
            this.update.emit();
            this.close();
        },
        (error) => {
            this.loader = false;
            console.log(error);
        }
    );
}

  validateBookableDays() {
    if (this.functionRoomModel.bookableDays == null || this.functionRoomModel.bookableDays.length == 0) {
      return false;
    }
    return true;
  }

  validateConfigurations() {
    if (this.functionRoomModel.configurations == null || this.functionRoomModel.configurations.length == 0) {
      return false;
    }
    return true;
  }

  validateSessions() {
    if (this.functionRoomModel.sessions == null || this.functionRoomModel.sessions.length == 0) {
      return false;
    }
    return true;
  }

  validateDisplayOrder() {
    for (const number of this.displayOrderList) {
        if (number === this.functionRoomModel.displayOrder) {
            return false;
        }
    }

    return true;
  }

  validateVat() {
    this.vatInvalid = false;
    if (this.functionRoomModel.vatId == null || this.functionRoomModel.vatId == 0) {
      this.vatInvalid = true;
      return false;
    }
    return true;
  }

  validateCostCentre() {
    this.costCenterInvalid = false;
    if (this.functionRoomModel.costCenterId == null || this.functionRoomModel.costCenterId == 0) {
      this.costCenterInvalid = true;
      return false;
    }
    return true;
  }

  setBookableDays(e) {
    console.log(e.value);
    this.functionRoomModel.bookableDays = e.value;
  }

  setCateringOptions(e) {
    this.functionRoomModel.cateringOptions = e.value;
  }

  setConfigurations(e) {
    this.functionRoomModel.configurations = e.value;
  }

  setSessions(e) {
    this.functionRoomModel.sessions = e.value;
  }

  close() {
    this.addFunctionRoomChange.emit(false);
  }

  setValueFromEditor(value) {
    this.functionRoomModel.description = value;
  }

  viewConfiguration() {
    this.showConfigurationTooltip = true;
  }

  closeConfiguration() {
    this.showConfigurationTooltip = false;
  }
  

}
