<div class="rate-schedules-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2 *ngIf="addHousekeepingGroup">Add cleaning group</h2>
            <h2 *ngIf="editHousekeepingGroup">
                {{ permissionsModel.formButtonEnabled ? 'Edit cleaning type' : 'Cleaning type' }}
            </h2>

            <form [formGroup]="valForm" class="form-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
                <div class="form-item">
                    <div class="custom-label">Group Name</div>
                    <input
                        formControlName="name"
                        name="cleaningGroupName"
                        class="custom-input"
                        [(ngModel)]="cleaningGroupPDModel.cleaningGroup.name"
                    />
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['name'].hasError('required') &&
                            (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                        "
                        >Group name is required</span
                    >
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['name'].hasError('maxlength') &&
                            (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                        "
                        >Max 50 characters</span
                    >
                </div>
                <div class="form-item">
                    <div class="custom-label">Status</div>
                    <dx-select-box
                        class="custom-select"
                        [dataSource]="statusModelList"
                        displayExpr="name"
                        [(value)]="cleaningGroupPDModel.cleaningGroup.acive"
                        formControlName="status"
                        valueExpr="value"
                    >
                    </dx-select-box>
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['status'].hasError('required') &&
                            (valForm.controls['status'].dirty || valForm.controls['status'].touched)
                        "
                        >Status is required.</span
                    >
                </div>
            </form>

            <dx-data-grid
                [dataSource]="cleaningGroupPDModel.cleaningGroup.rooms"
                [allowColumnResizing]="true"
                rowTemplate="rowTemplateName"
                [columns]="[
                    { dataField: 'id', caption: 'ID', allowSorting: true, width: 50 },
                    { dataField: 'fullName', caption: 'Full Name', allowSorting: true, width: 300 },
                    { dataField: 'shortName', caption: 'Short Name', allowSorting: true },
                    { cellTemplate: 'delete', allowSorting: false, width: 60 }
                ]"
            >
                <table>
                    <ng-container *dxTemplate="let row of 'rowTemplateName'">
                        <tr>
                            <td class="center-td">{{ row.data.id }}</td>
                            <td>
                                <dx-select-box
                                    class="custom-select"
                                    [dataSource]="cleaningGroupPDModel.rooms"
                                    valueExpr="id"
                                    [value]="row.data.id"
                                    displayExpr="fullName"
                                    (onValueChanged)="setRoomToCleaningGroup($event, row.data)"
                                    [disabled]="!permissionsModel.formButtonEnabled"
                                ></dx-select-box>
                            </td>
                            <td>{{ row.data.shortName }}</td>
                            <td (click)="deleteRow(row.data)" *ngIf="permissionsModel.formButtonEnabled">
                                <i title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small"></i>
                            </td>
                        </tr>
                    </ng-container>
                </table>

                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="true" [showInfo]="true"> </dxo-pager>
            </dx-data-grid>
            <div class="form-item">
                <span class="text-danger bottom-error">{{ errorMessage }}</span>
            </div>

            <div (click)="addNewItem()" class="add-new-box" *ngIf="permissionsModel.create">
                <span class="text">Add Room</span>
                <div class="img">
                    <i class="fa-solid fa-plus fa-icon gray"></i>
                </div>
            </div>

            <button
                *ngIf="!loader && permissionsModel.formButtonEnabled"
                type="button"
                (click)="submitCleaningGroup($event)"
                class="custom-button blue left"
            >
                Save
            </button>
            <div *ngIf="loader" class="loader left"></div>
            <button (click)="close()" type="button" class="custom-button red left">
                {{ permissionsModel.formButtonEnabled ? 'Cancel' : 'Close' }}
            </button>
        </div>
    </div>
</div>
