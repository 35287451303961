import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { environment } from '../../../../environments/environment';
import { BookingSearchFilterModel } from '../../../models/booking/booking-search-filter-model';
import { BookingFilterSearchRequestModel } from '../../../models/booking/booking-filter-search-request.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { InvoiceProcessModel } from '../../../models/booking/invoice-process.model';
import {InvoiceType} from '../../../enums/invoice-type.enum';

@Injectable()
export class SettingsBillingService {
    private getDataForBookingSearchUrl = environment.apiUrl + '/Booking/GetDataForBookingSearch';
    private getFilteredBookingUrl = environment.apiUrl + '/Booking/GetFilteredBookings';
    private getInvoicesForBookingsUrl = environment.apiUrl + '/Booking/GetInvoicesForBookings';
    private batchCreateInvoicesUrl = environment.apiUrl + '/Booking/CreateInvoicesFromBookings';
    private GetInvoiceReportsFormUrl = environment.apiUrl + '/Booking/GetInvoiceReportsForm';
    private ExportInvoicesToPdfUrl = environment.apiUrl + '/Booking/ExportInvoicesToPdf';
    private ExportFunctionInvoicesToPdfUrl = environment.apiUrl + '/Function/ExportInvoicesToPdf';
    private EmailInvoicesUrl = environment.apiUrl + '/Booking/EmailInvoices';
    private EmailFunctionInvoicesUrl = environment.apiUrl + '/Function/EmailInvoices';
    private ExportPartnerInvoicesToPdfUrl = environment.apiUrl + '/Booking/ExportPartnerInvoicesToPdf';
    private EmailPartnerInvoicesUrl = environment.apiUrl + '/Booking/EmailPartnerInvoices';
    private EmailInvoicesWithDefaultTemplatesUrl = environment.apiUrl + '/Booking/EmailInvoicesWithDefaultTemplates';

    constructor(private http: HttpRequestService, private httpOriginal: HttpClient) {}

    getDataForBookingSearch() {
        return this.http.get(this.getDataForBookingSearchUrl);
    }

    getFilteredBookings(model: BookingFilterSearchRequestModel) {
        return this.http.post(this.getFilteredBookingUrl, model);
    }

    getData(data) {
        return this.http.post(this.getFilteredBookingUrl, data);
    }

    getInvoicesForBookings(model: number[]) {
        return this.http.post(this.getInvoicesForBookingsUrl, model);
    }

    batchCreateInvoices(model: number[]) {
        return this.http.post(this.batchCreateInvoicesUrl, model, true);
    }

    getInvoiceReportsForm(invoices: string) {
        return this.http.get(this.GetInvoiceReportsFormUrl + '?invoices=' + invoices);
    }

    exportInvoicesToPdf(invoices: InvoiceProcessModel[], invoiceType: InvoiceType) {
        const token = AuthTokenService.getParsedToken();
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: token,
        });
        let url = invoiceType == InvoiceType.Booking ? this.ExportInvoicesToPdfUrl : this.ExportFunctionInvoicesToPdfUrl;

        return this.httpOriginal.post(`${url}?invoices=${invoices}`, invoices, {
            headers,
            responseType: 'blob',
        });
    }

    public emailInvoices(messageTemplateId: number, invoices: InvoiceProcessModel[], invoiceType: InvoiceType) {
        let url = invoiceType == InvoiceType.Booking ? this.EmailInvoicesUrl : this.EmailFunctionInvoicesUrl;
        return this.http.post(
            url + '?messageTemplateId=' + messageTemplateId,
            invoices,
            true,
            'Action has been added to queue',
            ' '
        );
    }

    exportPartnerInvoicesToPdf(invoices: InvoiceProcessModel[]) {
        const token = AuthTokenService.getParsedToken();
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: token,
        });
        return this.httpOriginal.post(this.ExportPartnerInvoicesToPdfUrl + '?invoices=' + invoices, invoices, {
            headers,
            responseType: 'blob',
        });
    }

    public emailPartnerInvoices(messageTemplateId: number, invoices: InvoiceProcessModel[], partnerStatement = false) {
        return this.http.post(
            `${this.EmailPartnerInvoicesUrl}?messageTemplateId=${messageTemplateId}&partnerStatement=${partnerStatement}`,
            invoices,
            true,
            'Action has been added to queue',
            ' '
        );
    }

    public emailInvoicesWithDefaultTemplates(invoices: InvoiceProcessModel[]) {
        return this.http.post(
            this.EmailInvoicesWithDefaultTemplatesUrl,
            invoices,
            true,
            'Action has been added to queue',
            ' '
        );
    }
}
