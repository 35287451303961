import { Component, OnDestroy, OnInit } from '@angular/core';
import { RateSchedulesService } from './rate-schedules.service';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { RateScheduleWithRatePlanModel } from '../../../../models/rate-schedule/rate-schedule-with-rate-plan.model';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { RateSchedulesPermissionsModel } from '../../../../models/permissions/permissions.model';
import { PermissionsEnum } from '../../../../enums/permissions.enum';

@Component({
    selector: 'app-rate-schedules',
    templateUrl: './rate-schedules.component.html',
    styleUrls: ['./rate-schedules.component.less'],
})
export class RateSchedulesComponent implements OnInit, OnDestroy {
    permissionsModel: RateSchedulesPermissionsModel;

    addSchedule = false;
    editSchedule = false;
    canChangeName = true;
    canChangeStatus = true;
    fromGridRatePlan = false;
    roomTypes = false;

    rateScheduleId: number;
    rateScheduleName: string;

    deletePopup = false;
    deleteId: number;
    theme: number;

    subscription: any;
    rateScheduleWithRatePlanModel: RateScheduleWithRatePlanModel[];
    rateScheduleWithRatePlan = new RateScheduleWithRatePlanModel();

    constructor(private rateSchedulesService: RateSchedulesService, private permissionsService: PermissionsService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.RateSchedules);
    }

    ngOnInit() {
        this.init();
    }

    init() {
        this.subscription = this.rateSchedulesService
            .getSchedulesWithRatePlans(AuthTokenService.decodeJwtToken().Property.Id)
            .subscribe(
                (response) => {
                    this.rateScheduleWithRatePlanModel = response;
                },
                (error) => console.log(error)
            );
    }

    deleteRateSchedule() {
        this.rateSchedulesService.deleteRateSchedule(this.rateScheduleWithRatePlan).subscribe(
            (response) => {
                this.init();
            },
            (error) => console.log(error)
        );
    }

    addNewSchedule() {
        this.addSchedule = true;
    }

    deleteModal(data) {
        this.deletePopup = true;
        this.rateScheduleWithRatePlan = data;
    }

    canDelete(data) {
        let canDelete = true;
        const listOfNames = ['public', 'ota', 'corporate'];
        const rsName = data.name.trim().toLowerCase();
        if (listOfNames.indexOf(rsName) > -1) {
            canDelete = false;
        }
        return canDelete;
    }

    ChangeStatus(data) {
        let canChangeStatus = true;
        const rsName = data.name.trim().toLowerCase();
        if (rsName === 'public') {
            canChangeStatus = false;
        }
        return canChangeStatus;
    }

    close() {
        this.deletePopup = false;
    }

    cellClick(e) {
        if (e.columnIndex === 1) {
            this.canChangeName = this.canDelete(e.data);
            this.canChangeStatus = this.ChangeStatus(e.data);
            this.openDetailsSchedules(e.data.id);
        }
    }

    openDetailsSchedules(id) {
        this.rateScheduleId = id;
        this.editSchedule = true;
    }

    openDetailsRatePlans(rateScheduleWithRatePlan) {
        this.rateScheduleWithRatePlan = rateScheduleWithRatePlan;
        this.fromGridRatePlan = true;
    }

    openDetailsRoomTypes(data) {
        this.rateScheduleId = data.id;
        this.rateScheduleName = data.name;
        this.roomTypes = true;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
