<div class="refresh-page-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup cost-fees" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>New version found. The page will be refreshed</h2>
            <div class="actions actions-button flex">
                <button type="button" (click)="close()" class="custom-button blue">Ok</button>
            </div>
        </div>
    </div>
</div>
