export class PricePlanRangeModel {
    public id: number;
    public propertyId: number;
    public ratePlanId: number;
    public roomTypeId: number;
    public minLoS: number;
    public priceDate: Date;
    public baseRate: number;
    public additionalRate: number;
    public childrenRate: number;
    public derivedFrom: number;
    public active: boolean;
    public priceModel: PriceModel[];
    public additionalInfo: string;
    public rulesAreDefined: string;
    public finalRate: number;
    public dynamicPricingRuleApplied: boolean;

    // added new properties
    public priceDateString: string;
    public show = false;
}

export class PriceModel {
    public numberOfGuests: number;
    public price: number;
}
