import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AuthTokenService } from '../../services/auth-token.service';

@Component({
    selector: 'app-delete-modal',
    templateUrl: './delete-modal.component.html',
    styleUrls: ['./delete-modal.component.less'],
})
export class DeleteModalComponent implements OnInit {
    @Input() deleteModal: boolean;
    @Output() deleteModalChange = new EventEmitter<boolean>();
    @Output() delete = new EventEmitter();
    @Input() message: string;
    theme: number;

    constructor() {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    }

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        console.log(event);
        const x = event.keyCode;
        if (x === 27) {
            this.close();
        }

        if (x === 13) {
            this.confirmDelete();
        }
    }

    ngOnInit() {}

    close() {
        this.deleteModalChange.emit(false);
    }

    confirmDelete() {
        this.delete.emit();
        this.deleteModalChange.emit(false);
    }
}
