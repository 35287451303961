import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpRequestService } from '../../../../shared/services/http-request.service';
import { CostCenterModel } from '../../../../models/cost-center/cost-center.model';

@Injectable()
export class CostCenterService {
    private getCostCentresUrl = environment.apiUrl + '/CostCentre/GetCostCentres';
    private getCostCentreDetailsUrl = environment.apiUrl + '/CostCentre/GetCostCentreDetails';
    private deleteCostCentreUrl = environment.apiUrl + '/CostCentre/DeleteCostCentre';
    private insertCostCentreUrl = environment.apiUrl + '/CostCentre/InsertCostCentre';
    private updateCostCentreUrl = environment.apiUrl + '/CostCentre/UpdateCostCentre';

    constructor(private http: HttpRequestService) {}

    getCostCentres(propertyId: number) {
        return this.http.get(this.getCostCentresUrl + '?propertyId=' + propertyId);
    }

    getCostCentreDetails(costCentreId: number) {
        return this.http.get(this.getCostCentreDetailsUrl + '?costCentreId=' + costCentreId);
    }

    deleteCostCentre(costCentreId: number) {
        return this.http.delete(this.deleteCostCentreUrl + '?costCentreId=' + costCentreId, true);
    }

    insertCostCentre(model: CostCenterModel) {
        return this.http.post(this.insertCostCentreUrl, model, true);
    }

    updateCostCentre(model: CostCenterModel) {
        return this.http.post(this.updateCostCentreUrl, model, true);
    }
}
