import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidationService } from 'src/app/shared/services/custom-validation.service';
import { CheckEmailUsernameService } from '../../../shared/services/check-email-username.service';
import { AuthLoginService } from '../../../shared/services/auth-login.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.less'],
})
export class ForgotPasswordComponent implements OnInit {
    emailExist = true;
    showInfo = false;
    showAccountError = false;
    disableConfirm = false;
    imageArray = [
        '../../../assets/images/wallpaper2.jpg',
        '../../../assets/images/wallpaper3.jpg',
        '../../../assets/images/wallpaper1.jpg',
        '../../../assets/images/wallpaper4.jpg',
        '../../../assets/images/wallpaper5.jpg',
        '../../../assets/images/wallpaper6.jpg',
    ];
    imageUrl: string;
    valForm: UntypedFormGroup;
    username: string;
    account: string;

    validPasswordResetLink = true;

    constructor(
        private fb: UntypedFormBuilder,
        private checkUsernameEmail: CheckEmailUsernameService,
        private authLoginService: AuthLoginService,
        private router: Router
    ) {
        this.valForm = this.fb.group({
            username: [null, Validators.compose([Validators.required])],
            accountID: [null, Validators.compose([Validators.required])],
        });
        const randomImage = Math.floor(Math.random() * this.imageArray.length);
        this.imageUrl = this.imageArray[randomImage];
    }

    ngOnInit() {
        if (this.router.url.indexOf('expired') > -1) {
            this.validPasswordResetLink = false;
        }
    }

    checkUsername() {
        if (this.valForm.controls['username'].valid) {
            this.checkUsernameEmail.checkUsernameExists(this.username).subscribe((response: any) => {
                this.emailExist = response.success;
            });
        }
    }

    checkAccount() {
        if (this.valForm.controls['accountID'].valid) {
            this.checkUsernameEmail.checkAccountExists(this.account).subscribe((response: any) => {
                this.showAccountError = response.success;
                this.disableConfirm = response.success;
            });
        }
    }

    clearValidationExistMail() {
        if (!this.valForm.controls['email'].valid && !this.emailExist) {
            this.emailExist = true;
        }
    }

    submitForgotPasswordForm(e) {
        e.stopPropagation();
        if (this.disableConfirm) {
            return;
        }
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            this.authLoginService.forgotPassword(this.account, this.username).subscribe(
                (response) => {
                    if (response) {
                        this.showInfo = true;
                    }
                },
                (error) => console.log(error)
            );
        }
    }
}
