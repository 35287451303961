import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../shared/services/http-request.service';
import { environment } from '../../../environments/environment';
import { RegistrationModel } from '../../models/user/registration.model';
import { UserPasswordModel } from '../../models/user/user-password.model';
import { RoomType } from '../../models/room-type/room-type-details.model';
import { RoomTypeRoomModel, RoomTypesModel } from '../../models/room-type/room-types.model';
import { RoomModel } from '../../models/room/rooms-mapping.model';
import { VirtualRoomModel } from '../../models/room/virtual-room.model';
import { ReOrderImagesModel } from '../../models/room-type/re-order-images.model';
import { RoomTypeInsertPictureModel } from '../../models/room-type/room-type-insert-picture.model';
import { KeyValuePair } from '../../models/shared/key-value-pair.model';

@Injectable()
export class SignupService {
    private sendAuthenticationMailUrl = environment.apiUrl + '/User/SendAuthenticationMail';
    private getAllCountriesWithRegisteredAccountAndTCUrl =
        environment.apiUrl + '/Country/getAllCountriesWithRegisteredAccountAndTC';
    private getActiveCountriesUrl = environment.apiUrl + '/Country/getActiveCountries';
    private registerUrl = environment.apiUrl + '/User/Register';
    private getNumberOfRoomsGlobalSettingsUrl = environment.apiUrl + '/User/GetGlobalSettingByKey?key=Registration.NoOfRooms';
    private getMaxNumberOfRoomsGlobalSettingsUrl = environment.apiUrl + '/User/GetGlobalSettingByKey?key=Registration.MaxNoOfRooms';
    private getEnvironmentUrl = environment.apiUrl + '/User/GetGlobalSettingByKey?key=Environment';
    private setRoomsForPropertyUrl = environment.apiUrl + '/User/SetRoomsForProperty';
    private getByPropertyUrl = environment.apiUrl + '/RoomType/GetByProperty';
    private getDetailsUrl = environment.apiUrl + '/RoomType/GetDetails';
    private createRoomTypeUrl = environment.apiUrl + '/RoomType/CreateRoomType';
    private updateRoomTypeUrl = environment.apiUrl + '/RoomType/UpdateRoomType';
    private deleteRoomTypeUrl = environment.apiUrl + '/RoomType/DeleteRoomType';
    private getRoomsMappingByPropertyUrl = environment.apiUrl + '/RoomType/GetRoomsMappingByProperty';
    private mapRoomsToRoomTypesUrl = environment.apiUrl + '/RoomType/MapRoomsToRoomTypes';
    private getDetailsRoomUrl = environment.apiUrl + '/Room/GetDetails';
    private updateRoomUrl = environment.apiUrl + '/Room/UpdateRoom';
    private getVirtualRoomsUrl = environment.apiUrl + '/Room/GetVirtualRooms';
    private createVirtualRoomUrl = environment.apiUrl + '/Room/CreateVirtualRoom';
    private updateVirtualRoomUrl = environment.apiUrl + '/Room/UpdateVirtualRoom';
    private getRoomTypePicturesUrl = environment.apiUrl + '/RoomType/GetRoomTypePictures';
    private reOrderImagesUrl = environment.apiUrl + '/RoomType/ReorderPictures';
    private insertNewPictureUrl = environment.apiUrl + '/RoomType/InsertNewPicture';
    private removePictureUrl = environment.apiUrl + '/RoomType/RemovePicture';
    private getRoomTypeRoomUrl = environment.apiUrl + '/RoomType/GetRoomTypeRoom';
    private updateRoomTypeRoomUrl = environment.apiUrl + '/RoomType/UpdateRoomTypeRoom';
    private reorderRoomTypesUrl = environment.apiUrl + '/RoomType/ReorderRoomTypes';
    private reorderRoomsUrl = environment.apiUrl + '/Room/ReorderRooms';

    constructor(private http: HttpRequestService) {}

    reorderRoomTypes(model: KeyValuePair[]) {
        return this.http.post(this.reorderRoomTypesUrl, model, true);
    }

    reorderRooms(model: KeyValuePair[]) {
        return this.http.post(this.reorderRoomsUrl, model, true);
    }

    sendAuthenticationMail(model: RegistrationModel) {
        return this.http.post(this.sendAuthenticationMailUrl, model, true);
    }

    getAllCountriesWithRegisteredAccountAndTC() {
        return this.http.get(this.getAllCountriesWithRegisteredAccountAndTCUrl);
    }

    getActiveCountries() {
        return this.http.get(this.getActiveCountriesUrl);
    }

    getRoomTypeRoom(id: number) {
        return this.http.get(this.getRoomTypeRoomUrl + '?id=' + id);
    }

    updateRoomTypeRoom(model: RoomTypeRoomModel) {
        return this.http.post(this.updateRoomTypeRoomUrl, model, true);
    }

    register(model: UserPasswordModel) {
        return this.http.post(this.registerUrl, model, true);
    }

    getDefaultNumberOfRooms() {
        return this.http.get(this.getNumberOfRoomsGlobalSettingsUrl);
    }

    getMaxNumberOfRooms() {
        return this.http.get(this.getMaxNumberOfRoomsGlobalSettingsUrl);
    }

    getEnvironment() {
        return this.http.get(this.getEnvironmentUrl);
    }

    setRoomsForProperty(userId: number, numberOfRooms: number) {
        return this.http.post(
            this.setRoomsForPropertyUrl + '?userId=' + userId + '&numberOfRooms=' + numberOfRooms,
            null,
            true
        );
    }

    getByProperty(propertyId: number) {
        return this.http.get(this.getByPropertyUrl + '?propertyId=' + propertyId);
    }

    getDetailsRoomType(id: number) {
        return this.http.get(this.getDetailsUrl + '?id=' + id);
    }

    createRoomType(model: RoomType, files: File[]) {
        return this.http.postMultipart(this.createRoomTypeUrl, model, files, true);
    }

    updateRoomType(model: RoomType, files: File[]) {
        return this.http.postMultipart(this.updateRoomTypeUrl, model, files, true);
    }

    deleteRoomType(id: number) {
        return this.http.delete(this.deleteRoomTypeUrl + '?id=' + id, true);
    }

    getRoomsMappingByProperty(propertyId: number) {
        return this.http.get(this.getRoomsMappingByPropertyUrl + '?propertyId=' + propertyId);
    }

    mapRoomsToRoomTypes(model: RoomTypesModel) {
        return this.http.post(this.mapRoomsToRoomTypesUrl, model, true);
    }

    getDetailsRoom(propertyId: number, id: number) {
        return this.http.get(`${this.getDetailsRoomUrl}?propertyId=${propertyId}&id=${id}`);
    }

    updateRoom(model: RoomModel) {
        return this.http.post(this.updateRoomUrl, model, true);
    }

    getVirtualRooms(id: number) {
        return this.http.get(this.getVirtualRoomsUrl + '?propertyId=' + id);
    }

    createVirtualRoom(model: VirtualRoomModel) {
        return this.http.post(this.createVirtualRoomUrl, model, true);
    }

    updateVirtualRoom(model: VirtualRoomModel) {
        return this.http.post(this.updateVirtualRoomUrl, model, true);
    }

    getRoomTypePictures(id: number) {
        return this.http.get(this.getRoomTypePicturesUrl + '?id=' + id);
    }

    reOrderImages(model: ReOrderImagesModel) {
        return this.http.post(this.reOrderImagesUrl, model);
    }

    insertNewPicture(model: RoomTypeInsertPictureModel, files: File[]) {
        return this.http.postMultipart(this.insertNewPictureUrl, model, files, true);
    }

    removePicture(pictureId: string) {
        return this.http.post(this.removePictureUrl + '?pictureId=' + pictureId, null, true);
    }
}
