<div class="caterpay-wrapper flex" *ngIf="!specificActionInProgress">
    <div class="overlay flex">
        <div class="custom-box popup flex" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <div *ngIf="!summary && !specificActionComplete">
                <div>
                    <h2 *ngIf="cardListModel.cards.length > 0">Select card:</h2>
                    <h2 *ngIf="cardListModel.cards.length === 0">No available cards</h2>
                    <h4 *ngIf="unableToFindCardWarning">The specific card to be actioned could not be found.</h4>
                </div>

                <div>
                    <div *ngFor="let card of cardListModel.cards">
                        <div class="cardSource">
                            <input type="radio" value="{{card.lastFourDigits}}" [(ngModel)]="selectedCardDigits" (change)="onCardChange(card)" />
                            <span *ngIf="card.lastFourDigits.length == 4">**** **** **** </span
                            >{{ card.lastFourDigits }}
                        </div>
                    </div>


                </div>

                <div class="buttons">
                    <button
                        *ngIf="!loader"
                        type="button"
                        class="custom-button blue left"
                        [disabled]="cardListModel.cards.length === 0"
                        (click)="proceedToSummary()"
                    >
                        Next
                    </button>
                    <div *ngIf="loader" class="loader left"></div>
                    <button (click)="close()" type="button" class="custom-button red left">Cancel</button>
                </div>
            </div>

            <div *ngIf="summary && !paymentSuccess && !paymentFailed && !specificActionComplete">
                <div>
                    <h2>Payment summary</h2>
                </div>

                <div>
                    <h3>Payment details</h3>
                    <p>Amount: {{ currencyContext.Symbol }}{{ functionPayment.amount | number: '1.2-2' }}</p>
                    <p>Payment purpose: {{ functionPayment.paymentPurpose }}</p>
                    <p>
                        Card: <span *ngIf="selectedCard.lastFourDigits.length == 4">**** **** **** </span
                        >{{ selectedCard.lastFourDigits }}
                    </p>
                </div>

                <div>
                    <h3>Customer details</h3>
                    <p>{{ cardListModel?.customer?.name }}</p>
                    <p>{{ cardListModel?.customer?.address }}</p>
                    <p>{{ cardListModel?.customer?.email }}</p>
                </div>

                <div class="buttons">
                    <button *ngIf="!loader" type="button" class="custom-button blue left" (click)="finishPayment()">
                        {{ functionPayment.paymentPurpose === 'Verify' ? 'Verify' : 'Finish payment' }}
                    </button>
                    <div *ngIf="loader" class="loader left"></div>
                    <button (click)="close()" type="button" class="custom-button red left">Cancel</button>
                </div>
            </div>

            <div *ngIf="paymentSuccess">
                <h2>Payment successful</h2>
                <h3>AuthCode: {{ finishPaymentResponse.authCode }}</h3>
                <p>Reference: {{ finishPaymentResponse.reference }}</p>

                <button (click)="closeAllModals()" type="button" class="custom-button red left">Close</button>
            </div>

            <div *ngIf="paymentFailed">
                <h2>Payment failed</h2>
                <h3>Message: {{ finishPaymentResponse.responseMessage }}</h3>
                <button (click)="close()" type="button" class="custom-button red left">Close</button>

            </div>
        </div>
    </div>
</div>
<div class="caterpay-wrapper flex" *ngIf="specificActionInProgress">
    <div class="overlay flex">
        <div class="custom-box popup flex" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <div class="loader"></div>
        </div>
    </div>
</div>