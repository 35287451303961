<div class="incidental-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup flex" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Booking 5436</h2>
            <div class="custom-content">
                <div class="left-content">
                    <div>
                        <div>
                            <h3>Billing details</h3>
                            <p>Accomodation <span> $1245</span></p>
                            <p>Food & Drink <span>$145</span></p>
                            <p>Spa <span>$15</span></p>
                        </div>
                        <div>
                            <h3>Payments Received</h3>
                            <p>Deposit <span>$45</span></p>
                            <button
                                type="button"
                                id="takeapayment"
                                class="custom-button orange left"
                                (click)="showEdit = true; showinvoice = false"
                            >
                                Take a payment
                            </button>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h3>Booking Balance</h3>
                            <p>Debit <span>$125</span></p>
                        </div>
                        <div>
                            <h3>Invoices</h3>
                            <button
                                type="button"
                                id="invoiceall"
                                class="custom-button orange left"
                                (click)="showinvoice = true; showEdit = false"
                            >
                                Invoice All
                            </button>
                        </div>
                    </div>
                </div>
                <div class="right-content">
                    <div *ngIf="showEdit" id="add-edit">
                        <hr />
                        <h3>Add/Edit payment</h3>
                        <div id="add-edit-holder">
                            <div>
                                <div class="form-item small">
                                    <div class="custom-label">Payment Type</div>
                                    <dx-select-box
                                        [ngModel]="selectedDevice"
                                        (ngModelChange)="checkValue($event)"
                                        class="custom-select"
                                        value="Cash"
                                        [dataSource]="payment"
                                    >
                                    </dx-select-box>
                                </div>
                                <div class="form-item">
                                    <div class="custom-label">Amount</div>
                                    <input class="custom-input" type="text" />
                                </div>
                                <div class="form-item">
                                    <div class="custom-label">Reference</div>
                                    <input class="custom-size-input custom-input" type="text" />
                                </div>
                            </div>
                            <div>
                                <div class="form-item">
                                    <div class="custom-label">Notes</div>
                                    <input class="custom-size-input custom-input" type="text" />
                                </div>
                                <div class="form-item small">
                                    <div class="custom-label">Assign to Invoice</div>
                                    <dx-select-box class="custom-select" [value]="invoice[0]" [dataSource]="invoice">
                                    </dx-select-box>
                                </div>
                                <button id="SaveChanges" class="custom-button blue left">Save</button>
                                <button id="OpenCaterpay" class="custom-button orange left" *ngIf="selectCaterpay">
                                    Open Caterpay
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showinvoice" id="invoice-all">
                        <hr />
                        <div class="invoice-all-holder">
                            <div>
                                <div class="form-item">
                                    <div class="custom-label">To</div>
                                    <input class="custom-input" type="text" />
                                </div>
                                <div class="form-item">
                                    <div class="custom-label">Email</div>
                                    <input class="custom-input" type="text" />
                                </div>
                                <div class="form-item">
                                    <div class="custom-label">EU VAT Reg</div>
                                    <input class="custom-input" type="text" />
                                </div>
                            </div>
                            <div>
                                <div class="form-item">
                                    <div class="custom-label">Address</div>
                                    <input class="custom-input" type="text" />
                                </div>
                                <div class="form-item">
                                    <input class="custom-input" type="text" />
                                </div>
                                <div class="form-item">
                                    <input class="custom-input" type="text" />
                                </div>
                                <div class="form-item">
                                    <input class="custom-input" type="text" />
                                </div>
                                <div class="form-item">
                                    <input class="custom-input" type="text" />
                                </div>
                            </div>
                        </div>
                        <dx-data-grid
                            class="rate-plans-grid"
                            [dataSource]="InvoiceItems"
                            [allowColumnResizing]="true"
                            [columns]="[
                                { dataField: 'Items', caption: 'Items', allowSorting: true },
                                { dataField: 'Quantity', caption: 'Quantity', allowSorting: true, width: '80' },
                                { dataField: 'Amount', caption: 'Amount', allowSorting: true, width: '80' },
                                { dataField: 'Vat', caption: 'VAT', allowSorting: true, width: '50' },
                                { dataField: 'Total', caption: 'Total', allowSorting: true, width: '50' }
                            ]"
                        >
                            <dxo-scrolling mode="standard"></dxo-scrolling>
                            <dxo-paging [pageSize]="10"></dxo-paging>
                            <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true">
                            </dxo-pager>
                        </dx-data-grid>
                        <div class="create-invoice">
                            <h3>Payments</h3>
                            <div>
                                <p>Deposit <span>$50</span></p>
                                <div class="form-item check">
                                    <input type="checkbox" name="includeDeposit" id="deposit" class="css-checkbox" />
                                    <label for="deposit" class="css-label radGroup1"></label>
                                    <div class="custom-label inline">Include on this Invoice</div>
                                </div>
                                <button class="custom-button orange left">Create Invoice</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="button-wrapper">
                <button type="submit" class="custom-button blue left">Save</button>

                <button type="button" (click)="close()" class="custom-button red left">Cancel</button>
            </div>
        </div>
    </div>
</div>
