<div class="rate-schedules-wrapper rate-schedules-ie flex">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <h2>Rate Schedules</h2>

        <dx-data-grid
            [dataSource]="rateScheduleWithRatePlanModel"
            class="rate-schedules-grid"
            [allowColumnResizing]="true"
            (onCellClick)="cellClick($event)"
            [columns]="[
                { dataField: 'id', caption: 'ID', cellTemplate: 'id', allowSorting: true, width: 40 },
                { dataField: 'name', caption: 'Name', cellTemplate: 'name', allowSorting: true },
                { dataField: 'active', cellTemplate: 'status', caption: 'Status', allowSorting: true, width: 100 },
                { cellTemplate: 'ratePlans', width: 70 },
                { cellTemplate: 'roomTypes', width: 70 },
                { cellTemplate: 'delete', width: 70 }
            ]"
        >
            <div *dxTemplate="let s of 'status'">
                <ng-container *ngIf="s.data.active">Active</ng-container>
                <ng-container *ngIf="!s.data.active">Inactive</ng-container>
            </div>
            <div class="name" *dxTemplate="let n of 'name'">
                <i [ngClass]="[theme == 2 ? 'dark-theme' : '']" class="fa-solid fa-link fa-icon fa-icon-xsmall blue link-icon"></i>
                {{ n.data.name }}
            </div>
            <div *dxTemplate="let a of 'ratePlans'">
                <div title="Rate plans" class="fa fa-list-alt" (click)="openDetailsRatePlans(a.data)"></div>
            </div>
            <div class="center-td" *dxTemplate="let i of 'id'">
                {{ i.data.id }}
            </div>
            <div *dxTemplate="let a of 'ratePlans'">
                <div title="Rate plans" class="fas fa-rectangle-list fa-icon gray fa-icon-small" (click)="openDetailsRatePlans(a.data)"></div>
            </div>
            <div *dxTemplate="let a of 'roomTypes'">
                <div title="Room types" class="fa fa-bed fa-icon gray fa-icon-small" (click)="openDetailsRoomTypes(a.data)"></div>
            </div>
            <div *dxTemplate="let a of 'delete'">
                <i *ngIf="canDelete(a.data) && permissionsModel.delete" title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small" (click)="deleteModal(a.data)"></i>
            </div>
            <dxo-scrolling mode="standard"></dxo-scrolling>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="true" [showInfo]="true"> </dxo-pager>
        </dx-data-grid>

        <div (click)="addNewSchedule()" class="add-new-box" *ngIf="permissionsModel.create">
            <span class="text">Add new</span>
            <div class="img">
                <i class="fa-solid fa-plus fa-icon gray"></i>
            </div>
        </div>
    </div>
</div>

<app-delete-modal
    *ngIf="deletePopup"
    [(deleteModal)]="deletePopup"
    [message]="'Are you sure you want to delete this rate schedule?'"
    (delete)="deleteRateSchedule()"
></app-delete-modal>

<app-rate-schedules-details
    *ngIf="addSchedule"
    [(addSchedule)]="addSchedule"
    (update)="init()"
></app-rate-schedules-details>
<app-rate-schedules-details
    *ngIf="editSchedule"
    [(editSchedule)]="editSchedule"
    [rateScheduleId]="rateScheduleId"
    [canChangeName]="canChangeName"
    [canChangeStatus]="canChangeStatus"
    (update)="init()"
></app-rate-schedules-details>
<app-rate-schedules-rate-plans
    *ngIf="fromGridRatePlan"
    [(fromGridRatePlan)]="fromGridRatePlan"
    [rateScheduleWithRatePlan]="rateScheduleWithRatePlan"
    (update)="init()"
></app-rate-schedules-rate-plans>
<app-rate-schedules-room-types
    *ngIf="roomTypes"
    [(roomTypes)]="roomTypes"
    [rateScheduleId]="rateScheduleId"
    [rateScheduleName]="rateScheduleName"
    (update)="init()"
></app-rate-schedules-room-types>
