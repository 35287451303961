<div class="incidental-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup flex" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2 *ngIf="editItem">Edit Email</h2>
            <h2 *ngIf="addItem">Add Email</h2>

            <div class="form-box flex" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
                <div class="form-item form-item-search">
                    <div class="custom-label">Name</div>
                    <input [(value)]="email.Name" class="custom-input dark" name="promocode" type="text" width="100" />
                </div>
                <div class="form-item form-item-search">
                    <div class="custom-label">Status</div>
                    <dx-select-box [(value)]="email.Status" [dataSource]="['Active', 'Inactive']" height="40">
                    </dx-select-box>
                </div>
                <div class="form-item form-item-search">
                    <div class="custom-label">Frequency</div>
                    <dx-select-box [(value)]="trimedFr" [dataSource]="frequency" height="40"> </dx-select-box>
                </div>
                <div class="form-item form-item-search">
                    <div class="custom-label">Days</div>
                    <input
                        [(ngModel)]="trimedDay"
                        [(value)]="trimedDay"
                        class="custom-input number-box"
                        name="promocode"
                        type="number"
                        width="100"
                    />
                </div>
                <div class="form-item form-item-search">
                    <div class="custom-label">Def. template</div>
                    <dx-select-box [value]="templates[0]" [dataSource]="templates" height="40"> </dx-select-box>
                </div>
            </div>

            <dx-data-grid
                id="dx-vat"
                [dataSource]="wemails"
                class="room-type-grid"
                [editing]="{ allowUpdating: 'true', mode: 'cell' }"
                [columns]="[
                    {
                        dataField: 'Rateschedule',
                        caption: 'Rate schedule',
                        allowSorting: true,
                        lookup: { dataSource: schedules }
                    },
                    { dataField: 'Template', caption: 'Template', lookup: { dataSource: templates } },
                    { cellTemplate: 'delete', width: '60' }
                ]"
            >
                <div *dxTemplate="let a of 'delete'">
                    <i title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small"></i>
                </div>
                <dxo-scrolling mode="standard"></dxo-scrolling>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true"> </dxo-pager>
            </dx-data-grid>

            <div class="button-wrapper">
                <button type="submit" class="custom-button blue left">Save</button>

                <button type="button" (click)="close()" class="custom-button red left">Cancel</button>
            </div>
        </div>
    </div>
</div>
