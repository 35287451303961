export class WidgetColorSchemeModel {
    font?: string;
    buttonColorsAndWidgetBackground: string;
    labelAndButtonText: string;
    pageInputAndPopUpBackground: string;
    headingTextColor: string;
    roomInfoTextColor: string;
    amenitiesAndSummaryTextColor: string;
    rowBackgroundColorEven: string;
    rowBackgroundColorOdd: string;
    buttonRolloverTextColor: string;
}
