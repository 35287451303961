import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpRequestService } from '../../../../shared/services/http-request.service';
import { RateScheduleWithRatePlanModel } from '../../../../models/rate-schedule/rate-schedule-with-rate-plan.model';
import { RateScheduleRoomTypeModel } from '../../../../models/rate-schedule/rate-schedule-room-type.model';
import { RoomTypeRateScheduleModel } from '../../../../models/rate-schedule/room-type-rate-schedule.model';

@Injectable()
export class RateSchedulesService {
    private getSchedulesWithRatePlansUrl = environment.apiUrl + '/RateSchedule/GetSchedulesWithRatePlans';
    private insertRateScheduleWithRatePlanUrl = environment.apiUrl + '/RateSchedule/InsertRateScheduleWithRatePlan';
    private updateRateScheduleWithRatePlanUrl = environment.apiUrl + '/RateSchedule/UpdateRateScheduleWithRatePlan';
    private updateGoogleRatePlanUrl = environment.apiUrl + '/RatePlan/UpdateGoogleRatePlan'; 
    private getRateScheduleDetailsUrl = environment.apiUrl + '/RateSchedule/GetRateScheduleDetails';
    private getRateSchedulePageDataUrl = environment.apiUrl + '/RateSchedule/GetRateSchedulePageData';
    private getAllRatePlansForRateSchedulesUrl = environment.apiUrl + '/RateSchedule/GetAllRatePlansForRateSchedules';
    private deleteRateScheduleUrl = environment.apiUrl + '/RateSchedule/DeleteRateSchedule';
    private assignRoomTypesUrl = environment.apiUrl + '/RateSchedule/AssignRoomTypes';
    private getAssignRoomTypesPopupUrl = environment.apiUrl + '/RateSchedule/GetAssignRoomTypesPopup';
    private assignRateSchedulesUrl = environment.apiUrl + '/RoomType/AssignRateSchedules';
    private getAssignRateSchedulesPopupUrl = environment.apiUrl + '/RoomType/GetAssignRoomTypesPopup';

    constructor(private http: HttpRequestService) {}

    getSchedulesWithRatePlans(propertyId: number) {
        return this.http.get(this.getSchedulesWithRatePlansUrl + '?propertyId=' + propertyId);
    }

    getAllRatePlansForRateSchedules(propertyId: number) {
        return this.http.get(this.getAllRatePlansForRateSchedulesUrl + '?propertyId=' + propertyId);
    }

    insertRateScheduleWithRatePlan(model: RateScheduleWithRatePlanModel) {
        return this.http.post(this.insertRateScheduleWithRatePlanUrl, model, true);
    }

    updateRateScheduleWithRatePlan(model: RateScheduleWithRatePlanModel) {
        return this.http.post(this.updateRateScheduleWithRatePlanUrl, model, true);
    }

    updateGoogleRatePlan(model: RateScheduleWithRatePlanModel) {
        return this.http.post(this.updateGoogleRatePlanUrl, model, true);
    }

    deleteRateSchedule(model: RateScheduleWithRatePlanModel) {
        return this.http.post(this.deleteRateScheduleUrl, model, true);
    }

    getRateScheduleDetails(rateScheduleId: number) {
        return this.http.get(this.getRateScheduleDetailsUrl + '?rateScheduleId=' + rateScheduleId);
    }

    getRateSchedulePageData() {
        return this.http.get(this.getRateSchedulePageDataUrl);
    }

    getAssignRoomTypesPopup(rateScheduleId: number) {
        return this.http.get(this.getAssignRoomTypesPopupUrl + '?rateScheduleId=' + rateScheduleId);
    }

    assignRoomTypes(model: RateScheduleRoomTypeModel) {
        return this.http.post(this.assignRoomTypesUrl, model, true);
    }

    getAssignRateSchedulesPopup(roomTypeId: number) {
        return this.http.get(this.getAssignRateSchedulesPopupUrl + '?roomTypeId=' + roomTypeId);
    }

    assignRateSchedules(model: RoomTypeRateScheduleModel) {
        return this.http.post(this.assignRateSchedulesUrl, model, true);
    }
}
