import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FilteredOtaSyncInModel, XMLPreviewModel } from 'src/app/models/ota-connections/filtered-ota-sync-in.model';
import { OtaLogsService } from 'src/app/routes/settings/settings-ota-logs/ota-logs.service';
import { AuthTokenService } from '../services/auth-token.service';

@Component({
    selector: 'app-xml-view',
    templateUrl: './xml-view.component.html',
    styleUrls: ['./xml-view.component.less'],
})
export class XmlViewComponent implements OnInit {
    @Input() xmlViewSyncIn: boolean;
    @Output() xmlViewSyncInChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() xmlViewSyncOut: boolean;
    @Output() xmlViewSyncOutChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() xmlViewBookingRecord: boolean;
    @Output() xmlViewBookingRecordChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() bookingId: number;
    @Input() storageLog: FilteredOtaSyncInModel;
    @Input() otaConnectionName: string;

    theme: number;
    xmlResponse: string;
    xmlPreviewModel: XMLPreviewModel = new XMLPreviewModel();

    constructor(private otaLogsService: OtaLogsService, private router: Router) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    }

    ngOnInit() {
        if (this.xmlViewSyncIn || this.xmlViewSyncOut) {
            this.otaLogsService.getSyncXML(this.storageLog.storageLogId).subscribe(
                (response) => {
                    this.xmlResponse = response;
                },
                (error) => {}
            );
        } else {
            this.otaLogsService.getSyncXMLByBooking(this.bookingId).subscribe(
                (response) => {
                    this.xmlPreviewModel = response;
                    this.otaConnectionName = this.xmlPreviewModel.otaConnectionName;
                    this.xmlResponse = this.xmlPreviewModel.xmlString;
                    this.storageLog = this.xmlPreviewModel.storageLog;
                },
                (error) => {
                    this.close();
                }
            );
        }
    }

    redirectToBooking() {
        if (this.xmlViewSyncIn || this.xmlViewSyncOut) {
            this.router.navigate(['/booking', 'details', this.storageLog.bookingId]);
        }
    }

    close() {
        this.xmlViewSyncInChange.emit(false);
        this.xmlViewSyncOutChange.emit(false);
        this.xmlViewBookingRecordChange.emit(false);
    }
}
