import { Injectable } from '@angular/core';

@Injectable()
export class TrimObjService {
    static trimStrings(key, value) {
        if (typeof value === 'string') {
            return value.trim();
        }
        return value;
    }

    trimObj(obj) {
        const objString = JSON.stringify(obj, TrimObjService.trimStrings, 4);

        return JSON.parse(objString);
    }
}
