import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PartnerAvailabilityPopupModel } from '../../../models/booking/partner-availability-popup.model';
import { UpdatePartnerAvailabilityModel } from '../../../models/booking/update-partner-availability.model';
import { InventoryService } from '../inventory.service';
import { AuthTokenService } from '../../../shared/services/auth-token.service';

@Component({
    selector: 'app-inventory-partner',
    templateUrl: './inventory-partner.component.html',
    styleUrls: ['./inventory-partner.component.less'],
})
export class InventoryPartnerComponent implements OnInit {
    @Input() inventoryPartner: boolean;
    @Output() inventoryPartnerChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();

    loader = false;
    theme: number;

    dateFrom: Date = new Date();
    dateTo: Date = new Date();

    valForm: UntypedFormGroup;
    partnerAvailabilityPopupModel = new PartnerAvailabilityPopupModel();
    updatePartnerAvailabilityModel = new UpdatePartnerAvailabilityModel();

    constructor(private fb: UntypedFormBuilder, private inventoryService: InventoryService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;

        this.valForm = fb.group({
            dateFrom: [new Date(), Validators.required],
            dateTo: [new Date(), Validators.required],
            rateSchedule: [null, Validators.required],
            roomType: [null, Validators.required],
            rooms: [null, Validators.compose([Validators.required, Validators.min(0)])],
        });
    }

    ngOnInit() {
        this.inventoryService.getPartnerAvailabilityPopup(AuthTokenService.decodeJwtToken().Property.Id).subscribe(
            (response) => {
                this.partnerAvailabilityPopupModel = response;
            },
            (error) => console.log(error)
        );
    }

    submitPartnerAvailability(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            this.loader = true;
            this.updatePartnerAvailabilityModel.propertyId = AuthTokenService.decodeJwtToken().Property.Id;
            this.updatePartnerAvailabilityModel.dateFrom = this.dateFrom.toDateString();
            this.updatePartnerAvailabilityModel.dateTo = this.dateTo.toDateString();
            this.inventoryService.updatePartnerAvailability(this.updatePartnerAvailabilityModel).subscribe(
                (response) => {
                    this.loader = false;
                    this.update.emit();
                    this.close();
                },
                (error) => {
                    this.loader = true;
                    console.log(error);
                }
            );
        }
    }

    close() {
        this.inventoryPartnerChange.emit(false);
    }
}
