<header [ngClass]="[router.url.indexOf('login') > -1 ? 'notification' : '']">
    <div routerLink="/" class="logo left"></div>

    <i *ngIf="showLogout" class="logout fas fa-sign-out-alt right" title="Log out" (click)="logout()"></i>

    <select
        class="custom-select property-select"
        [disabled]="propertyList.length <= 1"
        [ngModel]="propertyId"
        (ngModelChange)="setDashboardDataForProperty($event)"
        name="property"
    >
        <option value="" disabled>Filter by Property</option>
        <option *ngFor="let prop of propertyList" [ngValue]="prop.id">{{ prop.name }}</option>
    </select>
    <!-- <dx-select-box *ngIf="showPropertyCombo && propertyList && propertyList.length > 0"
                 class="property-select"
                 [value]="propertyId"
                [disabled]="propertyList.length <= 1"
                 placeholder="Filter by Property"
                 [dataSource]="propertyList"
                 displayExpr="name"
                 valueExpr="id"
                 (onItemClick)="setDashboardDataForProperty($event)">
  </dx-select-box> -->
    <span *ngIf="showLogout" class="logged-in-user">Logged in as {{ loggedInUser }}</span>
    <button type="button" class="custom-button" *ngIf="showActivationButton" (click)="activateAccount()">
        <span class="label">Activate account</span>
        <i class="fas fa-arrow-circle-down"></i>
    </button>
</header>
