import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RateSchedulesService } from '../rate-schedules.service';
import { RateScheduleDetailsPDModel } from '../../../../../models/rate-schedule/rate-schedule-details-pd.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RateScheduleWithRatePlanModel } from '../../../../../models/rate-schedule/rate-schedule-with-rate-plan.model';
import { AuthTokenService } from '../../../../../shared/services/auth-token.service';
import { RatePlanModel } from '../../../../../models/rate-plan/rate-plan.model';
import { StatusModel } from '../../../../../models/shared/status.model';
import { PermissionsService } from '../../../../../shared/services/permissions.service';
import { InventoryRoomTypesPermissionsModel } from '../../../../../models/permissions/permissions.model';
import { PermissionsEnum } from '../../../../../enums/permissions.enum';

@Component({
    selector: 'app-rate-schedules-details',
    templateUrl: './rate-schedules-details.component.html',
    styleUrls: ['./rate-schedules-details.component.less'],
})
export class RateSchedulesDetailsComponent implements OnInit {
    @Input() addSchedule: boolean;
    @Output() addScheduleChange = new EventEmitter<boolean>();
    @Input() editSchedule: boolean;
    @Output() editScheduleChange = new EventEmitter<boolean>();

    @Input() rateScheduleId: number;
    @Output() update = new EventEmitter();

    @Input() canChangeName: boolean;
    @Input() canChangeStatus: boolean;

    permissionsModel: InventoryRoomTypesPermissionsModel;

    loader = false;

    addRatePlan = false;
    editRatePlan = false;
    roomTypes = false;

    valForm: UntypedFormGroup;
    rateScheduleDetailsPDModel = new RateScheduleDetailsPDModel();
    rateScheduleWithRatePlanModel = new RateScheduleWithRatePlanModel();
    statusModelList: StatusModel[] = [];

    theme: number;

    constructor(
        private rateScheduleService: RateSchedulesService,
        private fb: UntypedFormBuilder,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.RateSchedules);

        this.valForm = fb.group({
            name: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            status: [null, Validators.required],
        });

        this.statusModelList.push(new StatusModel(true, 'Active'));
        this.statusModelList.push(new StatusModel(false, 'Inactive'));
    }

    ngOnInit() {
        if (this.addSchedule) {
            this.rateScheduleWithRatePlanModel.propertyId = AuthTokenService.decodeJwtToken().Property.Id;
            this.rateScheduleService.getRateSchedulePageData().subscribe(
                (response) => {
                    this.rateScheduleDetailsPDModel = response;
                },
                (error) => console.log(error)
            );
        } else if (this.editSchedule) {
            this.rateScheduleService.getRateScheduleDetails(this.rateScheduleId).subscribe(
                (response) => {
                    this.rateScheduleDetailsPDModel = response;
                    this.rateScheduleWithRatePlanModel.id = this.rateScheduleDetailsPDModel.rateSchedule.id;
                    this.rateScheduleWithRatePlanModel.propertyId =
                        this.rateScheduleDetailsPDModel.rateSchedule.propertyId;
                    this.rateScheduleWithRatePlanModel.name = this.rateScheduleDetailsPDModel.rateSchedule.name;
                    this.rateScheduleWithRatePlanModel.active = this.rateScheduleDetailsPDModel.rateSchedule.active;
                    this.rateScheduleWithRatePlanModel.dateCreated =
                        this.rateScheduleDetailsPDModel.rateSchedule.dateCreated;
                    this.rateScheduleWithRatePlanModel.ratePlans =
                        this.rateScheduleDetailsPDModel.rateSchedule.ratePlans;
                },
                (error) => console.log(error)
            );
        }
        if (this.editSchedule && !this.canChangeName) {
            this.valForm.controls['name'].disable();
            this.valForm.controls['name'].setValue(this.rateScheduleWithRatePlanModel.name);
        }
        if (this.editSchedule && !this.canChangeStatus) {
            this.valForm.controls['status'].disable();
            this.valForm.controls['status'].setValue(this.rateScheduleWithRatePlanModel.active);
        }

        if (!this.permissionsModel.formButtonEnabled) {
            this.valForm.controls['status'].disable();
            this.valForm.controls['name'].disable();
        }
    }

    setListOfRatePlans(listOfRatePlans: RatePlanModel[]) {
        this.rateScheduleWithRatePlanModel.ratePlans = listOfRatePlans;
    }

    submitRateSchedules(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (!this.valForm.invalid) {
            this.loader = true;
            if (this.addSchedule) {
                this.insertSchedule();
            } else if (this.editSchedule) {
                this.updateSchedule();
            }
        }
    }

    insertSchedule() {
        this.rateScheduleService.insertRateScheduleWithRatePlan(this.rateScheduleWithRatePlanModel).subscribe(
            (response) => {
                this.loader = true;
                this.update.emit();
                this.close();
            },
            (error) => {
                this.loader = false;
                console.log(error);
            }
        );
    }

    updateSchedule() {
        this.rateScheduleService.updateRateScheduleWithRatePlan(this.rateScheduleWithRatePlanModel).subscribe(
            (response) => {
                this.loader = true;
                this.update.emit();
                this.close();
            },
            (error) => {
                this.loader = true;
                console.log(error);
            }
        );
    }

    openRatePlans() {
        if (this.addSchedule) {
            this.editRatePlan = false;
            this.addRatePlan = true;
        } else if (this.editSchedule) {
            this.addRatePlan = false;
            this.editRatePlan = true;
        }
    }

    openRoomTypes() {
        this.roomTypes = true;
    }

    close() {
        this.addScheduleChange.emit(false);
        this.editScheduleChange.emit(false);
    }
}
