import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpRequestService } from '../../../../shared/services/http-request.service';
import { PricePlanModel } from '../../../../models/price-plan/price-plan.model';
import { PricePlanRangeRegularModel } from '../../../../models/price-plan/price-plan-range-regular.model';
import { PricePlanRangeWeekdayModel } from '../../../../models/price-plan/price-plan-range-weekday.model';
import { DeleteExactPricePlanModel } from '../../../../models/price-plan/delete-exact-price-plan.model';
import { RatePlanRoomTypeModel } from '../../../../models/rate-plan/rate-plan-room-type.model';
import { AvailabilityRequestPricePlanModel } from '../../../../models/price-plan/availability-request-price-plan.model';
import { UpdateCloseOutModel } from '../../../../models/price-plan/price-plan-page.model';

@Injectable()
export class PricingPlanService {
    private getSchedulesWithRatePlansUrl = environment.apiUrl + '/RateSchedule/GetActiveSchedulesWithRatePlans';
    private getPricePlanByRangeUrl = environment.apiUrl + '/PricePlan/GetPricePlanByRange';
    private getExactPricePlanUrl = environment.apiUrl + '/PricePlan/GetExactPricePlan';
    private updateExactPricePlanUrl = environment.apiUrl + '/PricePlan/UpdateExactPricePlan';
    private updatePriceRangeRegularUrl = environment.apiUrl + '/PricePlan/UpdatePriceRangeRegular';
    private insertPriceRangeRegularUrl = environment.apiUrl + '/PricePlan/InsertPriceRangeRegular';
    private updatePriceRangeWeekdayUrl = environment.apiUrl + '/PricePlan/UpdatePriceRangeWeekday';
    private insertPriceRangeWeekDayUrl = environment.apiUrl + '/PricePlan/InsertPriceRangeWeekDay';
    private deleteRegularPricePlanUrl = environment.apiUrl + '/PricePlan/DeleteRegularPricePlan';
    private deleteWeekdayPricePlanUrl = environment.apiUrl + '/PricePlan/DeleteWeekdayPricePlan';
    private insertDynamicPricingUrl = environment.apiUrl + '/PricePlan/InsertDynamicPricing';
    private getDetailsDynamicPricingUrl = environment.apiUrl + '/PricePlan/GetDetailsDynamicPricing';
    private getAvailabilityPricePlanUrl = environment.apiUrl + '/PricePlan/GetAvailabilityPricePlan';
    private getRoomTypesPricePlanDetailsUrl = environment.apiUrl + '/PricePlan/GetRoomTypesPricePlanDetails';
    private getBaseAndAdditionalPriceFromPricePlanUrl =
        environment.apiUrl + '/PricePlan/GetBaseAndAdditionalPriceFromPricePlan';
    private getCloseOutPopupUpdateUrl = environment.apiUrl + '/PricePlan/GetCloseOutPopupUpdate';
    private updateCloseOutUrl = environment.apiUrl + '/PricePlan/UpdateCloseOut';

    constructor(private http: HttpRequestService) {}

    getPricePlanByRange(rateScheduleId: number, ratePlanId: number, dateFrom: string, dateTo: string) {
        return this.http.get(
            this.getPricePlanByRangeUrl +
                '?rateScheduleId=' +
                rateScheduleId +
                '&ratePlanId=' +
                ratePlanId +
                '&dateFrom=' +
                dateFrom +
                '&dateTo=' +
                dateTo
        );
    }

    getRoomTypesPricePlanDetails(rateScheduleId: number, ratePlanId: number, dateFrom: string, dateTo: string) {
        return this.http.get(
            this.getRoomTypesPricePlanDetailsUrl +
                '?rateScheduleId=' +
                rateScheduleId +
                '&ratePlanId=' +
                ratePlanId +
                '&dateFrom=' +
                dateFrom +
                '&dateTo=' +
                dateTo
        );
    }

    getBaseAndAdditionalPriceFromPricePlan(ratePlanId: number, roomTypeId: number, minLoS: number, priceDate: string) {
        return this.http.get(
            this.getBaseAndAdditionalPriceFromPricePlanUrl +
                '?ratePlanId=' +
                ratePlanId +
                '&roomTypeId=' +
                roomTypeId +
                '&minLoS=' +
                minLoS +
                '&priceDate=' +
                priceDate
        );
    }

    getSchedulesWithRatePlans() {
        return this.http.get(this.getSchedulesWithRatePlansUrl);
    }

    getExactPricePlan(pricePlanId: number) {
        return this.http.get(this.getExactPricePlanUrl + '?pricePlanId=' + pricePlanId);
    }

    updateExactPricePlan(model: PricePlanModel) {
        return this.http.post(this.updateExactPricePlanUrl, model, true);
    }

    updatePriceRangeRegular(model: PricePlanRangeRegularModel) {
        return this.http.post(this.updatePriceRangeRegularUrl, model, true);
    }

    insertPriceRangeRegular(model: PricePlanRangeRegularModel) {
        return this.http.post(this.insertPriceRangeRegularUrl, model, true);
    }

    updatePriceRangeWeekday(model: PricePlanRangeWeekdayModel) {
        return this.http.post(this.updatePriceRangeWeekdayUrl, model, true);
    }

    insertPriceRangeWeekDay(model: PricePlanRangeWeekdayModel) {
        return this.http.post(this.insertPriceRangeWeekDayUrl, model, true);
    }

    deleteRegularPricePlan(model: DeleteExactPricePlanModel) {
        return this.http.post(this.deleteRegularPricePlanUrl, model, true);
    }

    deleteWeekdayPricePlan(model: DeleteExactPricePlanModel) {
        return this.http.post(this.deleteWeekdayPricePlanUrl, model, true);
    }

    insertDynamicPricing(model: RatePlanRoomTypeModel) {
        return this.http.post(this.insertDynamicPricingUrl, model, true);
    }

    getAvailabilityPricePlan(model: AvailabilityRequestPricePlanModel) {
        return this.http.post(this.getAvailabilityPricePlanUrl, model);
    }

    getDetailsDynamicPricing(ratePlanId: number, roomTypeId: number) {
        return this.http.get(
            this.getDetailsDynamicPricingUrl + '?ratePlanId=' + ratePlanId + '&roomTypeId=' + roomTypeId
        );
    }

    getCloseOutPopupUpdate(rateScheduleId: number) {
        return this.http.get(this.getCloseOutPopupUpdateUrl + '?rateScheduleId=' + rateScheduleId);
    }

    updateCloseOut(model: UpdateCloseOutModel) {
        return this.http.post(this.updateCloseOutUrl, model, true);
    }
}
