<div class="account-wrapper flex">
    <div class="custom-box tabs">
        <div class="form-box flex missing-parameter" *ngIf="errorMissingParameter">
            <p>Error missing parameter!</p>
        </div>
        <div *ngIf="!errorMissingParameter" class="form-box flex">
            <div class="header-wrap">
                <div class="info-container">
                    <div class="property-info">
                        <div class="logo-container" *ngIf="logo">
                            <img class="property-picture" alt="Embedded Image" [src]="logo" />
                        </div>
                        <div class="property-info-text">
                            <div>
                                <span class="blue-letters">{{ bookingGuestRegistrationPD.propertyInfo.name }}</span>
                            </div>
                            <div *ngIf="bookingGuestRegistrationPD.propertyInfo.address">
                                <span class="blue-letters">{{ bookingGuestRegistrationPD.propertyInfo.address }}</span>
                            </div>
                            <div *ngIf="bookingGuestRegistrationPD.propertyInfo.address2">
                                <span class="blue-letters">{{ bookingGuestRegistrationPD.propertyInfo.address2 }}</span>
                            </div>
                            <div *ngIf="bookingGuestRegistrationPD.propertyInfo.address3">
                                <span class="blue-letters">{{ bookingGuestRegistrationPD.propertyInfo.address3 }}</span>
                            </div>
                            <div *ngIf="bookingGuestRegistrationPD.propertyInfo.postalCode">
                                <span class="blue-letters">{{
                                    bookingGuestRegistrationPD.propertyInfo.postalCode
                                }}</span>
                            </div>
                            <div *ngIf="bookingGuestRegistrationPD.propertyInfo.phone">
                                <span class="blue-letters">{{ bookingGuestRegistrationPD.propertyInfo.phone }}</span>
                            </div>
                            <div *ngIf="bookingGuestRegistrationPD.propertyInfo.email">
                                <span class="blue-letters">{{ bookingGuestRegistrationPD.propertyInfo.email }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h3>
                        <strong>Booking information</strong>
                    </h3>
                    <div>
                        Reference number:
                        <span class="blue-letters">{{ bookingGuestRegistrationPD.bookingInfo.bookingNo }}</span>
                    </div>
                    <div class="booking-status" *ngIf="bookingGuestRegistrationPD.bookingInfo.status === 'Enquired'">
                        (This booking is enquiry only and is subject to confirmation by the property)
                    </div>
                    <div>Guests: {{ bookingGuestRegistrationPD.bookingInfo.people }}</div>
                </div>
                <div class="info-container">
                    <h3>Rooms:</h3>
                    <div class="booking-info">
                        <ng-container *ngFor="let bookingRoom of allBookings; let i = index">
                            <div class="booking-single">
                                <div>
                                    <span *ngIf="bookingRoom.count">{{ bookingRoom.count }}x </span
                                    ><span class="blue-letters">{{ bookingRoom.roomType }}</span>
                                </div>
                                <div>
                                    Rate plan: <span class="blue-letters">{{ bookingRoom.ratePlan }}</span>
                                </div>
                                <div>
                                    No of guests: <span class="blue-letters">{{ bookingRoom.guests }}</span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <h3 *ngIf="bookingGuestRegistrationPD.bookingInfo.bookingExtras.length">Extras:</h3>
                    <div class="extras-info" *ngIf="bookingGuestRegistrationPD.bookingInfo.bookingExtras.length">
                        <ng-container *ngFor="let extra of allExtras; let i = index">
                            <div class="extras-single">
                                <div>
                                    <span *ngIf="extra.count">{{ extra.count }}x </span
                                    ><span class="blue-letters">{{ extra.saleItemName }}</span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="container-fluid" *ngIf="!cameFromCms">
                <h2 style="margin-bottom: 5px">Booker / Lead guest details</h2>
                <hr class="container-fluid" />
                <p class="fields-footnote">
                    Fields needing to be completed during check in are marked with an asterisk
                </p>
                <h4><b>Terms & Conditions</b></h4>
                <div class="form-item">
                    <input
                        type="checkbox"
                        name="terms"
                        id="lead-terms"
                        class="css-checkbox"
                        [disabled]="cameFromCms || !!bookingGuestRegistrationPD.bookingInfo.guestAccepted"
                        (change)="saveTermsConditions($event)"
                        [ngModel]="bookingGuestRegistrationPD.bookingInfo.guestAccepted"
                    />
                    <label for="lead-terms" class="css-label radGroup1 label-newsletter"
                        >By clicking below I confirm I have read and accepted the
                        <span class="terms-link" (click)="openTermsModal($event)">terms & conditions</span>
                        <span> for this booking.</span>
                    </label>
                </div>
                <div class="mb-20">
                    <h4>{{ roomWithLeadGuest.roomTypeName }} - {{ roomWithLeadGuest.roomName }}</h4>
                    <div><strong>Check-in: </strong>{{ roomWithLeadGuest.bookingFrom | date: 'dd MMM yyyy' }}</div>
                    <div><strong>Check-out: </strong>{{ roomWithLeadGuest.bookingTo | date: 'dd MMM yyyy' }}</div>
                </div>
                <div>
                    <div class="guest-form-holder">
                        <div class="box-left">
                            <div class="half-box form-item">
                                <div class="custom-label">
                                    First name{{ bookingGuestRegistrationPD.registrationForm.firstName ? '*' : '' }}
                                </div>
                                <input class="custom-input" type="text" [(ngModel)]="leadGuest.guest.firstName" />
                            </div>
                            <div class="half-box margin-l10 form-item">
                                <div class="custom-label">
                                    Last name{{ bookingGuestRegistrationPD.registrationForm.lastName ? '*' : '' }}
                                </div>
                                <input class="custom-input" type="text" [(ngModel)]="leadGuest.guest.lastName" />
                            </div>
                            <div class="half-box margin-l10 form-item">
                                <div class="custom-label">
                                    Mobile Phone{{ bookingGuestRegistrationPD.registrationForm.phone ? '*' : '' }}
                                </div>
                                <div class="telephone-wrapper">
                                    <input
                                        class="custom-input"
                                        type="text"
                                        id="lead-phone"
                                        [disabled]="leadGuest.guest.isUnderSixteen"
                                        [(ngModel)]="leadGuest.guest.phone2"
                                    />
                                </div>
                            </div>
                            <div class="half-box form-item">
                                <div class="custom-label">
                                    E-mail{{ bookingGuestRegistrationPD.registrationForm.email ? '*' : '' }}
                                </div>
                                <input
                                    class="custom-input"
                                    type="email"
                                    id="lead-email"
                                    [disabled]="leadGuest.guest.isUnderSixteen"
                                    [(ngModel)]="leadGuest.guest.email"
                                />
                            </div>
                            <div class="form-item" *ngIf="bookingRooms.length === 1">
                                <input
                                    type="checkbox"
                                    name="newsletter"
                                    id="lead-newsletter"
                                    class="css-checkbox"
                                    [disabled]="leadGuest.guest.isUnderSixteen"
                                    (change)="newsletterChange($event, leadGuest)"
                                    [(ngModel)]="leadGuest.guest.newsletter"
                                />
                                <label for="lead-newsletter" class="css-label radGroup1 label-newsletter"
                                    >I consent to receiving occasional marketing emails and understand I can opt out at
                                    any time</label
                                >
                            </div>
                        </div>
                        <div class="box-right">
                            <div class="half-box form-item">
                                <div class="custom-label">
                                    Address 1{{ bookingGuestRegistrationPD.registrationForm.address1 ? '*' : '' }}
                                </div>
                                <input
                                    class="custom-input"
                                    type="text"
                                    id="lead-address1"
                                    [disabled]="leadGuest.guest.isUnderSixteen"
                                    [(ngModel)]="leadGuest.guest.address1"
                                />
                            </div>
                            <div class="half-box form-item">
                                <div class="custom-label">
                                    Address 2{{ bookingGuestRegistrationPD.registrationForm.address2 ? '*' : '' }}
                                </div>
                                <input
                                    class="custom-input"
                                    type="text"
                                    id="lead-address2"
                                    [disabled]="leadGuest.guest.isUnderSixteen"
                                    [(ngModel)]="leadGuest.guest.address2"
                                />
                            </div>
                            <div class="half-box form-item">
                                <div class="custom-label">
                                    Address 3{{ bookingGuestRegistrationPD.registrationForm.address3 ? '*' : '' }}
                                </div>
                                <input
                                    class="custom-input"
                                    type="text"
                                    id="lead-address3"
                                    [disabled]="leadGuest.guest.isUnderSixteen"
                                    [(ngModel)]="leadGuest.guest.address3"
                                />
                            </div>
                            <div class="half-box form-item">
                                <div class="custom-label">
                                    Postal code{{ bookingGuestRegistrationPD.registrationForm.postalCode ? '*' : '' }}
                                </div>
                                <input
                                    class="custom-input"
                                    type="text"
                                    id="lead-postCode"
                                    [disabled]="leadGuest.guest.isUnderSixteen"
                                    [(ngModel)]="leadGuest.guest.postalCode"
                                />
                            </div>
                            <div class="half-box form-item">
                                <div class="custom-label">
                                    Country of residence{{
                                        bookingGuestRegistrationPD.registrationForm.countryId ? '*' : ''
                                    }}
                                </div>
                                <dx-select-box
                                    class="custom-select"
                                    [dataSource]="bookingGuestRegistrationPD.countries"
                                    [disabled]="leadGuest.guest.isUnderSixteen"
                                    id="lead-country"
                                    valueExpr="id"
                                    displayExpr="name"
                                    [(ngModel)]="leadGuest.guest.countryId"
                                >
                                </dx-select-box>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid" *ngIf="cameFromCms || bookingGuestRegistrationPD.bookingInfo.people > 1">
                <h2>
                    {{
                        bookingGuestRegistrationPD.bookingInfo.people > 1
                            ? 'Additional guest information'
                            : 'Guest information'
                    }}
                </h2>
                <hr class="container-fluid" />
                <p class="fields-footnote">
                    Fields needing to be completed during check in are marked with an asterisk
                </p>

                <div class="guest-info-wrap" *ngFor="let room of bookingRooms; let i = index">
                    <ng-container
                        *ngIf="
                            !(room.bookingRoomId === roomWithLeadGuest?.bookingRoomId && room.bookingGuests.length === 1)
                        "
                    >
                        <h4>{{ room.roomTypeName }} - {{ room.roomName }}</h4>
                        <div><strong>Check-in: </strong>{{ room.bookingFrom | date: 'dd MMM yyyy' }}</div>
                        <div><strong>Check-out: </strong>{{ room.bookingTo | date: 'dd MMM yyyy' }}</div>
                    </ng-container>
                    <div *ngFor="let bookingGuest of room.bookingGuests; let j = index">
                        <ng-container *ngIf="cameFromCms ? true : !bookingGuest.leadGuest">
                            <h4 class="mb-20">Guest #{{ j + 1 }}</h4>
                            <div>
                                <div class="guest-form-holder">
                                    <div class="box-left">
                                        <div class="half-box form-item">
                                            <div class="custom-label">
                                                First name{{
                                                    bookingGuestRegistrationPD.registrationForm.firstName ? '*' : ''
                                                }}
                                            </div>
                                            <input
                                                class="custom-input"
                                                type="text"
                                                [(ngModel)]="bookingGuest.guest.firstName"
                                            />
                                        </div>
                                        <div class="half-box margin-l10 form-item">
                                            <div class="custom-label">
                                                Last name{{
                                                    bookingGuestRegistrationPD.registrationForm.lastName ? '*' : ''
                                                }}
                                            </div>
                                            <input
                                                class="custom-input"
                                                type="text"
                                                [(ngModel)]="bookingGuest.guest.lastName"
                                            />
                                        </div>
                                        <ng-container *ngIf="cameFromCms">
                                            <div class="half-box margin-l10 form-item">
                                                <div class="custom-label">Mobile Phone</div>
                                                <div class="telephone-wrapper">
                                                    <input
                                                        class="custom-input"
                                                        type="text"
                                                        id="phone{{ i }}{{ j }}"
                                                        [disabled]="bookingGuest.guest.isUnderSixteen"
                                                        [(ngModel)]="bookingGuest.guest.phone2"
                                                    />
                                                </div>
                                            </div>
                                            <div class="half-box form-item">
                                                <div class="custom-label">E-mail</div>
                                                <input
                                                    class="custom-input"
                                                    type="email"
                                                    id="email{{ i }}{{ j }}"
                                                    [disabled]="bookingGuest.guest.isUnderSixteen"
                                                    [(ngModel)]="bookingGuest.guest.email"
                                                />
                                            </div>
                                        </ng-container>
                                        <div class="half-box form-item underSixteenYears guest" *ngIf="!cameFromCms">
                                            <input
                                                type="checkbox"
                                                name="under16{{ i }}{{ j }}"
                                                id="under16{{ i }}{{ j }}"
                                                class="css-checkbox"
                                                [(ngModel)]="bookingGuest.guest.under16"
                                                (change)="checkGuestAge($event, i, j, bookingGuest)"
                                            />
                                            <label for="under16{{ i }}{{ j }}" class="css-label radGroup1"></label>
                                            <div class="custom-label inline">Is under 16 years?</div>
                                        </div>
                                    </div>
                                    <div class="box-right">
                                        <ng-container *ngIf="!cameFromCms">
                                            <div class="half-box margin-l10 form-item">
                                                <div class="custom-label">
                                                    Mobile Phone{{
                                                        bookingGuestRegistrationPD.registrationForm.phone ? '*' : ''
                                                    }}
                                                </div>
                                                <div class="telephone-wrapper">
                                                    <input
                                                        class="custom-input"
                                                        type="text"
                                                        id="phone{{ i }}{{ j }}"
                                                        [disabled]="bookingGuest.guest.isUnderSixteen"
                                                        [(ngModel)]="bookingGuest.guest.phone2"
                                                    />
                                                </div>
                                            </div>
                                            <div class="half-box form-item">
                                                <div class="custom-label">
                                                    E-mail{{
                                                        bookingGuestRegistrationPD.registrationForm.email ? '*' : ''
                                                    }}
                                                </div>
                                                <input
                                                    class="custom-input"
                                                    type="email"
                                                    id="email{{ i }}{{ j }}"
                                                    [disabled]="bookingGuest.guest.isUnderSixteen"
                                                    [(ngModel)]="bookingGuest.guest.email"
                                                />
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="cameFromCms">
                                            <div class="half-box form-item">
                                                <div class="custom-label">
                                                    Address 1{{
                                                        bookingGuestRegistrationPD.registrationForm.address1 ? '*' : ''
                                                    }}
                                                </div>
                                                <input
                                                    class="custom-input"
                                                    type="text"
                                                    id="address1{{ i }}{{ j }}"
                                                    [disabled]="bookingGuest.guest.isUnderSixteen"
                                                    [(ngModel)]="bookingGuest.guest.address1"
                                                />
                                            </div>
                                            <div class="half-box form-item">
                                                <div class="custom-label">
                                                    Address 2{{
                                                        bookingGuestRegistrationPD.registrationForm.address2 ? '*' : ''
                                                    }}
                                                </div>
                                                <input
                                                    class="custom-input"
                                                    type="text"
                                                    id="address2{{ i }}{{ j }}"
                                                    [disabled]="bookingGuest.guest.isUnderSixteen"
                                                    [(ngModel)]="bookingGuest.guest.address2"
                                                />
                                            </div>
                                            <div class="half-box form-item">
                                                <div class="custom-label">
                                                    Address 3{{
                                                        bookingGuestRegistrationPD.registrationForm.address3 ? '*' : ''
                                                    }}
                                                </div>
                                                <input
                                                    class="custom-input"
                                                    type="text"
                                                    id="address3{{ i }}{{ j }}"
                                                    [disabled]="bookingGuest.guest.isUnderSixteen"
                                                    [(ngModel)]="bookingGuest.guest.address3"
                                                />
                                            </div>
                                            <div class="half-box form-item">
                                                <div class="custom-label">
                                                    Postal code{{
                                                        bookingGuestRegistrationPD.registrationForm.postalCode
                                                            ? '*'
                                                            : ''
                                                    }}
                                                </div>
                                                <input
                                                    class="custom-input"
                                                    type="text"
                                                    id="postCode{{ i }}{{ j }}"
                                                    [disabled]="bookingGuest.guest.isUnderSixteen"
                                                    [(ngModel)]="bookingGuest.guest.postalCode"
                                                />
                                            </div>
                                            <div class="half-box form-item">
                                                <div class="custom-label">
                                                    Country of residence{{
                                                        bookingGuestRegistrationPD.registrationForm.countryId ? '*' : ''
                                                    }}
                                                </div>
                                                <dx-select-box
                                                    class="custom-select"
                                                    [dataSource]="bookingGuestRegistrationPD.countries"
                                                    [disabled]="bookingGuest.guest.isUnderSixteen"
                                                    id="country{{ j }}"
                                                    valueExpr="id"
                                                    displayExpr="name"
                                                    [(ngModel)]="bookingGuest.guest.countryId"
                                                >
                                                </dx-select-box>
                                            </div>
                                            <div class="half-box form-item underSixteenYears">
                                                <input
                                                    type="checkbox"
                                                    name="under16{{ j }}"
                                                    id="under16{{ i }}{{ j }}"
                                                    class="css-checkbox"
                                                    [(ngModel)]="bookingGuest.guest.under16"
                                                    (change)="checkGuestAge($event, i, j, bookingGuest)"
                                                />
                                                <label for="under16{{ i }}{{ j }}" class="css-label radGroup1"></label>
                                                <div class="custom-label inline">Is under 16 years?</div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <hr class="container-fluid" />
            <div class="booking-source-wrap">
                <div class="form-item right" *ngIf="bookingSources?.length">
                    <div class="custom-label">
                        How did you find out about {{ bookingGuestRegistrationPD.propertyInfo.name }}?
                    </div>
                    <dx-select-box
                        class="custom-select"
                        [dataSource]="bookingSources"
                        valueExpr="id"
                        displayExpr="name"
                        [(ngModel)]="bookingGuestRegistrationMenageModel.bookingSourceId"
                        [showClearButton]="true"
                    >
                    </dx-select-box>
                </div>
            </div>

            <div class="action">
                <button type="submit" class="custom-button blue right" (click)="updateGuestsForBooking()">Save</button>
                <button type="button" *ngIf="cameFromCms" (click)="goBack()" class="custom-button red left">
                    Back
                </button>
                <button
                    type="button"
                    *ngIf="cameFromCms"
                    (click)="printForm()"
                    class="custom-button orange right print"
                >
                    Print
                </button>
                <button
                    *ngIf="cameFromCms && leadGuest?.guest.phone2 && bookingGuestRegistrationPD?.canSendSMS"
                    type="button"
                    (click)="sendSms()"
                    class="custom-button green right print"
                >
                    Send SMS
                </button>
            </div>
        </div>
    </div>
</div>

<div class="loader" *ngIf="loader"></div>

<div class="overlay flex" *ngIf="showAreYouSureModal">
    <div class="custom-box small-box popup">
        <h2>
            Thank you for updating your booking details, your booking reference is #{{
                bookingGuestRegistrationPD.bookingInfo.bookingNo
            }}. <br />
            You can now close this window.
        </h2>
    </div>
</div>

<div class="terms-modal" *ngIf="showTermsModal">
    <div class="shadow-overlay"></div>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Terms & Conditions</h4>
            <button type="button" class="close-btn" aria-label="Close" (click)="toggleTermsModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div [innerHTML]="termsConditionsPropertySettings.value"></div>
        </div>
    </div>
</div>

<app-message-templates-recepient
    *ngIf="openMessageTemplates"
    [(messageTemplatePopup)]="openMessageTemplates"
    [bookingId]="leadGuest.bookingId"
    [bookingRoomId]="leadGuest?.bookingRoomId"
    [mobilePhone]="leadGuest.guest.phone2"
>
</app-message-templates-recepient>
