<div class="rate-schedules-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box rate popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2 *ngIf="addRoomItem">Link Room Items</h2>
            <h2 *ngIf="editRoomItem">Link Room Items</h2>
            <h2 *ngIf="addExtraItem">Link Extras</h2>
            <h2 *ngIf="editExtraItem">Link Extras</h2>

            <dx-data-grid
                class="link-invoice-grid"
                [dataSource]="invoiceItemList"
                [(selectedRowKeys)]="selectedRows"
                [columns]="[
                    {
                        dataField: 'description',
                        width: '350',
                        caption: 'Description',
                        allowSorting: true
                    },
                    { dataField: 'quantity', caption: 'Quantity', allowSorting: true },
                    {
                        dataField: 'priceIncVat',
                        caption: 'Price',
                        cellTemplate: 'price',
                        allowSorting: true
                    },
                    { dataField: 'vatAmount', caption: 'Vat Rate', allowSorting: true },
                    {
                        dataField: 'total',
                        caption: 'Total',
                        cellTemplate: 'total',
                        allowSorting: true
                    }
                ]"
            >
                <div *dxTemplate="let item of 'price'">
                    {{ currencyContext.Symbol }}{{ item.data.priceIncVat | number: '1.2-2' }}
                </div>
                <div *dxTemplate="let item of 'total'">
                    {{ currencyContext.Symbol }}{{ item.data.total | number: '1.2-2' }}
                </div>
                <dxo-paging [pageSize]="25"></dxo-paging>
                <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
                <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true"> </dxo-pager>
            </dx-data-grid>

            <button
                *ngIf="!loader"
                type="button"
                (click)="submitInvoiceItems()"
                [disabled]="selectedRows.length == 0"
                class="custom-button blue left"
            >
                Save
            </button>
            <div *ngIf="loader" class="loader left"></div>
            <button (click)="close()" type="button" class="custom-button red left">Cancel</button>
        </div>
    </div>
</div>
