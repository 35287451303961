import { Component, OnInit } from '@angular/core';
import { UserLoginModel } from '../../models/user/user-login.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthTokenService } from '../../shared/services/auth-token.service';
import { Router } from '@angular/router';
import { UserStatus } from '../../enums/user.enum';
import { AccountStatus } from '../../enums/account.enum';
import { DataTransferService } from '../../shared/services/data-transfer.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
    hideLogin = false;
    trialAccount = false;
    passCode = false;
    rememberMe = false;
    loader = false;
    imageArray = [
        '../../assets/images/wallpaper2.jpg',
        '../../assets/images/wallpaper3.jpg',
        '../../assets/images/wallpaper1.jpg',
        '../../assets/images/wallpaper4.jpg',
        '../../assets/images/wallpaper5.jpg',
        '../../assets/images/wallpaper6.jpg',
    ];

    imageUrl: string;
    activeAccount = true;

    valForm: UntypedFormGroup;

    notification: Object;

    userLoginModel = new UserLoginModel();

    constructor(private fb: UntypedFormBuilder, private authLoginService: AuthTokenService, private router: Router) {
        this.valForm = this.fb.group({
            username: [null, Validators.required],
            password: [null, Validators.required],
            accountID: [null, Validators.required],
            remember_me: [null],
        });

        // if (DataTransferService.GetData('PassCodeSuccess') !== null) {
        //   const decodedString = btoa(DataTransferService.GetData('PassCodeSuccess'));
        //   decodedString === 'true' ? this.passCode = false : this.passCode = true;
        // }
        const randomImage = Math.floor(Math.random() * this.imageArray.length);
        this.imageUrl = this.imageArray[randomImage];
        if (DataTransferService.GetData('PassCodeSuccess') !== null) {
            const decodedString = btoa(DataTransferService.GetData('PassCodeSuccess'));
            decodedString === 'true' ? (this.passCode = false) : (this.passCode = true);
        }

        this.authLoginService.getNotificationsOnLogin().subscribe((res) => (this.notification = res));
    }

    ngOnInit() {
        if (localStorage.getItem('propertyId')) {
            this.userLoginModel.accountID = Number(localStorage.getItem('propertyId'));
        }
        if (localStorage.getItem('userName')) {
            this.userLoginModel.username = localStorage.getItem('userName');
        }
    }

    validPass() {
        DataTransferService.PushData('PassCodeSuccess', atob('true'));
    }

    submitLoginForm(e) {
        e.stopPropagation();

        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            this.loader = true;

            if (this.rememberMe) {
                localStorage.setItem('propertyId', this.userLoginModel.accountID.toString());
                localStorage.setItem('userName', this.userLoginModel.username);
            }

            this.authLoginService.login(this.userLoginModel, this.rememberMe).subscribe(
                (response) => {
                    let status: UserStatus;
                    this.loader = false;

                    this.activeAccount = response;

                    if (!this.activeAccount) {
                        this.hideLogin = true;
                        return;
                    }

                    if (AuthTokenService.decodeJwtToken().IsSuperUser) {
                        status = AuthTokenService.decodeJwtToken().AdminUser.Status;
                    } else {
                        status = AuthTokenService.decodeJwtToken().User.Status;
                    }

                    const chatScript = document.createElement('script');
                    chatScript.src =
                        'https://static.zdassets.com/ekr/snippet.js?key=a1d8ade5-fd19-4dd2-9615-64723c484df8';
                    chatScript.id = 'ze-snippet';
                    document.body.appendChild(chatScript);

                    if (status === UserStatus.Incomplete) {
                        this.router.navigate(['room-number']);
                    } else {
                        const accountStatus = AuthTokenService.decodeJwtToken().Account.Status;
                        if (accountStatus === AccountStatus.Live || accountStatus === AccountStatus.Development) {
                            if (AuthTokenService.decodeJwtToken().User.Role.Name === 'WebDesigner') {
                                this.router.navigate(['settings/properties/booking-templates']);
                            } else if (AuthTokenService.decodeJwtToken().User.Role.Name === 'Housekeeping') {
                                this.router.navigate(['reports/housekeeping/groups']);
                            } else {
                                this.router.navigate(['dashboard']);
                            }
                        } else {
                            this.trialAccount = true;
                            this.hideLogin = true;
                        }
                    }
                },
                (error) => {
                    console.log(error);
                    this.loader = false;
                }
            );
        }
    }

    activateAccount() {
        DataTransferService.PushData('ShowTrialMessage', false);
        this.router.navigate(['account-details']);
    }
}
