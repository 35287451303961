import { CurrencyModel } from '../account/currency.model';
import { CountryModel } from '../account/country.model';
import { AccountAdminInsertModel } from '../account/account-admin-insert.model';
import { AccountBillInfoModel } from '../account/account-bill-info.model';

export class ActivationFlowModel {
    public currencies: CurrencyModel[] = [];
    public countries: CountryModel[] = [];
    public account: AccountAdminInsertModel;
    public billingInfo: AccountBillInfoModel;
    public goCardlessData: GoCardlessModel;
    public cardStreamData: CardStreamModel;

    constructor() {
        this.account = new AccountAdminInsertModel();
        this.billingInfo = new AccountBillInfoModel();
        this.goCardlessData = new GoCardlessModel();
        this.cardStreamData = new CardStreamModel();
    }
}

export class GoCardlessModel {
    public accountHolderName: string;
    public accountNumber: string;
    public branchCode: string;
}

export class CardStreamModel {
    public cardNumber: string;
    public cardExpiryMonth: string;
    public cardExpiryYear: string;
    public cardCVV: string;
}
