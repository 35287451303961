<div class="rate-plans-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup sale-items" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Rate Schedules - {{ ratePlanName }}</h2>

            <dx-data-grid
                [dataSource]="checkedRateScheduleModel"
                [(selectedRowKeys)]="selectedRows"
                [columns]="[{ dataField: 'name', caption: 'Name', allowSorting: true }]"
            >
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-selection
                    *ngIf="permissionsModel.formButtonEnabled"
                    showCheckBoxesMode="always"
                    mode="multiple"
                ></dxo-selection>
                <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="true" [showInfo]="true"> </dxo-pager>
            </dx-data-grid>

            <button
                *ngIf="permissionsModel.formButtonEnabled"
                type="button"
                (click)="saveRateSchedules()"
                class="custom-button blue"
            >
                Save
            </button>
            <button type="button" (click)="close()" class="custom-button red">
                {{ permissionsModel.formButtonEnabled ? 'Cancel' : 'Close' }}
            </button>
        </div>
    </div>
</div>
