import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { FunctionRoomsService } from './function-rooms.service';
import { PropertyFunctionRoomsModel } from '../../../models/function-room/function-room.model';

@Injectable()
export class FunctionRoomResolver  {
    constructor(private functionRoomsService: FunctionRoomsService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PropertyFunctionRoomsModel> {
        return this.functionRoomsService.getFunctionRoomsForProperty(AuthTokenService.decodeJwtToken().Property.Id);
    }
}
