import { FinanceAccountModel } from './booking-payment-pd.model';
import { BookingPaymentModel } from './booking-details.model';
import { BookingPaymentDetailModel } from './booking-all-details.model';
import { FunctionPaymentModel } from '../function-room/function-flow.model';

export class NewInvoiceDetailsPDModel {
    public invoiceToList: InvoiceHeaderDetailsModel[] = [];
    public invoiceItems: InvoiceItemModel[] = [];
    public bookingPaymentItems: BookingPaymentModel[] = [];
    public invoice: InvoiceDetailsModel;
    public functionPaymentItems: FunctionPaymentModel[] = [];

    constructor() {
        this.invoice = new InvoiceDetailsModel();
    }
}

export class InvoiceDetailsPDModel {
    public invoiceToList: InvoiceHeaderDetailsModel[] = [];
    public invoice: InvoiceDetailsModel;

    constructor() {
        this.invoice = new InvoiceDetailsModel();
    }
}

export class InvoiceHeaderDetailsModel {
    public invoiceTo: FinanceAccountModel;
    public header: InvoiceHeaderModel;

    constructor() {
        this.invoiceTo = new FinanceAccountModel();
        this.header = new InvoiceHeaderModel();
    }
}

export class InvoiceHeaderModel {
    public id: number;
    public invoiceNo: string;
    public bookingId?: number;
    public functionId?: number;
    public guestId?: number;
    public partnerId?: number;
    public dateCreated: Date;
    public status: string;
    public templateId: number;
    public customerName: string;
    public customerAddress: string;
    public customerAddress2: string;
    public customerAddress3: string;
    public customerPostalCode: string;
    public customerCountry: string;
    public customerAdditionalData1: string;
    public customerAdditionalData2: string;
    public poRef: string;
}

export class InvoiceDetailsModel extends InvoiceHeaderModel {
    public rooms: InvoiceItemGroupModel;
    public extras: InvoiceItemGroupModel;
    public payments: BookingPaymentModel[] = [];
    public functionPayments: FunctionPaymentModel[] = [];
    public invoiceTotal: number;
    public paymentTotal: number;
    public balanceTotal: number;

    constructor() {
        super();
        this.rooms = new InvoiceItemGroupModel();
        this.extras = new InvoiceItemGroupModel();
    }
}

export class InvoiceItemGroupModel {
    public items: InvoiceItemModel[] = [];
    public total: number;

    constructor() {
        this.total = 0;
    }
}

export class InvoiceItemModel {
    public id: number;
    public invoiceId: number;
    public bookingRoomId?: number;
    public bookingExtraId?: number;
    public functionRoomId?: number;
    public functionExtraId?: number;
    public title: string;
    public description: string;
    public printDescription: string;
    public quantity: number;
    public price: number;
    public vatId: number;
    public vatAmount: number;
    public total: number;
    public date: Date;
    public priceIncVat: number;
}
