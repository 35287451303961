<!--<app-header></app-header>-->
<!--<div class="login-wrapper flex" [ngStyle]="{'height': passCode ? 'unset' : '850px'}">-->
<div class="login-wrapper flex" [ngStyle]="{ 'background-image': 'url(' + imageUrl + ')' }">
    <div
        class="notification-wrap"
        [ngStyle]="{ 'margin-top': passCode ? '100px' : 'unset' }"
        *ngIf="notification && !passCode"
    >
        <p [innerHTML]="notification"></p>
    </div>
    <div class="custom-box" *ngIf="!passCode && !hideLogin" [ngClass]="[notification ? 'notification-active' : '']">
        <div class="logo-img">
            <img src="../../../assets/images/logo.svg" alt="caterbook logo" />
        </div>
        <h2>Login</h2>
        <form [formGroup]="valForm" (ngSubmit)="submitLoginForm($event)">
            <div class="form-item">
                <div class="custom-label">Account ID</div>
                <input
                    class="custom-input"
                    type="text"
                    [(ngModel)]="userLoginModel.accountID"
                    name="accountID"
                    formControlName="accountID"
                />
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['accountID'].hasError('required') &&
                        (valForm.controls['accountID'].dirty || valForm.controls['accountID'].touched)
                    "
                >
                    Account ID is required
                </span>
            </div>
            <div class="form-item">
                <div class="custom-label">Username</div>
                <input
                    class="custom-input"
                    type="text"
                    [(ngModel)]="userLoginModel.username"
                    name="username"
                    formControlName="username"
                />
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['username'].hasError('required') &&
                        (valForm.controls['username'].dirty || valForm.controls['username'].touched)
                    "
                >
                    Username is required
                </span>
            </div>
            <div class="form-item">
                <div class="custom-label">Password</div>
                <input
                    type="password"
                    class="custom-input"
                    formControlName="password"
                    name="password"
                    [(ngModel)]="userLoginModel.password"
                />
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['password'].hasError('required') &&
                        (valForm.controls['password'].dirty || valForm.controls['password'].touched)
                    "
                >
                    Password is required
                </span>
            </div>
            <div class="form-item">
                <input
                    type="checkbox"
                    name="remember_me"
                    formControlName="remember_me"
                    [(ngModel)]="rememberMe"
                    id="remember-me"
                    class="css-checkbox"
                />
                <label for="remember-me" class="css-label radGroup1"></label>
                <div class="custom-label inline">Remember me</div>
            </div>

            <div class="actions flex">
                <!-- <div class="info">
          Don't have account?<br/>
          <div routerLink="/signup" class="custom-link">Sign up here</div>
        </div> -->

                <div class="custom-label inline">
                    <div class="custom-link" routerLink="/forgot-password">Forgot password</div>
                </div>
            </div>

            <div class="actions actions-button flex">
                <button *ngIf="!loader" type="submit" class="custom-button blue">Login</button>
                <div *ngIf="loader" class="loader"></div>
            </div>
        </form>
    </div>
    <div *ngIf="trialAccount" class="overlay flex">
        <div class="custom-box popup h100">
            <h2>Activation</h2>
            <button type="button" routerLink="/dashboard" class="custom-button blue left">Continue trial</button>
            <button type="button" (click)="activateAccount()" class="custom-button activate right">
                Activate account
            </button>
        </div>
    </div>
    <div *ngIf="!activeAccount" class="overlay">
        <div class="custom-box popup h120">
        <h2>Inactive Account</h2>
        <p>Your 14 day trial has come to an end and the account is now inactive.</p>
        <p>Please email us at cb5&#64;caterbook.com or call on 01840 298298 to speak with one of our staff.</p>
        </div>
    </div>
    <app-pass-code *ngIf="passCode" [(passCode)]="passCode" (onClose)="validPass()"></app-pass-code>
</div>
