export class RegistrationFormModel {
    propertyId: number;
    title: boolean;
    email: boolean;
    firstName: boolean;
    lastName: boolean;
    address1: boolean;
    address2: boolean;
    address3: boolean;
    phone: boolean;
    postalCode: boolean;
    countryId: boolean;
    headerText: string;
    footerText: string;

    constructor(
        title = false,
        email = false,
        firstName = false,
        lastName = false,
        address1 = false,
        address2 = false,
        address3 = false,
        phone = false,
        postalCode = false,
        countryId = false
    ) {
        this.title = title;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.address1 = address1;
        this.address2 = address2;
        this.address3 = address3;
        this.phone = phone;
        this.postalCode = postalCode;
        this.countryId = countryId;
    }
}
