import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoomTypePictureModel } from '../../../../models/room-type/room-types.model';
import { DomSanitizer } from '@angular/platform-browser';
import { SignupService } from '../../signup.service';
import { ReOrderImagesModel } from '../../../../models/room-type/re-order-images.model';
import { RoomTypeInsertPictureModel } from '../../../../models/room-type/room-type-insert-picture.model';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { environment } from '../../../../../environments/environment';
import { InventoryRoomTypesPermissionsModel } from '../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../enums/permissions.enum';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';

@Component({
    selector: 'app-room-type-images',
    templateUrl: './room-type-images.component.html',
    styleUrls: ['./room-type-images.component.less'],
})
export class RoomTypeImagesComponent implements OnInit {
    @Input() imageRoomTypePopup: boolean;
    @Output() imageRoomTypePopupChange = new EventEmitter<boolean>();
    @Input() idOfRoomType: number;
    @Input() roomTypeName: number;

    permissionsModel: InventoryRoomTypesPermissionsModel;

    loader = false;
    showFullSize = false;
    currentIndex: number;
    currentId: string;
    fullSizeImageSrc: string;
    popupLoader = true;

    roomTypePictures: RoomTypePictureModel[] = [];
    reOrderImagesModel = new ReOrderImagesModel();
    roomTypeInsertPictureModel = new RoomTypeInsertPictureModel();
    theme: number;

    constructor(
        private sanitizer: DomSanitizer,
        private signUpService: SignupService,
        private toasterService: ToastService,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryRoomTypes);
    }

    ngOnInit() {
        this.init();
    }

    init() {
        this.signUpService.getRoomTypePictures(this.idOfRoomType).subscribe(
            (response) => {
                this.roomTypePictures = response;
                this.popupLoader = false;
            },
            (error) => {
                this.popupLoader = false;
            }
        );
    }

    uploadImage(event) {
        const component = this;
        const files: any = <HTMLElement>event.target.files;
        const urlStringReader = new FileReader();
        if (files.length && files.length > 0 && this.isValidUploadData(files)) {
            const file = files[0];
            urlStringReader.addEventListener('load', function (event: any) {
                const src = event.target.result;
                if (component.roomTypePictures.length < 3) {
                    component.uploadImages(files);
                }
            });
            urlStringReader.readAsDataURL(file);
        } else {
            this.toasterService.showToast('Error', 'File size exceeds the set limit of 4MB. Please try with a smaller file.', null, ToastEventType.Error, true, 5000);
        }
    }

    isValidUploadData(files: FileList) {
        let valid = true;
        Object.keys(files).forEach((i) => {
            if (files[i].size >= 4000000) {
                valid = false;
            }
        });
        return valid;
    }

    removeImage(picture) {
        const index = this.roomTypePictures.indexOf(picture);
        if (index > -1) {
            this.loader = true;
            this.signUpService.removePicture(picture.id).subscribe(
                (response) => {
                    this.init();
                    this.loader = false;
                },
                (error) => {
                    console.log(error);
                    this.loader = false;
                }
            );
        }
    }

    reOrderImages(listOfImages: string[]) {
        this.reOrderImagesModel.order = listOfImages;
        this.reOrderImagesModel.roomTypeId = this.idOfRoomType;

        this.loader = true;
        this.signUpService.reOrderImages(this.reOrderImagesModel).subscribe(
            (response) => {
                this.loader = false;
            },
            (error) => {
                console.log(error);
                this.loader = false;
            }
        );
    }

    uploadImages(file: File[]) {
        this.roomTypeInsertPictureModel.roomTypeId = this.idOfRoomType;
        this.loader = true;
        this.signUpService.insertNewPicture(this.roomTypeInsertPictureModel, file).subscribe(
            (response: RoomTypePictureModel) => {
                this.roomTypePictures.push(response);
                this.loader = false;
            },
            (error) => {
                console.log(error);
                this.loader = false;
            }
        );
    }

    showFullSizeImage(picture: RoomTypePictureModel) {
        this.showFullSize = true;
        const index = this.roomTypePictures.indexOf(picture);
        this.currentIndex = index;
        this.currentId = picture.id;
        this.fullSizeImageSrc = environment.azureBlobUrl + 'room-type-pictures/' + this.currentId + '.jpg';
    }

    sanitize(picture) {
        const index = this.roomTypePictures.indexOf(picture);
        if (index > -1) {
            return this.sanitizer.bypassSecurityTrustUrl(
                'data:image/jpeg;base64,' + this.roomTypePictures[index].thumbnail
            );
        } else {
            return this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + '');
        }
    }

    goRight() {
        this.currentIndex++;
        this.currentId = this.roomTypePictures[this.currentIndex].id;
        this.fullSizeImageSrc = environment.azureBlobUrl + 'room-type-pictures/' + this.currentId + '.jpg';
    }

    goLeft() {
        this.currentIndex--;
        this.currentId = this.roomTypePictures[this.currentIndex].id;
        this.fullSizeImageSrc = environment.azureBlobUrl + 'room-type-pictures/' + this.currentId + '.jpg';
    }

    close() {
        this.imageRoomTypePopupChange.emit(false);
    }

    closeFullSize() {
        this.showFullSize = false;
    }
}
