import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RateSchedulesService } from '../rate-schedules.service';
import { RoomTypeBaseModel } from '../../../../../models/room-type/room-type-base.model';
import { RateScheduleRoomTypeModel } from '../../../../../models/rate-schedule/rate-schedule-room-type.model';
import { RateSchedulesWithRoomTypesModel } from '../../../../../models/rate-schedule/rate-schedules-with-room-types.model';
import * as _ from 'lodash';
import { AuthTokenService } from '../../../../../shared/services/auth-token.service';
import { InventoryRoomTypesPermissionsModel } from '../../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../../enums/permissions.enum';

@Component({
    selector: 'app-rate-schedules-room-types',
    templateUrl: './rate-schedules-room-types.component.html',
    styleUrls: ['./rate-schedules-room-types.component.less'],
})
export class RateSchedulesRoomTypesComponent implements OnInit {
    @Input() roomTypes: boolean;
    @Output() roomTypesChange = new EventEmitter<boolean>();
    @Input() rateScheduleId: number;
    @Input() rateScheduleName: number;
    @Output() update = new EventEmitter();

    permissionsModel: InventoryRoomTypesPermissionsModel;

    loader = false;
    roomTypeDetails = false;
    roomTypeId: number;
    errorMessage: string;
    theme: number;

    rateSchedulesWithRoomTypes = new RateSchedulesWithRoomTypesModel();
    rateScheduleRoomTypeModel = new RateScheduleRoomTypeModel();

    constructor(private rateSchedulesService: RateSchedulesService, private permissionsService: PermissionsService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryRoomTypes);
    }

    ngOnInit() {
        this.rateSchedulesService.getAssignRoomTypesPopup(this.rateScheduleId).subscribe(
            (response) => {
                this.rateSchedulesWithRoomTypes = response;
                this.rateScheduleRoomTypeModel.rateScheduleId = this.rateScheduleId;
                this.rateScheduleRoomTypeModel.roomTypes = this.rateSchedulesWithRoomTypes.rateScheduleRoomTypes;
            },
            (error) => {
                console.log(error);
            }
        );
    }

    setRoomTypeToRateSchedule(e, data) {
        const roomTypeBaseModel: RoomTypeBaseModel = e.itemData;
        const index = this.rateScheduleRoomTypeModel.roomTypes.indexOf(data);
        this.rateScheduleRoomTypeModel.roomTypes[index] = roomTypeBaseModel;
    }

    openRoomTypeDetailsSchedules(id) {
        this.roomTypeDetails = true;
        this.roomTypeId = id;
    }

    deleteItem(data) {
        const index = this.rateScheduleRoomTypeModel.roomTypes.indexOf(data);
        this.rateScheduleRoomTypeModel.roomTypes.splice(index, 1);
    }

    addNewItem() {
        const roomTypeBaseModel: RoomTypeBaseModel = new RoomTypeBaseModel();
        roomTypeBaseModel.id = null;
        this.rateScheduleRoomTypeModel.roomTypes.push(roomTypeBaseModel);
    }

    validateForm(): boolean {
        for (const roomType of this.rateScheduleRoomTypeModel.roomTypes) {
            if (roomType.id === null) {
                this.errorMessage = 'Error! Please fill all fields.';
                return true;
            }
        }

        if (
            _.uniqBy(this.rateScheduleRoomTypeModel.roomTypes, 'id').length !==
            this.rateScheduleRoomTypeModel.roomTypes.length
        ) {
            this.errorMessage = 'Error! You cannot enter same room types.';
            return true;
        }

        this.errorMessage = '';
        return false;
    }

    submitRoomTypesToRateSchedules() {
        if (!this.validateForm()) {
            this.loader = true;
            this.rateSchedulesService.assignRoomTypes(this.rateScheduleRoomTypeModel).subscribe(
                (response) => {
                    this.loader = false;
                    this.update.emit();
                    this.close();
                },
                (error) => {
                    console.log(error);
                    this.loader = false;
                }
            );
        }
    }

    close() {
        this.roomTypesChange.emit(false);
    }
}
