import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { AuthTokenService } from './shared/services/auth-token.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { HttpRequestService } from './shared/services/http-request.service';
import { CommunicationService } from './shared/services/communication.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
    timeOutPopup = false;
    refreshPagePopup = false;
    mobileView = false;
    maintenanceMode: boolean;
    message: string;
    theme: number;

    constructor(
        private idle: Idle,
        private authTokenService: AuthTokenService,
        private router: Router,
        private titleService: Title,
        private http: HttpRequestService,
        ngZone: NgZone,
        private communicationService: CommunicationService
    ) {
        this.titleService.setTitle(environment.titleAppName);

        this.http.showRefreshPopup.subscribe((res) => {
            this.refreshPagePopup = res;
        });

        idle.setIdle(60 * 30); // in seconds
        //idle.setIdle(60); // dev testing 1 min;
        idle.setTimeout(20);
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => (this.message = 'Click here to go back to application.'));

        idle.onTimeoutWarning.subscribe((timer) => {
            if (timer > 1) {
                this.message = 'You are going offline in ' + timer + ' seconds.';
            } else {
                this.message = 'You are going offline in ' + timer + ' second.';
            }
            this.timeOutPopup = true;
        });

        idle.onTimeout.subscribe(() => {
            this.timeOutPopup = false;
            this.authTokenService.logout();
            this.router.navigate(['login']);
        });

        this.communicationService.maintenanceMode.subscribe((response: boolean) => {
            this.maintenanceMode = response;
        });

        this.resetIdle();

        window.onresize = (e) => {
            ngZone.run(() => {
                this.detectViewport();
            });
        };
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.detectViewport();
    }

    ngOnInit(): void {
        if (AuthTokenService.getToken() != null) {
            this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
            const body = document.getElementsByTagName('body')[0];

            if (Number(this.theme) === 2) {
                // add style 'darkTheme' to body emelent
                body.classList.add('dark-theme');
            }
            if (Number(this.theme) === 1) {
                // add style 'darkTheme' to body emelent
                body.classList.remove('dark-theme');
            }
        }
        this.detectViewport();
    }

    detectViewport() {
        if (this.inIFrame()) {
            return;
        }

        const mobileFriendlyUrls = ['/guest-registration'];

        if (window.innerHeight > window.innerWidth) {
            if (!mobileFriendlyUrls.find((mf) => window.location.href.includes(mf))) {
                this.mobileView = true;
            }
        }
        if (window.innerHeight < window.innerWidth) {
            this.mobileView = false;
        }
    }

    resetIdle() {
        const body = document.getElementsByTagName('body')[0];
        this.authTokenService.isAuthenticated().then((authenticated) => {
            if (authenticated) {
                this.idle.watch();

                this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;

                if (Number(this.theme) === 2) {
                    // add style 'darkTheme' to body element
                    body.classList.add('dark-theme');
                }
                if (Number(this.theme) === 1) {
                    // add style 'darkTheme' to body element
                    body.classList.remove('dark-theme');
                }
                if (
                    this.router.url.indexOf('login') > -1 ||
                    this.router.url.indexOf('forgot-password') > -1 ||
                    this.router.url.indexOf('signup') > -1 ||
                    this.router.url.indexOf('guest-registration') > -1
                ) {
                    body.classList.remove('dark-theme');
                }
            } else {
                body.classList.remove('dark-theme');
            }
        });
    }

    inIFrame(): boolean {
        try {
            return window.self !== window.top;
        } catch (e) {
            return false;
        }
    }
}
