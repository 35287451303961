import { Component, OnInit, AfterViewChecked, ViewChild, OnDestroy } from '@angular/core';
import { SignupService } from '../signup.service';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { RoomTypesGridModel } from '../../../models/room-type/room-types.model';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { InventoryRoomTypesPermissionsModel } from '../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../enums/permissions.enum';
import { DxDataGridComponent } from 'devextreme-angular';
import StringHelper from '../../../shared/helpers/string-helper';

@Component({
    selector: 'app-room-type',
    templateUrl: './room-type.component.html',
    styleUrls: ['./room-type.component.less'],
})
export class RoomTypeComponent implements OnInit, OnDestroy {
    @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;
    permissionsModel: InventoryRoomTypesPermissionsModel;

    roomTypeOrder = false;
    addRoomTypePopup = false;
    editRoomTypePopup = false;
    imageRoomTypePopup = false;
    deletePopup = false;
    pointer = false;

    idOfRoomType: number;
    deleteId: number;
    roomTypeName: string;

    displayOrderList: number[] = [];

    pager = {
        showPageSizeSelector: false,
        showNavigationButtons: true,
        allowedPageSizes: [10],
    };
    size: any;
    theme: number;

    roomTypesModel = new RoomTypesGridModel();

    constructor(
        private signUpService: SignupService,
        public router: Router,
        private route: ActivatedRoute,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryRoomTypes);
    }

    ngOnInit() {
        this.init();
    }

    // ngAfterViewChecked(): void {
    //   this.size = <HTMLElement>document.querySelectorAll('html')[0];
    //   if (this.size.getBoundingClientRect().width < 1400) {
    //     if (this.roomTypesModel.roomTypes) {
    //       this.grid.instance.pageSize(5);
    //       // this.grid.pager = this.pager;
    //     }
    //   }
    // }

    init() {
        this.roomTypesModel = this.route.snapshot.data.roomTypesModel;
    }

    updateData() {
        this.signUpService.getByProperty(AuthTokenService.decodeJwtToken().Property.Id).subscribe(
            (response) => {
                this.roomTypesModel = response;
            },
            (error) => {}
        );
    }

    cellClick(e) {
        if (e.columnIndex === 1) {
            this.openEditRoomPopup(e.data.id);
        }
    }

    openRoomTypeOrder() {
        this.roomTypeOrder = true;
    }

    openRoomTypePopup() {
        this.displayOrderList = [];
        this.roomTypesModel.roomTypes.forEach((x) => this.displayOrderList.push(x.displayOrder));
        this.addRoomTypePopup = true;
    }

    openEditRoomPopup(id) {
        this.displayOrderList = [];
        this.roomTypesModel.roomTypes.forEach((x) => {
            if (id !== x.id) {
                this.displayOrderList.push(x.displayOrder);
            }
        });

        this.editRoomTypePopup = true;
        this.idOfRoomType = id;
    }

    openImagePopup(data) {
        this.idOfRoomType = data.id;
        this.roomTypeName = data.name;
        this.imageRoomTypePopup = true;
    }

    navigateToRooms() {
        this.router.navigate(['inventory/assign-rooms']);
    }

    deleteModal(id) {
        this.deletePopup = true;
        this.deleteId = id;
    }

    deleteRoomType() {
        this.signUpService.deleteRoomType(this.deleteId).subscribe(
            (response) => {
                this.updateData();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    close() {
        this.deletePopup = false;
    }

    ngOnDestroy() {}

    cleanHtml(html) {
        return StringHelper.cleanHtml(html);
    }
}
