<app-header></app-header>

<div class="confirmation-wrapper flex">
    <div class="custom-box">
        <h2>Activation</h2>

        <p>
            Activation e-mail sent to {{ tempPropertyEmail }}. Please click the link inside the email to verify your
            address and log in to your account.
        </p>
    </div>
</div>
