import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivationFlowModel } from '../../../models/user/activation.model';
import { DataTransferService } from '../../../shared/services/data-transfer.service';
import { CountryModel } from '../../../models/account/country.model';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { ActivationResultModel } from '../../../models/account/activation-result.model';
import { AuthLoginService } from '../../../shared/services/auth-login.service';

@Component({
    selector: 'app-debit-mandate',
    templateUrl: './debit-mandate.component.html',
    styleUrls: ['./debit-mandate.component.less'],
})
export class DebitMandateComponent implements OnInit {
    countryId: number;
    agreement: boolean;
    debitConfirmModal = false;
    guaranteeInfo = false;
    submitted = false;

    currentCountry = new CountryModel();
    valForm: UntypedFormGroup;
    activationFlowModel = new ActivationFlowModel();

    popup = false;
    loader = false;
    activationResultModel = new ActivationResultModel();
    title: string;
    message: string;

    theme: number;

    constructor(
        private fb: UntypedFormBuilder,
        private router: Router,
        private authLoginService: AuthLoginService,
        private authTokenService: AuthTokenService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;

        this.valForm = this.fb.group({
            name: [null, Validators.required],
            number: [null, Validators.required],
            code: [null],
            agreement: [null, Validators.required],
        });

        if (DataTransferService.GetData('ActivationFlowModel') !== null) {
            this.activationFlowModel = DataTransferService.GetData('ActivationFlowModel');

            this.getCountry(this.activationFlowModel.account.countryId, this.activationFlowModel.countries);

            if (this.currentCountry.code === 'GB') {
                this.router.navigate(['debit-mandate']);
            } else {
                this.router.navigate(['debit-mandate']); // TEMPORARY
                // this.router.navigate(['payment-details']);
            }
        } else {
            this.router.navigate(['login/activation']);
        }
    }

    ngOnInit() {}

    openGuaranteeInfo() {
        this.guaranteeInfo = true;
    }

    openTerms() {
        window.open('https://gocardless.com/legal/', '_blank');
    }

    getCountry(countryId, listOfCountries: CountryModel[]) {
        for (const country of listOfCountries) {
            if (country.id === countryId) {
                this.currentCountry = country;
                break;
            }
        }
    }

    confirm() {
        if (this.activationResultModel !== null) {
            if (this.activationResultModel.success) {
                this.authTokenService.refreshToken(this.activationResultModel.token);
                DataTransferService.PullData('ActivationFlowModel');
                this.router.navigate(['dashboard']);
            }
        }
    }

    openConfirmModal(e) {
        e.stopPropagation();
        this.submitted = true;
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid && this.agreement) {
            this.debitConfirmModal = true;
            this.submitted = false;
        }
    }

    submitDebitMandate(e) {
        if (e) {
            this.loader = true;

            this.authLoginService.activateProperty(this.activationFlowModel).subscribe(
                (response) => {
                    this.popup = true;
                    this.loader = false;
                    this.activationResultModel = response;
                    if (this.activationResultModel !== null) {
                        this.title = this.activationResultModel.title;
                        this.message = this.activationResultModel.message;
                    }
                },
                (error) => {
                    console.log(error);
                    this.popup = true;
                    this.loader = false;
                }
            );
        }
    }
}
