import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpRequestService } from './http-request.service';
import { ReportsService } from 'src/app/routes/reports/reports.service';
import { BehaviorSubject } from 'rxjs';
import DateHelper from '../helpers/date-helper';
import { ExportPdfContentModel } from 'src/app/models/reports/export-pdf-content.model';
import { AuthTokenService } from './auth-token.service';
import { TokenContext } from 'src/app/models/auth-jwt/token-context';
import { ExportPdfHeader, ExportPdfPostCompleteAction } from 'src/app/enums/export-pdf.enum';
import { ExportPdfHeaderOptionsModel, ExportPdfOptionsModel } from 'src/app/models/shared/export-pdf-options.model';

@Injectable()
export class HtmlToPdfService {

    private messageSource = new BehaviorSubject('');
    currentMessage = this.messageSource.asObservable();

    token: TokenContext = new TokenContext();
    ExportOptions: ExportPdfOptionsModel;

    constructor(private reportsService: ReportsService) {
      this.token = AuthTokenService.decodeJwtToken();
    }

    exportToPdf(elements: ExportPdfContentModel[], exportOptions: ExportPdfOptionsModel , overrideStyles: string[]) {
        if (elements.length == 0) {
            return null;
        }
        this.ExportOptions = exportOptions;
        let htmlArray =[];
        elements.forEach(element => {
            if (element.isHtml) {
                htmlArray.push(element.content);
            }
            else {
                let eleHtml = document.getElementById(element.content);
                htmlArray.push(eleHtml.innerHTML);
            }

        });
        let html = htmlArray.join("\n");
        let styles = this.getStandardStyles(overrideStyles);
        let headerHtml = this.getHeaderHtml();
        let footerHtml = this.getFooterHtml();

        let reportHtml = `<html><head>${styles}</head><body>${headerHtml}${html}${footerHtml}</body></html>`;
        console.log('exportToPdf', reportHtml);
        this.reportsService.convertHtmlToPdf(reportHtml, this.ExportOptions.reportTitle, this.ExportOptions.fileName).subscribe(
            (response) => {          
              if (this.ExportOptions.postCompleteAction == ExportPdfPostCompleteAction.Download) {
                this.downloadFile(response, this.ExportOptions.fileName);
              }
              if (this.ExportOptions.postCompleteAction == ExportPdfPostCompleteAction.Print) {
                this.printFile(response, this.ExportOptions.fileName);
              }
              this.messageSource.next('Complete');
    
            },
            (error) => {
              console.log('exportPdf error: ', error);
              this.messageSource.next('Error');
            }
          );
    }

    downloadFile(b, fileName) {
        const dwldLink = document.createElement('a');
        const url = URL.createObjectURL(b.body);
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        if (isSafariBrowser) {
            // if Safari open in new window to save file with random filename.
            dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', fileName);
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
      }

      printFile(b, fileName) {
        console.log('printFile', b.body);
        const blob = new Blob([b.body], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);

        const iframe = document.createElement('iframe');
        const nav = (window.navigator as any);
        iframe.style.display = 'none';

        document.body.appendChild(iframe);

        iframe.onload = function () {

          if (nav.msSaveOrOpenBlob) {
              nav.msSaveOrOpenBlob(blob, fileName);
          } else if (window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
              const a = document.createElement('a');
              document.body.appendChild(a);
              a.href = blobUrl;
              a.download = fileName;
              a.target = '_blank';
              a.click();
          } else {
              iframe.contentWindow.print();
          }
        };

        iframe.src = blobUrl;
      }

      private getLeftRightHeaderPart(option: ExportPdfHeader) {
        let result =  ``;
        if (option == ExportPdfHeader.ReportTitle) {
          result = `<h2>${this.ExportOptions.reportTitle}</h2>`;
        }
        if (option == ExportPdfHeader.PropertyName) {
          result = `<h2>${this.token.Property.Name}</h2>`;
        }
        if (option == ExportPdfHeader.Logo) {
          let propertyLogoMatches = document.getElementsByClassName('uploaded-img');
          if (propertyLogoMatches && propertyLogoMatches.length == 1 && propertyLogoMatches[0].outerHTML != null) {
            result = propertyLogoMatches[0].outerHTML;
          }
          else {
            result = `<h2>${this.token.Property.Name}</h2>`; //fallback on name
          }
        }
        return result;
      }

      private getHeaderHtml() {
        let leftPart = this.getLeftRightHeaderPart(this.ExportOptions.header.headerLeft);
        let rightPart = this.getLeftRightHeaderPart(this.ExportOptions.header.headerRight);;
        
        return `<div class="header row"><div class="column header-left">${leftPart}</div><div class="column align-right header-right">${rightPart}</div>`;
      }

    private getFooterHtml() {
      if (this.ExportOptions.footer) {
        let currentDate = DateHelper.formatDate(new Date(), 'DD-MMM-YYYY HH:mm');
        let leftPart = `Generated by Caterbook on ${currentDate}`;
        let rightPart = '<img src="https://app.caterbook.net/assets/images/logo.svg" alt="Caterbook">';

        return `<div class="footer"><div class="footer-content row"><div class="column footer-left">${leftPart}</div><div class="column align-right footer-right">${rightPart}</div></div></div>`;
      }
      else {
        return '';
      }

    }

    private getStandardStyles(overrideStyles: string[]): string {
        let stylesArray = [];
        stylesArray.push('<style type="text/css">');
        stylesArray.push('html {');
        stylesArray.push('font-family: "Segoe UI", sans-serif;');
        stylesArray.push('}');
        stylesArray.push('body {');
        stylesArray.push('padding: 0px 35px 15px 35px;');
        stylesArray.push('}');
        stylesArray.push('h4 {');
        stylesArray.push('margin-block-start: 0.5em;');
        stylesArray.push('margin-block-end: 0.5em;');
        stylesArray.push('}');
        stylesArray.push('.header {');
        stylesArray.push('width: 100%;');
        stylesArray.push('clear: both;');
        stylesArray.push('}');

        stylesArray.push('.column {');
        stylesArray.push('float: left;');
        stylesArray.push('width: 50%;');
        stylesArray.push('}');
          
        stylesArray.push('.row:after {');
        stylesArray.push('content: "";');
        stylesArray.push('display: table;');
        stylesArray.push('clear: both;');
        stylesArray.push('}');

        stylesArray.push('.align-right {');
        stylesArray.push('text-align: right;');
        stylesArray.push('}');
        stylesArray.push('.chart-container, .table-container {');
        stylesArray.push('width: 90%;');
        stylesArray.push('}');
        stylesArray.push('.chart-container svg {');
        stylesArray.push('width: 90%;');
        stylesArray.push('}');
        stylesArray.push('.table {');
        stylesArray.push('width: 100%;');
        stylesArray.push('}');
        stylesArray.push('thead tr td {');
        stylesArray.push('font-weight: bold;');
        stylesArray.push('background-color: #fbfbfc;');
        stylesArray.push('border: 1px solid #eef1f5;');
        stylesArray.push('padding: 7px 5px;');
        stylesArray.push('text-transform: uppercase;');
        stylesArray.push('text-align: center;');
        stylesArray.push('}');
        stylesArray.push('.table-body tr {');
        stylesArray.push('tr:nth-child(even) {background-color: #fbfbfc;}');
        stylesArray.push('}');

        stylesArray.push('.table-body tr td {');
        stylesArray.push('border: 1px solid #eef1f5;');
        stylesArray.push('padding: 3px 1px;');
        stylesArray.push('text-transform: uppercase;');
        stylesArray.push('text-align: center;');
        stylesArray.push('font-size: 11px;');
        stylesArray.push('}');

        stylesArray.push('.footer {');
        stylesArray.push('width: 100%;');
        stylesArray.push('border-top: solid 1px;');
        stylesArray.push('height: 50px;');
        
        stylesArray.push('position: absolute;');
        stylesArray.push('bottom: 0;');
        stylesArray.push('left: 0;');
        stylesArray.push('}');

        stylesArray.push('.footer-content {');
        stylesArray.push('margin-top: 10px; margin-left: 40px; margin-right: 40px');
        stylesArray.push('}');

        stylesArray.push('.new-page {');
        stylesArray.push('page-break-before: always;');
        stylesArray.push('}');

        stylesArray.push('.uploaded-img {');
        stylesArray.push('max-height: 200px;');
        stylesArray.push('max-width: 400px;');
        stylesArray.push('object-fit: scale-down;');
        stylesArray.push('object-position: top;');
        stylesArray.push('}');

        if (overrideStyles != null && overrideStyles.length > 0) {
            stylesArray = stylesArray.concat(overrideStyles);
        }

        stylesArray.push('</style>');
        return stylesArray.join("\n");
    }

    blobToBase64(blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      };
}