<div class="room-type-image-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup cost-fees" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <div (click)="close()" class="close-button">
                <i class="fa fa-times"></i>
            </div>
            <div class="flex header-info">
                <h2>Room Type Images - {{ roomTypeName }}</h2>

                <ng-container *ngIf="roomTypePictures.length < 3">
                    <div class="header-chose-file-wrapper" *ngIf="permissionsModel.formButtonEnabled">
                        <input
                            type="file"
                            name="file"
                            id="file"
                            accept="image/jpeg"
                            (change)="uploadImage($event)"
                            class="inputfile"
                        />
                        <label *ngIf="!loader" class="custom-button orange right" for="file">Choose a file</label>
                        <div *ngIf="loader" class="loader right"></div>
                        <div *ngIf="!loader" class="image-validation-text">
                            * Maximum image size that you can upload is 4MB
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="roomTypePictures.length == 3">
                    <div *ngIf="loader" class="loader right"></div>
                </ng-container>
            </div>

            <div class="image-container">
                <div
                    class="img-holder"
                    *ngFor="let picture of roomTypePictures; let index = index"
                    id="imageDiv{{ index }}"
                >
                    <img [src]="sanitize(picture)" alt="" (click)="showFullSizeImage(picture)" />

                    <div class="img-actions" *ngIf="permissionsModel.formButtonEnabled">
                        <div class="action" (click)="removeImage(picture)">Remove</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="overlay" *ngIf="showFullSize">
    <div class="full-size-img">
        <img [src]="fullSizeImageSrc" alt="" />
    </div>

    <i class="fa fa-chevron-left" aria-hidden="true" (click)="goLeft()" *ngIf="currentIndex != 0"></i>
    <i
        class="fa fa-chevron-right"
        aria-hidden="true"
        (click)="goRight()"
        *ngIf="currentIndex != roomTypePictures.length - 1"
    ></i>
    <i class="fa fa-times custom" aria-hidden="true" (click)="closeFullSize()"></i>
</div>
<div *ngIf="popupLoader" class="loader"></div>
