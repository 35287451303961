import { Component, OnInit } from '@angular/core';
import { ActivationFlowModel } from '../../../models/user/activation.model';
import { DataTransferService } from '../../../shared/services/data-transfer.service';
import { AccountBillingDetailsModel } from '../../../models/account/account-bill-info.model';
import { Router } from '@angular/router';
import { CountryModel } from '../../../models/account/country.model';
import { CurrencyModel } from '../../../models/account/currency.model';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { AuthLoginService } from '../../../shared/services/auth-login.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';
import { CommunicationService } from '../../../shared/services/communication.service';

@Component({
    selector: 'app-account-activation',
    templateUrl: './account-activation.component.html',
    styleUrls: ['./account-activation.component.less'],
})
export class AccountActivationComponent implements OnInit {
    showTrialMessage = false;
    countryId: number;
    activationFlowModel = new ActivationFlowModel();
    currentCountry = new CountryModel();
    currentCurrency = new CurrencyModel();
    showActivatedSuccesMessage = false;

    theme: number;

    constructor(
        private router: Router,
        private authLogingService: AuthLoginService,
        private toasterService: ToastService,
        private communicationService: CommunicationService,
        private authTokenService: AuthTokenService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;

        if (DataTransferService.GetData('ShowTrialMessage') !== null) {
            this.showTrialMessage = DataTransferService.GetData('ShowTrialMessage');
        } else {
            this.showTrialMessage = false;
        }

        if (DataTransferService.GetData('ActivationFlowModel') !== null) {
            this.activationFlowModel = DataTransferService.GetData('ActivationFlowModel');
            this.getCountry(this.activationFlowModel.account.countryId, this.activationFlowModel.countries);
            this.getCurrency(this.activationFlowModel.account.currencyId, this.activationFlowModel.currencies);
        } else {
            this.router.navigate(['account-details']);
        }
    }

    getCountry(countryId, listOfCountries: CountryModel[]) {
        for (const country of listOfCountries) {
            if (country.id === countryId) {
                this.currentCountry = country;
                break;
            }
        }
    }

    getCurrency(currencyId, listOfCurrencies: CurrencyModel[]) {
        for (const currency of listOfCurrencies) {
            if (currency.code === 'GBP') {
                this.currentCurrency = currency;
                break;
            }
        }
    }

    ngOnInit() {}

    nextPage() {
        DataTransferService.PullData('ActivationFlowModel');
        DataTransferService.PushData('ActivationFlowModel', this.activationFlowModel);

        if (this.currentCountry.code === 'GB') {
            this.router.navigate(['debit-mandate']);
        } else {
            this.router.navigate(['debit-mandate']); // TEMPORARY
            // this.router.navigate(['payment-details']);
        }
    }

    activate(model) {
        this.authLogingService.activateProperty(model).subscribe(
            (res) => {
                this.showActivatedSuccesMessage = true;
                this.authTokenService.refreshToken(res.token);
                this.communicationService.accountActivated.next(true);
            },
            (error) => {
                console.log(error);
            }
        );
    }
    cancel() {
        this.router.navigate(['dashboard']);
    }

    calculateAmount(item: any, event) {
        if (event.target.checked) {
            item.checked = true;
            this.activationFlowModel.billingInfo.total += item.itemValue;
            this.activationFlowModel.billingInfo.items.push(item);
        } else {
            item.checked = false;
            this.activationFlowModel.billingInfo.total -= item.itemValue;
            const index = this.activationFlowModel.billingInfo.items.indexOf(item);
            this.activationFlowModel.billingInfo.items.splice(index, 1);
        }
    }

    goToDashboard() {
        this.router.navigate(['dashboard']);
    }
}
