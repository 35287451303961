import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InvoiceItemModel } from 'src/app/models/booking/invoice-details-pd.model';
import { CurrencyContext } from 'src/app/models/auth-jwt/token-context';
import {NewFunctionService} from '../../../function/settings-new-function/new-function.service'
import { AuthTokenService } from 'src/app/shared/services/auth-token.service';

@Component({
  selector: 'app-function-details-invoice-item',
  templateUrl: './function-details-invoice-item.component.html',
  styleUrls: ['./function-details-invoice-item.component.less']
})
export class FunctionDetailsInvoiceItemComponent implements OnInit {
    @Input() addRoomItem: boolean;
    @Output() addRoomItemChange = new EventEmitter<boolean>();
    @Input() editRoomItem: boolean;
    @Output() editRoomItemChange = new EventEmitter<boolean>();
    @Output() roomItemsList = new EventEmitter<InvoiceItemModel[]>();
    @Input() selectedRooms: InvoiceItemModel[];

    @Input() addExtraItem: boolean;
    @Output() addExtraItemChange = new EventEmitter<boolean>();
    @Input() editExtraItem: boolean;
    @Output() editExtraItemChange = new EventEmitter<boolean>();
    @Output() extrasItemsList = new EventEmitter<InvoiceItemModel[]>();
    @Input() selectedExtras: InvoiceItemModel[];

    @Input() functionId: number;
    @Input() invoiceId: number;
    @Output() update = new EventEmitter();

    loader = false;

    invoiceItemList: InvoiceItemModel[] = [];
    selectedRows: InvoiceItemModel[] = [];
    currencyContext: CurrencyContext = new CurrencyContext();

    theme: number;

  constructor(private functionService: NewFunctionService) {
    this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    this.currencyContext = AuthTokenService.decodeJwtToken().Currency;
   }

  ngOnInit(): void {
    if (this.addRoomItem || this.editRoomItem) {
      this.functionService.getRoomItemsForInvoice(this.functionId).subscribe(
          (response) => {
              this.invoiceItemList = response;
              if (this.addRoomItem) {
                  const existingItems: InvoiceItemModel[] = JSON.parse(JSON.stringify(this.selectedRooms));
                  for (let i = 0; i < this.invoiceItemList.length; i++) {
                      for (let j = 0; j < existingItems.length; j++) {
                          if (this.invoiceItemList[i].description === existingItems[j].description) {
                              this.invoiceItemList.splice(i, 1);
                          }
                      }
                  }
              }
          },
          (error) => {
              console.log(error);
          }
      );
  } else if (this.addExtraItem || this.editExtraItem) {
      this.functionService.getExtraItemsForInvoice(this.functionId).subscribe(
          (response) => {
              this.invoiceItemList = response;
              if (this.addExtraItem) {
                  const existingItems: InvoiceItemModel[] = JSON.parse(JSON.stringify(this.selectedExtras));
                  for (let i = 0; i < this.invoiceItemList.length; i++) {
                      for (let j = 0; j < existingItems.length; j++) {
                          if (this.invoiceItemList[i].description === existingItems[j].description) {
                              this.invoiceItemList.splice(i, 1);
                          }
                      }
                  }
              }
          },
          (error) => {
              console.log(error);
          }
      );
    }
  }

  submitInvoiceItems() {
    if (this.selectedRows.length > 0) {
        if (this.addRoomItem) {
            this.roomItemsList.emit(this.selectedRows);
            this.close();
        } else if (this.addExtraItem) {
            this.extrasItemsList.emit(this.selectedRows);
            this.close();
        } else if (this.editRoomItem || this.editExtraItem) {
            this.selectedRows.forEach((x) => (x.invoiceId = this.invoiceId));
            this.functionService.insertInvoiceItems(this.selectedRows).subscribe(
                (response) => {
                    this.update.emit();
                    this.close();
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }
}

close() {
    this.addRoomItemChange.emit(false);
    this.editRoomItemChange.emit(false);
    this.addExtraItemChange.emit(false);
    this.editExtraItemChange.emit(false);
}

}
