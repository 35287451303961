<div class="success-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box rate popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>{{ title }}</h2>
            <h3>{{ message }}</h3>

            <button type="button" (click)="close()" class="custom-button blue">Ok</button>
            <!--<button class="custom-button blue">Ok</button>-->
        </div>
    </div>
</div>
