import { EnumModel } from '../shared/enum-model';
import { RateScheduleWithRatePlanModel } from './rate-schedule-with-rate-plan.model';

export class RateScheduleDetailsPDModel {
    public rateSchedule: RateScheduleWithRatePlanModel;
    public discountTypes: EnumModel[] = [];

    constructor() {
        this.rateSchedule = new RateScheduleWithRatePlanModel();
    }
}
