import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpRequestService } from '../../../../shared/services/http-request.service';
import { RatePlanModel } from '../../../../models/rate-plan/rate-plan.model';
import { RatePlanCostCentreFeeManageModel } from '../../../../models/rate-plan/rate-plan-cost-centre-fee.model';
import { UpdateRatePlanSaleItemPDModel } from '../../../../models/rate-plan/checked-sale-item-pd.model';
import { UpdateRateScheduleForRatePlanPDModel } from '../../../../models/rate-schedule/update-rate-schedule-for-rate-plan-pd.model';
import { DerivedPricingUpdateModel } from '../../../../models/rate-plan/derived-pricing-update.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { KeyValuePair } from '../../../../models/shared/key-value-pair.model';
import { RatePlanEPOSAllowanceDetailsModel } from 'src/app/models/rate-plan/rate-plan-epos-allowance.model';

@Injectable()
export class RatePlanService {
    private getAllRatePlansForPropertyUrl = environment.apiUrl + '/RatePlan/GetAllRatePlansForProperty';
    private getRatePlanByIdUrl = environment.apiUrl + '/RatePlan/GetRatePlanById';
    private getRatePlanForPropertyUrl = environment.apiUrl + '/RatePlan/GetRatePlanForProperty';
    private getAccountVATUrl = environment.apiUrl + '/RatePlan/GetAccountVAT';
    private getRateScheduleUrl = environment.apiUrl + '/RatePlan/GetRateSchedule';
    private getListValuesForRatePlanUrl = environment.apiUrl + '/RatePlan/GetListValuesForRatePlan';
    private getCheckedSaleItemListUrl = environment.apiUrl + '/RatePlan/GetCheckedSaleItemList';
    private createRatePlanUrl = environment.apiUrl + '/RatePlan/CreateRatePlan';
    private updateRatePlanUrl = environment.apiUrl + '/RatePlan/UpdateRatePlan';
    private getRatePlanCostCentreUrl = environment.apiUrl + '/RatePlan/GetRatePlanCostCentre';
    private getRatePlanCostCentreFeeUrl = environment.apiUrl + '/RatePlan/GetRatePlanCostCentreFee';
    private setRatePlanCostCentreFeeUrl = environment.apiUrl + '/RatePlan/SetRatePlanCostCentreFee';
    private getCheckedSchedulesListUrl = environment.apiUrl + '/RateSchedule/GetCheckedSchedulesList';
    private deleteRatePlanUrl = environment.apiUrl + '/RatePlan/DeleteRatePlan';
    private updateRatePlanSaleItemsUrl = environment.apiUrl + '/RatePlan/UpdateRatePlanSaleItems';
    private updateCheckedSchedulesListUrl = environment.apiUrl + '/RateSchedule/UpdateCheckedSchedulesList';
    private getDerivedPricingPopupUrl = environment.apiUrl + '/RatePlan/GetDerivedPricingPopup';
    private updateDerivedPricingFromPopupUrl = environment.apiUrl + '/RatePlan/UpdateDerivedPricingFromPopup';
    private ratePlanHasPricesUrl = environment.apiUrl + '/RatePlan/RatePlanHasPrices';
    private newRatePlanUrl = environment.apiUrl + '/RatePlan/NewRatePlan';
    private reorderRatePlansUrl = environment.apiUrl + '/RatePlan/ReorderRatePlans';
    private getRatePlanEPOSAllowanceUrl = environment.apiUrl + '/RatePlan/GetRatePlanEPOSAllowance';
    private ratePlanAllowanceUrl = environment.apiUrl + '/RatePlan/SaveRatePlanEPOSAllowance'

    constructor(
        private http: HttpRequestService,
        private httpRegular: HttpClient,
        private toasterService: ToastService,
        private authTokenService: AuthTokenService
    ) {}

    private handleError(error: any) {
        let errMsg: string;
        if (error.error.message) {
            errMsg = error.error.message;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.log(errMsg);

        this.toasterService.showToast('Error', errMsg, null, ToastEventType.Error, true, 5000);

        this.authTokenService.checkMaintenanceMode(error);

        return observableThrowError({ status: error.status, message: errMsg });
    }

    ratePlanHasPrices(ratePlanId: number) {
        return this.http.get(this.ratePlanHasPricesUrl + '?ratePlanId=' + ratePlanId);
    }

    newRatePlan() {
        return this.http.get(this.newRatePlanUrl);
    }

    getAllRatePlansForProperty(propertyId: number) {
        return this.http.get(this.getAllRatePlansForPropertyUrl + '?propertyId=' + propertyId);
    }

    getRatePlanById(id: number) {
        return this.http.get(this.getRatePlanByIdUrl + '?id=' + id);
    }

    getRatePlanForProperty(propertyId: number) {
        return this.http.get(this.getRatePlanForPropertyUrl + '?propertyId=' + propertyId);
    }

    getAccountVAT() {
        return this.http.get(this.getAccountVATUrl);
    }

    getRateSchedule() {
        return this.http.get(this.getRateScheduleUrl);
    }

    getListValuesForRatePlan() {
        return this.http.get(this.getListValuesForRatePlanUrl);
    }

    getCheckedSaleItemList(ratePlanId: number) {
        return this.http.get(this.getCheckedSaleItemListUrl + '?ratePlanId=' + ratePlanId);
    }

    createRatePlan(model: RatePlanModel) {
        return this.http.post(this.createRatePlanUrl, model, true);
    }

    updateRatePlan(model: RatePlanModel) {
        return this.http.post(this.updateRatePlanUrl, model, true);
    }

    getRatePlanCostCentre(propertyId: number) {
        return this.http.get(this.getRatePlanCostCentreUrl + '?propertyId=' + propertyId);
    }

    getRatePlanCostCentreFee(ratePlanId: number) {
        return this.http.get(this.getRatePlanCostCentreFeeUrl + '?ratePlanId=' + ratePlanId);
    }

    setRatePlanCostCentreFee(model: RatePlanCostCentreFeeManageModel) {
        return this.http.post(this.setRatePlanCostCentreFeeUrl, model, true);
    }

    getRatePlanEPOSAllowance(ratePlanId: number) {
        return this.http.get(this.getRatePlanEPOSAllowanceUrl + '?ratePlanId=' + ratePlanId);
    }

    getCheckedSchedulesList(ratePlanId: number) {
        return this.http.get(this.getCheckedSchedulesListUrl + '?ratePlanId=' + ratePlanId);
    }

    deleteRatePlan(id: number) {
        return this.http.get(this.deleteRatePlanUrl + '?id=' + id, true);
    }

    updateRatePlanSaleItems(model: UpdateRatePlanSaleItemPDModel) {
        return this.http.post(this.updateRatePlanSaleItemsUrl, model, true);
    }

    updateCheckedSchedulesList(model: UpdateRateScheduleForRatePlanPDModel) {
        return this.http.put(this.updateCheckedSchedulesListUrl, model, true);
    }

    getDerivedPricingPopup(ratePlanId: number) {
        return this.http.get(this.getDerivedPricingPopupUrl + '?ratePlanId=' + ratePlanId);
    }

    reorderRatePlans(model: KeyValuePair[]) {
        return this.http.post(this.reorderRatePlansUrl, model, true);
    }

    updateDerivedPricingFromPopup(derivedPricingUpdateModel: DerivedPricingUpdateModel, toastMessage?: string) {
        return this.httpRegular
            .post(this.updateDerivedPricingFromPopupUrl, derivedPricingUpdateModel, { headers: this.getOptions() })
            .pipe(
                map((response) => {
                    this.authTokenService.checkMaintenanceMode(response);

                    if (response == null) {
                        return null;
                    } else {
                        if (response) {
                            this.toasterService.showToast('Success', toastMessage ? toastMessage : 'Operation successful.', null, ToastEventType.Success, true, 2000);
                        }
                        return response;
                    }
                }),
                catchError((response) => this.handleError(response))
            );
    }

    getOptions() {
        const token = AuthTokenService.getParsedToken();
        return new HttpHeaders({ 'Content-Type': 'application/json', Authorization: token });
    }

    saveRatePlanAllowance(allowanceModel:RatePlanEPOSAllowanceDetailsModel) {
        return this.http.post(this.ratePlanAllowanceUrl, allowanceModel, true);
    }
}
