import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { OTAConnectionMappingModel } from '../../../models/ota-connections/ota-connection-mapping-pd.model';

@Injectable()
export class OtaMappingsService {
    private getOTAConnectionMappingsUrl = environment.apiUrl + '/OTA/GetOTAConnectionMappings';
    private updateOTAConnectionMappingsUrl = environment.apiUrl + '/OTA/UpdateOTAConnectionMappings';
    private updateStatusOfOTAMappingUrl = environment.apiUrl + '/OTA/UpdateStatusOfOTAMapping';
    private getOTARatesInfoUrl = environment.apiUrl + '/OTA/GetOTARatesInfo';
    private getICalExportUrl = environment.apiUrl + '/OTA/GetICalUrls';

    constructor(private http: HttpRequestService) {}

    getOTAConnectionMappings(otaConnectionId: number) {
        return this.http.get(this.getOTAConnectionMappingsUrl + '?otaConnectionId=' + otaConnectionId);
    }

    updateStatusOfOTAMapping(otaConnectionId: number, otaMappingId: number) {
        return this.http.get(
            this.updateStatusOfOTAMappingUrl + '?otaConnectionId=' + otaConnectionId + '&otaMappingId=' + otaMappingId
        );
    }

    updateOTAConnectionMappings(otaConnectionId: number, model: OTAConnectionMappingModel[]) {
        return this.http.post(this.updateOTAConnectionMappingsUrl + '?otaConnectionId=' + otaConnectionId, model, true);
    }

    getOTARatesInfo(otaConnectionId: number) {
        return this.http.get(this.getOTARatesInfoUrl + '?otaConnectionId=' + otaConnectionId);
    }

    getICalExport(connectionId: number) {
        return this.http.get(this.getICalExportUrl + '?connectionId=' + connectionId);
    }
}
