import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RatePlanRoomTypeModel } from '../../../../../models/rate-plan/rate-plan-room-type.model';
import { PricingPlanService } from '../pricing-plan.service';
import { CurrencyContext } from '../../../../../models/auth-jwt/token-context';
import { AuthTokenService } from '../../../../../shared/services/auth-token.service';
import { PPRoomTypeModel } from '../../../../../models/price-plan/price-plan-page.model';
import { RatePlanWithDerivedPricingModel } from '../../../../../models/rate-plan/rate-plan-with-derived-pricing.model';
import { PricePlanPermissionsModel } from '../../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../../enums/permissions.enum';

@Component({
    selector: 'app-dynamic-pricing',
    templateUrl: './dynamic-pricing.component.html',
    styleUrls: ['./dynamic-pricing.component.less'],
})
export class DynamicPricingComponent implements OnInit {
    @Input() dynamicPricing: boolean;
    @Output() dynamicPricingChange = new EventEmitter<boolean>();
    @Input() ratePlan: RatePlanWithDerivedPricingModel;
    @Input() roomTypeId: number;
    @Input() roomTypesSource: PPRoomTypeModel[];
    @Output() update = new EventEmitter();

    permissionsModel: PricePlanPermissionsModel;
    loader = false;
    lessThan = true;
    theme: number;

    valForm: UntypedFormGroup;
    ratePlanRoomTypeModel = new RatePlanRoomTypeModel();
    currencyContext: CurrencyContext = new CurrencyContext();
    roomTypes: PPRoomTypeModel[] = [];

    wasUpdated: boolean;
    adjustPriceError: boolean;

    constructor(
        private fb: UntypedFormBuilder,
        private pricePlanService: PricingPlanService,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = permissionsService.createPermissionsModel(PermissionsEnum.PricePlans);

        this.valForm = this.fb.group({
            'more-less': [null, Validators.min(0)],
            'lead-time': [null, Validators.min(0)],
            change: [null],
        });
    }

    ngOnInit() {
        this.currencyContext = AuthTokenService.decodeJwtToken().Currency;
        this.roomTypes = JSON.parse(JSON.stringify(this.roomTypesSource));
        // this.roomTypes.push(new PPRoomTypeModel(-1, 'All'));
        this.ratePlanRoomTypeModel.ratePlanId = this.ratePlan.id;
        this.refreshView();
    }

    refreshView() {
        this.ratePlanRoomTypeModel.roomTypeId = this.roomTypeId;

        this.pricePlanService.getDetailsDynamicPricing(this.ratePlan.id, this.roomTypeId).subscribe(
            (response) => {
                this.ratePlanRoomTypeModel = response;
                if (this.ratePlanRoomTypeModel.dpLessThan === null) {
                    this.ratePlanRoomTypeModel.dpLessThan = 0;
                }
                if (this.ratePlanRoomTypeModel.dpDaysFrom === null) {
                    this.ratePlanRoomTypeModel.dpDaysFrom = 0;
                }
                if (this.ratePlanRoomTypeModel.dpIncreaseFor === null) {
                    this.ratePlanRoomTypeModel.dpIncreaseFor = 0;
                }

                this.ratePlanRoomTypeModel.dpLessThanHelper = this.ratePlanRoomTypeModel.dpLessThan;
                if (this.ratePlanRoomTypeModel.dpLessThanHelper < 0) {
                    this.lessThan = true;
                    this.ratePlanRoomTypeModel.dpLessThanHelper = Math.abs(this.ratePlanRoomTypeModel.dpLessThanHelper);
                } else {
                    this.lessThan = false;
                }
                if (this.ratePlanRoomTypeModel.ratePlanId === null) {
                    this.ratePlanRoomTypeModel.ratePlanId = this.ratePlan.id;
                }
                if (this.ratePlanRoomTypeModel.roomTypeId === null) {
                    this.ratePlanRoomTypeModel.roomTypeId = this.roomTypeId;
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    filterByRoomType(e) {
        if (e.itemData !== null) {
            if (this.roomTypeId !== -1) {
                this.refreshView();
            } else {
                this.ratePlanRoomTypeModel.ratePlanId = this.ratePlan.id;
                this.ratePlanRoomTypeModel.roomTypeId = this.roomTypeId;
                this.ratePlanRoomTypeModel.baseGuests = 0;
                this.ratePlanRoomTypeModel.additionalGuests = 0;
                this.ratePlanRoomTypeModel.additionalChildren = 0;
                this.ratePlanRoomTypeModel.childrenRate = 0;
                this.ratePlanRoomTypeModel.dpLessThan = 0;
                this.ratePlanRoomTypeModel.dpLessThanHelper = 0;
                this.ratePlanRoomTypeModel.dpDaysFrom = 0;
                this.ratePlanRoomTypeModel.dpIncreaseFor = 0;
            }
        }
    }

    submitForms(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        this.adjustPriceError = false;

        if (this.valForm.valid) {
            if (this.lessThan) {
                this.ratePlanRoomTypeModel.dpLessThan = -1 * this.ratePlanRoomTypeModel.dpLessThanHelper;
            } else {
                this.ratePlanRoomTypeModel.dpLessThan = this.ratePlanRoomTypeModel.dpLessThanHelper;
            }
            if (this.ratePlanRoomTypeModel.dpLessThanHelper != null && this.ratePlanRoomTypeModel.dpDaysFrom != null && this.ratePlanRoomTypeModel.dpIncreaseFor == null) {
                this.adjustPriceError = true;
                return;
            }
            this.loader = true;
            this.pricePlanService.insertDynamicPricing(this.ratePlanRoomTypeModel).subscribe(
                
                (response) => {
                    this.wasUpdated = true;
                    this.loader = false;
                },
                (error) => {
                    this.loader = false;
                }
            );
        }
    }

    close() {
        if (this.wasUpdated) {
            this.update.emit();
        }
        this.dynamicPricingChange.emit(false);
    }
}
