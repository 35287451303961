import { RatePlanModel } from '../rate-plan/rate-plan.model';

export class RateScheduleWithRatePlanModel {
    public id: number;
    public propertyId: number;
    public name: string;
    public active: boolean;
    public dateCreated: Date;
    public ratePlans: RatePlanModel[] = [];
}
