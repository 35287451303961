<div class="rate-schedules-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box rate popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2 *ngIf="addSchedule">Add Schedule</h2>
            <h2 *ngIf="editSchedule">{{ permissionsModel.formButtonEnabled ? 'Edit Schedule' : 'Schedule' }}</h2>

            <form (ngSubmit)="submitRateSchedules($event)" class="rate-schedules flex" [formGroup]="valForm">
                <div class="form-item">
                    <div class="custom-label">Name</div>
                    <input
                        class="custom-input"
                        name="rateScheduleWithRatePlanModelName"
                        formControlName="name"
                        type="text"
                        [(ngModel)]="rateScheduleWithRatePlanModel.name"
                        [disabled]="!permissionsModel.update"
                    />
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['name'].hasError('required') &&
                            (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                        "
                        >Name is required.</span
                    >
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['name'].hasError('maxlength') &&
                            (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                        "
                        >Maximum 50 characters.</span
                    >
                </div>
                <div class="form-item">
                    <div class="custom-label">Status</div>
                    <dx-select-box
                        class="custom-select"
                        [(value)]="rateScheduleWithRatePlanModel.active"
                        [dataSource]="statusModelList"
                        displayExpr="name"
                        formControlName="status"
                        valueExpr="value"
                        [disabled]="!permissionsModel.update"
                    >
                    </dx-select-box>
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['status'].hasError('required') &&
                            (valForm.controls['status'].dirty || valForm.controls['status'].touched)
                        "
                        >Status is required.</span
                    >
                </div>
                <div class="actions flex" *ngIf="editSchedule">
                    <button type="button" (click)="openRatePlans()" class="custom-button orange left">
                        Rate plans
                    </button>
                    <button type="button" (click)="openRoomTypes()" class="custom-button orange left">
                        Room Types
                    </button>
                </div>

                <button *ngIf="!loader && permissionsModel.update" type="submit" class="custom-button blue left">
                    Save
                </button>
                <div *ngIf="loader" class="loader left"></div>
                <button (click)="close()" type="button" class="custom-button red left">
                    {{ permissionsModel.update ? 'Cancel' : 'Close' }}
                </button>
            </form>
        </div>
    </div>
</div>
<app-rate-schedules-rate-plans
    *ngIf="addRatePlan"
    [(addRatePlan)]="addRatePlan"
    [rateScheduleWithRatePlan]="rateScheduleWithRatePlanModel"
    (emitListOfRatePlans)="setListOfRatePlans($event)"
></app-rate-schedules-rate-plans>
<app-rate-schedules-rate-plans
    *ngIf="editRatePlan"
    [(editRatePlan)]="editRatePlan"
    [rateScheduleWithRatePlan]="rateScheduleWithRatePlanModel"
    (emitListOfRatePlans)="setListOfRatePlans($event)"
></app-rate-schedules-rate-plans>
<app-rate-schedules-room-types
    *ngIf="roomTypes"
    [(roomTypes)]="roomTypes"
    [rateScheduleId]="rateScheduleId"
    [rateScheduleName]="rateScheduleWithRatePlanModel.name"
></app-rate-schedules-room-types>
