<div class="rate-plans-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup sale-items" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Derived Pricing - {{ derivedPricingPopupModel?.ratePlan?.name }}</h2>

            <div class="form-group">
                <div class="form-item" *ngFor="let item of derivedPricingPopupModel.options; let i = index">
                    <input
                        type="radio"
                        id="radio{{ i }}"
                        name="group"
                        class="custom-radio"
                        value="{{ item.id }}"
                        [checked]="item.selected"
                        (change)="value = item"
                        [disabled]="!permissionsModel.formButtonEnabled"
                    />
                    <label class="custom-label" for="radio{{ i }}"><span></span>{{ item.name }}</label>
                </div>

                <div class="rate-plans-content">
                    <ng-container *ngIf="value.id == 'DerivedRatePlan'">
                        <div class="derived-plan">
                            <div class="form-item short no-padding-input">
                                <div class="custom-label">Rate plans</div>
                                <dx-select-box
                                    class="custom-select"
                                    [dataSource]="derivedPricingPopupModel.ratePlans"
                                    [(value)]="derivedPricingPopupModel.ratePlan.masterPlanId"
                                    valueExpr="id"
                                    displayExpr="name"
                                    [disabled]="!permissionsModel.formButtonEnabled"
                                >
                                </dx-select-box>
                                <span class="text-danger">{{ errorMessageRatePlan }}</span>
                            </div>
                            <div class="form-item short">
                                <div class="custom-label">Offset</div>
                                <div class="flex offset-inputs">
                                    <dx-select-box
                                        [dataSource]="derivedPricingPopupModel.offsetTypes"
                                        displayExpr="name"
                                        valueExpr="id"
                                        [(value)]="derivedPricingPopupModel.ratePlan.offsetType"
                                        [disabled]="!permissionsModel.formButtonEnabled"
                                    >
                                    </dx-select-box>
                                    <input
                                        class="custom-input"
                                        type="number"
                                        name="offset"
                                        [(ngModel)]="derivedPricingPopupModel.ratePlan.offset"
                                        [disabled]="!permissionsModel.formButtonEnabled"
                                    />
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="value.id == 'DerivedRoomTypes'">
                        <dx-data-grid
                            [dataSource]="derivedPricingPopupModel.derivedPricesDetails"
                            [allowColumnResizing]="true"
                            rowTemplate="rowTemplateName"
                            [columns]="[
                                {
                                    dataField: 'masterRoomTypeId',
                                    caption: 'Controlling room type',
                                    allowSorting: true,
                                    width: 180
                                },
                                {
                                    dataField: 'slaveRoomTypeId',
                                    caption: 'Inheriting room type',
                                    allowSorting: true,
                                    width: 160
                                },
                                {},
                                { cellTemplate: 'delete', allowSorting: false, width: 60 }
                            ]"
                        >
                            <table>
                                <ng-container *dxTemplate="let row of 'rowTemplateName'">
                                    <tr>
                                        <td>
                                            <dx-select-box
                                                class="custom-select fixing-height"
                                                [dataSource]="derivedPricingPopupModel.roomTypes"
                                                [(value)]="row.data.masterRoomTypeId"
                                                valueExpr="id"
                                                displayExpr="shortName"
                                                [disabled]="!permissionsModel.formButtonEnabled"
                                            ></dx-select-box>
                                        </td>
                                        <td>
                                            <dx-select-box
                                                class="custom-select fixing-height"
                                                [dataSource]="derivedPricingPopupModel.roomTypes"
                                                [(value)]="row.data.slaveRoomTypeId"
                                                valueExpr="id"
                                                displayExpr="shortName"
                                                [disabled]="!permissionsModel.formButtonEnabled"
                                            ></dx-select-box>
                                        </td>
                                        <td>
                                            <div class="form-item fee-input flex">
                                                <dx-select-box
                                                    class="offset-select"
                                                    [dataSource]="derivedPricingPopupModel.offsetTypes"
                                                    [(value)]="row.data.offsetType"
                                                    valueExpr="id"
                                                    displayExpr="name"
                                                    [disabled]="!permissionsModel.formButtonEnabled"
                                                ></dx-select-box>
                                                <input
                                                    [(ngModel)]="row.data.offset"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    type="number"
                                                    class="custom-input offset-input"
                                                    [disabled]="!permissionsModel.formButtonEnabled"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <i *ngIf="permissionsModel.delete" title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small" (click)="deleteDeriveOption(row.data)"></i>
                                        </td>
                                    </tr>
                                </ng-container>
                            </table>

                            <dxo-paging [pageSize]="10"></dxo-paging>
                            <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="true" [showInfo]="true">
                            </dxo-pager>
                        </dx-data-grid>

                        <div (click)="addNewOption()" class="add-new-box" *ngIf="permissionsModel.formButtonEnabled">
                            <span class="text">Add New</span>
                            <div class="img">
                                <i class="fa-solid fa-plus fa-icon gray"></i>
                            </div>
                        </div>
                    </ng-container>

                    <div class="form-item">
                        <p class="text-danger first-error">{{ errorMessageRoomTypes }}</p>
                        <br />
                    </div>

                    <div class="form-item">
                        <p class="text-danger first-error">{{ errorMessageOffset }}</p>
                        <br />
                    </div>
                </div>
            </div>

            <button
                *ngIf="!loader && permissionsModel.formButtonEnabled"
                type="button"
                (click)="submitDeriveSettings(false)"
                class="custom-button blue"
            >
                Save
            </button>
            <div *ngIf="loader" class="loader left"></div>
            <button type="button" (click)="close()" class="custom-button red">
                {{ permissionsModel.formButtonEnabled ? 'Cancel' : 'Close' }}
            </button>
        </div>
    </div>
</div>

<app-alert-popup
    *ngIf="alertPopup"
    [(alertPopup)]="alertPopup"
    (emitUpdate)="submitDeriveSettings(true)"
    [message]="derivedPricingUpdateResultModel.message"
>
</app-alert-popup>
