<app-header></app-header>
<app-side-menu></app-side-menu>

<router-outlet></router-outlet>
<div
    *ngIf="loader"
    [ngClass]="[router.url.indexOf('calendar') > 0 ? 'active-calendar' : '']"
    class="overlay loader-container"
>
    <div class="loader"></div>
</div>

<!--
<div class="setting-menu" [ngClass]="{'visible': showSettings}">
  <h1>Settings</h1>
  <div class="item" routerLink="/settings/account" routerLinkActive="active">Account</div>
  <div class="item" routerLink="/settings/profile" routerLinkActive="active">Profile</div>
  &lt;!&ndash;<div class="item" routerLink="/settings/users" routerLinkActive="active">Users</div>&ndash;&gt;
  &lt;!&ndash;<div class="item" routerLink="/settings/guests" routerLinkActive="active">Guests</div>&ndash;&gt;
  &lt;!&ndash;<div class="item" routerLink="/settings/room-types" routerLinkActive="active">Room types</div>&ndash;&gt;
  &lt;!&ndash;<div class="item" routerLink="/settings/properties" routerLinkActive="active">Properties</div>&ndash;&gt;
  &lt;!&ndash;<div class="item" routerLink="/settings/assign-rooms" routerLinkActive="active">Assign rooms</div>&ndash;&gt;
  &lt;!&ndash;<div class="item" routerLink="/settings/partners" routerLinkActive="active">Partners</div>&ndash;&gt;
  &lt;!&ndash;<div class="item" (click)="toggleSubitems()" [ngClass]="{'active': expanded}">
    <i *ngIf="!expanded" class="fa fa-plus"></i>
    <i *ngIf="expanded" class="fa fa-minus"></i>
    Pricing
  </div>
  <div *ngIf="subitems">
    <div class="sub-item" routerLink="/settings/pricing/rate-plans" routerLinkActive="active">Rate plans</div>
    <div class="sub-item" routerLink="/settings/pricing/price-plans" routerLinkActive="active">Price plans</div>
    <div class="sub-item" routerLink="/settings/pricing/rate-schedules" routerLinkActive="active">Rate schedules</div>
    <div class="sub-item" routerLink="/settings/pricing/promo-codes" routerLinkActive="active">Promo codes</div>
    <div class="sub-item" routerLink="/settings/pricing/cost-centres" routerLinkActive="active">Cost centres</div>
  </div>&ndash;&gt;
  &lt;!&ndash;<div class="item" routerLink="/settings/incidental-sale-items" routerLinkActive="active">Incidental sale items</div>&ndash;&gt;
</div>
-->
