<div class="pricing-plans-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Set partner availability</h2>

            <form [formGroup]="valForm" (ngSubmit)="submitPartnerAvailability($event)">
                <div class="form-box flex">
                    <div class="form-item">
                        <div class="custom-label">Date from</div>
                        <dx-date-box
                            formControlName="dateFrom"
                            name="dateFrom"
                            [(value)]="dateFrom"
                            [max]="dateTo"
                            [displayFormat]="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            type="date"
                        >
                        </dx-date-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['dateFrom'].hasError('required') &&
                                (valForm.controls['dateFrom'].dirty || valForm.controls['dateFrom'].touched)
                            "
                            >Date from is required.</span
                        >
                    </div>

                    <div class="form-item">
                        <div class="custom-label">Date to</div>
                        <dx-date-box
                            formControlName="dateTo"
                            name="dateTo"
                            [(value)]="dateTo"
                            [min]="dateFrom"
                            [displayFormat]="'dd/MM/yyyy'"
                            type="date"
                        >
                        </dx-date-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['dateTo'].hasError('required') &&
                                (valForm.controls['dateTo'].dirty || valForm.controls['dateTo'].touched)
                            "
                            >Date to is required.</span
                        >
                    </div>

                    <div class="form-item">
                        <div class="custom-label">Rate schedule</div>
                        <dx-select-box
                            class="custom-select"
                            [(value)]="updatePartnerAvailabilityModel.rateScheduleId"
                            [dataSource]="partnerAvailabilityPopupModel.rateSchedules"
                            formControlName="rateSchedule"
                            name="rateSchedule"
                            valueExpr="id"
                            displayExpr="name"
                        >
                        </dx-select-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['rateSchedule'].hasError('required') &&
                                (valForm.controls['rateSchedule'].dirty || valForm.controls['rateSchedule'].touched)
                            "
                            >Rate schedule is required.</span
                        >
                    </div>

                    <div class="form-item">
                        <div class="custom-label">Room type</div>
                        <dx-select-box
                            class="custom-select"
                            [(value)]="updatePartnerAvailabilityModel.roomTypeId"
                            [dataSource]="partnerAvailabilityPopupModel.roomTypes"
                            formControlName="roomType"
                            name="roomType"
                            valueExpr="id"
                            displayExpr="name"
                        >
                        </dx-select-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['roomType'].hasError('required') &&
                                (valForm.controls['roomType'].dirty || valForm.controls['roomType'].touched)
                            "
                            >Room type is required.</span
                        >
                    </div>

                    <div class="form-item">
                        <div class="custom-label">Available rooms</div>
                        <input
                            class="custom-input"
                            min="0"
                            type="number"
                            name="rooms"
                            formControlName="rooms"
                            [(ngModel)]="updatePartnerAvailabilityModel.available"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['rooms'].hasError('required') &&
                                (valForm.controls['rooms'].dirty || valForm.controls['rooms'].touched)
                            "
                            >Available rooms are required.</span
                        >
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['rooms'].hasError('min') &&
                                (valForm.controls['rooms'].dirty || valForm.controls['rooms'].touched)
                            "
                            >Minimum number of available rooms is 0.</span
                        >
                    </div>
                </div>

                <button *ngIf="!loader" type="submit" class="custom-button blue left">Save</button>
                <div *ngIf="loader" class="loader left"></div>
                <button type="button" (click)="close()" class="custom-button red left">Cancel</button>
            </form>
        </div>
    </div>
</div>
