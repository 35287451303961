import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-colorpicker',
    templateUrl: 'colorpicker.component.html',
    styleUrls: ['./colorpicker.component.less'],
})
export class ColorpickerComponent implements OnInit {
    @Input() colors: String[] = [
        '#494949',
        '#fc4949',
        '#fc49fc',
        '#b687c9',
        '#fc9149',
        '#64c38c',
        '#49fcfc',
        '#c0392b',
        '#c2df24',
    ];

    @Input() activeColor: String;

    constructor() {}

    ngOnInit() {}

    @Output() onColorPicked = new EventEmitter<string>();

    public pickColor(c: string) {
        this.onColorPicked.emit(c);
    }
}
