import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-maintenance-mode-modal',
    templateUrl: './maintenance-mode-modal.component.html',
    styleUrls: ['./maintenance-mode-modal.component.less'],
})
export class MaintenanceModeModalComponent implements OnInit {
    @Input() maintenanceMode: boolean;
    @Output() maintenanceModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit() {}
}
