<!--<app-header></app-header>-->

<div class="login-wrapper flex" [ngStyle]="{ 'background-image': 'url(' + imageUrl + ')' }">
    <div class="custom-box" *ngIf="!showInfo">
        <div class="logo-img">
            <img src="../../../../assets/images/logo.svg" alt="caterbook logo" />
        </div>
        <h2 *ngIf="validPasswordResetLink">Forgotten your password?</h2>
        <h2 *ngIf="!validPasswordResetLink">Reset password link has expired.</h2>
        <p>Please enter your Account ID and username, we will send you a link to reset your password.</p>
        <form [formGroup]="valForm" (ngSubmit)="submitForgotPasswordForm($event)">
            <div class="form-item">
                <div class="custom-label">Account ID</div>
                <input
                    class="custom-input"
                    type="number"
                    min="1001"
                    name="accountID"
                    formControlName="accountID"
                    [(ngModel)]="account"
                />
                <div class="flex text-danger-wrapper">
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['accountID'].hasError('required') &&
                            (valForm.controls['accountID'].dirty || valForm.controls['accountID'].touched)
                        "
                    >
                        Account ID is required
                    </span>
                    <span
                        *ngIf="
                            showAccountError &&
                            valForm.controls['accountID'].hasError('required') &&
                            (valForm.controls['accountID'].dirty || valForm.controls['accountID'].touched)
                        "
                        class="text-danger"
                    >
                        &nbsp;and&nbsp;
                    </span>
                </div>
            </div>
            <div class="form-item">
                <div class="custom-label">Username</div>
                <input class="custom-input" [(ngModel)]="username" name="username" formControlName="username" />
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['username'].hasError('required') &&
                        (valForm.controls['username'].dirty || valForm.controls['username'].touched)
                    "
                    >Username is required</span
                >
            </div>
            <div class="actions flex">
                <button type="submit" class="custom-button blue">Confirm</button>
                <div class="custom-label inline">
                    <div class="custom-link" routerLink="/login">Back to login</div>
                </div>
            </div>
        </form>
    </div>
    <div class="confirmation-wrapper flex" *ngIf="showInfo">
        <div class="custom-box">
            <h2>Password reset</h2>
            <p>
                If your AccountID and Username matches details we have on file, an email will be sent with reset
                instructions.
            </p>
        </div>
    </div>
</div>
