import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';
import { MessageTemplateModel } from 'src/app/models/templates/message-template.model';
import { BookingDetailsService } from 'src/app/routes/booking/settings-booking-detail/booking-details.service';
import { AuthTokenService } from '../services/auth-token.service';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-message-template-preview',
    templateUrl: './message-template-preview.component.html',
    styleUrls: ['./message-template-preview.component.less'],
})
export class MessageTemplatePreviewComponent implements OnInit, AfterViewInit {
    @ViewChild('grid', { static: true }) grid: DxDataGridComponent;
    @Input() templatesPreview: boolean;
    @Output() templatesPreviewChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() selectedTemplateId: number;
    @Input() submitTitle: string;
    @Input() bookingGuestId: number;
    @Input() bookingId: number;
    @Input() headerTitle: string;
    @Output() selectedTemplate: EventEmitter<MessageTemplateModel> = new EventEmitter<MessageTemplateModel>();

    theme: number;
    rowSelection: any;
    messageTemplatesList: MessageTemplateModel[];
    selectedMessageTemplate: MessageTemplateModel[] = [];
    columnsConfig = [
        { dataField: 'name', caption: 'Name', allowSorting: true },
        { dataField: 'subject', caption: 'Subject', allowSorting: true },
        { dataField: 'message', caption: 'Message', width: 200, cellTemplate: 'message', allowSorting: true },
        { dataField: 'scheduleType', caption: 'Schedule type', allowSorting: true },
        { dataField: 'scheduleTrigger', caption: 'Schedule trigger', allowSorting: true },
    ];

    constructor(private bookingDetailsService: BookingDetailsService, private toasterService: ToastService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.rowSelection = {
            showCheckBoxesMode: 'always',
        };
    }

    ngOnInit() {
        if (!this.submitTitle) {
            this.submitTitle = 'Choose';
        }

        if (!this.headerTitle) {
            this.headerTitle = 'Please select one message template which you want to send';
        }
    }

    ngAfterViewInit() {
        this.grid.instance.beginCustomLoading('Loading...');

        this.bookingDetailsService.getMessageTemplatesForInvoice(false, this.bookingGuestId, this.bookingId).subscribe(
            (response) => {
                this.messageTemplatesList = response;
                if (this.selectedTemplateId) {
                    const selectedMessageTemplate = this.messageTemplatesList.find(
                        (x) => x.id === this.selectedTemplateId
                    );
                    if (selectedMessageTemplate) {
                        this.selectedMessageTemplate.push(selectedMessageTemplate);
                    }
                } else {
                    if (this.messageTemplatesList.length > 0) {
                        this.selectedMessageTemplate.push(this.messageTemplatesList[0]);
                    }
                }
                this.grid.instance.endCustomLoading();
            },
            (error) => {
                console.log(error);
                this.grid.instance.endCustomLoading();
            }
        );
    }

    toggleTooltip(template: MessageTemplateModel) {}

    selectionChanged(e) {
        if (e.currentDeselectedRowKeys.length > 0) {
            e.component.deselectRows(e.currentDeselectedRowKeys[0]);
        } else if (e.selectedRowKeys.length > 1) {
            e.component.deselectRows(e.selectedRowKeys[0]);
        }
    }

    submit() {
        if (this.selectedMessageTemplate.length === 0 || this.selectedMessageTemplate.length > 1) {
            this.toasterService.showToast('Warning', 'You have to select one template.', null, ToastEventType.Warning, true, 5000);
            return;
        }

        this.selectedTemplate.emit(this.selectedMessageTemplate[0]);
        this.close();
    }

    close() {
        this.templatesPreviewChange.emit(false);
    }
}
