import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { BookingDetailsService } from '../booking-details.service';
import { CommunicationService } from '../../../../shared/services/communication.service';
import { AuthLoginService } from '../../../../shared/services/auth-login.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BookingPrintModel } from '../../../../models/booking/booking-all-details.model';
import { CurrencyContext, TokenContext } from '../../../../models/auth-jwt/token-context';
import { PropertyDetailsModel } from '../../../../models/property/property-details.model';

@Component({
    selector: 'app-booking-print-receipt',
    templateUrl: './booking-print-receipt.component.html',
    styleUrls: ['./booking-print-receipt.component.less'],
})
export class BookingPrintReceiptComponent implements OnInit {
    @Output() generated = new EventEmitter();
    @ViewChild('content', { static: true }) content: ElementRef;
    propertyName: string;
    imageSafeUrl: SafeUrl;
    propertyThumbnail: string;
    showImage = false;
    receiptInfo: BookingPrintModel;
    propertyInfo: PropertyDetailsModel;
    token: TokenContext = new TokenContext();
    currentDate: string;
    isSuperUser = false;
    currencyContext: CurrencyContext = new CurrencyContext();

    constructor(
        private bookingService: BookingDetailsService,
        private communicationService: CommunicationService,
        private sanitizer: DomSanitizer,
        private authLoginService: AuthLoginService
    ) {}

    ngOnInit() {
        this.currencyContext = AuthTokenService.decodeJwtToken().Currency;
        this.receiptInfo = this.bookingService.getReceiptData();
        this.currentDate = new Date().toDateString();
        this.bookingService.getPropertyContact().subscribe(
            (response: PropertyDetailsModel) => {
                this.propertyInfo = response;
                this.setPicture();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    setPicture() {
        this.authLoginService.getPropertyInvoiceLogoBase64().subscribe(
            (response) => {
                if (response) {
                    this.propertyThumbnail = response ? response : '';
                    this.imageSafeUrl = this.sanitizer.bypassSecurityTrustUrl(
                        'data:image/jpeg;base64,' + this.propertyThumbnail
                    );
                    this.showImage = true;
                    setTimeout(() => {
                        this.generated.emit(this.content.nativeElement);
                    }, 200);
                } else {
                    this.propertyThumbnail = '';
                    this.showImage = true;
                    setTimeout(() => {
                        this.generated.emit(this.content.nativeElement);
                    }, 200);
                }
            },
            (error) => {
                this.showImage = false;
                console.log(error);
            }
        );
        // }
        // else {

        // }
    }
}
