import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NewFunctionService } from 'src/app/routes/function/settings-new-function/new-function.service';
import { AuthTokenService } from 'src/app/shared/services/auth-token.service';
import { TokenContext } from 'src/app/models/auth-jwt/token-context';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ChangeDetectorRef } from '@angular/core';
import { EnumModel } from 'src/app/models/shared/enum-model';
import { CommunicationService } from 'src/app/shared/services/communication.service';
import { DxSelectBoxComponent, DxDateBoxComponent } from 'devextreme-angular';
import {
  BookingPaymentPDModel,
  FinanceAccountModel,
  BookingPaymentHybridSourceModel,
} from 'src/app//models/booking/booking-payment-pd.model';
import { FunctionPaymentPDModel, FunctionPaymentHybridSourceModel } from 'src/app/models/function-room/function-payment-pd.model';
import { CardListModel } from 'src/app//models/booking/booking-details.model';
import { InvoiceModel } from 'src/app/models/booking/invoice.model';
import { PciFunctionDetailModel } from 'src/app/models/booking/pci-booking-detail.model';
import { PaymentActionType } from 'src/app/enums/payment-action-type.enum';
import { FunctionPaymentModel } from 'src/app/models/function-room/function-flow.model';

@Component({
  selector: 'app-settings-function-details-payment',
  templateUrl: './settings-function-details-payment.component.html',
  styleUrls: ['./settings-function-details-payment.component.less']
})
export class SettingsFunctionDetailsPaymentComponent implements OnInit {
  @Input() functionPayment: boolean;
  @Output() functionPaymentChange = new EventEmitter<boolean>();

  @Input() editFunctionPayment: boolean;
  @Output() editFunctionPaymentChange = new EventEmitter<boolean>();

  @Input() functionId: number;
  @Input() functionPaymentId: number;
  @Input() firstDate: Date;
  @Output() update = new EventEmitter();
  @Output() reloadFunctionDetails = new EventEmitter();

  @Input() newPaymentActionType: PaymentActionType;
  @Input() newPaymentSourceData: FunctionPaymentModel;

  @ViewChild('paymentType', { static: true }) paymentType: DxSelectBoxComponent;

  showPaymentFormModal = false;
  showCardsListModal = false;
  loader = false;
  balance = false;
  disabledPaymentControl = false;
  paymentWarningMessage = '';
  toShowMiscRefundWithWarning = false;
  showMiscRefundWithWarning = false;

  pciBookingForm: string;
  caterpayForm: string;
  paidBy: number;
  theme: number;
  invoiceBalance = 0;
  refundAmount: number = 20000;

  valForm: UntypedFormGroup;
  token: TokenContext;
  filteredPaymentPurposes: EnumModel[] = [];
  filteredPaymentHybridTypes: FunctionPaymentHybridSourceModel[] = [];
  functionPaymentPDModel = new FunctionPaymentPDModel();
  cardListModel: CardListModel = new CardListModel();
  showTypeListSelect = true;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private newFunctionService: NewFunctionService,
    private sanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef,
    private communicationService: CommunicationService
  ) { 
    this.token = AuthTokenService.decodeJwtToken();
    this.theme = this.token.User.CmsTheme;

    this.valForm = fb.group({
        paidBy: [null, Validators.required],
        type: [null, Validators.required],
        amount: [null, Validators.compose([Validators.min(0), Validators.required])],
        invoice: [null],
        note: [null],
        paymentDate: [new Date(), Validators.required],
        purpose: [null, Validators.required],
    });

    window.addEventListener('message', this.postMessageHandlerFunctionPayment.bind(this), false);
  }

  ngOnInit(): void {
    if (this.functionPayment) {
      this.newFunctionService.newPayment(this.functionId, this.newPaymentActionType, this.newPaymentSourceData).subscribe(
          (response) => {
              this.functionPaymentPDModel = response;
              this.setNewPaymentFilterDefaults();
          },
          (error) => {}
      );
    } else if (this.editFunctionPayment) {
        this.newFunctionService.editPayment(this.functionId, this.functionPaymentId).subscribe(
            (response) => {
                this.functionPaymentPDModel = response;
                this.valForm.controls['type'].setValue(this.functionPaymentPDModel.payment.paymentType);
                //this.paymentType.value = this.functionPaymentPDModel.payment.paymentType;
                this.filteredPaymentPurposes = this.functionPaymentPDModel.paymentPurposes.filter(
                    (x) => x.value !== 'Tokenise' && x.value !== 'Verify'
                );
                this.functionPaymentPDModel.payment.amount =
                    this.functionPaymentPDModel.payment.paymentPurpose === 'Refund'
                        ? Math.abs(this.functionPaymentPDModel.payment.amount)
                        : this.functionPaymentPDModel.payment.amount;
                this.paidBy = this.functionPaymentPDModel.payment.paidBy;
                this.filteredPaymentHybridTypes = this.functionPaymentPDModel.paymentHybridTypes.filter((x) =>
                    this.functionPaymentPDModel.payment.paymentPurpose === 'Refund'
                        ? x.source === 'Payment'
                        : x.source === 'Enum'
                );
                this.valForm.controls['purpose'].setValue(this.functionPaymentPDModel.payment.paymentPurpose);
            },
            (error) => {
                this.closePopup();
            }
        );
    }
  }

  setNewPaymentFilterDefaults() {
    this.functionPaymentPDModel.payment.paymentDate = new Date();
    this.functionPaymentPDModel.payment.paymentType = null;
    this.functionPaymentPDModel.payment.paymentPurpose = null;
    if (this.functionPaymentPDModel.paidByList.length > 0) {
        this.setPaidByValue(this.functionPaymentPDModel.paidByList[0]);
        this.paidBy = this.functionPaymentPDModel.paidByList[0].accountId;
    }
    this.setPaymentPurpose();
    if (this.filteredPaymentPurposes.length == 1) {
        this.functionPaymentPDModel.payment.paymentPurpose = this.filteredPaymentPurposes[0].value;
    }
    else if (this.newPaymentActionType == PaymentActionType.Rebill || this.newPaymentActionType == PaymentActionType.ChargeToken) {
        //if before arrival then deposit else balance
        let dtNow = new Date();
        if (dtNow < this.firstDate) {
            this.functionPaymentPDModel.payment.paymentPurpose = "Deposit";
        }
        else {
            this.functionPaymentPDModel.payment.paymentPurpose = "Balance";
        }
    }

    this.filteredPaymentHybridTypes = this.functionPaymentPDModel.paymentHybridTypes.filter(
        (x) =>
            (x.source === 'Payment' && x.paidBy === this.functionPaymentPDModel.payment.paidBy) ||
            x.source !== 'Payment'
    );

    if (this.filteredPaymentHybridTypes.length == 1) {
        this.valForm.controls['type'].setValue(this.filteredPaymentHybridTypes[0]);
        this.checkPaymentType({itemData: this.filteredPaymentHybridTypes[0]});

        if (this.newPaymentActionType == PaymentActionType.Refund) {
            this.showTypeListSelect = false;
        }
    }
  }

  setPaymentPurpose() {
    if (this.functionPaymentPDModel.paymentHybridTypes.filter((x) => x.source === 'Payment').length > 0) {
        this.filteredPaymentPurposes = this.functionPaymentPDModel.paymentPurposes.filter(
            (x) => x.value !== 'Tokenise' && x.value !== 'Verify'
        );
    } else {
        this.filteredPaymentPurposes = this.functionPaymentPDModel.paymentPurposes.filter(
            (x) => x.value !== 'Tokenise' && x.value !== 'Verify' && x.value !== 'Refund'
        );
    }
  }

  setPaidByValueDevex(e) {
    if (e.itemData !== null) {
      const financeAccount: FinanceAccountModel = e.itemData;
      this.setPaidByValue(financeAccount);
      this.resetPaymentPurposeType();
      this.setPaymentPurpose();
      this.resetPurposeState();
    }
  }

  setPaidByValue(financeAccount: FinanceAccountModel) {
      this.functionPaymentPDModel.payment.paidBy = financeAccount.accountId;
  }

  showMiscRefundWarning() {
    this.showMiscRefundWithWarning = true;
  }

  getInvoiceBalance(e) {
    this.balance = true;
    if (e.itemData !== null) {
        const invoice: InvoiceModel = e.itemData;
        this.newFunctionService.getInvoiceBalance(invoice.id).subscribe(
            (response) => {
                this.loader = false;
                this.invoiceBalance = response;
            },
            (error) => {
                this.loader = false;
            }
        );
    }
  }

  checkPaymentType(e: any) {
    const selectedPaymentType: BookingPaymentHybridSourceModel = e.itemData;

    if (!selectedPaymentType) {
        this.functionPaymentPDModel.payment.paymentType = null;
        // this.bookingPaymentPDModel.payment.paymentPurpose = null;
        this.filterPurposeSourceOnNewPayment();
        this.valForm.controls['purpose'].enable();
        this.valForm.controls['amount'].enable();
        this.valForm.controls['type'].markAsUntouched();
        return;
    }

    this.functionPaymentPDModel.payment.paymentType = selectedPaymentType.type;
    this.functionPaymentPDModel.payment.functionPaymentId = null;
    this.toShowMiscRefundWithWarning = false;

    if (selectedPaymentType.type === 'Verify' || selectedPaymentType.type === 'VerifyToken') {
        this.filteredPaymentPurposes = this.functionPaymentPDModel.paymentPurposes.filter(
            (x) => x.value === 'Verify'
        );
        this.valForm.controls['purpose'].disable();
        this.valForm.controls['amount'].disable();
        this.functionPaymentPDModel.payment.paymentPurpose = 'Verify';
        this.functionPaymentPDModel.payment.amount = 0;
    } else if (selectedPaymentType.type === 'Tokenise') {
        this.filteredPaymentPurposes = this.functionPaymentPDModel.paymentPurposes.filter(
            (x) => x.value === 'Tokenise'
        );
        this.valForm.controls['purpose'].disable();
        this.valForm.controls['amount'].disable();
        this.functionPaymentPDModel.payment.paymentPurpose = 'Tokenise';
        this.functionPaymentPDModel.payment.amount = 0;
    } else {
        if (selectedPaymentType.source === 'Payment') {
            this.filteredPaymentPurposes = this.functionPaymentPDModel.paymentPurposes.filter(
                (x) => x.value === 'Refund'
            );
            this.functionPaymentPDModel.payment.functionPaymentId = selectedPaymentType.id;
            this.functionPaymentPDModel.payment.paymentPurpose = 'Refund';
            this.functionPaymentPDModel.payment.amount = selectedPaymentType.amount;
            //this.refundAmount = selectedPaymentType.amount;

            if (selectedPaymentType.invoiceId) {
                this.disabledPaymentControl = true;
                this.paymentWarningMessage =
                    'You are trying to refund a payment that is already part of an invoice. You should first unlink payment from an invoice.';
                this.valForm.controls['purpose'].disable();
                this.valForm.controls['amount'].disable();
                this.valForm.controls['paidBy'].disable();
                this.valForm.controls['invoice'].disable();
                this.valForm.controls['paymentDate'].disable();
                this.valForm.controls['note'].disable();
                this.valForm.controls['amount'].disable();
            } else {
                this.valForm.controls['purpose'].enable();
                this.valForm.controls['amount'].enable();
                this.valForm.controls['paidBy'].enable();
                this.valForm.controls['invoice'].enable();
                this.valForm.controls['paymentDate'].enable();
                this.valForm.controls['note'].enable();
                this.valForm.controls['amount'].enable();
                this.disabledPaymentControl = false;
                this.paymentWarningMessage = '';
            }

            if (
                selectedPaymentType.description === 'Miscellaneous Refund' &&
                this.filteredPaymentHybridTypes.some((x) => !!x.invoiceId)
            ) {
                this.toShowMiscRefundWithWarning = true;
            }
        } else {
            this.valForm.controls['purpose'].enable();
            this.valForm.controls['amount'].enable();
            this.filteredPaymentPurposes = this.functionPaymentPDModel.paymentPurposes.filter(
                (x) => x.value !== 'Tokenise' && x.value !== 'Verify' && x.value !== 'Refund'
            );
            const purpose = this.filteredPaymentPurposes.find(
                (x) => x.value === this.functionPaymentPDModel.payment.paymentPurpose
            );
            if (!purpose) {
                this.functionPaymentPDModel.payment.paymentPurpose = null;
            }
            // this.valForm.controls['purpose'].markAsUntouched();
        }
    }
  }

  filterPurposeSourceOnNewPayment() {
    if (this.functionPaymentPDModel.paymentHybridTypes.filter((x) => x.source === 'Payment').length > 0) {
        this.filteredPaymentPurposes = this.functionPaymentPDModel.paymentPurposes.filter(
            (x) => x.value !== 'Tokenise' && x.value !== 'Verify'
        );
    } else {
        this.filteredPaymentPurposes = this.functionPaymentPDModel.paymentPurposes.filter(
            (x) => x.value !== 'Tokenise' && x.value !== 'Verify' && x.value !== 'Refund'
        );
    }
  }

  checkPurposeType(e: any) {
    const paidBy = this.functionPaymentPDModel.payment.paidBy;
    this.filteredPaymentHybridTypes =
        e.itemData.name === 'Refund'
            ? this.functionPaymentPDModel.paymentHybridTypes.filter(
                  (x) => x.source === 'Payment' && x.paidBy === paidBy
              )
            : this.functionPaymentPDModel.paymentHybridTypes.filter((x) => x.source !== 'Payment');
    this.toShowMiscRefundWithWarning = false;
    if (this.filteredPaymentHybridTypes.length == 1) {
        this.valForm.controls['type'].setValue(this.filteredPaymentHybridTypes[0]);
        this.checkPaymentType({itemData: this.filteredPaymentHybridTypes[0]});
    }
  }

  resetPaymentPurposeType() {
    const paidBy = this.functionPaymentPDModel.payment.paidBy;
    this.functionPaymentPDModel.payment.paymentPurpose = null;
    this.functionPaymentPDModel.payment.amount = null;
    this.setPaymentPurpose();
    this.filteredPaymentHybridTypes =
        this.functionPaymentPDModel.payment.paymentPurpose === 'Refund'
            ? this.functionPaymentPDModel.paymentHybridTypes.filter(
                  (x) => x.source === 'Payment' && x.paidBy === paidBy
              )
            : this.functionPaymentPDModel.paymentHybridTypes.filter((x) => x.source !== 'Payment');
  }

  resetPurposeState() {
    this.valForm.controls['purpose'].enable();
    this.valForm.controls['purpose'].markAsPristine();
    this.valForm.controls['purpose'].markAsUntouched();

    this.valForm.controls['amount'].enable();
    this.valForm.controls['amount'].markAsPristine();
  }

  submitFunctionPayment(e) {
    e && e.stopPropagation();

    for (const c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
    }

    if (!this.valForm.valid) {
        return;
    }

    this.loader = true;
    this.functionPaymentPDModel.payment.functionId = this.functionId;
    this.functionPaymentPDModel.payment.propertyId = this.token.Property.Id;
    this.functionPaymentPDModel.payment.userId = this.token.IsSuperUser ? null : this.token.User.Id;
    this.functionPaymentPDModel.payment.superUserId = this.token.IsSuperUser ? this.token.User.Id : null;

    if (this.functionPaymentPDModel.payment.paymentPurpose === 'Refund') {
        if (this.functionPaymentPDModel.payment.amount > this.refundAmount) {
            this.loader = false;
            return;
        }
        if (this.functionPayment) {
            this.insertPayment();
        } else if (this.editFunctionPayment) {
            this.updatePayment();
        }

        return;
    }

    switch (this.functionPaymentPDModel.payment.paymentType) {
        case 'CaterPay':
        case 'Verify':
            this.newFunctionService
                .getCaterpayHtmlFormForNewPayment(this.functionPaymentPDModel.payment)
                .subscribe(
                    (htmlForm: string) => {
                        this.loader = false;
                        this.caterpayForm = htmlForm;
                        this.showPaymentFormModal = true;
                        const body = document.getElementsByTagName('body')[0];
                        body.classList.add('noscroll');
                    },
                    (error) => {
                        this.loader = false;
                    }
                );
            break;
        case 'ChargeToken':
        case 'VerifyToken':
        case 'Rebill':
            this.newFunctionService.getCardList(this.functionPaymentPDModel.payment).subscribe(
                (response: CardListModel) => {
                    const guestId = this.functionPaymentPDModel.payment.guestId;
                    this.loader = false;                    
                    this.cardListModel = {
                        ...response,
                        cards: response.cards.filter((c) => c.paidBy === String(this.paidBy) || c.cardSource === 'PciBooking'),
                    };
                    this.showCardsListModal = true;
                },
                (error) => {
                    this.loader = false;
                }
            );
            break;
        case 'Tokenise':
            this.newFunctionService.getPciCardEntryForm().subscribe(
                (response) => {
                    this.loader = false;
                    this.pciBookingForm = response;
                    this.showPaymentFormModal = true;
                    const body = document.getElementsByTagName('body')[0];
                    body.classList.add('noscroll');
                },
                (error) => {
                    this.loader = false;
                }
            );
            break;
        default:
            if (this.functionPayment) {
                this.insertPayment();
            } else if (this.editFunctionPayment) {
                this.updatePayment();
            }
            break;
    }
  }

  insertPayment() {
    this.newFunctionService.insertPayment(this.functionPaymentPDModel.payment).subscribe(
        (response) => {
            this.loader = false;
            console.log('insertPayment');
            this.update.emit();
            this.closePopup();
        },
        (error) => {
            this.loader = false;
            this.reloadFunctionDetailsFromPayment();
        }
    );
  }

  updatePayment() {
    this.newFunctionService.updatePayment(this.functionPaymentPDModel.payment).subscribe(
        (response) => {
            this.loader = false;
            this.update.emit();
            this.closePopup();
        },
        (error) => {
            this.loader = false;
            this.reloadFunctionDetailsFromPayment();
        }
    );
  }

  postMessageHandlerFunctionPayment = (event: any) => {
    if (!event.data.functionId && !event.data.paymentNo && !event.data.token && !event.data.cardMask) {
        this.communicationService.closePaymentFormModal.next(true);
        return;
    }

    console.log('postMessageHandlerFunctionPayment', event);

    const functionId: string = event.data.functionId;
    const paymentNo: string = event.data.paymentNo;
    const token: string = event.data.token;
    const cardMask: string = event.data.cardMask;

    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('noscroll');

    if (token && cardMask) {
        const pciBookingDetailModel: PciFunctionDetailModel = new PciFunctionDetailModel();
        pciBookingDetailModel.functionPayment = this.functionPaymentPDModel.payment;
        pciBookingDetailModel.pciBookingToken = token;
        pciBookingDetailModel.cardMask = cardMask;
  
        this.newFunctionService.pciNewPayment(pciBookingDetailModel).subscribe(
            (paymentNoFromResponse) => {
                this.communicationService.closePaymentFormModal.next(true);
                if (paymentNoFromResponse) {
                    this.update.emit();
                    this.closePopup();
                }
            },
            (err) => {
                this.communicationService.closePaymentFormModal.next(true);
            }
        );
    } else {
        this.communicationService.closePaymentFormModal.next(true);
        if (paymentNo) {
            this.update.emit();
            this.closePopup();
        }
    }
  }

  closePopup() {
    this.functionPaymentChange.emit(false);
    this.editFunctionPaymentChange.emit(false);
  }

  reloadFunctionDetailsFromPayment() {
    this.reloadFunctionDetails.emit();
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.postMessageHandlerFunctionPayment, false);
  }

  displayInvoiceLabel(item) {
      if (item && item.invoiceNo) {
          return `${item.invoiceNo} / ${item.customerName}`;
      }
      return '';
  }

}
