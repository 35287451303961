<div class="partners-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>XML Preview</h2>

            <div class="quoter-box">
                <ul>
                    <li *ngIf="otaConnectionName">
                        OTA Connection: <span class="blue-letters">{{ otaConnectionName }}</span>
                    </li>
                    <li *ngIf="storageLog?.otaRef">
                        OTA Ref: <span class="blue-letters ota-ref">{{ storageLog?.otaRef }}</span>
                    </li>
                    <li *ngIf="storageLog?.bookingId">
                        Booking:
                        <span class="blue-letters pointer" (click)="redirectToBooking()"
                            >#{{ storageLog?.bookingRef }}</span
                        >
                    </li>
                    <li>
                        Status:
                        <span
                            class="blue-letters"
                            [ngClass]="[
                                storageLog?.status == 'Failed' ? 'failed-status' : '',
                                storageLog?.status == 'Warning' ? 'warning-status' : ''
                            ]"
                        >
                            {{ storageLog?.status }}
                        </span>
                    </li>
                    <li>
                        <ng-container *ngIf="xmlViewSyncIn || xmlViewBookingRecord">Time of import: </ng-container>
                        <ng-container *ngIf="xmlViewSyncOut">Time of export: </ng-container>
                        <span class="blue-letters">{{ storageLog?.dateCreated | datex: 'DD/MM/YYYY HH:mm' }}</span>
                    </li>
                </ul>
            </div>

            <textarea name="xml-view" class="custom-textarea xml-view" readonly [ngModel]="xmlResponse"></textarea>

            <button (click)="close()" type="button" class="custom-button red left">Close</button>
        </div>
    </div>
</div>
