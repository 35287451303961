<div class="xero-wrapper flex">
    <div class="custom-box tabs" *ngIf="token.Property.Xero" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        
        <div><img src="../../../../assets/images/xero_logo.png" class="mb-10" width="80px" alt="Xero" /></div>
  
        <div class="tab" (click)="changeTab(1)" [ngClass]="classTab(1)">1. Authorisation</div>
        <div class="tab" (click)="changeTab(2)" [ngClass]="classTab(2)">2. Settings</div>
        <div class="tab" (click)="changeTab(3)" [ngClass]="classTab(3)">3. Chart of accounts mapping</div>
        <div class="tab" (click)="changeTab(4)" [ngClass]="classTab(4)">4. Tax rate mapping</div>
        <div class="tab" *ngIf="1==0" (click)="changeTab(5)" [ngClass]="classTab(5)">5. Tracking category mapping</div>
        <div class="tab" (click)="changeTab(6)" [ngClass]="classTab(6)">5. Logs</div>

        <div class="search-holder custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">

            <div *ngIf="activeTab == 1" class="general">
                <i class="fas fa-sync sync" (click)="getXeroConfiguration()"></i>
                    <div class="actions ">
                        <div class="flex">
                            <div class="w-200 authorisation-status">Current authorisation status:</div>
                            <div class="w-200 authorisation-status">{{configurationModel.xeroAuthorisationStatus}}</div>
                            <div class="w-10p"><i *ngIf="configurationModel.xeroAuthorisationStatus=='Authorised'" class="fas fa-check green"></i><i *ngIf="configurationModel.xeroAuthorisationStatus!='Authorised'" class="fas fa-times red"></i></div>
                        </div>
                        
                        <div class="flex">
                            <div class="w-200 setting-status">Current settings status:</div>
                            <div class="w-200 setting-status">{{configurationModel.xeroSettingsStatus}}</div>
                            <div class="w-10p"><i *ngIf="configurationModel.xeroSettingsStatus=='Settings Configured'" class="fas fa-check green"></i><i *ngIf="configurationModel.xeroSettingsStatus!='Settings Configured'" class="fas fa-times red"></i></div>
                        </div>

                        <div class="flex">
                            <div class="w-200 mapping-status">Current mapping status:</div>
                            <div class="w-200 mapping-status">{{configurationModel.xeroMappingValidation?.statusText}}</div>
                            <div class="w-10p"><i *ngIf="configurationModel.xeroMappingValidation?.status=='FullyConfigured'" class="fas fa-check green"></i><i *ngIf="configurationModel.xeroMappingValidation?.status!='FullyConfigured'" class="fas fa-times red"></i></div>
                            <div class="w-30p" *ngIf="tenantSet && configurationModel.xeroMappingValidation?.status!='FullyConfigured'">
                                <ul>
                                    <li *ngFor="let issue of configurationModel.xeroMappingValidation?.issues">{{issue.issueDescription}}</li>
                                </ul>
                            </div>
                        </div>

                        <div class="buttons-wrapper mt-50">
                            <button
                                type="button"
                                class="custom-button blue"
                                (click)="startXeroAuthorisation()"
                            >
                                Start Authorisation Request
                            </button>

                            <button
                                type="button"
                                class="custom-button red"
                                (click)="stopXeroAuthorisation()"
                                >
                                Cancel <br /> Authorisation
                            </button>
                        </div>
                    </div>

            </div>

            <div *ngIf="activeTab == 2" class="general">

                <dx-data-grid
                    [dataSource]="propertySettings"
                    class="settings-grid"
                    (onCellClick)="cellClick($event)"
                    [columns]="[
                        {
                            dataField: 'keySetting.desc',
                            caption: 'Description',
                            cellTemplate: 'key',
                            allowSorting: true,
                            width: 350
                        },
                        { dataField: 'value', cellTemplate: 'value', caption: 'Value', allowSorting: true }
                    ]"
                >
                    <div class="name" *dxTemplate="let n of 'key'">
                        <i class="fa-solid fa-link fa-icon fa-icon-xsmall blue link-icon"></i>
                        {{ n.data.keySetting.desc }}
                    </div>

                    <div *dxTemplate="let n of 'value'">
                        <ng-container *ngIf="n.data.key.toLowerCase().indexOf('password') > -1 && n.data.keySetting.type != 2"
                            >* * * * * * * * *</ng-container
                        >
                        <ng-container *ngIf="n.data.keySetting.type == 2"
                            >{{n.data.value=="1" ? "Yes" : "No"}}</ng-container
                        >
                        <ng-container *ngIf="n.data.key.toLowerCase().indexOf('password') == -1 && n.data.keySetting.type != 2">{{
                            n.data.value
                        }}</ng-container>
                    </div>
                    <dxo-scrolling mode="virtual"></dxo-scrolling>
                </dx-data-grid>

                
            </div>

            <div *ngIf="activeTab == 3" class="general">
                <i class="fas fa-sync sync" (click)="createXeroConfiguration()"></i>
                    <dx-data-grid
                    class="room-type-grid"
                    [dataSource]="saleItemAccountMappings"
                    rowTemplate="rowTemplateSalesItems"
                    [columnAutoWidth]="false"
                    [columns]="[
                        {
                            dataField: 'caterbookValue',
                            alignment: 'left',
                            caption: 'Sale items',
                            width: '350px'
                        },
                        {
                            dataField: 'xeroId',
                            alignment: 'left',
                            caption: 'Xero account code'
                        },
                    ]"
                    >
                        <tbody *dxTemplate="let saleItem of 'rowTemplateSalesItems'">
                            <tr>
                                <td class="mapping-value-cell">
                                    <div>
                                        {{saleItem.data.caterbookValue}}
                                    </div>
                                </td>
                                <td class="mapping-cell">
                                    <div>
                                        <dx-select-box
                                            [ngClass]="itemMappingValid(saleItem.data)"
                                            class="sm-padding"
                                            displayExpr="codeAndName"
                                            valueExpr="accountId"
                                            [dataSource]="configurationModel.xeroAccountsWithIgnore"
                                            (onItemClick)="setMapping($event, saleItem.data, 1)"
                                            [(value)]="saleItem.data.xeroId"
                                        >
                                        </dx-select-box>
                                    </div>
                                </td>
                                <td class="clear-cell">
                                    <i class="fas fa-times-circle small-icon" *ngIf="saleItem.data.xeroId != null" (click)="clearMapping($event, saleItem.data, 1)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </dx-data-grid>

                    <dx-data-grid
                    *ngIf="usingEPOSCategoryGroups==true"
                    class="room-type-grid"
                    [dataSource]="trackingCategoryMappings"
                    rowTemplate="rowTemplateSalesItems"
                    [columnAutoWidth]="false"
                    [showColumnHeaders]="true"
                    [columns]="[
                        {
                            dataField: 'caterbookValue',
                            alignment: 'left',
                            caption: 'EPOS categories',
                            width: '350px'
                        },
                        {
                            dataField: 'xeroId',
                            alignment: 'left',
                            caption: 'Xero account code'
                        }
                    ]"
                    >
                        <tbody *dxTemplate="let saleItem of 'rowTemplateSalesItems'">
                            <tr>
                                <td class="mapping-value-cell">
                                    <div>
                                        {{saleItem.data.caterbookValue}}
                                    </div>
                                </td>
                                <td class="mapping-cell">
                                    <div>
                                        <dx-select-box
                                            [ngClass]="itemMappingValid(saleItem.data)"
                                            class="sm-padding"
                                            displayExpr="codeAndName"
                                            valueExpr="accountId"
                                            [dataSource]="configurationModel.xeroAccountsWithIgnore"
                                            (onItemClick)="setMapping($event, saleItem.data, 1)"
                                            [(value)]="saleItem.data.xeroId"
                                        >
                                        </dx-select-box>
                                    </div>
                                </td>
                                <td class="clear-cell">
                                    <i class="fas fa-times-circle small-icon" *ngIf="saleItem.data.xeroId != null" (click)="clearMapping($event, saleItem.data, 1)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </dx-data-grid>
    
                    <dx-data-grid
                    *ngIf="usingEPOSCategoryGroups==false"
                    class="room-type-grid"
                    [dataSource]="trackingCategoryMappings"
                    rowTemplate="rowTemplateSalesItems"
                    [columnAutoWidth]="false"
                    [showColumnHeaders]="true"
                    [columns]="[
                        {
                            dataField: 'caterbookValue',
                            alignment: 'left',
                            caption: 'EPOS categories',
                            width: '350px'
                        },
                        {
                            dataField: 'xeroId',
                            alignment: 'left',
                            caption: 'Xero account code'
                        }
                    ]"
                    >
                        <tbody *dxTemplate="let saleItem of 'rowTemplateSalesItems'">
                            <tr>
                                <td class="mapping-value-cell">
                                    <div>
                                        {{saleItem.data.caterbookValue}}
                                    </div>
                                </td>
                                <td class="mapping-cell">
                                    <div>
                                        <dx-select-box
                                            [ngClass]="itemMappingValid(saleItem.data)"
                                            class="sm-padding"
                                            displayExpr="codeAndName"
                                            valueExpr="accountId"
                                            [dataSource]="configurationModel.xeroAccountsWithIgnore"
                                            (onItemClick)="setMapping($event, saleItem.data, 1)"
                                            [(value)]="saleItem.data.xeroId"
                                        >
                                        </dx-select-box>
                                    </div>
                                </td>
                                <td class="clear-cell">
                                    <i class="fas fa-times-circle small-icon" *ngIf="saleItem.data.xeroId != null" (click)="clearMapping($event, saleItem.data, 1)"></i>
                                </td>       
                            </tr>
                        </tbody>
                    </dx-data-grid>

                    <dx-data-grid
                    class="room-type-grid"
                    [dataSource]="transactionSubcategoryAccountMappings"
                    rowTemplate="rowTemplateSalesItems"
                    [columnAutoWidth]="false"
                    [showColumnHeaders]="true"
                    [columns]="[
                        {
                            dataField: 'caterbookValue',
                            alignment: 'left',
                            caption: 'EPOS Additional Items',
                            width: '350px'
                        },
                        {
                            dataField: 'xeroId',
                            alignment: 'left',
                            caption: 'Xero account code'
                        }
                    ]"
                    >
                        <tbody *dxTemplate="let saleItem of 'rowTemplateSalesItems'">
                            <tr>
                                <td class="mapping-value-cell">
                                    <div>
                                        {{saleItem.data.caterbookValue}}

                                    </div>
                                </td>
                                <td class="mapping-cell">
                                    <div>
                                        <dx-select-box
                                            [ngClass]="itemMappingValid(saleItem.data)"
                                            class="sm-padding"
                                            displayExpr="codeAndName"
                                            valueExpr="accountId"
                                            [dataSource]="configurationModel.xeroAccountsWithIgnore"
                                            (onItemClick)="setMapping($event, saleItem.data, 1)"
                                            [(value)]="saleItem.data.xeroId"
                                        >
                                        </dx-select-box>
                                    </div>
                                </td>
                                <td class="clear-cell">
                                    <i class="fas fa-times-circle small-icon" *ngIf="saleItem.data.xeroId != null" (click)="clearMapping($event, saleItem.data, 1)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </dx-data-grid>

                    <hr />

                    <dx-data-grid
                    class="room-type-grid"
                    [dataSource]="paymentItemAccountMappings"
                    rowTemplate="rowTemplateSalesItems"
                    [columnAutoWidth]="false"
                    [columns]="[
                        {
                            dataField: 'caterbookValue',
                            alignment: 'left',
                            caption: 'Caterbook payments',
                            width: '350px'
                        },
                        {
                            dataField: 'xeroId',
                            alignment: 'left',
                            caption: 'Xero account code'
                        }
                    ]"
                    >
                        <tbody *dxTemplate="let saleItem of 'rowTemplateSalesItems'">
                            <tr>
                                <td class="mapping-value-cell">
                                    <div>
                                        {{saleItem.data.caterbookValue}}

                                    </div>
                                </td>
                                <td class="mapping-cell">
                                    <div>
                                        <dx-select-box
                                            [ngClass]="itemMappingValid(saleItem.data)"
                                            class="sm-padding"
                                            displayExpr="codeAndName"
                                            valueExpr="accountId"
                                            [dataSource]="configurationModel.xeroAccountsWithIgnore"
                                            (onItemClick)="setMapping($event, saleItem.data, 1)"
                                            [(value)]="saleItem.data.xeroId"
                                        >
                                        </dx-select-box>
                                    </div>
                                </td>
                                <td class="clear-cell">
                                    <i class="fas fa-times-circle small-icon" *ngIf="saleItem.data.xeroId != null" (click)="clearMapping($event, saleItem.data, 1)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </dx-data-grid>

                    <hr />
                    <dx-data-grid
                    class="room-type-grid"
                    [dataSource]="eposPaymentItemAccountMappings"
                    rowTemplate="rowTemplateSalesItems"
                    [columnAutoWidth]="false"
                    [columns]="[
                        {
                            dataField: 'caterbookValue',
                            alignment: 'left',
                            caption: 'EPOS Payments',
                            width: '350px'
                        },
                        {
                            dataField: 'xeroId',
                            alignment: 'left',
                            caption: 'Xero account code'
                        }
                    ]"
                    >
                        <tbody *dxTemplate="let saleItem of 'rowTemplateSalesItems'">
                            <tr>
                                <td class="mapping-value-cell">
                                    <div>
                                        {{saleItem.data.caterbookValue}}

                                    </div>
                                </td>
                                <td class="mapping-cell">
                                    <div>
                                        <dx-select-box
                                            [ngClass]="itemMappingValid(saleItem.data)"
                                            class="sm-padding"
                                            displayExpr="codeAndName"
                                            valueExpr="accountId"
                                            [dataSource]="configurationModel.xeroAccountsWithIgnore"
                                            (onItemClick)="setMapping($event, saleItem.data, 1)"
                                            [(value)]="saleItem.data.xeroId"
                                        >
                                        </dx-select-box>
                                    </div>
                                </td>
                                <td class="clear-cell">
                                    <i class="fas fa-times-circle small-icon" *ngIf="saleItem.data.xeroId != null" (click)="clearMapping($event, saleItem.data, 1)"></i>
                                </td>         
                            </tr>
                        </tbody>
                    </dx-data-grid>

                    <hr />

                    <dx-data-grid
                    class="room-type-grid"
                    [dataSource]="miscItemAccountMappings"
                    rowTemplate="rowTemplateSalesItems"
                    [columnAutoWidth]="false"
                    [columns]="[
                        {
                            dataField: 'caterbookValue',
                            alignment: 'left',
                            caption: 'Miscellaneous',
                            width: '350px'
                        },
                        {
                            dataField: 'xeroId',
                            alignment: 'left',
                            caption: 'Xero account code'
                        }
                    ]"
                    >
                        <tbody *dxTemplate="let saleItem of 'rowTemplateSalesItems'">
                            <tr>
                                <td class="mapping-value-cell">
                                    <div>
                                        {{saleItem.data.caterbookValue}}
                                    </div>
                                </td>
                                <td class="mapping-cell">
                                    <div>
                                        <dx-select-box
                                            [ngClass]="itemMappingValid(saleItem.data)"
                                            class="sm-padding"
                                            displayExpr="codeAndName"
                                            valueExpr="accountId"
                                            [dataSource]="configurationModel.xeroAccounts"
                                            (onItemClick)="setMapping($event, saleItem.data, 1)"
                                            [(value)]="saleItem.data.xeroId"
                                        >
                                        </dx-select-box>
                                    </div>
                                </td>
                                <td class="clear-cell">
                                    <i class="fas fa-times-circle small-icon" *ngIf="saleItem.data.xeroId != null" (click)="clearMapping($event, saleItem.data, 1)"></i>
                                </td> 
                            </tr>
                        </tbody>
                    </dx-data-grid>

            </div>

            <div *ngIf="activeTab == 4" class="general">
                <i class="fas fa-sync sync" (click)="createXeroConfiguration()"></i>
                <dx-data-grid
                class="room-type-grid"
                [dataSource]="taxRateMappings"
                rowTemplate="rowTemplateSalesItems"
                [columnAutoWidth]="false"
                [columns]="[
                    {
                        dataField: 'caterbookValue',
                        alignment: 'left',
                        caption: 'Caterbook tax rate',
                        width: '350px'
                    },
                    {
                        dataField: 'xeroId',
                        alignment: 'left',
                        caption: 'Xero tax rate'
                    }
                ]"
                >
                    <tbody *dxTemplate="let saleItem of 'rowTemplateSalesItems'">
                        <tr>
                            <td class="mapping-value-cell">
                                <div>
                                    {{saleItem.data.caterbookValue}}

                                </div>
                            </td>
                            <td class="mapping-cell">
                                <div>
                                    <dx-select-box
                                        class="sm-padding"
                                        displayExpr="nameAndDisplayRate"
                                        valueExpr="taxRateID"
                                        [dataSource]="configurationModel.xeroTaxRates"
                                        (onItemClick)="setMapping($event, saleItem.data, 1)"
                                        [(value)]="saleItem.data.xeroId"
                                    >
                                    </dx-select-box>
                                </div>
                            </td>
                            <td class="clear-cell">
                                <i class="fas fa-times-circle small-icon" *ngIf="saleItem.data.xeroId != null" (click)="clearMapping($event, saleItem.data, 1)"></i>
                            </td> 
                        </tr>
                    </tbody>
                </dx-data-grid>

            </div>

            <div *ngIf="activeTab == 5" class="general">

                <i class="fas fa-info-circle mr-5"></i>The use of tracking categories is optional but recommended

                <dx-data-grid
                *ngIf="usingEPOSCategoryGroups==true"
                class="room-type-grid"
                [dataSource]="trackingCategoryMappings"
                rowTemplate="rowTemplateSalesItems"
                [columnAutoWidth]="false"
                [columns]="[
                    {
                        dataField: 'caterbookValue',
                        alignment: 'left',
                        caption: 'Caterbook category group',
                        width: '350px'
                    },
                    {
                        dataField: 'xeroId',
                        alignment: 'left',
                        caption: 'Xero tracking category'
                    }
                ]"
                >
                    <tbody *dxTemplate="let saleItem of 'rowTemplateSalesItems'">
                        <tr>
                            <td class="mapping-value-cell">
                                <div>
                                    {{saleItem.data.caterbookValue}}

                                </div>
                            </td>
                            <td class="mapping-cell">
                                <div>
                                    <dx-select-box
                                        class="sm-padding"
                                        displayExpr="name"
                                        valueExpr="value"
                                        [dataSource]="trackingCategoryOptions"
                                        (onItemClick)="setMapping($event, saleItem.data, 1)"
                                        [(value)]="saleItem.data.xeroId"
                                    >
                                    </dx-select-box>
                                </div>
                            </td>
                            <td class="clear-cell">
                                <i class="fas fa-times-circle small-icon" *ngIf="saleItem.data.xeroId != null" (click)="clearMapping($event, saleItem.data, 1)"></i>
                            </td> 
                        </tr>
                    </tbody>
                </dx-data-grid>

                <dx-data-grid
                *ngIf="usingEPOSCategoryGroups==false"
                class="room-type-grid"
                [dataSource]="trackingCategoryMappings"
                rowTemplate="rowTemplateSalesItems"
                [columnAutoWidth]="false"
                [columns]="[
                    {
                        dataField: 'caterbookValue',
                        alignment: 'left',
                        caption: 'Caterbook category',
                        width: '350px'
                    },
                    {
                        dataField: 'xeroId',
                        alignment: 'left',
                        caption: 'Xero tracking category'
                    }
                ]"
                >
                    <tbody *dxTemplate="let saleItem of 'rowTemplateSalesItems'">
                        <tr>
                            <td class="mapping-value-cell">
                                <div>
                                    {{saleItem.data.caterbookValue}}

                                </div>
                            </td>
                            <td class="mapping-cell">
                                <div>
                                    <dx-select-box
                                        class="sm-padding"
                                        displayExpr="name"
                                        valueExpr="value"
                                        [dataSource]="trackingCategoryOptions"
                                        (onItemClick)="setMapping($event, saleItem.data, 1)"
                                        [(value)]="saleItem.data.xeroId"
                                    >
                                    </dx-select-box>
                                </div>
                            </td>
                            <td class="clear-cell">
                                <i class="fas fa-times-circle small-icon" *ngIf="saleItem.data.xeroId != null" (click)="clearMapping($event, saleItem.data, 1)"></i>
                            </td> 
                        </tr>
                    </tbody>
                </dx-data-grid>

            </div>

            <div *ngIf="activeTab == 6" class="general">

                <dx-data-grid 
                    [dataSource]="configurationModel.xeroLogs"
                    class="booking-search-grid"
                    [columns]="[
                    { dataField: 'dateCreated', caption: 'Time', dataType: 'date', format: 'dd/MM/yyyy, H:mm:ss', width: '15%', alignment:'left'},
                    { dataField: 'operation', cellTemplate: 'operation', width: '15%', alignment:'left'},
                    { dataField: 'user', caption: 'User', width: '15%', alignment:'left'},
                    { dataField: 'description', cellTemplate: 'description', caption: 'Description', width: '55%', alignment:'left' },
                    ]"
                    >
                    <div *dxTemplate="let e of 'operation'">
                        {{ e.data.operation | camelCaseSeparate }}
                    </div>
                    <div *dxTemplate="let d of 'description'" class="text-ellipsis log-description">
                        {{ d.data.description }}
                    </div>
                    
                </dx-data-grid>

            </div>

            <div class="buttons-wrapper" *ngIf="activeTab != 1">
                <button *ngIf="!loader" class="custom-button blue" type="button" (click)="saveMappings()">
                    Save
                </button>
            </div>
        
        </div>

    </div>

    <div class="custom-box tabs" *ngIf="!token.Property.Xero" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <div class="search-holder custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Xero</h2>
            Please contact us to activate the Xero integration
        </div>

        

    </div>

</div>

<div class="loader" *ngIf="loader"></div>

<app-settings-property-settings-details
    *ngIf="editPopup"
    [(editPropertySetting)]="editPopup"
    [data]="currentPropertySettingModel"
    (update)="xeroSettingUpdated()"
>
</app-settings-property-settings-details>

<app-confirm-modal
    *ngIf="confirmCancelAuthorisation"
    [(confirmModal)]="confirmCancelAuthorisation"
    [message]="'This will stop any further synchronisation of data with Xero, are you sure you want to cancel authorisation?'"
    (confirm)="stopXeroAuthorisationConfirmed()"
>
</app-confirm-modal>

<app-confirm-modal
    *ngIf="confirmClearMapping"
    [(confirmModal)]="confirmClearMapping"
    [message]="'Are you sure you want to remove this mapping'"
    (confirm)="clearMappingConfirmed()"
>
</app-confirm-modal>