import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { InventoryService } from '../inventory.service';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UpdateAvailabilityModel } from '../../../models/booking/update-availability.model';
import { AvailabilityPopupModel } from '../../../models/booking/availability-popup.model';
import { RoomTypeBaseModel } from '../../../models/room-type/room-type-base.model';
import { InventoryAvailabilityPermissionsModel } from '../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../enums/permissions.enum';
import * as moment from 'moment';

@Component({
    selector: 'app-inventory-details',
    templateUrl: './inventory-details.component.html',
    styleUrls: ['./inventory-details.component.less'],
})
export class InventoryDetailsComponent implements OnInit {
    @Input() inventoryDetails: boolean;
    @Output() inventoryDetailsChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();
    @Input() roomTypeId: number;
    @Input() dateFrom: string;
    @Input() dateTo: string;
    @Input() rateScheduleId: number;

    permissionsModel: InventoryAvailabilityPermissionsModel;

    loader = false;
    valForm: UntypedFormGroup;
    theme: number;
    wasChanged: boolean;

    availabilityPopupModel = new AvailabilityPopupModel();
    updateAvailabilityModel = new UpdateAvailabilityModel();

    constructor(
        private inventoryService: InventoryService,
        private fb: UntypedFormBuilder,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryAvailability);

        this.valForm = fb.group({
            dateFrom: [null, Validators.required],
            dateTo: [null, Validators.required],
            roomType: [null, Validators.required],
            status: [null, Validators.required],
        });
    }

    changeFromDate(event) {
        if (event.value !== null) {
            if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
                const dateFrom: Date = new Date(this.dateFrom);
                this.dateTo = new Date(dateFrom.getTime()).toDateString();
            }
        }
    }

    changeToDate(event) {
        if (event.value !== null) {
            if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
                const dateTo: Date = new Date(this.dateTo);
                this.dateFrom = new Date(dateTo.getTime()).toDateString();
            }
        }
    }

    ngOnInit() {
        this.valForm.controls['dateFrom'].setValue(this.dateFrom);
        this.valForm.controls['dateTo'].setValue(this.dateTo);
        const dateString = new Date(this.dateFrom).toDateString();
        this.inventoryService
            .getAvailabilityPopup(
                AuthTokenService.decodeJwtToken().Property.Id,
                this.roomTypeId,
                dateString,
                this.rateScheduleId
            )
            .subscribe(
                (response) => {
                    this.availabilityPopupModel = response;
                    this.availabilityPopupModel.roomTypes.push(new RoomTypeBaseModel(-1, 'All'));
                    this.updateAvailabilityModel.status = this.availabilityPopupModel.status;

                    this.updateAvailabilityModel.roomTypeIds.push(this.roomTypeId);
                    this.valForm.controls['roomType'].setValue(this.roomTypeId);
                },
                (error) => console.log(error)
            );
    }

    setRoomTypeValue(e) {
        if (e.itemData !== null) {
            const roomType: RoomTypeBaseModel = e.itemData;
            const roomTypeIds: number[] = [];
            if (roomType.id === -1) {
                const dataSource: RoomTypeBaseModel[] = this.availabilityPopupModel.roomTypes;
                dataSource.forEach((x) => {
                    if (x.id !== -1) {
                        roomTypeIds.push(x.id);
                    }
                });
            } else {
                roomTypeIds.push(roomType.id);
            }

            this.updateAvailabilityModel.roomTypeIds = roomTypeIds;
        }
    }

    submitAvailability(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            this.loader = true;
            this.updateAvailabilityModel.propertyId = AuthTokenService.decodeJwtToken().Property.Id;
            this.updateAvailabilityModel.dateFrom = moment(this.dateFrom).format('YYYY-MM-DD');
            this.updateAvailabilityModel.dateTo = moment(this.dateTo).format('YYYY-MM-DD');

            this.inventoryService.updateAvailability(this.updateAvailabilityModel).subscribe(
                (response) => {
                    this.loader = false;
                    this.wasChanged = true;
                },
                (error) => {
                    console.log(error);
                    this.loader = false;
                }
            );
        }
    }

    close() {
        if (this.wasChanged) {
            this.update.emit();
        }
        this.inventoryDetailsChange.emit(false);
    }
}
