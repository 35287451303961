import { CurrencyModel } from '../account/currency.model';
import { EnumModel } from '../shared/enum-model';

export class CheckedSaleItemPDModel {
    public list: CheckedSaleItemCategoryModel[];
    public currency: CurrencyModel;
    public chargeTypes: EnumModel[];
}

export class CheckedSaleItemCategoryModel {
    public id: number;
    public propertyId: number;
    public name: string;
    public active: boolean;
    public saleItems: CheckedSaleItemModel[] = [];
}

export class CheckedSaleItemModel {
    public checked: boolean;
    public id: number;
    public categoryId: number;
    public ratePlanId: number;
    public costCentreId: number;
    public name: string;
    public notes: string;
    public chargeType: string;
    public free: boolean;
    public price: number;
    public active: boolean;
    public dateCreated: Date;

    // added new properties
    public oldPrice: number;
}

export class UpdateRatePlanSaleItemPDModel {
    public ratePlanId: number;
    public list: CheckedSaleItemModel[];
}
