<div class="account-wrapper flex">
    <div class="custom-box tabs">
        <h2>Housekeeping config</h2>
        <input type="radio" id="tab3" class="tab-input" name="tabs" checked />
        <label for="tab3" class="tab-label t3">Cleaning types</label>
        <input type="radio" id="tab2" class="tab-input" name="tabs" />
        <label for="tab2" class="tab-label t2">Cleaning groups</label>
        <input type="radio" id="tab1" class="tab-input" name="tabs" />
        <label for="tab1" class="tab-label t1">Summary</label>
        <div id="finance" class="form-box flex" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <div *ngIf="permissionsModel.formButtonEnabled">
                <button type="button" (click)="openHousekeepingOrder()" class="change-order">
                    <i class="fa fa-sort" aria-hidden="true"></i>Change order
                </button>
            </div>
            <dx-data-grid
                class="room-type-grid"
                (onCellClick)="cellClick($event)"
                [dataSource]="cleaningFrequencies"
                [columns]="[
                    { dataField: 'name', cellTemplate: 'name', caption: 'Name', width: '250', allowSorting: true },
                    { dataField: 'order', caption: 'Order', allowSorting: true, width: '80', alignment: 'left' },
                    { dataField: 'frequencyType', caption: 'Frequency', width: '200' },
                    { dataField: 'active', caption: 'Status', cellTemplate: 'status' },
                    { dataField: 'excludeSunday', caption: 'Exl.Sunday', allowSorting: true, width: 80 },
                    { dataField: 'linen', caption: 'Linen', allowSorting: true, width: 80 },
                    { dataField: 'towels', caption: 'Towel', allowSorting: true, width: 80 },
                    { cellTemplate: 'delete', width: '60' }
                ]"
            >
                <div class="name" *dxTemplate="let n of 'name'">
                    <i [ngClass]="[theme == 2 ? 'dark-theme' : '']" class="fa-solid fa-link fa-icon fa-icon-xsmall blue link-icon"></i>
                    {{ n.data.name }}
                </div>
                <div *dxTemplate="let s of 'status'">
                    <ng-container *ngIf="s.data.active">Active</ng-container>
                    <ng-container *ngIf="!s.data.active">Inactive</ng-container>
                </div>
                <div *dxTemplate="let a of 'delete'">
                    <i *ngIf="permissionsModel.delete" title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small" (click)="openModalDeleteType(a.data.id)"></i>
                </div>
                <dxo-scrolling mode="standard"></dxo-scrolling>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true"> </dxo-pager>
            </dx-data-grid>
            <div title="Add new" class="add-new-box" (click)="addNewCleaningType()" *ngIf="permissionsModel.create">
                <div class="text">Add new</div>
                <div class="img">
                    <i class="fa-solid fa-plus fa-icon gray"></i>
                </div>
            </div>
        </div>
        <div id="guest" class="form-box flex" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <dx-data-grid
                class="room-type-grid"
                (onCellClick)="cellClickGroup($event)"
                [dataSource]="cleaningGroups"
                [columns]="[
                    { dataField: 'name', cellTemplate: 'name', caption: 'Group Name', allowSorting: true },
                    { cellTemplate: 'configuredRooms', caption: 'Rooms', allowSorting: false },
                    { cellTemplate: 'delete', width: '60' }
                ]"
            >
                <div class="name" *dxTemplate="let n of 'name'">
                    <i [ngClass]="[theme == 2 ? 'dark-theme' : '']" class="fa-solid fa-link fa-icon fa-icon-xsmall blue link-icon"></i>
                    {{ n.data.name }}
                </div>
                <div *dxTemplate="let t of 'configuredRooms'">
                    <ng-container *ngFor="let item of t.data.rooms"> {{ item.shortName }} </ng-container>
                </div>
                <div *dxTemplate="let a of 'delete'">
                    <i *ngIf="permissionsModel.delete" title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small" (click)="openModalDeleteGroup(a.data.id)"></i>
                </div>
                <dxo-scrolling mode="standard"></dxo-scrolling>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true"> </dxo-pager>
            </dx-data-grid>
            <div title="Add new" class="add-new-box" (click)="addNewCleaningGroup()" *ngIf="permissionsModel.create">
                <div class="text">Add new</div>
                <div class="img">
                    <i class="fa-solid fa-plus fa-icon gray"></i>
                </div>
            </div>
        </div>
        <div id="summary" class="form-box flex" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <dx-data-grid
                class="room-type-grid"
                (onCellClick)="cellClick($event)"
                [dataSource]="cleaningFrequencies"
                [columns]="[
                    { dataField: 'name', cellTemplate: 'name', caption: 'Name', width: '250', allowSorting: true },
                    { dataField: 'summary', caption: 'Summary', allowSorting: true }
                ]"
            >
                <div class="name" *dxTemplate="let n of 'name'">
                    <i [ngClass]="[theme == 2 ? 'dark-theme' : '']" class="fa-solid fa-link fa-icon fa-icon-xsmall blue link-icon"></i>
                    {{ n.data.name }}
                </div>
                <dxo-scrolling mode="standard"></dxo-scrolling>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true"> </dxo-pager>
            </dx-data-grid>
        </div>
    </div>
</div>

<app-change-order-housekeeping
    *ngIf="housekeepingOrder"
    (update)="updateData()"
    [(housekeepingOrder)]="housekeepingOrder"
    [cleaningFrequencies]="cleaningFrequencies"
>
</app-change-order-housekeeping>
<app-settings-housekeeping-details *ngIf="addHousekeeping" [(addHousekeeping)]="addHousekeeping" (update)="init()">
</app-settings-housekeeping-details>
<app-settings-housekeeping-details
    *ngIf="editHousekeeping"
    [(editHousekeeping)]="editHousekeeping"
    (update)="init()"
    [cleaningFrequency]="activeCleaningFrequency"
>
</app-settings-housekeeping-details>
<app-settings-housekeeping-groups
    *ngIf="addHousekeepingGroup"
    [(addHousekeepingGroup)]="addHousekeepingGroup"
    (update)="init()"
>
</app-settings-housekeeping-groups>
<app-settings-housekeeping-groups
    *ngIf="editHousekeepingGroup"
    [(editHousekeepingGroup)]="editHousekeepingGroup"
    [cleaningGroupId]="activeGroupId"
    (update)="init()"
>
</app-settings-housekeeping-groups>
<app-delete-modal
    *ngIf="deleteModalType"
    [(deleteModal)]="deleteModalType"
    [message]="'Are you sure you want to delete this cleaning type?'"
    (delete)="deleteType()"
>
</app-delete-modal>
<app-delete-modal
    *ngIf="deleteModalGroup"
    [(deleteModal)]="deleteModalGroup"
    [message]="'Are you sure you want to delete this cleaning group?'"
    (delete)="deleteGroup()"
>
</app-delete-modal>
