import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsVatRatesService } from './settings-vat-rates.service';
import { PropertyVatBaseModel } from '../../../models/property/property-vat-base.model';

import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { FinanceFinanceVatRatesPermissionsModel } from '../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../enums/permissions.enum';
import { CountryContext } from 'src/app/models/auth-jwt/token-context';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-settings-vat-rates',
    templateUrl: './settings-vat-rates.component.html',
    styleUrls: ['./settings-vat-rates.component.less'],
})
export class SettingsVatRatesComponent implements OnInit {
    @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
    permissionsModel: FinanceFinanceVatRatesPermissionsModel;

    vatRates: PropertyVatBaseModel[];
    errorMessage: string;
    loader = false;
    theme: number;
    countryContext: CountryContext;

    constructor(
        private settingsVatRatesService: SettingsVatRatesService,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.FinanceVatRates);
        this.countryContext = AuthTokenService.decodeJwtToken().Country;
    }

    ngOnInit() {
        this.getVatRates();
    }

    ngAfterViewInit() {
        if (this.grid && this.grid.instance) {
            this.grid.instance.columnOption('noVat', 'caption', 'No ' + this.countryContext.VatName);
        }
    }

    addNewVatRate() {
        let newPropertyVatEntry = new PropertyVatBaseModel();
        newPropertyVatEntry.active = true;
        this.vatRates.push(newPropertyVatEntry);

        let pages = this.grid.instance.pageCount();
        this.grid.instance.pageIndex(pages - 1);
    }

    deleteVatRate(vatRate) {
        let index = this.vatRates.indexOf(vatRate);
        this.vatRates.splice(index, 1);
    }

    saveVatRates() {
        if (!this.validateForm()) {
            this.loader = true;
            this.settingsVatRatesService.setVatRates(this.vatRates).subscribe(
                (response) => {
                    this.loader = false;
                    this.getVatRates();
                },
                (error) => {
                    this.loader = false;
                    console.error(error.message);
                }
            );
        }
    }

    validateForm(): boolean {
        for (const vatRate of this.vatRates) {
            if (vatRate.value == null || vatRate.name == null || vatRate.name == '') {
                this.errorMessage = 'Error! Please fill all fields.';
                return true;
            }

            if (vatRate.value < 0) {
                this.errorMessage = 'Error! Value cannot be negative.';
                return true;
            }
        }

        this.errorMessage = '';
        return false;
    }

    getVatRates() {
        this.settingsVatRatesService.getVats().subscribe(
            (response) => {
                this.vatRates = response;
            },
            (error) => {
                console.error(error.message);
            }
        );
    }
}
