import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { AccountUpdateModel } from '../../../models/account/account-update.model';
import { AccountSettingUpdateModel } from '../../../models/account/account-setting-update.model';

@Injectable()
export class SettingsAccountService {
    private getAccountDetailsUrl = environment.apiUrl + '/Account/GetAccountDetails';
    private updateAccountUrl = environment.apiUrl + '/Account/UpdateAccount';
    private getAccountSettingsListUrl = environment.apiUrl + '/Account/GetAccountSettingsList';
    private updateAccountSettingsListUrl = environment.apiUrl + '/Account/UpdateAccountSettingsList';

    // Billing plan urls
    private getPropertyDetailsForAdminUrl = environment.apiUrl + '/Account/GetPropertyDetailsForAdmin';
    private getPropertyRoomsCountUrl = environment.apiUrl + '/Account/GetRoomsCount';
    private getPropertPaymentsCountUrl = environment.apiUrl + '/Account/GetPaymentsCount';
    private getPropertSmsCountUrl = environment.apiUrl + '/Account/GetSmsCount';

    constructor(private http: HttpRequestService) {}

    getAccountDetails() {
        return this.http.get(this.getAccountDetailsUrl);
    }

    updateAccount(model: AccountUpdateModel) {
        return this.http.post(this.updateAccountUrl, model, true);
    }

    getAccountSettingsList() {
        return this.http.get(this.getAccountSettingsListUrl);
    }

    updateAccountSettingsList(model: AccountSettingUpdateModel[]) {
        return this.http.put(this.updateAccountSettingsListUrl, model, true);
    }

    // Billing plan methods
    getPropertyDetailsForAdmin(propertyId: any) {
        return this.http.get(this.getPropertyDetailsForAdminUrl + '?propertyId=' + propertyId);
    }

    getPropertyRoomsCount(propertyId: number) {
        return this.http.get(this.getPropertyRoomsCountUrl + '?propertyId=' + propertyId);
    }

    getPropertPaymentsCount(propertyId: number) {
        return this.http.get(this.getPropertPaymentsCountUrl + '?propertyId=' + propertyId);
    }

    getPropertSmsCount(propertyId: number) {
        return this.http.get(this.getPropertSmsCountUrl + '?propertyId=' + propertyId);
    }
}
