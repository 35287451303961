import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StatusModel } from '../../../../models/shared/status.model';
import { CleaningGroupPDModel } from '../../../../models/cleaning-frequency/cleaning-group-pd.model';
import { HousekeepingService } from '../housekeeping.service';
import { CleaningGroupModel } from '../../../../models/cleaning-frequency/cleaning-group.model';
import { RoomModel } from '../../../../models/room/rooms-mapping.model';
import * as _ from 'lodash';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import {
    InventoryAssignRoomsPermissionsModel,
    InventoryHousekeepingPermissionsModel,
} from '../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../enums/permissions.enum';

@Component({
    selector: 'app-settings-housekeeping-groups',
    templateUrl: './settings-housekeeping-groups.component.html',
    styleUrls: ['./settings-housekeeping-groups.component.less'],
})
export class SettingsHousekeepingGroupsComponent implements OnInit {
    @Input() addHousekeepingGroup: boolean;
    @Output() addHousekeepingGroupChange = new EventEmitter<boolean>();
    @Input() editHousekeepingGroup: boolean;
    @Output() editHousekeepingGroupChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();
    @Input() cleaningGroupId: number;

    permissionsModel: InventoryHousekeepingPermissionsModel;

    loader = false;

    errorMessage = '';
    theme: number;

    valForm: UntypedFormGroup;
    statusModelList: StatusModel[] = [];
    cleaningGroupPDModel = new CleaningGroupPDModel();

    constructor(
        private fb: UntypedFormBuilder,
        private housekeepingService: HousekeepingService,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryHousekeeping);

        this.valForm = fb.group({
            name: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            status: [null, Validators.required],
        });

        if (!this.permissionsModel.formButtonEnabled) {
            this.valForm.controls['name'].disable();
            this.valForm.controls['status'].disable();
        }

        this.statusModelList.push(new StatusModel(true, 'Active'));
        this.statusModelList.push(new StatusModel(false, 'Inactive'));
    }

    ngOnInit() {
        if (this.addHousekeepingGroup) {
            this.housekeepingService.getPageDataCleaningGroupsForInsert().subscribe(
                (response) => {
                    this.cleaningGroupPDModel = response;
                    this.cleaningGroupPDModel.cleaningGroup = new CleaningGroupModel();
                },
                (error) => {
                    console.log(error);
                }
            );
        } else if (this.editHousekeepingGroup) {
            this.housekeepingService.getPageDataCleaningGroups(this.cleaningGroupId).subscribe(
                (response) => {
                    this.cleaningGroupPDModel = response;
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }

    setRoomToCleaningGroup(event, data) {
        const room: RoomModel = this.cleaningGroupPDModel.rooms.find((r) => r.id === event.value);
        const index = this.cleaningGroupPDModel.cleaningGroup.rooms.indexOf(data);
        this.cleaningGroupPDModel.cleaningGroup.rooms[index] = room;
    }

    deleteRow(data) {
        const index = this.cleaningGroupPDModel.cleaningGroup.rooms.indexOf(data);
        this.cleaningGroupPDModel.cleaningGroup.rooms.splice(index, 1);
    }

    addNewItem() {
        if (this.cleaningGroupPDModel.cleaningGroup.rooms.length === 0) {
            this.cleaningGroupPDModel.cleaningGroup.rooms = [];
        }
        const room: RoomModel = new RoomModel();
        room.id = null;
        this.cleaningGroupPDModel.cleaningGroup.rooms = [...this.cleaningGroupPDModel.cleaningGroup.rooms, room];
    }

    validateForm() {
        for (const room of this.cleaningGroupPDModel.cleaningGroup.rooms) {
            if (room.id === null) {
                this.errorMessage = 'Error! Please fill all fields.';
                return false;
            }
        }

        if (
            _.uniqBy(this.cleaningGroupPDModel.cleaningGroup.rooms, 'id').length !==
            this.cleaningGroupPDModel.cleaningGroup.rooms.length
        ) {
            this.errorMessage = 'Error! You cannot enter same rooms.';
            return false;
        }

        this.errorMessage = '';
        return true;
    }

    submitCleaningGroup(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid && this.validateForm()) {
            if (this.addHousekeepingGroup) {
                this.insertCleaningGroup();
            } else if (this.editHousekeepingGroup) {
                this.updateCleaningGroup();
            }
        }
    }

    insertCleaningGroup() {
        this.housekeepingService.createCleaningGroup(this.cleaningGroupPDModel.cleaningGroup).subscribe(
            (response) => {
                this.update.emit();
                this.close();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    updateCleaningGroup() {
        this.housekeepingService.updateCleaningGroup(this.cleaningGroupPDModel.cleaningGroup).subscribe(
            (response) => {
                this.update.emit();
                this.close();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    close() {
        this.addHousekeepingGroupChange.emit(false);
        this.editHousekeepingGroupChange.emit(false);
    }
}
