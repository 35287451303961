import { AccountVatModel } from "../rate-plan/account-vat.model";
import { ReportingEposConfigurationModel } from "./reports-epos-configuration.model";

export class ZReadConfigurationModel {
    usingZRead: boolean;
    reportingDate: Date;
    categoryOptions: ReportingEposConfigurationModel[];
    paymentTypeOptions: ReportingEposConfigurationModel[];
    categoryItems: ZReadItemModel[];
    paymentItems: ZReadItemModel[];
    propertyVats: AccountVatModel[];
}

export class ZReadItemModel {
    itemType: string;
    key: string;
    description: string;
    amount: number;
    vatId?: number;
    newEPOSOption?: ReportingEposConfigurationModel
}