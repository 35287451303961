import {
    CalendarPermissionsModel,
    BookingSearchPermissionsModel,
    PermissionsModel,
    NewBookingPermissionsModel,
    NewFunctionPermissionsModel,
    GuestRecordsPermissionsModel,
    PricePlanPermissionsModel,
    RatePlanPermissionsModel,
    ExtrasPermissionsModel,
    PromoCodesPermissionsModel,
    InventoryAvailabilityPermissionsModel,
    InventoryRoomTypesPermissionsModel,
    InventoryAssignRoomsPermissionsModel,
    InventoryHousekeepingPermissionsModel,
    FinancePaymentsPermissionsModel,
    FinanceFinanceVatRatesPermissionsModel,
    FinanceManageInvoicesPermissionsModel,
    BookingTemplatePermissionsModel,
    PropertyListPermissionsModel,
    PropertyCostCentresPermissionsModel,
    PropertyBookingSourcesPermissionsModel,
    PropertyTermsAndConditionsPermissionsModel,
    MessagingPermissionsModel,
    HousekeepingPermissionsModel,
    ReportsPermissionsModel,
    BookingEmailSMSSettingsPermissionsModel,
    PoliciesPermissionsModel,
    UsersPermissionsModel,
    OtaConnectionsPermissionsModel,
    CloudhookPermissionsModel,
    GuestRegistrationTemplatePermissionsModel,
    PartnersPermissionsModel,
    RateSchedulesPermissionsModel,
    BookingDetailPermissionsModel,
} from './../../models/permissions/permissions.model';
import { PermissionsEnum } from './../../enums/permissions.enum';
import { Injectable } from '@angular/core';
import { AuthTokenService } from './auth-token.service';
import { UserRoleEnum } from '../../enums/user-role.enum';

@Injectable()
export class PermissionsService {
    constructor() {}

    private getUserRole() {
        return AuthTokenService.decodeJwtToken().User.Role;
    }

    public createPermissionsModel(component: PermissionsEnum): any {
        let model: any;
        const role = this.getUserRole().Value;

        switch (component) {
            case PermissionsEnum.Calendar:
                model = new CalendarPermissionsModel();
                break;
            case PermissionsEnum.BookingSearch:
                model = new BookingSearchPermissionsModel();
                break;
            case PermissionsEnum.NewBooking:
                model = new NewBookingPermissionsModel();
                break;
            case PermissionsEnum.NewFunction:
                model = new NewFunctionPermissionsModel();
                break;
            case PermissionsEnum.GuestRecords:
                model = new GuestRecordsPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.PricePlans:
                model = new PricePlanPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.RatePlans:
                model = new RatePlanPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.RateSchedules:
                model = new RateSchedulesPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.Extras:
                model = new ExtrasPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.PromoCodes:
                model = new PromoCodesPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.InventoryAvailability:
                model = new InventoryAvailabilityPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.InventoryRoomTypes:
                model = new InventoryRoomTypesPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.InventoryAssignRooms:
                model = new InventoryAssignRoomsPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.InventoryHousekeeping:
                model = new InventoryHousekeepingPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.FinanceManageInvoices:
                model = new FinanceManageInvoicesPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.FinancePayments:
                model = new FinancePaymentsPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.FinanceVatRates:
                model = new FinanceFinanceVatRatesPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.PropertyList:
                model = new PropertyListPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.PropertyCostCentres:
                model = new PropertyCostCentresPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.PropertyBookingSources:
                model = new PropertyBookingSourcesPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.PropertyTermsAndConditions:
                model = new PropertyTermsAndConditionsPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.Messaging:
                model = new MessagingPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.Housekeeping:
                model = new HousekeepingPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.BookingTemplate:
                model = new BookingTemplatePermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.BookingEmailSMSSettings:
                model = new BookingEmailSMSSettingsPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.GuestRegistrationTemplate:
                model = new GuestRegistrationTemplatePermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.Reports:
                model = new ReportsPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.Policies:
                model = new PoliciesPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.Users:
                model = new UsersPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.Partners:
                model = new PartnersPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.OtaConnections:
                model = new OtaConnectionsPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.Cloudhook:
                model = new CloudhookPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            case PermissionsEnum.BookingDetail:
                model = new BookingDetailPermissionsModel(this.getUserRoleEnumByRole(role));
                break;
            default:
                model = new PermissionsModel();
                break;
        }
        return model;
    }

    private getUserRoleEnumByRole(role: number) {
        switch (role) {
            case 1:
                return UserRoleEnum.Owner;
            case 2:
                return UserRoleEnum.Manager;
            case 3:
                return UserRoleEnum.Supervisor;
            case 4:
                return UserRoleEnum.Receptionist;
            case 5:
                return UserRoleEnum.Housekeeping;
            case 6:
                return UserRoleEnum.WebDesigner;
            case 7:
                return UserRoleEnum.BookingOnly;
            case 8:
                return UserRoleEnum.DemoUser;
        }
    }
}
