import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'app-room-creation',
    templateUrl: './room-creation.component.html',
    styleUrls: ['./room-creation.component.less'],
})
export class RoomCreationComponent implements OnInit {
    numberOfRooms: number;

    constructor(private route: ActivatedRoute) {
        this.route.params.subscribe((params: Params) => {
            this.numberOfRooms = params['numberOfRooms'];
        });
    }

    ngOnInit() {}
}
