<div class="zread-wrapper flex">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <h2>Z-read</h2>

        <ng-container *ngIf="model.usingZRead">
        <div class="search-wrapper">
            <div class="form-item">
                <div class="custom-label">Report date</div>
                <div class="custom-control">

                    <dx-date-box
                        class="date-box"
                        [value]="reportDate"
                        type="date"
                        [displayFormat]="'dd/MM/yyyy'"
                        [max]="maxReportDate"
                        (onValueChanged)="reportDateChanged($event)"
                    >
                        <dxo-calendar-options 
                        [cellTemplate]="'custom'" 
                        >
                        <div
                        *dxTemplate="let cell of 'custom'"
                        [ngClass]="getCellCssClass(cell)"
                        >
                        {{ cell.text }}
                        </div>
                        </dxo-calendar-options>

                    </dx-date-box>
                </div>
                <div class="custom-validation">
                    <div
                    [ngClass]="getReportStatusCssClass()"
                    >End of day report status: {{eodStatus}}</div
                    >
                </div>

            </div>
      
        </div>

        <div class="category-container" *ngIf="dataLoaded">
            <dx-data-grid
                class="smaller-cell"
                [dataSource]="model.categoryItems"
                rowTemplate="rowTemplateEposConfig"
                [columnAutoWidth]="true"
                [columns]="[
                    {
                        dataField: 'description',
                        alignment: 'left',
                        caption: 'EPOS Category',
                        width: '250px'
                    },
                    {
                        dataField: 'amount',
                        alignment: 'left',
                        caption: 'Amount',
                        width: '150px'
                    },
                    {
                        dataField: 'VatId',
                        alignment: 'left',
                        caption: 'VAT',
                        width: '200px'
                    },
                    {}
                ]"
            >
                <tbody *dxTemplate="let item of 'rowTemplateEposConfig'">
                    <tr>
                        <td>
                            <div class="form-item">
                                <span class="custom-label">{{item.data.description}}</span>
                            </div>
                        </td>
                        <td>
                            <div class="form-item">
                                <input
                                    class="custom-input"
                                    type="number"
                                    maxlength="100"
                                    (change)="setZReadAmount(item.data, $event)"
                                    [(value)]="item.data.amount"
                                    [readonly]="eodStatus == 'Finalised'"
                                />  
                            </div>
                        </td>
                        <td>
                            <div class="form-item">
                                <dx-select-box
                                    class="custom-select"
                                    [(value)]="item.data.vatId"
                                    [dataSource]="model.propertyVats"
                                    displayExpr="name"
                                    valueExpr="id"
                                    name="vat"
                                    (onValueChanged)="setZReadVat(item.data, $event)"
                                    [disabled]="eodStatus == 'Finalised'"
                                >
                                </dx-select-box>
                            </div>
                        </td>
                        <td class="right-td">
                            <i title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small" (click)="deleteZRead(1, item.data)"></i>
                        </td>
                    </tr>
                </tbody>
            </dx-data-grid>

        </div>
        <div class="payment-type-container" *ngIf="dataLoaded">
            <dx-data-grid
                class="smaller-cell"
                [dataSource]="model.paymentItems"
                rowTemplate="rowTemplateEposConfig"
                [columnAutoWidth]="true"
                [columns]="[
                    {
                        dataField: 'description',
                        alignment: 'left',
                        caption: 'EPOS Payment Method',
                        width: '250px'
                    },
                    {
                        dataField: 'amount',
                        alignment: 'left',
                        caption: 'Amount',
                        width: '150px'
                    },
                    {}
                ]"
            >
                <tbody *dxTemplate="let item of 'rowTemplateEposConfig'">
                    <tr>
                        <td>
                            <div class="form-item">
                                <span class="custom-label">{{item.data.description}}</span>
                            </div>
                        </td>
                        <td>
                            <div class="form-item">
                                <input
                                    class="custom-input"
                                    type="number"
                                    maxlength="100"
                                    (change)="setZReadAmount(item.data, $event)"
                                    [(value)]="item.data.amount"
                                    [readonly]="eodStatus == 'Finalised'"
                                />  
                            </div>
                        </td>
                        <td class="right-td">
                            <i title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small" (click)="deleteZRead(2, item.data)"></i>
                        </td>
                    </tr>
                </tbody>
            </dx-data-grid>
        </div>

        <div class="button-wrapper" *ngIf="dataLoaded">
            <div class="button-item-1">
                <div (click)="addNewZReadItem(1)" class="add-new-box">
                    <span class="text">Add new</span>
                    <div class="img">
                        <i class="fa-solid fa-plus fa-icon gray"></i>
                    </div>
                </div>  
            </div>

            <div class="button-item-2">
                <div class="form-item">
                    <button
                        *ngIf="!loader"
                        (click)="saveZRead()"
                        class="custom-button blue left form-btn"
                        [disabled]="eodStatus == 'Finalised'"
                    >
                        Save
                    </button>
                    <div *ngIf="loader" class="loader left"></div>
                </div>
            </div>

        </div>
        </ng-container>
        <ng-container *ngIf="dataLoaded && !model.usingZRead">
            <div class="text-warning">You are not currently using z-read.</div>
        </ng-container>
    </div>

</div>

<div class="loader" *ngIf="loader"></div>

<div class="add-new-modal flex" *ngIf="addNew">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Add new z-read</h2>

            <div class="form-item">
                <div class="custom-label">Type</div>
                <dx-select-box
                    class="custom-select"
                    [(value)]="addNewType"
                    [dataSource]="zreadTypes"
                    displayExpr="description"
                    valueExpr="type"
                    name="type"
                >
                </dx-select-box>
            </div>

            <div class="form-item" *ngIf="addNewType==1">
                <div class="custom-label">EPOS Category</div>
                <dx-select-box
                    class="custom-select"
                    [(value)]="selectedNewCategoryKey"
                    [dataSource]="model.categoryOptions"
                    displayExpr="value"
                    valueExpr="key"
                    name="type"
                >
                </dx-select-box>
            </div>

            <div class="form-item" *ngIf="addNewType==1 && selectedNewCategoryKey==addNewKey">
                <div class="custom-label">Category Name</div>
                    <input
                    class="custom-input c-name"
                    type="text"
                    maxlength="100"
                    (change)="setNewCategoryName($event)"
                    [(value)]="newCategoryName"
                    />  
            </div>

            <div class="form-item" *ngIf="addNewType==2">
                <div class="custom-label">EPOS Payment Type</div>
                <dx-select-box
                    class="custom-select"
                    [(value)]="selectedNewPaymentKey"
                    [dataSource]="model.paymentTypeOptions"
                    displayExpr="value"
                    valueExpr="key"
                    name="type"
                >
                </dx-select-box>
            </div>

            <div class="form-item" *ngIf="addNewType==2 && selectedNewPaymentKey==addNewKey">
                <div class="custom-label">Payment Type Name</div>
                    <input
                    class="custom-input c-name"
                    type="text"
                    maxlength="100"
                    (change)="setNewPaymentTypeName($event)"
                    [(value)]="newPaymentTypeName"
                    />  
            </div>

            <div class="form-item">
                <div class="custom-label">Amount</div>
                    <input
                    class="custom-input z-amount"
                    type="number"
                    maxlength="100"
                    (change)="setZReadAddAmount($event)"
                    [(value)]="addNewZRead.amount"
                    />  
            </div>

            <div class="form-item" *ngIf="addNewType==1">
                <div class="custom-label">{{this.countryContext.VatName}}</div>
                <dx-select-box
                    class="custom-select"
                    [(value)]="addNewZRead.vatId"
                    [dataSource]="model.propertyVats"
                    displayExpr="name"
                    valueExpr="id"
                    name="vat"
                >
                </dx-select-box>
            </div>

            <div *ngIf="loader" class="loader left custom-loader"></div>

            <div class="button-wrapper flex">
                <button type="button" class="custom-button blue" [disabled]="loader" (click)="add()">Add</button>
                <button type="button" class="custom-button red" [disabled]="loader" (click)="closeAddModal()">Cancel</button>
            </div>
            <div class="error-container mt-10" *ngIf="addError.length > 0">
                <div class="text-error">{{addError}}</div>
            </div>
        </div>
    </div>
</div>

