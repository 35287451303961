import { Component, OnInit, HostListener } from '@angular/core';
import { AuthTokenService } from '../shared/services/auth-token.service';
import { NavigationEnd, Router } from '@angular/router';
import { CommunicationService } from '../shared/services/communication.service';
import { TokenContext } from '../models/auth-jwt/token-context';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AuthLoginService } from '../shared/services/auth-login.service';
import { SettingsTemplatesService } from '../routes/settings/settings-templates/settings-templates.service';
import { PropertySettingModel } from '../models/property/property-setting.model';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.less'],
})
export class SideMenuComponent implements OnInit {
    bookingOptions = false;
    settings = false;
    propertyOptions = false;
    accountOptions = false;
    reports = false;
    pricingOptions = false;
    inventory = false;
    finance = false;
    message = false;
    showImage = false;
    hasImage = false;
    isSuperUser = false;
    policyOptions = false;

    innerWidth: any;

    theme: number;

    openMenu = '';
    propertyName: string;
    accountId: number;
    propertyThumbnail: string;

    userRole: string;

    token: TokenContext = new TokenContext();
    imageSafeUrl: SafeUrl;

    isMultiProperty: boolean;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    constructor(
        public router: Router,
        private communicationService: CommunicationService,
        private sanitizer: DomSanitizer,
        private authLoginService: AuthLoginService,
        private propertySettingsService: SettingsTemplatesService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.token = AuthTokenService.decodeJwtToken();
        this.communicationService.refreshToken.subscribe((response: any) => {
            this.propertyName = response.token.Property.Name;
            if (response.refreshThumbnail) {
                this.setPicture(response.token.Property.ThumbnailId);
            }
        });

        this.communicationService.changeThemeOnSidebar.subscribe((cmsTheme: number) => {
            this.theme = cmsTheme;
        });

        this.communicationService.isMultiProperty.subscribe((response: boolean) => {
            if (response) {
                this.isMultiProperty = response;
            }
        });

        if (AuthTokenService.decodeJwtToken() !== null) {
            this.setPicture(AuthTokenService.decodeJwtToken().Property.ThumbnailId);
            this.isSuperUser = AuthTokenService.decodeJwtToken().IsSuperUser;
        }
    }

    ngOnInit() {
        if (AuthTokenService.decodeJwtToken() !== null && AuthTokenService.decodeJwtToken().Property !== null) {
            this.propertyName = AuthTokenService.decodeJwtToken().Property.Name;
            this.accountId = AuthTokenService.decodeJwtToken().Account.Id;
            this.userRole = AuthTokenService.decodeJwtToken().User.Role.Name;
        }

        if (this.router.url.indexOf('booking') > 0) {
            this.bookingOptions = true;
        } else if (this.router.url.indexOf('settings') > 0) {
            this.settings = true;
        } else if (this.router.url.indexOf('reports') > 0) {
            this.reports = true;
        } else if (this.router.url.indexOf('pricing') > 0) {
            this.pricingOptions = true;
        } else if (this.router.url.indexOf('property') > 0) {
            this.propertyOptions = true;
        } else if (this.router.url.indexOf('policies') > 0) {
            this.policyOptions = true;
        } else if (this.router.url.indexOf('messaging-centre') > 0) {
            this.message = true;
        } else if (this.router.url.indexOf('account') > 0) {
            this.accountOptions = true;
        }

        this.innerWidth = window.innerWidth;
    }

    setPicture(thumbnailId: string) {
        if (thumbnailId !== null) {
            this.authLoginService.getPropertyThumbnail().subscribe(
                (response) => {
                    if (response === null) {
                        this.hasImage = false;
                        this.propertyThumbnail = '';
                    } else {
                        this.hasImage = true;
                        this.propertyThumbnail = response;
                    }
                    this.showImage = true;
                    this.imageSafeUrl = this.sanitizer.bypassSecurityTrustUrl(
                        'data:image/jpeg;base64,' + this.propertyThumbnail
                    );
                },
                (error) => {
                    this.showImage = true;
                    console.log(error);
                }
            );
        } else {
            this.hasImage = false;
            this.propertyThumbnail = '';
            this.showImage = true;
        }
    }

    openMenus(menu) {
        if (this.openMenu === menu) {
            this.openMenu = '';
        } else {
            this.openMenu = menu;
        }
    }
}
