import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpRequestService } from '../../shared/services/http-request.service';

@Injectable()
export class PciBookingService {
    private getCardEntryFormUrl = environment.apiUrl + '/Booking/GetCardEntryForm';

    constructor(private http: HttpRequestService) {}

    getCardEntryForm() {
        return this.http.get(this.getCardEntryFormUrl);
    }
}
