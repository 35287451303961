<div class="incidental-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup flex" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2 *ngIf="addItem">Add Item</h2>
            <h2 *ngIf="editItem">{{ permissionsModel.formButtonEnabled ? 'Edit Item' : 'Item' }}</h2>

            <!-- <p class="info-message" *ngIf="editItem && !saleItemModelPDModel.isEditable">{{ saleItemModelPDModel?.message }}</p> -->

            <form [formGroup]="valForm" (ngSubmit)="submitSaleItem($event)" class="add-item-form">
                <div class="form-box flex">
                    <div class="form-item">
                        <div class="custom-label">Item name</div>
                        <input
                            class="custom-input"
                            type="text"
                            formControlName="name"
                            name="saleItemName"
                            [(ngModel)]="saleItemModelPDModel.saleItem.name"
                            [readOnly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['name'].hasError('required') &&
                                (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                            "
                            >Name is required.</span
                        >
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['name'].hasError('maxlength') &&
                                (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                            "
                            >Max 50 characters.</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Category</div>
                        <dx-select-box
                            class="custom-select"
                            [(value)]="saleItemModelPDModel.saleItem.categoryId"
                            [dataSource]="saleItemModelPDModel.saleItemCategory"
                            displayExpr="name"
                            valueExpr="id"
                            name="category"
                            formControlName="category"
                            [disabled]="!permissionsModel.formButtonEnabled"
                            (onItemClick)="categoryChanged($event)"
                        >
                        </dx-select-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['category'].hasError('required') &&
                                (valForm.controls['category'].dirty || valForm.controls['category'].touched)
                            "
                            >Category is required.</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Cost centre</div>
                        <dx-select-box
                            class="custom-select"
                            [(value)]="saleItemModelPDModel.saleItem.costCenterId"
                            [dataSource]="saleItemModelPDModel.costCentres"
                            displayExpr="name"
                            valueExpr="id"
                            name="costCentre"
                            formControlName="costCentre"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-select-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['costCentre'].hasError('required') &&
                                (valForm.controls['costCentre'].dirty || valForm.controls['costCentre'].touched)
                            "
                            >Cost Centre is required.</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Price</div>
                        <input
                            class="custom-input"
                            type="number"
                            formControlName="price"
                            name="price"
                            [(ngModel)]="saleItemModelPDModel.saleItem.price"
                            [readOnly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['price'].hasError('required') &&
                                (valForm.controls['price'].dirty || valForm.controls['price'].touched)
                            "
                            >Price is required.</span
                        >
                    </div>

                    <div class="form-item">
                        <div class="custom-label">Item type</div>
                        <dx-select-box
                            class="custom-select"
                            [(value)]="saleItemModelPDModel.saleItem.itemType"
                            [dataSource]="saleItemModelPDModel.itemType"
                            displayExpr="name"
                            (onItemClick)="checkItemType($event)"
                            formControlName="itemType"
                            valueExpr="value"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-select-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['itemType'].hasError('required') &&
                                (valForm.controls['itemType'].dirty || valForm.controls['itemType'].touched)
                            "
                            >Item type is required.</span
                        >
                    </div>

                    <div class="form-item">
                        <div class="custom-label">Charge type</div>
                        <dx-select-box
                            class="custom-select"
                            [(value)]="saleItemModelPDModel.saleItem.chargeType"
                            [dataSource]="chargeTypes"
                            displayExpr="name"
                            valueExpr="value"
                            name="chargeType"
                            formControlName="chargeType"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-select-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['chargeType'].hasError('required') &&
                                (valForm.controls['chargeType'].dirty || valForm.controls['chargeType'].touched)
                            "
                            >Charge Type is required.</span
                        >
                    </div>

                    <div class="form-item">
                        <div class="custom-label">Status</div>
                        <dx-select-box
                            class="custom-select"
                            [(value)]="saleItemModelPDModel.saleItem.active"
                            [dataSource]="statusModelList"
                            displayExpr="name"
                            formControlName="status"
                            valueExpr="value"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-select-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['status'].hasError('required') &&
                                (valForm.controls['status'].dirty || valForm.controls['status'].touched)
                            "
                            >Status is required.</span
                        >
                    </div>

                    <!--<div class="form-item">-->
                    <!--<div class="custom-label">Sell type</div>-->
                    <!--<dx-select-box class="custom-select"-->
                    <!--[(value)]="saleItemModelPDModel.saleItem.sellType"-->
                    <!--[dataSource]="saleItemModelPDModel.sellType"-->
                    <!--displayExpr="name"-->
                    <!--formControlName="sellType"-->
                    <!--valueExpr="value"-->
                    <!--[disabled]="!permissionsModel.formButtonEnabled">-->
                    <!--</dx-select-box>-->
                    <!--<span class="text-danger" *ngIf="valForm.controls['sellType'].hasError('required') && (valForm.controls['sellType'].dirty || valForm.controls['sellType'].touched)">Sell type is required.</span>-->
                    <!--</div>-->

                    <div class="form-item">
                        <div class="custom-label">{{this.countryContext.VatName}} Rate</div>
                        <dx-select-box
                            class="custom-select"
                            [(value)]="saleItemModelPDModel.saleItem.vatId"
                            [dataSource]="saleItemModelPDModel.vatRates"
                            displayExpr="name"
                            valueExpr="id"
                            name="saleItemVatRate"
                            formControlName="vatRate"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-select-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['chargeType'].hasError('required') &&
                                (valForm.controls['chargeType'].dirty || valForm.controls['chargeType'].touched)
                            "
                            >VAT rate is required.</span
                        >
                    </div>

                    <div class="form-item">
                        <input
                        type="checkbox"
                        [(ngModel)]="saleItemModelPDModel.saleItem.housekeeping"
                        id="housekeeping"
                        class="css-checkbox"
                        formControlName="housekeeping"
                        [disabled]="!permissionsModel.formButtonEnabled"
                        />
                        <label for="housekeeping" class="css-label radGroup1"></label>
                        <div class="custom-label inline">Show on Dashboard</div>
                    </div>

                    <div class="form-item">
                        <input
                            type="checkbox"
                            [(ngModel)]="saleItemModelPDModel.saleItem.webSale"
                            id="web-sale"
                            class="css-checkbox"
                            formControlName="webSale"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        />
                        <label for="web-sale" class="css-label radGroup1"></label>
                        <div class="custom-label inline">Web sale</div>
                    </div>

                    <div class="form-item long">
                        <div class="custom-label">Notes</div>
                        <input
                            class="custom-input"
                            type="text"
                            formControlName="notes"
                            name="notes"
                            [(ngModel)]="saleItemModelPDModel.saleItem.notes"
                            [readOnly]="!permissionsModel.formButtonEnabled"
                        />
                    </div>
                </div>
                <div class="button-wrapper">
                    <button *ngIf="!loader && permissionsModel.update" type="submit" class="custom-button blue left">
                        Save
                    </button>
                    <div *ngIf="loader" class="loader left"></div>
                    <button (click)="close()" class="custom-button red left" type="button">
                        {{ permissionsModel.update ? 'Cancel' : 'Close' }}
                    </button>
                    <button *ngIf="editItem && !isFunction" (click)="editRatePlans()" class="custom-button orange right" type="button">
                        Rate Plans
                    </button>
                </div>
            </form>
            <div *ngIf="isEditRatePlans">
                <div class="rate-plans-wrapper flex">
                    <div class="overlay flex">
                        <div class="custom-box popup sale-items" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
                            <h2>{{ saleItemModelPDModel.saleItem.name }} - available for rate plans</h2>

                            <dx-data-grid
                                [dataSource]="saleItemModelPDModel.ratePlans"
                                rowTemplate="saleItemRatePlan"
                                [columns]="[
                                    { caption: '', allowSorting: false, width: 30 },
                                    { dataField: 'name', caption: 'Rate plan', allowSorting: true },
                                    {
                                        dataField: 'includeFree',
                                        caption: 'Include (Free)',
                                        allowSorting: false,
                                        alignment: 'left'
                                    }
                                ]"
                            >
                                <table>
                                    <ng-container *dxTemplate="let ratePlan of 'saleItemRatePlan'">
                                        <tr class="sale-grid-row">
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    name="checked"
                                                    [(ngModel)]="ratePlan.data.checked"
                                                    id="checkbox{{ ratePlan.data.ratePlanId }}"
                                                    class="css-checkbox"
                                                    [disabled]="!permissionsModel.formButtonEnabled"
                                                />
                                                <label
                                                    for="checkbox{{ ratePlan.data.ratePlanId }}"
                                                    class="css-label radGroup1"
                                                ></label>
                                            </td>
                                            <td class="row-name" title="{{ ratePlan.data.name }}">
                                                {{ ratePlan.data.name }}
                                            </td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    name="free"
                                                    [(ngModel)]="ratePlan.data.includeFree"
                                                    id="checkbox{{ ratePlan.data.ratePlanId }}-2"
                                                    class="css-checkbox"
                                                    [disabled]="
                                                        !ratePlan.data.checked || !permissionsModel.formButtonEnabled
                                                    "
                                                />
                                                <label
                                                    for="checkbox{{ ratePlan.data.ratePlanId }}-2"
                                                    class="css-label radGroup1"
                                                ></label>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </table>

                                <dxo-paging [pageSize]="10"></dxo-paging>
                                <dxo-pager
                                    [showNavigationButtons]="true"
                                    [showPageSizeSelector]="false"
                                    [showInfo]="true"
                                >
                                </dxo-pager>
                            </dx-data-grid>
                            <button
                                type="button"
                                *ngIf="!loader && permissionsModel.update"
                                (click)="saveSaleItemRatePlans()"
                                class="custom-button blue"
                            >
                                Save
                            </button>
                            <div *ngIf="loader" class="loader left"></div>
                            <button type="button" (click)="closeEditRatePlans()" class="custom-button red">
                                {{ permissionsModel.update ? 'Cancel' : 'Close' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
