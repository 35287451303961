import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { NgIdleModule } from '@ng-idle/core';
import { HttpRequestService } from './shared/services/http-request.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    AuthGuardInCompleteUser,
    AuthGuardCompleteUser,
    AuthGuardOwnerUser,
    AuthGuardSuperAdminUser,
    UnsavedChangesGuard,
    AuthGuardUserByRole,
} from './shared/auth-guards/auth-guard.service';
import { CheckEmailUsernameService } from './shared/services/check-email-username.service';
import { AutoLogoutPopupComponent } from './shared/components/auto-logout-popup/auto-logout-popup.component';
import { CheckEmailUsernameAdminService } from './shared/services/check-email-username-admin.service';
import { FileService } from './shared/services/file.service';
import { OtaAuthGuardService } from './shared/auth-guards/ota-auth-guard.service';
import { EposAuthGuardService } from './shared/auth-guards/epos-auth-guard.service';
import { RefreshPagePopupComponent } from './shared/components/refresh-page-popup/refresh-page-popup.component';
import { MaintenanceModeModalComponent } from './shared/components/maintenance-mode-modal/maintenance-mode-modal.component';
import { RoutesModule } from './routes/routes.module';
import { AuthTokenService } from './shared/services/auth-token.service';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { HtmlToPdfService } from './shared/services/html-to-pdf.service';
import { TableToCsvService } from './shared/services/table-to-csv.service';
import { ToasterComponent } from './shared/components/toaster/toaster.component';
import { CustomValidationService } from './shared/services/custom-validation.service';
import { ExportExcelService } from './shared/services/export-excel.service';

@NgModule({
    declarations: [AppComponent, AutoLogoutPopupComponent, RefreshPagePopupComponent, MaintenanceModeModalComponent, ToasterComponent],
    imports: [
        BrowserModule,
        RoutesModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgIdleModule.forRoot()     
    ],
    providers: [
        HttpRequestService,
        AuthTokenService,
        AuthGuardInCompleteUser,
        AuthGuardSuperAdminUser,
        AuthGuardUserByRole,
        OtaAuthGuardService,
        EposAuthGuardService,
        AuthGuardCompleteUser,
        AuthGuardOwnerUser,
        UnsavedChangesGuard,
        CheckEmailUsernameService,
        CheckEmailUsernameAdminService,
        FileService,
        CustomValidationService,
        NgIdleModule,
        HtmlToPdfService,
        ExportExcelService,
        TableToCsvService
    ],
    bootstrap: [AppComponent],
    exports: [RouterModule],
})
export class AppModule {}
