import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpRequestService } from './http-request.service';

@Injectable()
export class CheckEmailUsernameAdminService {
    private checkEmailUrl = environment.apiUrl + '/AdminUser/CheckEmail';
    private checkUsernameUrl = environment.apiUrl + '/AdminUser/CheckUsername';

    request = '';
    constructor(private http: HttpRequestService) {}

    checkUsernameExists(username: string, userId?: number) {
        this.request = this.checkUsernameUrl + '?username=' + username;
        if (userId) {
            this.request += '&userId=' + userId;
        }
        return this.http.get(this.request);
    }

    checkEmailExists(email: string, userId?: number) {
        this.request = this.checkEmailUrl + '?email=' + email;
        if (userId) {
            this.request += '&userId=' + userId;
        }
        return this.http.get(this.request);
    }
}
