import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SignupService } from '../../signup.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { RoomTypeDetailsModel } from '../../../../models/room-type/room-type-details.model';
import { RoomTypePictureModel } from '../../../../models/room-type/room-types.model';
import ArrayStore from 'devextreme/data/array_store';
import { ChangeDetectorRef } from '@angular/core';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { InventoryRoomTypesPermissionsModel } from '../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../enums/permissions.enum';
import { RoomTypeInsertPictureModel } from '../../../../models/room-type/room-type-insert-picture.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';

@Component({
    selector: 'app-room-type-edit',
    templateUrl: './room-type-edit.component.html',
    styleUrls: ['./room-type-edit.component.less'],
})
export class RoomTypeEditComponent implements OnInit {
    @Input() editRoomType: boolean;
    @Output() editRoomTypeChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();
    @Input() idOfRoomType: number;
    @Input() hasOrder: boolean;
    @Input() displayOrderList: number[];

    permissionsModel: InventoryRoomTypesPermissionsModel;

    loader = false;
    showEditor = false;
    submittedForm = false;
    popupLoader = true;
    rateSchedule = false;
    cleaningFrequencies: any;
    amenities: any;

    valForm: UntypedFormGroup;
    roomTypeDetailsModel = new RoomTypeDetailsModel();
    roomTypePictures: RoomTypePictureModel[] = [];
    roomTypeInsertPictureModel = new RoomTypeInsertPictureModel();
    files = [];
    theme: number;
    selectedBeddingArrangments: string[];

    constructor(
        private fb: UntypedFormBuilder,
        private signUpService: SignupService,
        private changeDetectionRef: ChangeDetectorRef,
        private permissionsService: PermissionsService,
        private sanitizer: DomSanitizer,
        private toasterService: ToastService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryRoomTypes);

        this.valForm = this.fb.group({
            status: [null, Validators.required],
            name: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            shortName: [null, Validators.compose([Validators.required, Validators.maxLength(10)])],
            occupancy: [null, Validators.compose([Validators.required, Validators.min(0)])],
            customTag: [null, Validators.maxLength(50)],
            displayOrder: [null, Validators.compose([Validators.required, Validators.min(1)])],
            childrenAvailability: [null],
            maxChildren: [null, Validators.compose([Validators.required, Validators.min(0)])],
            cleaningFrequencies: [null],
            beddingArangement: [null, Validators.required],
            amenities: [null],
            class: [null, Validators.required],
            rackRate: [null, Validators.required],
            desc: [null],
        });

        if (!this.permissionsModel.formButtonEnabled) {
            this.valForm.controls['status'].disable();
            this.valForm.controls['name'].disable();
            this.valForm.controls['shortName'].disable();
            this.valForm.controls['occupancy'].disable();
            this.valForm.controls['customTag'].disable();
            this.valForm.controls['displayOrder'].disable();
            this.valForm.controls['childrenAvailability'].disable();
            this.valForm.controls['maxChildren'].disable();
            this.valForm.controls['cleaningFrequencies'].disable();
            this.valForm.controls['beddingArangement'].disable();
            this.valForm.controls['amenities'].disable();
            this.valForm.controls['class'].disable();
            this.valForm.controls['rackRate'].disable();
            this.valForm.controls['desc'].disable();
        }
    }

    ngOnInit() {
        this.init();

        if (!this.hasOrder) {
            this.valForm.controls['displayOrder'].disable();
        }
    }

    init() {
        this.signUpService.getDetailsRoomType(this.idOfRoomType).subscribe(
            (response) => {
                this.roomTypeDetailsModel = response;
                this.cleaningFrequencies = new ArrayStore({
                    data: this.roomTypeDetailsModel.pageData.cleaningFrequencies,
                    key: 'id',
                });
                this.amenities = new ArrayStore({
                    data: this.roomTypeDetailsModel.pageData.amenities,
                    key: 'id',
                });
                this.popupLoader = false;
                this.showEditor = true;
                this.selectedBeddingArrangments = this.roomTypeDetailsModel.roomType.beddingArangement;
                this.roomTypeDetailsModel.pageData.beddingArangements.forEach((element) => {
                    element['disabled'] = element['name'] === 'Custom'; //LH1089
                });
                this.roomTypePictures = this.roomTypeDetailsModel.roomType.roomTypePictures.sort((a, b) =>
                    a.order > b.order ? 1 : -1
                );
                if (this.roomTypePictures.length !== 3) {
                    this.getDefaultRoomTypePictures();
                }
            },
            (error) => {
                this.popupLoader = false;
            }
        );
    }

    getDefaultRoomTypePictures() {
        for (let i = this.roomTypePictures.length + 1; i <= 3; i++) {
            const picture = new RoomTypePictureModel();
            picture.order = i;
            this.roomTypePictures.push(picture);
        }
    }

    validateDisplayOrder() {
        if (this.hasOrder) {
            for (const number of this.displayOrderList) {
                if (number === this.roomTypeDetailsModel.roomType.displayOrder) {
                    return false;
                }
            }
        }
        return true;
    }

    setCleaningFrequencies(e) {
        this.roomTypeDetailsModel.roomType.cleaningFrequencies = e.value;
    }

    setAmenities(e) {
        this.roomTypeDetailsModel.roomType.amenities = e.value;
    }

    submitRoomType(e) {
        e.stopPropagation();
        this.submittedForm = true;
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        this.files = [];
        for (const picture of this.roomTypePictures) {
            if (picture.file) {
                this.files.push(picture.file);
            }
        }

        if (
            this.valForm.valid &&
            this.valForm.controls['maxChildren'].value < this.valForm.controls['occupancy'].value &&
            this.validateDisplayOrder() &&
            this.roomTypeDetailsModel.roomType.description
        ) {
            this.loader = true;
            this.updateRoomType();
        }
    }

    updateRoomType() {
        this.roomTypeDetailsModel.roomType.beddingArangement = this.selectedBeddingArrangments;
        this.signUpService.updateRoomType(this.roomTypeDetailsModel.roomType, this.files).subscribe(
            (response) => {
                this.loader = false;
                this.update.emit();
            },
            (error) => {
                this.loader = false;
                console.log(error);
            }
        );
    }

    uploadImage(event, index) {
        const component = this;
        const files: any = <HTMLElement>event.target.files;
        const urlStringReader = new FileReader();
        if (files.length && files.length > 0 && this.isValidUploadData(files)) {
            const file = files[0];

            urlStringReader.addEventListener('load', function (event: any) {
                const src = event.target.result;
                component.roomTypePictures[index].thumbnail = src.split(',').pop();
                component.roomTypePictures[index].file = file;
                component.roomTypePictures[index].name = file.name;
            });

            urlStringReader.readAsDataURL(file);
        } else {
            this.toasterService.showToast('Error', 'File size exceeds the set limit of 4MB. Please try with a smaller file.', null, ToastEventType.Error, true, 5000);
        }
    }

    isValidUploadData(files: FileList) {
        let valid = true;
        Object.keys(files).forEach((i) => {
            if (files[i].size >= 4000000) {
                valid = false;
            }
        });
        return valid;
    }

    removeImage(picture) {
        if (picture.hasOwnProperty('id')) {
            this.loader = true;
            this.signUpService.removePicture(picture.id).subscribe(
                (response) => {
                    this.init();
                    this.loader = false;
                },
                (error) => {
                    console.log(error);
                    this.loader = false;
                }
            );
        } else {
            //this is just a local image file not yet sent to api
            const index = this.roomTypePictures.indexOf(picture);
            if (this.roomTypePictures.length > index) {
                this.roomTypePictures[index].thumbnail = '';
                this.roomTypePictures[index].file = null;
                this.roomTypePictures[index].name = null;
            }
        }
    }

    sanitize(picture) {
        const index = this.roomTypePictures.indexOf(picture);
        if (index > -1) {
            if (this.roomTypePictures[index].thumbnail == null || this.roomTypePictures[index].thumbnail.length === 0) {
                return this.sanitizer.bypassSecurityTrustUrl('../../../../../assets/images/room-type-placeholder.png');
            }
            return this.sanitizer.bypassSecurityTrustUrl(
                `data:image/jpeg;base64,${this.roomTypePictures[index].thumbnail}`
            );
        } else {
            return this.sanitizer.bypassSecurityTrustUrl('../../../../../assets/images/room-type-placeholder.png');
        }
    }

    openRateSchedulesFromRoomTypes() {
        this.rateSchedule = true;
    }

    close() {
        this.editRoomTypeChange.emit(false);
    }

    setValueFromEditor(value) {
        this.roomTypeDetailsModel.roomType.description = value;
        /*const div = document.createElement('div');
    div.innerHTML = this.messageTemplatePDModel.messageTemplate.message;
    this.messageWithoutHTMLTags = div.textContent || div.innerText || '';*/
    }
}
