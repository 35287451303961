<div class="rate-plans-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup epos-allowance" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>EPOS allowance - {{ ratePlanName }}</h2>

            <form [formGroup]="valForm" (ngSubmit)="submitEPOSAllowance($event)">

            <div class="flex rate-plan">
                <div class="flex w-30p">
                    <div class="radio-items">
                            <input
                            type="radio"
                            [checked]="allowanceModel.eposAllowanceType=='CategoryBased'"
                            (change)="setType(1)"
                            value="CategoryBased"
                            id="allowance-type-category"
                            formControlName="type"
                            class="css-checkbox"
                        />
                        <label for="allowance-type-category" class="css-label radGroup1"></label>
                        <label>Use PLU Category</label>
                    </div>
                    <div class="radio-items">
                            <input
                            type="radio"
                            [checked]="allowanceModel.eposAllowanceType=='SubCategoryBased'"
                            (change)="setType(2)"
                            value="SubCategoryBased"
                            id="allowance-type-subcategory"
                            formControlName="type"
                            class="css-checkbox"
                        />
                        <label for="allowance-type-subcategory" class="css-label radGroup1"></label>
                        <label>Use PLU Subcategory</label>
                    </div>
                </div>
                <div class="flex w-30p">
                    <div class="form-item">
                        <label>Allowance:</label>

                        <input
                            min="0"
                            max="10000"
                            type="number"
                            class="custom-input"
                            formControlName="amount"
                            [(ngModel)]="allowanceModel.eposAllowanceAmount"
                        />
                    </div>
                </div>
                
                <div class="flex w-30p">
                    <div class="radio-items">
                            <input
                            type="radio"
                            [checked]="allowanceModel.eposAllowanceChargeType=='PerRoomPerDay'"
                            (change)="setChargeType(1)"
                            id="allowance-chargeType-room"
                            value="PerRoomPerDay"
                            formControlName="chargeType"
                            class="css-checkbox"
                        />
                        <label for="allowance-chargeType-room" class="css-label radGroup1"></label>
                        <label>Per room per day</label>
                    </div>
                    <div class="radio-items">
                            <input
                            type="radio"
                            [checked]="allowanceModel.eposAllowanceChargeType=='PerPersonPerDay'"
                            (change)="setChargeType(2)"
                            id="allowance-chargeType-person"
                            value="PerPersonPerDay"
                            formControlName="chargeType"
                            class="css-checkbox"
                        />
                        <label for="allowance-chargeType-person" class="css-label radGroup1"></label>
                        <label>Per person per day (excludes children)</label>
                    </div>
                    <div class="radio-items">
                        <input
                        type="radio"
                        [checked]="allowanceModel.eposAllowanceChargeType=='PerRoom'"
                        (change)="setChargeType(3)"
                        id="allowance-chargeType-stay"
                        value="PerRoom"
                        formControlName="chargeType"
                        class="css-checkbox"
                    />
                    <label for="allowance-chargeType-person" class="css-label radGroup1"></label>
                    <label>Per room per stay</label>
                </div>
                </div>
            </div>

        
            <div>Select which items should be applicable for this allowance</div>
            <dx-data-grid
            [dataSource]="filteredAllowanceItems"
            [(selectedRowKeys)]="selectedItems"
            [allowColumnResizing]="true"
            [columns]="[
                { dataField: 'eposValue', caption: 'Name', alignment: 'left', allowSorting: true },
                { dataField: 'eposKey', caption: 'PLU CatID', alignment: 'left', allowSorting: true },
                
            ]"
        >

            <dxo-selection showCheckBoxesMode="always" [allowSelectAll]="true" mode="multiple"> </dxo-selection>
            <dxo-paging [pageSize]="8"></dxo-paging>
            <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true"> </dxo-pager>
        </dx-data-grid>


            <button
            *ngIf="!loader"
            type="submit"
            class="custom-button blue left"
            >
            Save
            </button>
            <div *ngIf="loader" class="loader left"></div>
            <button type="button" (click)="close()" class="custom-button red">
                {{ permissionsModel.formButtonEnabled ? 'Cancel' : 'Close' }}
            </button>

            </form>
        </div>
    </div>
</div>