import { PPCloseOutModel, PPCloseOutPopupSourceModel } from './../../../../models/price-plan/price-plan-page.model';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PricingPlanService } from './pricing-plan.service';
import {
    PPNightsModel,
    PPRoomTypeModel,
    PricePlanPageModel,
} from '../../../../models/price-plan/price-plan-page.model';
import { ActivatedRoute, Params } from '@angular/router';
import { RoomTypeBaseModel } from '../../../../models/room-type/room-type-base.model';
import { RatePlanWithDerivedPricingModel } from '../../../../models/rate-plan/rate-plan-with-derived-pricing.model';
import { PricePlanRangeModel } from '../../../../models/price-plan/price-plan-range.model';
import { CurrencyContext } from '../../../../models/auth-jwt/token-context';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { AvailabilityRequestPricePlanModel } from '../../../../models/price-plan/availability-request-price-plan.model';
import { CookieService } from 'ngx-cookie-service';
import { RateScheduleWithRatePlanModel } from '../../../../models/rate-schedule/rate-schedule-with-rate-plan.model';
import { PricePlanPermissionsModel } from '../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../enums/permissions.enum';

@Component({
    selector: 'app-pricing-plans',
    templateUrl: './pricing-plans.component.html',
    styleUrls: ['./pricing-plans.component.less'],
})
export class PricingPlansComponent implements OnInit, OnDestroy {
    permissionsModel: PricePlanPermissionsModel;

    top: number;
    left: number;
    loader: boolean;
    hasChannelManager: boolean;
    showCloseOut: boolean;

    subscriptionRatePlan: any;
    subscriptionPricePlan: any;

    dateFromInput: string;
    dateToInput: string;
    closeOutStatus: string;
    timeoutId: any;

    inactiveRatePlans = false;

    // open different popups
    pricePlanDetails = false;
    insertRegular = false;
    updateRegular = false;
    insertWeekday = false;
    updateWeekday = false;
    prevLoader = false;
    nextLoader = false;
    currencyText = false;
    deriveOptions = false;
    dynamicPricing = false;
    occupancyRules = false;
    currentDateRange: string;
    popup = false;
    showTooltip = true;

    roomBased = true;

    // derived options
    // masterRatePlan = false;

    weekdays = false;
    regular = false;
    ratePlanSelected = false;
    size: any;
    windowSize: any;

    ratePlanId: number;
    rateScheduleId: number;
    roomTypeId: number;
    pricePlanId: number;
    availabilityRooms: number;
    closeOutRatePlanId: number;
    closeOutRoomTypeId: number;

    nights: PPNightsModel[] = [];
    selectedNights: number;
    minLosArray: number[] = [];

    // dates
    startDate: Date = new Date();
    endDate: Date = new Date();
    closeOutDateFrom: Date;
    datesArray: string[] = [];

    rateSchedulesWithRatePlans: RateScheduleWithRatePlanModel[] = [];
    selectedPrice = new PricePlanRangeModel();
    ratePlan = new RatePlanWithDerivedPricingModel();
    pricePlanPageModel = new PricePlanPageModel();
    roomTypeBaseModel = new RoomTypeBaseModel();
    availabilityRequestPricePlanModel = new AvailabilityRequestPricePlanModel();
    currencyContext: CurrencyContext = new CurrencyContext();
    closeOutPopUpSource: PPCloseOutPopupSourceModel;

    editRoomType = false;
    subscriptionAvailability: any;

    theme: number;

    constructor(
        private pricingPlansService: PricingPlanService,
        private route: ActivatedRoute,
        private cookieService: CookieService,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.PricePlans);
        this.currencyContext = AuthTokenService.decodeJwtToken().Currency;

        // cookie configuration
        const match = document.cookie.match(new RegExp('(^| )dateFrom=([^;]+)'));
        if (match && match.length > 1) {
            const dateFrom: string = match[2];
            const dateFromDecoded: string = decodeURI(dateFrom);
            this.endDate = new Date(dateFromDecoded);
        }

        const expiresDate: Date = new Date();
        expiresDate.setMinutes(expiresDate.getMinutes() + 10);
        this.setCookie(this.endDate.toDateString(), expiresDate);

        // cookieService.set('dateFrom', this.endDate.toDateString(), expiresDate, '');

        // const cookieExist: boolean = cookieService.check('dateFrom');
        // if (cookieExist) {
        //   this.endDate = new Date(cookieService.get('dateFrom'));
        // }

        this.getNextTwoWeeks(false);

        this.route.params.subscribe((params: Params) => {
            this.rateScheduleId = params['rateScheduleId'];
            this.ratePlanId = params['ratePlanId'];
            if (
                typeof this.ratePlanId === 'undefined' ||
                this.ratePlanId === null ||
                typeof this.rateScheduleId === 'undefined' ||
                this.rateScheduleId === null
            ) {
                this.ratePlanSelected = false;
            } else {
                this.ratePlanSelected = true;
                this.rateScheduleId = +params['rateScheduleId'];
                this.ratePlanId = +params['ratePlanId'];
                this.getSchedulesWithRatePlans();
            }
        });
    }

    setCookie(cookieValue, expiryDate) {
        const expires = 'expires=' + expiryDate.toUTCString();
        document.cookie = 'dateFrom=' + cookieValue + ';' + expires + ';path=/';
    }

    ngOnInit() {
        this.init();
    }

    init() {
        this.loader = true;

        if (this.ratePlanSelected) {
            const monthFrom = new Date(this.datesArray[0]).toLocaleString('en-us', { month: 'long' });
            const monthTo = new Date(this.datesArray[this.datesArray.length - 1]).toLocaleString('en-us', {
                month: 'long',
            });

            const yearFrom = new Date(this.datesArray[0]).getFullYear();
            const yearTo = new Date(this.datesArray[this.datesArray.length - 1]).getFullYear();

            if (monthFrom === monthTo && yearFrom === yearTo) {
                this.currentDateRange = monthFrom + ' / ' + yearFrom;
            } else if (monthFrom !== monthTo && yearFrom === yearTo) {
                this.currentDateRange = monthFrom + ' - ' + monthTo + ' / ' + yearFrom;
            } else {
                this.currentDateRange = monthFrom + ' / ' + yearFrom + ' - ' + monthTo + ' / ' + yearTo;
            }

            if (typeof this.subscriptionPricePlan !== 'undefined') {
                this.subscriptionPricePlan.unsubscribe();
            }

            this.subscriptionPricePlan = this.pricingPlansService
                .getPricePlanByRange(
                    this.rateScheduleId,
                    this.ratePlanId,
                    this.datesArray[0],
                    this.datesArray[this.datesArray.length - 1]
                )
                .subscribe(
                    (response) => {
                        this.loader = false;
                        this.prevLoader = false;
                        this.nextLoader = false;
                        this.pricePlanPageModel = response;
                        this.hasChannelManager = this.pricePlanPageModel.roomTypes.some((x) => x.otaRestriction);
                        this.currencyText = true;

                        this.ratePlan = this.getRatePlanId(this.pricePlanPageModel.ratePlans, this.ratePlanId);

                        if (this.ratePlan.pricingType === 'RoomBased') {
                            this.roomBased = true;
                        } else {
                            this.roomBased = false;
                        }

                        for (const item of this.ratePlan.ratePlanDerivedPricings) {
                            for (const roomType of this.pricePlanPageModel.roomTypes) {
                                if (item.slaveRoomTypeId === roomType.roomType.id) {
                                    roomType.slave = true;
                                    roomType.roomType.masterRoomTypeId = item.masterRoomTypeId;
                                    roomType.roomType.masterRoomTypeName = item.roomType.shortName;
                                }
                            }
                        }

                        switch (this.ratePlan.bookingType) {
                            case 'AnyDay':
                                this.weekdays = false;
                                this.regular = true;
                                break;
                            case 'SpecificDay':
                                this.regular = false;
                                this.weekdays = true;
                                break;
                        }

                        this.pricePlanPageModel.roomTypes.forEach((item) => {
                            item.nights.forEach((night) => {
                                night.prices.forEach((price) => {
                                    price.priceDate = new Date(price.priceDate);
                                    price.priceDateString = price.priceDate.toDateString();
                                });
                            });
                        });

                        for (let i = 0; i < this.pricePlanPageModel.roomTypes.length; i++) {
                            for (let j = 0; j < this.pricePlanPageModel.roomTypes[i].nights.length; j++) {
                                this.pricePlanPageModel.roomTypes[i].nights[j].allPrices = [];
                                for (let k = 0; k < this.datesArray.length; k++) {
                                    let counter = 0;
                                    let priceExist: PricePlanRangeModel = new PricePlanRangeModel();

                                    for (
                                        let l = 0;
                                        l < this.pricePlanPageModel.roomTypes[i].nights[j].prices.length;
                                        l++
                                    ) {
                                        if (
                                            this.datesArray[k] ===
                                            this.pricePlanPageModel.roomTypes[i].nights[j].prices[l].priceDateString
                                        ) {
                                            priceExist = this.pricePlanPageModel.roomTypes[i].nights[j].prices[l];
                                            counter++;
                                        }
                                    }

                                    if (counter > 0) {
                                        this.pricePlanPageModel.roomTypes[i].nights[j].allPrices[k] = priceExist;
                                    } else {
                                        const price: PricePlanRangeModel = new PricePlanRangeModel();
                                        price.baseRate = 0;
                                        price.finalRate = 0;
                                        price.priceDateString = this.datesArray[k];
                                        price.minLoS = this.pricePlanPageModel.roomTypes[i].nights[j].minLoS;
                                        this.pricePlanPageModel.roomTypes[i].nights[j].allPrices[k] = price;
                                    }
                                }
                            }
                        }
                    },
                    (error) => {
                        this.loader = false;
                        this.prevLoader = false;
                        this.nextLoader = false;
                        console.log(error);
                    }
                );
        } else {
            this.subscriptionRatePlan = this.pricingPlansService.getSchedulesWithRatePlans().subscribe(
                (response) => {
                    this.loader = true;
                    this.prevLoader = false;
                    this.nextLoader = false;
                    this.rateSchedulesWithRatePlans = response;
                    if (this.rateSchedulesWithRatePlans.length > 0) {
                        this.rateScheduleId = this.rateSchedulesWithRatePlans[0].id;
                        if (this.rateSchedulesWithRatePlans[0].ratePlans.length > 0) {
                            this.ratePlanId = this.rateSchedulesWithRatePlans[0].ratePlans[0].id;
                            this.ratePlanSelected = true;
                            this.init();
                        } else {
                            this.loader = false;
                            this.inactiveRatePlans = true;
                        }
                    }
                },
                (error) => {
                    this.loader = true;
                    console.log(error);
                }
            );
        }

        this.size = <HTMLElement>document.querySelectorAll('html')[0];
        if (this.size.getBoundingClientRect().width < 1025) {
            this.showTooltip = false;
        }
    }

    getSchedulesWithRatePlans() {
        this.pricingPlansService.getSchedulesWithRatePlans().subscribe(
            (response) => {
                this.prevLoader = false;
                this.nextLoader = false;
                this.rateSchedulesWithRatePlans = response;
            },
            (error) => console.log(error)
        );
    }

    datePickerFunction(e) {
        if (e.value !== null) {
            this.datesArray = [];
            const chosenDate: Date = e.value;

            const currentDate: Date = new Date(chosenDate);
            const endDate: Date = new Date(chosenDate);
            endDate.setDate(endDate.getDate() + 17);

            while (currentDate < endDate) {
                const newDate: Date = new Date(currentDate);
                this.datesArray.push(newDate.toDateString());
                currentDate.setDate(currentDate.getDate() + 1);
            }

            const startDay = new Date(this.datesArray[0]);
            const endDay = new Date(this.datesArray[this.datesArray.length - 1]);
            this.startDate = new Date(startDay);
            this.endDate = new Date(endDay);
            this.startDate.setDate(startDay.getDate() - 1);
            this.endDate.setDate(endDay.getDate() + 1);

            const expiresDate: Date = new Date();
            expiresDate.setMinutes(expiresDate.getMinutes() + 10);
            this.setCookie(new Date(this.datesArray[0]).toDateString(), expiresDate);

            // this.cookieService.set('dateFrom', new Date(this.datesArray[0]).toDateString(), expiresDate, '');

            this.init();
        }
    }

    setDateToToday() {
        this.endDate = new Date();
        this.getNextTwoWeeks(true);
    }

    getNextTwoWeeks(fromConstructor?: boolean) {
        this.datesArray = [];
        this.nextLoader = true;

        const currentDate: Date = new Date(this.endDate);
        const endDate: Date = new Date(this.endDate);
        endDate.setDate(endDate.getDate() + 17);

        while (currentDate < endDate) {
            const newDate: Date = new Date(currentDate);
            this.datesArray.push(newDate.toDateString());
            currentDate.setDate(currentDate.getDate() + 1);
        }

        const startDay = new Date(this.datesArray[0]);
        const endDay = new Date(this.datesArray[this.datesArray.length - 1]);
        this.startDate = new Date(startDay);
        this.endDate = new Date(endDay);
        this.startDate.setDate(startDay.getDate() - 1);
        this.endDate.setDate(endDay.getDate() + 1);

        if (fromConstructor) {
            const expiresDate: Date = new Date();
            expiresDate.setMinutes(expiresDate.getMinutes() + 10);
            this.setCookie(new Date(this.datesArray[0]).toDateString(), expiresDate);
            // this.cookieService.set('dateFrom', new Date(this.datesArray[0]).toDateString(), expiresDate, '');

            this.init();
        }
    }

    getPreviousTwoWeeks() {
        this.datesArray = [];
        this.prevLoader = true;

        const endDate: Date = new Date(this.startDate);
        const startDate: Date = new Date(this.startDate);
        startDate.setDate(startDate.getDate() - 16);

        while (startDate <= endDate) {
            const newDate: Date = new Date(startDate);
            this.datesArray.push(newDate.toDateString());
            startDate.setDate(startDate.getDate() + 1);
        }

        const startDay = new Date(this.datesArray[0]);
        const endDay = new Date(this.datesArray[this.datesArray.length - 1]);
        this.startDate = new Date(startDay);
        this.endDate = new Date(endDay);
        this.startDate.setDate(startDay.getDate() - 1);
        this.endDate.setDate(endDay.getDate() + 1);

        const expiresDate: Date = new Date();
        expiresDate.setMinutes(expiresDate.getMinutes() + 10);
        this.setCookie(new Date(this.datesArray[0]).toDateString(), expiresDate);
        // this.cookieService.set('dateFrom', new Date(this.datesArray[0]).toDateString(), expiresDate, '');

        this.init();
    }

    filterByRatePlan(e) {
        if (e.itemData !== null) {
            this.ratePlanSelected = true;
            this.init();
        }
    }

    filterByRateSchedule(e) {
        if (e.itemData !== null) {
            const rateScheduleWithRatePlans: any = e.itemData;
            this.pricePlanPageModel.ratePlans = rateScheduleWithRatePlans.ratePlans;
            if (this.pricePlanPageModel.ratePlans.length > 0) {
                this.ratePlanId = this.pricePlanPageModel.ratePlans[0].id;
                this.ratePlanSelected = true;
                this.init();
            } else {
                this.ratePlanSelected = false;
                this.ratePlanId = null;
            }
        }
    }

    getRatePlanId(
        listRatePlans: RatePlanWithDerivedPricingModel[],
        ratePlanId: number
    ): RatePlanWithDerivedPricingModel {
        for (const ratePlan of listRatePlans) {
            if (ratePlan.id === ratePlanId) {
                return ratePlan;
            }
        }
    }

    openAddPricePlan(
        slave: boolean,
        roomType: RoomTypeBaseModel,
        nights: PPNightsModel[],
        selectedNights: number,
        exactDate: string
    ) {
        if (!slave && this.ratePlan.masterPlan === null) {
            this.minLosArray = [];
            nights.forEach((item) => {
                this.minLosArray.push(item.minLoS);
            });

            if (exactDate !== null) {
                this.dateFromInput = exactDate;
                this.dateToInput = exactDate;
            } else {
                this.dateFromInput = this.datesArray[0];
                this.dateToInput = this.datesArray[this.datesArray.length - 1];
            }

            this.selectedNights = selectedNights;
            this.roomTypeBaseModel = roomType;
            if (this.regular) {
                this.insertWeekday = false;
                this.insertRegular = true;
            } else if (this.weekdays) {
                this.insertRegular = false;
                this.insertWeekday = true;
            }
        }
    }

    openEditPricePlan(
        slave: boolean,
        roomType: RoomTypeBaseModel,
        nights: PPNightsModel[],
        price: PricePlanRangeModel,
        exactDate: string
    ) {
        if (!slave && this.ratePlan.masterPlan === null) {
            if (exactDate !== null) {
                this.dateFromInput = exactDate;
                this.dateToInput = exactDate;
            } else {
                this.dateFromInput = this.datesArray[0];
                this.dateToInput = this.datesArray[this.datesArray.length - 1];
            }

            this.nights = nights;
            this.roomTypeBaseModel = roomType;
            this.selectedPrice = price;
            if (this.regular) {
                this.updateWeekday = false;
                this.updateRegular = true;
            } else if (this.weekdays) {
                this.updateRegular = false;
                this.updateWeekday = true;
            }
        }
    }

    showPopup(event, price: PricePlanRangeModel) {
        // setTimeout(() => {
        //   price.show = true;
        // }, 500);
        this.timeoutId = setTimeout(() => {
            price.show = true;
        }, 1000);

        this.availabilityRequestPricePlanModel.roomTypeId = price.roomTypeId;
        this.availabilityRequestPricePlanModel.dateFrom = price.priceDate.toDateString();
        const dateTo: Date = new Date(price.priceDate);
        this.availabilityRequestPricePlanModel.dateTo = new Date(dateTo.getTime() + 24 * 60 * 60 * 1000).toDateString();
        this.availabilityRooms = null;

        this.subscriptionAvailability = this.pricingPlansService
            .getAvailabilityPricePlan(this.availabilityRequestPricePlanModel)
            .subscribe(
                (response) => {
                    this.availabilityRooms = response;
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    hidePopup(price) {
        clearTimeout(this.timeoutId);
        price.show = false;
        if (typeof this.subscriptionAvailability !== 'undefined') {
            this.subscriptionAvailability.unsubscribe();
        }
    }

    openDynamicPricing(roomTypeId) {
        this.roomTypeId = roomTypeId;
        this.dynamicPricing = true;
    }

    openOccupancyRules(roomTypeId) {
        this.roomTypeId = roomTypeId;
        this.occupancyRules = true;
    }

    openDeriveOptions() {
        this.deriveOptions = true;
    }

    openCloseOutModal(closeOut: PPCloseOutModel) {
        this.closeOutStatus = closeOut.status;
        this.closeOutRatePlanId = closeOut.ratePlanId;
        this.closeOutRoomTypeId = closeOut.roomTypeId;
        this.closeOutDateFrom = closeOut.date;

        this.pricingPlansService
            .getCloseOutPopupUpdate(this.rateScheduleId)
            .subscribe((res: PPCloseOutPopupSourceModel) => {
                this.closeOutPopUpSource = res;
                this.showCloseOut = true;
            });
    }

    ngOnDestroy() {
        if (typeof this.subscriptionPricePlan !== 'undefined') {
            this.subscriptionPricePlan.unsubscribe();
        }

        if (typeof this.subscriptionRatePlan !== 'undefined') {
            this.subscriptionRatePlan.unsubscribe();
        }
    }

    editRoom(id) {
        this.editRoomType = true;
        this.roomTypeId = id;
        //console.log(id);
    }
}
