<!--<app-layout></app-layout>-->
<!--<app-side-menu></app-side-menu>-->

<div class="room-type-wrapper flex">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <h2>Emails</h2>

        <dx-data-grid
            [dataSource]="emails"
            class="room-type-grid"
            [columns]="[
                { dataField: 'Name', caption: 'Name', allowSorting: true },
                { dataField: 'Frequency', caption: 'Frequency' },
                { dataField: 'Status', caption: 'Status', width: '110' },
                { cellTemplate: 'edit', width: '60' },
                { cellTemplate: 'delete', width: '60' }
            ]"
        >
            <div *dxTemplate="let a of 'edit'">
                <i title="Edit" class="ml-10 fas fa-pen-to-square fa-icon gray fa-icon-small" (click)="openEditEmailItem(a)"></i>
            </div>
            <div *dxTemplate="let a of 'images'">
                <i title="Images" class="fas fa-image fa-icon gray fa-icon-small"></i>
            </div>
            <div *dxTemplate="let a of 'delete'">
                <i title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small"></i>
            </div>
            <dxo-scrolling mode="standard"></dxo-scrolling>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true"> </dxo-pager>
        </dx-data-grid>

        <div (click)="openAddEmailItem()" class="add-new-box">
            <span class="text">Add New</span>
            <div class="img">
                <i class="fa-solid fa-plus fa-icon gray"></i>
            </div>
        </div>
    </div>
</div>

<app-settings-emails-item
    (editItemChange)="changeto()"
    [editItem]="editItem"
    [email]="email"
    [emails]="emails"
    *ngIf="editItem"
>
</app-settings-emails-item>

<app-settings-emails-item [(addItem)]="addItem" *ngIf="addItem"></app-settings-emails-item>
