import { RoomTypeModel } from '../room-type/room-types.model';

export class RoomsMappingModel {
    public rooms: RoomModel[];
    public roomTypes: RoomTypeModel[];
}

export class RoomModel {
    id: number;
    propertyId: number;
    fullName: string;
    shortName: string;
    customTag: string;
    status: string;
    lastRoomTypeId: number;
    isFunction: boolean;
    accommodationTypeId: number;
    note: string;
    dateCreated: Date;
    public assignRoomType: boolean;
    virtualRooms: RoomModel[] = [];
    orderNumber: number;

    // added new properties
    public addedRoomTypes: RoomTypeModel[] = [];

    constructor(id?: number, shortName?: string, fullName?: string) {
        this.id = id;
        this.shortName = shortName;
        this.fullName = fullName;
        this.assignRoomType = true;
    }
}
