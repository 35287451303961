export class PartnerModel {
    public id: number;
    public name: string;
    public rateScheduleId: number;
    public shortName: string;
    public email: string;
    public phone: string;
    public phone2: string;
    public address1: string;
    public address2: string;
    public address3: string;
    public postalCode: string;
    public countryId: number;
    public active: boolean;
    public discountType: string;
    public discountAmount: number;
}
