<div class="partners-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup flex" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2 *ngIf="addPartnerPopup">Add Partner</h2>
            <h2 *ngIf="editPartnerPopup">{{ permissionsModel.formButtonEnabled ? 'Edit Partner' : 'Partner' }}</h2>

            <form
                [formGroup]="valForm"
                (ngSubmit)="submitProperty($event)"
                class="form-box flex"
                [ngClass]="[theme == 2 ? 'dark-theme' : '']"
            >
                <div class="left-field">
                    <div class="form-item">
                        <div class="custom-label">Partner's name</div>
                        <input
                            [(ngModel)]="partnerPDModel.partnerModel.name"
                            formControlName="name"
                            class="custom-input"
                            name="partnersName"
                            [readOnly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['name'].hasError('required') &&
                                (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                            "
                            >Name is required</span
                        >
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['name'].hasError('maxlength') &&
                                (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                            "
                            >Max 50 characters</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Partner's short name</div>
                        <input
                            [(ngModel)]="partnerPDModel.partnerModel.shortName"
                            formControlName="shortName"
                            class="custom-input"
                            name="partnersShortName"
                            [readOnly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['shortName'].hasError('required') &&
                                (valForm.controls['shortName'].dirty || valForm.controls['shortName'].touched)
                            "
                            >Short Name is required</span
                        >
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['shortName'].hasError('maxlength') &&
                                (valForm.controls['shortName'].dirty || valForm.controls['shortName'].touched)
                            "
                            >Max 50 characters</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Partner's email</div>
                        <input
                            [(ngModel)]="partnerPDModel.partnerModel.email"
                            formControlName="email"
                            class="custom-input"
                            name="partnersEmail"
                            [readOnly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['email'].hasError('email') &&
                                !valForm.controls['email'].hasError('required') &&
                                (valForm.controls['email'].dirty || valForm.controls['email'].touched)
                            "
                            >Please enter correct email address</span
                        >
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['email'].hasError('maxlength') &&
                                (valForm.controls['email'].dirty || valForm.controls['email'].touched)
                            "
                            >Max 50 characters</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Partner's phone number</div>
                        <!--<div class="phone-control-wrapper">
            <dx-select-box class="custom-select phone-select-box"
                           [(value)]="dialCode"
                           [dataSource]="diallingCodesList"
                           [showClearButton]="true"
                           [placeholder]="'Select dial code...'"
                           displayExpr="label"
                           valueExpr="dialCode"
                           name="dialling-code">
            </dx-select-box>
            <input type="text" class="custom-input" name="phone-partner" formControlName="phoneNumber" [(ngModel)]="phoneNumber">
            <span class="text-danger" *ngIf="valForm.controls['phoneNumber'].hasError('maxlength') && (valForm.controls['phoneNumber'].dirty || valForm.controls['phoneNumber'].touched)">Max 50 characters</span>
            <span class="text-danger">{{ phoneErrorMessage }}</span>
          </div>-->
                        <input
                            type="text"
                            class="custom-input"
                            name="phone-partner"
                            formControlName="phoneNumber"
                            [(ngModel)]="partnerPDModel.partnerModel.phone"
                            [readOnly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['phoneNumber'].hasError('maxlength') &&
                                (valForm.controls['phoneNumber'].dirty || valForm.controls['phoneNumber'].touched)
                            "
                            >Max 50 characters</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Partner's mobile number</div>
                        <div class="telephone-wrapper">
                            <input
                                [(ngModel)]="partnerPDModel.partnerModel.phone2"
                                type="text"
                                formControlName="phone2"
                                class="custom-input"
                                name="partnersPhone2"
                                [readOnly]="!permissionsModel.formButtonEnabled"
                            />
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['phone2'].hasError('maxlength') &&
                                    (valForm.controls['phone2'].dirty || valForm.controls['phone2'].touched)
                                "
                                >Max 50 characters</span
                            >
                        </div>
                    </div>
                </div>
                <div class="right-field">
                    <div class="form-item">
                        <div class="custom-label">Partner's Address 1</div>
                        <input
                            [(ngModel)]="partnerPDModel.partnerModel.address1"
                            formControlName="address1"
                            class="custom-input"
                            name="partnersAddress1"
                            [readOnly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['address1'].hasError('maxlength') &&
                                (valForm.controls['address1'].dirty || valForm.controls['address1'].touched)
                            "
                            >Max 50 characters</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Partner's Address 2</div>
                        <input
                            [(ngModel)]="partnerPDModel.partnerModel.address2"
                            formControlName="address2"
                            class="custom-input"
                            name="partnersAddress2"
                            [readOnly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['address2'].hasError('maxlength') &&
                                (valForm.controls['address2'].dirty || valForm.controls['address2'].touched)
                            "
                            >Max 50 characters</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Partner's Address 3</div>
                        <input
                            [(ngModel)]="partnerPDModel.partnerModel.address3"
                            formControlName="address3"
                            class="custom-input"
                            name="partnersAddress3"
                            [readOnly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['address3'].hasError('maxlength') &&
                                (valForm.controls['address3'].dirty || valForm.controls['address3'].touched)
                            "
                            >Max 50 characters</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Partner's postal code</div>
                        <input
                            [(ngModel)]="partnerPDModel.partnerModel.postalCode"
                            formControlName="postalCode"
                            class="custom-input"
                            name="partnersPostalCode"
                            [readOnly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['postalCode'].hasError('maxlength') &&
                                (valForm.controls['postalCode'].dirty || valForm.controls['postalCode'].touched)
                            "
                            >Max 50 characters</span
                        >
                    </div>
                </div>
                <div class="form-item half">
                    <div class="custom-label">Country</div>
                    <dx-select-box
                        class="custom-select"
                        name="partnersCountry"
                        formControlName="country"
                        displayExpr="name"
                        valueExpr="id"
                        [(value)]="partnerPDModel.partnerModel.countryId"
                        [dataSource]="countries"
                        displayExpr="name"
                        valueExpr="id"
                        [disabled]="!permissionsModel.formButtonEnabled"
                    >
                    </dx-select-box>
                </div>
                <div class="form-item half">
                    <div class="custom-label">Status</div>
                    <dx-select-box
                        class="custom-select"
                        [(value)]="partnerPDModel.partnerModel.active"
                        [dataSource]="statusModelList"
                        displayExpr="name"
                        formControlName="status"
                        valueExpr="value"
                        name="partnersStatus"
                        [disabled]="!permissionsModel.formButtonEnabled"
                    >
                    </dx-select-box>
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['status'].hasError('required') &&
                            (valForm.controls['status'].dirty || valForm.controls['status'].touched)
                        "
                        >Status is required</span
                    >
                </div>
                <div class="form-item half">
                    <div class="custom-label">Adjustment type</div>
                    <dx-select-box
                        class="custom-select"
                        [(value)]="partnerPDModel.partnerModel.discountType"
                        [dataSource]="partnerPDModel.discountTypes"
                        displayExpr="name"
                        formControlName="priceAdjustment"
                        valueExpr="value"
                        (onItemClick)="checkDiscountType($event)"
                        name="partnersDiscountType"
                        [disabled]="!permissionsModel.formButtonEnabled"
                    >
                    </dx-select-box>
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['priceAdjustment'].hasError('required') &&
                            (valForm.controls['priceAdjustment'].dirty || valForm.controls['priceAdjustment'].touched)
                        "
                    >
                        Adjustment type is required</span
                    >
                </div>
                <div class="form-item half">
                    <div class="custom-label">Price adjustment</div>
                    <input
                        [(ngModel)]="partnerPDModel.partnerModel.discountAmount"
                        formControlName="discAmount"
                        type="number"
                        class="custom-input price-adj"
                        name="partnersPriceAdjustment"
                        [readOnly]="!permissionsModel.formButtonEnabled"
                    />
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['discAmount'].hasError('required') &&
                            (valForm.controls['discAmount'].dirty || valForm.controls['discAmount'].touched)
                        "
                    >
                        Amount is required</span
                    >
                </div>
                <div class="form-item half">
                    <div class="custom-label">Rate schedule</div>
                    <dx-select-box
                        class="custom-select"
                        [(value)]="partnerPDModel.partnerModel.rateScheduleId"
                        [dataSource]="partnerPDModel.rateSchedules"
                        displayExpr="name"
                        formControlName="rateSchedule"
                        valueExpr="id"
                        name="partnersRateSchedule"
                        [disabled]="!permissionsModel.formButtonEnabled"
                    >
                    </dx-select-box>
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['rateSchedule'].hasError('required') &&
                            (valForm.controls['rateSchedule'].dirty || valForm.controls['rateSchedule'].touched)
                        "
                    >
                        Rate Schedule is required</span
                    >
                </div>
                <div class="form-item half"></div>
                <div class="actions actions-button flex">
                    <button
                        *ngIf="!loader && permissionsModel.formButtonEnabled"
                        type="submit"
                        class="custom-button blue left"
                    >
                        Save
                    </button>
                    <div *ngIf="loader" class="loader left"></div>
                    <button (click)="close()" type="button" class="custom-button red left">
                        {{ permissionsModel.formButtonEnabled ? 'Cancel' : 'Close' }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
