import { Component, OnInit } from '@angular/core';
import { AuthTokenService } from '../../../shared/services/auth-token.service';

@Component({
    selector: 'app-settings-emails',
    templateUrl: './settings-emails.component.html',
    styleUrls: ['./settings-emails.component.less'],
})
export class SettingsEmailsComponent implements OnInit {
    editItem = false;
    addItem = false;
    email: any;
    theme: number;

    emails = [
        {
            Name: 'After booking - standard',
            Frequency: 'After booking - 2 days',
            Status: 'Active',
        },
        {
            Name: 'Online-cheching - standard',
            Frequency: 'Before arrival - 5 days',
            Status: 'Active',
        },
        {
            Name: 'After booking - standard',
            Frequency: 'After booking - 2 days',
            Status: 'Active',
        },
        {
            Name: 'Online-cheching - standard',
            Frequency: 'Before arrival - 5 days',
            Status: 'Active',
        },
        {
            Name: 'After booking - standard',
            Frequency: 'After booking - 2 days',
            Status: 'Active',
        },
        {
            Name: 'Online-cheching - standard',
            Frequency: 'Before arrival - 5 days',
            Status: 'Active',
        },
    ];

    constructor() {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    }

    ngOnInit() {}
    changeto() {
        this.editItem = false;
    }

    openEditEmailItem(a) {
        this.email = a.data;
        this.editItem = true;
    }
    openAddEmailItem() {
        this.addItem = true;
    }
}
