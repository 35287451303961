import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpRequestService } from '../../../shared/services/http-request.service';

@Injectable()
export class OtaSyncOutLogsService {
    private getFilteredOtaSyncOutUrl = environment.apiUrl + '/OTA/GetFilteredOtaSyncOut';

    constructor(private http: HttpRequestService) {}

    getData(data) {
        return this.http.post(this.getFilteredOtaSyncOutUrl, data);
    }
}
