import { Component, OnInit } from '@angular/core';
import { SignupService } from '../signup.service';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GlobalSettingsModel } from '../../../models/shared/global-settings.model';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { Router } from '@angular/router';
import { AccountStatus } from '../../../enums/account.enum';

@Component({
    selector: 'app-room-number',
    templateUrl: './room-number.component.html',
    styleUrls: ['./room-number.component.less'],
})
export class RoomNumberComponent implements OnInit {
    loader = false;
    globalSettingsDefaultNumber: string;
    globalSettingsMaxNumber: string;
    valForm: UntypedFormGroup;
    numberOfRooms: number;

    constructor(
        private signUpService: SignupService,
        private fb: UntypedFormBuilder,
        private authTokenService: AuthTokenService,
        private router: Router
    ) {
        this.valForm = this.fb.group({
            numberOfRooms: [null],
        });
        this.signUpService.getDefaultNumberOfRooms().subscribe(
            (response) => {
                this.globalSettingsDefaultNumber = response;

                this.signUpService.getMaxNumberOfRooms().subscribe(
                    (maxNumber) => {
                        this.globalSettingsMaxNumber = maxNumber;
                        this.valForm.controls['numberOfRooms'].setValue(this.globalSettingsDefaultNumber);
                        this.valForm.controls['numberOfRooms'].setValidators(
                            Validators.compose([
                                Validators.required,
                                Validators.min(1),
                                Validators.max(+this.globalSettingsMaxNumber),
                            ])
                        );
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            },
            (error) => {
                console.log(error);
            }
        );
    }

    ngOnInit() {
        const chatScript = document.createElement('script');
        chatScript.src = 'https://static.zdassets.com/ekr/snippet.js?key=a1d8ade5-fd19-4dd2-9615-64723c484df8';
        chatScript.id = 'ze-snippet';
        document.body.appendChild(chatScript);
    }

    submitNumberOfRooms(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            let userId: number;
            this.loader = true;
            if (AuthTokenService.decodeJwtToken().IsSuperUser) {
                userId = AuthTokenService.decodeJwtToken().AdminUser.Id;
            } else {
                userId = AuthTokenService.decodeJwtToken().User.Id;
            }
            this.signUpService.setRoomsForProperty(userId, this.numberOfRooms).subscribe(
                (response) => {
                    this.loader = false;
                    this.authTokenService.insertTokenAfterSettingRooms(response);
                    this.router.navigate(['room-creation', this.numberOfRooms]);
                },
                (error) => {
                    console.log(error);
                    this.loader = false;
                }
            );
        }
    }
}
