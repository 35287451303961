import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastEventType } from 'src/app/enums/toast-event-type';
import { ToastService } from '../../services/toast.service';
import { ToastEvent } from 'src/app/models/shared/toast-event';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToasterComponent implements OnInit{
  currentToasts: ToastEvent[] = [];
  alertToasts: ToastEvent[] = [];
  toastSubscription!: Subscription;

  constructor(private toastService: ToastService, private cdr: ChangeDetectorRef)
  {

  }

  ngOnInit() {
    this.subscribeToToasts();
  }

  subscribeToToasts() {

    this.toastSubscription = this.toastService.toastEvents.subscribe((toasts: {type: any; title: any, primaryMessage: any, secondaryMessage: any, showCloseButton: any, timeOut: any, iconCssClass: any}) => {
      const currentToast: ToastEvent = {
        type: toasts.type,
        title: toasts.title,
        primaryMessage: toasts.primaryMessage,
        secondaryMessage: toasts.secondaryMessage,
        showCloseButton: toasts.showCloseButton,
        timeOut: toasts.timeOut,
        iconCssClass: toasts.iconCssClass
      };

      switch (currentToast.type) {
        case ToastEventType.Error: 
        case ToastEventType.ErrorAlert:
          currentToast.iconCssClass = 'fa-sharp fa-solid fa-triangle-exclamation fa-xl';
          break;
        case ToastEventType.Warning: 
          currentToast.iconCssClass = 'fa-sharp fa-solid fa-circle-exclamation fa-xl';
          break;
        case ToastEventType.Info: 
          currentToast.iconCssClass = 'fa-sharp fa-solid fa-circle-info fa-xl';
          break;
        case ToastEventType.Success: 
          currentToast.iconCssClass = 'fa-sharp fa-solid fa-circle-check fa-xl';
          break;
      }

      if (currentToast.type == ToastEventType.ErrorAlert) {
        currentToast.showCloseButton = false; //we have a different close button for alert
        this.alertToasts.push(currentToast);
      }
      else {
        this.currentToasts.push(currentToast);
        setTimeout(() => this.removeAlert(currentToast), currentToast.timeOut);
      }
      
      this.cdr.detectChanges();
    })
  }

  removeAlert(toast: ToastEvent) {
    // check if already removed to prevent error on auto close
    if (toast.type == ToastEventType.ErrorAlert) {
      if (!this.alertToasts.includes(toast)) return;
      this.alertToasts = this.alertToasts.filter(x => x !== toast);
    }
    else {
      if (!this.currentToasts.includes(toast)) return;
      this.currentToasts = this.currentToasts.filter(x => x !== toast);
    }

    this.cdr.detectChanges();
  }

  dispose(index: number) {
    this.currentToasts.splice(index, 1);
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    // unsubscribe to avoid memory leaks
    this.toastSubscription.unsubscribe();
  }
}
