<div class="rate-schedules-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Room Types - {{ rateScheduleName }}</h2>

            <dx-data-grid
                [dataSource]="rateScheduleRoomTypeModel.roomTypes"
                [allowColumnResizing]="true"
                rowTemplate="rowTemplateName"
                [columns]="[
                    { dataField: 'id', caption: 'ID', allowSorting: true, width: 50 },
                    { dataField: 'name', caption: 'Name', allowSorting: true, width: 150 },
                    { dataField: 'shortName', caption: 'Short Name', allowSorting: true },
                    { dataField: 'status', caption: 'Status', allowSorting: true },
                    { cellTemplate: 'details', allowSorting: false, width: 60 },
                    { cellTemplate: 'delete', allowSorting: false, width: 60 }
                ]"
            >
                <table>
                    <ng-container *dxTemplate="let row of 'rowTemplateName'">
                        <tr>
                            <td>{{ row.data.id }}</td>
                            <td>
                                <dx-select-box
                                    class="custom-select"
                                    [dataSource]="rateSchedulesWithRoomTypes.allRoomTypes"
                                    [value]="row.data.id"
                                    valueExpr="id"
                                    displayExpr="name"
                                    (onItemClick)="setRoomTypeToRateSchedule($event, row.data)"
                                    [disabled]="!permissionsModel.formButtonEnabled"
                                ></dx-select-box>
                            </td>
                            <td>{{ row.data.shortName }}</td>
                            <td>{{ row.data.status }}</td>
                            <td>
                                <i *ngIf="row.data.id !== null" class="fas fa-rectangle-list fa-icon gray fa-icon-small" title="Details" (click)="openRoomTypeDetailsSchedules(row.data.id)"></i>
                            </td>
                            <td (click)="deleteItem(row.data)" *ngIf="permissionsModel.delete">
                                <i title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small"></i>
                            </td>
                        </tr>
                    </ng-container>
                </table>

                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="true" [showInfo]="true"> </dxo-pager>
            </dx-data-grid>
            <div class="form-item">
                <span class="text-danger">{{ errorMessage }}</span>
            </div>

            <div (click)="addNewItem()" *ngIf="permissionsModel.formButtonEnabled" class="add-new-box">
                <span class="text">Add New</span>
                <div class="img">
                    <i class="fa-solid fa-plus fa-icon gray"></i>
                </div>
            </div>

            <button
                *ngIf="!loader && permissionsModel.formButtonEnabled"
                type="button"
                (click)="submitRoomTypesToRateSchedules()"
                class="custom-button blue left"
            >
                Save
            </button>
            <div *ngIf="loader" class="loader left"></div>
            <button (click)="close()" type="button" class="custom-button red left">
                {{ permissionsModel.formButtonEnabled ? 'Cancel' : 'Close' }}
            </button>
        </div>
    </div>
</div>
<app-room-type-edit
    *ngIf="roomTypeDetails"
    [hasOrder]="false"
    [idOfRoomType]="roomTypeId"
    [(editRoomType)]="roomTypeDetails"
></app-room-type-edit>
