import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RatePlanGridModel } from '../../../../../models/rate-plan/rate-plan-grid.model';
import { KeyValuePair } from '../../../../../models/shared/key-value-pair.model';
import { RatePlanService } from '../rate-plan.service';
import { AuthTokenService } from '../../../../../shared/services/auth-token.service';

@Component({
    selector: 'app-rate-plan-order',
    templateUrl: './rate-plan-order.component.html',
    styleUrls: ['./rate-plan-order.component.less'],
})
export class RatePlanOrderComponent implements OnInit {
    @Input() ratePlanOrder: boolean;
    @Output() ratePlanOrderChange = new EventEmitter<boolean>();
    @Input() ratePlanGridModel: RatePlanGridModel[];
    @Output() update = new EventEmitter();

    copyOfRatePlanGridModel: RatePlanGridModel[] = [];
    listOfReorderedRatePlans: KeyValuePair[] = [];
    changeOrderLoader = false;
    theme: number;

    constructor(private ratePlanService: RatePlanService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.onReorder = this.onReorder.bind(this);
    }

    ngOnInit() {
        this.copyOfRatePlanGridModel = JSON.parse(JSON.stringify(this.ratePlanGridModel));
    }

    onReorder(e) {
        const visibleRows = e.component.getVisibleRows();
    
        const toIndex = this.copyOfRatePlanGridModel.findIndex((item) => item.id === visibleRows[e.toIndex].data.id);
        const fromIndex = this.copyOfRatePlanGridModel.findIndex((item) => item.id === e.itemData.id);

        this.copyOfRatePlanGridModel.splice(fromIndex, 1);
        this.copyOfRatePlanGridModel.splice(toIndex, 0, e.itemData);
    
        for (let i=1; i<= this.copyOfRatePlanGridModel.length; i++) {
          this.copyOfRatePlanGridModel[i-1].orderNumber = i;
        }
      }

    submitOrderRatePlan() {
        this.changeOrderLoader = true;
        let i = 0;

        for (const ratePlan of this.copyOfRatePlanGridModel) {
            i++;
            this.listOfReorderedRatePlans.push(new KeyValuePair(ratePlan.id, i));
        }

        this.ratePlanService.reorderRatePlans(this.listOfReorderedRatePlans).subscribe(
            (response) => {
                this.changeOrderLoader = false;
                this.update.emit();
                this.close();
            },
            (error) => {
                this.changeOrderLoader = false;
                console.log(error);
            }
        );
    }

    close() {
        this.ratePlanOrderChange.emit(false);
    }
}
