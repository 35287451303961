import { RoomTypeBaseModel } from '../room-type/room-type-base.model';

export class RatePlanDerivedPricingModel {
    public ratePlanId: number;
    public masterRoomTypeId: number;
    public slaveRoomTypeId: number;
    public offset: number;
    public offsetPercentage: number;
    public offsetType: string;
    public roomType: RoomTypeBaseModel;
    public roomType1: RoomTypeBaseModel;

    // added new properties
    public label: string;
}
