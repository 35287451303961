import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { PromoCodeSearchModel } from '../../../../models/promo-code/promo-code-search.model';
import { PromoCodesService } from './promo-codes.service';
import { PromoCodePDModel } from '../../../../models/promo-code/promo-code-pd.model';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { EnumModel } from '../../../../models/shared/enum-model';
import { RateScheduleBaseModel } from '../../../../models/rate-schedule/rate-schedule-base.model';
import { PromoCodeModel } from '../../../../models/promo-code/promo-code.model';
import { ActivateCodeRequestModel } from '../../../../models/promo-code/activate-code-request.model';
import { PromoCodesPermissionsModel } from '../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../enums/permissions.enum';
import { DxDataGridComponent } from 'devextreme-angular';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import * as moment from 'moment';

@Component({
    selector: 'app-promo-codes',
    templateUrl: './promo-codes.component.html',
    styleUrls: ['./promo-codes.component.less'],
})
export class PromoCodesComponent implements OnInit, OnDestroy {
    @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
    generatePopup = false;
    editGeneratePopup = false;
    warning = false;
    dateErrorMessage: string;

    promoCodeId: number;

    dateFrom: Date = new Date();
    dateTo: Date = new Date();

    types: EnumModel[] = [];
    rateSchedules: RateScheduleBaseModel[] = [];
    statuses: EnumModel[] = [];

    subscription: any;
    promoCodeSearchModel = new PromoCodeSearchModel();
    promoCodePDModel = new PromoCodePDModel();
    selectedRows: PromoCodeModel[] = [];
    promoCodeModel = new PromoCodeModel();

    theme: number;

    permissionsModel: PromoCodesPermissionsModel;

    customCell = ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'data') {         
            if (['validFrom','validTo'].indexOf(gridCell.column.dataField) >=0) {
                let fDate = moment('1/1/1900', 'DD/MM/YYYY'); //xlsx uses days since 1/1/1900!
                excelCell.value = moment(gridCell.value).diff(fDate, 'days') + 2;
                excelCell.numFmt = 'dd mmm yyyy;@';
            }
        }
    };

    constructor(private promoCodeService: PromoCodesService, private exportExcelService: ExportExcelService, private permissionsService: PermissionsService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = permissionsService.createPermissionsModel(PermissionsEnum.PromoCodes);
    }

    ngOnInit() {
        this.searchCodes();
    }

    /*validateRangeOfDates(): boolean {
    if (this.dateFrom > this.dateTo) {
      this.dateErrorMessage = 'Date from must be less or equal than date to.';
      return true;
    }
    this.dateErrorMessage = '';
    return false;
  }*/

    searchCodes() {
        this.promoCodeSearchModel.propertyId = AuthTokenService.decodeJwtToken().Property.Id;
        // this.promoCodeSearchModel.dateFrom = this.dateFrom.toDateString();
        // this.promoCodeSearchModel.dateTo = this.dateTo.toDateString();

        this.subscription = this.promoCodeService.getPromoCodes(this.promoCodeSearchModel).subscribe(
            (response) => {
                this.promoCodePDModel = response;

                this.types = [];
                this.rateSchedules = [];
                this.statuses = [];

                this.promoCodePDModel.types.forEach((type) => {
                    this.types.push(type);
                });
                this.promoCodePDModel.rateSchedules.forEach((rate) => {
                    this.rateSchedules.push(rate);
                });
                this.promoCodePDModel.statuses.forEach((status) => {
                    this.statuses.push(status);
                });

                this.promoCodePDModel.types.unshift(new EnumModel(null, null, '--'));
                this.promoCodePDModel.statuses.unshift(new EnumModel(null, null, '--'));
                this.promoCodePDModel.rateSchedules.unshift(new RateScheduleBaseModel(null, '--'));

                this.promoCodePDModel.promoCodes.forEach((x) => {
                    this.promoCodePDModel.rateSchedules.forEach((r) => {
                        if (x.rateScheduleId === r.id) {
                            x.rateScheduleName = r.name;
                        }
                    });
                });
            },
            (error) => {
                console.log(error);
            }
        );
    }

    deactivate() {
        this.promoCodeService.deactivatePromoCodes(this.promoCodeModel.id).subscribe(
            (response) => {
                this.searchCodes();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    activate(forced: boolean) {
        const activateCodeRequestModel = new ActivateCodeRequestModel();
        activateCodeRequestModel.codeId = this.promoCodeModel.id;
        activateCodeRequestModel.forced = forced;
        activateCodeRequestModel.dateFrom = new Date(this.promoCodeModel.validFrom).toDateString();
        activateCodeRequestModel.dateTo = new Date(this.promoCodeModel.validTo).toDateString();

        this.promoCodeService.activatePromoCodes(activateCodeRequestModel).subscribe(
            (response) => {
                this.searchCodes();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    changeStatus(e, model: PromoCodeModel) {
        this.promoCodeModel = model;
        if (e.value === 'Inactive') {
            this.deactivate();
        } else if (e.value === 'Active') {
            this.promoCodeService
                .checkActivePromoCodesForDateRange(
                    new Date(model.validFrom).toDateString(),
                    new Date(model.validTo).toDateString()
                )
                .subscribe(
                    (response) => {
                        if (response) {
                            this.warning = true;
                        } else {
                            this.activate(false);
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    exportGrid() {
        this.setColumnVisibility(true);
        if (this.selectedRows.length === 0) {
            this.exportExcelService.exportToExcel(this.grid, false, 'Promo Codes', 'Caterbook - Promo Codes.xlsx', this.customCell);
        } else {
            this.exportExcelService.exportToExcel(this.grid, true, 'Promo Codes', 'Caterbook - Promo Codes.xlsx', this.customCell);
        }
        this.setColumnVisibility(false);
    }

    setColumnVisibility(exporting: boolean)
    {
        this.grid.instance.columnOption('edit', 'visible', !exporting);
    }

    openGenerateRange() {
        this.generatePopup = true;
    }

    openEditCode(data: PromoCodeModel) {
        this.promoCodeModel = data;
        this.editGeneratePopup = true;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
