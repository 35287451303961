import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpRequestService } from '../../../shared/services/http-request.service';

@Injectable()
export class OtaLogsService {
    private getFilteredOtaSyncInUrl = environment.apiUrl + '/OTA/GetFilteredOtaSyncIn';
    private getSyncStatusesUrl = environment.apiUrl + '/OTA/GetSyncStatuses';
    private getSyncXMLUrl = environment.apiUrl + '/OTA/GetSyncXML';
    private getSyncXMLByBookingUrl = environment.apiUrl + '/OTA/GetSyncXMLByBooking';
    
    constructor(private http: HttpRequestService) {}

    getData(data) {
        return this.http.post(this.getFilteredOtaSyncInUrl, data);
    }

    getSyncStatuses() {
        return this.http.get(this.getSyncStatusesUrl);
    }

    getSyncXML(guid) {
        return this.http.get(this.getSyncXMLUrl + '?guid=' + guid);
    }

    getSyncXMLByBooking(bookingId) {
        return this.http.get(this.getSyncXMLByBookingUrl + '?bookingId=' + bookingId);
    }

}
