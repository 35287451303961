import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RegistrationFormModel } from '../../models/templates/registration-form.model';
import { GuestRegistrationServiceNew } from './guest-registration-new.service';
import {
    BookingGuestRegistrationPDModel,
    GuestRegistrationPropertyInfoModel,
    GrouppedBookingRoomGuestRegistration,
    BookingRoomGuestRegistrationModel,
    BookingSourcesModel,
} from '../../models/guest-registration/booking-guest-registration.model';
import { GuestModel } from '../../models/booking/guest-info.model';
import {
    BookingGuestModel,
    BookingGuestRegistrationMenageModel,
    BookingRoomModel,
} from '../../models/booking/booking-details.model';
import { BookingRoomIdListModel } from '../../models/guest-registration/booking-room-id-list.model';
import { ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BookingExtraDetailModel } from '../../models/booking/booking-all-details.model';
import { PropertySettingModel } from '../../models/property/property-setting.model';
import { switchMap } from 'rxjs/operators';
import { of, combineLatest } from 'rxjs';
import { DxSelectBoxComponent } from 'devextreme-angular';
import { CustomValidationService } from 'src/app/shared/services/custom-validation.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';

@Component({
    selector: 'app-guest-registration-new',
    templateUrl: './guest-registration-new.component.html',
    styleUrls: ['./guest-registration-new.component.less'],
})
export class GuestRegistrationNewComponent implements OnInit {
    @ViewChild('select') selectBox: DxSelectBoxComponent;

    loader: boolean;
    cameFromCms: boolean;
    showAreYouSureModal: boolean;
    printLoader = false;
    showConfirmPopup = false;

    activeTab = 1;
    propertyId: number;
    bookingId: number;

    finishMessage: string;
    token: string;

    errorMissingParameter = false;

    bookingGuestRegistrationPD: BookingGuestRegistrationPDModel = new BookingGuestRegistrationPDModel();
    bookingRooms: Array<GrouppedBookingRoomGuestRegistration> = [];
    formTemplate: RegistrationFormModel = new RegistrationFormModel();
    form: UntypedFormGroup;
    bookingRoomIdList: BookingRoomIdListModel[] = [];
    propertyInfo: GuestRegistrationPropertyInfoModel = new GuestRegistrationPropertyInfoModel();
    roomWithLeadGuest: GrouppedBookingRoomGuestRegistration = new GrouppedBookingRoomGuestRegistration();
    leadGuest: BookingGuestModel = new BookingGuestModel();
    logo: SafeUrl;
    allBookings: BookingRoomGuestRegistrationModel[];
    allExtras: BookingExtraDetailModel[];
    bookingGuestRegistrationMenageModel = new BookingGuestRegistrationMenageModel();
    bookingSources: Array<BookingSourcesModel>;
    showTermsModal = false;
    openMessageTemplates = false;
    termsAndConditionsBody = '';
    termsConditionsPropertySettings: Object = new PropertySettingModel();

    constructor(
        private activatedRoute: ActivatedRoute,
        private registrationService: GuestRegistrationServiceNew,
        private fb: UntypedFormBuilder,
        private sanitizer: DomSanitizer,
        private router: Router,
        private toasterService: ToastService
    ) {
        this.form = this.fb.group({
            rooms: this.fb.array([]),
        });
    }

    ngOnInit() {
        const cameFromCms = +this.activatedRoute.snapshot.queryParams['cameFromCms'];
        this.token = this.activatedRoute.snapshot.queryParams['token'];

        const jwtHelper: JwtHelperService = new JwtHelperService();
        const tokenObject = jwtHelper.decodeToken(this.token);
        this.propertyId = tokenObject.PropertyId;
        this.bookingId = tokenObject.BookingId;

        if (this.propertyId === -1 && this.bookingId === -1) {
            this.errorMissingParameter = true;
            return;
        }

        this.getGuestsForBooking();

        this.cameFromCms = !!cameFromCms;
    }

    changeTab(index) {
        this.activeTab = index;
        this.appendAQueryParam(index);
    }

    getTermsAndConditions(propertyId) {
        return this.registrationService.getTermsAndConditions(propertyId, this.token);
    }

    getGuestsForBooking() {
        this.loader = true;
        this.registrationService
            .getBookingGuests(this.bookingId, this.token)
            .pipe(
                switchMap((response) => {
                    return combineLatest([of(response), this.getTermsAndConditions(response.bookingInfo.propertyId)]);
                })
            )
            .subscribe(
                ([response, termsConditionsResponse]) => {
                    this.bookingGuestRegistrationPD = response;
                    this.termsConditionsPropertySettings = termsConditionsResponse;

                    this.bookingRooms = this.bookingGuestRegistrationPD.bookingInfo.groupedBookingRooms.map((gb) => ({
                        ...gb,
                        roomName: gb.roomFullName,
                    }));

                    this.allBookings = this.sortBookings(this.bookingGuestRegistrationPD.bookingInfo.bookingRooms);
                    this.roomWithLeadGuest = this.bookingRooms.find((br, i) => {
                        if (!!br.bookingGuests.find((bg) => !!bg.leadGuest)) {
                            (br as any).collectionIndex = i;
                            return true;
                        }
                        return false;
                    });
                    this.leadGuest = this.roomWithLeadGuest
                        ? this.roomWithLeadGuest.bookingGuests.find((bg, i) => {
                              if (bg.leadGuest) {
                                  (bg as any).collectionIndex = i;
                                  return true;
                              }
                              return false;
                          })
                        : null;
                    this.allExtras = this.sortExtras(this.bookingGuestRegistrationPD.bookingInfo.bookingExtras);
                    this.logo = this.bookingGuestRegistrationPD.propertyLogo
                        ? this.sanitizer.bypassSecurityTrustUrl(
                              'data:image/jpeg;base64,' + this.bookingGuestRegistrationPD.propertyLogo
                          )
                        : null;
                    this.bookingSources = this.bookingGuestRegistrationPD.bookingInfo.bookingSources;
                    this.bookingGuestRegistrationMenageModel.bookingSourceId =
                        this.bookingGuestRegistrationPD.bookingInfo.bookingSourceId;
                    this.finishMessage = `Thank you for updating your details.`;
                    this.bookingGuestRegistrationPD.bookingInfo.groupedBookingRooms.forEach((x) => {
                        x.bookingGuests.forEach((guest) => {
                            if (guest.guest.under16) {
                                guest.guest.isUnderSixteen = true;
                            }
                        });
                    });
                    this.loader = false;
                },
                (error) => {
                    this.loader = false;
                }
            );
    }

    newsletterChange(event, bookingGuest: BookingGuestModel) {
        if (!bookingGuest.leadGuest || this.bookingRooms.length > 1) {
            return;
        }

        if (event.target.checked) {
            const date: Date = new Date();
            const dateFormatted = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

            bookingGuest.notes
                ? (bookingGuest.notes += ` Consented to email newsletter on ${dateFormatted} via Express Check In Form.`)
                : (bookingGuest.notes = `Consented to email newsletter on ${dateFormatted} via Express Check In Form.`);
        } else {
            bookingGuest.notes = null;
        }
    }

    checkIfGuestIsEntered(guest: GuestModel): boolean {
        // if neither one field is entered return false
        if (
            !guest.firstName &&
            !guest.lastName &&
            !guest.phone2 &&
            !guest.email &&
            !guest.address1 &&
            !guest.address2 &&
            !guest.address3 &&
            !guest.postalCode &&
            !guest.countryId
        ) {
            return false;
        }

        return true;
    }

    updateGuestsForBooking() {
        this.loader = true;
        let isValid = true;

        this.bookingRooms.forEach((room) => {
            room.bookingGuests.forEach((bookingGuest) => {
                if (!this.validatePhoneNumber(bookingGuest.guest.phone2)) {
                    this.loader = false;
                    this.toasterService.showToast('Error', 'Please enter a valid mobile phone number', null, ToastEventType.Error, true, 2000);
                    isValid = false;
                  }


                if (this.checkIfGuestIsEntered(bookingGuest.guest)) {
                    this.bookingGuestRegistrationMenageModel.bookingGuests.push(bookingGuest);
                }
            });
        });

        if (isValid) {
        this.registrationService
            .updateGuestsForBooking(this.bookingGuestRegistrationMenageModel, this.token, true)
            .subscribe(
                (response) => {
                    this.loader = false;
                    this.bookingGuestRegistrationMenageModel.bookingGuests = [];

                    if (!this.cameFromCms) {
                        this.showAreYouSureModal = true;
                    }
                },
                (error) => {
                    this.bookingGuestRegistrationMenageModel.bookingGuests = [];
                    this.loader = false;
                }
            );
        }
    }

    printForm() {
        this.loader = true;

        this.bookingGuestRegistrationPD.bookingInfo.groupedBookingRooms.forEach((x) => {
            x.bookingGuests.forEach((guest) => {
                if (this.checkIfGuestIsEntered(guest.guest)) {
                    this.bookingGuestRegistrationMenageModel.bookingGuests.push(guest);
                }
            });
        });
        this.registrationService
            .updateGuestsForBooking(this.bookingGuestRegistrationMenageModel, this.token, false)
            .subscribe(
                (response) => {
                    this.registrationService.exportGuestRegistrationFormToPdf(this.token).subscribe(
                        (responsePdf) => {
                            const blob = new Blob([responsePdf], { type: 'application/pdf' });
                            const blobUrl = URL.createObjectURL(blob);
                            const iframe = document.createElement('iframe');
                            const nav = (window.navigator as any);
                            iframe.style.display = 'none';

                            document.body.appendChild(iframe);

                            iframe.onload = function () {
                                const date = new Date();
                                const dateString =
                                    date.getDate() +
                                    '/' +
                                    date.getMonth() +
                                    '/' +
                                    date.getFullYear() +
                                    ' ' +
                                    date.getHours() +
                                    ':' +
                                    date.getMinutes() +
                                    ':' +
                                    date.getSeconds();

                                if (nav.msSaveOrOpenBlob) {
                                    nav.msSaveOrOpenBlob(
                                        blob,
                                        'Guest Registration - ' + dateString + '.pdf'
                                    );
                                } else if (window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
                                    const a = document.createElement('a');
                                    document.body.appendChild(a);
                                    a.href = blobUrl;
                                    a.download = 'Guest Registration -' + dateString + '.pdf';
                                    a.target = '_blank';
                                    a.click();
                                } else {
                                    iframe.contentWindow.print();
                                }
                            };

                            iframe.src = blobUrl;

                            this.loader = false;
                        },
                        (error) => {
                            this.loader = false;
                        }
                    );
                    this.bookingGuestRegistrationMenageModel.bookingGuests = [];
                },
                (error) => {
                    this.bookingGuestRegistrationMenageModel.bookingGuests = [];
                    this.loader = false;
                }
            );
    }

    goBack() {
        this.loader = true;
        this.router.navigate(['booking/details/', this.bookingId]);
    }

    appendAQueryParam(index) {
        // Changes the activeTab query param
        const urlTree = this.router.createUrlTree([], {
            queryParams: { activeTab: index },
            queryParamsHandling: 'merge',
            preserveFragment: true,
        });
        this.router.navigateByUrl(urlTree);
    }

    sortBookings(bookings) {
        const arr = [];

        bookings.forEach((booking) => {
            booking.count = 1;
            if (arr.some((x) => x.roomType === booking.roomType)) {
                const bookingAlreadyThere = arr.find((x) => x.roomType === booking.roomType);

                if (bookingAlreadyThere.ratePlan === booking.ratePlan) {
                    bookingAlreadyThere.guests += booking.guests;
                    bookingAlreadyThere.count++;
                } else {
                    arr.push(booking);
                }
            } else {
                arr.push(booking);
            }
        });
        return arr;
    }

    openTermsModal(e) {
        this.toggleTermsModal();
    }

    toggleTermsModal() {
        this.showTermsModal = !this.showTermsModal;
        if (this.showTermsModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }

    sortExtras(extras) {
        const arr = [];

        extras.forEach((extra) => {
            extra.count = 1;

            if (arr.some((x) => x.saleItemName === extra.saleItemName)) {
                const extraAlreadyThere = arr.find((x) => x.saleItemName === extra.saleItemName);

                extraAlreadyThere.count++;
            } else {
                arr.push(extra);
            }
        });
        return arr;
    }

    saveTermsConditions(event) {
        const state = event.target.checked;

        this.loader = true;
        this.registrationService
            .acceptTermsСondtions(this.bookingGuestRegistrationPD.bookingInfo.id, state, this.token)
            .subscribe(
                (res) => {
                    this.loader = false;
                    this.bookingGuestRegistrationPD.bookingInfo.guestAccepted = state;
                },
                (err) => {
                    this.loader = false;
                }
            );
    }

    checkGuestAge(event, roomIndex, guestIndex, bookingGuest: BookingGuestModel) {
        if (event.target.checked) {
            this.bookingGuestRegistrationPD.bookingInfo.groupedBookingRooms[roomIndex].bookingGuests[
                guestIndex
            ].guest.isUnderSixteen = true;
            this.bookingGuestRegistrationPD.bookingInfo.groupedBookingRooms[roomIndex].bookingGuests[
                guestIndex
            ].guest.address1 = null;
            this.bookingGuestRegistrationPD.bookingInfo.groupedBookingRooms[roomIndex].bookingGuests[
                guestIndex
            ].guest.address2 = null;
            this.bookingGuestRegistrationPD.bookingInfo.groupedBookingRooms[roomIndex].bookingGuests[
                guestIndex
            ].guest.address3 = null;
            this.bookingGuestRegistrationPD.bookingInfo.groupedBookingRooms[roomIndex].bookingGuests[
                guestIndex
            ].guest.postalCode = null;
            this.bookingGuestRegistrationPD.bookingInfo.groupedBookingRooms[roomIndex].bookingGuests[
                guestIndex
            ].guest.email = null;
            this.bookingGuestRegistrationPD.bookingInfo.groupedBookingRooms[roomIndex].bookingGuests[
                guestIndex
            ].guest.phone2 = null;
            this.bookingGuestRegistrationPD.bookingInfo.groupedBookingRooms[roomIndex].bookingGuests[
                guestIndex
            ].guest.countryId = null;

            if (bookingGuest.leadGuest) {
                bookingGuest.guest.newsletter = false;
            }
        } else {
            this.bookingGuestRegistrationPD.bookingInfo.groupedBookingRooms[roomIndex].bookingGuests[
                guestIndex
            ].guest.isUnderSixteen = false;
        }
    }

    sendSms() {
        this.loader = true;
        this.registrationService.sendSMSById(this.bookingId, this.propertyId).subscribe(
            (res) => {
                this.loader = false;
            },
            (err) => {
                this.loader = false;
            }
        );
    }

    validatePhoneNumber(phoneNumber: String) : boolean {
        if (phoneNumber === null) {
          return true;
        }
        const matches = phoneNumber.match(CustomValidationService.telephoneExpression);
        if (matches === null) {
          return false;
        }
        else {
          return matches.length > 0;
        }
      }
}
