import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RatePlanService } from '../../rate-plan.service';
import {
    DerivedPricingPopupModel,
    EnumModelDerive,
} from '../../../../../../models/rate-plan/derived-pricing-popup.model';
import { RatePlanDerivedPricingModel } from '../../../../../../models/rate-plan/rate-plan-derived-pricing.model';
import { DerivedPricingUpdateModel } from '../../../../../../models/rate-plan/derived-pricing-update.model';
import * as _ from 'lodash';
import { DerivedPricingUpdateResultModel } from '../../../../../../models/rate-plan/derived-pricing-update-result.model';
import { AuthTokenService } from '../../../../../../shared/services/auth-token.service';
import { CurrencyContext } from '../../../../../../models/auth-jwt/token-context';
import { InventoryRoomTypesPermissionsModel } from '../../../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../../../enums/permissions.enum';

@Component({
    selector: 'app-derive-options',
    templateUrl: './derive-options.component.html',
    styleUrls: ['./derive-options.component.less'],
})
export class DeriveOptionsComponent implements OnInit {
    @Input() deriveOptions: boolean;
    @Output() deriveOptionsChange = new EventEmitter<boolean>();
    @Input() ratePlanId: number;
    @Output() update = new EventEmitter();

    permissionsModel: InventoryRoomTypesPermissionsModel;

    alertPopup = false;
    loader = false;

    theme: number;

    errorMessageRatePlan: string;
    errorMessageOffset: string;
    errorMessageRoomTypes: string;

    value = new EnumModelDerive();
    derivedPricingPopupModel = new DerivedPricingPopupModel();
    derivedPricingUpdateModel = new DerivedPricingUpdateModel();
    derivedPricingUpdateResultModel = new DerivedPricingUpdateResultModel();
    currencyContext: CurrencyContext = new CurrencyContext();

    constructor(private ratePlanService: RatePlanService, private permissionsService: PermissionsService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryRoomTypes);
    }

    ngOnInit() {
        this.ratePlanService.getDerivedPricingPopup(this.ratePlanId).subscribe(
            (response) => {
                this.derivedPricingPopupModel = response;
                this.setInitialData();
            },
            (error) => console.log(error)
        );
    }

    setInitialData() {
        if (this.derivedPricingPopupModel.derivedPricesDetails.length > 0) {
            for (const item of this.derivedPricingPopupModel.options) {
                if (item.value === 'DerivedRoomTypes') {
                    item.selected = true;
                    this.value = item;
                } else {
                    item.selected = false;
                }
            }
        } else if (this.derivedPricingPopupModel.ratePlan.masterPlanId !== 0) {
            for (const item of this.derivedPricingPopupModel.options) {
                if (item.value === 'DerivedRatePlan') {
                    item.selected = true;
                    this.value = item;
                } else {
                    item.selected = false;
                }
            }
        } else {
            for (const item of this.derivedPricingPopupModel.options) {
                if (item.value === 'NotDerived') {
                    item.selected = true;
                    this.value = item;
                } else {
                    item.selected = false;
                }
            }
        }
    }

    addNewOption() {
        const newDerivePriceDetails = new RatePlanDerivedPricingModel();
        newDerivePriceDetails.ratePlanId = this.ratePlanId;
        newDerivePriceDetails.masterRoomTypeId = null;
        newDerivePriceDetails.slaveRoomTypeId = null;
        newDerivePriceDetails.offsetPercentage = 0;
        newDerivePriceDetails.offset = 0;
        newDerivePriceDetails.offsetType = this.derivedPricingPopupModel.offsetTypes[0].id;
        this.derivedPricingPopupModel.derivedPricesDetails.push(newDerivePriceDetails);
    }

    isRatePlanOptionValid(): boolean {
        if (
            this.value.id === 'DerivedRatePlan' &&
            (this.derivedPricingPopupModel.ratePlan.masterPlanId === 0 ||
                this.derivedPricingPopupModel.ratePlan.masterPlanId === null)
        ) {
            this.errorMessageRatePlan = 'Error! Please, choose rate plan.';
            return false;
        }

        this.errorMessageRatePlan = '';
        return true;
    }

    isOffsetValid(): boolean {
        switch (this.value.id) {
            case 'DerivedRatePlan':
                if (this.derivedPricingPopupModel.ratePlan.offset === null) {
                    this.errorMessageOffset = 'Error! Please enter Offset.';
                    return false;
                }

                this.errorMessageOffset = '';
                return true;
            case 'DerivedRoomTypes':
                for (const item of this.derivedPricingPopupModel.derivedPricesDetails) {
                    if (item.offset === null) {
                        this.errorMessageOffset = 'Error! Please enter Offset.';
                        return false;
                    }
                }

                this.errorMessageOffset = '';
                return true;
            default:
                this.errorMessageOffset = '';
                return true;
        }
    }

    isRoomTypeOptionValid(): boolean {
        if (this.value.id !== 'DerivedRoomTypes') {
            this.errorMessageRoomTypes = '';
            return true;
        }

        if (this.derivedPricingPopupModel.derivedPricesDetails.length === 0) {
            this.errorMessageRoomTypes = 'Error! Please insert data into table.';
            return false;
        }

        for (const item of this.derivedPricingPopupModel.derivedPricesDetails) {
            if (item.masterRoomTypeId === null || item.slaveRoomTypeId === null) {
                this.errorMessageRoomTypes = 'Error! Please fill all fields.';
                return false;
            }
            if (item.masterRoomTypeId === item.slaveRoomTypeId) {
                this.errorMessageRoomTypes = 'Error! Controlling and Inheriting room types are identical.';
                return false;
            }
        }

        const masterRoomTypesArray: number[] = [];
        const slaveRoomTypesArray: number[] = [];
        this.derivedPricingPopupModel.derivedPricesDetails.forEach((x) => {
            masterRoomTypesArray.push(x.masterRoomTypeId);
            slaveRoomTypesArray.push(x.slaveRoomTypeId);
        });

        if (masterRoomTypesArray.length !== slaveRoomTypesArray.length) {
            this.errorMessageRoomTypes = 'Error! Please fill all fields.';
            return false;
        }

        for (const master of masterRoomTypesArray) {
            for (const slave of slaveRoomTypesArray) {
                if (master === slave) {
                    this.errorMessageRoomTypes =
                        'Error! Room types cannot be both, controlling and inheriting room type.';
                    return false;
                }
            }
        }

        if (_.uniq(slaveRoomTypesArray).length !== slaveRoomTypesArray.length) {
            this.errorMessageRoomTypes = 'Error! Duplicate Inheriting room types.';
            return false;
        }

        this.errorMessageRoomTypes = '';
        return true;
    }

    deleteDeriveOption(data): void {
        const index = this.derivedPricingPopupModel.derivedPricesDetails.indexOf(data);
        this.derivedPricingPopupModel.derivedPricesDetails.splice(index, 1);
    }

    validateForm() {
        return this.isOffsetValid() && this.isRoomTypeOptionValid() && this.isRatePlanOptionValid();
    }

    submitDeriveSettings(forced: boolean) {
        if (this.validateForm()) {
            this.loader = true;
            this.derivedPricingUpdateModel.option = this.value.id;

            switch (this.derivedPricingUpdateModel.option) {
                case 'DerivedRatePlan':
                    this.derivedPricingUpdateModel.derivedPricesDetails = [];
                    this.derivedPricingUpdateModel.ratePlan = this.derivedPricingPopupModel.ratePlan;
                    break;
                case 'DerivedRoomTypes':
                    this.derivedPricingUpdateModel.derivedPricesDetails =
                        this.derivedPricingPopupModel.derivedPricesDetails;
                    this.derivedPricingUpdateModel.ratePlan = this.derivedPricingPopupModel.ratePlan;
                    break;
                default:
                    this.derivedPricingUpdateModel.derivedPricesDetails = [];
                    this.derivedPricingUpdateModel.ratePlan = this.derivedPricingPopupModel.ratePlan;
                    break;
            }

            this.derivedPricingUpdateModel.force = forced;
            this.ratePlanService.updateDerivedPricingFromPopup(this.derivedPricingUpdateModel).subscribe(
                (response: any) => {
                    this.loader = false;
                    this.derivedPricingUpdateResultModel = response;
                    if (!this.derivedPricingUpdateResultModel.success) {
                        this.alertPopup = true;
                    } else {
                        this.alertPopup = false;
                        this.close();
                        this.update.emit();
                    }
                },
                (error) => {
                    console.log(error);
                    this.loader = false;
                }
            );
        }
    }

    close() {
        this.deriveOptionsChange.emit(false);
    }
}
