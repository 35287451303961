export class RatePlanRoomTypeModel {
    public ratePlanId: number;
    public roomTypeId: number;
    public baseGuests: number;
    public additionalGuests: number;
    public additionalChildren: number;
    public childrenRate: number;
    public dpLessThan?: number;
    public dpDaysFrom?: number;
    public dpIncreaseFor?: number;

    // added new properties
    public dpLessThanHelper?: number;
}
