import { GridConfiguration, GridRequest } from '../../shared/helpers/dx-grid-helper';

export class BookingPaymentsFilterApiModel extends GridRequest {
    public from: string;
    public to: string;
    public paymentType: string[];
    public paymentStatus: string;
    constructor(
        from = new Date().toDateString(),
        to = new Date().toDateString(),
        paymentType = [],
        paymentStatus = null
    ) {
        super();
        this.from = from;
        this.to = to;
        this.paymentType = paymentType;
        this.paymentStatus = paymentStatus;
    }
}

export class BookingPaymentsGridResponseModel extends GridConfiguration {
    constructor() {
        super();
        this.columns = [
            { dataField: 'bookingNo', caption: 'Booking ID', cellTemplate: 'bookingNo' },
            {
                dataField: 'arrivalDate',
                caption: 'Arrival Date',
                format: 'dd/MM/yyyy,',
                cellTemplate: 'arrivalDate',
                allowSorting: false,
            },
            { dataField: 'name', caption: 'Guest/Partner', cellTemplate: 'name', allowSorting: false },
            { dataField: 'paymentId', caption: 'Pay ID', cellTemplate: 'paymentId' },
            { dataField: 'paymentType', caption: 'Payment Type', cellTemplate: 'paymentType', width: 200 },
            { dataField: 'date', caption: 'Pay Date', format: 'dd/MM/yyyy,', cellTemplate: 'date', width: 100 },
            {
                dataField: 'time',
                caption: 'Time',
                format: 'H:mm',
                cellTemplate: 'time',
                width: 80,
                allowSorting: false,
            },
            { dataField: 'amount', caption: 'Amount', cellTemplate: 'amount', width: 80 },
        ];

        this.customSearch = [
            {
                fieldName: 'searchString',
                value: '',
            },
        ];

        this.pager = {
            showPageSizeSelector: false,
            showNavigationButtons: true,
            allowedPageSizes: [10, 15, 20],
            pageSize: 20,
            showInfo: true,
        };

        this.paging = {
            enabled: true,
            pageIndex: 0,
            pageSize: 20,
        };
    }

    mapGridRequest(gridRequest: GridRequest, loadOptions: any) {
        super.mapGridRequest(gridRequest, loadOptions);
        gridRequest.options.customSearch = this.customSearch;
    }
}
