export class ReportingEposConfigurationModel {
    public id:  number;
    public settingId: number;
    public key: string;
    public value: string;

    constructor(id?: number, settingId?: number, key?: string, value?: string) {
        this.id = id;
        this.settingId = settingId;
        this.key = key;
        this.value = value;
    }
}

export class ReportingEposShiftConfigurationModel {
    public id:  number;
    public terminalId: string;
    public shiftStart: string;
    public shiftEnd: string;
    public shiftName: string;
    public dShiftStart: string;
    public dShiftEnd: string;
    public static dPrefix: string = "2021-01-01T";

    constructor(id?: number, terminalId?: string, shiftStart?: string, shiftEnd?: string, shiftName?: string) {
        this.id = id;
        this.terminalId = terminalId;
        this.shiftStart = shiftStart;
        this.shiftEnd = shiftEnd;
        this.shiftName = shiftName;
        this.dShiftStart = ReportingEposShiftConfigurationModel.dPrefix + shiftStart;
        this.dShiftEnd = ReportingEposShiftConfigurationModel.dPrefix + shiftEnd;
    }
}

export class ReportingEposShiftConfigurationListsModel {
    public eposConfiguration: ReportingEposConfigurationModel[];
    public eposShiftConfiguration: ReportingEposShiftConfigurationModel[];
    public eposCategoryGroupConfiguration: ReportingEPOSCategoryGroupMappingModel[];
    public propertyId: number;

    constructor(eposConfiguration: ReportingEposConfigurationModel[], eposShiftConfiguration: ReportingEposShiftConfigurationModel[], eposCategoryGroupConfiguration: ReportingEPOSCategoryGroupMappingModel[], propertyId: number) {
        this.eposConfiguration = eposConfiguration;
        this.eposShiftConfiguration = eposShiftConfiguration;
        this.eposCategoryGroupConfiguration = eposCategoryGroupConfiguration;
        this.propertyId = propertyId;
    }
}

export class ReportingEPOSCategoryGroupMappingModel {
    public id:  number;
    public category: number;
    public categoryGroup: number;

    constructor(id?: number, category?: number, categoryGroup?: number) {
        this.id = id;
        this.category = category;
        this.categoryGroup = categoryGroup;
    }
}