import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-settings-guests',
    templateUrl: './settings-guests.component.html',
    styleUrls: ['./settings-guests.component.less'],
})
export class SettingsGuestsComponent implements OnInit {
    guestPopup = false;

    constructor() {}

    ngOnInit() {}
}
