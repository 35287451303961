import { GuestMergeService } from '../guest-merge.service';
import { Component, OnInit } from '@angular/core';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { BookingSearchPDModel } from '../../../../models/booking/booking-search-pd.model';
import { ActivatedRoute } from '@angular/router';
import { GuestsRegistrationFormModel } from '../../../../models/guest-merge/guest-merge.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';

@Component({
    selector: 'app-guest-bulk-grf',
    templateUrl: './guest-bulk-GRF.component.html',
    styleUrls: ['./guest-bulk-GRF.component.less'],
})
export class GuestBulkGRFComponent implements OnInit {
    loader = false;
    theme: number;
    token: string;
    today: string;

    bookingSearchPDModel: BookingSearchPDModel;

    constructor(
        private guestMergeService: GuestMergeService,
        private activatedRoute: ActivatedRoute,
        private toasterService: ToastService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    }

    ngOnInit() {
        const date = new Date();
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        this.today =
            days[date.getDay()] + '  ' + date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
        this.token = this.activatedRoute.snapshot.queryParams['token'];
    }

    printBulkGuestGRF() {
        const today = new Date();
        this.loader = true;
        const model = new GuestsRegistrationFormModel();
        model.date = today;
        this.guestMergeService.printBulkGRF(model).subscribe(
            (responsePdf) => {
                const blob = new Blob([responsePdf], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(blob);
                const iframe = document.createElement('iframe');
                const nav = (window.navigator as any);
                iframe.style.display = 'none';

                document.body.appendChild(iframe);

                iframe.onload = function () {
                    const date = new Date();
                    const dateString =
                        date.getDate() +
                        '/' +
                        (date.getMonth() + 1) +
                        '/' +
                        date.getFullYear() +
                        ' ' +
                        date.getHours() +
                        ':' +
                        date.getMinutes() +
                        ':' +
                        date.getSeconds();

                    if (nav.msSaveOrOpenBlob) {
                        nav.msSaveOrOpenBlob(blob, 'Bulk Guest Registration - ' + dateString + '.pdf');
                    } else if (window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
                        const a = document.createElement('a');
                        document.body.appendChild(a);
                        a.href = blobUrl;
                        a.download = 'Bulk Guest Registration -' + dateString + '.pdf';
                        a.target = '_blank';
                        a.click();
                    } else {
                        iframe.contentWindow.print();
                    }
                };

                iframe.src = blobUrl;

                this.loader = false;
            },
            (error) => {
                this.loader = false;
                this.toasterService.showToast('', 'There are no Guest Registration Forms to print today', null, ToastEventType.Error, true, 4000);
            }
        );
    }
}
