import { BookingPaymentModel } from './booking-details.model';
import { FunctionPaymentModel } from '../function-room/function-flow.model';

export class PciBookingDetailModel {
    public bookingPayment: BookingPaymentModel;
    public pciBookingToken: string;
    public cardMask: string;
}

export class PciFunctionDetailModel {
    public functionPayment: FunctionPaymentModel;
    public pciBookingToken: string;
    public cardMask: string;
}
