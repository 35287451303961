import { KeyValuePair } from '../shared/key-value-pair.model';
import { RoomType } from './room-type-details.model';

export class RoomTypeDetailsGetModel {
    public id?: number;
    public propertyId: number;
}

export class RoomTypesModel {
    public roomTypes: RoomTypeModel[] = [];
}

export class RoomTypeModel {
    public id: number;
    public propertyId: number;
    public name: string;
    public shortName: string;
    public maximumOccupancy: number;
    public maximumChildren: number;
    public class: string;
    public beddingArangement: string;
    public description: string;
    public otaTypeId: number;
    public status: string;
    public dateCreated: string;
    public roomTypePictures: RoomTypePictureModel[];
    public amenities: AmenityModel[];
    public cleaningFrequencies: CleaningFrequencyModel[];
    public roomTypeRooms: RoomTypeRoomModel[] = [];
    public roomsIdName: KeyValuePair[];

    // added new properties
    public listForCombo: any[] = [];
    public orderNumber: any;
    public valid = true;
    public showDetails = false;
}

export class RoomTypeRoomModel {
    public id: number;
    public roomTypeId: number;
    public roomId: number;
    public maximumOccupancy: number;
    public maximumChildren: number;
    public orderNumber: number;
    public status: string;
    public dateCreated: Date;
}

export class RoomTypePictureModel {
    public id: string;
    public roomTypeId: number;
    public order: number;
    public active: boolean;
    public dateCreated: Date;
    public thumbnail = '';

    // added new properties
    public file: File = null;
    public name: string;
}

export class AmenityModel {
    public id: number;
    public name: string;
    public otaCode: string;
    public active: boolean;
    public dateCreated: Date;
    public type: number;
    public order: number;
}

export class CleaningFrequencyModel {
    public id: number;
    public propertyId: number;
    public name: string;
    public frequencyType: number;
    public excludeSunday: boolean;
    public linen: boolean;
    public towels: boolean;
    public active: boolean;
    public dateCreated: Date;
}

export class RoomTypesGridModel {
    public roomTypes: RoomType[] = [];
}
