import { BookingPaymentModel } from './booking-details.model';
import { EnumModel } from '../shared/enum-model';
import { InvoiceModel } from './invoice.model';
import { FinanceAccountType } from '../../enums/finance-account-type.enum';

export class BookingPaymentPDModel {
    public payment: BookingPaymentModel;
    public paidByList: FinanceAccountModel[] = [];
    public paymentTypes: EnumModel[] = [];
    public paymentHybridTypes: BookingPaymentHybridSourceModel[] = [];
    public paymentPurposes: EnumModel[] = [];
    public invoices: InvoiceModel[] = [];
    public balance: number;

    constructor() {
        this.payment = new BookingPaymentModel();
    }
}

export class FinanceAccountModel {
    public accountId: number;
    public accountName: string;
    public accountType: string;
}

export class BookingPaymentHybridSourceModel {
    public id: number;
    public source: string;
    public type: string;
    public description: string;
    public amount: number;
    public guestId: number;
    public partnerId: number;
    public invoiceId?: number;
}
