import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { RoomTypesGridModel } from '../../../models/room-type/room-types.model';
import { SignupService } from '../signup.service';

@Injectable()
export class RoomTypeResolver  {
    constructor(private signUpService: SignupService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RoomTypesGridModel> {
        return this.signUpService.getByProperty(AuthTokenService.decodeJwtToken().Property.Id);
    }
}
