import { FunctionBookingBookingDetailModel } from "./function-flow.model";

export class FunctionModel {
    public id: number;
    public propertyId: number;
    public functionName: string;
    public partnerId: number;
    public userId: number;
    public superUserId: number;
    public dateCreated: Date;
    public title: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public telephone: string;
    public mobilePhone: string;
    public address1: string;
    public address2: string;
    public address3: string;
    public address4: string;
    public postcode: string;
    public countryId: number;
    public notes: string;
    public status: number;
    public functionDesc: string;

    constructor(id: number, functionName: string, functionDesc: string) {
        this.id = id;
        this.functionName = functionName;
        this.functionDesc = functionDesc;
    }
}

export class BookingFunction {
    public functionId: number;
    public bookingId: number;
    public bookingDetails: FunctionBookingBookingDetailModel;
}