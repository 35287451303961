<div class="pricing-plans-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <!--<h2>Dynamic Pricing</h2>-->

            <form [formGroup]="valForm" (ngSubmit)="submitForms($event)">
                <div class="form-box flex">
                    <div class="title">
                        <div class="label long">Dynamic pricing  for {{ ratePlan?.name }}</div>
                        <div class="form-item shortest" >
                            <div class="custom-label">Room Type</div>
                            <dx-select-box
                                class="custom-select"
                                [(value)]="roomTypeId"
                                [dataSource]="roomTypes"
                                (onItemClick)="filterByRoomType($event)"
                                displayExpr="roomType.shortName"
                                valueExpr="roomType.id"
                            >
                            </dx-select-box>  
                                                
                        </div>        
                    </div>
                    <div class="form-item shortest">
                        <div class="custom-label">Rooms remaining</div>
                        <div *ngIf="lessThan" (click)="lessThan = !lessThan" class="toggle-sign" title="less than">
                            <i class="fa fa-angle-left"></i>
                        </div>
                        <div *ngIf="!lessThan" (click)="lessThan = !lessThan" class="toggle-sign" title="greater than">
                            <i class="fa fa-angle-right"></i>
                        </div>
                        <input
                            class="custom-input more-less"
                            type="number"
                            min="0"
                            name="more-less"
                            formControlName="more-less"
                            [(ngModel)]="ratePlanRoomTypeModel.dpLessThanHelper"
                            [readonly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['more-less'].hasError('min') &&
                                (valForm.controls['more-less'].dirty || valForm.controls['more-less'].touched)
                            "
                            >Minimum number is 0.</span
                        >
                    </div>
                    <div class="form-item shortest">
                        <div class="custom-label">Lead time</div>
                        <input
                            class="custom-input"
                            type="number"
                            min="0"
                            name="lead-time"
                            formControlName="lead-time"
                            [(ngModel)]="ratePlanRoomTypeModel.dpDaysFrom"
                            [readonly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['lead-time'].hasError('min') &&
                                (valForm.controls['lead-time'].dirty || valForm.controls['lead-time'].touched)
                            "
                            >Minimum number is 0.</span
                        >
                    </div>
                    <div class="form-item shortest">
                        <div class="custom-label">Adjust Price</div>
                        <input
                            class="custom-input"
                            type="number"
                            name="change"
                            formControlName="change"
                            [(ngModel)]="ratePlanRoomTypeModel.dpIncreaseFor"
                            [readonly]="!permissionsModel.formButtonEnabled"
                            [ngClass]="[adjustPriceError ? 'input-error' : '']"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['change'].hasError('min') &&
                                (valForm.controls['change'].dirty || valForm.controls['change'].touched)
                            "
                            >Minimum number is 0.</span
                        >
                    </div>
                    <div class="form-item shortest"></div>
                    <div class="form-item shortest"></div>
                </div>
                <div class="error-container" *ngIf="adjustPriceError">
                    Please enter a value for 'Adjust Price'.
                </div>

                <button *ngIf="!loader && permissionsModel.update" type="submit" class="custom-button blue left">
                    Update
                </button>
                <div *ngIf="loader" class="loader left"></div>
            </form>
            <button type="button" (click)="close()" class="custom-button red left">Close</button>
        </div>
    </div>
</div>
