<div class="success-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Check your details are correct</h2>

            <div class="flex field">
                <div>Your branch code:</div>
                <div>
                    <strong>{{ goCardLessModel.branchCode }}</strong>
                </div>
            </div>

            <div class="flex field">
                <div>Your account number:</div>
                <div>
                    <strong>{{ goCardLessModel.accountNumber }}</strong>
                </div>
            </div>

            <div class="flex field">
                <div>Holder:</div>
                <div>
                    <strong>{{ goCardLessModel.accountHolderName }}</strong>
                </div>
            </div>

            <p class="link" (click)="close()">Change</p>

            <button (click)="confirmFunction()" type="button" class="custom-button blue">Confirm</button>
        </div>
    </div>
</div>
