import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MessageTemplateModel } from 'src/app/models/templates/message-template.model';
import { BookingDetailsService } from 'src/app/routes/booking/settings-booking-detail/booking-details.service';
import { AuthTokenService } from '../services/auth-token.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { defer, iif } from 'rxjs';

@Component({
    selector: 'app-message-templates-recepient',
    templateUrl: './message-templates-recepient.component.html',
    styleUrls: ['./message-templates-recepient.component.less'],
})
export class MessageTemplatesRecepientComponent implements AfterViewInit {
    @ViewChild('grid', { static: true }) grid: DxDataGridComponent;
    @Input() messageTemplatePopup: boolean;
    @Output() messageTemplatePopupChange = new EventEmitter<boolean>();
    @Input() bookingRoomId: number;
    @Input() bookingId: number;
    @Input() bookingGuestId: number;
    @Input() email: string;
    @Input() mobilePhone: string;
    @Input() partnerMode: boolean = false;

    columnsConfig = [
        { dataField: 'name', width: 210, caption: 'Name', allowSorting: true },
        { dataField: 'type', width: 100, caption: 'Type', allowSorting: true },
        { dataField: 'subject', width: 550, caption: 'Subject', allowSorting: true },
        // {dataField: 'sender', caption: 'Sender', allowSorting: true},
        // {dataField: 'message', caption: 'Message', width: 200, cellTemplate: 'message', allowSorting: true},
        // {dataField: 'scheduleType', caption: 'Schedule type', allowSorting: true},
        // {dataField: 'scheduleTrigger', caption: 'Schedule trigger', allowSorting: true}
    ];

    messagePreview = false;

    showTooltip = false;
    targetId: number;
    message: string;

    theme: number;

    messageTemplatesList: MessageTemplateModel[];
    messageTemplate: MessageTemplateModel = new MessageTemplateModel();

    constructor(private bookingDetailsService: BookingDetailsService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    }

    ngAfterViewInit() {
        this.grid.instance.beginCustomLoading('Loading...');
        iif(
            () => !this.partnerMode,
            defer(() => this.bookingDetailsService.getMessageTemplatesForGuest()),
            defer(() => this.bookingDetailsService.getMessageTemplatesForPartner())
        ).subscribe(
            (response) => {
                this.messageTemplatesList = this.sortTemplatesList(response);
                this.grid.instance.endCustomLoading();
            },
            (error) => {
                console.log(error);
                this.grid.instance.endCustomLoading();
            }
        );
    }

    onRowClick(event) {
        if (event.data) {
            this.messageTemplate = event.data;
            this.messagePreview = true;
        }
    }

    close() {
        this.messageTemplatePopupChange.emit(false);
    }

    sortTemplatesByName(a, b) {
        let x = a.name.toLowerCase();
        let y = b.name.toLowerCase();
        if (x < y) {
            return -1;
        }
        if (x > y) {
            return 1;
        }
        return 0;
    }

    sortTemplatesList(templateList: Array<MessageTemplateModel>): Array<MessageTemplateModel> {
        if (this.email && this.mobilePhone) {
            return templateList.sort(this.sortTemplatesByName);
        } else if (this.email) {
            return templateList.filter((t) => t.type !== 'Sms').sort(this.sortTemplatesByName);
        } else if (this.mobilePhone) {
            return templateList.filter((t) => t.type !== 'Email').sort(this.sortTemplatesByName);
        }
        return [];
    }
}
