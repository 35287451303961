import { Component, OnDestroy, OnInit } from '@angular/core';
import { SignupService } from '../signup.service';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { RoomModel, RoomsMappingModel } from '../../../models/room/rooms-mapping.model';
import { RoomTypeModel, RoomTypeRoomModel, RoomTypesModel } from '../../../models/room-type/room-types.model';
import { NavigationStart, Router } from '@angular/router';
import { UnsavedChangesGuard } from '../../../shared/auth-guards/auth-guard.service';
import { ViewChild } from '@angular/core';
import { PermissionsService } from '../../../shared/services/permissions.service';
import { InventoryAssignRoomsPermissionsModel } from '../../../models/permissions/permissions.model';
import { PermissionsEnum } from '../../../enums/permissions.enum';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-assign-rooms',
    templateUrl: './assign-rooms.component.html',
    styleUrls: ['./assign-rooms.component.less'],
})
export class AssignRoomsComponent implements OnInit, OnDestroy {
    @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
    permissionsModel: InventoryAssignRoomsPermissionsModel;

    loader = false;
    updateRoomPopup = false;
    virtualRoomPopup = false;
    mapRoomDetails = false;
    checkPopup = false;
    forceLeave = false;
    destinationUrl;
    lastPageIndex = 0;
    roomOrder = false;

    duplicateErrorMessage = '';

    theme: number;

    roomId: number;
    roomTypeRoomId = -1;
    stateInitialList: number[] = [];

    subscription: any;
    roomType = new RoomTypeModel();
    roomsMappingModel = new RoomsMappingModel();
    roomTypePostModel = new RoomTypesModel();

    constructor(
        private signUpService: SignupService,
        public router: Router,
        private canDeactivateGuard: UnsavedChangesGuard,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryAssignRooms);

        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.destinationUrl = event.url;
            }
        });
    }

    ngOnInit() {
        this.init();
    }

    init() {
        this.subscription = this.signUpService
            .getRoomsMappingByProperty(AuthTokenService.decodeJwtToken().Property.Id)
            .subscribe(
                (response) => {
                    this.roomsMappingModel = response;
                    for (let i = 0; i < this.roomsMappingModel.roomTypes.length; i++) {
                        this.roomsMappingModel.roomTypes[i].listForCombo = [];
                        this.roomsMappingModel.roomTypes[i].listForCombo.push('--');
                        for (let j = 0; j < this.roomsMappingModel.rooms.length; j++) {
                            this.roomsMappingModel.roomTypes[i].listForCombo.push(j + 1);
                        }
                    }

                    // set Order number to select box
                    for (let i = 0; i < this.roomsMappingModel.rooms.length; i++) {
                        this.roomsMappingModel.rooms[i].addedRoomTypes = [];
                        for (let j = 0; j < this.roomsMappingModel.roomTypes.length; j++) {
                            let counter = 0;
                            for (let k = 0; k < this.roomsMappingModel.roomTypes[j].roomTypeRooms.length; k++) {
                                if (
                                    this.roomsMappingModel.rooms[i].id ===
                                    this.roomsMappingModel.roomTypes[j].roomTypeRooms[k].roomId
                                ) {
                                    const roomType: RoomTypeModel = new RoomTypeModel();
                                    roomType.id = this.roomsMappingModel.roomTypes[j].id;
                                    roomType.orderNumber =
                                        this.roomsMappingModel.roomTypes[j].roomTypeRooms[k].orderNumber;
                                    roomType.roomTypeRooms = this.roomsMappingModel.roomTypes[j].roomTypeRooms;
                                    this.roomsMappingModel.rooms[i].addedRoomTypes.push(roomType);
                                    counter++;
                                }
                            }

                            if (counter === 0) {
                                const roomType: RoomTypeModel = new RoomTypeModel();
                                roomType.orderNumber = '--';
                                this.roomsMappingModel.rooms[i].addedRoomTypes.push(roomType);
                            }
                        }
                    }

                    this.showDetailsForRoomTypeRoom();
                    this.saveInitialState();
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    // ngAfterViewInit(): void {
    //   // scroll to top of page if page on grid schanged
    //   this.grid.onContentReady.subscribe(
    //     x => {
    //       const currentPageIndex = this.grid.instance.pageIndex();
    //       if (this.lastPageIndex !== currentPageIndex) {
    //         this.lastPageIndex = currentPageIndex;
    //         window.scrollTo(0, 0);
    //       }
    //   });
    // }
    openRoomOrder() {
        this.roomOrder = true;
    }
    canDeactivate() {
        return this.checkStateChanged();
    }

    saveInitialState() {
        this.stateInitialList = [];
        for (const roomType of this.roomsMappingModel.roomTypes) {
            for (const roomTypeRoom of roomType.roomTypeRooms) {
                this.stateInitialList.push(roomTypeRoom.orderNumber);
            }
        }
    }

    checkStateChanged() {
        const orderNumbers: number[] = [];
        for (const roomType of this.roomsMappingModel.roomTypes) {
            for (const roomTypeRoom of roomType.roomTypeRooms) {
                orderNumbers.push(roomTypeRoom.orderNumber);
            }
        }

        if (this.stateInitialList.length !== orderNumbers.length) {
            this.checkPopup = true;
            return true;
        }

        for (let i = 0; i < this.stateInitialList.length; i++) {
            if (this.stateInitialList[i] !== orderNumbers[i]) {
                this.checkPopup = true;
                return true;
            }
        }

        return false;
    }

    stayOnPage() {
        this.checkPopup = false;
    }

    leavePage() {
        this.forceLeave = true;
        this.router.navigate([this.destinationUrl]);
    }

    setOrderForRoom(e, room: RoomModel, roomType: RoomTypeModel, indexOfRoomType: number) {
        let counter = 0;
        for (let i = 0; i < roomType.roomTypeRooms.length; i++) {
            if (room.id === roomType.roomTypeRooms[i].roomId) {
                if (e.value !== '--') {
                    roomType.roomTypeRooms[i].orderNumber = e.value;
                    counter++;
                } else {
                    roomType.roomTypeRooms.splice(i, 1);
                }
            }
        }

        if (counter === 0 && e.value !== '--') {
            const newRoomTypeRoom = new RoomTypeRoomModel();
            newRoomTypeRoom.roomId = room.id;
            newRoomTypeRoom.roomTypeId = roomType.id;
            newRoomTypeRoom.maximumOccupancy = roomType.maximumOccupancy;
            newRoomTypeRoom.maximumChildren = roomType.maximumChildren;
            newRoomTypeRoom.orderNumber = e.value;
            newRoomTypeRoom.status = roomType.status;
            roomType.roomTypeRooms.push(newRoomTypeRoom);

            room.addedRoomTypes[indexOfRoomType].roomTypeRooms = [];
            room.addedRoomTypes[indexOfRoomType].roomTypeRooms.push(newRoomTypeRoom);
        }
    }

    saveAssignRoomToTypes() {
        this.clearValidators();
        if (this.checkDuplicatesRoomTypesRoom()) {
            this.loader = true;
            this.roomTypePostModel.roomTypes = this.roomsMappingModel.roomTypes;
            this.signUpService.mapRoomsToRoomTypes(this.roomTypePostModel).subscribe(
                (response) => {
                    this.loader = false;
                    this.init();
                },
                (error) => {
                    this.loader = false;
                    console.log(error);
                }
            );
        }
    }

    checkDuplicatesRoomTypesRoom(): boolean {
        let counter = 0;
        for (let i = 0; i < this.roomsMappingModel.roomTypes.length; i++) {
            for (let j = 0; j < this.roomsMappingModel.roomTypes[i].roomTypeRooms.length; j++) {
                for (let k = 0; k < this.roomsMappingModel.roomTypes[i].roomTypeRooms.length; k++) {
                    if (j !== k) {
                        if (
                            this.roomsMappingModel.roomTypes[i].roomTypeRooms[j].orderNumber ===
                            this.roomsMappingModel.roomTypes[i].roomTypeRooms[k].orderNumber
                        ) {
                            const roomModel: RoomModel = this.getRoomById(
                                this.roomsMappingModel.roomTypes[i].roomTypeRooms[j].roomId
                            );
                            const roomTypeModel: RoomTypeModel = this.getRoomTypeRoom(
                                roomModel,
                                this.roomsMappingModel.roomTypes[i].id
                            );
                            roomTypeModel.valid = false;
                            counter++;
                        }
                    }
                }
            }
        }

        if (counter === 0) {
            this.duplicateErrorMessage = '';
            return true;
        } else {
            this.duplicateErrorMessage = 'Duplicate order for same room type.';
            return false;
        }
    }

    getRoomById(id: number): RoomModel {
        let roomModel: RoomModel = new RoomModel();
        for (const room of this.roomsMappingModel.rooms) {
            if (room.id === id) {
                roomModel = room;
                break;
            }
        }
        return roomModel;
    }

    getRoomTypeRoom(roomModel: RoomModel, roomTypeId: number): RoomTypeModel {
        let roomTypeModel: RoomTypeModel = new RoomTypeModel();

        for (let i = 0; i < roomModel.addedRoomTypes.length; i++) {
            for (let j = 0; j < roomModel.addedRoomTypes[i].roomTypeRooms.length; j++) {
                if (
                    roomModel.addedRoomTypes[i].roomTypeRooms[j].roomId === roomModel.id &&
                    roomModel.addedRoomTypes[i].roomTypeRooms[j].roomTypeId === roomTypeId
                ) {
                    return (roomTypeModel = roomModel.addedRoomTypes[i]);
                }
            }
        }
    }

    clearValidators() {
        for (let i = 0; i < this.roomsMappingModel.rooms.length; i++) {
            for (let j = 0; j < this.roomsMappingModel.rooms[i].addedRoomTypes.length; j++) {
                this.roomsMappingModel.rooms[i].addedRoomTypes[j].valid = true;
            }
        }
    }

    openEditPopup(id) {
        for (let i = 0; i < this.roomsMappingModel.rooms.length; i++) {
            if (id === this.roomsMappingModel.rooms[i].id) {
                if (this.roomsMappingModel.rooms[i].virtualRooms.length > 0) {
                    this.roomId = id;
                    this.virtualRoomPopup = true;
                    break;
                } else {
                    this.updateRoomPopup = true;
                    this.roomId = id;
                    break;
                }
            }
        }
    }

    openVirtualRoomPopup() {
        this.virtualRoomPopup = true;
        this.roomId = null;
    }

    showDetailsForRoomTypeRoom() {
        for (const room of this.roomsMappingModel.rooms) {
            for (const addedRoomType of room.addedRoomTypes) {
                for (const roomTypeRoom of addedRoomType.roomTypeRooms) {
                    if (room.id === roomTypeRoom.roomId && addedRoomType.id === roomTypeRoom.roomTypeId) {
                        addedRoomType.showDetails = true;
                    }
                }
            }
        }
    }

    openMapRoomDetails(room, roomType) {
        const roomId: number = room.id;
        const roomTypeId: number = roomType.id;

        let counter = 0;

        for (const item of roomType.roomTypeRooms) {
            if (item.roomTypeId === roomTypeId && roomId === item.roomId) {
                this.roomTypeRoomId = item.id;
                counter++;
                break;
            }
        }

        if (counter > 0) {
            this.roomType = roomType;
            this.mapRoomDetails = true;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
