export class PropertySettingModel {
    public id: number;
    public propertyId?: number;
    public key: string;
    public value: string;
    public description: string;
    public keySetting: KeySettingModel;

    constructor() {
        this.keySetting = new KeySettingModel();
    }
}

export class KeySettingModel {
    public key: string;
    public desc: string;
    public source: string;
    public type: number;
}
