<div class="partners-wrapper housekeeping-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup flex" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2 *ngIf="addHousekeeping">Add cleaning type</h2>
            <h2 *ngIf="editHousekeeping">
                {{ permissionsModel.formButtonEnabled ? 'Edit cleaning type' : 'Cleaning type' }}
            </h2>

            <form
                [formGroup]="valForm"
                (ngSubmit)="submitCleaningType($event)"
                class="form-box flex"
                [ngClass]="[theme == 2 ? 'dark-theme' : '']"
            >
                <div class="form-item">
                    <div class="custom-label">Name</div>
                    <input
                        formControlName="name"
                        name="cleaningFrequencyModelName"
                        class="custom-input"
                        [(ngModel)]="cleaningFrequencyModel.name"
                    />
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['name'].hasError('required') &&
                            (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                        "
                        >Name is required</span
                    >
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['name'].hasError('maxlength') &&
                            (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                        "
                        >Max 50 characters</span
                    >
                </div>
                <div class="form-item">
                    <div class="custom-label">Frequency</div>
                    <dx-select-box
                        class="custom-select"
                        [dataSource]="frequencyTypes"
                        [(value)]="cleaningFrequencyModel.frequencyType"
                        displayExpr="name"
                        formControlName="frequency"
                        (onItemClick)="checkFreqType($event)"
                        valueExpr="value"
                    >
                    </dx-select-box>
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['frequency'].hasError('required') &&
                            (valForm.controls['frequency'].dirty || valForm.controls['frequency'].touched)
                        "
                        >Frequency is required</span
                    >
                </div>
                <div class="form-item" *ngIf="showTagBox">
                    <div class="custom-label">Weekdays</div>
                    <dx-tag-box
                        class="custom-select"
                        [dataSource]="dayOfWeekHelper"
                        displayExpr="day"
                        [value]="cleaningFrequencyModel.weekdaysParsed"
                        formControlName="scheduled"
                        (onValueChanged)="setScheduledFreq($event)"
                        valueExpr="id"
                    >
                    </dx-tag-box>
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['scheduled'].hasError('required') &&
                            (valForm.controls['scheduled'].dirty || valForm.controls['scheduled'].touched)
                        "
                        >Field is required</span
                    >
                </div>
                <div class="form-item">
                    <div class="custom-label">Status</div>
                    <dx-select-box
                        class="custom-select"
                        [dataSource]="statusModelList"
                        displayExpr="name"
                        [(value)]="cleaningFrequencyModel.active"
                        formControlName="status"
                        valueExpr="value"
                    >
                    </dx-select-box>
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['status'].hasError('required') &&
                            (valForm.controls['status'].dirty || valForm.controls['status'].touched)
                        "
                        >Status is required.</span
                    >
                </div>

                <div class="form-item">
                    <div class="custom-label">Summary</div>

                    <textarea
                        class="custom-textarea dark full-width-textarea"
                        type="text"
                        maxlength="255"
                        [(ngModel)]="cleaningFrequencyModel.summary"
                        formControlName="summary"
                        placeholder="Type a text here..."
                    ></textarea>
                    <!--<dx-select-box class="custom-select"-->
                    <!--[dataSource]="statusModelList"-->
                    <!--displayExpr="name"-->
                    <!--[(value)]="cleaningFrequencyModel.active"-->
                    <!--formControlName="status"-->
                    <!--valueExpr="value">-->
                    <!--</dx-select-box>-->
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['status'].hasError('required') &&
                            (valForm.controls['status'].dirty || valForm.controls['status'].touched)
                        "
                        >Status is required.</span
                    >
                </div>

                <div class="form-item short">
                    <input
                        type="checkbox"
                        id="excludeSunday"
                        formControlName="exlSunday"
                        [(ngModel)]="cleaningFrequencyModel.excludeSunday"
                        name="exlSunday"
                        class="css-checkbox"
                    />
                    <label for="excludeSunday" class="css-label radGroup1"></label>
                    <div class="custom-label inline">Exclude Sunday</div>
                </div>

                <div class="form-item short">
                    <input
                        type="checkbox"
                        id="linen"
                        formControlName="linen"
                        [(ngModel)]="cleaningFrequencyModel.linen"
                        name="linen"
                        class="css-checkbox"
                    />
                    <label for="linen" class="css-label radGroup1"></label>
                    <div class="custom-label inline">Linen</div>
                </div>

                <div class="form-item short">
                    <input
                        type="checkbox"
                        id="towel"
                        formControlName="towel"
                        [(ngModel)]="cleaningFrequencyModel.towels"
                        name="towel"
                        class="css-checkbox"
                    />
                    <label for="towel" class="css-label radGroup1"></label>
                    <div class="custom-label inline">Towel</div>
                </div>

                <div class="button-wrapper">
                    <button
                        *ngIf="!loader && permissionsModel.formButtonEnabled"
                        type="submit"
                        class="custom-button blue left"
                    >
                        Save
                    </button>
                    <div *ngIf="loader" class="loader left"></div>
                    <button (click)="close()" type="button" class="custom-button red left">
                        {{ permissionsModel.formButtonEnabled ? 'Cancel' : 'Close' }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
