import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';

@Component({
    selector: 'app-settings-billing-invoice',
    templateUrl: './settings-billing-invoice.component.html',
    styleUrls: ['./settings-billing-invoice.component.less'],
})
export class SettingsBillingInvoiceComponent implements OnInit {
    theme: number;

    @Output() closePopup = new EventEmitter();
    constructor() {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    }

    close() {
        this.closePopup.emit();
    }
    checkValue(n) {
        if (n == 'Caterpay') {
            this.selectCaterpay = true;
        } else {
            this.selectCaterpay = false;
        }
        //console.log(n)
    }
    selectCaterpay = false;
    makedChange = false;
    showEdit = false;
    showinvoice = false;

    payment = ['Cash', 'Card', 'Caterpay'];
    selectedDevice = 'Cash';
    invoice = ['None', 'I23438J'];
    InvoiceItems = [
        {
            Items: 'Bed and Breakfast, Double room 17, 12/11/2017 -  12/11/2017',
            Quantity: '1',
            Amount: '120',
            Vat: '24',
            Total: '145',
        },
        {
            Items: 'Pint Fosters',
            Quantity: '1',
            Amount: '120',
            Vat: '24',
            Total: '145',
        },
        {
            Items: 'Scallops',
            Quantity: '2',
            Amount: '120',
            Vat: '24',
            Total: '145',
        },
        {
            Items: 'Gym pass',
            Quantity: '1',
            Amount: '120',
            Vat: '24',
            Total: '145',
        },
    ];

    ngOnInit() {}
}
