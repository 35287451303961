import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
    InvoiceDetailsPDModel,
    InvoiceItemModel,
    InvoiceHeaderDetailsModel,
} from 'src/app/models/booking/invoice-details-pd.model';
import { BookingPaymentModel } from 'src/app/models/booking/booking-details.model';
import { CurrencyContext, CountryContext } from 'src/app/models/auth-jwt/token-context';
import { BookingDetailsService } from 'src/app/routes/booking/settings-booking-detail/booking-details.service';
import { AuthTokenService } from '../services/auth-token.service';
import { BookingPaymentDetailModel } from 'src/app/models/booking/booking-all-details.model';
import { DxSelectBoxComponent } from 'devextreme-angular';

@Component({
    selector: 'app-booking-details-invoice',
    templateUrl: './booking-details-invoice.component.html',
    styleUrls: ['./booking-details-invoice.component.less'],
})
export class BookingDetailsInvoiceComponent implements OnInit {
    @ViewChild('selectBox') selectBox: DxSelectBoxComponent;
    @Input() addInvoice: boolean;
    @Output() addInvoiceChange = new EventEmitter<boolean>();

    @Input() editInvoice: boolean;
    @Output() editInvoiceChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();
    @Output() updateGrid = new EventEmitter();

    @Input() bookingId: number;
    @Input() invoiceId: number;
    @Input() disableEditMode: boolean;
    @Input() status: string;

    addRoomItems = false;
    editRoomItems = false;
    deleteRoomItems = false;

    addExtraItems = false;
    editExtraItems = false;
    deleteExtraItems = false;

    addPaymentItems = false;
    editPaymentItems = false;
    deletePaymentItems = false;

    loader = false;
    submitted = false;
    theme: number;

    invoiceAccount: number;

    valForm: UntypedFormGroup;

    invoiceDetailsPDModel = new InvoiceDetailsPDModel();
    currentRoomItem = new InvoiceItemModel();
    currentExtraItem = new InvoiceItemModel();
    currentPaymentItem = new BookingPaymentModel();
    currencyContext: CurrencyContext = new CurrencyContext();
    displayPayments: BookingPaymentModel[];
    countryContext: CountryContext;

    constructor(private bookingDetailsService: BookingDetailsService, private fb: UntypedFormBuilder) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;

        this.valForm = fb.group({
            customerName: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            customerAddress: [null, Validators.compose([Validators.maxLength(50)])],
            customerAddress2: [null, Validators.compose([Validators.maxLength(50)])],
            customerAddress3: [null, Validators.compose([Validators.maxLength(50)])],
            customerAddress4: [null, Validators.compose([Validators.maxLength(50)])],
            customerCountry: [null, Validators.compose([Validators.maxLength(50)])],
            customerData1: [null, Validators.compose([Validators.maxLength(100)])],
            customerData2: [null, Validators.compose([Validators.maxLength(100)])],
            poRef: [null, Validators.compose([Validators.maxLength(50)])],
        });
        this.currencyContext = AuthTokenService.decodeJwtToken().Currency;
        this.countryContext = AuthTokenService.decodeJwtToken().Country;
      }

    ngOnInit() {
        this.init();
    }

    init() {
        if (this.addInvoice) {
            this.bookingDetailsService.newInvoice(this.bookingId).subscribe(
                (response) => {
                    this.invoiceDetailsPDModel = response;
                    this.setDisplayPayments();
                    if (this.invoiceDetailsPDModel.invoiceToList.length > 0) {
                        this.setHeaderDetails(this.invoiceDetailsPDModel.invoiceToList[0]);
                        this.invoiceAccount = this.invoiceDetailsPDModel.invoiceToList[0].invoiceTo.accountId;
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        } else if (this.editInvoice) {
            this.bookingDetailsService.editInvoice(this.invoiceId).subscribe(
                (response) => {
                    this.invoiceDetailsPDModel = response;
                    this.setDisplayPayments();
                    if (this.disableEditMode) {
                        this.valForm.controls['customerName'].disable();
                        this.valForm.controls['customerAddress'].disable();
                        this.valForm.controls['customerAddress2'].disable();
                        this.valForm.controls['customerAddress3'].disable();
                        this.valForm.controls['customerAddress4'].disable();
                        this.valForm.controls['customerData1'].disable();
                        this.valForm.controls['customerData2'].disable();
                        this.valForm.controls['customerCountry'].disable();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }

    setHeaderDetails(headerDetails: InvoiceHeaderDetailsModel) {
        this.invoiceDetailsPDModel.invoice.id = headerDetails.header.id;
        this.invoiceDetailsPDModel.invoice.invoiceNo = headerDetails.header.invoiceNo;
        this.invoiceDetailsPDModel.invoice.bookingId = this.bookingId;
        if (headerDetails.invoiceTo.accountType === 'Guest') {
            this.invoiceDetailsPDModel.invoice.guestId = headerDetails.invoiceTo.accountId;
            this.invoiceDetailsPDModel.invoice.partnerId = null;
        } else if (headerDetails.invoiceTo.accountType === 'Partner') {
            this.invoiceDetailsPDModel.invoice.guestId = null;
            this.invoiceDetailsPDModel.invoice.partnerId = headerDetails.invoiceTo.accountId;
        }
        this.invoiceDetailsPDModel.invoice.dateCreated = headerDetails.header.dateCreated;
        // this.invoiceDetailsPDModel.invoice.status = headerDetails.header.status;
        this.invoiceDetailsPDModel.invoice.templateId = headerDetails.header.templateId;
        this.invoiceDetailsPDModel.invoice.customerName = headerDetails.header.customerName;
        this.invoiceDetailsPDModel.invoice.customerAddress = headerDetails.header.customerAddress;
        this.invoiceDetailsPDModel.invoice.customerCountry = headerDetails.header.customerCountry;
        this.invoiceDetailsPDModel.invoice.customerAddress2 = headerDetails.header.customerAddress2;
        this.invoiceDetailsPDModel.invoice.customerAddress3 = headerDetails.header.customerAddress3;
        this.invoiceDetailsPDModel.invoice.customerPostalCode = headerDetails.header.customerPostalCode;
        this.invoiceDetailsPDModel.invoice.customerAdditionalData1 = headerDetails.header.customerAdditionalData1;
        this.invoiceDetailsPDModel.invoice.customerAdditionalData2 = headerDetails.header.customerAdditionalData2;
    }

    setHeaderDetailsDevex(e) {
        if (e.itemData !== null) {
            const headerDetails: InvoiceHeaderDetailsModel = e.itemData;
            this.setHeaderDetails(headerDetails);
        }
    }

    addNewRooms() {
        if (this.addInvoice) {
            this.addRoomItems = true;
        } else if (this.editInvoice) {
            this.editRoomItems = true;
        }
    }

    addNewExtras() {
        if (this.addInvoice) {
            this.addExtraItems = true;
        } else if (this.editInvoice) {
            this.editExtraItems = true;
        }
    }

    addNewPayment() {
        if (this.addInvoice) {
            this.addPaymentItems = true;
        } else if (this.editInvoice) {
            this.editPaymentItems = true;
        }
    }

    setRoomItemsInList(roomItems: InvoiceItemModel[]) {
        let roomItemsList: InvoiceItemModel[] = [];
        roomItemsList = JSON.parse(JSON.stringify(this.invoiceDetailsPDModel.invoice.rooms.items));
        roomItems.forEach((x) => roomItemsList.push(x));
        this.invoiceDetailsPDModel.invoice.rooms.items = roomItemsList;
    }

    setExtraItemsInList(extraItems: InvoiceItemModel[]) {
        let extrasItemsList: InvoiceItemModel[] = [];
        extrasItemsList = JSON.parse(JSON.stringify(this.invoiceDetailsPDModel.invoice.extras.items));
        extraItems.forEach((x) => extrasItemsList.push(x));
        this.invoiceDetailsPDModel.invoice.extras.items = extrasItemsList;
    }

    setPaymentsInList(paymentItems: BookingPaymentDetailModel[]) {
        this.mappingPaymentModels(paymentItems);
    }

    setDisplayPayments() {
        this.displayPayments = this.invoiceDetailsPDModel.invoice.payments.filter(
            (x) => x.paymentType !== 'Tokenise' && x.paymentType !== 'Verify' && x.paymentType !== 'VerifyToken'
        );
    }

    mappingPaymentModels(paymentDetailsModelList: BookingPaymentDetailModel[]) {
        let paymentItemsList: BookingPaymentModel[] = [];
        paymentItemsList = JSON.parse(JSON.stringify(this.invoiceDetailsPDModel.invoice.payments));
        paymentDetailsModelList.forEach((x) => {
            const paymentModel: BookingPaymentModel = new BookingPaymentModel();
            paymentModel.id = x.id;
            paymentModel.paymentNo = x.paymentNo;
            paymentModel.paymentDate = x.date;
            paymentModel.amount = x.amount;
            paymentModel.paymentType = x.type.value;
            paymentModel.paymentPurpose = x.purpose.value;
            paymentModel.bookingId = x.bookingId;
            paymentModel.invoiceId = x.invoiceId;
            paymentModel.guestId = x.guestId;
            paymentModel.partnerId = x.partnerId;

            paymentItemsList.push(paymentModel);
        });

        this.invoiceDetailsPDModel.invoice.payments = paymentItemsList;
    }

    totalRoomsPrice(): number {
        if (this.addInvoice) {
            let total = 0;
            this.invoiceDetailsPDModel.invoice.rooms.items.forEach((x) => (total += x.total));
            this.invoiceDetailsPDModel.invoice.rooms.total = total;
            return total;
        } else if (this.editInvoice) {
            return this.invoiceDetailsPDModel.invoice.rooms.total;
        }
    }

    totalExtrasPrice(): number {
        if (this.addInvoice) {
            let total = 0;
            this.invoiceDetailsPDModel.invoice.extras.items.forEach((x) => (total += x.total));
            this.invoiceDetailsPDModel.invoice.extras.total = total;
            return total;
        } else if (this.editInvoice) {
            return this.invoiceDetailsPDModel.invoice.extras.total;
        }
    }

    totalAmountOfPayments() {
        if (this.addInvoice) {
            let total = 0;
            this.invoiceDetailsPDModel.invoice.payments.forEach((x) => (total += x.amount));
            this.invoiceDetailsPDModel.invoice.paymentTotal = total;
            return total;
        } else if (this.editInvoice) {
            return this.invoiceDetailsPDModel.invoice.paymentTotal;
        }
    }

    balanceInvoice(): number {
        if (this.addInvoice) {
            return this.totalRoomsPrice() + this.totalExtrasPrice() - this.totalAmountOfPayments();
        } else if (this.editInvoice) {
            return this.invoiceDetailsPDModel.invoice.balanceTotal;
        }
    }

    submitInvoice(e) {
        e.stopPropagation();

        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            this.loader = true;
            if (this.addInvoice) {
                this.createInvoice();
            } else if (this.editInvoice) {
                this.updateInvoice();
            }
        }
    }

    updateInvoice() {
        this.bookingDetailsService.updateInvoice(this.invoiceDetailsPDModel.invoice).subscribe(
            (response) => {
                this.loader = false;
                this.update.emit();
                this.close();
            },
            (error) => {
                console.log(error);
                this.loader = false;
            }
        );
    }

    createInvoice() {
        this.bookingDetailsService.createInvoice(this.invoiceDetailsPDModel.invoice).subscribe(
            (response) => {
                this.update.emit();
                this.close();
                this.loader = false;
            },
            (error) => {
                console.log(error);
                this.loader = false;
            }
        );
    }

    deleteRoomItem(data: InvoiceItemModel) {
        this.currentRoomItem = data;
        this.deleteRoomItems = true;
    }

    deleteExtrasItem(data: InvoiceItemModel) {
        this.currentExtraItem = data;
        this.deleteExtraItems = true;
    }

    deletePaymentItem(data: BookingPaymentModel) {
        this.currentPaymentItem = data;
        this.deletePaymentItems = true;
    }

    confirmDeleteRoomItems() {
        if (this.addInvoice) {
            const index = this.invoiceDetailsPDModel.invoice.rooms.items.indexOf(this.currentRoomItem);
            this.invoiceDetailsPDModel.invoice.rooms.items.splice(index, 1);
        } else if (this.editInvoice) {
            this.bookingDetailsService.deleteInvoiceItem(this.currentRoomItem).subscribe(
                (response) => {
                    this.init();
                    this.refreshParentData();
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }

    confirmDeleteExtraItems() {
        if (this.addInvoice) {
            const index = this.invoiceDetailsPDModel.invoice.extras.items.indexOf(this.currentExtraItem);
            this.invoiceDetailsPDModel.invoice.extras.items.splice(index, 1);
        } else if (this.editInvoice) {
            this.bookingDetailsService.deleteInvoiceItem(this.currentExtraItem).subscribe(
                (response) => {
                    this.init();
                    this.refreshParentData();
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }

    confirmDeletePaymentItems() {
        if (this.addInvoice) {
            const index = this.invoiceDetailsPDModel.invoice.payments.indexOf(this.currentPaymentItem);
            this.invoiceDetailsPDModel.invoice.payments.splice(index, 1);
        } else if (this.editInvoice) {
            this.bookingDetailsService.deleteInvoicePayment(this.currentPaymentItem).subscribe(
                (response) => {
                    this.init();
                    this.refreshParentData();
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }

    refreshParentData() {
        this.updateGrid.emit();
    }

    close() {
        this.addInvoiceChange.emit(false);
        this.editInvoiceChange.emit(false);
    }public onCellPrepared(event)
  {
    if (event.rowType === 'header' && event.column.dataField == "vatAmount")
    {
      let commandCell = event.cellElement;
      commandCell[0].innerHTML = commandCell[0].innerHTML.replace('Vat Amount', this.countryContext.VatName + " Amount");
    }
  }
}
