import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RatePlanService } from '../../rate-plan.service';
import { CheckedRateScheduleModel } from '../../../../../../models/rate-schedule/checked-rate-schedule.model';
import { UpdateRateScheduleForRatePlanPDModel } from '../../../../../../models/rate-schedule/update-rate-schedule-for-rate-plan-pd.model';
import { AuthTokenService } from '../../../../../../shared/services/auth-token.service';
import { InventoryRoomTypesPermissionsModel } from '../../../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../../../enums/permissions.enum';

@Component({
    selector: 'app-rate-plan-schedules',
    templateUrl: './rate-plan-schedules.component.html',
    styleUrls: ['./rate-plan-schedules.component.less'],
})
export class RatePlanSchedulesComponent implements OnInit {
    @Input() rateSchedules: boolean;
    @Output() rateSchedulesChange = new EventEmitter<boolean>();
    @Input() ratePlanId: number;
    @Input() ratePlanName: number;

    permissionsModel: InventoryRoomTypesPermissionsModel;
    theme: number;

    checkedRateScheduleModel: CheckedRateScheduleModel[];
    selectedRows: CheckedRateScheduleModel[] = [];
    updateRateScheduleForRatePlanPDModel = new UpdateRateScheduleForRatePlanPDModel();

    constructor(private ratePlanService: RatePlanService, private permissionsService: PermissionsService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryRoomTypes);
    }

    ngOnInit() {
        this.ratePlanService.getCheckedSchedulesList(this.ratePlanId).subscribe(
            (response) => {
                this.checkedRateScheduleModel = response;
                this.checkedRateScheduleModel.forEach((item) => {
                    if (item.checked) {
                        this.selectedRows.push(item);
                    }
                });
            },
            (error) => {
                console.log(error);
                this.close();
            }
        );
    }

    saveRateSchedules() {
        this.updateRateScheduleForRatePlanPDModel.ratePlanId = this.ratePlanId;
        this.updateRateScheduleForRatePlanPDModel.list = this.selectedRows;

        this.ratePlanService.updateCheckedSchedulesList(this.updateRateScheduleForRatePlanPDModel).subscribe(
            (response) => {
                this.close();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    close() {
        this.rateSchedulesChange.emit(false);
    }
}
