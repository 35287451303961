import { Component, ViewChild } from '@angular/core';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { CountryContext } from 'src/app/models/auth-jwt/token-context';
import DateHelper from 'src/app/shared/helpers/date-helper';
import { ZReadConfigurationModel, ZReadItemModel } from 'src/app/models/reports/zread-configuration.model';
import { ReportsService } from '../../reports/reports.service';
import * as moment from 'moment';
import { ReportingDateConfigurationModel } from 'src/app/models/reports/reports-date-configuration.model';
import { DxDataGridComponent } from 'devextreme-angular';
import { ReportingEposConfigurationModel } from 'src/app/models/reports/reports-epos-configuration.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';


@Component({
  selector: 'app-settings-zread',
  templateUrl: './settings-zread.component.html',
  styleUrls: ['./settings-zread.component.less']
})
export class SettingsZReadComponent {
  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  
  loader = false;
  theme: number;
  countryContext: CountryContext;
  reportRunDates: string[];
  model: ZReadConfigurationModel;
  reportingDateConfigurationModel: ReportingDateConfigurationModel;

  dataLoaded: boolean;
  maxReportDate: Date;
  reportDate: Date;
  eodStatus: string;
  
  categories: ReportingEposConfigurationModel[];
  paymentTypes: ReportingEposConfigurationModel[];

  zreadTypes: any[] = [{type:1, description:"EPOS Category"},{type:2, description:"EPOS Payment Type"}]
  addNew: boolean = false;
  addNewType: number = 1;
  addNewZRead: ZReadItemModel;
  addError: string = "";
  showError: boolean;
  addNewKey: string = '$$!-Add-!$$';
  newCategoryName: string = '';
  newPaymentTypeName: string = '';

  selectedNewCategoryKey: string = null;
  selectedNewPaymentKey: string = null;

  constructor(
    private reportsService: ReportsService,
    private toasterService: ToastService,
) {
    this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    this.countryContext = AuthTokenService.decodeJwtToken().Country;
}

getCellCssClass({ date, view }) {
    let cssClass = '';

    if (this.reportRunDates && this.reportRunDates.findIndex(x => x==DateHelper.formatDate(date, 'YYYY-MM-DD')) >= 0) {
      cssClass = 'eod-report-run';
    }

    return cssClass;
  }

  getReportStatusCssClass() {
    if (this.eodStatus == "Finalised") {
      return "text-error";
    }
    if (this.eodStatus == "Draft") {
      return "text-warning";
    }
    if (this.eodStatus == "Not run") {
      return "text-info";
    }
  }

  ngOnInit(): void {
    this.model = new ZReadConfigurationModel();
    this.addNewZRead = new ZReadItemModel();
    
    this.maxReportDate = new Date(); //today
    this.maxReportDate.setDate(this.maxReportDate.getDate() + 1)

    this.reportDate =  moment(new Date()).set({ hour: 12, minute: 0, second: 0 }).toDate();
    this.loadReportingDatesFinalised();
    this.loadZReadDataForReportingDate();
    this.loadReportingDateConfiguration();
  }

  ngAfterViewInit() {
    if (this.grid && this.grid.instance) {
        this.grid.instance.columnOption('VatId', 'caption', this.countryContext.VatName);
    }
}

  loadReportingDatesFinalised() {
    this.loader = true;
    this.reportsService.getReportingDatesFinalised().subscribe(
      (res) => {
        console.log('loadReportingDatesFinalised', res);     
        this.reportRunDates = res;
        this.loader = false;
      },
      (error) => {
        console.log('loadReportingDatesFinalised error: ', error);
        this.loader = false;
      }
    );   
  }

  loadZReadDataForReportingDate() {
    console.log('Load for date', this.reportDate);
    this.reportsService.getZReadConfiguration(this.reportDate).subscribe(
      (response) => {
        if (response != null) {
          this.model = response;
          if (this.model.categoryItems.length > 0) {
            this.addNewZRead.vatId = this.model.categoryItems[0].vatId;
          }
          this.model.categoryOptions.unshift(new ReportingEposConfigurationModel(-999, 2, this.addNewKey, '--Add new--'));
          this.model.paymentTypeOptions.unshift(new ReportingEposConfigurationModel(-999, 1, this.addNewKey, '--Add new--'));
          this.dataLoaded = true;
        }
        else {
          this.dataLoaded = false;
        }
        this.loader = false;
      },
      (error) => {
        console.log('Error with zread', error);
        this.loader = false;
      }
    );
  }


  loadReportingDateConfiguration() {
    this.reportsService.getReportingDateConfiguration(this.reportDate).subscribe(
      (response) => {
        if (response != null) {
          this.reportingDateConfigurationModel = response;
     
          if (this.reportingDateConfigurationModel.endOfDayReportGenerated) {
            this.eodStatus = "Finalised";
          }
          else {
            this.eodStatus = "Not run";
          }
        }
        else {
          this.eodStatus = "Unknown";
        }
        this.loader = false;
      },
      (error) => {
        this.loader = false;
      }
    );
  }

  reportDateChanged(e) {
    console.log('reportDateChanged', e);

    this.reportDate = moment(e.value).set({ hour: 12, minute: 0, second: 0 }).toDate();
    this.loader = true;
    
    this.dataLoaded = false;
    this.loadZReadDataForReportingDate();
    this.loadReportingDateConfiguration();
    
  }

  addNewZReadItem(type: number) {
    if (this.eodStatus == 'Finalised') {
      this.showToastError('A new entry cannot be added because the end of day report has been finalised.');
      return;
    }
    this.addNew = true;
  }

  closeAddModal() {
    this.addNew = false;
    this.addNewZRead.amount = null;
    this.addNewZRead.description = null;
    this.addNewZRead.key = null;
    this.addNewZRead.vatId = null;
    this.newCategoryName = null;
    this.newPaymentTypeName = null;
    this.addError="";
    this.selectedNewCategoryKey = null;
    this.selectedNewPaymentKey = null;
    this.addNewZRead.newEPOSOption = null;
  }

  add() {
    this.addError="";
    this.addNewZRead.newEPOSOption = null;
    
    if (this.addNewType == 1) {
      this.addNewZRead.key = this.selectedNewCategoryKey;
      console.log('add new category item', this.addNewZRead.key);
      this.addNewZRead.itemType = "Category";

      if (this.addNewZRead.key == null) {
        this.addError = "Please select an EPOS category";
        return;
      }
      else {
        this.addNewZRead.description = this.model.categoryOptions.find(x => x.key == this.addNewZRead.key).value;
      }

      if (this.addNewZRead.amount == null || this.addNewZRead.amount == 0) {
        this.addError = "Please enter an amount";
        return;
      }
      if (this.addNewZRead.vatId == null) {
        this.addError = "Please select a " + this.countryContext.VatName + " rate";
        return;
      }
      //check if combination of key / vat doesn't already exist
      if (this.model.categoryItems.filter(x => x.key == this.addNewZRead.key && x.vatId == this.addNewZRead.vatId).length > 0)
      {
        this.addError = "This EPOS category and " + this.countryContext.VatName + " rate already exists, please use the existing one.";
      }
      else {
        if (this.addNewZRead.key == this.addNewKey)
        {
          if (this.model.categoryOptions.filter(x => x.value == this.newCategoryName).length > 0)
          {
            this.addError = "This EPOS category already exists, please use the existing one.";
            return;
          }
          let eposCategory = new ReportingEposConfigurationModel(0, 2, this.addNewKey + '_' + this.newCategoryName, this.newCategoryName);
          this.model.categoryOptions.push(eposCategory);
          this.addNewZRead.description = this.newCategoryName;
          this.addNewZRead.newEPOSOption = eposCategory;
          this.addNewZRead.key = eposCategory.key;
        }
        else if (this.addNewZRead.key.startsWith(this.addNewKey))
        {
          let eposCategory = this.model.categoryOptions.find(x => x.key == this.addNewZRead.key);
          this.addNewZRead.newEPOSOption = eposCategory;
        }

        this.model.categoryItems.push(JSON.parse(JSON.stringify(this.addNewZRead)));
        this.closeAddModal();
        
      }
    }
    if (this.addNewType == 2) {
      this.addNewZRead.key = this.selectedNewPaymentKey;
      console.log('add new payment item', this.addNewZRead.key);
      this.addNewZRead.itemType = "PaymentTypes";

      if (this.addNewZRead.key == null) {
        this.addError = "Please select an EPOS Payment Type";
        return;
      }
      else {
        this.addNewZRead.description = this.model.paymentTypeOptions.find(x => x.key == this.addNewZRead.key).value;
      }

      if (this.addNewZRead.amount == null || this.addNewZRead.amount == 0) {
        this.addError = "Please enter an amount";
        return;
      }
      this.addNewZRead.vatId = null
      if (this.model.paymentItems.filter(x => x.key == this.addNewZRead.key).length > 0)
      {
        this.addError = "This EPOS payment type already exists, please use the existing one.";
      }
      else 
      {
        if (this.addNewZRead.key == this.addNewKey)
        {
          if (this.model.paymentTypeOptions.filter(x => x.value == this.newPaymentTypeName).length > 0)
          {
            this.addError = "This EPOS payment type already exists, please use the existing one.";
            return;
          }
          let eposPayment = new ReportingEposConfigurationModel(0, 1, this.addNewKey + '_' + this.newPaymentTypeName, this.newPaymentTypeName);
          this.model.paymentTypeOptions.push(eposPayment);
          this.addNewZRead.description = this.newPaymentTypeName;
          this.addNewZRead.newEPOSOption = eposPayment;
          this.addNewZRead.key = eposPayment.key;
        }
        else if (this.addNewZRead.key.startsWith(this.addNewKey))
        {
          let eposPayment = this.model.paymentTypeOptions.find(x => x.key == this.addNewZRead.key);
          this.addNewZRead.newEPOSOption = eposPayment;
        }

        this.model.paymentItems.push(JSON.parse(JSON.stringify(this.addNewZRead)));
        this.closeAddModal();
        
      }
    }
  }

  setZReadAmount(item: ZReadItemModel, event: any) {
    item.amount = event.target.value;
  }

  setZReadAddAmount(event: any) {
    this.addNewZRead.amount = event.target.value;
  }

  setZReadVat(item: ZReadItemModel, event: any) {
    if (this.model.categoryItems.filter(x => x.key == item.key && x.vatId == event.value).length > 1)
    {
      this.showToastError("This EPOS category and " + this.countryContext.VatName + " rate already exists, please use the existing one.");
      console.log('returning Vat to previous value', event.previousValue);
      setTimeout(() => {item.vatId = event.previousValue}, 800);

    }
    else 
    {
      item.vatId = event.value;
    }
    
  }

  setNewCategoryName(event: any) {
    this.newCategoryName = event.target.value;
  }
 
  setNewPaymentTypeName(event: any) {
    this.newPaymentTypeName = event.target.value;
  }
 

  deleteZRead(type: number, item: ZReadItemModel) {
    if (this.eodStatus == 'Finalised') {
      this.showToastError('This entry cannot be deleted because the end of day report has been finalised.');
      return;
    }
    if (type == 1) {
      let index = this.model.categoryItems.findIndex(x => x.key == item.key && x.vatId == item.vatId);
      console.log('deleting category item', item);
      this.model.categoryItems.splice(index, 1);
    }
    if (type == 2) {
      let index = this.model.paymentItems.findIndex(x => x.key == item.key);
      console.log('deleting payment type item', item);
      this.model.paymentItems.splice(index, 1);
    }
  }

  validateZRead() {
    if (this.model.categoryItems.length == 0 && this.model.paymentItems.length == 0) {
      return true;
    }
    let nonZeroCats = this.model.categoryItems.filter(x => x.amount != null && x.amount > 0).length;
    let nonZeroPayments = this.model.paymentItems.filter(x => x.amount != null && x.amount > 0).length;

    if (nonZeroCats == 0 && nonZeroPayments == 0)
    {
      return false;
    }
    return true;
  }

  saveZRead() {
    if (this.validateZRead()) {

      this.loader = true;

      this.reportsService.saveZreadInput(this.model).subscribe(
          (response) => {
              
              this.loader = false;
              
              if (response.saveResult=='Success')
              {
                  this.showToastSuccess('Z-read input saved.', 'Success');
                  this.loadZReadDataForReportingDate();
              }
          },
          (error) => {
              this.loader = false;
              console.log(error);
          }
      );
    } 
    else {
      this.showToastError("At least 1 amount must be greater than zero");
    }
  }

  showToastError(message: string, title?: string) {
    this.toasterService.showToast(title ? title : 'Error', message, null, ToastEventType.Error, true, 5000);
  }

  showToastSuccess(message: string, title?: string) {
    this.toasterService.showToast(title ? title : 'Success', message, null, ToastEventType.Success, true, 2000);
  }

}
