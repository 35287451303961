import { CleaningGroupModel } from './cleaning-group.model';
import { RoomModel } from '../room/rooms-mapping.model';

export class CleaningGroupPDModel {
    public cleaningGroup: CleaningGroupModel;
    public rooms: RoomModel[];

    constructor() {
        this.cleaningGroup = new CleaningGroupModel();
    }
}
