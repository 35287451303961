<div class="rate-schedules-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <div *ngIf="popupLoader" class="loader popup-loader"></div>
            <div *ngIf="!popupLoader">
                <h2>Rate Schedules - {{ roomTypeShortName }}</h2>

                <dx-data-grid
                    [dataSource]="roomTypeRateScheduleModel.rateSchedules"
                    [allowColumnResizing]="true"
                    rowTemplate="rowTemplateName"
                    [columns]="[
                        { dataField: 'id', caption: 'ID', allowSorting: true, width: 50 },
                        { dataField: 'name', caption: 'Name', allowSorting: true },
                        { cellTemplate: 'details', allowSorting: false, width: 60 },
                        { cellTemplate: 'delete', allowSorting: false, width: 60 }
                    ]"
                >
                    <table>
                        <ng-container *dxTemplate="let row of 'rowTemplateName'">
                            <tr>
                                <td class="center-td">{{ row.data.id }}</td>
                                <td>
                                    <dx-select-box
                                        class="custom-select"
                                        [dataSource]="roomTypesWithRateSchedules.allRateSchedules"
                                        [value]="row.data.id"
                                        valueExpr="id"
                                        displayExpr="name"
                                        (onValueChanged)="setRateScheduleToRoomType($event, row.data)"
                                        [disabled]="!permissionsModel.formButtonEnabled"
                                    ></dx-select-box>
                                </td>
                                <td>
                                    <i *ngIf="row.data.id !== null" class="fas fa-rectangle-list fa-icon gray fa-icon-small" title="Details" (click)="openRateScheduleDetails(row.data.id)"></i>
                                </td>
                                <td (click)="deleteItem(row.data)" *ngIf="permissionsModel.delete">
                                    <i title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small"></i>
                                </td>
                            </tr>
                        </ng-container>
                    </table>

                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="true" [showInfo]="true">
                    </dxo-pager>
                </dx-data-grid>
                <div class="form-item">
                    <span class="text-danger">{{ errorMessage }}</span>
                </div>

                <div (click)="addNewItem()" class="add-new-box" *ngIf="permissionsModel.formButtonEnabled">
                    <span class="text">Add New</span>
                    <div class="img">
                        <i class="fa-solid fa-plus fa-icon gray"></i>
                    </div>
                </div>

                <button
                    *ngIf="!loader && permissionsModel.formButtonEnabled"
                    type="button"
                    (click)="submitRateSchedulesToRoomTypes()"
                    class="custom-button blue left"
                >
                    Save
                </button>
                <div *ngIf="loader" class="loader left"></div>
                <button (click)="close()" type="button" class="custom-button red left">
                    {{ permissionsModel.formButtonEnabled ? 'Cancel' : 'Close' }}
                </button>
            </div>
        </div>
    </div>
</div>
<app-rate-schedules-details
    *ngIf="scheduleDetails"
    [(editSchedule)]="scheduleDetails"
    [rateScheduleId]="rateScheduleId"
></app-rate-schedules-details>
