import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { OtaConnectionModel } from '../../../models/ota-connections/ota-connection.model';
import { OtaConnectionSettingModel } from '../../../models/ota-connections/ota-connection-setting.model';
import { OTADerivedPricingModel } from '../../../models/ota-connections/bcom-derived-pricing-pd.model';

@Injectable()
export class OtaConnectionsService {
    private getAllOtaConnectionsUrl = environment.apiUrl + '/OTA/GetAllOtaConnections';
    private getOtaConnectionDetailsUrl = environment.apiUrl + '/OTA/GetOtaConnectionDetails';
    private insertOtaConnectionUrl = environment.apiUrl + '/OTA/InsertOtaConnection';
    private updateOtaConnectionUrl = environment.apiUrl + '/OTA/UpdateOtaConnection';
    private deleteOtaConnectionUrl = environment.apiUrl + '/OTA/DeleteOtaConnection';
    private syncAllOtaConnectionUrl = environment.apiUrl + '/OTA/SyncAllOtaConnection';
    private syncRatesOtaConnectionUrl = environment.apiUrl + '/OTA/SyncRatesOtaConnection';
    private syncAvailabilityOtaConnectionUrl = environment.apiUrl + '/OTA/SyncAvailabilityOtaConnection';
    private syncInOtaConnectionUrl = environment.apiUrl + '/OTA/SyncInOtaConnection';
    private getOTAConnectionSettingUrl = environment.apiUrl + '/OTA/GetOTAConnectionSetting';
    private updateOtaConnectionSettingUrl = environment.apiUrl + '/OTA/UpdateOTAConnectionSettings';
    private getDerivedPricingBcomUrl = environment.apiUrl + '/OTA/GetDerivedPricingBcom';
    private updateBcomDerivedPricingUrl = environment.apiUrl + '/OTA/UpdateBcomDerivedPricing';
    private getOTABookingReviewsUrl = environment.apiUrl + '/OTA/GetOTABookingReviews';
    private getConfigurationUrl = environment.apiUrl + '/OTA/GetConfiguration';
    private getPropertyConfigurationUrl = environment.apiUrl + '/OTA/GetPropertyConfiguration';
    private syncPropertyUrl = environment.apiUrl + '/OTA/SyncProperty';
    private otaSwitchIFrameUrl = environment.apiUrl + '/OTA/GetOTASwitchIFrameUrl';
    private hasDoneFullSyncUrl = environment.apiUrl + '/OTA/HasDoneFullSync';
    private ipAddressInfoUrl = environment.uiUrl + 'ipinfo';

    constructor(private http: HttpRequestService) {}

    getAllOtaConnections() {
        return this.http.get(this.getAllOtaConnectionsUrl);
    }

    getOtaConnectionDetails(otaConnectionId?: number) {
        return this.http.get(this.getOtaConnectionDetailsUrl + '?otaConnectionId=' + otaConnectionId);
    }

    getOTAConnectionSetting(otaConnectionId: number) {
        return this.http.get(this.getOTAConnectionSettingUrl + '?otaConnectionId=' + otaConnectionId);
    }

    insertOtaConnection(model: OtaConnectionModel) {
        return this.http.post(this.insertOtaConnectionUrl, model, true);
    }

    updateOtaConnection(model: OtaConnectionModel) {
        return this.http.put(this.updateOtaConnectionUrl, model, true);
    }

    deleteOtaConnection(otaConnectionId: number) {
        return this.http.delete(this.deleteOtaConnectionUrl + '?otaConnectionId=' + otaConnectionId, true);
    }

    syncAllOtaConnection(otaConnectionId: number) {
        return this.http.get(
            this.syncAllOtaConnectionUrl + '?otaConnectionId=' + otaConnectionId,
            true,
            'Action has been added to queue',
            ' '
        );
    }

    syncRatesOtaConnection(otaConnectionId: number) {
        return this.http.get(
            this.syncRatesOtaConnectionUrl + '?otaConnectionId=' + otaConnectionId,
            true,
            'Action has been added to queue',
            ' '
        );
    }

    syncAvailabilityOtaConnection(otaConnectionId: number) {
        return this.http.get(
            this.syncAvailabilityOtaConnectionUrl + '?otaConnectionId=' + otaConnectionId,
            true,
            'Action has been added to queue',
            ' '
        );
    }

    syncInOtaConnection(otaConnectionId: number) {
        return this.http.get(this.syncInOtaConnectionUrl + '?otaConnectionId=' + otaConnectionId, true);
    }

    updateOtaConnectionSetting(connectionSettingsModel: OtaConnectionSettingModel) {
        return this.http.post(this.updateOtaConnectionSettingUrl, connectionSettingsModel, true);
    }

    getDerivedPricingBcom(otaConnectionId: number) {
        return this.http.get(this.getDerivedPricingBcomUrl + '?otaConnectionId=' + otaConnectionId);
    }

    updateBcomDerivedPricing(otaConnectionId: number, otaDerivedPricingModel: Array<OTADerivedPricingModel>) {
        return this.http.post(
            `${this.updateBcomDerivedPricingUrl}?otaConnectionId=${otaConnectionId}`,
            otaDerivedPricingModel,
            true,
            'Action has been added to queue',
            ' '
        );
    }

    getOTABookingReviews(otaConnectionId: number) {
        return this.http.get(`${this.getOTABookingReviewsUrl}?otaConnectionId=${otaConnectionId}`);
    }

    getConfiguration(otaConnectionId: number) {
        return this.http.post(this.getConfigurationUrl, otaConnectionId);
    }

    getPropertyConfiguration(otaConnectionId: number) {
        return this.http.get(this.getPropertyConfigurationUrl + '?otaConnectionId=' + otaConnectionId);
    }

    syncProperty(otaConnectionId: number) {
        return this.http.post(this.syncPropertyUrl, otaConnectionId);
    }

    getMappingIFrameUrl(otaConnectionId: number, ip: string, type: string) {
        let model = {otaConnectionId: otaConnectionId, ip: ip, frameType: type};
        return this.http.post(this.otaSwitchIFrameUrl, model);
    }

    hasDoneFullSync(otaConnectionId: number) {
        return this.http.get(this.hasDoneFullSyncUrl + '?otaConnectionId=' + otaConnectionId);
    }

    getIpInfo() {
        return this.http.get(this.ipAddressInfoUrl);
    }
}
