import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { environment } from '../../../../environments/environment';
import { BookingPaymentsFilterApiModel } from '../../../models/payment/payment.model';

@Injectable()
export class SettingsPaymentsService {
    private getDataForBookingPaymentSearchUrl = environment.apiUrl + '/Payment/GetDataForBookingPaymentSearch';
    private getFilteredBookingPaymentsUrl = environment.apiUrl + '/Payment/GetFilteredBookingPayments';
    private getBookingPaymentsCsvUrl = environment.apiUrl + '/Payment/GetBookingPaymentsCsv';
    constructor(private http: HttpRequestService) {}

    getDataForBookingPaymentSearch() {
        return this.http.get(this.getDataForBookingPaymentSearchUrl);
    }

    getData(filterPaymentsModel: any) {
        return this.http.post(this.getFilteredBookingPaymentsUrl, filterPaymentsModel);
    }

    getBookingPaymentsCsv(bookingPaymentsRequest: BookingPaymentsFilterApiModel) {
        return this.http.getBlob(this.getBookingPaymentsCsvUrl, bookingPaymentsRequest);
    }
}
