<app-header></app-header>

<div class="login-wrapper flex">
    <div *ngIf="loginTypePopup" class="overlay flex">
        <div class="custom-box popup">
            <h2>Activation</h2>

            <button type="button" routerLink="/dashboard" class="custom-button blue left">Continue trial</button>
            <button type="button" (click)="activateAccount()" class="custom-button activate right">
                Activate account
            </button>
        </div>
    </div>
    <div class="loader" *ngIf="loader"></div>
</div>
