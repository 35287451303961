<div class="billing-wrapper flex">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <h2>Manage invoices</h2>
        <div class="search-holder">
            <div class="form-item">
                <div class="custom-label">Departure</div>
                <dx-date-box
                    [(value)]="departureDate"
                    type="date"
                    [displayFormat]="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                >
                </dx-date-box>
            </div>
            <div class="form-item">
                <div class="custom-label">Search</div>
                <input class="custom-input" type="text" name="searchName" [(ngModel)]="searchValue" />
            </div>
            <div class="form-item">
                <div class="custom-label empty-label"></div>
                <button type="button" class="custom-button blue" (click)="searchBookings()">Search</button>
            </div>
        </div>

        <dx-data-grid [hoverStateEnabled]="false" class="promo-codes-grid" (onRowClick)="rowClickEvent($event)">
            <div *dxTemplate="let d of 'bookingNo'" (click)="goToBookingRef($event, d.data)" style="cursor: pointer">
                <i [ngClass]="[theme == 2 ? 'dark-theme' : '']" class="fa-solid fa-link fa-icon fa-icon-xsmall blue link-icon"></i> {{ d.data.bookingNo }}
            </div>
            <div *dxTemplate="let d of 'balance'">
                {{ currencyContext.Symbol }}{{ d.data.balance | number: '1.2-2' }}
            </div>
            <div *dxTemplate="let d of 'partnerBooking'">
                <ng-container *ngIf="d.data.partnerBooking"
                    ><i
                        id="briefcase-{{ d.data?.id }}"
                        class="fas fa-briefcase briefcase"
                        (mouseenter)="dxhoverstart(d.data, false)"
                        (mouseleave)="dxhoverend(d.data)"
                    ></i
                ></ng-container>
            </div>
            <div *dxTemplate="let d of 'guesthasaddress'">
                <p *ngIf="noAdress && !d.data.guestHasAddress"><i class="fas fa-exclamation"></i></p>
                <i
                    id="response-{{ d.data?.id }}"
                    *ngIf="!d.data?.guestHasAddress"
                    (mouseenter)="dxhoverstart(d.data, true)"
                    (mouseleave)="dxhoverend(d.data)"
                    class="fa fa-info-circle info-circle"
                ></i>
            </div>
            <div *dxTemplate="let e of 'extras'">
                <ng-container *ngIf="e.data.hasExtras"><i class="fa fa-check"></i></ng-container>
                <ng-container *ngIf="!e.data.hasExtras"><i class="fa fa-times"></i></ng-container>
            </div>
            <div *dxTemplate="let e of 'uninvoiced'">
                <ng-container *ngIf="e.data.hasUninvoicedItems"><i class="fa fa-check"></i></ng-container>
                <ng-container *ngIf="!e.data.hasUninvoicedItems"><i class="fa fa-times"></i></ng-container>
            </div>

            <dxo-selection
                *ngIf="permissionsModel.formButtonEnabled"
                showCheckBoxesMode="always"
                mode="multiple"
            ></dxo-selection>
        </dx-data-grid>
        <dx-tooltip
            target="{{ tooltipTarget }}"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            position="top"
            [width]="200"
            [(visible)]="messageResponse"
        >
            <dxo-animation>
                <dxo-show type="pop" [from]="{ scale: 0 }" [to]="{ scale: 1 }"> </dxo-show>
                <dxo-hide type="fade" [from]="1" [to]="0"> </dxo-hide>
            </dxo-animation>

            <div *dxTemplate="let data of 'content'">
                <div class="tooltip-msg">{{ invoiceMessage }}</div>
            </div>
        </dx-tooltip>
        <div class="bottom-holder">
            <button
                type="button"
                class="custom-button orange"
                (click)="batchCreateInvoices()"
                *ngIf="permissionsModel.create"
            >
                Create invoices
            </button>
            <button
                type="button"
                class="custom-button orange"
                (click)="openInvoiceListPopup()"
                *ngIf="permissionsModel.exportPrintEmailEnabled"
            >
                Print/Email invoices
            </button>
        </div>
    </div>
</div>
<app-settings-billing-invoice-list
    *ngIf="invoiceListPopup"
    (closePopupEvent)="closePopup()"
    [invoices]="invoices"
></app-settings-billing-invoice-list>
