import { BookingGuestModel } from '../booking/booking-details.model';
import { CountryModel } from '../account/country.model';
import { EnumModel } from '../shared/enum-model';
import { WidgetColorSchemeModel } from '../booking/widget-color-scheme.model';
import { RegistrationFormModel } from '../templates/registration-form.model';
import { BookingExtraDetailModel } from '../booking/booking-all-details.model';

export class GrouppedBookingRoomGuestRegistration {
    public bookingRoomId: number;
    public roomTypeName: string;
    public bookingFrom: Date;
    public bookingTo: Date;
    public bookingGuests: Array<BookingGuestModel>;
    public roomName?: String;
    public roomFullName?: String;
}

export class BookingGuestRegistrationPDModel {
    public bookingInfo: BookingInfoGuestRegistrationModel;
    public propertyInfo: GuestRegistrationPropertyInfoModel;
    public registrationForm: RegistrationFormModel;
    public colors: WidgetColorSchemeModel;
    public countries: CountryModel[] = [];
    public titles: EnumModel[] = [];
    public propertyLogo: string;
    public canSendSMS: boolean;

    constructor() {
        this.bookingInfo = new BookingInfoGuestRegistrationModel();
        this.propertyInfo = new GuestRegistrationPropertyInfoModel();
        this.registrationForm = new RegistrationFormModel();
        this.colors = new WidgetColorSchemeModel();
    }
}

export class BookingInfoGuestRegistrationModel {
    public id: number;
    public propertyId: number;
    public bookingNo: number;
    public booker: string;
    public status: string;
    public source: string;
    public arrival: Date;
    public departure: Date;
    public people: number;
    public total: number;
    public balance: number;
    public groupedBookingRooms: GrouppedBookingRoomGuestRegistration[] = [];
    public bookingRooms: BookingRoomGuestRegistrationModel[] = [];
    public bookingExtras: BookingExtraDetailModel[] = [];
    public bookingSources: Array<BookingSourcesModel>;
    public bookingSourceId: number;
    public guestAccepted: boolean;
}

export class GuestRegistrationPropertyInfoModel {
    public name: string;
    public address: string;
    public address2: string;
    public address3: string;
    public postalCode: string;
    public phone: string;
    public email: string;
}

export class BookingRoomGuestRegistrationModel {
    public roomType: string;
    public ratePlan: string;
    public guests: number;
    public bookingFrom: Date;
    public bookingTo: Date;
    public price: number;
    public status: string;
    public roomFullName: string;
    public roomShortName: string;
    public count?: number;
}

export class BookingSourcesModel {
    public id: number;
    public name: string;
}
