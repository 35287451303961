import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RoomTypesGridModel } from '../../../../models/room-type/room-types.model';
import { KeyValuePair } from '../../../../models/shared/key-value-pair.model';
import { SignupService } from '../../signup.service';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { RoomType } from 'src/app/models/room-type/room-type-details.model';

@Component({
    selector: 'app-room-type-order',
    templateUrl: './room-type-order.component.html',
    styleUrls: ['./room-type-order.component.less'],
})
export class RoomTypeOrderComponent implements OnInit {
    @Input() roomTypeOrder: boolean;
    @Output() roomTypeOrderChange = new EventEmitter<boolean>();
    @Input() roomTypesModel: RoomTypesGridModel;
    @Output() update = new EventEmitter();

    copyOfRoomTypes = new RoomTypesGridModel();
    listOfReorderedRoomTypes: KeyValuePair[] = [];
    changeOrderLoader = false;
    theme: number;

    constructor(private signupService: SignupService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.onReorder = this.onReorder.bind(this);
    }

    ngOnInit() {
        this.copyOfRoomTypes = JSON.parse(JSON.stringify(this.roomTypesModel));
    }

    onReorder(e) {
        const visibleRows = e.component.getVisibleRows();
    
        const toIndex = this.copyOfRoomTypes.roomTypes.findIndex((item) => item.id === visibleRows[e.toIndex].data.id);
        const fromIndex = this.copyOfRoomTypes.roomTypes.findIndex((item) => item.id === e.itemData.id);

        this.copyOfRoomTypes.roomTypes.splice(fromIndex, 1);
        this.copyOfRoomTypes.roomTypes.splice(toIndex, 0, e.itemData);
    
        for (let i=1; i<= this.copyOfRoomTypes.roomTypes.length; i++) {
          this.copyOfRoomTypes.roomTypes[i-1].displayOrder = i;
        }
      }

      calculateConfiguredRooms(rowData: RoomType) {
        let configuredRooms = '';
        if (rowData != null && rowData.roomsIdName != null)
        {
            for (let i =0; i< rowData.roomsIdName.length; i++) {
                configuredRooms = configuredRooms + rowData.roomsIdName[i].value;
                if (i != rowData.roomsIdName.length -1) {
                    configuredRooms = configuredRooms + ', ';
                }
            }
        }

        return configuredRooms;
      }

      calculateTotal(rowData: RoomType) { 
        return rowData.roomsIdName?.length;
      }

    submitOrderRoomType() {
        this.changeOrderLoader = true;
        let i = 0;

        for (const roomType of this.copyOfRoomTypes.roomTypes) {
            i++;
            this.listOfReorderedRoomTypes.push(new KeyValuePair(roomType.id, i));
        }

        this.signupService.reorderRoomTypes(this.listOfReorderedRoomTypes).subscribe(
            (response) => {
                this.changeOrderLoader = false;
                this.update.emit();
                this.close();
            },
            (error) => {
                this.changeOrderLoader = false;
            }
        );
    }

    close() {
        this.roomTypeOrderChange.emit(false);
    }
}
