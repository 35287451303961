import { GridConfiguration, GridRequest } from '../../shared/helpers/dx-grid-helper';

export class BookingBillingConfigurationModel extends GridConfiguration {
    constructor() {
        super();
        this.columns = [
            {
                dataField: 'bookingNo',
                caption: 'Booking Ref #',
                // width: '70',
                cellTemplate: 'bookingNo',
                width: 120,
            },
            {
                dataField: 'balance',
                caption: 'Balance',
                allowSorting: false,
                cellTemplate: 'balance',
                alignment: 'left',
                width: 120,
            },
            {
                dataField: 'partnerBooking',
                caption: '',
                allowSorting: false,
                cellTemplate: 'partnerBooking',
                width: 30,
            },
            {
                dataField: 'guestHasAddress',
                caption: '',
                allowSorting: false,
                cellTemplate: 'guesthasaddress',
                width: 30,
            },
            {
                dataField: 'guest',
                caption: 'Guest',
                allowSorting: false,
            },

            {
                dataField: 'hasExtras',
                caption: 'Has Extras',
                allowSorting: false,
                cellTemplate: 'extras',
            },
            {
                dataField: 'hasUninvoicedItems',
                caption: 'Has Uninvoiced Items',
                allowSorting: false,
                cellTemplate: 'uninvoiced',
            },
        ];

        this.customSearch = [
            {
                fieldName: 'searchString',
                value: '',
            },
        ];

        this.selection = {
            mode: 'multiple',
            // showCheckBoxesMode : 'always',
            allowSelectAll: true,
            selectAllMode: 'page',
        };
    }

    mapGridRequest(gridRequest: GridRequest, loadOptions: any) {
        super.mapGridRequest(gridRequest, loadOptions);
        gridRequest.options.customSearch = this.customSearch;
    }
}
