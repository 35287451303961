import { KeyValuePair } from '../shared/key-value-pair.model';
import { EnumModel } from '../shared/enum-model';
import { PropertyVatBaseModel } from '../property/property-vat-base.model';
import { CostCenterModel } from '../cost-center/cost-center.model';

export class PropertyFunctionRoomsModel {
    public functionRooms: PropertyFunctionRoomModel[] = [];
}

export class PropertyFunctionRoomModel {
    public id: number;
    public propertyId: number;
    public name: string;
    public shortName: string;
    public description: string;
    public maxAttendance: number;
    public dateCreated: Date;
    public status: number;
    public displayOrder: number;
    public basePrice?: number;
    public vatId: number;
    public costCenterId: number;
    public bookableDays: PropertyFunctionRoomBookableDayModel[] = [];
    public cateringOptions: PropertyFunctionRoomCateringOptionModel[] = [];
    public configurations: PropertyFunctionRoomConfigurationModel[] = [];
    public sessions: PropertyFunctionRoomSessionModel[] = [];

    pageData: PageData;

    constructor() {
        this.status = 0;
        this.pageData = new PageData();
        this.description = "";
    }
}

export class PropertyFunctionRoomBookableDayModel {
    public propertyFunctionRoomId: number;
    public dayOfWeekId: number;
    public id: number;
}

export class PropertyFunctionRoomCateringOptionModel {
    public propertyFunctionRoomId: number;
    public cateringOptionId: number;
    public id: number;
}

export class PropertyFunctionRoomConfigurationModel {
    public propertyFunctionRoomId: number;
    public configurationId: number;
    public id: number;
}

export class PropertyFunctionRoomSessionModel {
    public propertyFunctionRoomId: number;
    public sessionId: number;
    public id: number;
}

export class Status {
    value: string;
    id: string;
    name: string;
}


export class PageData {
    status: Status[] = [];
    bookableDays: EnumModel[] = [];
    cateringOptions: EnumModel[] = [];
    sessions: EnumModel[] = [];
    configurations: EnumModel[] = [];
    propertyVATs: PropertyVatBaseModel[]=[];
    costCentres: CostCenterModel[]=[];
}