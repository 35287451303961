import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthTokenService } from '../../shared/services/auth-token.service';
import { UserStatus } from '../../enums/user.enum';
import { AccountStatus } from '../../enums/account.enum';
import { DataTransferService } from '../../shared/services/data-transfer.service';
import CookieHelper from 'src/app/shared/helpers/cookie-helper';

@Component({
    selector: 'app-admin-login',
    templateUrl: './admin-login.component.html',
    styleUrls: ['./admin-login.component.less'],
})
export class AdminLoginComponent implements OnInit {
    loginTypePopup = false;
    loader = true;

    token: string;
    userId: number;

    constructor(private authLoginService: AuthTokenService, private router: Router, private route: ActivatedRoute) {
        this.route.params.subscribe((params: Params) => {

            this.token = CookieHelper.getCookie('property-token', false);
            this.userId = params['userId'];;
            CookieHelper.deleteCookie('property-token');

            this.authLoginService.loginAdminAsEndUser(this.userId, this.token).subscribe(
                (response) => {
                    this.loader = false;
                    if (AuthTokenService.decodeJwtToken().User.Status === UserStatus.Incomplete) {
                        this.router.navigate(['room-number']);
                    } else {
                        const accountStatus = AuthTokenService.decodeJwtToken().Account.Status;
                        if (accountStatus === AccountStatus.Live || accountStatus === AccountStatus.Development) {
                            this.router.navigate(['dashboard']);
                        } else {
                            this.loginTypePopup = true;
                        }
                    }
                    this.token = null;
                    this.userId = null;
                },
                (error) => {
                    console.log(error);
                    this.loader = false;
                    this.router.navigate(['login']);
                }
            );
        });
    }

    ngOnInit() {}

    activateAccount() {
        DataTransferService.PushData('ShowTrialMessage', false);
        this.router.navigate(['account-details']);
    }
}
