<div class="auto-logout-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup cost-fees" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Alert</h2>
            <p>{{ message }}</p>
            <p>Do you want to continue?</p>
            <div class="actions actions-button flex">
                <button type="button" (click)="confirm()" class="custom-button blue">Ok</button>
                <button type="button" (click)="close()" class="custom-button red">Cancel</button>
            </div>
        </div>
    </div>
</div>
