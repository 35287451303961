import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RoomTypesGridModel } from '../../../../models/room-type/room-types.model';
import { KeyValuePair } from '../../../../models/shared/key-value-pair.model';
import { FunctionRoomsService } from '../function-rooms.service';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { PropertyFunctionRoomsModel } from 'src/app/models/function-room/function-room.model';

@Component({
  selector: 'app-function-room-order',
  templateUrl: './function-room-order.component.html',
  styleUrls: ['./function-room-order.component.less']
})
export class FunctionRoomOrderComponent implements OnInit {
  @Input() functionRoomOrder: boolean;
  @Output() functionRoomOrderChange = new EventEmitter<boolean>();
  @Input() functionRoomsModel: PropertyFunctionRoomsModel;
  @Output() update = new EventEmitter();

  copyOfFunctionRooms = new PropertyFunctionRoomsModel();
  copyOfFunctionRoomsSet: boolean = false;
  listOfReorderedFunctionRooms: KeyValuePair[] = [];
  changeOrderLoader = false;
  theme: number;

  constructor(private functionRoomsService: FunctionRoomsService) { 
    this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    this.onReorder = this.onReorder.bind(this);
  }

  ngOnInit(): void {
    this.copyOfFunctionRooms = JSON.parse(JSON.stringify(this.functionRoomsModel));
    this.copyOfFunctionRoomsSet = true;
  }

  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();

    const toIndex = this.copyOfFunctionRooms.functionRooms.findIndex((item) => item.id === visibleRows[e.toIndex].data.id);
    const fromIndex = this.copyOfFunctionRooms.functionRooms.findIndex((item) => item.id === e.itemData.id);

    this.copyOfFunctionRooms.functionRooms.splice(fromIndex, 1);
    this.copyOfFunctionRooms.functionRooms.splice(toIndex, 0, e.itemData);

    for (let i=1; i<= this.copyOfFunctionRooms.functionRooms.length; i++) {
      this.copyOfFunctionRooms.functionRooms[i-1].displayOrder = i;
    }
  }

  submitOrderRoomType() {
    this.changeOrderLoader = true;
    let i = 0;

    for (const functionRoom of this.copyOfFunctionRooms.functionRooms) {
        i++;
        this.listOfReorderedFunctionRooms.push(new KeyValuePair(functionRoom.id, i));
    }

    this.functionRoomsService.reorderFunctionRooms(this.listOfReorderedFunctionRooms).subscribe(
        (response) => {
            this.changeOrderLoader = false;
            this.update.emit();
            this.close();
        },
        (error) => {
            this.changeOrderLoader = false;
        }
    );
  }

  close() {
    this.functionRoomOrderChange.emit(false);
}

}
