import { Component, OnDestroy, OnInit } from '@angular/core';
import { IncidentalSalesService } from './incidental-sales.service';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import {
    CategoriesWithSaleItemsGridModel,
    SaleItemGridModel,
} from '../../../models/cost-center/categories-with-sale-items.model';
import { SaleItemCategoryModel } from '../../../models/cost-center/sale-item-category.model';
import { SaleItemModel } from '../../../models/cost-center/sale-item.model';
import { ExtrasPermissionsModel } from '../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../enums/permissions.enum';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';

@Component({
    selector: 'app-settings-incidental-sales',
    templateUrl: './settings-incidental-sales.component.html',
    styleUrls: ['./settings-incidental-sales.component.less'],
})
export class SettingsIncidentalSalesComponent implements OnInit, OnDestroy {
    addItem = false;
    editItem = false;
    addCategory = false;
    editCategory = false;

    saleItemId: number;
    editSaleItemCategory: String;

    deletePopup = false;
    deletePopupCategory = false;

    theme: number;

    permissionsModel: ExtrasPermissionsModel;

    subscription: any;
    categoriesWithSaleItemsModel: CategoriesWithSaleItemsGridModel[];
    saleItemCategoryModel = new SaleItemCategoryModel();
    saleItemModel = new SaleItemModel();

    constructor(
        private incidentalSalesService: IncidentalSalesService,
        private toasterService: ToastService,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = permissionsService.createPermissionsModel(PermissionsEnum.Extras);
    }

    ngOnInit() {
        this.init();
    }

    init() {
        this.subscription = this.incidentalSalesService
            .getSaleItemsWithCategories(AuthTokenService.decodeJwtToken().Property.Id)
            .subscribe(
                (response) => {
                    this.categoriesWithSaleItemsModel = response;
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    openAddSaleItem() {
        this.addItem = true;
    }

    openAddSaleCategory() {
        this.addCategory = true;
    }

    openEditSaleItem(id) {
        this.saleItemId = id;
        this.editSaleItemCategory = this.categoriesWithSaleItemsModel.find(x => x.saleItems.some(y => y.id == id)).name;
        this.editItem = true;
    }

    openEditSaleCategory(data: SaleItemCategoryModel) {
        this.saleItemCategoryModel = data;
        this.editCategory = true;
    }

    openDeleteSaleItem() {
        // this.saleItemModel = data;
        this.incidentalSalesService.deleteSaleItem(this.saleItemModel).subscribe(
            (response) => {
                this.init();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    openDeleteSaleItemCategory() {
        this.incidentalSalesService.deleteSaleItemCategory(this.saleItemCategoryModel).subscribe(
            (response) => {
                this.init();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    deleteModal(data) {
        // if (data.name.toLowerCase().indexOf('epos') > -1) {
        //   const toast: Toast = {
        //     type: 'warning',
        //     title: 'Warning',
        //     body: 'Cannot delete EPOS extra',
        //     showCloseButton: true,
        //     timeout: 5000
        //   };
        //   this.toasterService.pop(toast);
        //   return;
        // }

        this.deletePopup = true;
        this.saleItemModel = data;
    }

    deleteModalCategory(data) {
        this.deletePopupCategory = true;
        this.saleItemCategoryModel = data;
    }

    close() {
        this.deletePopup = false;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
