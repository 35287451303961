import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpRequestService } from '../../shared/services/http-request.service';
import { AvailabilityRequestModel } from '../../models/booking/availability-request.model';
import { UpdatePartnerAvailabilityModel } from '../../models/booking/update-partner-availability.model';
import { UpdateAvailabilityModel } from '../../models/booking/update-availability.model';

@Injectable()
export class InventoryService {
    private getAvailabilityUrl = environment.apiUrl + '/Booking/GetAvailability';
    private getFunctionAvailabilityUrl = environment.apiUrl + '/Function/GetAvailability';
    private getPartnerAvailabilityPopupUrl = environment.apiUrl + '/Booking/GetPartnerAvailabilityPopup';
    private updatePartnerAvailabilityUrl = environment.apiUrl + '/Booking/UpdatePartnerAvailability';
    private getAvailabilityPopupUrl = environment.apiUrl + '/Booking/GetAvailabilityPopupUpdate';
    private getFunctionAvailabilityPopupUrl = environment.apiUrl + '/Function/GetAvailabilityPopupUpdate';
    private updateAvailabilityUrl = environment.apiUrl + '/Booking/UpdateAvailability';
    private updateFunctionAvailabilityUrl = environment.apiUrl + '/Function/UpdateAvailability';
    private getActiveSchedulesUrl = environment.apiUrl + '/RateSchedule/GetActiveSchedules';

    constructor(private http: HttpRequestService) {}

    getAvailability(model: AvailabilityRequestModel) {
        return this.http.post(this.getAvailabilityUrl, model);
    }

    getFunctionAvailability(model: AvailabilityRequestModel) {
        return this.http.post(this.getFunctionAvailabilityUrl, model);
    }

    getActiveSchedules() {
        return this.http.get(this.getActiveSchedulesUrl);
    }

    getPartnerAvailabilityPopup(propertyId: number) {
        return this.http.get(this.getPartnerAvailabilityPopupUrl + '?propertyId=' + propertyId);
    }

    updatePartnerAvailability(model: UpdatePartnerAvailabilityModel) {
        return this.http.post(this.updatePartnerAvailabilityUrl, model, true);
    }

    getAvailabilityPopup(propertyId: number, roomTypeId: number, fromTo: string, rateScheduleId: number) {
        return this.http.get(
            this.getAvailabilityPopupUrl +
                '?propertyId=' +
                propertyId +
                '&roomTypeId=' +
                roomTypeId +
                '&fromTo=' +
                fromTo +
                '&rateScheduleId=' +
                rateScheduleId
        );
    }

    getFunctionAvailabilityPopup(propertyId: number, propertyFunctionRoomId: number, fromTo: string) {
        return this.http.get(
            this.getFunctionAvailabilityPopupUrl +
                '?propertyId=' +
                propertyId +
                '&propertyFunctionRoomId=' +
                propertyFunctionRoomId +
                '&fromTo=' +
                fromTo
        );
    }

    updateAvailability(model: UpdateAvailabilityModel) {
        return this.http.post(this.updateAvailabilityUrl, model, true);
    }

    updateFunctionAvailability(model: UpdateAvailabilityModel) {
        return this.http.post(this.updateFunctionAvailabilityUrl, model, true);
    }
}
