import { Pipe, PipeTransform } from '@angular/core';
import { RoomTypeAvailabilityGridModel } from '../../models/booking/availability-grid.model';

@Pipe({
    name: 'searchPipe',
    pure: false,
})
export class SearchRoomTypePipe implements PipeTransform {
    transform(data: RoomTypeAvailabilityGridModel[], searchTerm: string): any[] {
        if (searchTerm) {
            searchTerm = searchTerm.toUpperCase();
            return data.filter((item) => {
                return item.roomTypeShortName.toUpperCase().indexOf(searchTerm) !== -1;
            });
        } else {
            return data;
        }
    }
}
