import { PricePlanModel } from './price-plan.model';

export class PricePlanRangeBaseModel {
    public ratePlanId: number;
    public roomTypeId: number;
    public minLos: number;
    public dateFrom: string;
    public dateTo: string;
    public pricePlan: PricePlanModel;
}
