import { RoomTypeRoomModel } from './room-types.model';
import { EnumModel } from '../shared/enum-model';

export class RoomTypeRoomDetailsModel {
    public pageData: RoomTypeRoomDetailsPDModel;
    public roomTypeRoom: RoomTypeRoomModel;

    constructor() {
        this.pageData = new RoomTypeRoomDetailsPDModel();
        this.roomTypeRoom = new RoomTypeRoomModel();
    }
}

export class RoomTypeRoomDetailsPDModel {
    public status: EnumModel[];
}
