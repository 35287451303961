<!--<app-header *ngIf="router.url.indexOf('booking') < 1"></app-header>-->

<div class="room-type-wrapper flex">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <h2>Room type editor</h2>
        <div *ngIf="permissionsModel.formButtonEnabled">
            <button type="button" (click)="openRoomTypeOrder()" class="change-order">
                <i class="fa fa-sort" aria-hidden="true"></i>Change order
            </button>
        </div>
        <dx-data-grid
            *ngIf="roomTypesModel?.roomTypes"
            [dataSource]="roomTypesModel.roomTypes"
            class="room-type-grid"
            (onCellClick)="cellClick($event)"
            [columns]="[
                {
                    dataField: 'displayOrder',
                    cellTemplate: 'displayOrder',
                    caption: 'Order',
                    allowSorting: true,
                    width: '60'
                },
                { dataField: 'name', cellTemplate: 'name', caption: 'Name', allowSorting: true },
                { dataField: 'shortName', caption: 'Short name', allowSorting: true, width: '110' },
                { dataField: 'id', cellTemplate: 'id', caption: 'Id', allowSorting: true, width: '60' },
                { cellTemplate: 'configuredRooms', caption: 'Configured Rooms', allowSorting: false },
                { cellTemplate: 'total', caption: 'Total', allowSorting: false, width: '50' },
                { dataField: 'description', caption: 'Description', cellTemplate: 'description', allowSorting: true },
                { cellTemplate: 'delete', width: '60' }
            ]"
        >
            <div *dxTemplate="let t of 'configuredRooms'">
                <ng-container *ngFor="let item of t.data.roomsIdName"> {{ item.value }} </ng-container>
            </div>
            <div class="center-td" *dxTemplate="let n of 'displayOrder'">
                {{ n.data.displayOrder }}
            </div>
            <div class="name" *dxTemplate="let n of 'name'">
                <i [ngClass]="[theme == 2 ? 'dark-theme' : '']" class="fa-solid fa-link fa-icon fa-icon-xsmall blue link-icon"></i>{{ n.data.name }}
            </div>
            <div class="left-td" *dxTemplate="let n of 'id'">
                {{ n.data.id }}
            </div>
            <div class="desc" *dxTemplate="let d of 'description'">
                {{ this.cleanHtml(d.data.description) }}
            </div>
            <div class="center-td" *dxTemplate="let t of 'total'">
                {{ t.data.roomsIdName?.length }}
            </div>
            <div *dxTemplate="let a of 'images'">
                <i title="Images" class="fas fa-image fa-icon gray fa-icon-small" (click)="openImagePopup(a.data)"></i>
            </div>
            <div *dxTemplate="let a of 'delete'">
                <i *ngIf="permissionsModel.delete" title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small" (click)="deleteModal(a.data.id)"></i>
            </div>
            <dxo-scrolling mode="standard"></dxo-scrolling>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <!--<dxo-pager-->
            <!--[showNavigationButtons]="true"-->
            <!--[showPageSizeSelector]="true"-->
            <!--[allowedPageSizes]="[10, 20]"-->
            <!--[showInfo]="true">-->
            <!--</dxo-pager>-->
        </dx-data-grid>

        <div *ngIf="permissionsModel.create" (click)="openRoomTypePopup()" class="add-new-box">
            <span class="text">Add New</span>
            <div class="img">
                <i class="fa-solid fa-plus fa-icon gray"></i>
            </div>
        </div>

        <button
            *ngIf="permissionsModel.formButtonEnabled"
            type="button"
            (click)="navigateToRooms()"
            class="custom-button blue"
        >
            Assign Rooms to Types
        </button>
    </div>
</div>

<app-delete-modal
    *ngIf="deletePopup"
    [(deleteModal)]="deletePopup"
    [message]="'Are you sure you want to delete this room type?'"
    (delete)="deleteRoomType()"
></app-delete-modal>
<app-room-type-order
    *ngIf="roomTypeOrder"
    [(roomTypeOrder)]="roomTypeOrder"
    [roomTypesModel]="roomTypesModel"
    (update)="updateData()"
></app-room-type-order>
<app-room-type-add
    *ngIf="addRoomTypePopup"
    [(addRoomType)]="addRoomTypePopup"
    [displayOrderList]="displayOrderList"
    (update)="updateData()"
></app-room-type-add>
<app-room-type-edit
    *ngIf="editRoomTypePopup"
    [hasOrder]="true"
    [idOfRoomType]="idOfRoomType"
    [displayOrderList]="displayOrderList"
    [(editRoomType)]="editRoomTypePopup"
    (update)="updateData()"
></app-room-type-edit>
<app-room-type-images
    *ngIf="imageRoomTypePopup"
    [roomTypeName]="roomTypeName"
    [idOfRoomType]="idOfRoomType"
    [(imageRoomTypePopup)]="imageRoomTypePopup"
></app-room-type-images>
