<div class="success-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Direct Debit Guarantee</h2>

            <div class="form-item">
                <p>
                    The Guarantee is offered by all banks and building societies that accept instructions to pay Direct
                    Debits.
                </p>
                <p>
                    If there are any changes to the amount, date or frequency of your Direct Debit Hamilton Enterpris
                    will notify you (normally 3 working days) in advance of your account being debited or as otherwise
                    agreed. If you request Hamilton Enterpris to collect a payment, confirmation of the amount and date
                    will be given to you at the time of the request.
                </p>
                <p>
                    If an error is made in the payment of your Direct Debit, by Hamilton Enterpris or your bank or
                    building society, you are entitled to a full and immediate refund of the amount paid from your bank
                    or building society.
                </p>
                <p>
                    If you receive a refund you are not entitled to, you must pay it back when Hamilton Enterpris asks
                    you to.
                </p>
                <p>
                    You can cancel a Direct Debit at any time by simply contacting your bank or building society.
                    Written confirmation may be required. Please also notify Hamilton Enterpris.
                </p>
            </div>

            <button type="button" (click)="close()" class="custom-button blue">Done</button>
        </div>
    </div>
</div>
