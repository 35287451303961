<app-header></app-header>

<div class="login-wrapper flex">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <h2>Activation</h2>

        <p *ngIf="showTrialMessage">Your trial is now over, you need to activate your account to continue.</p>

        <p>Your current billing status would be as follows:</p>
        <ul>
            <li *ngFor="let item of activationFlowModel.billingInfo.itemsView">
                {{ item.itemDescription }}: {{ currentCurrency.symbol }}{{ item.itemValue }}
            </li>
        </ul>

        <p *ngIf="activationFlowModel.billingInfo.excludedItems.length > 0">Do you wish to add:</p>
        <ul>
            <li *ngFor="let item of activationFlowModel.billingInfo.excludedItems; let i = index">
                <div class="add-item">
                    {{ item.itemDescription }} for {{ currentCurrency.symbol }}{{ item.itemValue }}?
                </div>
                <input
                    class="tgl tgl-flip"
                    id="toogle{{ i }}"
                    [checked]="item.checked"
                    type="checkbox"
                    (change)="calculateAmount(item, $event)"
                />
                <label class="tgl-btn" data-tg-off="No" data-tg-on="Yes" for="toogle{{ i }}"></label>
            </li>
        </ul>
        <ul>
            <li>
                <div class="add-item">
                    {{ activationFlowModel.billingInfo.caterpayPlan.itemName }} for {{ currentCurrency.symbol
                    }}{{ activationFlowModel.billingInfo.caterpayPlan.itemValue }} (additional tx
                    {{ currentCurrency.symbol
                    }}{{ activationFlowModel.billingInfo.caterpayPlan.additionalItemAmount }})?
                </div>
                <input
                    class="tgl tgl-flip"
                    id="caterPlan"
                    [checked]="activationFlowModel.billingInfo.caterpayPlan.checked"
                    type="checkbox"
                    (change)="calculateAmount(activationFlowModel.billingInfo.caterpayPlan, $event)"
                />
                <label class="tgl-btn" data-tg-off="No" data-tg-on="Yes" for="caterPlan"></label>
            </li>
        </ul>
        <ul>
            <li>
                <div class="add-item">
                    {{ activationFlowModel.billingInfo.smsPlan.itemName }} for {{ currentCurrency.symbol
                    }}{{ activationFlowModel.billingInfo.smsPlan.itemValue }} (additional sms {{ currentCurrency.symbol
                    }}{{ activationFlowModel.billingInfo.smsPlan.additionalItemAmount }})?
                </div>
                <input
                    class="tgl tgl-flip"
                    id="smsPlan"
                    [checked]="activationFlowModel.billingInfo.smsPlan.checked"
                    type="checkbox"
                    (change)="calculateAmount(activationFlowModel.billingInfo.smsPlan, $event)"
                />
                <label class="tgl-btn" data-tg-off="No" data-tg-on="Yes" for="smsPlan"></label>
            </li>
        </ul>

        <p class="strong margin-top">
            Your monthly bill will be
            <span>{{ currentCurrency.symbol }}{{ activationFlowModel.billingInfo.total }} + VAT</span> per month.
        </p>

        <button type="button" (click)="activate(activationFlowModel)" class="custom-button blue">Activate</button>
        <button type="button" (click)="cancel()" class="custom-button red">Cancel</button>

        <p>If you have any questions, call us on 01840 298298</p>
    </div>

    <div *ngIf="showActivatedSuccesMessage" class="activation-wrapper flex">
        <div class="overlay flex">
            <div class="custom-box popup messageLogLink" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
                <h3>Thank you! One of our staff will contact you to discuss the next steps.</h3>
                <div class="buttons-wrapper continue">
                    <button type="button" class="custom-button orange center" (click)="goToDashboard()">
                        Continue
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
