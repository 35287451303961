import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthTokenService } from 'src/app/shared/services/auth-token.service';
import { RatePlanService } from '../../rate-plan.service';
import { EnumModel } from 'src/app/models/shared/enum-model';
import { RatePlanEPOSAllowanceDetailsModel, RatePlanEPOSAllowanceItemModel } from 'src/app/models/rate-plan/rate-plan-epos-allowance.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import * as _ from 'lodash';
import { InventoryRoomTypesPermissionsModel } from 'src/app/models/permissions/permissions.model';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { PermissionsEnum } from 'src/app/enums/permissions.enum';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';

@Component({
  selector: 'app-epos-allowance-popup',
  templateUrl: './epos-allowance-popup.component.html',
  styleUrls: ['./epos-allowance-popup.component.less']
})
export class EposAllowancePopupComponent implements OnInit {
  @Input() eposAllowance: boolean;
  @Output() eposAllowanceChange = new EventEmitter<boolean>();
  @Input() ratePlanId: number;
  @Input() ratePlanName: number;
  @Input() ratePlanEposAllowanceType: string;

  permissionsModel: InventoryRoomTypesPermissionsModel;

  errorMessage = '';
  theme: number;
  valForm: UntypedFormGroup;
  loader = false;

  allowanceModel: RatePlanEPOSAllowanceDetailsModel;
  filteredAllowanceItems: RatePlanEPOSAllowanceItemModel[] = [];
  selectedItems: RatePlanEPOSAllowanceItemModel[] = [];

  constructor(private ratePlanService: RatePlanService, private toasterService: ToastService, private permissionsService: PermissionsService, private fb: UntypedFormBuilder,) {
    this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryRoomTypes);
    this.allowanceModel = new RatePlanEPOSAllowanceDetailsModel();

    this.valForm = this.fb.group({
      type: [null, Validators.required],
      chargeType: [null, Validators.required],
      amount: [null, Validators.compose([Validators.required, Validators.min(1), Validators.max(1000)])],
      

    });
  }

  ngOnInit(): void {
    this.loadAllowanceModel();
  }

  loadAllowanceModel() {
    this.ratePlanService.getRatePlanEPOSAllowance(this.ratePlanId).subscribe(
      (response) => {
          this.allowanceModel = response;

          this.selectedItems = this.allowanceModel.allowanceItems.filter(
            (x) => x.selected == true
          );
          this.filteredAllowanceItems = this.allowanceModel.allowanceItems.filter(
            (x) => x.eposAllowanceType == this.allowanceModel.eposAllowanceType
          );
          this.valForm.get('type').setValue(this.allowanceModel.eposAllowanceType);
          this.valForm.get('chargeType').setValue(this.allowanceModel.eposAllowanceChargeType);

          this.loader = false;
      },
      (error) => {
        this.loader = false;
        console.log(error)
      }
    );
  }
  
  setType(index:number) {
    this.allowanceModel.eposAllowanceType = index == 1 ? "CategoryBased" : "SubCategoryBased";

    this.filteredAllowanceItems = this.allowanceModel.allowanceItems.filter(
      (x) => x.eposAllowanceType == this.allowanceModel.eposAllowanceType
    )
  }

  setChargeType(index:number) {
    switch (index) {
      case 1: 
        this.allowanceModel.eposAllowanceChargeType = "PerRoomPerDay";
        break;
      case 2: 
        this.allowanceModel.eposAllowanceChargeType = "PerPersonPerDay";
        break;
      case 3: 
        this.allowanceModel.eposAllowanceChargeType = "PerRoom";
        break;
    }
  }

  close() {
    this.eposAllowanceChange.emit(false);
  }

  showToastError(message: string, title?: string) {
    this.toasterService.showToast(title ? title : 'Error', message, null, ToastEventType.Error, true, 5000);
  }

  submitEPOSAllowance(e) {
    e.stopPropagation();

    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid) {
      this.loader = true;
      let updatedModel = JSON.parse(JSON.stringify(this.allowanceModel));

      updatedModel.allowanceItems = this.selectedItems;

      this.ratePlanService.saveRatePlanAllowance(updatedModel).subscribe(
        (response) => {
          this.loadAllowanceModel();
        });

    }
    else {
      this.showToastError('Please configure all values for this allowance.', 'Missing items');
    }
  }

}
