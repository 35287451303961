<div class="pricing-plans-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <!--<h2>Dynamic Pricing</h2>-->
            <form [formGroup]="valForm" (ngSubmit)="submitForms($event)">
                <div class="form-box flex">
                    <div class="title">
                        <div class="occupancy-label long">Occupancy rules for {{ ratePlan?.name }}</div>                       
                       
                    </div>
                   
                    <div class="form-item shortest" >
                        <div class="custom-label">Room Type</div>
                        <dx-select-box
                            class="custom-select"
                            [(value)]="roomTypeId"
                            [dataSource]="roomTypes"
                            (onItemClick)="filterByRoomType($event)"
                            displayExpr="roomType.shortName"
                            valueExpr="roomType.id"
                        >
                        </dx-select-box>  
                                            
                    </div>                    
                    <div class="form-item shortest"></div>
                    <div class="form-item shortest"></div>
                    <div class="form-item ">
                        <div class="custom-label">
                            Occupancy = {{this.selectedRoomType != null ? this.selectedRoomType.roomType.maximumOccupancy : '-'}} <br>
                            Max. Children = {{this.selectedRoomType != null ? this.selectedRoomType.roomType.maximumChildren : '-'}} <br>
                            Children Take Availability = {{this.selectedRoomType != null ? this.selectedRoomType.roomType.childrenTakeAvailability : '-'}}
                        </div>
                    </div>
                    <div class="title"></div>
                   
                    <div class="form-item shortest">
                        <div class="custom-label">Adults Included</div>
                        <input
                            class="custom-input"
                            type="number"
                            min="0"
                            name="included"
                            [readonly]="!permissionsModel.formButtonEnabled"
                            formControlName="included"
                            [(ngModel)]="ratePlanRoomTypeModel.baseGuests"
                            (change)="validateOccupancy()"
                            [ngClass]="[warningAdults || warningOccupancy ? 'input-warning' : '']"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['included'].hasError('required') &&
                                (valForm.controls['included'].dirty || valForm.controls['included'].touched)
                            "
                            >Field is required.</span
                        >
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['included'].hasError('min') &&
                                (valForm.controls['included'].dirty || valForm.controls['included'].touched)
                            "
                            >Minimum number is 0.</span
                        >
                    </div>
                    <div class="form-item shortest">
                        <div class="custom-label">Extra Adults</div>
                        <input
                            class="custom-input"
                            type="number"
                            min="0"
                            name="additional"
                            formControlName="additional"
                            [(ngModel)]="ratePlanRoomTypeModel.additionalGuests"
                            [readonly]="!permissionsModel.formButtonEnabled"
                            (change)="validateOccupancy()"
                            [ngClass]="[warningAdults || warningOccupancy ? 'input-warning' : '']"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['additional'].hasError('required') &&
                                (valForm.controls['additional'].dirty || valForm.controls['additional'].touched)
                            "
                            >Field is required.</span
                        >
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['additional'].hasError('min') &&
                                (valForm.controls['additional'].dirty || valForm.controls['additional'].touched)
                            "
                            >Minimum number is 0.</span
                        >
                    </div>
                    <div class="form-item shortest">
                        <div class="custom-label">Extra Children</div>
                        <input
                            class="custom-input"
                            type="number"
                            min="0"
                            name="additional-children"
                            formControlName="additional-children"
                            [(ngModel)]="ratePlanRoomTypeModel.additionalChildren"
                            [readonly]="!permissionsModel.formButtonEnabled"
                            (change)="validateOccupancy()"
                            [ngClass]="[warningChildren || (warningOccupancy && selectedRoomType != null && selectedRoomType.roomType.childrenTakeAvailability) ? 'input-warning' : '']"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['additional-children'].hasError('required') &&
                                (valForm.controls['additional-children'].dirty ||
                                    valForm.controls['additional-children'].touched)
                            "
                            >Field is required.</span
                        >
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['additional-children'].hasError('min') &&
                                (valForm.controls['additional-children'].dirty ||
                                    valForm.controls['additional-children'].touched)
                            "
                            >Minimum number is 0.</span
                        >
                    </div>
                    <div class="form-item shortest">
                        <div class="custom-label">Child Price</div>
                        <input
                            class="custom-input"
                            type="number"
                            min="0"
                            name="children-rate"
                            formControlName="children-rate"
                            [(ngModel)]="ratePlanRoomTypeModel.childrenRate"
                            [readonly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['children-rate'].hasError('min') &&
                                (valForm.controls['children-rate'].dirty || valForm.controls['children-rate'].touched)
                            "
                            >Minimum number is 0.</span
                        >
                    </div>
                    <div class="form-item shortest"></div>
                    <div class="form-item shortest"></div>
                  
                </div>
                <div class="warning-container">
                    <span *ngIf="warningChildren">Please note: you have entered a value for 'Extra Children' that is greater than the 'Max. Children' set for this room type.</span>
                    <span *ngIf="warningAdults">Please note: you have entered a value for 'Extra Adults' that is greater than the allowed occupancy set for this room type.</span>
                    <span *ngIf="warningOccupancy">Please note: you have entered occupancy value that sum to a total greater than the allowed occupancy set for this room type.</span>
                </div>
                <button *ngIf="!loader && permissionsModel.update" type="submit" class="custom-button blue left">
                    Update
                </button>
                <div *ngIf="loader" class="loader left"></div>
            </form>
            <button type="button" (click)="close()" class="custom-button red left">Close</button>
        </div>
    </div>
</div>