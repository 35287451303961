<div class="rate-schedules-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Rate plans - {{ rateScheduleWithRatePlan?.name }}</h2>

            <dx-data-grid
                [dataSource]="copyOfRateScheduleWithRatePlan.ratePlans"
                [allowColumnResizing]="true"
                rowTemplate="rowTemplateName"
                [columns]="[
                    { dataField: 'id', caption: 'ID', allowSorting: true, width: 50 },
                    { dataField: 'name', caption: 'Name', allowSorting: true, width: 240 },
                    { dataField: 'shortName', caption: 'Short Name', allowSorting: true },
                    { dataField: 'googleOta', caption: 'Google', cellTemplate: 'google', allowSorting: false, width: 80, visible: false },
                    { cellTemplate: 'details', allowSorting: false, width: 60 },
                    { cellTemplate: 'delete', allowSorting: false, width: 60 }
                ]"
            >
                <table>
                    <ng-container *dxTemplate="let row of 'rowTemplateName'">
                        <tr>
                            <td class="center-td">{{ row.data.id }}</td>
                            <td>
                                <dx-select-box
                                    class="custom-select"
                                    [dataSource]="ratePlanModelPageData"
                                    [value]="row.data.id"
                                    valueExpr="id"
                                    displayExpr="name"
                                    (onItemClick)="setRatePlanToRateSchedule($event, row.data)"
                                    [disabled]="!permissionsModel.formButtonEnabled"
                                ></dx-select-box>
                            </td>
                            <td>{{ row.data.shortName }}</td>
                            <td *ngIf="hasGoogleOta && isPublic">
                                <input
                                    type="checkbox"
                                    name="room-check"
                                    (change)="googleOtaChange(row.data.id)"
                                    id="google-check{{
                                        row.data.id
                                    }}"
                                    [(ngModel)]="row.data.googleOta"
                                    [disabled]="!permissionsModel.formButtonEnabled"
                                />
                            </td>
                            <td>
                                <i *ngIf="row.data.id !== null" class="fas fa-rectangle-list fa-icon gray fa-icon-small" title="Details" (click)="openRatePlanDetailsSchedules(row.data.id)"></i>
                            </td>
                            <td (click)="delete(row.data)" *ngIf="permissionsModel.delete">
                                <i title="Delete" class="fas fa-trash-can fa-icon gray fa-icon-small"></i>
                            </td>
                        </tr>
                    </ng-container>
                </table>

                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="true" [showInfo]="true"> </dxo-pager>
            </dx-data-grid>
            <div class="form-item">
                <span class="text-danger">{{ errorMessage }}</span>
            </div>

            <div (click)="addNewItem()" class="add-new-box" *ngIf="permissionsModel.create">
                <span class="text">Add New</span>
                <div class="img">
                    <i class="fa-solid fa-plus fa-icon gray"></i>
                </div>
            </div>

            <button
                *ngIf="!loader && permissionsModel.update"
                type="button"
                (click)="addRatePlanToRateSchedules()"
                class="custom-button blue left"
            >
                Save
            </button>
            <div *ngIf="loader" class="loader left"></div>
            <button (click)="close()" type="button" class="custom-button red left">
                {{ permissionsModel.update ? 'Cancel' : 'Close' }}
            </button>
        </div>
    </div>
</div>
<app-rate-plans-details
    *ngIf="ratePlanDetails"
    [hasOrder]="false"
    [(detailsPopup)]="ratePlanDetails"
    [ratePlanId]="ratePlanId"
></app-rate-plans-details>
