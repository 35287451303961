import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthTokenService } from '../../../../../../shared/services/auth-token.service';

@Component({
    selector: 'app-alert-popup',
    templateUrl: './alert-popup.component.html',
    styleUrls: ['./alert-popup.component.less'],
})
export class AlertPopupComponent implements OnInit {
    @Input() alertPopup: boolean;
    @Output() alertPopupChange = new EventEmitter<boolean>();
    @Input() message: string;
    @Output() emitUpdate = new EventEmitter();

    theme: number;

    constructor() {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    }

    ngOnInit() {}

    confirm() {
        this.emitUpdate.emit();
        this.close();
    }

    close() {
        this.alertPopupChange.emit(false);
    }
}
