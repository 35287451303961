import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SignupService } from '../../signup.service';
import { RoomTypeRoomDetailsModel } from '../../../../models/room-type/room-type-room-details.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RoomTypeModel } from '../../../../models/room-type/room-types.model';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';

@Component({
    selector: 'app-map-room-details',
    templateUrl: './map-room-details.component.html',
    styleUrls: ['./map-room-details.component.less'],
})
export class MapRoomDetailsComponent implements OnInit {
    @Input() mapRoomDetails: boolean;
    @Output() mapRoomDetailsChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();
    @Input() roomTypeRoomId: number;
    @Input() roomType: RoomTypeModel;

    valForm: UntypedFormGroup;
    roomTypeRoomDetailsModel = new RoomTypeRoomDetailsModel();
    loader = false;
    theme: number;

    constructor(private signUpService: SignupService, private fb: UntypedFormBuilder) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;

        this.valForm = fb.group({
            occupancy: [null],
            children: [null],
            status: [null, Validators.required],
        });
    }

    ngOnInit() {
        this.valForm.controls['occupancy'].setValidators(
            Validators.compose([Validators.required, Validators.min(0), Validators.max(this.roomType.maximumOccupancy)])
        );
        this.valForm.controls['children'].setValidators(
            Validators.compose([Validators.required, Validators.min(0), Validators.max(this.roomType.maximumChildren)])
        );
        this.signUpService.getRoomTypeRoom(this.roomTypeRoomId).subscribe(
            (response) => {
                this.roomTypeRoomDetailsModel = response;
            },
            (error) => {
                console.log(error);
            }
        );
    }

    submitMapRoomDetails(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid && this.valForm.controls['children'].value <= this.valForm.controls['occupancy'].value) {
            this.loader = true;
            this.signUpService.updateRoomTypeRoom(this.roomTypeRoomDetailsModel.roomTypeRoom).subscribe(
                (response) => {
                    this.loader = false;
                    this.update.emit();
                    this.close();
                },
                (error) => {
                    this.loader = false;
                    console.log(error);
                }
            );
        }
    }

    close() {
        this.mapRoomDetailsChange.emit(false);
    }
}
