export class PricePlanModel {
    id: number;
    propertyId: number;
    ratePlanId: number;
    roomTypeId: number;
    minLoS: number;
    priceDate: Date;
    refDate?: any;
    dayOfWeek: number;
    derivedFrom?: any;
    offset?: any;
    offsetPercentage?: any;
    included: number;
    baseRate: number;
    additional: number;
    additionalRate: number;
    childrenRate: number;
    dpLessThan: number;
    dpDaysFrom: number;
    dpIncresedFor: number;
    active: boolean;
    dateCreated: Date;
}
