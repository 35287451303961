import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTransferService } from '../../../shared/services/data-transfer.service';
import { ActivationFlowModel } from '../../../models/user/activation.model';
import { EnumModel } from '../../../models/shared/enum-model';
import { CountryModel } from '../../../models/account/country.model';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { AuthLoginService } from '../../../shared/services/auth-login.service';
import { ActivationResultModel } from '../../../models/account/activation-result.model';

@Component({
    selector: 'app-payment-details',
    templateUrl: './payment-details.component.html',
    styleUrls: ['./payment-details.component.less'],
})
export class PaymentDetailsComponent implements OnInit {
    countryId: number;

    currentCountry = new CountryModel();
    valForm: UntypedFormGroup;
    activationFlowModel = new ActivationFlowModel();
    months: string[] = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    monthsEnumModel: EnumModel[] = [];
    activationResultModel = new ActivationResultModel();
    yearArray: number[] = [];
    popup = false;
    message: string;
    title: string;
    loader = false;
    theme: number;

    constructor(
        private fb: UntypedFormBuilder,
        private router: Router,
        private authLoginService: AuthLoginService,
        private authTokenService: AuthTokenService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;

        this.valForm = this.fb.group({
            cardNumber: [null, Validators.required],
            cvv: [null, Validators.compose([Validators.required, Validators.maxLength(3)])],
            month: [null, Validators.required],
            year: [null, Validators.required],
        });

        if (DataTransferService.GetData('ActivationFlowModel') !== null) {
            this.activationFlowModel = DataTransferService.GetData('ActivationFlowModel');
            this.getCountry(this.activationFlowModel.account.countryId, this.activationFlowModel.countries);

            if (this.currentCountry.code === 'GB') {
                this.router.navigate(['debit-mandate']);
            } else {
                this.router.navigate(['debit-mandate']); // TEMPORARY
                // this.router.navigate(['payment-details']);
            }
        } else {
            this.router.navigate(['login/activation']);
        }

        for (let i = 0; i < this.months.length; i++) {
            const month: EnumModel = new EnumModel();
            month.id = String(i + 1);
            month.value = String(i + 1);
            month.name = this.months[i];
            this.monthsEnumModel.push(month);
        }

        const currentYear = new Date().getFullYear();
        for (let i = 0; i < 25; i++) {
            this.yearArray.push(currentYear + i);
        }
    }

    ngOnInit() {}

    getCountry(countryId, listOfCountries: CountryModel[]) {
        for (const country of listOfCountries) {
            if (country.id === countryId) {
                this.currentCountry = country;
                break;
            }
        }
    }

    confirm() {
        if (this.activationResultModel !== null) {
            if (this.activationResultModel.success) {
                this.authTokenService.refreshToken(this.activationResultModel.token);
                DataTransferService.PullData('ActivationFlowModel');
                this.router.navigate(['dashboard']);
            }
        }
    }

    submitCardStream(e) {
        e.stopPropagation();

        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            this.loader = true;

            this.authLoginService.activateProperty(this.activationFlowModel).subscribe(
                (response) => {
                    this.popup = true;
                    this.loader = false;
                    this.activationResultModel = response;
                    if (this.activationResultModel !== null) {
                        this.title = this.activationResultModel.title;
                        this.message = this.activationResultModel.message;
                    }
                },
                (error) => {
                    console.log(error);
                    this.popup = true;
                    this.loader = false;
                }
            );
        }
    }
}
