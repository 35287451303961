import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidationService } from 'src/app/shared/services/custom-validation.service';
import { Router } from '@angular/router';
import { ActivationFlowModel, CardStreamModel, GoCardlessModel } from '../../../models/user/activation.model';
import { DataTransferService } from '../../../shared/services/data-transfer.service';
import { AuthLoginService } from '../../../shared/services/auth-login.service';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { AccountStatus } from '../../../enums/account.enum';
import { CommunicationService } from '../../../shared/services/communication.service';
import {CountryContext} from '../../../models/auth-jwt/token-context';

@Component({
    selector: 'app-account-details',
    templateUrl: './account-details.component.html',
    styleUrls: ['./account-details.component.less'],
})
export class AccountDetailsComponent implements OnInit {
    valForm: UntypedFormGroup;
    activationFlowModel = new ActivationFlowModel();

    loader = false;
    theme: number;
    countryContext: CountryContext;

    constructor(
        private fb: UntypedFormBuilder,
        private router: Router,
        private authLoginService: AuthLoginService,
        private communicationService: CommunicationService,
        private authTokenService: AuthTokenService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.countryContext = AuthTokenService.decodeJwtToken().Country;

        this.valForm = this.fb.group({
            name: [null, Validators.compose([Validators.required, Validators.maxLength(250)])],
            billEmail: [
                null,
                Validators.compose([Validators.required, Validators.maxLength(50), Validators.email]),
            ],
            billContactName: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            address: [null, Validators.compose([Validators.required, Validators.maxLength(250)])],
            companyNumber: [null, Validators.maxLength(50)],
            countryCode: [null, Validators.required],
            vatNo: [null, Validators.maxLength(50)],
        });

        if (
            AuthTokenService.decodeJwtToken() !== null &&
            (AuthTokenService.decodeJwtToken().Account.Status === AccountStatus.Live || AuthTokenService.decodeJwtToken().Account.Status === AccountStatus.Development)
        ) {
            this.router.navigate(['dashboard']);
        }
    }

    ngOnInit() {
        DataTransferService.PullData('ActivationFlowModel');
        DataTransferService.PullData('ShowTrialMessage');

        this.authLoginService.getForActivation().subscribe(
            (response) => {
                this.loader = false;
                this.activationFlowModel = response;
                this.activationFlowModel.cardStreamData = new CardStreamModel();
                this.activationFlowModel.goCardlessData = new GoCardlessModel();
                this.activationFlowModel.billingInfo.itemsView = this.activationFlowModel.billingInfo.items;
                this.activationFlowModel.billingInfo.excludedItemsView =
                    this.activationFlowModel.billingInfo.excludedItems;
                this.activate(this.activationFlowModel);
            },
            (error) => {
                this.loader = false;
                console.log(error);
            }
        );
        this.valForm.controls['countryCode'].setValue(this.activationFlowModel.account.countryId);
    }

    nextAccountDetails(e) {
        e.stopPropagation();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        if (this.valForm.valid) {
            this.loader = true;
            DataTransferService.PullData('ActivationFlowModel');
            DataTransferService.PushData('ActivationFlowModel', this.activationFlowModel);
            this.router.navigate(['login/activation']);
        }
    }

    goToDashboard() {
        this.router.navigate(['dashboard']);
    }
    activate(model) {
        this.authLoginService.activateProperty(model).subscribe(
            (res) => {
                this.authTokenService.refreshToken(res.token);
                this.communicationService.accountActivated.next(true);
            },
            (error) => {
                console.log(error);
            }
        );
    }
}
