import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AuthTokenService } from '../../services/auth-token.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.less'],
})
export class ConfirmModalComponent implements OnInit {
    @Input() confirmModal: boolean;
    @Output() confirmModalChange = new EventEmitter<boolean>();
    @Input() confirmLabel: string;
    @Input() declineLabel: string;
    @Input() confirmClass: string="";
    @Input() declineClass: string="";
    @Input() alternativeClass: string="";
    @Output() confirm = new EventEmitter();
    @Output() declined = new EventEmitter();
    @Output() alternativeOption = new EventEmitter();
    @Input() message: string;
    @Input() displayHtmlMessage: boolean;
    @Input() oKButtonOnly: string;
    @Input() displayConfirmButton: boolean;
    @Input() displayAlternativeOptionButton: boolean = false;
    @Input() alternativeOptionLabel: string = "";
    @Input() reverseOptions: boolean = false;

    theme: number;
    messageHtml: SafeHtml;

    constructor(
        private sanitizer: DomSanitizer,
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    }

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        console.log(event);
        const x = event.keyCode;
        if (x === 27) {
            this.close();
        }

        if (x === 13) {
            this.confirmFunction();
        }
    }

    ngOnInit() {
        if (this.displayConfirmButton==null) {
            this.displayConfirmButton = true;
        }
        if (this.displayHtmlMessage) {
            this.messageHtml = this.sanitizer.bypassSecurityTrustHtml(this.message);
        }
    }

    close() {
        this.confirmModalChange.emit(false);
        this.declined.emit(true);
    }

    confirmFunction() {
        this.confirm.emit();
        this.confirmModalChange.emit(false);
    }

    alternativeOptionFunction() {
        this.alternativeOption.emit();
        this.confirmModalChange.emit(false);  
    }

    showDecline(){
        if (this.oKButtonOnly === 'true'){
            return false;
        }
        return true;
    }
}
