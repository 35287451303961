import { AmenityModel, CleaningFrequencyModel, RoomTypePictureModel } from './room-types.model';
import { KeyValuePair } from '../shared/key-value-pair.model';
import { EnumModel } from '../shared/enum-model';

export class RoomTypeDetailsModel {
    roomType: RoomType;
    pageData: PageData;

    constructor() {
        this.roomType = new RoomType();
        this.pageData = new PageData();
    }
}

export class RoomType {
    id: number;
    propertyId: number;
    name: string;
    shortName: string;
    maximumOccupancy: number;
    maximumChildren: number;
    class: string;
    childrenTakeAvailability: boolean;
    beddingArangement: string[];
    description: string;
    otaTypeId?: any;
    status: string;
    dateCreated: Date;
    displayOrder: number;
    customTag: string;
    roomTypePictures: RoomTypePictureModel[] = [];
    amenities: AmenityModel[] = [];
    cleaningFrequencies: CleaningFrequencyModel[] = [];
    roomsIdName: KeyValuePair[];

    // added new properties
    cleaningFrequency: CleaningFrequencyModel;

    constructor() {
        this.cleaningFrequency = new CleaningFrequencyModel();
    }
}

export class BeddingArangement {
    value: string;
    id: string;
    name: string;
}

export class RoomTypeClass {
    value: string;
    id: string;
    name: string;
}

export class OtaType {
    public id: number;
    public otaName: string;
    public otaCode: string;
    public active: boolean;
    public roomTypes: RoomType[];
}

export class Status {
    value: string;
    id: string;
    name: string;
}

export class PageData {
    status: Status[];
    cleaningFrequencies: CleaningFrequencyModel[];
    beddingArangements: string[];
    amenities: AmenityModel[];
    roomTypeClass: RoomTypeClass[];
    otaTypes: OtaType[];
    displayOrder: number;
}
