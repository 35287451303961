import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { environment } from '../../../../environments/environment';
import { PropertyDetailsModel } from '../../../models/property/property-details.model';
import { PropertySettingModel } from '../../../models/property/property-setting.model';
import { SendTestEmailComponent } from '../settings-property-settings/send-test-email/send-test-email.component';
import { TestEmailModel } from '../../../models/templates/test-email.model';
import { AccountSettingUpdateModel } from '../../../models/account/account-setting-update.model';
import { PropertyPictureModel } from '../../../models/property/property-details.model';

@Injectable()
export class SettingsPropertiesService {
    private getPropertiesUrl = environment.apiUrl + '/Property/GetProperties';
    private getPropertyDetailsUrl = environment.apiUrl + '/Property/GetPropertyDetails';
    private insertPropertyUrl = environment.apiUrl + '/Property/InsertProperty';
    private updatePropertyUrl = environment.apiUrl + '/Property/UpdateProperty';
    private getPropertyDetailsForInsertUrl = environment.apiUrl + '/Property/GetPropertyDetailsForInsert';
    private deletePropertySettingUrl = environment.apiUrl + '/Property/DeletePropertySetting';
    private insertPropertySettingUrl = environment.apiUrl + '/Property/InsertPropertySetting';
    private updatePropertySettingUrl = environment.apiUrl + '/Property/UpdatePropertySetting';
    private updateAccountSettingUrl = environment.apiUrl + '/Account/UpdateAccountSetting';
    private getPropertySettingsUrl = environment.apiUrl + '/Property/GetPropertySettings';
    private getEmailSMSPropertySettingsUrl = environment.apiUrl + '/Property/GetEmailSMSPropertySettings';
    private getOtherPropertySettingsUrl = environment.apiUrl + '/Property/GetOtherPropertySettings';
    private getReportingPropertySettingsUrl = environment.apiUrl + '/Property/GetReportingPropertySettings';
    private getEPOSAccountLimitPropertySettingsUrl =
        environment.apiUrl + '/Property/GetEPOSAccountLimitPropertySetting';
    private sendTestEmailUrl = environment.apiUrl + '/Templates/SendTestEmail';
    private getPropertyAddressUrl = environment.apiUrl + '/Property/GetPropertyAddress';
    private downloadPictureUrl = environment.apiUrl + '/Property/GetPropertyPicture';
    private deletePictureUrl = environment.apiUrl + '/Property/DeletePropertyPicture';
    private uploadPictureUrl = environment.apiUrl + '/Property/UploadPropertyPicture';
    private decryptPropertySettingUrl = environment.apiUrl + '/Property/DecryptPropertySetting';

    constructor(private http: HttpRequestService) {}

    getProperties() {
        return this.http.get(this.getPropertiesUrl);
    }

    getPropertyAddress() {
        return this.http.get(this.getPropertyAddressUrl);
    }

    sendTestEmail(model: TestEmailModel) {
        return this.http.post(this.sendTestEmailUrl, model, true, 'Action has been added to queue', ' ');
    }

    getPropertyDetails(propertyId: number) {
        return this.http.get(this.getPropertyDetailsUrl + '?propertyId=' + propertyId);
    }

    insertProperty(model: PropertyDetailsModel, file: File[]) {
        return this.http.postMultipart(this.insertPropertyUrl, model, file, true);
    }

    updateProperty(model: PropertyDetailsModel, file: File[]) {
        return this.http.postMultipart(this.updatePropertyUrl, model, file, true);
    }

    getPropertyDetailsForInsert() {
        return this.http.get(this.getPropertyDetailsForInsertUrl);
    }

    deletePropertySetting(propertySettingId: number) {
        return this.http.delete(this.deletePropertySettingUrl + '?propertySettingId=' + propertySettingId, true);
    }

    insertPropertySetting(model: PropertySettingModel) {
        return this.http.post(this.insertPropertySettingUrl, model, true);
    }

    updatePropertySetting(model: PropertySettingModel, showSuccessToast?: boolean) {
        return this.http.post(this.updatePropertySettingUrl, model, showSuccessToast);
    }

    updateAccountSetting(model: AccountSettingUpdateModel, showSuccessToast?: boolean) {
        return this.http.put(this.updateAccountSettingUrl, model, showSuccessToast);
    }

    getEmailSMSPropertySettings() {
        return this.http.get(this.getEmailSMSPropertySettingsUrl);
    }

    getOtherPropertySettings(propertyId: number) {
        return this.http.get(this.getOtherPropertySettingsUrl + '?propertyId=' + propertyId);
    }

    getReportingPropertySettings(propertyId: number) {
        return this.http.get(this.getReportingPropertySettingsUrl + '?propertyId=' + propertyId);
    }

    getDecryptedPropertySetting(propertyId: number, key: string)
    {
        return this.http.post(this.decryptPropertySettingUrl + '?propertyId=' + propertyId + '&key=' + key, null, false);
    }

    getEPOSAccountLimitPropertySettings(propertyId: number) {
        return this.http.get(this.getEPOSAccountLimitPropertySettingsUrl + '?propertyId=' + propertyId);
    }

    getSourceForPropertySettings(url: string) {
        return this.http.get(url);
    }

    downloadPicture(model: PropertyPictureModel) {
        return this.http.getBlob(this.downloadPictureUrl, model);
    }

    deletePicture(model: PropertyPictureModel) {
        return this.http.post(this.deletePictureUrl, model);
    }

    uploadPicture(model: PropertyPictureModel, file: File[]) {
        return this.http.postMultipart(this.uploadPictureUrl, model, file, true);
    }
}
