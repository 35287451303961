<div class="room-type-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>{{ permissionsModel.formButtonEnabled ? 'Edit Room Type' : 'Room Type' }}</h2>

            <form [formGroup]="valForm" (ngSubmit)="submitRoomType($event)">
                <ng-container *ngIf="roomTypeDetailsModel.roomType && roomTypeDetailsModel.pageData">
                    <div class="room-type flex">
                        <div class="left-part flex">
                            <div class="form-item">
                                <div class="custom-label">Full name</div>
                                <input
                                    class="custom-input"
                                    formControlName="name"
                                    name="roomTypeName"
                                    [(ngModel)]="roomTypeDetailsModel.roomType.name"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['name'].hasError('required') &&
                                        (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                                    "
                                    >Name is required.</span
                                >
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['name'].hasError('maxlength') &&
                                        (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                                    "
                                    >Max 50 characters.</span
                                >
                            </div>

                            <div class="form-item">
                                <div class="custom-label">Short name</div>
                                <input
                                    class="custom-input"
                                    formControlName="shortName"
                                    name="shortName"
                                    [(ngModel)]="roomTypeDetailsModel.roomType.shortName"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['shortName'].hasError('required') &&
                                        (valForm.controls['shortName'].dirty || valForm.controls['shortName'].touched)
                                    "
                                    >Short name is required.</span
                                >
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['shortName'].hasError('maxlength') &&
                                        (valForm.controls['shortName'].dirty || valForm.controls['shortName'].touched)
                                    "
                                    >Max 10 characters.</span
                                >
                            </div>

                            <div class="form-item">
                                <div class="custom-label">Status</div>
                                <dx-select-box
                                    class="custom-select"
                                    [(value)]="roomTypeDetailsModel.roomType.status"
                                    [dataSource]="roomTypeDetailsModel.pageData.status"
                                    displayExpr="name"
                                    valueExpr="value"
                                    formControlName="status"
                                    name="status"
                                >
                                </dx-select-box>
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['status'].hasError('required') &&
                                        (valForm.controls['status'].dirty || valForm.controls['status'].touched)
                                    "
                                    >Status is required.</span
                                >
                            </div>

                            <div class="form-item">
                                <div class="custom-label">Class</div>
                                <dx-select-box
                                    class="custom-select"
                                    [(value)]="roomTypeDetailsModel.roomType.class"
                                    [dataSource]="roomTypeDetailsModel.pageData.roomTypeClass"
                                    displayExpr="name"
                                    valueExpr="value"
                                    formControlName="class"
                                    name="class"
                                >
                                </dx-select-box>

                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['class'].hasError('required') &&
                                        (valForm.controls['class'].dirty || valForm.controls['class'].touched)
                                    "
                                    >Class is required.</span
                                >
                            </div>

                            <div class="form-item">
                                <div class="custom-label">Bedding configuration</div>
                                <dx-tag-box
                                    class="custom-select" 
                                    [dataSource]="roomTypeDetailsModel.pageData.beddingArangements"
                                    displayExpr="name"
                                    valueExpr="value"
                                    [showClearButton]="true"
                                    [(value)]="selectedBeddingArrangments"
                                    formControlName="beddingArangement"
                                    [showSelectionControls]="true"
                                    name="beddingArangement"
                                >
                                </dx-tag-box>
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['beddingArangement'].hasError('required') &&
                                        (valForm.controls['beddingArangement'].dirty ||
                                            valForm.controls['beddingArangement'].touched)
                                    "
                                    >Bedding Arrangements are required.</span
                                >
                            </div>

                            <div class="form-item">
                                <div class="custom-label">Cleaning frequency</div>
                                <dx-tag-box
                                    class="custom-select"
                                    [dataSource]="cleaningFrequencies"
                                    displayExpr="name"
                                    [showClearButton]="true"
                                    [value]="roomTypeDetailsModel.roomType.cleaningFrequencies"
                                    formControlName="cleaningFrequencies"
                                    [showSelectionControls]="true"
                                    name="cleaningFrequencies"
                                    (onValueChanged)="setCleaningFrequencies($event)"
                                >
                                </dx-tag-box>
                                <!--<span class="text-danger" *ngIf="valForm.controls['cleaningFrequencies'].hasError('required') && (valForm.controls['cleaningFrequencies'].dirty || valForm.controls['cleaningFrequencies'].touched)">Cleaning Frequencies are required.</span>-->
                            </div>

                            <div class="form-item">
                                <div class="custom-label">Display order</div>
                                <input
                                    type="number"
                                    class="custom-input"
                                    min="1"
                                    name="displayOrder"
                                    formControlName="displayOrder"
                                    [(ngModel)]="roomTypeDetailsModel.roomType.displayOrder"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['displayOrder'].hasError('required') &&
                                        (valForm.controls['displayOrder'].dirty ||
                                            valForm.controls['displayOrder'].touched)
                                    "
                                    >Display order is required.</span
                                >
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['displayOrder'].hasError('min') &&
                                        (valForm.controls['displayOrder'].dirty ||
                                            valForm.controls['displayOrder'].touched)
                                    "
                                    >Minimum number is 1.</span
                                >
                                <span class="text-danger" *ngIf="!validateDisplayOrder()">Order is occupied.</span>
                            </div>

                            <div class="form-item">
                                <div class="custom-label">Custom tag</div>
                                <input
                                    type="text"
                                    class="custom-input"
                                    name="customTag"
                                    formControlName="customTag"
                                    [(ngModel)]="roomTypeDetailsModel.roomType.customTag"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['customTag'].hasError('maxlength') &&
                                        (valForm.controls['customTag'].dirty || valForm.controls['customTag'].touched)
                                    "
                                    >Maximum 50 characters.</span
                                >
                            </div>
                        </div>

                        <div class="right-part">
                            <div class="form-item">
                                <ng-container *ngIf="showEditor">
                                    <div class="custom-label">Description</div>
                                    <!--<textarea name="desc" class="custom-textarea" formControlName="desc" [(ngModel)]="roomTypeDetailsModel.roomType.description"></textarea>-->
                                    <app-tiny-editor
                                        class="room-desc-text-editor"
                                        elementHeight="240"
                                        [statusBar]="false"
                                        [readonly]="!permissionsModel.formButtonEnabled"
                                        [elementId]="'desc'"
                                        [text]="roomTypeDetailsModel.roomType.description"
                                        (onEditorContentChange)="setValueFromEditor($event)"
                                    ></app-tiny-editor>
                                    <!--<span class="text-danger" *ngIf="valForm.controls['desc'].hasError('required') && (valForm.controls['desc'].dirty || valForm.controls['desc'].touched)">Description is required.</span>-->
                                    <span
                                        class="text-danger"
                                        *ngIf="!roomTypeDetailsModel.roomType.description && submittedForm"
                                        >Description is required.</span
                                    >
                                </ng-container>
                            </div>

                            <div class="form-item">
                                <div class="custom-label">Amenities</div>
                                <dx-tag-box
                                    class="custom-select"
                                    formControlName="amenities"
                                    name="amenities"
                                    [showClearButton]="true"
                                    [value]="roomTypeDetailsModel.roomType.amenities"
                                    [dataSource]="amenities"
                                    [showSelectionControls]="true"
                                    displayExpr="name"
                                    (onValueChanged)="setAmenities($event)"
                                >
                                </dx-tag-box>
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['amenities'].hasError('required') &&
                                        (valForm.controls['amenities'].dirty || valForm.controls['amenities'].touched)
                                    "
                                    >Amenities are required.</span
                                >
                            </div>
                        </div>
                        
                    </div>

                    <div class="bottom-part flex">
                        <div class="form-item">
                            <div class="custom-label">Occupancy</div>
                            <input
                                type="number"
                                class="custom-input"
                                min="0"
                                name="occupancy"
                                formControlName="occupancy"
                                [(ngModel)]="roomTypeDetailsModel.roomType.maximumOccupancy"
                            />
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['occupancy'].hasError('required') &&
                                    (valForm.controls['occupancy'].dirty || valForm.controls['occupancy'].touched)
                                "
                                >Occupancy is required.</span
                            >
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['occupancy'].hasError('min') &&
                                    (valForm.controls['occupancy'].dirty || valForm.controls['occupancy'].touched)
                                "
                                >Minimum number is 0.</span
                            >
                        </div>

                        <div class="form-item">
                            <div class="custom-label">Max. children</div>
                            <input
                                type="number"
                                class="custom-input"
                                min="0"
                                formControlName="maxChildren"
                                name="maxChildren"
                                [(ngModel)]="roomTypeDetailsModel.roomType.maximumChildren"
                            />
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['maxChildren'].hasError('required') &&
                                    (valForm.controls['maxChildren'].dirty || valForm.controls['maxChildren'].touched)
                                "
                                >Field is required.</span
                            >
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['maxChildren'].hasError('min') &&
                                    (valForm.controls['maxChildren'].dirty || valForm.controls['maxChildren'].touched)
                                "
                                >Minimum number is 0.</span
                            >
                            <span
                                class="text-danger max-children"
                                *ngIf="valForm.controls['maxChildren'].value >= valForm.controls['occupancy'].value"
                                >This num must be less than Occupancy num.</span
                            >
                        </div>

                        <div class="form-item">
                            <div class="custom-label">Children take availability</div>
                            <input
                                type="checkbox"
                                id="select-all"
                                formControlName="childrenAvailability"
                                name="childrenAvailability"
                                class="css-checkbox"
                                [(ngModel)]="roomTypeDetailsModel.roomType.childrenTakeAvailability"
                            />
                            <label for="select-all" class="css-label radGroup1"></label>
                        </div>

                        <div class="form-item">
                            <div class="custom-label">Rack Rate</div>
                            <input
                                type="number"
                                class="custom-input"
                                min="1"
                                formControlName="rackRate"
                                name="rackRate"
                                [(ngModel)]="roomTypeDetailsModel.roomType.rackRate"
                            />
                            <label for="select-all" class="css-label radGroup1"></label>
                        </div>

                        <div class="img-labels flex">
                            <div class="label">Image 1</div>
                            <div class="label">Image 2</div>
                            <div class="label">Image 3</div>
                        </div>

                        <div class="image-container">
                            <div
                                class="img-holder"
                                *ngFor="let picture of roomTypePictures; let index = index"
                                id="imageDiv{{ index }}"
                            >
                                <img [src]="sanitize(picture)" alt="" />

                                <div class="img-actions">
                                    <input type="file" accept="image/*" (change)="uploadImage($event, index)" />
                                    <div class="action" (click)="removeImage(picture)">Remove</div>
                                </div>
                            </div>
                        </div>
                        <div class="image-validation-text">* Maximum image size that you can upload is 4MB</div>
                    </div>

                    <div *ngIf="loader" class="loader left"></div>

                    <div class="dialog-controls-wrap">
                        <button
                            *ngIf="!loader && permissionsModel.formButtonEnabled"
                            type="submit"
                            class="custom-button blue left"
                        >
                            Save
                        </button>
                        <button (click)="close()" type="button" class="custom-button red left">
                            {{ permissionsModel.formButtonEnabled ? 'Cancel' : 'Close' }}
                        </button>
                        <button
                            (click)="openRateSchedulesFromRoomTypes()"
                            type="button"
                            class="custom-button orange left"
                        >
                            Rate Schedules
                        </button>
                    </div>
                </ng-container>
            </form>
        </div>
    </div>
</div>
<app-room-type-rate-schedule
    *ngIf="rateSchedule"
    [roomTypeId]="idOfRoomType"
    [roomTypeShortName]="roomTypeDetailsModel.roomType.shortName"
    [(rateSchedule)]="rateSchedule"
></app-room-type-rate-schedule>

<div *ngIf="popupLoader" class="loader"></div>
