import { PartnerModel } from './partner.model';
import { RateScheduleBaseModel } from '../rate-schedule/rate-schedule-base.model';
import { EnumModel } from '../shared/enum-model';

export class PartnerPdModel {
    public partnerModel: PartnerModel;
    public rateSchedules: RateScheduleBaseModel[];
    public discountTypes: EnumModel[];

    constructor() {
        this.partnerModel = new PartnerModel();
    }
}
