<div
    class="side-menu"
    [ngClass]="[
        router.url.indexOf('calendar') > -1 ||
        router.url.indexOf('assign-rooms') > -1 ||
        (router.url.indexOf('dashboard') && innerWidth <= 1366) ||
        router.url.indexOf('booking/new') > -1
            ? 'calendar'
            : '',
        theme == 2 ? 'dark-theme' : ''
    ]"
>
    <div class="inner-wrapper">
        <div class="hotel-info">
            <ng-container *ngIf="showImage">
                <img *ngIf="!hasImage" src="../../assets/images/caterbook-default-hotel-image.jpg" alt="hotel-image" />
                <img *ngIf="hasImage" class="uploaded-img" [src]="imageSafeUrl" alt="hotel-image" />
            </ng-container>
            <div class="name">{{ propertyName }}</div>
            <div class="label">
                <strong>#{{ accountId + 1000 }}</strong>
            </div>
        </div>
        <nav>
          <ul>
            <!--dashboard-->
            <li routerLink="/dashboard"
                routerLinkActive="active"
                (click)="openMenus('')"
                *ngIf="userRole != 'Housekeeping' && userRole != 'WebDesigner'">
              <div class="icon flex-center">
                <i class="fa-solid fa-house"></i>
              </div>
              <div class="name">Dashboard</div>
            </li>
            <!--booking-->
            <li>
              <div (click)="openMenus('bookingOptions')"
                   class="dropdown-wrapper flex"
                   *ngIf="userRole != 'Housekeeping' && userRole != 'WebDesigner'">
                <div class="icon flex-center">
                  <i [ngClass]="[openMenu == 'bookingOptions' ? 'active' : '']" class="fa-solid fa-file-pen"></i>
                </div>
                <div class="name">Booking</div>
                <i *ngIf="openMenu != 'bookingOptions'" class="toggle-arrow fa fa-chevron-down"></i>
                <i *ngIf="openMenu == 'bookingOptions'" class="toggle-arrow fa fa-chevron-up"></i>
              </div>
              <ul *ngIf="openMenu == 'bookingOptions' || (router.url.indexOf('booking') > 0 && bookingOptions)"
                  class="submenu">
                <li routerLink="/booking/calendar"
                    routerLinkActive="active"
                    *ngIf="userRole != 'Housekeeping' && userRole != 'WebDesigner'">
                  <div class="icon">C</div>
                  <div class="name">Calendar</div>
                </li>
                <li routerLink="/booking/search"
                    routerLinkActive="active"
                    *ngIf="userRole != 'Housekeeping' && userRole != 'WebDesigner'">
                  <div class="icon">BS</div>
                  <div class="name">Booking search</div>
                </li>
                <li routerLink="/booking/new"
                    routerLinkActive="active"
                    *ngIf="userRole != 'Housekeeping' && userRole != 'WebDesigner'">
                  <div class="icon">NB</div>
                  <div class="name">New booking</div>
                </li>
                <li routerLink="/function/new"
                    routerLinkActive="active"
                    *ngIf="userRole != 'Housekeeping' && userRole != 'WebDesigner' && token.Property.Functions">
                  <div class="icon">NF</div>
                  <div class="name">New function</div>
                </li>
                <li routerLink="/booking/guest-records"
                    routerLinkActive="active"
                    *ngIf="userRole === 'Owner' || userRole === 'Manager' || userRole === 'Supervisor'">
                  <div class="icon">GR</div>
                  <div class="name">Guest records</div>
                </li>
                <li routerLink="/reports/resident-guests"
                    routerLinkActive="active"
                    *ngIf="userRole != 'BookingOnly' && userRole != 'Housekeeping' && userRole != 'WebDesigner'">
                  <div class="icon">R</div>
                  <div class="name">Resident guests</div>
                </li>
              </ul>
            </li>
            <!--pricing-->
            <li>
              <div (click)="openMenus('pricingOptions')"
                   class="dropdown-wrapper flex"
                   *ngIf="userRole != 'Housekeeping' && userRole != 'WebDesigner'">
                <div class="icon flex-center">
                  <i [ngClass]="[openMenu == 'pricingOptions' ? 'active' : '']" class="fa-solid fa-money-bill-trend-up"></i>
                </div>
                <div class="name">Pricing</div>
                <i *ngIf="openMenu != 'pricingOptions'" class="toggle-arrow fa fa-chevron-down"></i>
                <i *ngIf="openMenu == 'pricingOptions'" class="toggle-arrow fa fa-chevron-up"></i>
              </div>
              <ul *ngIf="openMenu == 'pricingOptions' || (router.url.indexOf('pricing') > 0 && pricingOptions)"
                  class="submenu">
                <li routerLink="/pricing/price-plans" routerLinkActive="active">
                  <div class="icon">PP</div>
                  <div class="name">Price plans</div>
                </li>
                <li routerLink="/pricing/rate-plans" routerLinkActive="active">
                  <div class="icon">RP</div>
                  <div class="name">Rate plans</div>
                </li>
                <li routerLink="/pricing/extras" routerLinkActive="active">
                  <div class="icon">E</div>
                  <div class="name">Extras</div>
                </li>
                <li routerLink="/pricing/promo-codes" routerLinkActive="active">
                  <div class="icon">PC</div>
                  <div class="name">Promo codes</div>
                </li>
                <li routerLink="/pricing/rate-schedules" routerLinkActive="active">
                  <div class="icon">RS</div>
                  <div class="name">Rate schedules</div>
                </li>
              </ul>
            </li>
            <!--inventory-->
            <li>
              <div (click)="openMenus('inventory')"
                   class="dropdown-wrapper flex"
                   *ngIf="userRole != 'WebDesigner'">
                <div class="icon flex-center">
                  <i [ngClass]="[openMenu == 'inventory' ? 'active' : '']" class="fa-solid fa-cubes-stacked ml-3"></i>
                </div>
                <div class="name">Inventory</div>
                <i *ngIf="openMenu != 'inventory'" class="toggle-arrow fa fa-chevron-down"></i>
                <i *ngIf="openMenu == 'inventory'" class="toggle-arrow fa fa-chevron-up"></i>
              </div>
              <ul *ngIf="openMenu == 'inventory' || (router.url.indexOf('inventory') > 0 && inventory)"
                  class="submenu">
                <li routerLink="/inventory/availability"
                    routerLinkActive="active"
                    *ngIf="userRole != 'Housekeeping'">
                  <div class="icon">A</div>
                  <div class="name">Availability</div>
                </li>
                <li routerLink="/inventory/room-types"
                    routerLinkActive="active"
                    *ngIf="userRole != 'Housekeeping'">
                  <div class="icon">RT</div>
                  <div class="name">Room types</div>
                </li>
                <li routerLink="/inventory/assign-rooms"
                    routerLinkActive="active"
                    *ngIf="userRole != 'Housekeeping'">
                  <div class="icon">AR</div>
                  <div class="name">Assign rooms</div>
                </li>
                <li routerLink="/inventory/function-rooms"
                    routerLinkActive="active"
                    *ngIf="userRole != 'Housekeeping' && token.Property.Functions">
                  <div class="icon">FR</div>
                  <div class="name">Function rooms</div>
                </li>
                <li routerLink="/inventory/housekeeping" routerLinkActive="active">
                  <div class="icon">H</div>
                  <div class="name">Housekeeping config</div>
                </li>
              </ul>
            </li>
            <!--finance-->
            <li>
              <div (click)="openMenus('finance')"
                   class="dropdown-wrapper flex"
                   *ngIf="userRole != 'Housekeeping' && userRole != 'WebDesigner'">
                <div class="icon flex-center">
                  <i  [ngClass]="[openMenu == 'finance' ? 'active' : '']" class="fa-solid fa-coins"></i>
                </div>
                <div class="name">Finance</div>
                <i *ngIf="openMenu != 'finance'" class="toggle-arrow fa fa-chevron-down"></i>
                <i *ngIf="openMenu == 'finance'" class="toggle-arrow fa fa-chevron-up"></i>
              </div>
              <ul *ngIf="openMenu == 'finance' || (router.url.indexOf('finance') > 0 && finance)" class="submenu">
                <li routerLink="/finance/billing" routerLinkActive="active">
                  <div class="icon">MI</div>
                  <div class="name">Manage invoices</div>
                </li>
                <li routerLink="/reports/partner-invoice" routerLinkActive="active">
                  <div class="icon">PS</div>
                  <div class="name">Partner statements</div>
                </li>
                <li routerLink="/finance/payments" routerLinkActive="active">
                  <div class="icon">P</div>
                  <div class="name">Payments</div>
                </li>
                <li routerLink="/finance/vat-rates" routerLinkActive="active">
                  <div class="icon">VR</div>
                  <div class="name">{{this.token.Country.VatName}} rates</div>
                </li>
                <li routerLink="/reports/held-deposits"
                    routerLinkActive="active"
                    *ngIf="userRole != 'BookingOnly'">
                  <div class="icon">H</div>
                  <div class="name">Held deposits</div>
                </li>
                <li routerLink="/reports/payment-reconciliation"
                    routerLinkActive="active"
                    *ngIf="userRole != 'BookingOnly'">
                  <div class="icon">P</div>
                  <div class="name">Payment reconciliation</div>
                </li>
                <li routerLink="/booking/broken-payments"
                    routerLinkActive="active"
                    *ngIf="userRole != 'BookingOnly'">
                  <div class="icon">R</div>
                  <div class="name">Broken payments</div>
                </li>
                <li
                  routerLink="/finance/zread"
                  routerLinkActive="active"
                  *ngIf="(userRole === 'Owner' || userRole === 'Manager' || userRole === 'Supervisor') && (token.Property.ZReadEnabled)"
                >
                  <div class="icon">ZR</div>
                  <div class="name">Z-read</div>
                </li>
                <li
                    routerLink="/finance/xero"
                    routerLinkActive="active"
                    *ngIf="(userRole === 'Owner' || userRole === 'Manager') && token.Property.Xero"
                  >
                    <div class="icon">XE</div>
                    <div class="name">Xero</div>
                </li>
              </ul>
            </li>
            <!--messaging centre-->
            <li>
              <div (click)="openMenus('message')"
                   class="dropdown-wrapper flex"
                   *ngIf="userRole != 'Housekeeping' && userRole != 'WebDesigner'">
                <div class="icon flex-center">
                  <i [ngClass]="[openMenu == 'message' ? 'active' : '']" class="fa-solid fa-message"></i>
                </div>
                <div class="name">Messaging Centre</div>
                <i *ngIf="openMenu != 'message'" class="toggle-arrow fa fa-chevron-down"></i>
                <i *ngIf="openMenu == 'message'" class="toggle-arrow fa fa-chevron-up"></i>
              </div>
              <ul *ngIf="openMenu == 'message' || (router.url.indexOf('messages') > 0 && message)"
                  class="submenu">
                <li routerLink="/messaging-centre/templates"
                    routerLinkActive="active"
                    *ngIf="userRole == 'Owner' || userRole == 'Manager' || userRole == 'Supervisor'">
                  <div class="icon">T</div>
                  <div class="name">Email/SMS templates</div>
                </li>
                <li routerLink="/messaging-centre/invoice-template"
                    routerLinkActive="active"
                    *ngIf="userRole == 'Owner' || userRole == 'Manager'">
                  <div class="icon">IT</div>
                  <div class="name">Finance templates</div>
                </li>
                <li routerLink="/messaging-centre/logs"
                    routerLinkActive="active"
                    *ngIf="
                                userRole == 'Owner' ||
                                userRole == 'Manager' ||
                                userRole == 'Supervisor' ||
                                userRole == 'Receptionist'
                            ">
                  <div class="icon">L</div>
                  <div class="name">Logs</div>
                </li>
                <!-- <li routerLink="/messaging-centre/guest-bulk-grf" routerLinkActive="active" *ngIf="userRole == 'Owner' || userRole == 'Manager' || userRole == 'Supervisor' || userRole == 'Receptionist'">
        <div class="icon">B</div>
        <div class="name">Bulk print GRF</div>
      </li> -->
              </ul>
            </li>
            <!--reports-->
            <li>
              <div (click)="openMenus('reports')" class="dropdown-wrapper flex" *ngIf="userRole != 'WebDesigner'">
                <div class="icon flex-center">
                  <i [ngClass]="[openMenu == 'reports' ? 'active' : '']" class="fa-solid fa-file-lines ml-3"></i>
                </div>
                <div class="name">Reports</div>
                <i *ngIf="openMenu != 'reports'" class="toggle-arrow fa fa-chevron-down"></i>
                <i *ngIf="openMenu == 'reports'" class="toggle-arrow fa fa-chevron-up"></i>
              </div>
              <ul *ngIf="openMenu == 'reports' || (router.url.indexOf('reports') > 0 && reports)" class="submenu">
                <li routerLink="/reports/housekeeping/groups" routerLinkActive="active">
                  <div class="icon">H</div>
                  <div class="name">Housekeeping</div>
                </li>
                <li routerLink="/reports/occupancy"
                    routerLinkActive="active"
                    *ngIf="
                                userRole != 'Housekeeping' && userRole != 'BookingOnly' && userRole != 'Receptionist'
                            ">
                  <div class="icon">O</div>
                  <div class="name">Occupancy</div>
                </li>
                <li routerLink="/reports/sales"
                    routerLinkActive="active"
                    *ngIf="
                                userRole != 'Housekeeping' && userRole != 'BookingOnly' && userRole != 'Receptionist'
                            ">
                  <div class="icon">S</div>
                  <div class="name">Sales</div>
                </li>
                <li routerLink="/reports/invoice"
                    routerLinkActive="active"
                    *ngIf="
                                userRole != 'Housekeeping' && userRole != 'BookingOnly' && userRole != 'Receptionist'
                            ">
                  <div class="icon">I</div>
                  <div class="name">Invoice</div>
                </li>
                <li routerLink="/reports/direct-revenue-ratio"
                    routerLinkActive="active"
                    *ngIf="
                                userRole != 'Housekeeping' && userRole != 'BookingOnly' && userRole != 'Receptionist'
                            ">
                  <div class="icon">D</div>
                  <div class="name">Direct revenue ratio</div>
                </li>
                <li routerLink="/reports/rfm"
                    routerLinkActive="active"
                    *ngIf="
                                userRole != 'Housekeeping' && userRole != 'BookingOnly' && userRole != 'Receptionist'
                            ">
                  <div class="icon">R</div>
                  <div class="name">RFM</div>
                </li>
                <li routerLink="/reports/end-of-day"
                    routerLinkActive="active"
                    *ngIf="
                                userRole != 'Housekeeping' && userRole != 'BookingOnly' && userRole != 'Receptionist'
                            ">
                  <div class="icon">E</div>
                  <div class="name">End of day</div>
                </li>
                <li routerLink="/reports/pace"
                    routerLinkActive="active"
                    *ngIf="
                                userRole != 'Housekeeping' && userRole != 'BookingOnly' && userRole != 'Receptionist'
                            ">
                  <div class="icon">P</div>
                  <div class="name">Pace</div>
                </li>
              </ul>
            </li>
            <!--settings-->
            <li>
              <div (click)="openMenus('settings')" class="dropdown-wrapper flex">
                <div class="icon flex-center">
                  <i [ngClass]="[openMenu == 'settings' ? 'active' : '']" class="fa-solid fa-gear"></i>
                </div>
                <div class="name">Settings</div>
                <i *ngIf="openMenu != 'settings'" class="toggle-arrow fa fa-chevron-down"></i>
                <i *ngIf="openMenu == 'settings'" class="toggle-arrow fa fa-chevron-up"></i>
              </div>
              <ul *ngIf="openMenu == 'settings' || (router.url.indexOf('settings') > 0 && settings)"
                  class="submenu">
                <!-- <li routerLink="/settings/account" routerLinkActive="active">
        <div class="icon">A</div>
        <div class="name">Account</div>
      </li> -->
                <li>
                  <div (click)="accountOptions = !accountOptions"
                       class="dropdown-wrapper flex"
                       *ngIf="userRole == 'Owner'">
                    <div class="icon">A</div>
                    <div class="name">Account</div>
                    <i *ngIf="!accountOptions" class="toggle-arrow fa fa-chevron-down"></i>
                    <i *ngIf="accountOptions" class="toggle-arrow fa fa-chevron-up"></i>
                  </div>
                  <ul *ngIf="accountOptions || (router.url.indexOf('account') > 0 && accountOptions)"
                      class="submenu">
                    <li routerLink="/settings/account/details"
                        class="sub-menu-lvl2"
                        routerLinkActive="active">
                      <div class="icon">D</div>
                      <div class="name">Details</div>
                    </li>
                  </ul>
                  <ul *ngIf="
                      (accountOptions || (router.url.indexOf('account')>
                    0 && accountOptions)) &&
                    isMultiProperty
                    "
                    class="submenu"
                    >
                    <li routerLink="/settings/account/booking-templates"
                        class="sub-menu-lvl2"
                        routerLinkActive="active">
                      <div class="icon">BT</div>
                      <div class="name">Booking template</div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div (click)="propertyOptions = !propertyOptions"
                       class="dropdown-wrapper flex"
                       *ngIf="
                                    userRole === 'Owner' ||
                                    userRole === 'Manager' ||
                                    userRole === 'Supervisor' ||
                                    userRole === 'WebDesigner'
                                ">
                    <div class="icon">P</div>
                    <div class="name">Property</div>
                    <i *ngIf="!propertyOptions" class="toggle-arrow fa fa-chevron-down"></i>
                    <i *ngIf="propertyOptions" class="toggle-arrow fa fa-chevron-up"></i>
                  </div>
                  <ul *ngIf="propertyOptions || (router.url.indexOf('properties') > 0 && propertyOptions)"
                      class="submenu">
                    <li routerLink="/settings/properties/property-list"
                        class="sub-menu-lvl2"
                        routerLinkActive="active"
                        *ngIf="userRole === 'Owner' || userRole === 'Manager'">
                      <div class="icon">PL</div>
                      <div class="name">Property list</div>
                    </li>
                  </ul>
                  <ul *ngIf="propertyOptions || (router.url.indexOf('properties') > 0 && propertyOptions)"
                      class="submenu">
                    <li routerLink="/settings/properties/logs"
                        class="sub-menu-lvl2"
                        routerLinkActive="active"
                        *ngIf="
                                        userRole === 'Owner' ||
                                        userRole === 'Manager' ||
                                        userRole === 'Supervisor' ||
                                        userRole === 'Receptionist'
                                    ">
                      <div class="icon">PL</div>
                      <div class="name">Property logs</div>
                    </li>
                  </ul>
                  <ul *ngIf="propertyOptions || (router.url.indexOf('properties') > 0 && propertyOptions)"
                      class="submenu">
                    <li routerLink="/settings/properties/cost-centres"
                        class="sub-menu-lvl2"
                        routerLinkActive="active"
                        *ngIf="
                                        userRole === 'Owner' ||
                                        userRole === 'Manager' ||
                                        userRole === 'Supervisor' ||
                                        userRole === 'Receptionist'
                                    ">
                      <div class="icon">CC</div>
                      <div class="name">Cost centres</div>
                    </li>
                  </ul>
                  <ul *ngIf="propertyOptions || (router.url.indexOf('properties') > 0 && propertyOptions)"
                      class="submenu">
                    <li routerLink="/settings/properties/booking-sources"
                        class="sub-menu-lvl2"
                        routerLinkActive="active"
                        *ngIf="
                                        userRole === 'Owner' ||
                                        userRole === 'Manager' ||
                                        userRole === 'Supervisor' ||
                                        userRole === 'Receptionist'
                                    ">
                      <div class="icon">BS</div>
                      <div class="name break-word">Booking sources</div>
                    </li>
                  </ul>
                  <ul *ngIf="propertyOptions || (router.url.indexOf('properties') > 0 && propertyOptions)"
                      class="submenu">
                    <li routerLink="/settings/properties/terms-and-conditions"
                        class="sub-menu-lvl2"
                        routerLinkActive="active"
                        *ngIf="
                                        userRole === 'Owner' ||
                                        userRole === 'Manager' ||
                                        userRole === 'Supervisor' ||
                                        userRole === 'Receptionist'
                                    ">
                      <div class="icon">TC</div>
                      <div class="name break-word">Terms and conditions</div>
                    </li>
                  </ul>
                  <ul *ngIf="propertyOptions || (router.url.indexOf('properties') > 0 && propertyOptions)"
                      class="submenu">
                    <li routerLink="/settings/properties/booking-templates"
                        class="sub-menu-lvl2"
                        routerLinkActive="active"
                        *ngIf="
                                        userRole === 'Owner' ||
                                        userRole === 'Manager' ||
                                        userRole === 'Supervisor' ||
                                        userRole === 'WebDesigner'
                                    ">
                      <div class="icon">BT</div>
                      <div class="name break-word">Booking template</div>
                    </li>
                  </ul>
                  <ul *ngIf="propertyOptions || (router.url.indexOf('properties') > 0 && propertyOptions)"
                      class="submenu">
                    <li routerLink="/settings/properties/guest-registration-template"
                        class="sub-menu-lvl2"
                        routerLinkActive="active"
                        *ngIf="
                                        userRole === 'Owner' ||
                                        userRole === 'Manager' ||
                                        userRole === 'Supervisor' ||
                                        userRole === 'Receptionist'
                                    ">
                      <div class="icon">RF</div>
                      <div class="name break-word">Registration form</div>
                    </li>
                  </ul>
                  <ul *ngIf="propertyOptions || (router.url.indexOf('properties') > 0 && propertyOptions)"
                      class="submenu">
                    <li routerLink="/settings/properties/email-sms-settings"
                        class="sub-menu-lvl2"
                        routerLinkActive="active"
                        *ngIf="
                                        userRole != 'WebDesigner' &&
                                        userRole != 'BookingOnly' &&
                                        userRole != 'Housekeeping'
                                    ">
                      <div class="icon">ES</div>
                      <div class="name break-word">Email/SMS settings</div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div (click)="policyOptions = !policyOptions"
                       class="dropdown-wrapper flex"
                       *ngIf="
                                    userRole != 'WebDesigner' &&
                                    userRole != 'BookingOnly' &&
                                    userRole != 'Housekeeping' &&
                                    userRole != 'Receptionist'
                                ">
                    <div class="icon">P</div>
                    <div class="name">Policies</div>
                    <i *ngIf="!policyOptions" class="toggle-arrow fa fa-chevron-down"></i>
                    <i *ngIf="policyOptions" class="toggle-arrow fa fa-chevron-up"></i>
                  </div>
                  <ul *ngIf="policyOptions || (router.url.indexOf('policies') > 0 && policyOptions)"
                      class="submenu">
                    <li routerLink="/settings/policies/deposit-policy"
                        class="sub-menu-lvl2"
                        routerLinkActive="active"
                        *ngIf="
                                        userRole != 'WebDesigner' &&
                                        userRole != 'BookingOnly' &&
                                        userRole != 'Housekeeping'
                                    ">
                      <div class="icon">D</div>
                      <div class="name">Deposit</div>
                    </li>
                    <li routerLink="/settings/policies/payment-policy"
                        class="sub-menu-lvl2"
                        routerLinkActive="active"
                        *ngIf="
                                        userRole != 'WebDesigner' &&
                                        userRole != 'BookingOnly' &&
                                        userRole != 'Housekeeping'
                                    ">
                      <div class="icon">P</div>
                      <div class="name">Payment</div>
                    </li>
                    <li routerLink="/settings/policies/cancellation-policy"
                        class="sub-menu-lvl2"
                        routerLinkActive="active"
                        *ngIf="
                                        userRole != 'WebDesigner' &&
                                        userRole != 'BookingOnly' &&
                                        userRole != 'Housekeeping'
                                    ">
                      <div class="icon">C</div>
                      <div class="name break-word">Cancellation</div>
                    </li>
                  </ul>
                </li>
                <li routerLink="/settings/profile" routerLinkActive="active" *ngIf="!isSuperUser">
                  <div class="icon">P</div>
                  <div class="name">Profile</div>
                </li>
                <li routerLink="/settings/users"
                    routerLinkActive="active"
                    *ngIf="
                                userRole != 'WebDesigner' &&
                                userRole != 'BookingOnly' &&
                                userRole != 'Housekeeping' &&
                                userRole != 'Receptionist'
                            ">
                  <div class="icon">U</div>
                  <div class="name">Users</div>
                </li>
                <li routerLink="/settings/partners"
                    routerLinkActive="active"
                    *ngIf="
                                userRole === 'Owner' ||
                                userRole === 'Manager' ||
                                userRole === 'Supervisor' ||
                                (userRole === 'Receptionist' && userRole != 'Receptionist')
                            ">
                  <div class="icon">P</div>
                  <div class="name">Partners</div>
                </li>
                <li routerLink="/settings/ota-connections"
                    routerLinkActive="active"
                    *ngIf="
                                ((token.Property.ChannelManager || token.Property.OTASwitch) && userRole === 'Owner') ||
                                userRole === 'Manager' ||
                                userRole === 'Supervisor'
                            "
                        >
                            <div class="icon">OC</div>
                            <div class="name">OTA interfaces</div>
                        </li>
                        <li
                            routerLink="/settings/epos"
                            routerLinkActive="active"
                            *ngIf="
                                token.Property.CloudHookEPOS &&
                                userRole != 'WebDesigner' &&
                                userRole != 'BookingOnly' &&
                                userRole != 'Housekeeping' &&
                                userRole != 'Receptionist'
                            ">
                  <div class="icon">CH</div>
                  <div class="name">Cloudhook</div>
                </li>
              </ul>
            </li>
            <li>
              <div class="dropdown-wrapper flex">
                <div class="icon flex-center">
                  <i [ngClass]="[openMenu == 'settings' ? 'active' : '']" class="fa-solid fa-circle-question"></i>
                </div>
                <div class="name"><a href="https://support.caterbook.net" target="_blank">Helpdesk</a></div>
              </div>
            </li>
            <li>
              <div class="dropdown-wrapper flex">
                <div class="icon flex-center">
                  <i [ngClass]="[openMenu == 'settings' ? 'active' : '']" class="fa-solid fa-wrench"></i>
                </div>
                <div class="name"><a href="https://updates.caterbook.net" target="_blank">Updates</a></div>
              </div>
            </li>
            <li>
              <div class="dropdown-wrapper flex">
                <div class="icon flex-center">
                  <i [ngClass]="[openMenu == 'settings' ? 'active' : '']" class="fa-solid fa-credit-card"></i>
                </div>
                <div class="name"><a href="https://mms.caterbook.net/admin/login.php" target="_blank">Caterpay MMS</a></div>
              </div>
            </li>
          </ul>
        </nav>
    </div>
</div>
