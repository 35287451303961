import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { PropertyFunctionRoomModel } from '../../../models/function-room/function-room.model';
import { KeyValuePair } from '../../../models/shared/key-value-pair.model';

@Injectable()
export class FunctionRoomsService {
    private getFunctionRoomsForPropertyUrl = environment.apiUrl + '/Function/GetFunctionRoomsByProperty';
    private deleteFunctionRoomUrl = environment.apiUrl + '/Function/DeletePropertyFunctionRoom';
    private getPropertyFunctionRoomDetailsUrl = environment.apiUrl + '/Function/GetPropertyFunctionRoomDetails';
    private createFunctionRoomUrl = environment.apiUrl + '/Function/CreatePropertyFunctionRoom';
    private updateFunctionRoomUrl = environment.apiUrl + '/Function/UpdatePropertyFunctionRoom';
    private reorderFunctionRoomsUrl = environment.apiUrl + '/Function/ReorderPropertyFunctionRooms';

    constructor(private http: HttpRequestService) {}

    getFunctionRoomsForProperty(propertyId: number) {
        return this.http.get(this.getFunctionRoomsForPropertyUrl + '?propertyId=' + propertyId);
    }

    deleteFunctionRoom(id: number) {
        return this.http.delete(this.deleteFunctionRoomUrl + '?id=' + id, true); 
    }

    getPropertyFunctionRoomDetails(propertyId: number, propertyFunctionRoomId: number) {
        return this.http.get(this.getPropertyFunctionRoomDetailsUrl + '?propertyId=' + propertyId + '&id=' + propertyFunctionRoomId);
    }

    createFunctionRoom(model: PropertyFunctionRoomModel, files: File[]) {
        return this.http.postMultipart(this.createFunctionRoomUrl, model, files, true);
    }

    updateFunctionRoom(model: PropertyFunctionRoomModel, files: File[]) {
        return this.http.postMultipart(this.updateFunctionRoomUrl, model, files, true);
    }

    reorderFunctionRooms(model: KeyValuePair[]) {
        return this.http.post(this.reorderFunctionRoomsUrl, model, true);
    }

}