import { RoomModel } from './rooms-mapping.model';
import { RoomStatus } from './room-details.model';
import { RoomTypeModel } from '../room-type/room-types.model';

export class VirtualRoomDetailsModel {
    public virtualRooms: RoomModel[];
    public selectedRoom: RoomModel;
    public pageData: VirtualRoomPDModel;
}

export class VirtualRoomPDModel {
    public status: RoomStatus[];
    public allRooms: RoomModel[];
    public accommodationTypes: AccommodationTypeModel[];
    public lastRoomDefault: RoomTypeModel[];
}

export class AccommodationTypeModel {
    public id: number;
    public name: string;
    public description: string;
    public active: boolean;
    public dateCreated: Date;
}
