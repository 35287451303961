import { Component, OnInit } from '@angular/core';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { NavigationStart, Router } from '@angular/router';
import { UnsavedChangesGuard } from '../../../shared/auth-guards/auth-guard.service';
import { ViewChild } from '@angular/core';
import { PermissionsService } from '../../../shared/services/permissions.service';
import { InventoryFunctionRoomsPermissionsModel } from '../../../models/permissions/permissions.model';
import { PermissionsEnum } from '../../../enums/permissions.enum';
import { DxDataGridComponent } from 'devextreme-angular';
import { PropertyFunctionRoomsModel } from '../../../models/function-room/function-room.model';
import { ActivatedRoute } from '@angular/router';
import { FunctionRoomsService } from './function-rooms.service';
import StringHelper from '../../../shared/helpers/string-helper';

@Component({
  selector: 'app-function-rooms',
  templateUrl: './function-rooms.component.html',
  styleUrls: ['./function-rooms.component.less']
})
export class FunctionRoomsComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
    permissionsModel: InventoryFunctionRoomsPermissionsModel;

    theme: number;
    destinationUrl;
    forceLeave = false;
    functionRoomOrder = false;

    editFunctionRoomPopup = false;
    deletePopup = false;
    deleteId: number;
    idOfFunctionRoom: number;

    addFunctionRoomPopup = false;

    functionRoomsModel = new PropertyFunctionRoomsModel();

    displayOrderList: number[] = [];

  constructor(
    private functionRoomsService: FunctionRoomsService,
    public router: Router,
    private route: ActivatedRoute,
    private canDeactivateGuard: UnsavedChangesGuard,
    private permissionsService: PermissionsService
  ) { 
    this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;

    this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryFunctionRooms);

    router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
            this.destinationUrl = event.url;
        }
    });
  }

  ngOnInit(): void {
    this.init();
  }

  updateData() {
    this.functionRoomsService.getFunctionRoomsForProperty(AuthTokenService.decodeJwtToken().Property.Id).subscribe(
        (response) => {
            this.functionRoomsModel = response;
        },
        (error) => {}
    );
  }

  openFunctionRoomOrder() {
    this.functionRoomOrder = true;
  }

  leavePage() {
    this.forceLeave = true;
    this.router.navigate([this.destinationUrl]);
  }

  init() {
    this.functionRoomsModel = this.route.snapshot.data.functionRoomsModel;
  }

  cellClick(e) {
    if (e.columnIndex === 1) {
        this.openEditRoomPopup(e.data.id);
    }
  }

  deleteModal(id) {
    this.deletePopup = true;
    this.deleteId = id;
  }

  close() {
    this.deletePopup = false;
  }

  deleteFunctionRoom() {
    this.functionRoomsService.deleteFunctionRoom(this.deleteId).subscribe(
        (response) => {
            this.updateData();
        },
        (error) => {
            console.log(error);
        }
    );
  }

  openEditRoomPopup(id) {
    this.displayOrderList = [];
    this.functionRoomsModel.functionRooms.forEach((x) => {
        if (id !== x.id) {
            this.displayOrderList.push(x.displayOrder);
        }
    });

    this.editFunctionRoomPopup = true;
    this.idOfFunctionRoom = id;
}

  cleanHtml(html) {
    return StringHelper.cleanHtml(html);
  }

  openFunctionRoomsPopup() {
    this.displayOrderList = [];
    this.functionRoomsModel.functionRooms.forEach((x) => this.displayOrderList.push(x.displayOrder));
    this.addFunctionRoomPopup = true;
  }

}
