import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthTokenService } from '../services/auth-token.service';
import { UserStatus } from '../../enums/user.enum';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';
import { AssignRoomsComponent } from '../../routes/signup/assign-rooms/assign-rooms.component';

@Injectable()
export class AuthGuardInCompleteUser  {
    constructor(private authTokenService: AuthTokenService, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.authTokenService.isAuthenticated().then((authenticated: boolean) => {
            if (authenticated) {
                return true;
            } else {
                this.router.navigate(['login']);
            }
        });
    }
}

@Injectable()
export class AuthGuardCompleteUser  {
    constructor(private authTokenService: AuthTokenService, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.authTokenService.isAuthenticated().then((authenticated: boolean) => {
            if (authenticated) {
                return this.authTokenService.isSuperAdmin().then((isSuperAdmin: boolean) => {
                    if (isSuperAdmin) {
                        return true;
                    } else {
                        return this.authTokenService.getUserStatus().then((userStatus) => {
                            if (userStatus === UserStatus.Active) {
                                return true;
                            } else {
                                this.router.navigate(['room-number']);
                            }
                        });
                    }
                });
            } else {
                this.router.navigate(['login']);
            }
        });
    }
}

@Injectable()
export class AuthGuardOwnerUser  {
    constructor(private authTokenService: AuthTokenService, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.authTokenService.isAuthenticated().then((authenticated: boolean) => {
            if (authenticated) {
                return this.authTokenService.isSuperAdmin().then((isSuperAdmin: boolean) => {
                    if (isSuperAdmin) {
                        return true;
                    } else {
                        return this.authTokenService.getUserStatus().then((userStatus) => {
                            if (userStatus === UserStatus.Active) {
                                return this.authTokenService.getRoleName().then((isOwner) => {
                                    if (
                                        isOwner === 'Owner' ||
                                        isOwner === 'Manager' ||
                                        isOwner === 'Supervisor' ||
                                        isOwner === 'Receptionist'
                                    ) {
                                        return true;
                                    } else {
                                        this.router.navigate(['dashboard']);
                                    }
                                });
                            } else {
                                this.router.navigate(['room-number']);
                            }
                        });
                    }
                });
            } else {
                this.router.navigate(['login']);
            }
        });
    }
}

@Injectable()
export class AuthGuardNotSuperUser  {
    constructor(private authTokenService: AuthTokenService, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.authTokenService.isAuthenticated().then((authenticated: boolean) => {
            if (authenticated) {
                return this.authTokenService.isSuperAdmin().then((isSuperAdmin: boolean) => {
                    if (isSuperAdmin) {
                        this.router.navigate(['dashboard']);
                    } else {
                        return this.authTokenService.getUserStatus().then((userStatus) => {
                            if (userStatus === UserStatus.Active) {
                                return true;
                            } else {
                                this.router.navigate(['room-number']);
                            }
                        });
                    }
                });
            } else {
                this.router.navigate(['login']);
            }
        });
    }
}

@Injectable()
export class AuthGuardSuperAdminUser  {
    constructor(private authTokenService: AuthTokenService, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.authTokenService.isAuthenticated().then((authenticated: boolean) => {
            if (authenticated) {
                return this.authTokenService.isSuperAdmin().then((isSuperAdmin: boolean) => {
                    if (isSuperAdmin) {
                        return true;
                    } else {
                        this.router.navigate(['dashboard']);
                    }
                });
            } else {
                this.router.navigate(['login']);
            }
        });
    }
}

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
    forceLeave: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class UnsavedChangesGuard  {
    canDeactivate(component: CanComponentDeactivate) {
        // return window.confirm('You have unsaved changes. Still want to leave?');

        if (component.forceLeave) {
            return true;
        }

        return component.canDeactivate() ? false : true;
    }
}

@Injectable()
export class AuthGuardUserByRole  {
    constructor(
        private authTokenService: AuthTokenService,
        private router: Router,
        private toasterService: ToastService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const roles = route.data['roles'] as Array<string>;

        return this.authTokenService.isAuthenticated().then((authenticated: boolean) => {
            if (authenticated) {
                return this.authTokenService.isSuperAdmin().then((isSuperAdmin: boolean) => {
                    if (isSuperAdmin) {
                        return true;
                    } else {
                        return this.authTokenService.getUserStatus().then((userStatus) => {
                            if (userStatus === UserStatus.Active) {
                                return this.authTokenService.getRoleName().then((isOwner) => {
                                    if (roles.indexOf(isOwner.toString()) > -1) {
                                        return true;
                                    } else {
                                        this.toasterService.showToast('Error', 'You do not have permission to access this resource.', null, ToastEventType.Error, true, 3500);
                                        switch (isOwner.toString()) {
                                            case 'WebDesigner': {
                                                this.router.navigate(['settings/properties/booking-templates']);
                                                break;
                                            }
                                            case 'Housekeeping': {
                                                this.router.navigate(['reports/housekeeping/groups']);
                                                break;
                                            }
                                            default: {
                                                this.router.navigate(['dashboard']);
                                                break;
                                            }
                                        }
                                    }
                                });
                            } else {
                                this.router.navigate(['room-number']);
                            }
                        });
                    }
                });
            } else {
                this.router.navigate(['login']);
            }
        });
    }
}
