import { RatePlanModel } from './rate-plan.model';
import { EnumModel } from '../shared/enum-model';
import { CancellationPolicyModel } from '../policy/cancellation-policy.model';
import { DepositPolicyModel } from '../policy/deposit-policy.model';
import { PaymentPolicyModel } from '../policy/payment-policy.model';
import { AccountVatModel } from './account-vat.model';

export class CreateRatePlanPDModel {
    public ratePlan: RatePlanModel;
    public boardTypes: EnumModel[];
    public pricingTypes: EnumModel[];
    public ratePlanStatuses: EnumModel[];
    public cancellationPolicies: CancellationPolicyModel[];
    public depositPolicies: DepositPolicyModel[];
    public paymentPolicies: PaymentPolicyModel[];
    public bookingTypes: EnumModel[];
    public itemChargeTypes: EnumModel[];
    public propertyVATs: AccountVatModel[];
    public weekDays: EnumModel[];
}
