import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { environment } from '../../../../environments/environment';
import { SaleItemCategoryModel } from '../../../models/cost-center/sale-item-category.model';
import { SaleItemModel } from '../../../models/cost-center/sale-item.model';
import { SaleItemRatePlansModel } from '../../../models/cost-center/sale-item-pd.model';

@Injectable()
export class IncidentalSalesService {
    private getSaleItemsWithCategoriesUrl = environment.apiUrl + '/CostCentre/GetSaleItemsWithCategories';
    private getSaleItemDetailsUrl = environment.apiUrl + '/CostCentre/GetSaleItemDetails';
    private getSaleItemPageDataForInsertUrl = environment.apiUrl + '/CostCentre/GetSaleItemPageDataForInsert';
    private insertSaleItemCategoryUrl = environment.apiUrl + '/CostCentre/InsertSaleItemCategory';
    private updateSaleItemCategoryUrl = environment.apiUrl + '/CostCentre/UpdateSaleItemCategory';
    private insertSaleItemUrl = environment.apiUrl + '/CostCentre/InsertSaleItem';
    private insertSaleItemRatePlansUrl = environment.apiUrl + '/RatePlan/UpdateSaleItemRatePlans';
    private updateSaleItemUrl = environment.apiUrl + '/CostCentre/UpdateSaleItem';
    private deleteSaleItemCategoryUrl = environment.apiUrl + '/CostCentre/DeleteSaleItemCategory';
    private deleteSaleItemUrl = environment.apiUrl + '/CostCentre/DeleteSaleItem';

    constructor(private http: HttpRequestService) {}

    getSaleItemsWithCategories(propertyId: number) {
        return this.http.get(this.getSaleItemsWithCategoriesUrl + '?propertyId=' + propertyId);
    }

    getSaleItemDetails(saleItemId: number, propertyId: number) {
        return this.http.get(this.getSaleItemDetailsUrl + '?saleItemId=' + saleItemId + '&propertyId=' + propertyId);
    }

    getSaleItemPageDataForInsert(propertyId: number) {
        return this.http.get(this.getSaleItemPageDataForInsertUrl + '?propertyId=' + propertyId);
    }

    insertSaleItemCategory(model: SaleItemCategoryModel) {
        return this.http.post(this.insertSaleItemCategoryUrl, model, true);
    }

    updateSaleItemCategory(model: SaleItemCategoryModel) {
        return this.http.post(this.updateSaleItemCategoryUrl, model, true);
    }

    insertSaleItem(model: SaleItemModel) {
        return this.http.post(this.insertSaleItemUrl, model, true);
    }

    updateSaleItem(model: SaleItemModel) {
        return this.http.post(this.updateSaleItemUrl, model, true);
    }

    deleteSaleItemCategory(model: SaleItemCategoryModel) {
        return this.http.post(this.deleteSaleItemCategoryUrl, model, true);
    }

    deleteSaleItem(model: SaleItemModel) {
        return this.http.post(this.deleteSaleItemUrl, model, true);
    }

    updateSaleItemRatePlans(model: SaleItemRatePlansModel[]) {
        return this.http.post(this.insertSaleItemRatePlansUrl, model, true);
    }
}
