import { Injectable } from '@angular/core';
import { AuthTokenService } from '../services/auth-token.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenContext } from '../../models/auth-jwt/token-context';

@Injectable()
export class OtaAuthGuardService  {
    constructor(private authTokenService: AuthTokenService, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.authTokenService.getDecodedTokenPromise().then((token: TokenContext) => {
            if (token && token.Property.ChannelManager) {
                return true;
            } else {
                this.router.navigate(['dashboard']);
            }
        });
    }
}
