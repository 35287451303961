<!--<app-header *ngIf="router.url.indexOf('booking') < 1"></app-header>-->

<div class="assign-rooms-wrapper flex">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <h2>Assign Rooms to Types</h2>
        <div *ngIf="permissionsModel.formButtonEnabled">
            <button type="button" (click)="openRoomOrder()" class="change-order">
                <i class="fa fa-sort" aria-hidden="true"></i>Change order
            </button>
        </div>
        <div class="flex">
            <div class="rooms-wrapper">
                <div class="rooms-box sticky-header">Rooms</div>
                <div class="rooms-scroll-div">
                    <div *ngFor="let room of roomsMappingModel.rooms" class="flex room-name">
                        <i title="{{ permissionsModel.formButtonEnabled ? 'Edit' : 'View' }}" class="fas fa-pen-to-square fa-icon gray fa-icon-small edit-room-wrapper" (click)="openEditPopup(room.id)"><div *ngIf="room.virtualRooms.length > 0" title="Virtual Room" class="styledV">v</div></i>
                        <div class="table-rooms"></div>
                        <div
                            [ngStyle]="{
                                color:
                                    room.status === 'Active'
                                        ? theme == 2
                                            ? 'white'
                                            : 'black'
                                        : theme == 2
                                        ? 'gray'
                                        : 'lightGray'
                            }"
                        >
                            {{ room.shortName }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid-wrapper">
                <dx-data-grid
                    class="assign-rooms-grid"
                    [dataSource]="roomsMappingModel.rooms"
                    rowTemplate="rowTemplateName"
                    columnMinWidth="110"
                >
                    <dxi-column
                        *ngFor="let item of roomsMappingModel.roomTypes"
                        caption="{{ item.shortName }}"
                    ></dxi-column>
                    <tbody class="assign-rooms-body" *dxTemplate="let item of 'rowTemplateName'">
                        <tr *ngIf="item.data.addedRoomTypes">
                            <td
                                *ngFor="let combo of roomsMappingModel.roomTypes; let i = index"
                                class="room-count-wrap"
                            >
                                <div class="form-item">
                                    <dx-select-box
                                        [ngClass]="!item.data.addedRoomTypes[i].valid ? 'invalid-order' : ''"
                                        class="custom-select"
                                        [dataSource]="combo.listForCombo"
                                        [(value)]="item.data.addedRoomTypes[i].orderNumber"
                                        (onValueChanged)="setOrderForRoom($event, item.data, combo, i)"
                                        [disabled]="!permissionsModel.update"
                                    >
                                    </dx-select-box>
                                    
                                </div>
                            </td>
                        </tr>
                    </tbody>

                    <dxo-paging [enabled]="false"></dxo-paging>
                    <dxo-pager [visible]="false"></dxo-pager>
                    <dxo-scrolling mode="standard" rowRenderingMode="standard"></dxo-scrolling>
                </dx-data-grid>
            </div>
        </div>

        <div class="form-item">
            <span class="text-danger">{{ duplicateErrorMessage }}</span>
        </div>
        <button
            type="button"
            *ngIf="!loader && permissionsModel.update"
            (click)="saveAssignRoomToTypes()"
            class="custom-button blue left"
        >
            Save changes
        </button>
        <div *ngIf="loader" class="loader left"></div>
        <button
            *ngIf="permissionsModel.update"
            type="button"
            (click)="openVirtualRoomPopup()"
            class="custom-button orange left"
        >
            Add virtual room
        </button>
    </div>
</div>

<div *ngIf="checkPopup" class="dialog flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>You have unsaved changes, are you sure you want to leave this page?</h2>

            <div class="flex buttons">
                <button type="button" (click)="stayOnPage()" class="custom-button blue">Stay on page</button>
                <button type="button" (click)="leavePage()" class="custom-button red">Discard changes</button>
            </div>
        </div>
    </div>
</div>

<app-update-room
    *ngIf="updateRoomPopup"
    [(editRoom)]="updateRoomPopup"
    [roomId]="roomId"
    (update)="init()"
></app-update-room>
<app-virtual-room
    *ngIf="virtualRoomPopup"
    [roomId]="roomId"
    [(virtualRoom)]="virtualRoomPopup"
    (update)="init()"
></app-virtual-room>
<app-map-room-details
    *ngIf="mapRoomDetails"
    [(mapRoomDetails)]="mapRoomDetails"
    [roomTypeRoomId]="roomTypeRoomId"
    [roomType]="roomType"
    (update)="init()"
></app-map-room-details>
<app-assign-room-order
    *ngIf="roomOrder"
    [(roomOrder)]="roomOrder"
    (update)="init()"
    [roomsGridModel]="roomsMappingModel"
></app-assign-room-order>
