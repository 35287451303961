export class ReportsSearchModel {
    dateFrom: Date;
    dateTo: Date;
}

export class ReportsSearchStringModel {
    dateFrom: string;
    dateTo: string;
    isArrival?: boolean;
}

export class SalesReportsSearchModel extends ReportsSearchModel {
    dateFilter: string;
}

export class InvoiceReportsSearchModel extends ReportsSearchModel {
    status: string;
    invoiceRef: string;
    bookingRef: string;
}

export class PartnerInvoiceReportsSearchModel extends ReportsSearchModel {
    outstandingBalanceOnly: boolean;
    partnerId: number;
    invoiceRef: string;
    bookingRef: string;
}

export class PartnerInvoiceReportsSearchParameterStoredModel extends PartnerInvoiceReportsSearchModel {
    expires: Date;
}

export class ResidentGuestSearchModel {
    searchDate: string;
}

export class PaymentReconciliationReportsSearchModel {
    date: Date;
    cutOffTime: number;
}

export class RecencyReportsSearchModel {
    period: number;
}

export class FrequencyReportsSearchModel {
    hasBooked: number;
    months: number;
}

export class MonetaryReportsSearchModel {
    bookingValue: number;
    months: number;
}

export class EndOfDayReportSearchModel {
    reportingDate: Date;
    cutOffTime: Date;
    cutOffTimeLocal: string;
    adjustmentAmount: number;
    adjustmentNote: string;
    finalised: boolean;
}
