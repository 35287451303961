<div class="rate-schedules-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box rate popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Message templates</h2>

            <p>{{ headerTitle }}</p>

            <dx-data-grid
                class="message-templates-guest-grid"
                [(selectedRowKeys)]="selectedMessageTemplate"
                #grid
                (onSelectionChanged)="selectionChanged($event)"
                [dataSource]="messageTemplatesList"
                [columns]="columnsConfig"
            >
                <div *dxTemplate="let item of 'message'">
                    <div
                        id="template-{{ item.data.id }}"
                        class="message-template-guest-body"
                        [innerHTML]="item.data.message"
                        (mouseenter)="toggleTooltip(item.data)"
                        (mouseleave)="toggleTooltip(item.data)"
                    ></div>
                </div>
                <dxo-scrolling mode="standard"></dxo-scrolling>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [showInfo]="true"> </dxo-pager>
                <dxo-selection
                    [allowSelectAll]="false"
                    showCheckBoxesMode="always"
                    [mode]="'multiple'"
                    [selectAllMode]="'page'"
                ></dxo-selection>
            </dx-data-grid>

            <div class="button-actions">
                <button (click)="submit()" type="button" class="custom-button blue left">{{ submitTitle }}</button>
                <button (click)="close()" type="button" class="custom-button red left">Close</button>
            </div>
        </div>
    </div>
</div>
