<div class="incidental-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box activities popup flex" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2 *ngIf="addCategory">Add Category</h2>
            <h2 *ngIf="editCategory">Edit Category</h2>

            <form [formGroup]="valForm" (ngSubmit)="submitSaleCategory($event)">
                <div class="form-item">
                    <div class="custom-label">Category</div>
                    <input
                        class="custom-input"
                        type="text"
                        formControlName="name"
                        name="saleItemCategoryName"
                        [(ngModel)]="saleItemCategory.name"
                    />
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['name'].hasError('required') &&
                            (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                        "
                        >Name is required.</span
                    >
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['name'].hasError('maxlength') &&
                            (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                        "
                        >Maximum 50 characters.</span
                    >
                </div>
                <!--<div class="form-item">
          <input type="checkbox" name="status" formControlName="status" id="status" [(ngModel)]="saleItemCategory.active" class="css-checkbox" />
          <label for="status" class="css-label radGroup1"></label>
          <div class="custom-label inline">Status</div>
        </div>-->
                <div class="form-item">
                    <div class="custom-label">Status</div>
                    <dx-select-box
                        class="custom-select"
                        [(value)]="saleItemCategory.active"
                        [dataSource]="statusModelList"
                        displayExpr="name"
                        formControlName="status"
                        valueExpr="value"
                    >
                    </dx-select-box>
                    <span
                        class="text-danger"
                        *ngIf="
                            valForm.controls['status'].hasError('required') &&
                            (valForm.controls['status'].dirty || valForm.controls['status'].touched)
                        "
                        >Status is required.</span
                    >
                </div>

                <div class="button-wrapper">
                    <button *ngIf="!loader" type="submit" class="custom-button blue left">Save</button>
                    <div *ngIf="loader" class="loader left"></div>
                    <button (click)="close()" class="custom-button left red" type="button">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>
