import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';

@Component({
    selector: 'app-settings-emails-item',
    templateUrl: './settings-emails-item.component.html',
    styleUrls: ['./settings-emails-item.component.less'],
})
export class SettingsEmailsItemComponent implements OnInit {
    @Input() emails: any;
    @Input() email: any;
    @Input() addItem: boolean;
    @Input() editItem: boolean;

    @Output() addItemChange = new EventEmitter<boolean>();
    @Output() editItemChange = new EventEmitter();

    theme: number;

    trimedDay: string;
    trimedFr: string;
    people = ['Some man', 'Some man2'];
    active = ['Active', 'InActive'];
    templates = ['Template #1', 'Template #2', 'Template #3'];
    schedules = ['OTA', 'Corporate', 'Partners'];
    wemails = [
        {
            Rateschedule: 'Corporate',
            Template: 'Template #1',
        },
        {
            Rateschedule: 'OTA',
            Template: 'Template #3',
        },
        {
            Rateschedule: 'Corporate',
            Template: 'Template #2',
        },
        {
            Rateschedule: 'Partners',
            Template: 'Template #1',
        },
    ];

    frequency = ['After booking - X days', 'After arrival X days', 'Before arrival - X days'];

    close() {
        this.addItemChange.emit(false);
        this.editItemChange.emit();
    }

    constructor() {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    }

    ngOnInit() {
        if (this.email == undefined) {
            this.email = {
                Name: '',
                Frequency: '',
                Status: '',
            };
        } else {
            this.trimedDay = this.email.Frequency.match(/\d+/)[0];
            this.trimedFr = this.email.Frequency.replace(this.trimedDay, 'X');
            console.log(this.email);
        }
    }
}
