import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RateSchedulesService } from '../rate-schedules.service';
import { AuthTokenService } from '../../../../../shared/services/auth-token.service';
import { RateScheduleWithRatePlanModel } from '../../../../../models/rate-schedule/rate-schedule-with-rate-plan.model';
import * as _ from 'lodash';
import { RatePlanModel } from '../../../../../models/rate-plan/rate-plan.model';
import { PermissionsService } from '../../../../../shared/services/permissions.service';
import { InventoryRoomTypesPermissionsModel } from '../../../../../models/permissions/permissions.model';
import { PermissionsEnum } from '../../../../../enums/permissions.enum';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-rate-schedules-rate-plans',
    templateUrl: './rate-schedules-rate-plans.component.html',
    styleUrls: ['./rate-schedules-rate-plans.component.less'],
})
export class RateSchedulesRatePlansComponent implements OnInit {
    @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

    @Input() addRatePlan: boolean;
    @Output() addRatePlanChange = new EventEmitter<boolean>();
    @Input() editRatePlan: boolean;
    @Output() editRatePlanChange = new EventEmitter<boolean>();
    @Input() fromGridRatePlan: boolean;
    @Output() fromGridRatePlanChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();

    @Input() rateScheduleWithRatePlan: RateScheduleWithRatePlanModel;
    @Output() emitListOfRatePlans = new EventEmitter<RatePlanModel[]>();

    permissionsModel: InventoryRoomTypesPermissionsModel;

    loader = false;

    ratePlanDetails = false;
    ratePlanId: number;
    errorMessage: string;
    theme: number;

    ratePlanModelPageData: RatePlanModel[];
    copyOfRateScheduleWithRatePlan: RateScheduleWithRatePlanModel = new RateScheduleWithRatePlanModel();
    googleChanged = false;
    hasGoogleOta = false;
    isPublic = false;

    constructor(private rateScheduleService: RateSchedulesService, private permissionsService: PermissionsService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryRoomTypes);
    }

    ngOnInit() {
        this.isPublic = this.rateScheduleWithRatePlan.name.toLowerCase() == "public";
        this.copyOfRateScheduleWithRatePlan = JSON.parse(JSON.stringify(this.rateScheduleWithRatePlan));
        this.rateScheduleService
            .getAllRatePlansForRateSchedules(AuthTokenService.decodeJwtToken().Property.Id)
            .subscribe(
                (response) => {
                    this.ratePlanModelPageData = response.ratePlans;
                    this.hasGoogleOta = response.hasGoogleOta;
                    this.grid.instance.columnOption('googleOta', 'visible',this.hasGoogleOta && this.isPublic);
                },
                (error) => console.log(error)
            );
    }

    openRatePlanDetailsSchedules(id) {
        this.ratePlanId = id;
        this.ratePlanDetails = true;
    }

    addNewItem() {
        const ratePlanModel: RatePlanModel = new RatePlanModel();
        ratePlanModel.id = null;
        this.copyOfRateScheduleWithRatePlan.ratePlans.push(ratePlanModel);
    }

    delete(data) {
        const index = this.copyOfRateScheduleWithRatePlan.ratePlans.indexOf(data);
        this.copyOfRateScheduleWithRatePlan.ratePlans.splice(index, 1);
    }

    setRatePlanToRateSchedule(e, data) {
        const ratePlanModel: RatePlanModel = e.itemData;
        const index = this.copyOfRateScheduleWithRatePlan.ratePlans.indexOf(data);
        this.copyOfRateScheduleWithRatePlan.ratePlans[index] = ratePlanModel;
    }

    googleOtaChange(id: number) {
        if (this.hasGoogleOta) {
            this.ratePlanModelPageData.forEach(x => x.googleOta = x.id == id ? true : false);
            this.copyOfRateScheduleWithRatePlan.ratePlans.forEach(x => x.googleOta = x.id == id ? true : false);
            this.googleChanged = true;
        }
    }

    validateForm(): boolean {
        for (const ratePlan of this.copyOfRateScheduleWithRatePlan.ratePlans) {
            if (ratePlan.id === null) {
                this.errorMessage = 'Error! Please fill all fields.';
                return true;
            }
        }

        if (
            _.uniqBy(this.copyOfRateScheduleWithRatePlan.ratePlans, 'id').length !==
            this.copyOfRateScheduleWithRatePlan.ratePlans.length
        ) {
            this.errorMessage = 'Error! You cannot enter same rate plans.';
            return true;
        }

        this.errorMessage = '';
        return false;
    }

    addRatePlanToRateSchedules() {
        if (!this.validateForm()) {
            this.loader = true;
            if (this.googleChanged) {
                this.rateScheduleService.updateGoogleRatePlan(this.copyOfRateScheduleWithRatePlan).subscribe(
                    (response) => {
                        console.log('Google rate plan updated');
                    },
                    (error) => {
                        console.log(error);
                    }
                );               
            }

            if (this.fromGridRatePlan) {
                this.updateOnlyRatePlans();
            } else if (this.addRatePlan) {
                this.emitListOfRatePlans.emit(this.copyOfRateScheduleWithRatePlan.ratePlans);
                this.close();
            } else if (this.editRatePlan) {
                this.emitListOfRatePlans.emit(this.copyOfRateScheduleWithRatePlan.ratePlans);
                this.close();
            }
        }
    }

    updateOnlyRatePlans() {
        this.rateScheduleService.updateRateScheduleWithRatePlan(this.copyOfRateScheduleWithRatePlan).subscribe(
            (response) => {
                this.loader = false;
                this.update.emit();
                this.fromGridRatePlanChange.emit(false);
            },
            (error) => {
                this.loader = false;
                console.log(error);
            }
        );
    }

    close() {
        this.addRatePlanChange.emit(false);
        this.editRatePlanChange.emit(false);
        this.fromGridRatePlanChange.emit(false);
    }
}
