<app-header></app-header>

<div class="room-number-wrapper flex">
    <div class="custom-box">
        <h2>Add Rooms</h2>

        <form [formGroup]="valForm" (ngSubmit)="submitNumberOfRooms($event)">
            <div class="form-item">
                <div class="custom-label">How many Rooms at the Property:</div>
                <input
                    name="numberOfRooms"
                    min="1"
                    type="number"
                    class="custom-input"
                    name="numberOfRooms"
                    formControlName="numberOfRooms"
                    [(ngModel)]="numberOfRooms"
                />
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['numberOfRooms'].hasError('required') &&
                        (valForm.controls['numberOfRooms'].dirty || valForm.controls['numberOfRooms'].touched)
                    "
                    >Number of rooms is required.</span
                >
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['numberOfRooms'].hasError('min') &&
                        (valForm.controls['numberOfRooms'].dirty || valForm.controls['numberOfRooms'].touched)
                    "
                    >Minimum number of rooms is 1.</span
                >
                <span
                    class="text-danger"
                    *ngIf="
                        valForm.controls['numberOfRooms'].hasError('max') &&
                        (valForm.controls['numberOfRooms'].dirty || valForm.controls['numberOfRooms'].touched)
                    "
                    >Maximum number of rooms are {{ globalSettingsMaxNumber.value }}.</span
                >
            </div>

            <button type="submit" *ngIf="!loader" class="custom-button blue right">Next</button>
            <div *ngIf="loader" class="loader right"></div>
        </form>
    </div>
</div>
