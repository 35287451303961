import { Component, OnInit, Input, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FunctionPaymentModel } from 'src/app/models/function-room/function-flow.model';
import { Output } from '@angular/core';
import { NewFunctionService } from 'src/app/routes/function/settings-new-function/new-function.service';
import { CurrencyContext } from 'src/app/models/auth-jwt/token-context';
import { AuthTokenService } from 'src/app/shared/services/auth-token.service';
import { FinishCmsFunctionPaymentModel } from 'src/app/models/function-room/finish-cms-function-payment.model';
import { CardModel, CardListModel } from 'src/app/models/booking/booking-details.model';
import { FinishFunctionPaymentResponseModel } from 'src/app/models/function-room/finish-function-payment-response.model';
import { DxTemplateModule } from 'devextreme-angular';
import { PaymentActionType } from 'src/app/enums/payment-action-type.enum';

@Component({
  selector: 'app-cards-list-modal-functions',
  templateUrl: './cards-list-modal-functions.component.html',
  styleUrls: ['./cards-list-modal-functions.component.less']
})
export class CardsListModalFunctionsComponent implements OnInit {
  @Input() showCardModal: boolean;
  @Output() showCardModalChange = new EventEmitter<boolean>();

  @Input() cardListModel: CardListModel;

  @Output() reloadFunctionDetails = new EventEmitter<any>();
  @Output() closeAllModalsEmitter = new EventEmitter<any>();
  @Input() functionPayment: FunctionPaymentModel;

  @Input() newPaymentActionType: PaymentActionType;
  @Input() newPaymentSourceData: FunctionPaymentModel;

  loader = false;
  summary: boolean;
  paymentSuccess = false;
  paymentFailed = false;

  theme: number;

  selectedCard: CardModel;
  selectedCardDigits: String;
  currencyContext: CurrencyContext = new CurrencyContext();
  finishPaymentResponse = new FinishFunctionPaymentResponseModel();
  unableToFindCardWarning = false;
  specificActionInProgress = false;
  specificActionComplete = false;

  constructor(private changeDetector: ChangeDetectorRef, private functionService: NewFunctionService) {
      this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
      this.currencyContext = AuthTokenService.decodeJwtToken().Currency;
      if (this.newPaymentActionType != PaymentActionType.None) {
        this.specificActionInProgress = true;
    }
  }

  ngOnInit() {
      if (this.cardListModel.cards.length > 0) {
          this.selectedCard = this.cardListModel.cards[0];
          this.selectedCardDigits = this.selectedCard.lastFourDigits;
          if (this.newPaymentActionType != PaymentActionType.None) {
            //LH1503 - we are performing particular action on card, find the card and complete payment
            this.selectedCard = this.cardListModel.cards.find(x => x.lastFourDigits == this.newPaymentSourceData.card && x.cardExpiry == this.newPaymentSourceData.cardExpiry);
            if (this.selectedCard != null) {
                console.log('Payment card found - charging');
                this.finishPayment();
            }
            else {
                this.cardListModel.cards.length == 0;
                this.unableToFindCardWarning = true;
                this.specificActionInProgress = false;
            }
        }
      }
  }

  proceedToSummary() {
      if (this.cardListModel.cards.length === 0) {
          return;
      }

      this.summary = true;
  }

  onCardChange(c: CardModel)
  {
      this.selectedCard = c;
  }

  finishPayment() {
      this.loader = true;
      this.paymentFailed = false;
      this.paymentSuccess = false;

      const model: FinishCmsFunctionPaymentModel = {
        payment: this.functionPayment,
        card: this.selectedCard,
    };

    this.functionService.processPaymentDirect(model).subscribe(
        (response) => {
            this.finishPaymentResponse = response;
            this.loader = false;
            if (this.specificActionInProgress) {
                this.specificActionInProgress = false;
                this.specificActionComplete = true;
            }
            
            this.paymentSuccess = this.finishPaymentResponse.responseCode === '0';
            this.paymentFailed = this.finishPaymentResponse.responseCode !== '0';

            this.reloadFunctionDetails.emit();
        },
        (error) => {
            this.loader = false;
            this.close();
        }
    );

      
  }

  close() {
      this.showCardModalChange.emit(false);
  }

  closeAllModals() {
      this.showCardModalChange.emit(false);
      this.closeAllModalsEmitter.emit(false);
  }
}
