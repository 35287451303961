export class AccountAdminInsertModel {
    public id: number;
    public name: string;
    // public shortName: string;
    public description: string;
    public address: string;
    public address2: string;
    public address3: string;
    public postalCode: string;
    public billingPlanId: number;
    public currencyId: number;
    public countryId: number;
    public email: string;
    public billingEmail: string;
    public vatNo: string;
    public companyNumber: string;
    public contactName: string;
    public type: string;
    public status: string;
    public createDate: Date;
    public activationDate?: Date;
}
