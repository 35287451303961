import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { environment } from '../../../../environments/environment';
import { PartnerModel } from '../../../models/partners/partner.model';

@Injectable()
export class SettingsPartnersService {
    private getAllPartnersUrl = environment.apiUrl + '/Partner/GetAllPartners';
    private getPartnerDetailsPdUrl = environment.apiUrl + '/Partner/GetPartnerDetailsPD';
    private getPartnerDetailsPdForInsertUrl = environment.apiUrl + '/Partner/GetPartnerDetailsPDForInsert';
    private insertPartnerUrl = environment.apiUrl + '/Partner/InsertPartner';
    private updatePartnerUrl = environment.apiUrl + '/Partner/UpdatePartner';
    private deletePartnerUrl = environment.apiUrl + '/Partner/DeletePartner';

    constructor(private http: HttpRequestService) {}

    getAllPartners() {
        return this.http.get(this.getAllPartnersUrl);
    }

    getPartnerDetailsPd(partnerId: number) {
        return this.http.get(this.getPartnerDetailsPdUrl + '?partnerId=' + partnerId);
    }

    getPartnerDetailsPdForInsert() {
        return this.http.get(this.getPartnerDetailsPdForInsertUrl);
    }

    insertPartner(model: PartnerModel) {
        return this.http.post(this.insertPartnerUrl, model, true);
    }

    updatePartner(model: PartnerModel) {
        return this.http.put(this.updatePartnerUrl, model, true);
    }

    deletePartner(partnerId: number) {
        return this.http.delete(this.deletePartnerUrl + '?partnerId=' + partnerId, true);
    }
}
