import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'booleanDescription',
})
export class BooleanDescriptionPipe implements PipeTransform {
    transform(value: boolean, options: string[]): string {
        if (value) {
            return options[1];
        } else {
            return options[0];
        }
    }
}
