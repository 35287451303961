import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-payment-success',
    templateUrl: './payment-success.component.html',
    styleUrls: ['./payment-success.component.less'],
})
export class PaymentSuccessComponent implements OnInit {
    message: string;

    constructor(private route: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            switch (params['payment-response']) {
                case 'success':
                    this.message = 'Your payment was successful';
                    break;
                case 'verify-success':
          this.message = 'Your card verification was successful';
          break;
        case 'error':
                    this.message = 'Your payment was not successful';
          break;
        case 'verify-error':
          this.message = 'Your card verification was not successful';
                    break;
                case 'zerobalance':
                    this.message = 'Currently your balance is zero and no payments are due';
                    break;
            }
        });
    }
}
