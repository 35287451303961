import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpRequestService } from '../../../shared/services/http-request.service';
import { ChangePasswordModel } from '../../../models/user/change-password.model';
import { UpdateProfileModel } from '../../../models/user/update-profile.model';

@Injectable()
export class SettingsProfileService {
    private getProfileForUpdateUrl = environment.apiUrl + '/User/GetProfileForUpdate';
    private updateProfileUrl = environment.apiUrl + '/User/UpdateProfile';
    private changePasswordUrl = environment.apiUrl + '/User/ChangePassword';

    constructor(private http: HttpRequestService) {}

    getProfileForUpdate(userId: number) {
        return this.http.get(this.getProfileForUpdateUrl + '?userId=' + userId);
    }

    changePassword(model: ChangePasswordModel) {
        return this.http.put(this.changePasswordUrl, model, true);
    }

    updateProfile(model: UpdateProfileModel) {
        return this.http.put(this.updateProfileUrl, model, true);
    }
}
