import { AfterViewChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RatePlanService } from './rate-plan.service';
import { RatePlanGridModel } from '../../../../models/rate-plan/rate-plan-grid.model';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { Router } from '@angular/router';
import { RatePlanPermissionsModel } from '../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../enums/permissions.enum';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-rate-plans',
    templateUrl: './rate-plans.component.html',
    styleUrls: ['./rate-plans.component.less'],
})
export class RatePlansComponent implements OnInit, OnDestroy {
    @ViewChild(DxDataGridComponent, { static: true })
    grid: DxDataGridComponent;
    detailsPopup = false;
    addRatePlan = false;
    ratePlanOrder = false;
    ratePlanId: number;
    deletePopup = false;
    deleteId: number;
    subscription: any;
    displayOrderList: number[] = [];
    theme: number;

    // pager = {
    //   showPageSizeSelector: false,
    //   showNavigationButtons: true,
    //   allowedPageSizes: [5]
    // };
    size: any;

    permissionsModel: RatePlanPermissionsModel;

    ratePlanGridModel: RatePlanGridModel[];

    constructor(
        private ratePlanService: RatePlanService,
        private router: Router,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = permissionsService.createPermissionsModel(PermissionsEnum.RatePlans);
    }

    ngOnInit() {
        this.init();
    }

    // ngAfterViewChecked(): void {
    //   this.size = <HTMLElement>document.querySelectorAll('html')[0];
    //   if (this.size.getBoundingClientRect().width < 1400) {
    //     this.grid.instance.pageSize(5);
    //     // this.grid.pager = this.pager;
    //   }
    // }

    init() {
        if (AuthTokenService.decodeJwtToken().Property.Id) {
            this.subscription = this.ratePlanService
                .getAllRatePlansForProperty(AuthTokenService.decodeJwtToken().Property.Id)
                .subscribe(
                    (response) => {
                        this.ratePlanGridModel = response;
                    },
                    (error) => console.log(error)
                );
        }
    }

    deleteModal(id) {
        this.deletePopup = true;
        this.deleteId = id;
    }
    close() {
        this.deletePopup = false;
    }

    cellClick(e) {
        if (e.columnIndex === 1) {
            this.openRatePlanDetails(e.data.id);
        }
    }

    deleteRatePlan() {
        this.ratePlanService.deleteRatePlan(this.deleteId).subscribe(
            (response) => {
                this.init();
            },
            (error) => console.log(error)
        );
    }

    openRatePlanOrder() {
        this.ratePlanOrder = true;
    }

    openAddRatePlan() {
        this.displayOrderList = [];
        this.ratePlanGridModel.forEach((x) => this.displayOrderList.push(x.orderNumber));
        this.addRatePlan = true;
    }

    openRatePlanDetails(id) {
        this.displayOrderList = [];
        this.ratePlanGridModel.forEach((x) => {
            if (id !== x.id) {
                this.displayOrderList.push(x.orderNumber);
            }
        });
        this.ratePlanId = id;
        this.detailsPopup = true;
    }

    openPricing(data: RatePlanGridModel) {
        const rateScheduleId: number = data.rateSchedules.length > 0 ? data.rateSchedules[0].id : -1;

        this.router.navigate(['pricing/price-plans', rateScheduleId, data.id]);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
