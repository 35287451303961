export default class StringHelper {
    static cleanHtml(inputHtml: String) {
        return inputHtml.replace(/<\/?[^>]+(>|$)/g, '');
    }

    static replaceNull(input: Object): String {
        if (!input) {
            return '';
          }
        else {
            return String(input);
        }
    }

    static formatCurrency(currencySymbol: String, amount: Number): String {
        return currencySymbol + amount.toFixed(2);
    }
}
