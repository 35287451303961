<div class="caterpay-wrapper flex" [ngClass]="{ larger: !pciBookingForm }">
    <div class="overlay flex" [ngClass]="{ larger: !pciBookingForm }">
        <div class="custom-box popup flex" [ngClass]="{ larger: !pciBookingForm }">
            <i class="fa fa-close" (click)="close()"></i>
            <div class="logo-holder" *ngIf="pciBookingForm">
                <img src="../../../../../../assets/images/padlock.png" alt="" />
                <img src="../../../../../../assets/images/caterpay.png" alt="" />
            </div>
            <div class="payment-wrapper" [innerHTML]="form" [ngClass]="{ larger: !pciBookingForm }"></div>

            <button type="button" class="custom-button red" style="margin: 20px auto 0" (click)="close()">Back</button>
        </div>
    </div>
</div>
