import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoomModel } from '../../../../models/room/rooms-mapping.model';
import { SignupService } from '../../signup.service';
import { RoomDetailsModel } from '../../../../models/room/room-details.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';
import { InventoryAssignRoomsPermissionsModel } from '../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../enums/permissions.enum';

@Component({
    selector: 'app-update-room',
    templateUrl: './update-room.component.html',
    styleUrls: ['./update-room.component.less'],
})
export class UpdateRoomComponent implements OnInit {
    @Input() editRoom: boolean;
    @Output() editRoomChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();
    @Input() roomId: number;

    permissionsModel: InventoryAssignRoomsPermissionsModel;

    loader = false;
    roomDetailsModel = new RoomDetailsModel();
    valForm: UntypedFormGroup;
    theme: number;
    confirmRoomStatusChangeToArchive = false;
    confirmRoomStatusChangeToArchiveMessage = `Are you sure you want to set status to Archive? An Archived room would not count for billing, not display on the calendar and be unmapped from it's room types so it didn't "count" towards inventory calculations.`;

    constructor(
        private signUpService: SignupService,
        private fb: UntypedFormBuilder,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.InventoryAssignRooms);

        this.valForm = this.fb.group({
            roomName: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            shortName: [null, Validators.compose([Validators.required, Validators.maxLength(10)])],
            customTag: [null, Validators.maxLength(50)],
            notes: [null],
            status: [null, Validators.required],
            type: [null, Validators.required],
        });

        if (!this.permissionsModel.formButtonEnabled) {
            this.valForm.controls['roomName'].disable();
            this.valForm.controls['shortName'].disable();
            this.valForm.controls['customTag'].disable();
            this.valForm.controls['notes'].disable();
            this.valForm.controls['status'].disable();
            this.valForm.controls['type'].disable();
        }
    }

    ngOnInit() {
        const propertyId = AuthTokenService.decodeJwtToken().Property.Id;

        this.signUpService.getDetailsRoom(propertyId, this.roomId).subscribe(
            (response) => {
                this.roomDetailsModel = response;
                if (this.roomDetailsModel.room.lastRoomTypeId === 0) {
                    this.roomDetailsModel.room.lastRoomTypeId = -1;
                }
                this.valForm.controls['status'].setValue(this.roomDetailsModel.room.status);
                this.valForm.controls['type'].setValue(this.roomDetailsModel.room.lastRoomTypeId);
            },
            (error) => console.log(error)
        );
    }
    submitUpdateRoomCheck() {
        if (this.roomDetailsModel.room.status === 'Archive') {
            this.confirmRoomStatusChangeToArchive = true;
            return;
        }
        this.submitUpdateRoom();
    }
    submitUpdateRoom() {
        // e.stopPropagation();

        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            this.loader = true;
            this.signUpService.updateRoom(this.roomDetailsModel.room).subscribe(
                (response) => {
                    this.loader = false;
                    this.update.emit();
                    this.close();
                },
                (error) => {
                    this.loader = false;
                    console.log(error);
                }
            );
        }
    }

    close() {
        this.editRoomChange.emit(false);
    }
}
