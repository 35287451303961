<div class="promo-codes-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Extras</h2>

            <dx-data-grid
                [dataSource]="categoriesWithSaleItemsModel"
                rowTemplate="rowTemplateName"
                [columns]="[
                    { dataField: 'name', caption: 'Category Item', allowSorting: true },
                    { dataField: 'saleItems.chargeType', caption: 'Charge Type', allowSorting: false },
                    { dataField: 'saleItems.notes', caption: 'Notes', allowSorting: false }
                ]"
            >
                <table>
                    <ng-container *dxTemplate="let row of 'rowTemplateName'">
                        <tr class="table-header">
                            <td>{{ row.data.name }}</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr
                            *ngFor="let item of row.data.saleItems; let i = index"
                            class="table-row"
                            (click)="selectSaleItem(item)"
                        >
                            <td>{{ item.name }}</td>
                            <td>{{ item.chargeType.name }}</td>
                            <td>{{ item.notes }}</td>
                        </tr>
                    </ng-container>
                </table>

                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="true" [showInfo]="true"> </dxo-pager>
            </dx-data-grid>
            <button type="button" (click)="close()" class="custom-button red left">Close</button>
        </div>
    </div>
</div>
