import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.less']
})
export class GenericErrorComponent {

  imageArray = [
      '../../assets/images/wallpaper2.jpg',
      '../../assets/images/wallpaper3.jpg',
      '../../assets/images/wallpaper1.jpg',
      '../../assets/images/wallpaper4.jpg',
      '../../assets/images/wallpaper5.jpg',
      '../../assets/images/wallpaper6.jpg',
  ];

  imageUrl: string;
 
  constructor(private router: Router) {

    const randomImage = Math.floor(Math.random() * this.imageArray.length);
    this.imageUrl = this.imageArray[randomImage];

  }
}
