export class RatePlanEPOSAllowanceDetailsModel {
    public ratePlanId: number;
    public eposAllowanceType: string;
    public eposAllowanceAmount: number;
    public eposAllowanceChargeType: string;
    public allowanceItems: RatePlanEPOSAllowanceItemModel[];
}

export class RatePlanEPOSAllowanceItemModel {
    public eposAllowanceType: string;
    public eposKey: string;
    public eposValue: string;
    public selected: boolean;

}