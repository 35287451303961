import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SaleItemModel } from '../../../../../../models/cost-center/sale-item.model';
import {
    CategoriesWithSaleItemsGridModel,
    SaleItemGridModel,
} from '../../../../../../models/cost-center/categories-with-sale-items.model';
import { AuthTokenService } from '../../../../../../shared/services/auth-token.service';

@Component({
    selector: 'app-promo-code-sale-items',
    templateUrl: './promo-code-sale-items.component.html',
    styleUrls: ['./promo-code-sale-items.component.less'],
})
export class PromoCodeSaleItemsComponent implements OnInit {
    @Input() promoCodeSaleItem: boolean;
    @Output() promoCodeSaleItemChange = new EventEmitter<boolean>();
    @Output() updateSaleItem = new EventEmitter<SaleItemGridModel>();
    @Input() categoriesWithSaleItemsModel: CategoriesWithSaleItemsGridModel[];

    theme: number;

    constructor() {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    }

    ngOnInit() {}

    selectSaleItem(item: SaleItemGridModel) {
        this.updateSaleItem.emit(item);
        this.close();
    }

    close() {
        this.promoCodeSaleItemChange.emit(false);
    }
}
