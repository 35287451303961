<div class="room-type-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Change order</h2>
            <p>Drag and drop room type to change order using the drag handle</p>
            <dx-data-grid
                *ngIf="copyOfCleaningFrequency"
                class="room-type-grid"
                [dataSource]="copyOfCleaningFrequency"
            >
            <dxo-row-dragging
            [allowReordering]="true"
            [onReorder]="onReorder"
            [showDragIcons]="true">
            </dxo-row-dragging>
            <dxo-sorting mode="none"></dxo-sorting>
            <dxo-scrolling mode="virtual"></dxo-scrolling>
                <dxi-column dataField="order" caption="Order"> </dxi-column>
                <dxi-column dataField="name" caption="Name"> </dxi-column>
                <dxi-column dataField="frequencyType" caption="Frequency Type"> </dxi-column>
                <dxi-column dataField="active" caption="Status" [calculateCellValue]="calculateStatusValue"> </dxi-column>

            </dx-data-grid>

            <div *ngIf="changeOrderLoader" class="loader left"></div>
            <button type="button" class="custom-button blue left" (click)="submitOrderHousekeeping()">Save</button>
            <button type="button" class="custom-button red left" (click)="close()">Cancel</button>
        </div>
    </div>
</div>
