<app-header></app-header>
<app-side-menu></app-side-menu>

<div class="guests-bulk">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <h2>Guest Registration Forms for {{ today }}</h2>

        <div class="filter-wrapper flex">
            <div class="form-item export-btn">
                <button type="button" class="custom-button smaller orange" (click)="printBulkGuestGRF()">
                    Bulk Print
                </button>
            </div>
        </div>
    </div>
</div>

<div class="loader" *ngIf="loader"></div>
