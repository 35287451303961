import { AvailabilityGridModel } from './availability-grid.model';
import { RoomTypeBaseModel } from '../room-type/room-type-base.model';
import { RateScheduleBaseModel } from '../rate-schedule/rate-schedule-base.model';

export class AvailabilityGridPdModel {
    public availabilityList: AvailabilityGridModel;
    public roomTypes: RoomTypeBaseModel[] = [];
    public rateSchedules: RateScheduleBaseModel[] = [];

    constructor() {
        this.availabilityList = new AvailabilityGridModel();
    }
}
