import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthTokenService } from '../../../../shared/services/auth-token.service';

@Component({
    selector: 'app-message-modal',
    templateUrl: './message-modal.component.html',
    styleUrls: ['./message-modal.component.less'],
})
export class MessageModalComponent implements OnInit {
    @Input() popup: boolean;
    @Input() title: string;
    @Input() message: string;
    @Output() popupChange = new EventEmitter<boolean>();
    @Output() confirm = new EventEmitter();
    theme: number;

    constructor() {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    }

    ngOnInit() {}

    close() {
        this.confirm.emit();
        this.popupChange.emit(false);
    }
}
