import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BookingPaymentDetailModel } from 'src/app/models/booking/booking-all-details.model';
import { CurrencyContext } from 'src/app/models/auth-jwt/token-context';
import { BookingDetailsService } from 'src/app/routes/booking/settings-booking-detail/booking-details.service';
import { AuthTokenService } from '../../services/auth-token.service';

@Component({
    selector: 'app-booking-details-invoice-payment',
    templateUrl: './booking-details-invoice-payment.component.html',
    styleUrls: ['./booking-details-invoice-payment.component.less'],
})
export class BookingDetailsInvoicePaymentComponent implements OnInit {
    @Input() addPayment: boolean;
    @Output() addPaymentChange = new EventEmitter<boolean>();
    @Output() paymentList = new EventEmitter<BookingPaymentDetailModel[]>();
    @Input() selectedPayments: BookingPaymentDetailModel[];

    @Input() editPayment: boolean;
    @Output() editPaymentChange = new EventEmitter<boolean>();
    @Output() update = new EventEmitter();

    @Input() bookingId: number;
    @Input() invoiceId: number;

    loader = false;

    bookingPaymentList: BookingPaymentDetailModel[] = [];
    selectedRows: BookingPaymentDetailModel[] = [];
    currencyContext: CurrencyContext = new CurrencyContext();

    theme: number;

    constructor(private bookingDetailService: BookingDetailsService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.currencyContext = AuthTokenService.decodeJwtToken().Currency;
    }

    ngOnInit() {
        this.bookingDetailService.getPaymentItemsForInvoice(this.bookingId).subscribe(
            (response) => {
                this.bookingPaymentList = response;
                if (this.addPayment) {
                    this.removeExistItems();
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    removeExistItems() {
        const idOfBookingPayments: number[] = [];
        this.selectedPayments.forEach((x) => idOfBookingPayments.push(x.id));

        for (let i = 0; i < this.bookingPaymentList.length; i++) {
            for (let j = 0; j < idOfBookingPayments.length; j++) {
                if (this.bookingPaymentList[i].id === idOfBookingPayments[j]) {
                    this.bookingPaymentList.splice(i, 1);
                }
            }
        }
    }

    submitBookingPayments() {
        if (this.selectedRows.length > 0) {
            if (this.addPayment) {
                this.paymentList.emit(this.selectedRows);
                this.close();
            } else if (this.editPayment) {
                this.selectedRows.forEach((x) => (x.invoiceId = this.invoiceId));
                this.bookingDetailService.insertInvoicePayments(this.selectedRows).subscribe(
                    (response) => {
                        this.update.emit();
                        this.close();
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        }
    }

    close() {
        this.addPaymentChange.emit(false);
        this.editPaymentChange.emit(false);
    }
}
