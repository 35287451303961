import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-pass-code',
    templateUrl: './pass-code.component.html',
    styleUrls: ['./pass-code.component.less'],
})
export class PassCodeComponent implements OnInit {
    @Input() passCode: boolean;
    @Input() hasNotification: boolean;
    @Output() passCodeChange = new EventEmitter<boolean>();
    @Output() onClose = new EventEmitter();

    passCodeText: string;

    constructor() {}

    ngOnInit() {}

    checkPassCode() {
        if (this.passCodeText === '7896') {
            this.passCodeChange.emit(false);
            this.onClose.emit();
        }
    }
}
