import { Component, ContentChild } from '@angular/core';

@Component({
    selector: 'app-show-hide-container',
    template: ` <style>
            a {
                position: absolute;
                right: 1px;
                top: 1px;
                width: 37px;
                height: 37px;
                line-height: 37px;
                text-align: center;
                color: #666;
                background-color: #e6ebf6;
                cursor: pointer;
                z-index: 1;
            }
        </style>
        <ng-content></ng-content>
        <a (click)="toggleShow($event)">
            <i *ngIf="!this.show" class="fa fa-eye"></i>
            <i *ngIf="this.show" class="fa fa-eye-slash"></i>
        </a>`,
})
export class ShowHideContainerComponent {
    show = false;

    @ContentChild('showhideinput', { static: true }) input;

    constructor() {}

    toggleShow(event) {
        this.show = !this.show;
        if (this.show) {
            this.input.nativeElement.type = 'text';
        } else {
            this.input.nativeElement.type = 'password';
        }
    }
}
