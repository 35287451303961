export class SaleItemModel {
    public id: number;
    public categoryId: number;
    public costCenterId: number;
    public name: string;
    public notes: string;
    public chargeType: string;
    public sellType: string;
    public price: number;
    public active: boolean;
    public dateCreated: Date;
    public vatId: number;
    public webSale: boolean;
    public itemType: string;
    public defaultEPOSItem: boolean;
    public housekeeping: boolean;
}

export class SaleItemWithCategoryNameModel {
    public id: number;
    public categoryId: number;
    public categoryName: string;
    public costCenterId: number;
    public name: string;
    public notes: string;
    public itemType: string;
    public chargeType: string;
    public price: number;
    public active: boolean;
    public dateCreated: Date;
    public vatId: number;
    public defaultEPOSItem: boolean;
}
