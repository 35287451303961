<app-header></app-header>
<ng-container *ngIf="false"
    ><div class="account-details-wrapper flex">
        <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Account details</h2>

            <form [formGroup]="valForm" (ngSubmit)="nextAccountDetails($event)">
                <div class="form-box flex">
                    <div class="form-item long">
                        <div class="custom-label">Registered business trading name</div>
                        <input
                            class="custom-input"
                            type="text"
                            name="buisnessAccountName"
                            formControlName="name"
                            [(ngModel)]="activationFlowModel.account.name"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['name'].hasError('required') &&
                                (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                            "
                            >Name is required.</span
                        >
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['name'].hasError('maxlength') &&
                                (valForm.controls['name'].dirty || valForm.controls['name'].touched)
                            "
                            >Maximum 250 characters..</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Billing email</div>
                        <input
                            type="email"
                            class="custom-input"
                            name="billEmail"
                            formControlName="billEmail"
                            [(ngModel)]="activationFlowModel.account.billingEmail"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['billEmail'].hasError('required') &&
                                (valForm.controls['billEmail'].dirty || valForm.controls['billEmail'].touched)
                            "
                            >Billing email is required.</span
                        >
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['billEmail'].hasError('maxlength') &&
                                (valForm.controls['billEmail'].dirty || valForm.controls['billEmail'].touched)
                            "
                            >Maximum 50 characters..</span
                        >
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['billEmail'].hasError('email') &&
                                (valForm.controls['billEmail'].dirty || valForm.controls['billEmail'].touched)
                            "
                            >Must be a valid email address</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Billing contact name</div>
                        <input
                            class="custom-input"
                            name="billContactName"
                            formControlName="billContactName"
                            type="text"
                            [(ngModel)]="activationFlowModel.account.contactName"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['billContactName'].hasError('required') &&
                                (valForm.controls['billContactName'].dirty ||
                                    valForm.controls['billContactName'].touched)
                            "
                            >Contact name is required.</span
                        >
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['billContactName'].hasError('maxlength') &&
                                (valForm.controls['billContactName'].dirty ||
                                    valForm.controls['billContactName'].touched)
                            "
                            >Maximum 50 characters..</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Registered business address</div>
                        <input
                            class="custom-input"
                            type="text"
                            name="address"
                            formControlName="address"
                            [(ngModel)]="activationFlowModel.account.address"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['address'].hasError('required') &&
                                (valForm.controls['address'].dirty || valForm.controls['address'].touched)
                            "
                            >Address is required.</span
                        >
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['address'].hasError('maxlength') &&
                                (valForm.controls['address'].dirty || valForm.controls['address'].touched)
                            "
                            >Maximum 250 characters..</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Company number <span>(where applicable)</span></div>
                        <input
                            class="custom-input"
                            name="companyNumber"
                            formControlName="companyNumber"
                            type="text"
                            [(ngModel)]="activationFlowModel.account.companyNumber"
                        />
                        <!-- <span class="text-danger" *ngIf="valForm.controls['companyNumber'].hasError('required') && (valForm.controls['companyNumber'].dirty || valForm.controls['companyNumber'].touched)">Company number is required.</span> -->
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['companyNumber'].hasError('maxlength') &&
                                (valForm.controls['companyNumber'].dirty || valForm.controls['companyNumber'].touched)
                            "
                            >Maximum 50 characters..</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">Country</div>
                        <dx-select-box
                            class="custom-select"
                            [dataSource]="activationFlowModel.countries"
                            displayExpr="name"
                            [(value)]="activationFlowModel.account.countryId"
                            valueExpr="id"
                            name="countryCode"
                            formControlName="countryCode"
                        >
                        </dx-select-box>
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['countryCode'].hasError('required') &&
                                (valForm.controls['countryCode'].dirty || valForm.controls['countryCode'].touched)
                            "
                            >Country is required.</span
                        >
                    </div>
                    <div class="form-item">
                        <div class="custom-label">VAT number</div>
                        <input
                            class="custom-input"
                            type="text"
                            name="vatNo"
                            formControlName="vatNo"
                            [(ngModel)]="activationFlowModel.account.vatNo"
                        />
                        <!-- <span class="text-danger" *ngIf="valForm.controls['vatNo'].hasError('required') && (valForm.controls['vatNo'].dirty || valForm.controls['vatNo'].touched)">VAT Number is required.</span> -->
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['vatNo'].hasError('maxlength') &&
                                (valForm.controls['vatNo'].dirty || valForm.controls['vatNo'].touched)
                            "
                            >Maximum 50 characters..</span
                        >
                    </div>
                    <div class="form-item">
                        <!--<input type="checkbox" name="checkbox" id="checkbox1" class="css-checkbox"/>
          <label for="checkbox1" class="css-label radGroup1"></label>
          <div class="custom-label inline">Create new VAT rate</div>-->
                    </div>
                    <div class="new-vat flex">
                        <!--<div class="form-item">
            <div class="custom-label">VAT name</div>
            <input class="custom-input">
          </div>
          <div class="form-item">
            <div class="custom-label">VAT value (%)</div>
            <input class="custom-input">
          </div>-->
                    </div>
                    <!--<div class="form-item">
          <input type="checkbox" name="checkbox" id="checkbox2" class="css-checkbox" formControlName="terms"/>
          <label for="checkbox2" class="css-label radGroup1"></label>
          <div class="custom-label inline">Terms and Conditions</div>
          <span class="text-danger" *ngIf="valForm.controls['terms'].hasError('required') && (valForm.controls['terms'].dirty || valForm.controls['terms'].touched)">You have to accept Terms and Conditions.</span>
        </div>-->
                </div>

                <button *ngIf="!loader" type="submit" class="custom-button blue">Next</button>
                <div *ngIf="loader" class="loader left"></div>
                <button type="button" class="custom-button red" (click)="goToDashboard()">Cancel</button>
            </form>
        </div>
    </div></ng-container
>
<div class="activation-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup messageLogLink" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h3>Thank you! One of our staff will contact you to discuss the next steps.</h3>
            <div class="buttons-wrapper continue">
                <button type="button" class="custom-button orange center" (click)="goToDashboard()">Continue</button>
            </div>
        </div>
    </div>
</div>
